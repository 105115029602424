import React, { useContext } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { WordLocalizeContext } from '@/contexts/WordLocalizeContext';
import MultiUnitProdOrMatBox from '../../Box/Rendering/MultiUnitProdOrMatBox';
import ProdOrMatQuantityInput from '../../Inputs/ProdOrMatQuantityInput';

/**
 * ฟอร์มสำหรับเลือกวัตถุพร้อมจำนวนไปทำอะไรสักอย่าง เช่น การย้ายวัตถุดิบ
 */
function SelectProductToMoveForm({
  control,
  controlKey = 'materialIn',
  watch,
  setValue,
  information,
  rerender,
  setRerender,
}) {
  const { fields } = useFieldArray({ control, name: controlKey });
  const { findWord } = useContext(WordLocalizeContext);
  const wmsSetting = information?.setting?.wms;
  return (
    <div>
      {' '}
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold"> เลือก</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">
                    วันที่วัตถุดิบเข้าคลังปัจจุบัน
                  </div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> เลขอ้างอิง </div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">สินค้า </div>
                </TableCell>
                {wmsSetting?.display?.lot_number && (
                  <TableCell>
                    <div className="font-bold">หมายเลขล็อต</div>
                  </TableCell>
                )}
                <TableCell>
                  <div className="font-bold">จำนวนทั้งหมด</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">จำนวนคงเหลือ</div>
                </TableCell>
                {wmsSetting?.display?.production_date && (
                  <TableCell>
                    <div className="font-bold"> วันที่ผลิต</div>
                  </TableCell>
                )}
                {wmsSetting?.display?.expiration_date && (
                  <TableCell>
                    <div className="font-bold"> วันที่หมดอายุ</div>
                  </TableCell>
                )}{' '}
                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(fields) ? (
                _.map(fields, (row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="text-xs">
                        <Controller
                          control={control}
                          name={`${controlKey}[${index}].selected`}
                          render={({ field }) => (
                            <Checkbox {...field} defaultChecked={field.value} />
                          )}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      {dayjs(row?.inventory_date).format('DD/MM/BBBB')}
                    </TableCell>
                    <TableCell>{row?.running_number}</TableCell>
                    <TableCell>
                      {row?.product?.type_code || ''}
                      {row?.product?.name}
                    </TableCell>
                    {wmsSetting?.display?.lot_number && (
                      <TableCell>
                        {row?.source?.overall_code} {row?.source?.name}
                      </TableCell>
                    )}
                    <TableCell>
                      <MultiUnitProdOrMatBox
                        quantity={row?.quantity}
                        foundMaterial={row?.product}
                      />
                    </TableCell>
                    <TableCell>
                      <MultiUnitProdOrMatBox
                        quantity={row?.amount}
                        foundMaterial={row?.product}
                      />
                    </TableCell>{' '}
                    {wmsSetting?.display?.production_date && (
                      <TableCell>
                        <b className="text-green-500">
                          {dayjs(row?.production_date)
                            .locale('th')
                            .format('DD MMM BBBB')}
                        </b>
                      </TableCell>
                    )}
                    {wmsSetting?.display?.expiration_date && (
                      <TableCell>
                        <b className="text-red-500">
                          {dayjs(row?.expiration_date)
                            .locale('th')
                            .format('DD MMM BBBB')}
                        </b>
                      </TableCell>
                    )}
                    <TableCell>
                      <ProdOrMatQuantityInput
                        controllerName={`${controlKey}[${index}].amount`}
                        artificialControllerName={`${controlKey}[${index}].amount_artificial`}
                        index={index}
                        selectedUnitControllerName={`${controlKey}[${index}].amount_selected_unit`}
                        defaultValue={row?.amount}
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        disabled={!watch(`${controlKey}[${index}].selected`)}
                        selectedProduct={row?.product}
                        reload={rerender}
                        setReload={setRerender}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default SelectProductToMoveForm;

SelectProductToMoveForm.propTypes = {
  control: PropTypes.object,
  /** คำที่จะเอาไปเป็น Key นำหน้าใน Array ของวัตถุดิบแต่ละตัว เช่น ถ้า key เป็น materialList
   * array แต่ละตัว key จะเป็น materialList[0].material, materialList[1].material  */
  controlKey: PropTypes.string,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  information: PropTypes.object,
  rerender: PropTypes.bool,
  setRerender: PropTypes.func,
};
