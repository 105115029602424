const QUOTATION_ACION = {
  CREATE: {
    status_code: "CREATE",
    description: "สร้าง",
    color: "secondary",
  },
  UPDATE: {
    status_code: "UPDATE",
    description: "แก้ไข",
    color: "warning",
  },
  DELETE: {
    status_code: "DELETE",
    description: "ลบ",
    color: "error",
  },
  PRODUCE: {
    status_code: "PRODUCE",
    description: "สั่งผลิต",
    color: "success",
  },
};

module.exports = QUOTATION_ACION;
