import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  Autocomplete,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { PRODUCT_STOCK_STATUS } from '@/utils/constants';
import MultiUnitProdOrMatBox from '../../Box/Rendering/MultiUnitProdOrMatBox';
import { ProdOrMatQuantityInput, ProductAutocomplete } from '../../Inputs';

/**
 * @namespace Form/WMS
 * @description ฟอร์มต่างๆ ในระบบคลังสินค้า
 */

/**
 * @function AddProductForm
 * @description เพิ่มสินค้าเข้าในคลังสินค้า
 * @memberof Form/WMS
 * @returns {React.Component} React Component
 * @author ApiwanaSuwannapoom , YOT-ANAN
 */
export function AddProductForm({ product, selectProduct, setSelectProduct }) {
  const resetValues = {
    quantity: 0,
    cost_price: 0,
    product: product[0],
    production_date: new Date(),
    expiration_date: new Date(),
    lot_number: '',
  };
  const [reload, setReload] = useState(false);
  const information = useSelector((state) => state.information);
  const {
    setting: { wms: wmsSetting },
  } = information;
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm();
  useEffect(() => () => {}, [reload]);

  const handleAddproduct = () => {
    if (
      (getValues('product') ||
        getValues('cost_price') ||
        getValues('quantity')) === ''
    ) {
      alert('กรุณาใส่ข้อมูลให้ครบถ้วน');
    } else {
      const data = {
        product: getValues('product')._id,
        cost_price: getValues('cost_price'),
        quantity: getValues('quantity'),
        amount: getValues('quantity'),
        lot_number: getValues('lot_number'),
        production_date: getValues('production_date'),
        expiration_date: getValues('expiration_date'),
        status: PRODUCT_STOCK_STATUS.IN_STOCK.status_code,
      };
      selectProduct.push(data);
      setSelectProduct(selectProduct);
      reset(resetValues);
      setReload(!reload);
    }
  };

  const handleRemoveproduct = (index) => {
    selectProduct.splice(index, 1);
    setSelectProduct(selectProduct);
    setReload(!reload);
  };

  const productOptions = () => (
    <Card>
      <form onSubmit={handleSubmit(handleAddproduct)}>
        <div className="w-full flex flex-row flex-wrap p-4">
          <div className="w-full px-1 py-2">
            {!_.isEmpty(product) && (
              <Controller
                name={'product'}
                control={control}
                rules={{ required: true }}
                defaultValue={product?.[0]}
                render={({ field }) => (
                  <ProductAutocomplete
                    field={field}
                    information={information}
                    productRows={product}
                  />
                )}
              />
            )}
          </div>
          {wmsSetting?.display?.lot_number && (
            <div className="w-full px-1 py-2">
              <Controller
                name={'lot_number'}
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="หมายเลขล็อต"
                    fullWidth
                    size={'small'}
                    helperText={errors.lot_number && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
          )}
          {wmsSetting?.display?.cost_price && (
            <div className="w-full lg:w-1/2 px-1 py-2">
              <Controller
                name={'cost_price'}
                control={control}
                defaultValue={0}
                rules={{ required: false, min: 0 }}
                render={({ field }) => (
                  <TextField
                    type="Number"
                    {...field}
                    label="ต้นทุน (บาท)"
                    fullWidth
                    size={'small'}
                    helperText={errors.cost_price && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
          )}

          <div className="w-full  px-1 py-2">
            <ProdOrMatQuantityInput
              controllerName="quantity"
              artificialControllerName="quantity_artificial"
              selectedUnitControllerName="quantity_unit"
              index={0}
              setValue={setValue}
              watch={watch}
              control={control}
              selectedProduct={watch('product')}
            />
          </div>
          {wmsSetting?.display?.production_date && (
            <div className="w-full lg:w-1/2 px-1 mb-2">
              <label className="text-sm">วันที่ผลิต</label>
              <Controller
                name={'production_date'}
                control={control}
                defaultValue={new Date()}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="date"
                    fullWidth
                    size={'small'}
                    helperText={errors.production_date && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
          )}
          {wmsSetting?.display?.expiration_date && (
            <div className="w-full lg:w-1/2 px-1 mb-2">
              <label className="text-sm">วันหมดอายุ</label>
              <Controller
                name={'expiration_date'}
                control={control}
                defaultValue={new Date()}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="date"
                    fullWidth
                    size={'small'}
                    helperText={errors.expiration_date && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
          )}
          <div className="px-1 w-full">
            <Button
              type="button"
              variant="outlined"
              size={'small'}
              color={'success'}
              onClick={handleSubmit(handleAddproduct)}
            >
              เพิ่ม
            </Button>
          </div>
        </div>
      </form>
    </Card>
  );

  const displayProduct = () => (
    <Card>
      <TableContainer>
        <Table size="small" className="border-lg">
          <TableHead className="bg-blue-100">
            <TableRow>
              <TableCell>
                <div className="font-bold">ลำดับที่</div>
              </TableCell>
              {wmsSetting?.display?.lot_number && (
                <TableCell>
                  <div className="font-bold"> หมายเลขล็อต</div>
                </TableCell>
              )}

              <TableCell>
                <div className="font-bold"> ชื่อสินค้า</div>
              </TableCell>
              {wmsSetting?.display?.cost_price && (
                <TableCell>
                  <div className="font-bold"> ราคาต้นทุน</div>
                </TableCell>
              )}
              <TableCell>
                <div className="font-bold"> จำนวน</div>
              </TableCell>
              {wmsSetting?.display?.production_date && (
                <TableCell>
                  <div className="font-bold"> วันที่ผลิต</div>
                </TableCell>
              )}
              {wmsSetting?.display?.expiration_date && (
                <TableCell>
                  <div className="font-bold"> วันที่หมดอายุ</div>
                </TableCell>
              )}

              <TableCell>
                <div className="font-bold"> ดำเนินการ</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!_.isEmpty(selectProduct) ? (
              selectProduct.map((_product, index) => {
                const findproduct = _.find(product, {
                  id: _product?.product,
                });
                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    {wmsSetting?.display?.lot_number && (
                      <TableCell>{_product?.lot_number || '-'}</TableCell>
                    )}

                    <TableCell>
                      <b>{findproduct?.type_code}</b> {findproduct?.name}
                    </TableCell>
                    {wmsSetting?.display?.cost_price && (
                      <TableCell>{_product?.cost_price} บาท</TableCell>
                    )}

                    <TableCell>
                      <MultiUnitProdOrMatBox
                        foundMaterial={findproduct}
                        quantity={_product?.quantity}
                      />
                    </TableCell>
                    {wmsSetting?.display?.production_date && (
                      <TableCell>
                        {dayjs(_product?.production_date)
                          .locale('th')
                          .format('DD MMM BBBB')}
                      </TableCell>
                    )}
                    {wmsSetting?.display?.expiration_date && (
                      <TableCell>
                        {dayjs(_product?.expiration_date)
                          .locale('th')
                          .format('DD MMM BBBB')}
                      </TableCell>
                    )}

                    <TableCell>
                      <Button
                        color={'error'}
                        variant="contained"
                        size={'small'}
                        onClick={() => handleRemoveproduct(index)}
                      >
                        ลบ
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow key={''}>
                <TableCell colSpan={6}>
                  <div className="text-center">ไม่มีข้อมูล</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );

  const showSelectProduct = () => displayProduct();

  return (
    <div>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-1/3 ">{productOptions()}</div>
        <div className="w-full lg:w-2/3 lg:pl-2">{showSelectProduct()}</div>
      </div>
    </div>
  );
}

AddProductForm.propTypes = {
  product: PropTypes.object,
  selectProduct: PropTypes.object,
  setSelectProduct: PropTypes.func,
};

AddProductForm.defaultProps = {
  product: null,
  selectProduct: null,
};

export default AddProductForm;
