export const DISPLAY_TYPE = {
  CARD: {
    status_code: 'CARD',
    description: 'การ์ด',
  },
  TABLE: {
    status_code: 'TABLE',
    description: 'ตาราง',
  },
};

export default DISPLAY_TYPE;
