module.exports = {
  name: "E-Factory",
  description: "E-Factory",
  owner: {
    name: "ศูนย์วิจัยระบบอัตโนมัติอัจฉริยะ",
    website: "https://iarc.psu.ac.th/",
    telephone: "088-782-4507",
    email: "iarc.psu@gmail.com",
    tax: "01234567890123",
    address: {
      subdistrict: "คอหงส์",
      district: "หาดใหญ่",
      province: "สงขลา",
      postcode: "90112",
      country: "ไทย",
    },
  },
  sponsor: {
    name: "ศูนย์ส่งเสริมอุตสาหกรรมภาคที่ 11",
    website: "https://ipc11.dip.go.th/th",
  },
  available_module: {
    HRMS: true,
    CRM: true,
    IMS: true,
    MMS: true,
    SPM: true,
    WMS: true,
    PMS: true,
    MOM: true,
    INFORMATION: true,
    REPORT: true,
    DASHBOARD: true,
  },
};
