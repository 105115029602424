/* eslint-disable no-confusing-arrow */
import dayjs from 'dayjs';

const columns = [
  {
    label: 'ลำดับที่',
    value: 'index',
  },
  {
    label: 'วันที่ดำเนินการ',
    value: (col) =>
      `${dayjs(col?.createdAt)?.locale('th')?.format('D/M/BBBB')}`,
  },
  {
    label: 'ช่วงเวลาที่ดำเนินการ',
    value: (col) => `${new Date(col?.createdAt)?.toLocaleTimeString('th')}`,
  },
  {
    label: 'กระบวนการดำเนินการ',
    value: (col) => col?.material_transaction_type?.name,
  },
  {
    label: 'หมายเลขล็อต',
    value: (col) => col?.lotId?.lot_number || '',
  },
  {
    label: 'รหัสวัตถุดิบ',
    value: (col) => col?.lotId?.material?.type_code || '',
  },
  {
    label: 'วัตถุดิบ',
    value: (col) => col?.lotId?.material?.name || '',
  },
  {
    label: 'คลัง',
    value: (col) => col?.lotId?.place?.name || '',
  },
  {
    label: 'จำนวน',
    value: (col) => col?.lotId?.quantity || '',
  },
  {
    label: 'คงเหลือ',
    value: (col) => col?.lotId?.amount || '',
  },
  {
    label: 'ผู้ดำเนินการ',
    value: (col) =>
      col?.material_transaction_type?.direction === 'add'
        ? `${col?.material_stock_lot[0]?.lotId?.recipient_name || '-'}`
        : `${col?.request_name || '-'}`,
  },
  {
    label: 'หมายเหตุ',
    value: 'remark',
  },
];

export default { columns };
