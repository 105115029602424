import React, { createRef } from 'react';
import { CSVReader } from 'react-papaparse';
import { Button } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

const CSVUpload = ({ setCsvData }) => {
  const buttonRef = createRef();

  const handleOnFileLoad = (data) => {
    console.log('File Data');
    console.log(data);
    console.log('-----');

    // ชื่อของ Key จะอยู่ใน แถวที่ index 1 (แถวที่ 2 ใน Excel) และเอาแถวสุดท้ายออก
    const fileDataKey = data?.[1]?.data;
    const fileData = _.initial(_.slice(data, 2));

    console.log('File Data Key', fileDataKey);
    console.log('File Data', fileData);
    const fileInFormat = _.map(fileData, (_fileData) => {
      const onlyDataFileData = _fileData?.data;
      return _.zipObject(fileDataKey, onlyDataFileData);
    });

    console.log('File In Format', fileInFormat);
    setCsvData(fileInFormat);
  };

  const handleOnError = (error, file, inputElem, reason) => {
    window.alert('เกิดข้อผิดพลาดในการอัพโหลดไฟล์', reason);
    console.error('Error on File Upload', error);
  };

  const handleOnRemoveFile = (data) => {
    console.log('On Remove FIle data', data);
  };

  const handleRemoveFile = (event) => {
    if (buttonRef.current) {
      buttonRef.current.removeFile(event);
    }
  };

  const handleOpenDialog = (event) => {
    if (buttonRef.current) {
      buttonRef.current.open(event);
    }
  };

  return (
    <div>
      <CSVReader
        ref={buttonRef}
        onFileLoad={handleOnFileLoad}
        onError={handleOnError}
        noClick
        noDrag
        onRemoveFile={handleOnRemoveFile}
      >
        {({ file }) => (
          <div>
            {!file && (
              <Button
                type="button"
                onClick={handleOpenDialog}
                variant="contained"
                color="primary"
              >
                เลือกไฟล์
              </Button>
            )}
            <div className="flex gap-2">
              <div className="self-center">
                <p>{file && file?.name} </p>
              </div>
              {file && (
                <Button type="button" onClick={handleRemoveFile} color="error">
                  ลบ
                </Button>
              )}
            </div>
          </div>
        )}
      </CSVReader>
    </div>
  );
};

CSVUpload.propTypes = {
  setCsvData: PropTypes.func,
};

export default CSVUpload;
