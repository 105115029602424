import React, { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import graphqlClient from '@/configs/graphqlClient';
import { MANUFACTURING_TRANSACTION_TYPE } from '@/utils/constants';

import MultiUnitProdOrMatBox from '../Rendering/MultiUnitProdOrMatBox';

/**
 * @function ReportMaterialUsageTableRowElement
 * @description Display a collections or a list of ReportMaterialUsageTableRowElement from database
 */

export default function ReportMaterialUsageTableRowElement({
  material,
  startDate,
  endDate,
  index,
  allDataList,
  setAllDataList = () => {},
}) {
  const [manufacMaterialData, setManufacMaterialData] = useState();
  const [isError, setIsError] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const query = gql`
    query FindReportMaterialUsageTableRowElement(
      $manufacturingMaterialInput: ManufacturingMaterialInput
    ) {
      findManufacturingMaterials(input: $manufacturingMaterialInput) {
        analyzed {
          _id
          count
          sum
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        manufacturingMaterialInput: {
          material: material?._id,
          startDate,
          endDate,
        },
      });
      const manufacturingMaterialData = queryResult?.findManufacturingMaterials;
      setManufacMaterialData(manufacturingMaterialData);
      setIsReady(true);

      // eslint-disable-next-line no-param-reassign
      allDataList[index] = {
        material,
        analyzed: manufacturingMaterialData?.analyzed,
      };
      setAllDataList(allDataList);
    } catch (error) {
      console.error(error);
      setIsError(true);
    }
  };

  useEffect(() => {
    setIsReady(false);
    queryDataFromServer();
    return () => {};
  }, [startDate, endDate, material]);

  if (isError) {
    return <div>ไม่สามารถดึงข้อมูลได้</div>;
  }

  if (!isReady) {
    return (
      <div>
        <Skeleton />
      </div>
    );
  }

  return (
    <div>
      <div className="flex  flex-wrap">
        {_.map(
          _.orderBy(manufacMaterialData?.analyzed, ['_id'], ['asc']),
          (eachAnalyzed, eachAnalyzedIndex) => (
            <div key={eachAnalyzedIndex} className="lg:w-1/4 px-2">
              <div className="font-semibold">
                {MANUFACTURING_TRANSACTION_TYPE[eachAnalyzed?._id]?.description}
              </div>
              <div>
                <MultiUnitProdOrMatBox
                  foundMaterial={material}
                  quantity={eachAnalyzed?.sum}
                />
              </div>
            </div>
          ),
        )}
      </div>
      {_.isEmpty(manufacMaterialData?.analyzed) && (
        <div>ไม่มีข้อมูลการใช้วัตถุดิบ</div>
      )}
    </div>
  );
}

ReportMaterialUsageTableRowElement.propTypes = {
  material: PropTypes.object,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  index: PropTypes.number,
  allDataList: PropTypes.arrayOf(PropTypes.object),
  setAllDataList: PropTypes.func,
};
