import { BaseAction } from '../../class';
import {
  COST_ESTIMATION_PROFILE_ALL,
  COST_ESTIMATION_PROFILE_DELETE,
  COST_ESTIMATION_PROFILE_ERROR,
  COST_ESTIMATION_PROFILE_GET,
  COST_ESTIMATION_PROFILE_LOADING,
  COST_ESTIMATION_PROFILE_POST,
  COST_ESTIMATION_PROFILE_PUT,
} from '../types';

const costEstimationProfileAction = new BaseAction('cost-estimation-profile', {
  allConst: COST_ESTIMATION_PROFILE_ALL,
  getConst: COST_ESTIMATION_PROFILE_GET,
  postConst: COST_ESTIMATION_PROFILE_POST,
  putConst: COST_ESTIMATION_PROFILE_PUT,
  deleteConst: COST_ESTIMATION_PROFILE_DELETE,
  errorConst: COST_ESTIMATION_PROFILE_ERROR,
  loadingConst: COST_ESTIMATION_PROFILE_LOADING,
});

export const costEstimationProfileCreate = (data) =>
  costEstimationProfileAction.create(data);
export const costEstimationProfileAll = ({ name, page, size, ...query }) =>
  costEstimationProfileAction.getAllData({ name, page, size, ...query });
export const costEstimationProfileGet = (id) =>
  costEstimationProfileAction.getOneData(id);
export const costEstimationProfilePut = (id, data) =>
  costEstimationProfileAction.editOne(id, data);
export const costEstimationProfileDelete = (id) =>
  costEstimationProfileAction.deleteOne(id);
export const costEstimationProfileStateSet = (data) =>
  costEstimationProfileAction.stateSet(data);
export const costEstimationProfileStateOneSet = (data) =>
  costEstimationProfileAction.stateOneSet(data);
export const costEstimationProfileReset = () =>
  costEstimationProfileAction.reset();
export const costEstimationProfileError = () =>
  costEstimationProfileAction.onError();
