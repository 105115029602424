import dayjs from 'dayjs';
import _ from 'lodash';
import { CAPITAL_COST_TYPE } from '@iarcpsu/emanufac-constant';
import { findAmountOfWorkDayFromInformation } from './findAmountOfWorkDay';

const getDataFrontend = async ({ currentDate, api, maxFetchSize }) => {
  const searchParams = new URLSearchParams({
    page: 1,
    size: maxFetchSize,
    startDate: dayjs(currentDate).format('YYYY-MM-DD'),
    endDate: dayjs(currentDate).format('YYYY-MM-DD'),
  });

  const { data } = await api.get(
    `${
      process.env.REACT_APP_API_URL
    }/factory-capital-cost?${searchParams.toString()}`,
  );
  return data;
};

const getFactoryCapitalCostInDay = async ({
  currentDate,
  isFrontend = true,
  axios: api,
  maxFetchSize = 10000,
  information,
  enableElement,
}) => {
  try {
    let data = [];

    if (isFrontend) {
      data = await getDataFrontend({
        currentDate,
        api,
        maxFetchSize,
      });
    }

    // TODO: Create get data from backend

    const enableElementInStatusCode = _.reduce(
      enableElement,
      (result, value, key) => {
        if (value) {
          const foundCapitalCostType = _.find(
            CAPITAL_COST_TYPE,
            (each) => each?.settingKey === key,
          );
          // console.log(
          //   'Key',
          //   key,
          //   ' Found Capital Cost Type',
          //   foundCapitalCostType,
          // );
          result.push(foundCapitalCostType?.status_code);
        }
        return result;
      },
      [],
    );
    // console.log('Enable Element In Status Code', enableElementInStatusCode);

    // Filter data by enable cost type
    const filteredData = _.filter(data.rows, (each) =>
      _.includes(enableElementInStatusCode, each?.cost_type),
    );

    // console.log('Filter  Data', filteredData);

    // Looping inside data to get total cost for our day
    const costList = _.map(filteredData, (each) => {
      let eachCost = 0;
      // is same day return cost
      if (
        dayjs(currentDate).isSame(dayjs(each?.start_date), 'date') &&
        dayjs(currentDate).isSame(dayjs(each?.end_date), 'date')
      ) {
        eachCost = each?.cost;
      } else {
        // Else divide cost by amount of day
        const startDay = dayjs(each?.start_date);
        const endDay = dayjs(each?.end_date);
        const totalAmountOfDay = findAmountOfWorkDayFromInformation({
          information,
          startPeriod: startDay,
          endPeriod: endDay,
        });

        // console.log(
        //   `Total Cost ${each?.cost} valid from ${dayjs(startDay).format(
        //     'D MMM',
        //   )} to ${dayjs(endDay).format('D MMM')} Amount ${totalAmountOfDay}`,
        // );
        eachCost = (each?.cost || 0) / totalAmountOfDay;
      }

      return eachCost;
    });

    return _.sum(costList);
  } catch (error) {
    console.error('Cannot Get Place Labor Cost In Day', error);
    return 0;
  }
};

export default getFactoryCapitalCostInDay;
