/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Button,
  Card,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import thLocale from 'date-fns/locale/th';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { MultiUnitProdOrMatBox } from '@iarcpsu/emanufac-components/src/components/Box';
import { Error } from '@iarcpsu/emanufac-components/src/components/Error';
import Loading from '@iarcpsu/emanufac-components/src/components/Loading';
import {
  DetailProductHTypeAddReport,
  DetailProductHTypeDescReport,
  ProductHReport,
} from '@iarcpsu/emanufac-components/src/components/Report/index';
import { ViewTitle } from '@iarcpsu/emanufac-components/src/components/ViewTitle';
import api from '../../configs/api';
import * as actions from '../../redux/actions';

function ProductHistory({ title, subtitle }) {
  const dispatch = useDispatch();
  const productTransaction = useSelector((state) => state.productTransaction);
  const productTransactionType = useSelector(
    (state) => state.productTransactionType,
  );
  const information = useSelector((state) => state.information);
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [month, setMonth] = useState(new Date());
  const [monthChang, setMonthChang] = useState();
  const history = useHistory();
  const { control } = useForm();
  const [selectType, setSelectType] = useState('');

  const {
    setting: { wms: wmsSetting },
  } = information;

  useEffect(() => {
    if (monthChang) {
      dispatch(
        actions.productTransactionSearch({
          productName: name,
          page,
          size,
          month: new Date(month).toISOString().split('T')[0],
          selectType,
        }),
      );
    } else {
      dispatch(
        actions.productTransactionAll({
          productName: name,
          page,
          size,
          selectType,
        }),
      );
    }
    dispatch(actions.productTransactionTypeAll(''));
    dispatch(actions.informationAll());
    return () => {};
  }, [name, page, size, selectType, monthChang]);

  useEffect(() => {
    setTotal(productTransaction?.total);
    return () => {};
  }, [productTransaction]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  // const handleSeachProductTransaction = () => {
  //   dispatch(
  //     actions.productTransactionSearch({
  //       productName: name,
  //       page,
  //       size,
  //       month: new Date(month).toISOString().split('T')[0],
  //       selectType,
  //     }),
  //   );
  // };

  // useEffect(() => {
  //   handleSeachProductTransaction();
  //   return () => {};
  // }, [monthChang]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handlePushToDetailProductTransaction = async (id) => {
    history.push(`/wms/history/detail/${id}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleSelectType = (type) => {
    setSelectType(type);
  };

  const handleProductTransactionAll = async () => {
    try {
      if (monthChang || name || selectType) {
        const { data: productTransactionNew } = await api.get(
          `${
            process.env.REACT_APP_API_URL
          }/product-transaction?productName=${name}&month=${
            new Date(monthChang).toISOString().split('T')[0]
          }&selectType=${selectType}`,
        );
        return productTransactionNew;
      }
      const { data: productTransactionNew } = await api.get(
        `${process.env.REACT_APP_API_URL}/product-transaction`,
      );
      return productTransactionNew;
    } catch (error) {
      console.error(error);
    }
  };

  const handlePrintPdf = async () => {
    const productTransactionNew = await handleProductTransactionAll();
    try {
      await ProductHReport(
        productTransactionNew?.rows,
        information,
        monthChang,
      );
    } catch (error) {
      await ProductHReport(
        productTransactionNew?.rows,
        information,
        monthChang,
        true,
      );
    }
  };

  const handlePrintPdfTypeDesc = async (row) => {
    try {
      await DetailProductHTypeDescReport(row, information);
    } catch (error) {
      await DetailProductHTypeDescReport(row, information, true);
    }
  };

  const handlePrintPdfTypeAdd = async (row) => {
    try {
      await DetailProductHTypeAddReport(row, information);
    } catch (error) {
      await DetailProductHTypeAddReport(row, information, true);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4 gap-2">
      <Button variant="contained" color="teal" onClick={() => handlePrintPdf()}>
        พิมพ์
      </Button>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row gap-2">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/2">
          <Controller
            name={'status_code'}
            control={control}
            defaultValue={''}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                <InputLabel id="type" size={'small'}>
                  ค้นหาด้วยประเภท
                </InputLabel>
                <Select
                  {...field}
                  label="ค้นหาด้วยประเภท"
                  size={'small'}
                  fullWidth
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    handleSelectType(e.target.value);
                  }}
                >
                  <MenuItem value="">ทั้งหมด</MenuItem>
                  {_.map(productTransactionType.rows, (_type) => (
                    <MenuItem value={_type?._id} key={_type?._id}>
                      {_type?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </div>
        <div className="w-full  md:w-5/12 md:pl-1 my-2 md:my-0">
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={thLocale}>
            <DatePicker
              views={['year', 'month']}
              label="เดือน"
              minDate={new Date('2012-03-01')}
              maxDate={new Date('2030-06-01')}
              value={month}
              onChange={(newValue) => {
                setMonth(newValue);
                setMonthChang(newValue);
              }}
              renderInput={(params) => (
                <TextField fullWidth {...params} required size={'small'} />
              )}
            />
          </LocalizationProvider>
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <colgroup>
              <col width="5%" />
              <col width="12%" />
              <col width="5%" />
              {wmsSetting?.display?.lot_number && <col width="10%" />}
              <col width="5%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="15%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> วันที่ดำเนินการ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ประเภท</div>
                </TableCell>
                {wmsSetting?.display?.lot_number && (
                  <TableCell>
                    <div className="font-bold"> หมายเลขล็อต</div>
                  </TableCell>
                )}
                <TableCell>
                  <div className="font-bold"> เลขที่สั่งผลิต</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อคลัง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> สินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> จำนวน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> คงเหลือ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ผู้ดำเนินการ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> หมายเหตุ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> รายละเอียด</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(productTransaction.rows) ? (
                productTransaction.rows.map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      {dayjs(row?.createdAt).locale('th').format('DD MMM BBBB')}
                      <p>
                        เวลา {new Date(row?.createdAt).toLocaleTimeString('th')}{' '}
                        น.
                      </p>
                    </TableCell>
                    <TableCell>
                      {row?.product_transaction_type?.direction === 'add' ? (
                        <div className="bg-gray-100 p-1 rounded-md text-center">
                          <p className="text-green-600 ">{`${row?.product_transaction_type?.name}`}</p>
                        </div>
                      ) : (
                        <div className="bg-gray-100 p-1 rounded-md text-center">
                          <p className="text-red-600">{`${row?.product_transaction_type?.name}`}</p>
                        </div>
                      )}
                    </TableCell>
                    {wmsSetting?.display?.lot_number && (
                      <TableCell>
                        <div>
                          {_.map(row?.product_stock_lot, (_product, _index) => (
                            <div key={_index}>
                              {_product?.lotId?.lot_number || '-'}
                            </div>
                          ))}
                        </div>
                      </TableCell>
                    )}{' '}
                    <TableCell>
                      {row?.product_stock_lot[0]?.lotId?.production_number
                        ?.running_number &&
                      row?.product_transaction_type?.direction === 'add' ? (
                        <b>
                          {`${row?.product_stock_lot[0]?.lotId?.production_number?.prefix}${row?.product_stock_lot[0]?.lotId?.production_number?.running_number}`}
                        </b>
                      ) : (
                        <p>-</p>
                      )}
                    </TableCell>
                    <TableCell className="whitespace-nowrap">
                      {_.map(row?.product_stock_lot, (lot, _index) => (
                        <div
                          key={_index}
                          className="text-blue-500 p-1 bg-gray-100 text-center rounded-md mb-1"
                        >
                          {lot?.lotId?.warehouse?.name}
                        </div>
                      ))}
                    </TableCell>
                    <TableCell className="whitespace-nowrap">
                      {_.map(row?.product_stock_lot, (_product, _index) => (
                        <Link
                          key={_index}
                          to={`/wms/product-stock-lot/${_product?.lotId?._id}`}
                        >
                          <span>{_index + 1}. </span>{' '}
                          <span className="font-bold font-display">
                            {_product?.lotId?.product?.type_code}{' '}
                          </span>
                          {_product?.lotId?.product?.name}
                        </Link>
                      ))}
                    </TableCell>
                    <TableCell className="whitespace-nowrap">
                      {_.map(row?.product_stock_lot, (_product, _index) => (
                        <p key={_index} className="my-1">
                          <MultiUnitProdOrMatBox
                            foundMaterial={_product?.lotId?.product}
                            quantity={_product?.quantity}
                          />
                        </p>
                      ))}
                    </TableCell>
                    <TableCell className="whitespace-nowrap">
                      {' '}
                      {_.map(row?.product_stock_lot, (_product, _index) => (
                        <p key={_index} className="my-1">
                          <MultiUnitProdOrMatBox
                            foundMaterial={_product?.lotId?.product}
                            quantity={_product?.amount}
                          />
                        </p>
                      ))}
                    </TableCell>
                    <TableCell>
                      {row?.product_transaction_type?.direction === 'add' ? (
                        <div>
                          <b>{`${row?.product_stock_lot[0]?.lotId?.consignee_name}`}</b>
                        </div>
                      ) : (
                        <div>
                          <b>{`${row?.seller}`}</b>
                        </div>
                      )}
                    </TableCell>
                    <TableCell>{row?.remark}</TableCell>
                    <TableCell>
                      <div className="flex flex-wrap ">
                        <div className="mr-2 mb-2">
                          <Button
                            variant="contained"
                            color={'info'}
                            size={'small'}
                            onClick={() => {
                              handlePushToDetailProductTransaction(row._id);
                            }}
                          >
                            รายละเอียด
                          </Button>
                        </div>
                        <div className="mb-2">
                          <Button
                            variant="contained"
                            color={'teal'}
                            size={'small'}
                            onClick={() => {
                              if (
                                row?.product_transaction_type?.direction ===
                                'desc'
                              ) {
                                handlePrintPdfTypeDesc(row);
                              } else {
                                handlePrintPdfTypeAdd(row);
                              }
                            }}
                          >
                            พิมพ์
                          </Button>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (productTransaction.isLoading) {
    return <Loading />;
  }
  if (!productTransaction.isLoading && productTransaction.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButtom()}</div>
        </div>{' '}
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error message={productTransaction?.message} />;
}

ProductHistory.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ProductHistory.defaultProps = {
  title: '',
  subtitle: '',
};

export default ProductHistory;
