import React from 'react';
import { Autocomplete, Button, TextField } from '@mui/material';
import { Controller, useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';

const ProcessCustomerAddForm = ({
  control,
  customer,
  reload,
  setReload,
  index,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `processes[${index}].other_customers`,
  });

  return (
    <div className="flex w-full flex-wrap">
      <div className="w-full py-1 px-2">
        <Controller
          name={`processes[${index}].customer`}
          control={control}
          defaultValue={null}
          render={({ field }) => (
            <Autocomplete
              size={'small'}
              {...field}
              onChange={(e, newValue) => {
                field.onChange(newValue);
                setReload(!reload);
              }}
              options={customer?.rows}
              // prettier-ignore
              getOptionLabel={(option) => `${option?.type_code} | ${option?.name}`
                        }
              required
              renderInput={(params) => (
                <TextField {...params} label="เลือกลูกค้า" />
              )}
            />
          )}
        />
      </div>
      <div className="w-full py-1 px-2">
        {_.map(fields, (eachField, fieldIndex) => (
          <div className="w-full flex py-1" key={eachField?.id}>
            <div className="w-10/12 px-1">
              <Controller
                name={`processes[${index}].other_customers[${fieldIndex}]`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <Autocomplete
                    size={'small'}
                    {...field}
                    onChange={(e, newValue) => {
                      field.onChange(newValue);
                      setReload(!reload);
                    }}
                    options={customer?.rows}
                    // prettier-ignore
                    getOptionLabel={(option) => `${option?.type_code} | ${option?.name}`
                        }
                    required
                    renderInput={(params) => (
                      <TextField {...params} label="เลือกลูกค้า" />
                    )}
                  />
                )}
              />
            </div>
            <div className="w-2/12 px-1">
              <Button
                size="small"
                color="error"
                variant="contained"
                onClick={() => remove(fieldIndex)}
              >
                ลบ
              </Button>
            </div>
          </div>
        ))}
        <Button size="small" variant="outlined" onClick={() => append({})}>
          เพิ่มลูกค้าคนอื่น
        </Button>
      </div>
    </div>
  );
};

ProcessCustomerAddForm.propTypes = {
  control: PropTypes.object,
  customer: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  reload: PropTypes.bool,
  setReload: PropTypes.func,
  index: PropTypes.number,
};

export default ProcessCustomerAddForm;
