import React from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import PropTypes from 'prop-types';

const CheckAndDescribeInput = ({
  control,
  measurementType,
  measurementControllerName = '',
  watch,
}) => (
  <div>
    <div>
      <Controller
        control={control}
        name={`${measurementControllerName}.boolean_value`}
        render={({ field }) => (
          <FormControlLabel
            label={measurementType?.label}
            control={<Checkbox {...field} />}
          />
        )}
      />
      {watch(`${measurementControllerName}.boolean_value`) && (
        <Controller
          control={control}
          name={`${measurementControllerName}.additional_value`}
          render={({ field }) => <TextField {...field} multiline rows={3} />}
        />
      )}
    </div>
  </div>
);

CheckAndDescribeInput.propTypes = {
  control: PropTypes.object,
  measurementType: PropTypes.object,
  measurementControllerName: PropTypes.string,
  watch: PropTypes.func,
};

export default CheckAndDescribeInput;
