import React from 'react';
import { useHistory } from 'react-router-dom';
import { MATERIAL_STOCK_STATUS } from '@iarcpsu/emanufac-constant';
import {
  Autocomplete,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

const ProductStockFilterBox = ({
  warehouse,
  customer,
  product,
  selectedWarehouse,
  setSelectedWarehouse = () => {},
  selectedCustomer,
  setSelectedCustomer = () => {},
  selectedProduct,
  setSelectedProduct = () => {},
  selectedStockStatus,
  setSelectedStockStatus = () => {},
  allowAddToSeachQuery,
  anotherQuery = '?',
  showSupplierFilter = true,
}) => {
  const history = useHistory();
  return (
    <Card>
      <CardContent>
        <div className="mb-2 font-semibold">กรองตามรายการต่างๆ</div>
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 lg:w-1/4 px-1">
            <div>
              {!_.isEmpty(warehouse?.rows) && (
                <Autocomplete
                  disablePortal
                  options={warehouse?.rows}
                  size="small"
                  placeholder="คลังสินค้า"
                  value={selectedWarehouse}
                  onChange={(e, newValue) => {
                    setSelectedWarehouse(newValue);
                    if (allowAddToSeachQuery) {
                      history.push(
                        `${anotherQuery}&warehouse=${
                          newValue?._id || ''
                        }&product=${selectedProduct?._id || ''}&customer=${
                          selectedCustomer?._id || ''
                        }&stockStatus=${selectedStockStatus || ''}`,
                      );
                    }
                  }}
                  className="z-40"
                  // prettier-ignore
                  getOptionLabel={(option) => `${option?.name || ''}`
            }
                  renderInput={(params) => (
                    <TextField label="คลังสินค้า" {...params} />
                  )}
                />
              )}
            </div>
          </div>
          {showSupplierFilter && (
            <div className="w-full md:w-1/2 lg:w-1/4 px-1">
              <div>
                {!_.isEmpty(customer?.rows) && (
                  <Autocomplete
                    disablePortal
                    options={customer?.rows}
                    size="small"
                    placeholder="เลือกลูกค้า"
                    onChange={(e, newValue) => {
                      setSelectedCustomer(newValue);
                      if (allowAddToSeachQuery) {
                        history.push(
                          `${anotherQuery}&warehouse=${
                            selectedWarehouse?._id || ''
                          }&product=${selectedProduct?._id || ''}&customer=${
                            newValue?._id || ''
                          }&stockStatus=${selectedStockStatus || ''}`,
                        );
                      }
                    }}
                    value={selectedCustomer}
                    className="z-40"
                    // prettier-ignore
                    getOptionLabel={(option) => `${option?.name || ''}`
            }
                    renderInput={(params) => (
                      <TextField label="เลือกลูกค้า" {...params} />
                    )}
                  />
                )}
              </div>
            </div>
          )}
          <div className="w-full md:w-1/2 lg:w-1/4 px-1">
            <div>
              {!_.isEmpty(product?.rows) && (
                <Autocomplete
                  disablePortal
                  options={product?.rows}
                  size="small"
                  placeholder="เลือกสินค้า"
                  onChange={(e, newValue) => {
                    setSelectedProduct(newValue);
                    if (allowAddToSeachQuery) {
                      history.push(
                        `${anotherQuery}&warehouse=${
                          selectedWarehouse?._id || ''
                        }&product=${newValue?._id || ''}&customer=${
                          selectedCustomer?._id || ''
                        }&stockStatus=${selectedStockStatus || ''}`,
                      );
                    }
                  }}
                  value={selectedProduct}
                  className="z-40"
                  // prettier-ignore
                  getOptionLabel={(option) => `${option?.type_code || ''} ${option?.name || ''}`
            }
                  renderInput={(params) => (
                    <TextField label="เลือกสินค้า" {...params} />
                  )}
                />
              )}
            </div>
          </div>

          <div className="w-full md:w-1/2 lg:w-1/4 px-1 ">
            <FormControl fullWidth size="small">
              <InputLabel>สถานะ</InputLabel>
              <Select
                label="สถานะ"
                size="small"
                value={selectedStockStatus}
                onChange={(event) => {
                  setSelectedStockStatus(event.target.value);
                  if (allowAddToSeachQuery) {
                    history.push(
                      `${anotherQuery}&warehouse=${
                        selectedWarehouse?._id || ''
                      }&product=${selectedProduct?._id || ''}&customer=${
                        selectedCustomer?._id || ''
                      }&stockStatus=${event.target.value || ''}`,
                    );
                  }
                }}
              >
                <MenuItem value={null}>ทุกสถานะ</MenuItem>
                {_.map(MATERIAL_STOCK_STATUS, (status, key) => (
                  <MenuItem key={key} value={status.status_code}>
                    {status.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ProductStockFilterBox;

ProductStockFilterBox.propTypes = {
  warehouse: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  customer: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  product: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  selectedWarehouse: PropTypes.object,
  setSelectedWarehouse: PropTypes.func,
  selectedCustomer: PropTypes.object,
  setSelectedCustomer: PropTypes.func,
  selectedProduct: PropTypes.object,
  setSelectedProduct: PropTypes.func,
  selectedStockStatus: PropTypes.string,
  setSelectedStockStatus: PropTypes.func,
  allowAddToSeachQuery: PropTypes.bool,
  anotherQuery: PropTypes.string,
  showSupplierFilter: PropTypes.bool,
};
