import React from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { currencyFormatter } from '../../../utils/functions';

const MetalDivideForm = ({ control, customer, watch, index = 1 }) => (
  <div>
    <div className="">
      <Controller
        name={`divide_sets[${index}].step`}
        control={control}
        render={({ field }) => (
          <input size="small" {...field} value={index} type="hidden" />
        )}
      />
    </div>
    <div className="flex flex-wrap w-full">
      <div className="w-full px-1 py-2 lg:w-1/3">
        <Controller
          name={`divide_sets[${index}].length`}
          control={control}
          render={({ field }) => (
            <TextField
              size="small"
              {...field}
              label="ขนาด (เมตร)"
              fullWidth
              required
            />
          )}
        />
      </div>

      <div className="w-full px-1 py-2 lg:w-1/3">
        <Controller
          name={`divide_sets[${index}].amount`}
          control={control}
          render={({ field }) => (
            <TextField
              size="small"
              {...field}
              label="จำนวน/คู่"
              fullWidth
              required
            />
          )}
        />
      </div>

      <div className="w-full px-1 py-2 lg:w-1/3 flex">
        <div className="w-1/3 px-1">รวมความยาว</div>
        <div className="w-1/3 px-1 text-lg font-semibold">
          {currencyFormatter.format(
            parseFloat(watch(`divide_sets[${index}].length`)) *
              parseFloat(watch(`divide_sets[${index}].amount`)),
          )}{' '}
        </div>
        <div className="w-1/3 px-1">เมตร</div>
      </div>
      <div className="w-full px-1 py-2 lg:w-1/2">
        <Controller
          name={`divide_sets[${index}].so_number`}
          control={control}
          render={({ field }) => (
            <TextField size="small" {...field} label="เลขที่ So" fullWidth />
          )}
        />
      </div>
      <div className="w-full px-1 py-2 lg:w-1/2">
        {!_.isEmpty(customer?.rows) && (
          <Controller
            name={`divide_sets[${index}].customer`}
            control={control}
            render={({ field }) => (
              <Autocomplete
                size={'small'}
                {...field}
                onChange={(e, newValue) => {
                  field.onChange(newValue);
                }}
                defaultValue={field.value}
                options={customer?.rows}
                // prettier-ignore
                getOptionLabel={(option) => `${option?.type_code || ''} | ${option?.name}`
                        }
                renderInput={(params) => (
                  <TextField {...params} label="เลือกลูกค้า" fullWidth />
                )}
              />
            )}
          />
        )}{' '}
      </div>

      <div className="w-full px-1 py-2 ">
        <Controller
          name={`divide_sets[${index}].remark`}
          control={control}
          render={({ field }) => (
            <TextField
              size="small"
              {...field}
              label="หมายเหตุ"
              rows={2}
              fullWidth
              multiline
            />
          )}
        />
      </div>
    </div>
  </div>
);

MetalDivideForm.propTypes = {
  control: PropTypes.object,
  customer: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  setValue: PropTypes.func,
  watch: PropTypes.func,
  index: PropTypes.number,
};

export default MetalDivideForm;
