import React from 'react';
import { Card, CardContent } from '@mui/material';
import { ChevronUp, ChevronDown } from 'react-feather';
import PropTypes from 'prop-types';

import MultiUnitProdOrMatBox from '../Rendering/MultiUnitProdOrMatBox';

const MaterialBomCompareBox = ({ eachAnalyze }) => (
  <Card className="h-full">
    <CardContent>
      <div className="font-semibold font-display">
        {eachAnalyze?.material?.type_code || ''} {eachAnalyze?.material?.name}{' '}
      </div>
      <div className="flex justify-between my-2">
        <div className="w-1/3">
          <div className="text-xs font-display font-semibold">สูตร </div>
          <div>
            <MultiUnitProdOrMatBox
              foundMaterial={eachAnalyze?.material}
              quantity={eachAnalyze?.totalPrefer}
            />
          </div>
        </div>{' '}
        <div className="w-1/3">
          <div className="text-xs font-display  font-semibold">เบิก </div>
          <div>
            <MultiUnitProdOrMatBox
              foundMaterial={eachAnalyze?.material}
              quantity={eachAnalyze?.totalDeposit}
            />
          </div>
        </div>
        <div className="w-1/3">
          <div className="text-xs font-display  font-semibold">ใช้จริง </div>
          <div>
            <MultiUnitProdOrMatBox
              foundMaterial={eachAnalyze?.material}
              quantity={eachAnalyze?.totalActual}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        {eachAnalyze?.difference !== 0 && (
          <div
            className={`my-1 text-sm font-semibold text-center font-display flex gap-2 ${
              eachAnalyze?.difference > 0 ? 'text-red-500' : ''
            } ${eachAnalyze?.difference < 0 ? 'text-yellow-400' : ''}`}
          >
            {eachAnalyze?.difference > 0 && 'ใช้จริงมากกว่าสูตร'}
            {eachAnalyze?.difference < 0 && 'ใช้จริงน้อยกว่าสูตร'}
            <MultiUnitProdOrMatBox
              foundMaterial={eachAnalyze?.material}
              quantity={eachAnalyze?.difference}
              noWrap
            />
            {eachAnalyze?.difference > 0 && (
              <ChevronUp size="16" className="self-center text-red-500" />
            )}
            {eachAnalyze?.difference < 0 && (
              <ChevronDown size="16" className="self-center text-yellow-400" />
            )}
          </div>
        )}
      </div>
    </CardContent>
  </Card>
);

MaterialBomCompareBox.propTypes = { eachAnalyze: PropTypes.object };

export default MaterialBomCompareBox;
