import {
  MMS_DASHBOARD_ALL,
  MMS_DASHBOARD_DELETE,
  MMS_DASHBOARD_ERROR,
  MMS_DASHBOARD_GET,
  MMS_DASHBOARD_LOADING,
  MMS_DASHBOARD_POST,
  MMS_DASHBOARD_PUT,
} from '../../actions/types';

const initialState = {
  mmsDashboard: null,
  isLoading: true,
  isCompleted: true,
};

export default function MMSDashboardReducer(state = initialState, action) {
  switch (action.type) {
    case MMS_DASHBOARD_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MMS_DASHBOARD_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MMS_DASHBOARD_POST:
      return { isLoading: false, isCompleted: true };
    case MMS_DASHBOARD_PUT:
      return { isLoading: false, isCompleted: true };
    case MMS_DASHBOARD_DELETE:
      return { isLoading: false, isCompleted: true };
    case MMS_DASHBOARD_LOADING:
      return { isLoading: true, isCompleted: true };
    case MMS_DASHBOARD_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
