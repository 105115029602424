import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  BackButton,
  CostProfileRendering,
  EmployeeTimeDrivenBox,
  Error,
  Loading,
  LoadingLinear,
  MachineTimeDrivenBox,
  MaterialLotCostDriverBox,
  MaterialStockLotTimeline,
  MultiUnitProdOrMatBox,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Box, Card, CardContent, Tab, Tabs } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { api, config } from '@/configs';
import * as actions from '@/redux/actions';

/**
 * @function CostDriverDisplay
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function MaterialCostDriverDisplay({ title, subtitle }) {
  const dispatch = useDispatch();
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const costEstimationProfile = useSelector(
    (state) => state.costEstimationProfile,
  );
  const information = useSelector((state) => state.information);
  const params = useParams();
  const [previousLotList, setPreviousLotList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  // Test
  const queryDataFromServer = async () => {
    try {
      dispatch(actions.materialStockLotGet(params.id));
      dispatch(
        actions.costEstimationProfileAll({
          page: 1,
          size: config.maxFetchSize,
        }),
      );
    } catch (error) {
      dispatch(actions.materialStockLotError());
    }
  };

  const getPreviousLots = async () => {
    try {
      setIsLoading(true);
      const { data } = await api.get(
        `${process.env.REACT_APP_API_URL}/material-stock-lot/with-previous-lot/${params.id}`,
      );
      const tempPreviousLot = data?.rows;
      const uniqueLots = _.uniqBy(tempPreviousLot, '_id');
      const tempOrderedLot = _.orderBy(uniqueLots, 'createdAt');
      setPreviousLotList(tempOrderedLot);
    } catch (error) {
      console.error('Cannot Get Previous Lot', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    getPreviousLots();
    return () => {};
  }, [params]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const defaultTDABCCost = (
    <div>
      <div className="my-2">
        <Card>
          <CardContent>
            <EmployeeTimeDrivenBox
              defaultSplittedParent={false}
              previousLotList={previousLotList}
            />
          </CardContent>{' '}
        </Card>
      </div>{' '}
      <div className="my-2">
        <Card>
          <CardContent>
            <MachineTimeDrivenBox
              previousLotList={previousLotList}
              defaultSplittedParent={false}
            />
          </CardContent>{' '}
        </Card>
      </div>
      <div className="my-2">
        <Card>
          <CardContent>
            <MaterialLotCostDriverBox previousLotList={previousLotList} />
          </CardContent>{' '}
        </Card>
      </div>
    </div>
  );

  const costEstimationProfileUse = (
    <div>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: 'background.paper',
          width: '100%',
        }}
      >
        <Tabs
          value={currentTab}
          onChange={(e, newValue) => setCurrentTab(parseInt(newValue, 10))}
        >
          {_.map(
            _.orderBy(costEstimationProfile?.rows, ['createdAt'], ['asc']),
            (row, index) => (
              <Tab label={row.name} key={index} value={index} />
            ),
          )}
        </Tabs>
      </Box>

      <CostProfileRendering
        costEstimatioProfile={
          _.orderBy(costEstimationProfile?.rows, ['createdAt'], ['asc'])?.[
            currentTab
          ]
        }
        previousLotList={previousLotList}
        currentLot={materialStockLot}
        isCurrentAsProductLot={false}
      />
    </div>
  );

  if (materialStockLot.isLoading) {
    return <Loading />;
  }

  if (!materialStockLot.isLoading && materialStockLot.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <div>
            {isLoading ? (
              <LoadingLinear />
            ) : (
              <div>
                <div className="flex flex-wrap ">
                  <div className="w-full md:w-1/2 lg:w-1/3 pr-2">
                    <Card>
                      <CardContent>
                        <MaterialStockLotTimeline
                          previousLots={previousLotList}
                        />
                      </CardContent>
                    </Card>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-2/3">
                    <div className="mb-2">
                      <Card>
                        <CardContent>
                          <h3 className="font-semibold">วัตถุดิบปัจจุบัน</h3>
                          <div className="my-2 text-xl">
                            {materialStockLot?.material?.type_code}{' '}
                            {materialStockLot.material?.name}
                          </div>
                          <div className="flex justify-between flex-wrap my-2">
                            <div className="w-full md:w-1/2 lg:w-1/4">
                              <div>ปริมาณทั้งหมด</div>
                              <MultiUnitProdOrMatBox
                                foundMaterial={materialStockLot?.material}
                                quantity={materialStockLot?.quantity}
                              />
                            </div>
                            <div className="w-full md:w-1/2 lg:w-1/4">
                              <div>คงเหลือ</div>
                              <MultiUnitProdOrMatBox
                                foundMaterial={materialStockLot?.material}
                                quantity={materialStockLot?.amount}
                              />
                            </div>
                          </div>
                        </CardContent>{' '}
                      </Card>
                    </div>

                    {information?.setting?.ims?.cost_estimation
                      ?.use_cost_estimation_profile
                      ? costEstimationProfileUse
                      : defaultTDABCCost}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  return <Error />;
}

MaterialCostDriverDisplay.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MaterialCostDriverDisplay.defaultProps = {
  title: '',
  subtitle: '',
};
