import React from 'react';
import PropTypes from 'prop-types';

import MeasurementDisplayRenderingComponent from '../../Measurement/MeasurementDisplayRenderingComponent';

const OneMeasurementVariableBox = ({ measurement }) => (
  <div className="w-1/2 md:w-1/4 lg:w-1/6 min-w-36 p-2">
    <div className="border rounded-md p-2 shadow-md lg:h-36 ">
      <div className="font-semibold self-center my-1 px-1">
        {measurement?.measurement_type?.name}
      </div>
      <div className="flex p-2">
        <MeasurementDisplayRenderingComponent measurement={measurement} />
      </div>
    </div>
  </div>
);

OneMeasurementVariableBox.propTypes = { measurement: PropTypes.object };

export default OneMeasurementVariableBox;
