import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';
import { currencyFormatter } from '@/utils/functions';

function MaterialSplitterMaterialList({
  processSteps,
  materialSplittingProcess,
  currentProcess,
}) {
  const filterStep = _.filter(
    processSteps,
    (eachProcessStep) =>
      eachProcessStep?.material_associate &&
      !eachProcessStep?.is_waste &&
      eachProcessStep?.splited_step,
  );

  const totalWaste = _.sumBy(
    _.filter(
      materialSplittingProcess?.steps,
      (each) => each?.is_waste === true,
    ),
    'amount',
  );

  const totalAmount = _.sumBy(filterStep, 'amount') + totalWaste;

  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const information = useSelector((state) => state.information);
  const [selectedStep, setSelectedStep] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newAmount, setNewAmount] = useState();

  const checkCurrentHover = (inputNewProcessStep, currentStep, parentStep) => {
    const newProcessStep = Object(inputNewProcessStep);
    const sameHoverWithoutWaste = _.filter(
      newProcessStep,
      (each) =>
        each?.hover_index === currentStep?.hover_index &&
        each?.from_index === currentStep?.from_index &&
        !each?.is_waste,
    );

    const parentAmount = parentStep?.amount;
    const sumWithoutWaste = _.sumBy(sameHoverWithoutWaste, 'amount');
    console.log('ปริมาณรวม', parentAmount);
    console.log('ปริมาณใหม่ที่ไม่รวมของเสีย', sumWithoutWaste);
    const wastedAmount = parentAmount - sumWithoutWaste;
    console.log('ปริมาณของเสีย', wastedAmount);

    const foundWasteIndex = _.findIndex(
      newProcessStep,
      (each) =>
        each?.hover_index === currentStep?.hover_index &&
        each?.from_index === currentStep?.from_index &&
        each?.is_waste,
    );

    if (foundWasteIndex === -1) {
      throw new Error('ไม่เจอหน่วยเก็บเศษเหลือ');
    }

    console.log('Found Waste Index', foundWasteIndex);
    newProcessStep[foundWasteIndex].amount = wastedAmount;
    console.log('New Process After', newProcessStep);

    return newProcessStep;
  };

  // วนกลับไปเรื่อยๆ จนกว่าจะเจอ Step ที่มันมี Material จริงๆ
  const goBackAndCheck = (inputNewProcessStep, currentStep) => {
    console.log('GOBACK AND CHECK');
    let newProcessStep = Object(inputNewProcessStep);
    const foundComingStep = _.find(
      newProcessStep,
      (each) => each?.index === currentStep?.from_index,
    );
    console.log('Step ปัจจุบันมาจาก ขั้นตอน', foundComingStep?.name);
    const foundIndexOfComingStep = _.findIndex(
      newProcessStep,
      (each) => each?.index === currentStep?.from_index,
    );

    console.log('Old Amount', foundComingStep?.amount);
    // Setting Coming Step Amount
    newProcessStep[foundIndexOfComingStep].amount += parseFloat(newAmount);
    console.log('New Amount', newProcessStep[foundIndexOfComingStep].amount);

    // Find the next hover step and update waste
    const comingOfComingStep = _.find(
      newProcessStep,
      (each) => each?.index === foundComingStep?.from_index,
    );
    console.log('Step ขั้นตอนก่อนหน้านั้น', comingOfComingStep?.name);

    if (!comingOfComingStep) {
      console.log('เจอขั้นตอนแรกแล้ว หยุด');
      throw new Error('ระบบเลยมาถึงขั้นตอนแรก');
    }

    if (comingOfComingStep?.material_associate) {
      newProcessStep = checkCurrentHover(
        newProcessStep,
        foundComingStep,
        comingOfComingStep,
      );
    } else {
      newProcessStep = goBackAndCheck(newProcessStep, foundComingStep);
    }

    return newProcessStep;
  };

  // Main Handler Function
  const handleUpdateSplittingProcess = async () => {
    try {
      let newProcessStep = Object(processSteps);
      // ตัวที่มาในขั้นตอนเดียวกัน และขั้นตอนก่อนหน้าเดียวกัน
      const sameHoverAndSameParent = _.filter(
        newProcessStep,
        (each) =>
          each?.hover_index === selectedStep?.hover_index &&
          each?.from_index === selectedStep?.from_index,
      );

      const sumAmountOfSameHoverAndSameParent = _.sumBy(
        sameHoverAndSameParent,
        'amount',
      );
      console.log(
        'ที่ส่งมามีปริมาณ',
        sumAmountOfSameHoverAndSameParent,
        'ที่ใส่ปริมาณมี',
        newAmount,
      );

      const comingStep = _.find(
        processSteps,
        (each) => each?.index === selectedStep?.from_index,
      );
      if (
        parseFloat(newAmount) > sumAmountOfSameHoverAndSameParent &&
        comingStep?.material_associate
      ) {
        window.alert('ปริมาณที่ใส่มากกว่าปริมาณที่มา');
        return;
      }

      const selectedStepInProcessIndex = _.findIndex(
        newProcessStep,
        (each) => each?._id === selectedStep?._id,
      );
      newProcessStep[selectedStepInProcessIndex].amount = parseFloat(newAmount);
      console.log('Selected Step in Process Index', selectedStepInProcessIndex);

      if (comingStep?.material_associate) {
        newProcessStep = checkCurrentHover(
          newProcessStep,
          selectedStep,
          comingStep,
        );
      } else {
        // วนกลับไปเรื่อยๆ จนกว่าจะเจอกับขั้นตอนที่มีการใช้ Material ตัวจริงๆ
        newProcessStep = goBackAndCheck(newProcessStep, selectedStep);
      }

      await dispatch(
        actions.materialSplittingPut(materialSplittingProcess?._id, {
          steps: newProcessStep,
        }),
      );
      dispatch(actions.materialSplittingReset());
      await dispatch(
        actions.materialSplittingGet(materialSplittingProcess?._id),
      );
    } catch (error) {
      alert(`ไม่สามารถดำเนินการได้ เนื่องจาก ${error?.message}}`);
    } finally {
      setDialogOpen(false);
      setSelectedStep();
      setNewAmount();
    }
  };

  const checkBoxEnable = (stepDepartment) => {
    if (me?.userData?.role?.level >= 1) {
      return true;
    }

    if (me?.userData?.department?._id === stepDepartment?._id) {
      return true;
    }

    return false;
  };

  const allowStep = _.filter(
    filterStep,
    (each) => checkBoxEnable(each?.responsible?.department) === true,
  );

  return (
    <div>
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
          setSelectedStep();
        }}
      >
        <DialogTitle>ใส่ข้อมูลปริมาณในแต่ละขั้นตอน</DialogTitle>
        <DialogContent>
          <div className="text-xl">{selectedStep?.name}</div>
          <div>ปริมาณเดิม {selectedStep?.amount}</div>
          <hr className="my-2" />
          <div className="py-2">
            <TextField
              label="ปริมาณใหม่"
              fullWidth
              value={newAmount}
              onChange={(e) => setNewAmount(e.target.value)}
            />
          </div>
          <div className="my-2">
            ต่างจากเดิม{' '}
            <span className="font-bold text-lg">
              {parseFloat(newAmount) - selectedStep?.amount}
            </span>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="success"
            onClick={handleUpdateSplittingProcess}
          >
            ยืนยัน
          </Button>
        </DialogActions>
      </Dialog>

      <div className="mt-0 mb-3 font-semibold">
        ปริมาณวัตถุดิบในแต่ละขั้นตอน
      </div>
      {!localStorage.getItem('NO_DISPLAY_ADVICE_MATERIAL_SPLIT') && (
        <div className="my-2">
          <Alert severity="info">
            <div className="flex gap-2">
              <div>คลิกที่แต่ละกล่องแล้วใส่ปริมาณวัตถุดิบ</div>
              <div>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    localStorage.setItem(
                      'NO_DISPLAY_ADVICE_MATERIAL_SPLIT',
                      true,
                    );
                  }}
                >
                  ไม่ต้องแสดงอีก
                </Button>
              </div>
            </div>
          </Alert>
        </div>
      )}
      <div className="grid gap-2  grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        {_.map(
          information?.setting?.mms?.materialSplitting?.displayStepForAllAccess
            ? filterStep
            : allowStep,
          (eachProcessStep, index) => (
            <Card
              key={index}
              className={` ${
                checkBoxEnable(eachProcessStep?.responsible?.department)
                  ? 'cursor-pointer hover:bg-gray-300'
                  : ''
              }`}
              onClick={() => {
                if (
                  checkBoxEnable(eachProcessStep?.responsible?.department) &&
                  !currentProcess?.deleted &&
                  !currentProcess?.completed
                ) {
                  setDialogOpen(true);
                  setSelectedStep(eachProcessStep);
                }
              }}
            >
              <CardContent
                className={
                  checkBoxEnable(eachProcessStep?.responsible?.department)
                    ? 'text-black'
                    : 'text-gray-500'
                }
              >
                <div className="font-semibold">{eachProcessStep?.name}</div>
                <div className="text-sm">
                  {eachProcessStep?.responsible?.department?.name}
                </div>
                <div className="text-lg font-semibold">
                  {currencyFormatter.format(eachProcessStep?.amount)}
                </div>
                {information?.setting?.mms?.materialSplitting
                  ?.displayStatisticalBeside && (
                  <div className="my-2">
                    <div className="text-sm">
                      ปริมาณจากสถิติ{' '}
                      {currencyFormatter.format(
                        eachProcessStep?.percent_amount * 0.01 * totalAmount,
                      )}
                    </div>
                    <div
                      className={`text-sm my-1 ${
                        eachProcessStep?.amount -
                          eachProcessStep?.percent_amount * 0.01 * totalAmount >
                        0
                          ? 'text-green-500'
                          : 'text-red-500'
                      }`}
                    >
                      ต่างกัน{' '}
                      {currencyFormatter.format(
                        eachProcessStep?.amount -
                          eachProcessStep?.percent_amount * 0.01 * totalAmount,
                      )}
                    </div>
                  </div>
                )}
              </CardContent>
            </Card>
          ),
        )}
      </div>
    </div>
  );
}

export default MaterialSplitterMaterialList;

MaterialSplitterMaterialList.propTypes = {
  processSteps: PropTypes.arrayOf(PropTypes.object),
  materialSplittingProcess: PropTypes.object,
  currentProcess: PropTypes.object,
};
