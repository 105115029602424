import _ from 'lodash';

const findIsStepActive = (currentProcess, eachStep) => {
  const activeIndex = currentProcess?.current?.index;
  if (eachStep?.index === activeIndex) {
    return true;
  }

  if (!eachStep?.splited_step) {
    return false;
  }

  const stepWithSameOrigin = _.filter(
    currentProcess?.steps,
    (each) => each?.from_index === eachStep?.from_index,
  );

  const stepWithSameOriginIndex = _.map(
    stepWithSameOrigin,
    (each) => each?.index,
  );

  const isSomeStepInside = _.includes(stepWithSameOriginIndex, activeIndex);

  if (isSomeStepInside) {
    return true;
  }

  return false;
};

export default findIsStepActive;
