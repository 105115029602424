import _ from 'lodash';

export const converseMaterialOrProductUnit = (material, totalAmount) => {
  const directedConversion = _.find(
    material?.conversion_from,
    (eachConversion) => eachConversion?.unit_b === material?.unit_output?._id,
  );
  const directedConFactor = directedConversion?.conversion_factor;
  // console.log('Selected Conversion', directedConversion);

  // TODO: Make one for direct revered conversion

  const amountWithConversion = directedConFactor * totalAmount;

  return amountWithConversion;
};

export const backConverseMaterialOrProductUnit = (material, totalAmount) => {
  const directedConversion = _.find(
    material?.conversion_from,
    (eachConversion) => eachConversion?.unit_b === material?.unit_output?._id,
  );
  const backConFactor = 1 / directedConversion?.conversion_factor;

  // console.log('Selected Conversion', directedConversion);

  // TODO: Make one for back revered conversion

  const amountWithConversion = backConFactor * totalAmount;

  return amountWithConversion;
};

export default {
  converseMaterialOrProductUnit,
  backConverseMaterialOrProductUnit,
};
