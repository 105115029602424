import React from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, Controller } from 'react-hook-form';
import _ from 'lodash';
import dayjs from 'dayjs';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  FormHelperText,
} from '@mui/material';

const ReturnMaterialForm = ({
  control,
  materialTransactionType,
  errors,
  material,
}) => {
  const { fields } = useFieldArray({
    control,
    name: 'material',
  });
  const filteredMaterialTxnType = _.filter(
    materialTransactionType?.rows,
    (_txnType) => _txnType?.direction === 'add',
  );
  return (
    <div>
      <div>
        <h4 className="font-semibold text-lg my-2">คืนวัตถุดิบ</h4>

        <h4>ประเภทการดำเนินการ</h4>
        <div className="py-4">
          <Controller
            control={control}
            name="material_transaction_type"
            rules={{ required: true }}
            defaultValue={filteredMaterialTxnType?.[0]?._id}
            render={({ field }) => (
              <FormControl fullWidth size="small" required>
                <InputLabel>ประเภทการดำเนินการ</InputLabel>
                <Select {...field} label="ประเภทการดำเนินการ">
                  {_.map(filteredMaterialTxnType, (_matTxnType, index) => (
                    <MenuItem key={index} value={_matTxnType?._id}>
                      {_matTxnType?.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {errors?.material_transaction_type &&
                    'กรุณาเลือกประเภทการดำเนินการ'}
                </FormHelperText>
              </FormControl>
            )}
          />
        </div>

        <h4>รายการวัตถุดิบ</h4>
        <Table size="small">
          <TableHead>
            <TableCell>ลำดับที่</TableCell>
            <TableCell>วัตถุดิบ</TableCell>
            <TableCell>จำนวนที่คืน</TableCell>
            <TableCell>ล็อตวัตถุดิบ</TableCell>
          </TableHead>
          <TableBody>
            {_.map(fields, (_field, index) => {
              const selectedMaterial = _.find(
                material?.rows,
                // prettier-ignore
                (_material) => _material?._id === _field?.materialInfo?._id,
              );

              console.log('Field', _field);

              return (
                <TableRow key={_field?.id}>
                  <TableCell> {index + 1}</TableCell>
                  <TableCell>
                    {_field?.materialInfo?.name}
                    (ใช้ได้ {_field?.resultAmount} {_field?.materialInfo?.unit})
                    <Controller
                      control={control}
                      name={`material[${index}].material`}
                      defaultValue={_field?.materialInfo}
                      render={({ field }) => <input type="hidden" {...field} />}
                    />
                  </TableCell>
                  <TableCell>
                    <div className="w-24">
                      <Controller
                        control={control}
                        name={`material[${index}].return`}
                        defaultValue={0}
                        rules={{
                          require: true,
                          min: 0,
                          max: _field?.resultAmount,
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            fullWidth
                            helperText={
                              errors?.material?.[index]?.return &&
                              'กรุณาใส่ปริมาณที่คืน และจะต้องไม่มากกว่าจำนวนวัตถุดิบที่มีในขั้นตอน'
                            }
                          />
                        )}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="w-64">
                      <Controller
                        control={control}
                        name={`material[${index}].lotId`}
                        rules={{ require: true }}
                        defaultValue="NO"
                        render={({ field }) => (
                          <FormControl fullWidth size="small" defaultValue="NO">
                            <InputLabel>ล็อตวัตถุดิบ</InputLabel>
                            <Select {...field} label="ล็อตวัตถุดิบ">
                              <MenuItem value="NO">
                                ไม่คืนวัตถุดิบชิ้นนี้
                              </MenuItem>
                              {_.map(
                                selectedMaterial?.stocklots,
                                (_stocklots, stocklotIndex) => (
                                  <MenuItem
                                    key={stocklotIndex}
                                    value={_stocklots?._id}
                                  >
                                    ล็อตวันที่{' '}
                                    {dayjs(_stocklots?.production_date)
                                      .locale('th')
                                      .format('D MMM BB')}{' '}
                                    คงเหลือ {_stocklots?.amount} {_field?.unit}
                                  </MenuItem>
                                ),
                              )}
                            </Select>
                            <FormHelperText>
                              {errors?.material?.[index]?.lotId &&
                                'กรุณาเลือกล็อตของวัตถุดิบที่ต้องการคืน'}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <div className="my-4">
          <Controller
            name="remark"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="หมายเหตุ"
                multiline
                rows={2}
                fullWidth
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

ReturnMaterialForm.propTypes = {
  control: PropTypes.object,
  materialTransactionType: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  errors: PropTypes.object,
  material: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default ReturnMaterialForm;
