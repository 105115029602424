import React, { useEffect, useState } from 'react';
import { CircularProgress, Dialog, DialogContent } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { api, config } from '../../../../configs';

import ButtonExportExcel from './ButtonExportExcel';
import ExcelSheetGenerator from './ExcelSheetGenerator';

const ExportExcelContainer = ({
  currentData,
  dataAPIEndPoint,
  dataQuery = {},
  sheetName,
  columnList,
  processColumnList,
  disableAllExport = false,
  buttonSize = 'medium',
  spreadedColumn = '',
}) => {
  const [dialogOpen, setDialogOpen] = useState();
  const [excelData, setExcelData] = useState();
  const [usedColumnList, setUsedColumnList] = useState(columnList);

  const extractProcess = (dataRows) => {
    const allData = [];
    _.map(dataRows, (eachData) => {
      _.map(eachData?.process, (eachColumn, index) => {
        const processedColumn = {};
        _.forOwn(eachColumn, (value, key) => {
          processedColumn[`process_${key}`] = value;
        });
        allData.push({
          ...eachData,
          old_process: eachData?.process,
          ...processedColumn,
          isPrimary: index === 0,
          isSpread: index !== 0,
        });
      });
    });
    console.log('All Data', allData);
    return allData;
  };

  const handleAllDownload = async () => {
    try {
      setUsedColumnList(columnList);
      if (dataQuery && !dataQuery?.size) {
        // eslint-disable-next-line no-param-reassign
        dataQuery.size = config.maxFetchSize;
      }
      const searchParam = new URLSearchParams(dataQuery);
      setDialogOpen(true);
      const { data } = await api.get(
        `${
          process.env.REACT_APP_API_URL
        }/${dataAPIEndPoint}/?${searchParam.toString()}`,
      );

      setExcelData(data?.rows);
      setDialogOpen(false);
    } catch (error) {
      alert(`ไม่สามารถดาวน์โหลดไฟล์ Excel ได้ ${error?.message}`);
    }
  };

  const handleAllDownloadWithProcess = async () => {
    try {
      setUsedColumnList(processColumnList);
      if (dataQuery && !dataQuery?.size) {
        // eslint-disable-next-line no-param-reassign
        dataQuery.size = config.maxFetchSize;
      }
      const searchParam = new URLSearchParams(dataQuery);
      setDialogOpen(true);
      const { data } = await api.get(
        `${
          process.env.REACT_APP_API_URL
        }/${dataAPIEndPoint}/?${searchParam.toString()}`,
      );

      const extractProcessRows = extractProcess(data?.rows);
      setExcelData(extractProcessRows);
      setDialogOpen(false);
    } catch (error) {
      alert(`ไม่สามารถดาวน์โหลดไฟล์ Excel ได้ ${error?.message}`);
    }
  };

  const handleCurrentDownload = () => {
    setUsedColumnList(columnList);
    setExcelData(currentData);
  };

  const handleCurrentDownloadWithProcess = () => {
    setUsedColumnList(processColumnList);
    const extractProcessRows = extractProcess(currentData);
    setExcelData(extractProcessRows);
  };

  useEffect(() => {
    let interval = () => {};
    if (excelData) {
      interval = setInterval(() => {
        setExcelData();
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [excelData]);

  return (
    <div>
      <Dialog open={dialogOpen}>
        <DialogContent>
          <div className="flex justify-center">
            <CircularProgress />
          </div>
          <div>กำลังเตรียมข้อมูลสำหรับการดาวน์โหลด</div>
        </DialogContent>
      </Dialog>
      {excelData ? (
        <ExcelSheetGenerator
          columnList={usedColumnList}
          dataSets={excelData}
          sheetName={sheetName}
          spreadedColumn={spreadedColumn}
        />
      ) : (
        <div></div>
      )}
      <ButtonExportExcel
        handleAllDownload={handleAllDownload}
        handleCurrentDownload={handleCurrentDownload}
        disableAllExport={disableAllExport}
        buttonSize={buttonSize}
        handleAllDownloadWithProcess={handleAllDownloadWithProcess}
        handleCurrentDownloadWithProcess={handleCurrentDownloadWithProcess}
      />
    </div>
  );
};

ExportExcelContainer.propTypes = {
  currentData: PropTypes.arrayOf(PropTypes.object),
  dataAPIEndPoint: PropTypes.string,
  dataQuery: PropTypes.object,
  sheetName: PropTypes.string,
  columnList: PropTypes.arrayOf(PropTypes.object),
  disableAllExport: PropTypes.bool,
  buttonSize: PropTypes.string,
  spreadedColumn: PropTypes.string,
};

export default ExportExcelContainer;
