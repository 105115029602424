import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  BackButton,
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import {
  Button,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import { config } from '@/configs';
import * as actions from '@/redux/actions';

/**
 * @function SettingPlaceAverageCost
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function SettingPlaceAverageCost({ title, subtitle }) {
  const dispatch = useDispatch();
  const place = useSelector((state) => state.place);
  const { control, handleSubmit } = useForm();
  const orderedPlace = _.orderBy(place?.rows, ['name'], ['asc']);

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.placeAll({ page: 1, size: config.maxFetchSize }));
    } catch (error) {
      dispatch(actions.placeError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, []);

  const handleUpdateData = async (data) => {
    try {
      const payload = _.map(data?.place, (each, index) => ({
        _id: orderedPlace?.[index]?._id,
        place_name: orderedPlace?.[index]?.name,
        ...each,
      }));
      console.log('payload', payload);
      await dispatch(actions.placePut('many', { arr: payload }));
      Swal.fire({
        icon: 'success',
        title: 'แก้ไขสำเร็จ',
      }).then(() => {
        queryDataFromServer();
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: error?.message,
      });
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (place.isLoading) {
    return <Loading />;
  }

  if (!place.isLoading && place.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="font-semibold my-2">
                แก้ไขต้นทุนเฉลี่ยของพนักงาน
              </div>
              <div>เป็นค่าแรงเฉลี่ยของพนักงานต่อคน ต่อวัน</div>
              <TableContainer component={Paper} className="my-2" size="small">
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <div className="font-bold">ลำดับที่</div>
                      </TableCell>
                      <TableCell>
                        <div className="font-bold">ชื่อคลัง</div>
                      </TableCell>
                      <TableCell>
                        <div className="font-bold">ต้นทุนเฉลี่ยแรงงาน</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!_.isEmpty(orderedPlace) ? (
                      _.map(orderedPlace, (row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <div className="text-xs">{index + 1}</div>
                          </TableCell>
                          <TableCell>{row?.name}</TableCell>
                          <TableCell>
                            <Controller
                              control={control}
                              name={`place[${index}].average_labor_cost`}
                              defaultValue={row?.average_labor_cost || 0}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  size="small"
                                  label="ต้นทุนเฉลี่ยแรงงาน"
                                />
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <div className="text-center">ไม่มีคลัง</div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
          <div className="my-2 flex justify-end">
            <Button
              onClick={handleSubmit(handleUpdateData)}
              variant="contained"
            >
              บันทึก
            </Button>
          </div>
        </div>
      </div>
    );
  }
  return <Error />;
}

SettingPlaceAverageCost.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SettingPlaceAverageCost.defaultProps = {
  title: '',
  subtitle: '',
};
