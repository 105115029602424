import React, { useState, useEffect } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';

export function SelectMaterialRequestForm({ material, setSelectMaterial }) {
  const [reload, setReload] = useState(false);
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      material: [
        {
          material: '',
          supplier: '',
          amount: 0,
          quantity: 0,
        },
      ],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'material',
  });

  useEffect(() => {
    setSelectMaterial(getValues('material'));
    return () => {};
  }, [reload, fields]);

  const filterSupplier = (index) => {
    const findMaterial = _.find(material, function (_material) {
      return _material?._id === getValues(`material.[${index}].material`);
    });
    if (!_.isEmpty(findMaterial?.supplier)) {
      return _.map(findMaterial?.supplier, (_supplier) => (
        <MenuItem key={_supplier._id} value={_supplier._id}>
          {_supplier.name}
        </MenuItem>
      ));
    } else {
      return (
        <MenuItem disabled value="ไม่มีข้อมูล" selected>
          ไม่มีข้อมูล
        </MenuItem>
      );
    }
  };

  const findQuantity = (index) => {
    const findMaterial = _.find(material, function (_material) {
      return _material?._id === getValues(`material.[${index}].material`);
    });
    setValue(`material.[${index}].quantity`, findMaterial?.purchase_total);
    setValue(
      `material.[${index}].amount`,
      getValues(`material.[${index}].quantity`),
    );
  };

  const displaymaterial = () => (
    <TableContainer>
      <Table size="small" className="border-lg">
        <TableHead className="bg-blue-100">
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> วัตถุดิบ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> คู่ค้า</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> จำนวน</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ดำเนินการ</div>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {_.size(fields) ? (
            fields.map((_field, index) => {
              return (
                <TableRow key={_field.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <div className="w-full px-1 py-2">
                      <Controller
                        name={`material.[${index}].material`}
                        control={control}
                        defaultValue={''}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                            <InputLabel id="type" size={'small'}>
                              วัตถุดิบ
                            </InputLabel>
                            <Select
                              {...field}
                              label="วัตถุดิบ"
                              size={'small'}
                              fullWidth
                              onChange={(e) => {
                                field.onChange(e.target.value);
                                findQuantity(index);
                                setReload(!reload);
                              }}
                            >
                              {_.size(material) ? (
                                _.map(material, (_material) => (
                                  <MenuItem
                                    key={_material._id}
                                    value={_material._id}
                                  >
                                    {_material?.type_code +
                                      ' | ' +
                                      _material.name}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem>
                                  <MenuItem
                                    disabled
                                    value="ไม่มีข้อมูล"
                                    selected
                                  >
                                    <em>ไม่มีข้อมูล</em>
                                  </MenuItem>
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        )}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="w-full px-1 py-2">
                      <Controller
                        name={`material.[${index}].supplier`}
                        control={control}
                        defaultValue={''}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                            <InputLabel id="type" size={'small'}>
                              คู่ค้า*
                            </InputLabel>
                            <Select
                              {...field}
                              label="คู่ค้า*"
                              size={'small'}
                              fullWidth
                              required
                              onChange={(e) => {
                                field.onChange(e.target.value);
                                setReload(!reload);
                              }}
                            >
                              {filterSupplier(index)}
                            </Select>
                          </FormControl>
                        )}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name={`material.[${index}].quantity`}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setValue(
                              `material.[${index}].amount`,
                              getValues(`material.[${index}].quantity`),
                            );
                            setReload(!reload);
                          }}
                          label="จำนวน"
                          fullWidth
                          type={'Number'}
                          required
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'center' },
                          }}
                          size={'small'}
                          helperText={errors.quantity && 'กรุณาใส่ข้อมูล'}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      color={'error'}
                      variant="contained"
                      size={'small'}
                      onClick={() => remove(index)}
                    >
                      ลบ
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={5}>
                <div className="text-center py-2">ไม่มีรายการ</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <div>
      <div>{displaymaterial()}</div>
      <div className="flex flex-wrap justify-between">
        <div className="px-2 py-2">
          <Button
            color={'success'}
            variant="outlined"
            size={'small'}
            onClick={() =>
              append({
                material: '',
                supplier: '',
                amount: 0,
                quantity: 0,
              })
            }
          >
            เพิ่มรายการ
          </Button>
        </div>
      </div>
    </div>
  );
}

SelectMaterialRequestForm.propTypes = {
  material: PropTypes.object,
  setSelectMaterial: PropTypes.func,
};

SelectMaterialRequestForm.defaultProps = {
  material: null,
};

export default SelectMaterialRequestForm;
