import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { gql } from 'graphql-request';
import { useForm } from 'react-hook-form';
import { Button, Card, CardContent } from '@mui/material';

import {
  ViewTitle,
  Loading,
  Error,
  BackButton,
  AddAsMaterialForm,
} from '@iarcpsu/emanufac-components/src/components';
import * as actions from '../../../redux/actions';
import graphqlClient from '../../../configs/graphqlClient';
import { app as appConfig } from '../../../configs';
import { MMS } from '../../../utils/functions';

/**
 * @function AddToMaterialInventory
 * @memberof MMS/Process
 * @description Display a collections or a list of AddToMaterialInventory from database
 */

export default function AddToMaterialInventory({ title, subtitle }) {
  const dispatch = useDispatch();

  // process is JavaScript Reserved Word, So use currentProcess is more save
  const currentProcess = useSelector((state) => state.process);
  const material = useSelector((state) => state.material);
  const place = useSelector((state) => state.place);
  const materialTransactionType = useSelector(
    (state) => state.materialTransactionType,
  );
  const me = useSelector((state) => state.me);
  const information = useSelector((state) => state.information);
  const params = useParams();
  const history = useHistory();
  const { handleAddAsMaterial } = MMS;

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      material: [],
    },
  });

  useEffect(() => {
    const fetchPlace = async () => {
      try {
        await dispatch(actions.materialTransactionTypeAll({}));
      } catch (error) {
        console.error('Material Transaction Type All Error', error);
      }
    };

    fetchPlace();

    return () => {};
  }, []);

  const query = gql`
    query FindMainDetailProcess(
      $processInput: ProcessInput
      $materialInput: MaterialInput
      $placeInput: PlaceInput
    ) {
      findOneProcess(input: $processInput) {
        _id
      }
      findMaterials(input: $materialInput) {
        total
        rows {
          _id
          name
          type_code
          use_unit_conversion
          unit
          conversion_from {
            unit_a
            unit_b
            conversion_factor
          }
          conversion_to {
            unit_b
            unit_a
            conversion_factor
          }
          unit_input {
            _id
            short_sign
            name
          }
          unit_output {
            _id
            short_sign
            name
          }
        }
      }
      findPlaces(input: $placeInput) {
        total
        rows {
          _id
          name
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        processInput: { id: params.id },
        materialInput: {
          fetchStockLot: false,
          status: '',
          page: 1,
          size: appConfig.maxFetchSize,
        },
      });
      const processData = queryResult?.findOneProcess;
      const materialData = queryResult?.findMaterials;
      const placeData = queryResult?.findPlaces;
      dispatch(actions.processStateOneSet(processData));
      dispatch(actions.materialStateSet(materialData));
      dispatch(actions.placeStateSet(placeData));
    } catch (error) {
      console.error('Data Fetch Error', error);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const handleMaterialAdd = async (data) => {
    try {
      await handleAddAsMaterial({
        dispatch,
        processId: currentProcess?._id,
        data,
        me,
      });

      history.goBack();
    } catch (error) {
      alert('การเพิ่มกลับไปยังคลังวัตถุดิบมีปัญหา', error?.message);
      console.error('Error on Add As Material', error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderBackButton = () => (
    <div className="flex">
      <BackButton />
    </div>
  );

  if (currentProcess.isLoading) {
    return <Loading />;
  }
  if (!currentProcess.isLoading && currentProcess.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}
        <div className="my-2">
          <Card>
            <CardContent>
              <form>
                <AddAsMaterialForm
                  control={control}
                  errors={errors}
                  imsSetting={information?.setting?.ims}
                  material={material}
                  materialTransactionType={materialTransactionType}
                  place={place}
                  setValue={setValue}
                  watch={watch}
                />
                <div className="flex gap-2 my-2 justify-end">
                  <Button
                    color="primary"
                    variant="contained"
                    type="button"
                    onClick={handleSubmit(handleMaterialAdd)}
                  >
                    ยืนยัน
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

AddToMaterialInventory.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  showAsSubPage: PropTypes.bool,
  defaultProcessId: PropTypes.string,
};

AddToMaterialInventory.defaultProps = {
  title: '',
  subtitle: '',
  showAsSubPage: false,
  defaultProcessId: '',
};
