import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  BackButton,
  Error,
  Loading,
  PayrollForm,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Button, Card, CardContent } from '@mui/material';
import { gql } from 'graphql-request';
import PropTypes from 'prop-types';

import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';

/**
 * @function EditPayroll
 * @description Edit Payroll of Employee
 */

export default function EditPayroll({ title, subtitle }) {
  const dispatch = useDispatch();
  const employee = useSelector((state) => state.employee);
  const history = useHistory();
  const params = useParams();
  const { control, handleSubmit } = useForm({
    defaultValues: employee,
  });

  const query = gql`
    query FindEditPayroll($employeeInput: EmployeeInput) {
      findOneEmployee(input: $employeeInput) {
        _id
        firstname
        lastname
        department {
          _id
          name
        }
        earnings {
          is_pay_monthy
          amount
          hour_per_day
          day_per_month
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        employeeInput: { id: params.id },
      });
      const employeeData = queryResult?.findOneEmployee;
      dispatch(actions.employeeStateOneSet(employeeData));
    } catch (error) {
      dispatch(actions.employeeError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const handleEditEmployee = async (data) => {
    try {
      await dispatch(actions.employeePut(params.id, data));
      history.goBack();
    } catch (error) {
      alert(`แก้ไขพนักงานไม่สำเร็จ ${error?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (employee.isLoading) {
    return <Loading />;
  }

  if (!employee.isLoading && employee.isCompleted && !employee?.rows) {
    return (
      <div>
        {renderTitle()}

        <BackButton />
        <div className="my-2">
          <form onSubmit={handleSubmit(handleEditEmployee)}>
            <Card>
              <CardContent>
                <div className="flex flex-wrap justify-between">
                  <div className="w-full md:w-3/4">
                    <div className="font-semibold">พนักงาน</div>
                    <div className="text-lg mb-2">
                      {employee?.firstname} {employee?.lastname}
                    </div>
                  </div>{' '}
                  <div className="w-full md:w-1/4">
                    <div className="font-semibold">แผนก</div>
                    <div className="text-lg mb-2">
                      {employee?.department?.name}
                    </div>
                  </div>
                </div>

                <PayrollForm control={control} employee={employee} />
              </CardContent>
            </Card>
            <div className="flex justify-end my-2">
              <Button type="submit" variant="contained">
                บันทึก
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditPayroll.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditPayroll.defaultProps = {
  title: '',
  subtitle: '',
};
