import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { gql } from 'graphql-request';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, Card, CardContent } from '@mui/material';

import {
  ViewTitle,
  Loading,
  Error,
  RequestAddWarehouseForm,
  BackButton,
} from '@iarcpsu/emanufac-components/src/components';
import * as actions from '../../../redux/actions';
import graphqlClient from '../../../configs/graphqlClient';
import { config } from '../../../configs';

/**
 * @function RequestAddWarehouse
 * @description การส่งคำขอการเพิ่มสินค้าลงในคลังสินค้า สำหรับที่ๆ เปิดใช้งานการอนุมัติจากคลังสินค้า
 * @memberof MMS/Process
 */

export default function RequestAddWarehouse({ title, subtitle }) {
  const dispatch = useDispatch();
  const selectedProcess = useSelector((state) => state.process);
  const product = useSelector((state) => state.product);
  const me = useSelector((state) => state.me);
  const history = useHistory();
  const params = useParams();
  const { control, handleSubmit, setValue, watch } = useForm();

  const query = gql`
    query FindSettingEditProductBom(
      $processInput: ProcessInput
      $productInput: ProductInput
    ) {
      findOneProcess(input: $processInput) {
        _id
        product {
          _id
          name
          type_code
          use_unit_conversion
          unit
          conversion_from {
            unit_a
            unit_b
            conversion_factor
          }
          conversion_to {
            unit_b
            unit_a
            conversion_factor
          }
          unit_input {
            _id
            short_sign
            name
          }
          unit_output {
            _id
            short_sign
            name
          }
        }
        quantity
        produce_material_instead
        manufacturing_order {
          _id
        }
      }
      findProducts(input: $productInput) {
        total
        rows {
          _id
          name
          type_code
          use_unit_conversion
          unit
          conversion_from {
            unit_a
            unit_b
            conversion_factor
          }
          conversion_to {
            unit_b
            unit_a
            conversion_factor
          }
          unit_input {
            _id
            short_sign
            name
          }
          unit_output {
            _id
            short_sign
            name
          }
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    const queryResult = await graphqlClient.request(query, {
      processInput: { id: params.id, fetchProduct: true },
      productInput: { page: 1, size: config.maxFetchSize },
    });
    const processData = queryResult?.findOneProcess;
    const productData = queryResult?.findProducts;
    dispatch(actions.processStateOneSet(processData));
    dispatch(actions.productStateSet(productData));
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  useEffect(() => {
    if (selectedProcess && selectedProcess?.product) {
      setValue('products', [
        {
          product: selectedProcess?.product,
          quantity: selectedProcess?.quantity,
        },
      ]);
    }

    return () => {};
  }, [selectedProcess]);

  const handleCreateRequest = async (data) => {
    try {
      await dispatch(
        actions.warehouseStockRequestCreate({
          ...data,
          requester: me?.userData?._id,
          manufacturing_order: selectedProcess?.manufacturing_order?._id,
          process: params.id,
        }),
      );
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      alert(`ไม่สามารถขอเพิ่มลงคลังสินค้าได้ ${error?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (selectedProcess.isLoading) {
    return <Loading />;
  }

  if (!selectedProcess.isLoading && selectedProcess.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div>
          <BackButton />
        </div>
        <div className="my-2">
          <Card>
            <CardContent>
              <form onSubmit={handleSubmit(handleCreateRequest)}>
                <RequestAddWarehouseForm
                  control={control}
                  product={product}
                  setValue={setValue}
                  watch={watch}
                />
                <div className="flex justify-end">
                  <Button variant="contained" type="submit">
                    บันทึกการขอเพิ่ม
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

RequestAddWarehouse.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

RequestAddWarehouse.defaultProps = {
  title: '',
  subtitle: '',
};
