import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  DaySpotAndRangePicker,
  Error,
  Loading,
  QuotationTransactionTable,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import { Card, CardContent, Switch } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';

/**
 * @function EachQuotationTransaction
 * @description Display a collections or a list of EachQuotationTransaction from database
 */

export default function QuotationAndInternalDepositTransactions({
  title,
  subtitle,
}) {
  const dispatch = useDispatch();
  const qtAndIDTransaction = useSelector((state) => state.qtAndIDTransaction);
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(undefined);
  const [allowTimeQuery, setAllowTimeQuery] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.qtAndIdTransactionAll({
          page,
          size,
          startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
          endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : '',
        }),
      );
    } catch (error) {
      dispatch(actions.qtAndIdTransactionError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, startDate, endDate]);

  useEffect(() => {
    if (qtAndIDTransaction) {
      setTotal(qtAndIDTransaction?.total);
    }

    return () => {};
  }, [qtAndIDTransaction]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (qtAndIDTransaction.isLoading) {
    return <Loading />;
  }
  if (!qtAndIDTransaction.isLoading && qtAndIDTransaction.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="flex flex-wrap items-center">
                <div className="w-full lg:w-1/3 flex items-center">
                  <Switch
                    value={allowTimeQuery}
                    onChange={(event) => {
                      setAllowTimeQuery(event.target.checked);
                      setStartDate(null);
                      setEndDate(null);
                      if (event.target.checked !== true) {
                        setStartDate(null);
                        setEndDate(null);
                        history.push(`?page=${page}&size=${size}&start=&end=`);
                      }
                    }}
                  />
                  <div>เปิดใช้การกรองเวลา</div>
                </div>
                <div className="w-full lg:w-2/3">
                  <DaySpotAndRangePicker
                    addSearchQuery={true}
                    anotherQuery={`?page=${page}&size=${size}&start=${
                      startDate ? dayjs(startDate).format('YYYY-MM-DD') : ''
                    }&end=${
                      endDate ? dayjs(endDate).format('YYYY-MM-DD') : ''
                    }`}
                    flex
                    size="small"
                    enable={allowTimeQuery}
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="my-2">
          <QuotationTransactionTable
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            total={total}
            quotationTransaction={qtAndIDTransaction}
            showQuotationNumber
          />
        </div>
      </div>
    );
  }
  return <Error />;
}

QuotationAndInternalDepositTransactions.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

QuotationAndInternalDepositTransactions.defaultProps = {
  title: '',
  subtitle: '',
};
