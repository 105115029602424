import precasting from '@iarcpsu/emanufac-plugin-precasting-frontend';
import precastMainRoute from '@iarcpsu/emanufac-plugin-precasting-frontend/src/App';
import precastOnProcessComponent from '@iarcpsu/emanufac-plugin-precasting-frontend/src/OnProcessDisplay';

// Insert your data about plugin in array
// For each Plugin you must give name.id,routes and slug
// name : Name of Plugin
// id: ID or Unique ID of plugin
// routes : routes of API inside plugins
// slug : route url
export const usedPlugin = [
  {
    name: precasting.name,
    id: precasting.id,
    slug: precasting.slug,
    moduleLevel: precasting.moduleLevel,
    information: precasting.information,
    routes: precastMainRoute,
    displayOnProcessContainer: precastOnProcessComponent,
  },
];

export default usedPlugin;
