const PRODUCT_QUANTITY_DIVIDE_BY = {
  NONE: {
    status_code: "NONE",
    description: "ไม่ต้องหารอะไร",
    divided_by_quantity_key: "quantity",
  },
  PACKAGE_SIZE: {
    status_code: "PACKAGE_SIZE",
    description: "ขนาดบรรจุภัณฑ์",
    divided_by_quantity_key: "package_size",
  },
  NET: {
    status_code: "NET",
    description: "ปริมาณสุทธิ",
    divided_by_quantity_key: "net",
  },
};

module.exports = PRODUCT_QUANTITY_DIVIDE_BY;
