import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  TextField,
} from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';

import graphqlClient from '../../../configs/graphqlClient';

const MOFromTemplateLoadingForm = ({ moTemplate, setCreateMO }) => {
  const [selectedMOTemplate, setSelectedMOTemplate] = useState();
  const query = gql`
    query FindMOTemplate($input: MOTemplateInput) {
      findOneMOTemplate(input: $input) {
        name
        processes {
          product {
            _id
            name
            type_code
            unit
          }
          produce_material_instead
          product_as_material {
            _id
            name
            type_code
            unit
          }
          process_template {
            _id
            name
            steps {
              materials {
                material {
                  _id
                  name
                  use_unit_conversion
                  unit
                  conversion_from {
                    unit_a
                    unit_b
                    conversion_factor
                  }
                  conversion_to {
                    unit_b
                    unit_a
                    conversion_factor
                  }
                  unit_input {
                    short_sign
                    name
                  }
                  unit_output {
                    short_sign
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const settingMaterial = (currentProcessTemplate) => {
    let allMaterials = [];

    // Find Material of Each Step and Merge them
    _.map(currentProcessTemplate?.steps, (_eachStep) => {
      const fullDataEachStepMaterial = _.map(
        _eachStep?.materials,
        (eachMaterial) => ({ material: eachMaterial }),
      );
      //  console.log('Each Step Materials', fullDataEachStepMaterial);

      allMaterials = _.concat(allMaterials, fullDataEachStepMaterial);
    });

    // Set Merged Material into from stage
    if (!_.isEmpty(allMaterials) && !_.includes(allMaterials, null)) {
      return allMaterials;
    }

    return [];
  };

  const handleGetFullMOTemplate = async () => {
    if (!selectedMOTemplate) {
      alert('ท่านยังไม่ได้เลือกสูตรคำสั่งผลิต');
      return;
    }
    try {
      const queryResult = await graphqlClient.request(query, {
        input: {
          id: selectedMOTemplate?._id,
          fetchFullProcessTemplate: true,
          fetchFullOfMaterial: true,
        },
      });

      const fetchedMOTemplate = queryResult?.findOneMOTemplate;
      console.log('MO Template', fetchedMOTemplate);
      const modifiedTemplateProcess = _.map(
        fetchedMOTemplate?.processes,
        (eachProcess) => ({
          ...eachProcess,
          createFromTemplate: true,
          materials: settingMaterial(eachProcess?.process_template),
        }),
      );

      setCreateMO('processes', modifiedTemplateProcess);
    } catch (error) {
      alert('ไม่สามารถโหลดสูตรคำสัั่งผลิตได้');
    }
  };

  if (_.isEmpty(moTemplate?.rows)) {
    return <div></div>;
  }

  return (
    <div className="my-2">
      <Card>
        <CardContent>
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/2">
              <Autocomplete
                size="small"
                options={moTemplate?.rows}
                defaultValue={selectedMOTemplate}
                placeholder="เลือกสูตรคำสั่งผลิต"
                onChange={(e, newValue) => {
                  setSelectedMOTemplate(newValue);
                }}
                getOptionLabel={(option) => `${option?.name}`}
                renderInput={(params) => (
                  <TextField label="เลือกสูตรคำสั่งผลิต" {...params} />
                )}
              />
            </div>
            <div className="w-full lg:w-1/2 px-2">
              <Button variant="contained" onClick={handleGetFullMOTemplate}>
                โหลดข้อมูล
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

MOFromTemplateLoadingForm.propTypes = {
  moTemplate: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  setCreateMO: PropTypes.func,
};

export default MOFromTemplateLoadingForm;
