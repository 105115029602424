import React, { useState } from 'react';
import { Switch } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import PieChart from './PieChart';

const MaterialStockLotPieChart = ({ materialStockLotRows }) => {
  const [combineMaterial, setIsCombineMaterial] = useState(true);

  const uniqMaterial = _.groupBy(materialStockLotRows, 'material._id');
  const uniqMaterialValue = _.values(uniqMaterial);
  const materialuniqueRows = _.map(uniqMaterialValue, (each) => ({
    material: each?.[0]?.material,
    quantity: _.sumBy(each, 'quantity'),
    amount: _.sumBy(each, 'amount'),
  }));

  return (
    <div>
      <div className="flex justify-end">
        <div className="flex gap-1 items-center">
          <div>แยกตามล็อต</div>
          <Switch
            checked={combineMaterial}
            onChange={(e) => setIsCombineMaterial(e.target.checked)}
          />
          <div>รวมตามวัตถุดิบ</div>
        </div>
      </div>
      <PieChart
        donut
        labels={_.map(
          combineMaterial ? materialuniqueRows : materialStockLotRows,
          (each) => each?.material?.name,
        )}
        series={_.map(
          combineMaterial ? materialuniqueRows : materialStockLotRows,
          (each) => each?.quantity,
        )}
      />
    </div>
  );
};

export default MaterialStockLotPieChart;

MaterialStockLotPieChart.propTypes = {
  materialStockLotRows: PropTypes.arrayOf(PropTypes.object),
};
