import {
  ORDER_ALL,
  ORDER_POST,
  ORDER_GET,
  ORDER_PUT,
  ORDER_DEL,
  ORDER_ERROR,
  ORDER_LOADING,
} from '../types';

import api from '../../../configs/api';

export const orderCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: ORDER_POST });
    const { data } = await api.post(`${process.env.REACT_APP_API_URL}/order`, {
      ...payload,
    });
    dispatch({ type: ORDER_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: ORDER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const orderAll = (params) => async (dispatch) => {
  try {
    const {
      name = '',
      size = '',
      page = 1,
      statusCode = '',
      startDate = '',
      endDate = '',
    } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/order?name=${name}&size=${size}&page=${page}&status=${statusCode}&startDate=${startDate}&endDate=${endDate}`,
    );
    if (status === 200) {
      dispatch({ type: ORDER_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: ORDER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const orderGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/order/${id}`,
    );
    if (status === 200) {
      dispatch({ type: ORDER_GET, payload: data });
    }
    if (status === 404) {
      dispatch({ type: ORDER_ERROR, payload: { message: '' } });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: ORDER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const orderStateSet = (data) => async (dispatch) => {
  dispatch({ type: ORDER_ALL, payload: data });
};

export const orderPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: ORDER_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/order/${id}`,
      payload,
    );
    dispatch({ type: ORDER_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: ORDER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const orderDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: ORDER_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/order/${id}`,
    );
    dispatch({ type: ORDER_DEL, payload: data });
  } catch (error) {
    const { status } = error.request;
    console.error(error?.response?.data?.error?.message);

    if (status === 403) {
      // prettier-ignore
      const message = 'ไม่สามารถลบข้อมูลเนื่องจากยังคงมีรายการที่ใช้สถานะนี้อยู่';
      dispatch({ type: ORDER_ERROR, payload: { message } });
      throw new Error(error?.response?.data?.error?.message);
    } else {
      const message = 'เกิดข้อผิดพลาด';
      dispatch({ type: ORDER_ERROR, payload: { message } });
      throw new Error(error?.response?.data?.error?.message);
    }
  }
};
