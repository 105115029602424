const MATERIAL_WITHDRAW_STATUS = {
  PENDING_APPROVAL: {
    status_code: "PENDING_APPROVAL",
    description: "รออนุมัติ",
  },
  APPROVAL: {
    status_code: "APPROVAL",
    description: "อนุมัติแล้ว",
  },
  REJECT: {
    status_code: "REJECT",
    description: "ไม่อนุมัติ",
  },
};

module.exports = MATERIAL_WITHDRAW_STATUS;
