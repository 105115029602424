/* eslint-disable import/no-unresolved */
/* eslint-disable import/prefer-default-export */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';

import currencyFormatterTH from '@/utils/functions/currencyFormatterTH';

import 'addthaifont-pdfmake/build/vfs_fonts';

import { ReportHeader } from './common';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};

const genMaterial = (data) =>
  _.map(data.stocklots, (_data, index) => [
    {
      text: index + 1,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${dayjs(_data?.purchase_date)
        .locale('th')
        .format('DD MMM BBBB')}\n `,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${dayjs(_data?.receipt_date)
        .locale('th')
        .format('DD MMM BBBB')}\n `,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${_data?.lot_number || '-'}`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${_data?.bill_number || '-'}`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${dayjs(_data?.production_date)
        .locale('th')
        .format('DD MMM BBBB')}\n`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${dayjs(_data?.expiration_date)
        .locale('th')
        .format('DD MMM BBBB')}\n `,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${_data?.supplier?.name || '-'}`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${_data?.recipient_name || '-'}`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${currencyFormatterTH.format(_data?.price) || '0'} บาท`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${currencyFormatterTH.format(_data?.quantity) || '0'} ${
        data?.unit || ''
      }`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${currencyFormatterTH.format(_data?.amount) || '0'} ${
        data?.unit || ''
      }`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${_data?.remark || ''}`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
  ]);

export const MaterialLotReport = (material, information, isError) => {
  const materialData = genMaterial(material);
  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [20, 30, 40, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '16',
    },
    info: {
      title: 'รายละเอียดล็อตวัตถุดิบ',
    },
    content: [
      isError ? ReportHeader(information, false) : ReportHeader(information),
      {
        margin: [0, 20, 30, 0],
        style: 'tableExample',
        layout: {
          fillColor(rowIndex) {
            return rowIndex === 0 ? '#c5cae9' : null;
          },
        },
        table: {
          widths: [
            '5%',
            '10%',
            '10%',
            '10%',
            '8%',
            '10%',
            '10%',
            '10%',
            '8%',
            '7%',
            '7%',
            '7%',
            '7%',
          ],
          heights: [15],
          headerRows: 1,
          dontBreakRows: true,
          body: [
            [
              {
                text: 'ลำดับ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'วันที่่ซื้อ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'วันที่่รับวัตถุดิบ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'หมายเลขล็อต',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'เลขที่บิล',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'วันที่ผลิต',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'วันที่หมดอายุ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'คู่ค้า',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'ผู้รับวัตถุดิบ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'ราคา',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'จำนวน',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'คงเหลือ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'หมายเหตุ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
            ],
            ...materialData,
          ],
        },
      },
    ],
    images: {
      logo: information?.logo?.url,
    },
  };
  pdfMake.createPdf(docDefinition).open();
};
