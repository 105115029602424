import React, { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Button, Card } from '@mui/material';
import PropTypes from 'prop-types';

import { BackButton } from '@iarcpsu/emanufac-components/src/components/Button';
import { Error } from '@iarcpsu/emanufac-components/src/components/Error';
import { CustomerForm } from '@iarcpsu/emanufac-components/src/components/Forms';
import { Loading } from '@iarcpsu/emanufac-components/src/components/Loading';
import { ViewTitle } from '@iarcpsu/emanufac-components/src/components/ViewTitle';
import * as actions from '../../redux/actions';

const defaultValues = {
  type: '',
  organization_type: '',
  type_code: '',
  name: '',
  address: '',
  taxes: '',
  phone_number: '',
  fax: '',
  email: '',
  payment: '',
};
export default function EditCustomer({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const customerType = useSelector((state) => state.customerType);
  const customer = useSelector((state) => state.customer);
  const paymentType = useSelector((state) => state.paymentType);
  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      type: customer.type,
      name: customer.name,
      organization_type: customer.organization_type,
      address: customer.address,
      taxes: customer.taxes,
      phone_number: customer.phone_number,
      fax: customer.fax,
      email: customer.email,
      payment: customer.payment || null,
      contact: customer?.contact || [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contact',
  });

  useEffect(() => {
    dispatch(actions.customerGet(id));
    dispatch(actions.customerTypeAll(''));
    dispatch(actions.paymentTypeAll(''));

    return () => {};
  }, []);

  useEffect(() => {
    if (customer?.contact) {
      setValue('contact', customer?.contact);
    }
    return () => {};
  }, [customer]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      data.contact = getValues('contact');
      await dispatch(actions.customerPut(id, data));
      await dispatch(actions.customerGet(id));
      alert('สำเร็จ');
      reset(defaultValues);
      history.goBack();
    } catch (error) {
      console.log(error);
      alert(`ดำเนินการไม่สำเร็จ${error?.message}`);
    }
  };
  if (customer.isLoading || customer.rows) {
    return <Loading />;
  }
  if (!customer.isLoading && customer.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <div className="flex flex-wrap">
          <Card className="p-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <CustomerForm
                errors={errors}
                customerType={customerType}
                customer={customer}
                paymentType={paymentType}
                control={control}
                Controller={Controller}
                fields={fields}
                append={append}
                remove={remove}
                watch={watch}
              />
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditCustomer.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditCustomer.defaultProps = {
  title: '',
  subtitle: '',
};
