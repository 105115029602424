import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { notNan } from '@/utils/functions';

const MaterialOnProcessTable = ({ stepMaterialResult }) => (
  <div>
    {' '}
    <Table size="medium" className="border-lg mt-2">
      <TableHead className="bg-blue-100">
        <TableRow
          sx={{
            '& td, & th': { border: 1, borderColor: '#DCDCDC' },
          }}
        >
          <TableCell rowSpan={2}>
            <div className="font-bold text-center">ลำดับที่</div>
          </TableCell>
          <TableCell rowSpan={2}>
            <div className="font-bold text-center">ขั้นตอน</div>
          </TableCell>
          <TableCell colSpan={7}>
            <div className="font-bold text-center">ค่าในแต่ละขั้นตอน</div>
          </TableCell>
          <TableCell colSpan={6}>
            <div className="font-bold text-center">ค่าสะสม</div>
          </TableCell>
        </TableRow>
        <TableRow
          sx={{
            '& td, & th': { border: 1, borderColor: '#DCDCDC' },
          }}
        >
          <TableCell>
            <div className="font-bold text-center"> ยกมา</div>
          </TableCell>
          <TableCell>
            <div className="font-bold text-center"> เบิกเพิ่ม</div>
          </TableCell>
          <TableCell>
            <div className="font-bold text-center"> คืนของ</div>
          </TableCell>
          <TableCell>
            <div className="font-bold text-center"> ใช้จริง</div>
          </TableCell>
          <TableCell>
            <div className="font-bold text-center"> ได้เป็นผลผลิต</div>
          </TableCell>
          <TableCell>
            <div className="font-bold text-center"> ของเสีย</div>
          </TableCell>
          <TableCell>
            <div className="font-bold text-center"> Yield ของขั้นตอน</div>{' '}
          </TableCell>
          <TableCell>
            <div className="font-bold text-center"> เบิกมา</div>
          </TableCell>
          <TableCell>
            <div className="font-bold text-center"> คืนของ</div>
          </TableCell>
          <TableCell>
            <div className="font-bold text-center"> ใช้จริง</div>
          </TableCell>
          <TableCell>
            <div className="font-bold text-center"> ได้เป็นผลผลิต</div>
          </TableCell>
          <TableCell>
            <div className="font-bold text-center"> ของเสีย</div>
          </TableCell>
          <TableCell>
            <div className="font-bold text-center"> Yield ทั้งหมด</div>
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {_.map(stepMaterialResult, (_stepMaterial, index) => (
          <TableRow
            key={index}
            sx={{
              '& td, & th': { border: 1, borderColor: '#DCDCDC' },
            }}
          >
            <TableCell>{index + 1}</TableCell>
            <TableCell>{_stepMaterial?.name}</TableCell>
            <TableCell>
              {index === 0
                ? '-'
                : notNan(stepMaterialResult?.[index - 1]?.analyze?.remain)}
            </TableCell>
            <TableCell>
              {' '}
              {index === 0
                ? notNan(_stepMaterial?.analyze?.requestAmount)
                : notNan(
                    _stepMaterial?.analyze?.requestAmount -
                      stepMaterialResult?.[index - 1]?.analyze?.requestAmount,
                  )}
            </TableCell>
            <TableCell>
              {' '}
              {notNan(_stepMaterial?.analyze?.originalResult?.return)}
            </TableCell>
            <TableCell>
              {' '}
              {notNan(
                _stepMaterial?.analyze?.originalResult?.begin -
                  _stepMaterial?.analyze?.originalResult?.return,
              )}
            </TableCell>
            <TableCell>
              {' '}
              {notNan(_stepMaterial?.analyze?.originalResult?.end)}
            </TableCell>
            <TableCell>
              {notNan(
                _stepMaterial?.analyze?.originalResult?.begin -
                  _stepMaterial?.analyze?.originalResult?.return -
                  _stepMaterial?.analyze?.originalResult?.end,
              )}
            </TableCell>
            <TableCell>
              {' '}
              {notNan(
                (
                  (_stepMaterial?.analyze?.originalResult?.end /
                    _stepMaterial?.analyze?.originalResult?.begin) *
                  100
                ).toFixed(2),
              )}
              %
            </TableCell>
            {/* ข้างล่างนี้เป็นค่าสะสม (Comulative) */}
            <TableCell>
              {' '}
              {notNan(_stepMaterial?.analyze?.requestAmount)}
            </TableCell>
            <TableCell>
              {notNan(_stepMaterial?.analyze?.returnAmount)}
            </TableCell>
            <TableCell> {notNan(_stepMaterial?.analyze?.usedAmount)}</TableCell>
            <TableCell> {notNan(_stepMaterial?.analyze?.remain)}</TableCell>
            <TableCell>
              {notNan(
                _stepMaterial?.analyze?.usedAmount -
                  _stepMaterial?.analyze?.remain,
              )}
            </TableCell>
            <TableCell>
              {notNan(_stepMaterial?.analyze?.yield?.toFixed(2))} %
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    {_.isEmpty(stepMaterialResult) && (
      <div className="w-full my-8 flex justify-center">
        <div className="font-system">ยังไม่มีข้อมูลการใช้วัตถุดิบ</div>
      </div>
    )}
  </div>
);

MaterialOnProcessTable.propTypes = {
  stepMaterialResult: PropTypes.arrayOf(PropTypes.object),
};

export default MaterialOnProcessTable;
