import React from 'react';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@mui/material';
import _ from 'lodash';

import { currencyFormatter } from '../../../utils/functions';

const MetalDivideDisplayTable = ({ eachMetalDividing }) => (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <div className="font-bold"> ลำดับที่</div>
          </TableCell>
          <TableCell>
            <div className="font-bold"> ขนาด</div>
          </TableCell>
          <TableCell>
            <div className="font-bold"> จำนวน/คู่</div>
          </TableCell>
          <TableCell>
            <div className="font-bold"> ความยาว (ม.)</div>{' '}
          </TableCell>
          <TableCell>
            <div className="font-bold"> เลขที่ Sale Order (So)</div>{' '}
          </TableCell>
          <TableCell>
            <div className="font-bold"> ลูกค้า</div>
          </TableCell>
          <TableCell>
            <div className="font-bold"> ส่วนสูญเสีย</div>
          </TableCell>
          <TableCell>
            <div className="font-bold"> หมายเหตุ</div>
          </TableCell>
          <TableCell>
            <div className="font-bold"> หมายเหตุ</div>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {_.map(eachMetalDividing?.divide_sets, (eachDivideSet, divSetIndex) => (
          <TableRow key={`divset-${divSetIndex}`}>
            <TableCell> {divSetIndex + 1} </TableCell>
            <TableCell>
              {currencyFormatter.format(eachDivideSet?.length)}{' '}
            </TableCell>
            <TableCell>
              {currencyFormatter.format(eachDivideSet?.amount)}{' '}
            </TableCell>
            <TableCell>
              {currencyFormatter.format(
                eachDivideSet?.length * eachDivideSet?.amount,
              )}{' '}
            </TableCell>
            <TableCell>{eachDivideSet?.so_number}</TableCell>
            <TableCell>{eachDivideSet?.customer?.name}</TableCell>
            <TableCell>
              {eachDivideSet?.lost
                ? currencyFormatter.format(eachDivideSet?.lost)
                : '-'}
            </TableCell>
            <TableCell>{eachDivideSet?.remark}</TableCell>
          </TableRow>
        ))}
        {_.isEmpty(eachMetalDividing?.divide_sets) && (
          <TableRow>
            <TableCell colSpan={8}>
              <div className="text-center my-2">
                ยังไม่มีรายการรางลวดในสินค้านี้
              </div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
);

MetalDivideDisplayTable.propTypes = {
  eachMetalDividing: PropTypes.shape({
    divide_sets: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default MetalDivideDisplayTable;
