/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import _ from 'lodash';

import * as CONSTANT from '../../constants';

/**
 * @function stepMaterialAnalyze
 * @description วิเคราะห์การใช้งานวัตถุดิบ ชนิดใดชนิดหนึ่งเมื่อผ่านจาก
 * Step แรกไปถึง Step ที่เราต้องการ
 * @param {Object} selectedMaterial  ข้อมูล Obj ของวัตถุดิบที่เราต้องการวิเคราะห์
 * @param {Array} steps ข้อมูล Array of Step ทั้งหมด
 * @param {Object} currentStep ข้อมูลของ Step ปัจจุบันที่เราต้องการวิเเคราะห์
 * @returns {Object} ของการวิเคราะห์
 */
export const stepMaterialAnalyze = (selectedMaterial, steps, currentStep) => {
  // กรองแล้วคิดข้อมูลเฉพาะของ Step เราและ Step ก่อนหน้า
  const filteredStep = _.filter(
    steps,
    (_step) =>
      _step?.index <= currentStep?.index &&
      (_step?.completed === true || _step?.deleted === true),
  );

  const stepMaterials = _.map(filteredStep, (_step) =>
    _.map(_step.materials, (_material) => ({
      ..._material,
      stepIndex: _step?.index,
    })),
  );

  // การกรองเฉพาะวัตถุดิบที่เลือก
  const filteredMaterialOnStep = _.map(stepMaterials, (_stepMaterialArray) =>
    _.filter(
      _stepMaterialArray,
      (_stepMaterial) =>
        _stepMaterial?.material?._id === selectedMaterial?.material?._id ||
        _stepMaterial?.material === selectedMaterial?.material?._id,
    ),
  );

  // การกรองข้อมูลข้างในให้เป็นแค่รูปแบบ Object
  const processedFilteredMaterial = _.map(
    filteredMaterialOnStep,
    (_filteredMaterial) => _.first(_filteredMaterial),
  );

  const thisMaterialLastStep = _.find(
    currentStep?.materials,
    (_material) =>
      _material?.material?._id === selectedMaterial?.material?._id ||
      _material.material === selectedMaterial?.material?._id,
  );

  const lastStepAmount = thisMaterialLastStep?.end;

  // ปริมาณที่เคยของ Request ทั้งหมด มีการลบพวก end ออกเพราะว่า จะได้ไม่นักซ้ำกับที่เบิกตอนแรก
  // และต้องลบ end สุดท้าย (ผลผลิต) ออกด้วย
  const requestAmount = selectedMaterial?.begin;
  const returnAmount = _.sumBy(processedFilteredMaterial, 'return');

  //  ปริมาณที่ถูกเอามาใช้จริง ๆ
  const usedAmount = requestAmount - returnAmount;

  // ค้นหา Yield
  const yielsValue = (lastStepAmount / usedAmount) * 100;

  // หา Original Data ของ Step ตรงนี้
  const originalResult = _.find(
    processedFilteredMaterial,
    (_stepMaterial) => _stepMaterial?.stepIndex === currentStep?.index,
  );

  return {
    requestAmount,
    returnAmount,
    usedAmount,
    remain: lastStepAmount,
    yield: yielsValue,
    originalResult,
  };
};

/**
 * @function allStepMaterialAnalyze
 * @description การหาผลการวิคราะห์การใช้วัตถุดิบในแต่ละ Step ในทุก ๆ Step ที่ผ่านมาแล้ว
 * @param {Object} selectedMaterial ข้อมูลวัตถุดิบที่เราต้องการวิเคราะห์
 * @param {Array} steps  Step ทั้งหมดของเรา
 * @returns  {Array} Array ของผลการวิเคราะห์
 */
export const allStepMaterialAnalyze = (selectedMaterial, steps) => {
  const stepWithMaterialAnalyze = _.map(steps, (_step) => ({
    _id: _step?._id,
    name: _step?.name,
    index: _step?.index,
    analyze: stepMaterialAnalyze(selectedMaterial, steps, _step),
  }));
  return stepWithMaterialAnalyze;
};

export const materialAnalyzer = (selectedMaterial, steps) => {
  // การกรองให้เหลือแต่วัตถุดิบ
  const stepMaterials = _.map(
    steps,
    _.filter(
      steps,
      (_step) => _step?.completed === true || _step?.deleted === true,
    ),
    (_step) => _step.materials,
  );

  // การกรองเฉพาะวัตถุดิบที่เลือก
  const filteredMaterialOnStep = _.map(stepMaterials, (_stepMaterialArray) =>
    _.filter(
      _stepMaterialArray,
      (_stepMaterial) =>
        _stepMaterial?.material?._id === selectedMaterial?.material?._id ||
        _stepMaterial?.material === selectedMaterial?.material?._id,
    ),
  );

  // การกรองข้อมูลข้างในให้เป็นแค่รูปแบบ Object
  const processedFilteredMaterial = _.map(
    filteredMaterialOnStep,
    (_filteredMaterial) => _.first(_filteredMaterial),
  );

  // หาข้อมูล Step สุดท้ายใหม่ เนื่องจากไม่มั่นใจการเรียงลำดับของ Array
  const lastStepNumber = _.max(_.map(steps, (_step) => _step.index));
  const lastStep = _.find(steps, (_step) => _step.index === lastStepNumber);

  const thisMaterialLastStep = _.find(
    lastStep?.materials,
    (_material) =>
      _material?.material?._id === selectedMaterial?.material?._id ||
      _material?.material === selectedMaterial?.material?._id,
  );

  const lastStepAmount = thisMaterialLastStep?.end;

  // ปริมาณที่เคยของ Request ทั้งหมด มีการลบพวก end ออกเพราะว่า จะได้ไม่นักซ้ำกับที่เบิกตอนแรก
  // และต้องลบ end สุดท้าย (ผลผลิต) ออกด้วย
  const requestAmount = selectedMaterial?.begin;
  const returnAmount = _.sumBy(processedFilteredMaterial, 'return');

  //  ปริมาณที่ถูกเอามาใช้จริง ๆ
  const usedAmount = requestAmount - returnAmount;

  // ค้นหา Yield
  const yielsValue = (lastStepAmount / usedAmount) * 100;

  return {
    requestAmount,
    returnAmount,
    usedAmount,
    remain: lastStepAmount,
    yield: yielsValue,
  };
};

/**
 * @function materialByMaterialAnalyzer
 * @description คือการค้นหาว่าวัตถุดิบแต่ละชิ้นในเดือน / ช่วงเวลาใดช่วงเวลาหนึ่งนั้น
 * ถูกใช้ไปเป็นผลิตภัณฑ์ใดบ้าง การผลิตใดบ้าง
 * @param {Object} materialData  ข้อมูลวัตถุดิบ ที่ข้างในมี Process ต่าง ๆ ของการผลิตเข้ามาด้วย
 * @returns {Object} ข้อมูลผลการวิเคราะห์
 */
export const materialByMaterialAnalyzer = (materialData) => {
  const allMaterialOnEveryProcess = _.map(
    materialData?.process_materials,
    (_process) => _process?.materials,
  );

  // หา Material ชิ้นนี้ในแต่ละ Process (ใน Process
  // อาจประกอบด้วยหลาย Material แต่เราจะกรองเฉพาะชิ้นนี้)
  const filteredMaterialOnEveryProcess = _.map(
    allMaterialOnEveryProcess,
    (_materialOnProcessArray) =>
      _.filter(
        _materialOnProcessArray,
        (_materialOnProcess) =>
          _materialOnProcess?.material === materialData?._id,
      ),
  );

  const flattedMaterialOnEveryProcess = _.flatten(
    filteredMaterialOnEveryProcess,
  );

  // หาจำนวนเบิก / คืนทั้งหมด ของ Material ชิ้นนี้
  const allRequestAmount = _.sum(
    _.map(flattedMaterialOnEveryProcess, (_material) => _material?.begin),
  );

  const allReturnAmount = _.sum(
    _.map(flattedMaterialOnEveryProcess, (_material) => _material?.return),
  );

  const processMaterial = materialData?.process_materials;

  // ค้นหา Step ของ Process จาก Material นี้
  const materialSteps = _.map(processMaterial, (_processMaterial) =>
    _.map(_processMaterial?.steps, (_step) => ({
      processInfo: _processMaterial,
      ..._step,
    })),
  );

  const flattenedSteps = _.flatten(materialSteps);
  const flattenStepByIndex = _.values(_.groupBy(flattenedSteps, 'index'));
  const onlyThisMaterialStepByIndex = _.map(flattenStepByIndex, (_flatenstep) =>
    _.map(_flatenstep, (_step) => ({
      ..._step,
      materials: _.find(
        _step?.materials,
        (_material) => _material?.material === materialData?._id,
      ),
    })),
  );

  // ค้นหาผลจริง ๆ ที่เกิดขึ้น โดยดูจากสินค้าที่เข้าไปในคลังสินค้า
  // มันจะมี Prod ที่เราคาดการณ์ process.product และ product
  // ที่ได้จริงตอนที่เรา Add เข้าคลัง process.product_stock_lots
  const resultProduct = _.map(
    processMaterial,
    (_processMaterial) =>
      _.map(_processMaterial?.product_stock_lots, (_prodStockLot) => ({
        processInfo: _processMaterial,
        ..._prodStockLot,
      })),
    // eslint-disable-next-line function-paren-newline
  );

  const flattenedResultProduct = _.flatten(resultProduct);
  const flatResultProdByProd = _.values(
    _.groupBy(flattenedResultProduct, 'product._id'),
  );

  const resultMaterial = _.map(
    processMaterial,
    (_processMaterial) =>
      _.map(_processMaterial?.product_as_materials, (_prodAsMaterial) => ({
        processInfo: _processMaterial,
        ..._prodAsMaterial,
      })),
    // eslint-disable-next-line function-paren-newline
  );

  const flattendedProductAsMaterial = _.flatten(resultMaterial);
  const falttenResultByMaterial = _.values(
    _.groupBy(flattendedProductAsMaterial, 'material._id'),
  );

  return {
    materialInfo: materialData,
    materialByProcess: flattedMaterialOnEveryProcess,
    requestAmount: allRequestAmount,
    returnAmount: allReturnAmount,
    usedAmount: allRequestAmount - allReturnAmount,
    allResultProducts: resultProduct,
    // Product ที่ได้จริงตอนเพิ่มเข้าคลังสินค้า แยกโดยใช้ Process อยู่
    resultByProducts: flatResultProdByProd, // product ที่ได้จริง แยกโดยใช้ Product,
    resultAsMaterials: falttenResultByMaterial,
    allProcesses: processMaterial,
    steps: materialSteps,
    stepByIndex: onlyThisMaterialStepByIndex,
  };
};

/**
 *
 * @typedef {Object} ManufacMaterialAnalyzed
 * @property {number}  requestAmount จำนวนที่ขอเบิกทั้งหมด
 * @property {number} returnAmount จำนวนที่คืนทั้งหมด
 * @property {number} wastesAmount จำนวนที่เป็นของเสียทั้งหมด
 * @property {number} usedAmount จำนวนที่ได้ใชิจริง
 * @property {number} resultAmount ผลผลิตที่ได้ หรือ วัตถุดิบที่ไม่เสีย
 * @property {number} mergedAmount ผลผลิตที่ถูกนำไปเป็นวัตถุดิบอื่น
 * @property {number} mergingAmount จำนวนทีเข้ามารวมกัน
 * @property {number} yield ผลการคำนวณ Yield ทั้งการผลิต
 *
 *
 * @function manufacturingMaterialAnalyzed
 * @description การวิเคราะห์ข้อมูลโดยส่ง analyzed จาก Redux State
 *  มา เป็นค่าที่วิเคราะห์มาแล้วจาก Backend
 * @param {Array} manufacMaterialAnalyzedArray ค่า Analyzed จาก State ของ Redux
 * ปกติมันจตะมี total currPage rows แต่อันนี้จะพิเศษเพราะว่ามันมี analyzed มาด้วย
 * @returns {ManufacMaterialAnalyzed} ผลการวิเคราะห์
 */

export const manufacturingMaterialAnalyzed = (
  manufacMaterialAnalyzedArray,
  allSteps = false,
) => {
  const requestAmount =
    _.find(
      manufacMaterialAnalyzedArray,
      (_manufacAnalyzedRes) =>
        _manufacAnalyzedRes?._id ===
        CONSTANT.MANUFACTURING_TRANSACTION_TYPE.MATERIAL_REQUEST.status_code,
    )?.sum || 0;

  const returnAmount =
    _.find(
      manufacMaterialAnalyzedArray,
      (_manufacAnalyzedRes) =>
        _manufacAnalyzedRes?._id ===
        CONSTANT.MANUFACTURING_TRANSACTION_TYPE.MATERIAL_RETURN.status_code,
    )?.sum || 0;

  const wastesAmount =
    _.find(
      manufacMaterialAnalyzedArray,
      (_manufacAnalyzedRes) =>
        _manufacAnalyzedRes?._id ===
        CONSTANT.MANUFACTURING_TRANSACTION_TYPE.MATERIAL_WASTED.status_code,
    )?.sum || 0;

  const mergedAmount =
    _.find(
      manufacMaterialAnalyzedArray,
      (_manufacAnalyzedRes) =>
        _manufacAnalyzedRes?._id ===
        CONSTANT.MANUFACTURING_TRANSACTION_TYPE.MATERIAL_MERGED.status_code,
    )?.sum || 0;

  const mergingAmount =
    _.find(
      manufacMaterialAnalyzedArray,
      (_manufacAnalyzedRes) =>
        _manufacAnalyzedRes?._id ===
        CONSTANT.MANUFACTURING_TRANSACTION_TYPE.MATERIAL_MERGING.status_code,
    )?.sum || 0;

  const handoverAmount =
    _.find(
      manufacMaterialAnalyzedArray,
      (_manufacAnalyzedRes) =>
        _manufacAnalyzedRes?._id ===
        CONSTANT.MANUFACTURING_TRANSACTION_TYPE.MATERIAL_HANDOVER.status_code,
    )?.sum || 0;

  return {
    requestAmount,
    returnAmount,
    wastesAmount,
    handoverAmount,
    mergedAmount,
    mergingAmount,
    usedAmount: (allSteps ? 0 : handoverAmount) + requestAmount - returnAmount,
    resultAmount:
      (allSteps ? 0 : handoverAmount) +
      requestAmount -
      returnAmount -
      wastesAmount -
      mergedAmount +
      mergingAmount,
    yield:
      (((allSteps ? 0 : handoverAmount) +
        requestAmount +
        mergingAmount -
        mergedAmount -
        returnAmount -
        wastesAmount) /
        ((allSteps ? 0 : handoverAmount) +
          requestAmount -
          returnAmount +
          mergingAmount -
          mergedAmount)) *
      100,
  };
};

/**
 * @typedef {Object} IDAndType
 * @property {string} material
 * @property {string} transaction_type
 *
 * @typedef {Object} ManufacMaterialAnalyzedArrayObj
 * @property {string} name
 * @property {number} quantity
 * @property {string} type_code
 * @property {string} unit
 * @property {IDAndType} _id
 *
 * @function manufacMaterialAnalyzedArrayAnalzer
 * @description วิเคราะห์การใช้งานวัตถุดิบในแต่ละไลน์การผลิต
 * @param {Array.<ManufacMaterialAnalyzedArrayObj>} analyzedArray ผลการวิเคราะห์ที่เราทำมาใน
 *  Pipeline ของ manufacturingOrder Service
 */
export const manufacMaterialAnalyzedArrayAnalzer = (analyzedArray) => {
  const byMaterialAnalyzed = _.values(_.groupBy(analyzedArray, 'material._id'));

  const resultPayload = _.map(byMaterialAnalyzed, (_materialAnalysis) => {
    const requestAmount =
      _.find(
        _materialAnalysis,
        (_manufacAnalyzedRes) =>
          _manufacAnalyzedRes?.transaction_type ===
          CONSTANT.MANUFACTURING_TRANSACTION_TYPE.MATERIAL_REQUEST.status_code,
      )?.quantity || 0;

    const returnAmount =
      _.find(
        _materialAnalysis,
        (_manufacAnalyzedRes) =>
          _manufacAnalyzedRes?.transaction_type ===
          CONSTANT.MANUFACTURING_TRANSACTION_TYPE.MATERIAL_RETURN.status_code,
      )?.quantity || 0;

    const wastesAmount =
      _.find(
        _materialAnalysis,
        (_manufacAnalyzedRes) =>
          _manufacAnalyzedRes?.transaction_type ===
          CONSTANT.MANUFACTURING_TRANSACTION_TYPE.MATERIAL_WASTED.status_code,
      )?.quantity || 0;
    const mergedAmount =
      _.find(
        _materialAnalysis,
        (_manufacAnalyzedRes) =>
          _manufacAnalyzedRes?.transaction_type ===
          CONSTANT.MANUFACTURING_TRANSACTION_TYPE.MATERIAL_MERGED.status_code,
      )?.quantity || 0;
    const mergingAmount =
      _.find(
        _materialAnalysis,
        (_manufacAnalyzedRes) =>
          _manufacAnalyzedRes?.transaction_type ===
          CONSTANT.MANUFACTURING_TRANSACTION_TYPE.MATERIAL_MERGING.status_code,
      )?.quantity || 0;

    const materialStockLot = _.map(
      _materialAnalysis,
      (each) => each?.material_stock_lot,
    );
    return {
      materialInfo: {
        _id:
          _materialAnalysis[0]?.material || _materialAnalysis[0]?._id?.material,
        ..._materialAnalysis?.[0]?.material,
      },
      requestAmount,
      returnAmount,
      wastesAmount,
      mergedAmount,
      mergingAmount,
      usedAmount: requestAmount - returnAmount,
      resultAmount:
        requestAmount -
        returnAmount -
        wastesAmount -
        mergedAmount +
        mergingAmount,
      yield:
        ((requestAmount -
          returnAmount -
          wastesAmount -
          mergedAmount +
          mergingAmount) /
          (requestAmount - returnAmount - mergedAmount + mergingAmount)) *
        100,
      lots: materialStockLot,
    };
  });

  return resultPayload;
};

/**
 * @function unNullManufacMaterialAnalyzedArrayAnalzer
 * @description วิเคราะห์การใช้งานวัตถุดิบในแต่ละไลน์การผลิต
 * @param {Array.<ManufacMaterialAnalyzedArrayObj>} analyzedArray ผลการวิเคราะห์ที่เราทำมาใน
 *  Pipeline ของ manufacturingOrder Service
 */

export const unNullManufacMaterialAnalyzedArrayAnalzer = (analyzedArray) =>
  _.filter(
    manufacMaterialAnalyzedArrayAnalzer(analyzedArray),
    (_material) => _material?.materialInfo?._id !== undefined,
  );

/**
 * @function unZeroManufacMaterialAnalyzedArrayAnalyzer
 * @description วิเคราะห์การใช้งานวัตถุดิบในแต่ละไลน์การผลิต
 * @param {Array.<ManufacMaterialAnalyzedArrayObj>} analyzedArray ผลการวิเคราะห์ที่เราทำมาใน
 *  Pipeline ของ manufacturingOrder Service
 */

export const unZeroManufacMaterialAnalyzedArrayAnalyzer = (analyzedArray) =>
  _.filter(
    manufacMaterialAnalyzedArrayAnalzer(analyzedArray),
    (_material) =>
      _material?.materialInfo?._id !== undefined &&
      _material?.resultAmount !== 0,
  );

/**
 * @function manufacturingMaterialStaticAnalyze
 * @description การคำนวณสรุปว่ามการเบิกมาเท่าไหร่ ใช้เท่าไหร่ คืน เสียเท่าไหร่
 * และได้ Yield เท่าไหร่ โดยรับจาก Array ของค่าใน State
 * @param {Array.<ManufacMaterialAnalyzedArrayObj>} manufacturingMaterialArrays
 * Array ของค่า manufacturingMaterial ใน State
 * @returns Object ผลการวิเคราะห์
 */
export const manufacturingMaterialStaticAnalyze = (
  manufacturingMaterialArrays,
  latestStepAmount = 0,
) => {
  const incomingAmount = latestStepAmount;

  const requestedOrders = _.filter(
    manufacturingMaterialArrays,
    (_manufacMat) =>
      _manufacMat?.transaction_type ===
      CONSTANT.MANUFACTURING_TRANSACTION_TYPE.MATERIAL_REQUEST.status_code,
  );
  const requestAmount = _.sumBy(requestedOrders, 'quantity');
  console.log('MMSA | Request Amount', requestAmount);

  const returnOrder = _.filter(
    manufacturingMaterialArrays,
    (_manufacMat) =>
      _manufacMat?.transaction_type ===
      CONSTANT.MANUFACTURING_TRANSACTION_TYPE.MATERIAL_RETURN.status_code,
  );
  const returnAmount = _.sumBy(returnOrder, 'quantity');

  const wastedOrder = _.filter(
    manufacturingMaterialArrays,
    (_manufacMat) =>
      _manufacMat?.transaction_type ===
      CONSTANT.MANUFACTURING_TRANSACTION_TYPE.MATERIAL_WASTED.status_code,
  );
  const wasteAmount = _.sumBy(wastedOrder, 'quantity');
  const productStockLot = _.map(
    manufacturingMaterialArrays,
    (each) => each.product_stock_lots,
  );

  const flattenedResultProduct = _.flatten(productStockLot);
  const flatResultProdByProd = _.values(
    _.groupBy(flattenedResultProduct, 'product._id'),
  );

  const materialResultStockLot = _.map(
    manufacturingMaterialArrays,
    (each) => each.material_stock_lots,
  );

  const flattenResultMaterial = _.flatten(materialResultStockLot);
  const flatResultMaterialByMaterial = _.values(
    _.groupBy(flattenResultMaterial, 'material._id'),
  );

  return {
    incomingAmount,
    requestAmount,
    returnAmount,
    wasteAmount,
    usedAmount: requestAmount + incomingAmount - returnAmount,
    resultAmount: requestAmount + incomingAmount - wasteAmount - returnAmount,
    yield:
      ((incomingAmount + requestAmount - returnAmount - wasteAmount) /
        (incomingAmount + requestAmount - returnAmount)) *
      100,
    productStockLot: flatResultProdByProd,
    materialResultStockLot: flatResultMaterialByMaterial,
  };
};

export const manufacturingMaterialAllStepByStepAnalyzer = (
  manufacturingMaterialArrays,
) => {
  // Before step
  const beforeStepResult = _.find(
    manufacturingMaterialArrays,
    (_manufacMat) => _manufacMat?.[0]?.step?.index === undefined,
  );

  const beforeStepAnalyse = manufacturingMaterialStaticAnalyze(
    beforeStepResult,
  );

  // console.log('MMSAS | Before step analyze ', beforeStepAnalyse);

  // By Step
  const filteredManufacByStep = _.orderBy(
    _.filter(
      manufacturingMaterialArrays,
      (_manufacMat) =>
        _manufacMat?.[0]?.step?.index !== undefined ||
        _manufacMat?.[0]?.step?.index !== -1,
    ),
    ['0.step.index'],
  );

  // console.log('MMSAS| All Steps except before step ', filteredManufacByStep);

  const allStepResult = [beforeStepAnalyse];
  _.map(filteredManufacByStep, (_filterManufacByStep, index) => {
    if (index === 0) {
      // console.log('MMSAS | Processing Step 1 🎈 ');
      const currentStepResult = manufacturingMaterialStaticAnalyze(
        _filterManufacByStep,
        beforeStepAnalyse.resultAmount,
      );
      // console.log('MMSAS | Step 1 Result', currentStepResult);
      allStepResult.push(currentStepResult);
    } else {
      // console.log('MMSAS | Processing Step ', index + 1, ' 🎈 ');
      const currentStepResult = manufacturingMaterialStaticAnalyze(
        _filterManufacByStep,
        _.last(allStepResult)?.resultAmount,
      );
      // console.log('MMSAS | Step', index + 1, ' Result', currentStepResult);
      allStepResult.push(currentStepResult);
    }
  });

  return allStepResult;
};
export default {
  materialAnalyzer,
  materialByMaterialAnalyzer,
  manufacturingMaterialAnalyzed,
  allStepMaterialAnalyze,
  manufacMaterialAnalyzedArrayAnalzer,
  manufacturingMaterialStaticAnalyze,
  unNullManufacMaterialAnalyzedArrayAnalzer,
  unZeroManufacMaterialAnalyzedArrayAnalyzer,
  manufacturingMaterialAllStepByStepAnalyzer,
};
