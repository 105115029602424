import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Card } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import {
  MaterialRequestForm,
  SelectMaterialForm,
} from '@iarcpsu/emanufac-components/src/components/Forms';
import Loading from '@iarcpsu/emanufac-components/src/components/Loading';
import { Error } from '@iarcpsu/emanufac-components/src/components/Error';
import { ViewTitle } from '@iarcpsu/emanufac-components/src/components/ViewTitle';
import { BackButton } from '@iarcpsu/emanufac-components/src/components/Button';
import { MATERIAL_REQUEST_STATUS } from '../../utils/constants';

const defaultValues = {
  material: '',
  remark: '',
};

const CreateMaterialRequest = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const material = useSelector((state) => state.material);
  const department = useSelector((state) => state.department);
  const me = useSelector((state) => state.me);

  const [selectMaterial, setSelectMaterial] = useState([]);
  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [name] = useState('');
  const [page] = useState(1);
  const [size] = useState(5);

  useEffect(() => {
    dispatch(actions.materialAll({ name: '', size: '', page: 1 }));
    dispatch(actions.departmentAll(''));
    dispatch(actions.meGet(''));
    return () => {};
  }, [name, page, size]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data, event) => {
    try {
      if (_.isEmpty(selectMaterial)) {
        alert('กรุณาเลือกวัตถุดิบ');
      } else {
        data.material = selectMaterial;
        data.status = MATERIAL_REQUEST_STATUS.PENDING_APPROVAL.status_code;
        event.preventDefault();
        await dispatch(actions.materialRequestCreate(data));
        reset(defaultValues);
        alert('สำเร็จ');
        history.goBack();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderForm = () => (
    <Card className="p-4">
      <div>
        <MaterialRequestForm
          Controller={Controller}
          control={control}
          errors={errors}
          department={department.rows}
          me={me.userData}
        />
      </div>
    </Card>
  );

  const renderSelectMaterial = () => (
    <Card>
      <SelectMaterialForm
        material={material.rows}
        setSelectMaterial={setSelectMaterial}
      />
    </Card>
  );

  if (material.isLoading) {
    return <Loading />;
  }
  if (!material.isLoading && material.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-row flex-wrap">
            <div className="w-full lg:w-1/3 px-1 py-2">{renderForm()}</div>
            <div className="w-full lg:w-2/3 px-1 py-2">
              {renderSelectMaterial()}
            </div>
          </div>
          <div className="flex flex-row justify-end gap-1 py-4">
            <Button variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    );
  }
  return <Error message={material?.message} />;
};

CreateMaterialRequest.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateMaterialRequest.defaultProps = {
  title: '',
  subtitle: '',
};

export default CreateMaterialRequest;
