/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as CONSTANT from '@/utils/constants';

import { MultiUnitProdOrMatBox } from '../../Box';
import { DesktopDatepicker } from '../../DatePicker';
import ProductAmountInput from '../../Inputs/ProdOrMatQuantityInput';

const AddToWarehouseForm = ({
  control,
  productTransactionType,
  warehouse,
  errors,
  customer,
  watch,
  product,
  setValue,
}) => {
  const information = useSelector((state) => state.information);
  const [reload, setReload] = useState(false);

  const {
    setting: { wms: { display: wmsDisplaySetting } = {}, mms: mmsSetting },
  } = information;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'product',
  });

  const filteredProductTxnType = _.filter(
    productTransactionType?.rows,
    (_txnType) =>
      _txnType?.direction ===
      CONSTANT.PRODUCT_TRANSACTION_JSON_TYPE.add.direction,
  );
  return (
    <div>
      <h3 className="text-lg font-semibold">เพิ่มสินค้าลงในคลังสินค้า</h3>
      <div className="w-full py-2 my-1">
        <Controller
          name="product_transaction_type"
          control={control}
          rules={{ required: true }}
          defaultValue={filteredProductTxnType?.[0]?._id}
          render={({ field }) => (
            <FormControl fullWidth required size="small">
              <InputLabel>ประเภทการดำเนินการ</InputLabel>
              <Select
                {...field}
                label="ประเภทการดำเนินการ"
                defaultValue={field.value}
              >
                {_.map(filteredProductTxnType, (_txnType, index) => (
                  <MenuItem key={index} value={_txnType?._id}>
                    {_txnType?.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {errors?.product_transaction_type &&
                  'กรุณาเลือกประเภทการดำเนินการ'}
              </FormHelperText>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full py-2 my-1">
        <Controller
          name="warehouse"
          control={control}
          rules={{ required: true }}
          defaultValue={warehouse?.rows?.[0]?._id}
          render={({ field }) => (
            <FormControl fullWidth required size="small">
              <InputLabel>คลังสินค้า</InputLabel>
              <Select {...field} label="คลังสินค้า">
                {_.map(warehouse?.rows, (_warehouse, index) => (
                  <MenuItem key={index} value={_warehouse?._id}>
                    {_warehouse?.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {errors?.warehouse && 'กรุณาเลือกคลังสินค้า'}
              </FormHelperText>
            </FormControl>
          )}
        />
      </div>
      {product?.rows && (
        <div className="overflow-x-auto">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ลำดับที่</TableCell>
                <TableCell>สินค้า</TableCell>
                {wmsDisplaySetting?.cost_price && (
                  <TableCell>
                    <div className="whitespace-nowrap">ราคาทุน</div>{' '}
                  </TableCell>
                )}
                {wmsDisplaySetting?.lot_number && (
                  <TableCell>หมายเลขล็อต </TableCell>
                )}
                {wmsDisplaySetting?.expiration_date ||
                  (wmsDisplaySetting?.production_date && (
                    <TableCell>วันที่ผลิต / วันหมดอายุ </TableCell>
                  ))}{' '}
                {mmsSetting?.usingAsDefault?.attachCustomer && (
                  <TableCell>ลูกค้า </TableCell>
                )}
                <TableCell>จำนวน </TableCell>
                <TableCell>ดำเนินการ </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(fields, (_field, index) => (
                <TableRow key={_field?.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <div className="w-48">
                      <Controller
                        name={`product[${index}].product`}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Autocomplete
                            size={'small'}
                            disableClearable
                            {...field}
                            onChange={(e, newValue) => {
                              field.onChange(newValue);
                              setReload(!reload);
                            }}
                            options={product?.rows}
                            getOptionLabel={(option) => {
                              if (option.type_code) {
                                return `${option?.type_code} | ${option?.name}`;
                              }
                              return option?.name;
                            }}
                            required
                            renderInput={(params) => (
                              <TextField {...params} label="สินค้า" required />
                            )}
                          />
                        )}
                      />
                    </div>
                  </TableCell>
                  {wmsDisplaySetting?.cost_price && (
                    <TableCell>
                      <div className="w-24">
                        <Controller
                          name={`product[${index}].cost_price`}
                          control={control}
                          rules={{ required: false }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="number"
                              label="ราคาทุน"
                              fullWidth
                              size={'small'}
                              helperText={
                                errors?.product?.[index]?.price &&
                                'กรุณาใส่ข้อมูล '
                              }
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                  )}
                  {wmsDisplaySetting?.lot_number && (
                    <TableCell>
                      <div className="w-48">
                        <Controller
                          name={`product[${index}].lot_number`}
                          control={control}
                          rules={{ required: false }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="หมายเลขล็อต"
                              fullWidth
                              size={'small'}
                              helperText={
                                errors?.product?.[index]?.lot_number &&
                                'กรุณาใส่ข้อมูล '
                              }
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                  )}
                  {(wmsDisplaySetting?.production_date ||
                    wmsDisplaySetting?.expiration_date) && (
                    <TableCell>
                      <div className="w-64">
                        {wmsDisplaySetting?.production_date && (
                          <div className="py-2">
                            <Controller
                              name={`product[${index}].production_date`}
                              control={control}
                              defaultValue={new Date()}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <DesktopDatepicker
                                  {...field}
                                  label="วันผลิต"
                                  fullWidth
                                  size="small"
                                  inputFormat="DD/MM/YYYY"
                                />
                              )}
                            />
                          </div>
                        )}
                        {wmsDisplaySetting?.expiration_date && (
                          <div className="py-2">
                            <Controller
                              name={`product[${index}].expiration_date`}
                              control={control}
                              defaultValue={new Date()}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <DesktopDatepicker
                                  {...field}
                                  label="วันหมดอายุ"
                                  fullWidth
                                  size="small"
                                  inputFormat="DD/MM/YYYY"
                                />
                              )}
                            />{' '}
                          </div>
                        )}
                      </div>
                    </TableCell>
                  )}
                  {mmsSetting?.usingAsDefault?.attachCustomer &&
                    !_.isEmpty(customer?.rows) && (
                      <TableCell>
                        <div className="w-48">
                          <Controller
                            name={`product[${index}].customer`}
                            control={control}
                            rules={{ required: true, min: 1 }}
                            render={({ field }) => (
                              <Autocomplete
                                size={'small'}
                                disableClearable
                                {...field}
                                onChange={(e, newValue) => {
                                  field.onChange(newValue);
                                  setReload(!reload);
                                }}
                                options={customer?.rows}
                                getOptionLabel={(option) =>
                                  `${option?.type_code} | ${option?.name}`
                                }
                                required
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="ลูกค้า"
                                    required
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      </TableCell>
                    )}
                  <TableCell>
                    <div className="w-52">
                      <ProductAmountInput
                        control={control}
                        controllerName={`product[${index}].quantity`}
                        artificialControllerName={`product[${index}].quantity_artificial`}
                        index={index}
                        selectedProduct={watch(`product[${index}].product`)}
                        selectedUnitControllerName={`product[${index}].selected_unit`}
                        setValue={setValue}
                        watch={watch}
                      />
                      <MultiUnitProdOrMatBox
                        foundMaterial={watch(`product[${index}].product`)}
                        quantity={watch(`product[${index}].quantity`)}
                        noWrap
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={() => {
                        remove(index);
                      }}
                    >
                      ลบ{' '}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}{' '}
              <TableRow>
                <TableCell>
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      append({
                        product: null,
                        quantity: 0,
                        cost_price: 0,
                        production_date: dayjs().format('YYYY-MM-DD'),
                        expiration_date: dayjs().format('YYYY-MM-DD'),
                      });
                    }}
                  >
                    เพิ่ม{' '}
                  </Button>
                </TableCell>
                <TableCell></TableCell>
                <TableCell> </TableCell>
                <TableCell></TableCell>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};

AddToWarehouseForm.propTypes = {
  control: PropTypes.func,
  productTransactionType: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  warehouse: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  errors: PropTypes.object,
  customer: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  product: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  watch: PropTypes.func,
  setValue: PropTypes.func,
};

export default AddToWarehouseForm;
