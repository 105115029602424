import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from '@mui/material';
import dayjs from 'dayjs';
import hash from 'object-hash';
import PropTypes from 'prop-types';

import {
  BackButton,
  DesktopDatepicker,
  LoadingDialog,
  SocketIOLoading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import * as actions from '@/redux/actions';
import { MQ_TASK } from '@/utils/constants';
/**
 * @function SettingProcess
 * @memberof MMS/Setting
 * @description ตั้งค่ากระบวนการทำงาน การเบิกวัตถุดิบ การคืนของ
 * การเพิ่มลงในคลังสินค้า คลังวัตถุดิบ หริืออื่นๆ
 */

const SystemAudit = ({ title, subtitle }) => {
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const [referenceNumber, setReferenceNumber] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [dialogLoading, setOpenDialogLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().subtract(1, 'year').toDate(),
  );
  const [selectedDateForStep, setSelectedDateForStep] = useState(
    dayjs().subtract(1, 'year').toDate(),
  );
  const [selectedDateForMM, setSelectedDateForMM] = useState(
    dayjs().subtract(1, 'year').toDate(),
  );
  const me = useSelector((state) => state.me);
  const dispatch = useDispatch();

  useEffect(() => {
    const refNo = hash({
      date: new Date(),
      user: me?.userData?._id,
    });

    setReferenceNumber(refNo);

    return () => {};
  }, []);

  const onAuditManufacturingOrder = async () => {
    try {
      setOpenDialogLoading(true);
      dispatch(actions.manufacturingOrderAudit({ referenceNumber }));
    } catch (error) {
      console.error(error);
    }
  };

  const onAuditProcess = async () => {
    try {
      setOpenDialogLoading(true);
      dispatch(actions.processAudit({ referenceNumber }));
    } catch (error) {
      console.error(error);
    }
  };

  const onAuditStep = async () => {
    try {
      setOpenDialogLoading(true);
      dispatch(actions.stepAudit({ referenceNumber }));
    } catch (error) {
      console.error(error);
    }
  };

  const onForceLogAudit = async () => {
    try {
      setOpenDialogLoading(true);
      dispatch(
        actions.manufacturingOrderForceAudit({
          referenceNumber,
          selectedTime: selectedDate,
        }),
      );
    } catch (error) {
      console.error(error);
    }
  };

  const onForceMoveStepAudit = async () => {
    try {
      setOpenDialogLoading(true);
      dispatch(
        actions.stepForceMoveAudit({
          referenceNumber,
          selectedTime: selectedDateForStep,
        }),
      );
    } catch (error) {
      console.error(error);
    }
  };

  const onForceMovingManufacturingMaterial = async () => {
    try {
      setOpenDialogLoading(true);
      dispatch(
        actions.forceMovingManufacturingMaterial({
          referenceNumber,
          selectedTime: selectedDateForMM,
        }),
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleSocketIOFunction = {
    onSuccess: () => {
      setOpenDialogLoading(false);
      alert('ดำเนินการสำเร็จ');
    },
    onFail: (args) => {
      setOpenDialogLoading(false);
      alert(args?.message);
    },
  };

  return (
    <div>
      {renderTitle()}
      <LoadingDialog isLoading={dialogLoading} label={statusMessage} />
      <div className="flex flex-row justify-start pb-4">
        <div>
          <BackButton />
        </div>
      </div>

      <SocketIOLoading
        taskCode={MQ_TASK.AUDIT_MANUFACTURING_ORDER.status_code}
        handleSuccess={handleSocketIOFunction.onSuccess}
        handleFail={handleSocketIOFunction.onFail}
        referenceNumber={referenceNumber}
        setStatusMessage={setStatusMessage}
      />
      <SocketIOLoading
        taskCode={MQ_TASK.AUDIT_PROCESS.status_code}
        handleSuccess={handleSocketIOFunction.onSuccess}
        handleFail={handleSocketIOFunction.onFail}
        referenceNumber={referenceNumber}
        setStatusMessage={setStatusMessage}
      />
      <SocketIOLoading
        taskCode={MQ_TASK.AUDIT_STEP.status_code}
        handleSuccess={handleSocketIOFunction.onSuccess}
        handleFail={handleSocketIOFunction.onFail}
        referenceNumber={referenceNumber}
        setStatusMessage={setStatusMessage}
      />
      <SocketIOLoading
        taskCode={MQ_TASK.FORCE_AUDIT_MANUFACTURING_ORDER.status_code}
        handleSuccess={handleSocketIOFunction.onSuccess}
        handleFail={handleSocketIOFunction.onFail}
        referenceNumber={referenceNumber}
        setStatusMessage={setStatusMessage}
      />
      <SocketIOLoading
        taskCode={MQ_TASK.FORCE_AUDIT_STEP.status_code}
        handleSuccess={handleSocketIOFunction.onSuccess}
        handleFail={handleSocketIOFunction.onFail}
        referenceNumber={referenceNumber}
        setStatusMessage={setStatusMessage}
      />
      <SocketIOLoading
        taskCode={MQ_TASK.FORCE_MOVE_MM.status_code}
        handleSuccess={handleSocketIOFunction.onSuccess}
        handleFail={handleSocketIOFunction.onFail}
        referenceNumber={referenceNumber}
        setStatusMessage={setStatusMessage}
      />
      <Card className="p-4">
        <div className="flex flex-wrap w-full ">
          <div className="w-full py-2 px-2 font-semibold font-display ">
            การปรับข้อมูลคำสั่งผลิต / ไลน์ผลิต / งาน เพือการทำงานที่เร็วขึ้น
          </div>

          <div className="w-3/4 py-2 px-2">ปรับข้อมูลคำสั่งผลิต</div>
          <div className="w-1/4 py-2 px-2">
            <Button
              variant="contained"
              onClick={() => onAuditManufacturingOrder()}
            >
              ปรับ
            </Button>
          </div>

          <div className="w-3/4 py-2 px-2  ">ปรับข้อมูลไลน์การผลิต</div>
          <div className="w-1/4 py-2 px-2">
            <Button variant="contained" onClick={() => onAuditProcess()}>
              ปรับ
            </Button>
          </div>

          <div className="w-3/4 py-2 px-2">ปรับข้อมูลงาน</div>
          <div className="w-1/4 py-2 px-2">
            <Button variant="contained" onClick={() => onAuditStep()}>
              ปรับ
            </Button>
          </div>
          <div className="w-3/4 py-2 px-2">
            Force ปรับข้อมูลคำสั่งผลิต (ย้ายคำสั่งผลิตไปยังประวัติคำสั่งผลิต)
            <div>
              <div>ตั้งแต่วันเริ่ม - วันที่ที่เลือก</div>
              <div className="my-2">
                <DesktopDatepicker
                  value={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  label="วันที่เลือก"
                  size="small"
                />
              </div>
            </div>
          </div>
          <div className="w-1/4 py-2 px-2">
            <Button variant="contained" onClick={() => onForceLogAudit()}>
              ปรับ
            </Button>
          </div>
          <div className="w-3/4 py-2 px-2">
            Force ปรับข้อมูลงาน (ย้ายงานไปยังประวัติงาน)
            <div>
              <div>ตั้งแต่วันเริ่ม - วันที่ที่เลือก</div>
              <div className="my-2">
                <DesktopDatepicker
                  value={selectedDateForStep}
                  onChange={(date) => setSelectedDateForStep(date)}
                  label="วันที่เลือก"
                  size="small"
                />
              </div>
            </div>
          </div>
          <div className="w-1/4 py-2 px-2">
            <Button variant="contained" onClick={() => onForceMoveStepAudit()}>
              ปรับ
            </Button>
          </div>
          <div className="w-3/4 py-2 px-2">
            Force ปรับข้อมูลการใช้วัตถุดิบในการผลิต (ย้ายงานไปยังประวัติงาน)
            <div>
              <div>ตั้งแต่วันเริ่ม - วันที่ที่เลือก</div>
              <div className="my-2">
                <DesktopDatepicker
                  value={selectedDateForMM}
                  onChange={(date) => setSelectedDateForMM(date)}
                  label="วันที่เลือก"
                  size="small"
                />
              </div>
            </div>
          </div>
          <div className="w-1/4 py-2 px-2">
            <Button
              variant="contained"
              onClick={() => onForceMovingManufacturingMaterial()}
            >
              ปรับ
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

SystemAudit.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SystemAudit.defaultProps = {
  title: '',
  subtitle: '',
};
export default SystemAudit;
