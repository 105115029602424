import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  DASHBOARD_ELEMENT_VALUE_TYPE,
  DASHBOARD_TYPE,
} from '@/utils/constants';
import { MMS } from '@/utils/functions';

import TableDashboardElementRendering from './TableDashboardElementRendering';

/**
 * @function DashboardElementRenderingComponent
 * @description ตัวแสดง Dashboard Element ออกมาทั้งระดับ MO หรือ ระดับ Process
 * @param {object} props
 * @param {object} props.dashboardElement Object ของ Dashboard ELement
 * @param {string} props.type type ที่จะเอาไว้ใช้โชว์แดชบอร์ด
 * @param {boolean} props.showAsExample แสดงเป็นตัวอย่างหรือไม่
 * ถ้าเป็นตัวอย่างมันจะไม่เอาข้อมูลมา Render
 * @param {boolean} props.fixWidth ยึดขนาดตามที่ตั้งไว้ตอนสร้าง Component หรือไม่
 * ถ้าไม่จะแสดงขนาดเต็ม
 * @returns {React.Component} React.Component
 */

const DashboardElementRenderingComponent = ({
  dashboardElement,
  type,
  manufacturingOrder,
  showAsExample,
}) => {
  const props = { dashboardElement, type, manufacturingOrder, showAsExample };
  const { moLevelDashboardDataPrepare } = MMS;

  if (dashboardElement?.is_mo_level) {
    const dashboardRows = showAsExample
      ? []
      : moLevelDashboardDataPrepare(manufacturingOrder, dashboardElement);
    switch (type) {
      // TODO: Create Dashboard for BarChart
      case DASHBOARD_TYPE.BAR_CHART.status_code:
        return <div>ระบบยังไม่พร้อมให้บริการ</div>;
      default:
        return (
          <TableDashboardElementRendering
            {...props}
            dashboardRows={dashboardRows}
          />
        );
    }
  } else {
    // TODO: Create Dashboard for Process level
    return <div>ระบบยังไม่พร้อมใช้งาน</div>;
  }
};

DashboardElementRenderingComponent.propTypes = {
  dashboardElement: PropTypes.object,
  type: PropTypes.string,
  showAsExample: PropTypes.bool,
  manufacturingOrder: PropTypes.object,
};

export default DashboardElementRenderingComponent;
