import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import 'dayjs/locale/th';

import NameBox from '../../Box/Common/NameBox';
import MultiUnitProdOrMatBox from '../../Box/Rendering/MultiUnitProdOrMatBox';

export default function InternalDepositTable({
  internalDeposit,
  page,
  size,
  total,
  setPage,
  setSize,
  handleDelete,
  handlePrintPdf,
}) {
  const renderStatus = (selectRow) => {
    if (selectRow?.completed) {
      return (
        <div className="bg-green-300 text-center p-1 rounded-md my-1">
          สั่งผลิตแล้ว
        </div>
      );
    }

    if (selectRow?.deleted) {
      return (
        <div className="bg-red-300 text-center p-1 rounded-md my-1">ปฏิเสธ</div>
      );
    }

    return <div></div>;
  };

  return (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            {/* <colgroup>
              <col width="5%" />
              <col width="8%" />
              <col width="8%" />
              <col width="8%" />
              <col width="10%" />
              <col width="15%" />
              <col width="8%" />
              <col width="20%" />
            </colgroup> */}
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">หมายเลข</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">วันสร้างใบ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วันที่กำหนดจัดส่ง</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">ใบเสนอราคา</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ลูกค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">สินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ผู้เสนอราคา</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(internalDeposit?.rows) ? (
                internalDeposit?.rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>{' '}
                    <TableCell>
                      {row?.running_number}
                      {renderStatus(row)}
                    </TableCell>
                    <TableCell>
                      {dayjs(row?.start_date).locale('th').format('D MMM BBBB')}
                    </TableCell>
                    <TableCell>
                      {dayjs(row?.expected_date)
                        .locale('th')
                        .format('D MMM BBBB')}
                    </TableCell>
                    <TableCell>
                      {row?.customer?.type_code || ''} {row?.customer?.name}
                    </TableCell>{' '}
                    <TableCell>
                      {row?.quotation?.prefix || ''}
                      {row?.quotation?.running_number || '-'}
                    </TableCell>
                    <TableCell>
                      {_.map(row?.processes, (eachProdOfOrder, prodIndex) => {
                        if (eachProdOfOrder?.produce_material_instead) {
                          return (
                            <div key={prodIndex}>
                              <span>
                                {prodIndex + 1}
                                {'. '}
                              </span>
                              <span className=" font-semibold font-display">
                                {
                                  eachProdOfOrder?.product_as_material
                                    ?.type_code
                                }
                              </span>{' '}
                              <span>
                                {eachProdOfOrder?.product_as_material?.name}
                              </span>{' '}
                              {eachProdOfOrder?.additional_unit ? (
                                <span>
                                  {eachProdOfOrder?.quantity}{' '}
                                  {eachProdOfOrder?.additional_unit}{' '}
                                </span>
                              ) : (
                                <MultiUnitProdOrMatBox
                                  foundMaterial={
                                    eachProdOfOrder?.product_as_material
                                  }
                                  quantity={eachProdOfOrder?.quantity}
                                />
                              )}
                            </div>
                          );
                        }

                        return (
                          <div key={prodIndex}>
                            <span>
                              {prodIndex + 1}
                              {'. '}
                            </span>
                            <span className=" font-semibold font-display">
                              {eachProdOfOrder?.product?.type_code}
                            </span>{' '}
                            <span>{eachProdOfOrder?.product?.name}</span>{' '}
                            {eachProdOfOrder?.additional_unit ? (
                              <span>
                                {eachProdOfOrder?.quantity}{' '}
                                {eachProdOfOrder?.additional_unit}{' '}
                              </span>
                            ) : (
                              <MultiUnitProdOrMatBox
                                foundMaterial={eachProdOfOrder?.product}
                                quantity={eachProdOfOrder?.quantity}
                              />
                            )}
                          </div>
                        );
                      })}
                    </TableCell>
                    <TableCell>
                      <NameBox user={row?.creator} />
                    </TableCell>
                    <TableCell>
                      {' '}
                      <div className="flex flex-row flex-wrap gap-1">
                        {!row?.completed && !row?.deleted && (
                          <div>
                            <Link
                              to={`/mms/manufacturing-orders/create?internal-deposit=${row?._id}`}
                            >
                              <Button
                                variant="contained"
                                color="success"
                                size="small"
                              >
                                สั่งผลิต
                              </Button>
                            </Link>
                          </div>
                        )}
                        <div>
                          <Link to={`/spm/internal-deposit/detail/${row?._id}`}>
                            <Button
                              variant="contained"
                              color="info"
                              size={'small'}
                            >
                              รายละเอียด
                            </Button>
                          </Link>
                        </div>{' '}
                        <Link
                          to={
                            row?.completed || row?.deleted
                              ? ''
                              : `/spm/internal-deposit/edit/${row?._id}`
                          }
                        >
                          <Button
                            variant="contained"
                            color={'warning'}
                            size={'small'}
                            disabled={row?.completed || row?.deleted}
                          >
                            แก้ไข
                          </Button>
                        </Link>
                        <Button
                          variant="contained"
                          color={'error'}
                          size={'small'}
                          onClick={() => {
                            handleDelete(row?._id);
                          }}
                          disabled={row?.completed || row?.deleted}
                        >
                          ลบ
                        </Button>
                        <Button
                          variant="contained"
                          color={'teal'}
                          size={'small'}
                          onClick={() => {
                            handlePrintPdf(row);
                          }}
                        >
                          พิมพ์
                        </Button>
                        <Link
                          to={`/spm/internal-deposit/transaction/${row?._id}`}
                        >
                          <Button
                            variant="contained"
                            color="inherit"
                            size="small"
                          >
                            ประวัติกิจกรรม
                          </Button>
                        </Link>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          onRowsPerPageChange={(event) => {
            setSize(event.target.value);
            setPage(1);
          }}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={(event, newValue) => {
            setPage(newValue + 1);
          }}
        />
      </Paper>
    </div>
  );
}

InternalDepositTable.propTypes = {
  internalDeposit: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  setPage: PropTypes.func,
  setSize: PropTypes.func,
  information: PropTypes.object,
  handleDelete: PropTypes.func,
  handlePrintPdf: PropTypes.func,
};
