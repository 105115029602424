import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { NotAuthorized } from '@iarcpsu/emanufac-components/src/components/Error';

import accessRight from '../../utils/functions/accessRight';
import {
  SettingCEM as SettingCEMView,
  SettingEditCostInventoryWorkflow,
  SettingPlaceAverageCost,
} from '../../views/CEM';

export function SettingPAMS() {
  const module = 'CEM';
  const prefix = '/setting/cem';
  const name = 'ตั้งค่าระบบ';
  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);

  if (accessRight(me, module, 1, availableModule)) {
    return (
      <div>
        <Route exact path={`${prefix}/module-setting`}>
          <SettingCEMView
            title="ตั้งค่าระบบการประมาณการต้นทุน"
            subtitle={name}
          />
        </Route>{' '}
        <Route exact path={`${prefix}/inventory-workflow`}>
          <SettingEditCostInventoryWorkflow
            title="จัดการ Workflow ที่จะคิดราคาต้นทุน"
            subtitle={name}
          />
        </Route>{' '}
        <Route exact path={`${prefix}/place-average-cost`}>
          <SettingPlaceAverageCost
            title="จัดการค่าเฉลี่ยต้นทุนของคลัง"
            subtitle={name}
          />
        </Route>
      </div>
    );
  }
  return (
    <div>
      <Route path="*">
        <NotAuthorized />
      </Route>
    </div>
  );
}

export default SettingPAMS;
