import { BaseAction } from '../../class';
import {
  QUOTATION_LOG_ALL,
  QUOTATION_LOG_DELETE,
  QUOTATION_LOG_ERROR,
  QUOTATION_LOG_GET,
  QUOTATION_LOG_LOADING,
  QUOTATION_LOG_POST,
  QUOTATION_LOG_PUT,
} from '../types';

const quotationLogAction = new BaseAction('quotation-log', {
  allConst: QUOTATION_LOG_ALL,
  getConst: QUOTATION_LOG_GET,
  postConst: QUOTATION_LOG_POST,
  putConst: QUOTATION_LOG_PUT,
  deleteConst: QUOTATION_LOG_DELETE,
  errorConst: QUOTATION_LOG_ERROR,
  loadingConst: QUOTATION_LOG_LOADING,
});

export const quotationLogCreate = (data) => quotationLogAction.create(data);
export const quotationLogAll = ({ name, page, size, ...query }) =>
  quotationLogAction.getAllData({ name, page, size, ...query });
export const quotationLogGet = (id) => quotationLogAction.getOneData(id);
export const quotationLogPut = (id, data) =>
  quotationLogAction.editOne(id, data);
export const quotationLogDelete = (id) => quotationLogAction.deleteOne(id);
export const quotationLogStateSet = (data) => quotationLogAction.stateSet(data);
export const quotationLogStateOneSet = (data) =>
  quotationLogAction.stateOneSet(data);
export const quotationLogReset = () => quotationLogAction.reset();
export const quotationLogError = () => quotationLogAction.onError();
