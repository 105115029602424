import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  PRODUCT_STOCK_STATUS,
  PRODUCT_TRANSACTION_TYPE,
} from '@iarcpsu/emanufac-constant';
import { Button, Card } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  AddProductForm,
  BackButton,
  Error,
  Loading,
  ProductStockForm,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { config } from '@/configs';
import * as actions from '@/redux/actions';
import { useQuery } from '@/utils/functions';

const defaultValues = {
  product_transaction_type: '',
  // production_number: '',
  warehouse: '',
  type: '',
  supplier: '',
  customer: '',
  quantity: '',
  price: '',
  discount: '',
  product: '',
  remark: '',
  payment: '',
  shipping: '',
  tracking_number: '',
  shipping_cost: '',
  seller: '',
  production_date: new Date(),
  expiration_date: new Date(),
  inventory_date: new Date(),
  issue_date: new Date(),
  sale_date: new Date(),
};

// Original By ApiwanaSuwannapoom

const AddProductStockLot = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const product = useSelector((state) => state.product);
  const warehouse = useSelector((state) => state.warehouse);
  const productTransactionType = useSelector(
    (state) => state.productTransactionType,
  );
  const customer = useSelector((state) => state.customer);
  const me = useSelector((state) => state.me);
  const paymentType = useSelector((state) => state.paymentType);
  const shippingType = useSelector((state) => state.shippingType);

  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      inventory_date: dayjs().format('YYYY-MM-DD'),
    },
  });

  const tempSelectedType = _.find(
    productTransactionType?.rows,
    (each) => each?._id === query.get('txnType'),
  );

  const [type, setType] = useState(
    tempSelectedType || {
      direction: PRODUCT_TRANSACTION_TYPE.add.status_code,
    },
  );
  const [selectProduct, setSelectProduct] = useState([]);

  // Setting Initial Data
  useEffect(() => {
    dispatch(
      actions.productAll({ name: '', size: config.maxFetchSize, page: 1 }),
    );
    dispatch(
      actions.productTransactionTypeAll({
        direction: PRODUCT_TRANSACTION_TYPE.add.status_code,
      }),
    );
    dispatch(
      actions.warehouseAll({ name: '', page: 1, size: config.maxFetchSize }),
    );
    dispatch(actions.customerAll({}));
    dispatch(actions.paymentTypeAll({}));
    dispatch(actions.shippingTypeAll({}));
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data, event) => {
    const payload = data;
    try {
      if (_.isEmpty(selectProduct)) {
        alert('กรุณาเลือกสินค้า');
      } else if (!_.isEmpty(selectProduct)) {
        payload.product = selectProduct;
        payload.status = PRODUCT_STOCK_STATUS.IN_STOCK.status_code;
        payload.inventory_date = new Date();
        console.log('payload', payload);
        event.preventDefault();
        await dispatch(actions.productStockLotCreate(payload));
        reset(defaultValues);
        setSelectProduct([]);
        alert('สำเร็จ');
        history.goBack();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderForm = () => (
    <Card className="p-6">
      <div className="py-2">
        <ProductStockForm
          control={control}
          errors={errors}
          warehouse={warehouse.rows}
          productTransactionType={productTransactionType.rows}
          customer={customer.rows}
          me={me.userData}
          paymentType={paymentType.rows}
          shippingType={shippingType.rows}
          type={type}
          setType={setType}
          setSelectProduct={setSelectProduct}
        />
      </div>
    </Card>
  );

  if (product.isLoading) {
    return <Loading />;
  }
  if (!product.isLoading && product.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-row flex-wrap">
            <div className="w-full px-1 py-2">{renderForm()}</div>
            <div className="w-full px-1 py-2">
              <AddProductForm
                product={product.rows}
                selectProduct={selectProduct}
                setSelectProduct={setSelectProduct}
              />
            </div>
          </div>
          <div className="flex flex-row justify-end gap-1 py-4">
            <Button variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    );
  }
  return <Error message={product?.message} />;
};

AddProductStockLot.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

AddProductStockLot.defaultProps = {
  title: '',
  subtitle: '',
};

export default AddProductStockLot;
