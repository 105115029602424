import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const ProcessStartTimeBox = ({ selectedProcess, manufacturingOrder }) => (
  <div>
    <h4 className="font-bold font-display">เวลาที่เริ่มงาน</h4>
    {selectedProcess?.start_time ? (
      <div>
        <div className="text-xl font-semibold">
          {dayjs(selectedProcess?.start_time)?.format('D MMM BBBB  HH:mm')}
        </div>
        <div className="my-2">
          {dayjs(selectedProcess?.start_time)?.isBefore(
            dayjs(manufacturingOrder?.start_date),
          ) && (
            <div className="text-sm text-green-500 font-semibold">
              เริ่มก่อนเวลา{' '}
              {dayjs(selectedProcess?.start_time).from(
                dayjs(manufacturingOrder?.start_date),
                true,
              )}
            </div>
          )}
          {dayjs(selectedProcess?.start_time)?.isAfter(
            dayjs(manufacturingOrder?.start_date),
          ) && (
            <div className="text-sm text-red-500 font-semibold">
              เริ่มช้ากว่ากำหนด{' '}
              {dayjs(selectedProcess?.start_time).to(
                dayjs(manufacturingOrder?.start_date),
                true,
              )}
            </div>
          )}
        </div>
      </div>
    ) : (
      <div>-</div>
    )}
  </div>
);

ProcessStartTimeBox.propTypes = {
  selectedProcess: PropTypes.object,
  manufacturingOrder: PropTypes.object,
};

export default ProcessStartTimeBox;
