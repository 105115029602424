import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  BackButton,
  Error,
  Loading,
  LoadingDialogWithTimeout,
  ReturnMaterialForm,
  SocketIOLoading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import {
  MANUFACTURING_TRANSACTION_TYPE,
  MQ_TASK,
} from '@iarcpsu/emanufac-constant';
import { Button, Card, CardContent, LinearProgress } from '@mui/material';
import { gql } from 'graphql-request';
import _, { set } from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import graphqlClient from '../../../configs/graphqlClient';
import * as actions from '../../../redux/actions';
import { MMS } from '../../../utils/functions';

/**
 * @function ReturnMaterial
 * @description Display a collections or a list of ReturnMaterial from database
 */

export default function ReturnMaterial({ title, subtitle }) {
  const dispatch = useDispatch();
  const currentProcess = useSelector((state) => state.process);
  const material = useSelector((state) => state.material);
  const history = useHistory();
  const params = useParams();
  const materialTransactionType = useSelector(
    (state) => state.materialTransactionType,
  );
  const me = useSelector((state) => state.me);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [dialogLoading, setOpenDialogLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [isReady, setIsReady] = useState(false);

  const {
    MaterialAnalyzer: { manufacMaterialAnalyzedArrayAnalzer },
  } = MMS;

  useEffect(() => {
    const refNo = hash({
      date: new Date(),
      user: me?.userData?._id,
    });

    setReferenceNumber(refNo);

    return () => {};
  }, []);

  const query = gql`
    query FindReturnMaterialElement(
      $processInput: ProcessInput
      $materialInput: MaterialInput
    ) {
      findOneProcess(input: $processInput) {
        _id
        current {
          step {
            _id
            process {
              _id
            }
          }
        }
        manufacturing_materials {
          _id
          material {
            _id
            type_code
            name
            unit
            use_unit_conversion
            conversion_from {
              unit_a
              unit_b
              conversion_factor
            }
            conversion_to {
              unit_b
              unit_a
              conversion_factor
            }
            unit_input {
              _id
              short_sign
              name
            }
            unit_output {
              _id
              short_sign
              name
            }
          }
          begin
          quantity
          amount
          transaction_dir
          transaction_type
        }
      }
      findMaterials(input: $materialInput) {
        rows {
          _id
          type_code
          name
          unit
          use_unit_conversion
          conversion_from {
            unit_a
            unit_b
            conversion_factor
          }
          conversion_to {
            unit_b
            unit_a
            conversion_factor
          }
          unit_input {
            _id
            short_sign
            name
          }
          unit_output {
            _id
            short_sign
            name
          }
          stocklots {
            _id
            amount
            issue_date
            lot_number
            quantity
            material {
              _id
              name
              type_code
              use_unit_conversion
              unit
              conversion_from {
                unit_a
                unit_b
                conversion_factor
              }
              conversion_to {
                unit_b
                unit_a
                conversion_factor
              }
              unit_input {
                _id
                short_sign
                name
              }
              unit_output {
                _id
                short_sign
                name
              }
            }
          }
        }
        total
      }
    }
  `;

  const fetchMaterialTxnType = async () => {
    try {
      await dispatch(actions.materialTransactionTypeAll({}));
    } catch (error) {
      console.error('Error on Fetch Material Txn Type', error);
    }
  };

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        processInput: {
          id: params.id,
          fetchMaterial: true,
          fetchInsideProcess: true,
        },
        materialInput: {
          fetchStockLot: true,
          status: 'IN_STOCK',
        },
      });
      const processData = queryResult?.findOneProcess;
      const materialData = queryResult?.findMaterials;
      dispatch(actions.processStateOneSet(processData));
      dispatch(actions.materialStateSet(materialData));
    } catch (error) {
      console.error('Error on Query', error);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    fetchMaterialTxnType();
    return () => {};
  }, [params]);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      material: _.map(currentProcess?.step?.materials, (_mat) => ({
        ..._mat,
        return: 0,
      })),
    },
  });

  useEffect(() => {
    if (currentProcess) {
      const manufacMatAnalyze = _.filter(
        manufacMaterialAnalyzedArrayAnalzer(
          currentProcess?.manufacturing_materials,
        ),
        (_material) => _material?.materialInfo?._id !== undefined,
      );

      setValue(
        'material',
        _.map(manufacMatAnalyze, (_mat) => ({
          ..._mat,
          return: 0,
        })),
      );
      setIsReady(true);
    }

    return () => {};
  }, [currentProcess]);

  const onSubmit = async (data) => {
    try {
      console.log('Data on Form', data);
      // Validation
      const falseArray = _.map(data?.material, (_material) => {
        if (parseFloat(_material?.return) < 0) {
          window.alert('ไม่สามารถคืนวัตถุดิบเป็นจำนวนติดลบได้');
          return false;
        }
        if (parseFloat(_material?.return) > 0 && _material?.lotId === 'NO') {
          window.alert('โปรดเลือกล็อตวัตถุดิบ');
          return false;
        }
        return true;
      });

      // กรณีที่เลือกจำนวนเป็น 0 และ lotID เป็นไม่เลือกล็อตใด ทุกอัน หรือ มันเท่ากับไม่ได้คืนอะไรเลย
      const isEmptyRequest = _.filter(
        data?.material,
        // prettier-ignore
        (_material) => parseFloat(_material?.return) === 0 && _material?.lotId === 'NO',
      );
      if (_.size(isEmptyRequest) >= _.size(data?.material)) {
        window.alert('ท่านไม่ได้เลือกคืนวัตถุดิบชิ้นใด');
      }

      if (
        !_.includes(falseArray, false) &&
        _.size(isEmptyRequest) < _.size(data?.material)
      ) {
        console.log('Data.material', data.material);

        // เตรียมข้อมูลสำหรับสร้าง ManufacMaterial
        const manufacMaterialArray = _.map(data.material, (_preMaterial) => {
          console.log('ข้อมูลที่เพิ่มลงในรายการใช้วัตถุดิบ / วัตถุดิบเสีย');
          console.table(
            [
              {
                ชื่อวัตถุดิบ: _preMaterial?.materialInfo?.name,
                จำนวนเริ่มต้น: _preMaterial?.resultAmount,
                จำนวนที่ขอลด: parseFloat(_preMaterial.return).toFixed(2),
                คงเหลือในการผลิต:
                  _preMaterial?.resultAmount - parseFloat(_preMaterial.return),
              },
            ],
            [
              'ชื่อวัตถุดิบ',
              'จำนวนเริ่มต้น',
              'จำนวนที่ขอเพิ่ม',
              'คงเหลือในการผลิต',
            ],
          );
          console.log('Pre Material', _preMaterial);
          return {
            material:
              _preMaterial?.material?._id || _preMaterial?.materialInfo?._id,
            begin: _preMaterial?.resultAmount,
            quantity: parseFloat(_preMaterial.return),
            amount:
              _preMaterial?.resultAmount - parseFloat(_preMaterial.return),
          };
        });

        // กรองเฉพาะอันที่ Quantity ไม่เท่ากัน 0
        const filteredManufacMaterialArr = _.filter(
          manufacMaterialArray,
          (_manufacMaterial) => _manufacMaterial?.quantity !== 0,
        );

        console.log('Filtered ManufacMaterial Arr', filteredManufacMaterialArr);
        const payload = {
          ...data,
          increaseMode: true,
          materialLots: _.map(
            _.filter(
              data?.material,
              // prettier-ignore
              (_material) => typeof _material?.lotId === 'string' && _material?.lotId !== 'NO',
            ),
            (_material) => ({
              lotId: _material?.lotId,
              material: _material?.material?._id || _material?.material,
              quantity: _material?.return,
            }),
          ),
          request_name: `${me?.userData?.firstname} ${me?.userData?.lastname}`,
          request_department:
            me?.userData?.department?._id || me?.userData?.department,
          remark: data?.remark,
          employee: me?.userData?._id,
          employeeId: me?.userData?._id,
          manufacturing_transaction_type:
            MANUFACTURING_TRANSACTION_TYPE.MATERIAL_RETURN.status_code,
          materialArray: filteredManufacMaterialArr,
          referenceNumber,
          process: currentProcess?._id,
          step: currentProcess?.current?.step?._id,
        };
        console.log('Payload', payload);
        await dispatch(actions.processMaterialReturn(payload));
      }
    } catch (error) {
      alert(`ไม่สามารถคืนวัตถุดิบได้ ${error?.message}`);
    }
  };

  const handleSocketIOFunction = {
    onSuccess: () => {
      setOpenDialogLoading(false);
      Swal.fire({
        icon: 'success',
        title: 'คืนวัตถุดิบสำเร็จ',
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        history.goBack();
      });
    },
    onFail: (args) => {
      setOpenDialogLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'คืนวัตถุดิบไม่สำเร็จ',
        text: args?.message,
      });
    },
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (currentProcess.isLoading) {
    return <Loading />;
  }

  if (!currentProcess.isLoading && currentProcess.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div>
          <BackButton />
        </div>
        <div className="my-2">
          {' '}
          <SocketIOLoading
            taskCode={MQ_TASK.RETURN_MATERIAL.status_code}
            handleSuccess={handleSocketIOFunction.onSuccess}
            handleFail={handleSocketIOFunction.onFail}
            referenceNumber={referenceNumber}
            setStatusMessage={setStatusMessage}
          />
          <LoadingDialogWithTimeout
            isLoading={dialogLoading}
            label={`กำลังดำเนินการคืนวัตถุดิบ ${statusMessage || ''}`}
          />
          <Card>
            <CardContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <ReturnMaterialForm
                  control={control}
                  errors={errors}
                  material={material}
                  materialTransactionType={materialTransactionType}
                />
                {!isReady && <LinearProgress />}
                <div className="flex gap-2 justify-end my-2">
                  <Button type="submit" color="primary" variant="contained">
                    ยืนยัน
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

ReturnMaterial.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ReturnMaterial.defaultProps = {
  title: '',
  subtitle: '',
};
