import {
  CONVERSION_ALL,
  CONVERSION_GET,
  CONVERSION_PUT,
  CONVERSION_DELETE,
  CONVERSION_POST,
  CONVERSION_LOADING,
  CONVERSION_ERROR,
} from '../../actions/types';

const initialState = {
  CONVERSION: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case CONVERSION_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case CONVERSION_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case CONVERSION_POST:
      return { isLoading: false, isCompleted: true };
    case CONVERSION_PUT:
      return { isLoading: false, isCompleted: true };
    case CONVERSION_DELETE:
      return { isLoading: false, isCompleted: true };
    case CONVERSION_LOADING:
      return { isLoading: true, isCompleted: true };
    case CONVERSION_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
