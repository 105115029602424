import React from 'react';
import { Check as CheckIcon, X as XIcon } from 'react-feather';
import {
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { currencyFormatter } from '../../../utils/functions';

const MetalDivideEditTable = ({
  eachMetalDividing,
  handleEdit,
  handleDelete,
  machine,
}) => {
  const conveyorWidth =
    (machine?.conveyor_width || 1) * (machine?.conveyor_parallel_number || 1);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <div className="font-bold"> ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ขนาด</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> จำนวน/คู่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ความยาว (ม.)</div>{' '}
            </TableCell>
            <TableCell>
              <div className="font-bold"> พื้นที่ (ตร.ม.)</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> เลขที่ Sale Order (So)</div>{' '}
            </TableCell>
            <TableCell>
              <div className="font-bold"> ลูกค้า</div>
            </TableCell>

            <TableCell>
              <div className="font-bold"> ลงคลัง</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> หมายเหตุ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ดำเนินการ</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(
            eachMetalDividing?.divide_sets,
            (eachDivideSet, divSetIndex) => (
              <TableRow key={`divset-${divSetIndex}`}>
                <TableCell>
                  {' '}
                  {divSetIndex + 1}{' '}
                  {eachDivideSet?.is_waste && (
                    <Chip color="error" label="waste" size="small" />
                  )}{' '}
                </TableCell>
                <TableCell>
                  {eachDivideSet?.is_waste
                    ? currencyFormatter.format(eachDivideSet?.lost)
                    : currencyFormatter.format(eachDivideSet?.length)}{' '}
                </TableCell>
                <TableCell>
                  {eachDivideSet?.is_waste
                    ? currencyFormatter.format(1)
                    : currencyFormatter.format(eachDivideSet?.amount)}{' '}
                </TableCell>
                <TableCell>
                  {eachDivideSet?.is_waste
                    ? currencyFormatter.format(eachDivideSet?.lost * 1)
                    : currencyFormatter.format(
                        eachDivideSet?.length * eachDivideSet?.amount,
                      )}{' '}
                </TableCell>
                <TableCell>
                  {currencyFormatter.format(
                    (eachDivideSet?.is_waste
                      ? eachDivideSet?.lost
                      : eachDivideSet?.length) *
                      eachDivideSet?.amount *
                      conveyorWidth,
                  )}{' '}
                </TableCell>
                <TableCell>
                  {eachDivideSet?.is_waste
                    ? `ตำแหน่งที่เสีย ${eachDivideSet?.failure_index || '-'}`
                    : eachDivideSet?.so_number}
                </TableCell>
                <TableCell>
                  {eachDivideSet?.is_waste
                    ? '-'
                    : eachDivideSet?.customer?.name}
                </TableCell>

                <TableCell>
                  {eachDivideSet?.in_warehouse ? <CheckIcon /> : <XIcon />}
                </TableCell>
                <TableCell>{eachDivideSet?.remark}</TableCell>
                <TableCell>
                  <div className="flex gap-1">
                    {/* <Button
                  color="warning"
                  variant="contained"
                  size="small"
                  onClick={() => handleEdit(divSetIndex)}
                >
                  แก้ไข
                </Button> */}
                    <Button
                      color="error"
                      variant="contained"
                      size="small"
                      onClick={() => handleDelete(divSetIndex)}
                    >
                      ลบ
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ),
          )}
          <TableRow className="bg-green-100">
            <TableCell colSpan={2}>
              <div className="text-center">รวมของดี</div>
            </TableCell>{' '}
            <TableCell>
              {currencyFormatter.format(
                _.sum(
                  // eslint-disable-next-line no-confusing-arrow
                  _.map(eachMetalDividing?.divide_sets, (eachDivideSet) =>
                    eachDivideSet?.is_waste ? 0 : eachDivideSet?.amount,
                  ),
                ),
              )}{' '}
              คู่
            </TableCell>
            <TableCell>
              {currencyFormatter.format(
                _.sum(
                  _.map(
                    eachMetalDividing?.divide_sets,
                    (eachDivideSet) =>
                      (eachDivideSet?.is_waste ? 0 : eachDivideSet?.length) *
                      eachDivideSet?.amount,
                  ),
                ),
              )}{' '}
              ม.
            </TableCell>
            <TableCell>
              {' '}
              {currencyFormatter.format(
                _.sum(
                  _.map(
                    eachMetalDividing?.divide_sets,
                    (eachDivideSet) =>
                      (eachDivideSet?.is_waste ? 0 : eachDivideSet?.length) *
                      eachDivideSet?.amount *
                      conveyorWidth,
                  ),
                ),
              )}{' '}
              ตร.ม.
            </TableCell>
            <TableCell colSpan={7}></TableCell>
          </TableRow>{' '}
          <TableRow className="bg-red-100">
            <TableCell colSpan={2}>
              <div className="text-center">รวมของเสีย</div>
            </TableCell>{' '}
            <TableCell>
              {currencyFormatter.format(
                _.sum(
                  // eslint-disable-next-line no-confusing-arrow
                  _.map(eachMetalDividing?.divide_sets, (eachDivideSet) =>
                    eachDivideSet?.is_waste ? 1 : 0,
                  ),
                ),
              )}{' '}
              คู่
            </TableCell>
            <TableCell>
              {currencyFormatter.format(
                _.sum(
                  _.map(
                    eachMetalDividing?.divide_sets,
                    (eachDivideSet) =>
                      (eachDivideSet?.is_waste ? eachDivideSet?.lost : 0) *
                      eachDivideSet?.amount,
                  ),
                ),
              )}{' '}
              ม.
            </TableCell>
            <TableCell>
              {' '}
              {currencyFormatter.format(
                _.sum(
                  _.map(
                    eachMetalDividing?.divide_sets,
                    (eachDivideSet) =>
                      (eachDivideSet?.is_waste ? eachDivideSet?.lost : 0) *
                      eachDivideSet?.amount *
                      conveyorWidth,
                  ),
                ),
              )}{' '}
              ตร.ม.
            </TableCell>
            <TableCell colSpan={7}></TableCell>
          </TableRow>
          <TableRow className="bg-gray-100">
            <TableCell colSpan={2}>
              <div className="text-center">รวม</div>
            </TableCell>{' '}
            <TableCell>
              {currencyFormatter.format(
                _.sum(
                  // eslint-disable-next-line no-confusing-arrow
                  _.map(eachMetalDividing?.divide_sets, (eachDivideSet) =>
                    eachDivideSet?.is_waste ? 1 : eachDivideSet?.amount,
                  ),
                ),
              )}{' '}
              คู่
            </TableCell>
            <TableCell>
              {currencyFormatter.format(
                _.sum(
                  _.map(
                    eachMetalDividing?.divide_sets,
                    (eachDivideSet) =>
                      (eachDivideSet?.is_waste
                        ? eachDivideSet?.lost
                        : eachDivideSet?.length) * eachDivideSet?.amount,
                  ),
                ),
              )}{' '}
              ม.
            </TableCell>
            <TableCell>
              {' '}
              {currencyFormatter.format(
                _.sum(
                  _.map(
                    eachMetalDividing?.divide_sets,
                    (eachDivideSet) =>
                      (eachDivideSet?.is_waste
                        ? eachDivideSet?.lost
                        : eachDivideSet?.length) *
                      eachDivideSet?.amount *
                      conveyorWidth,
                  ),
                ),
              )}{' '}
              ตร.ม.
            </TableCell>
            <TableCell colSpan={7}></TableCell>
          </TableRow>
          {_.isEmpty(eachMetalDividing?.divide_sets) && (
            <TableRow>
              <TableCell colSpan={8}>
                <div className="text-center my-2">
                  ยังไม่มีรายการรางลวดในสินค้านี้
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

MetalDivideEditTable.propTypes = {
  eachMetalDividing: PropTypes.shape({
    divide_sets: PropTypes.arrayOf(PropTypes.object),
  }),
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  machine: PropTypes.object,
};

export default MetalDivideEditTable;
