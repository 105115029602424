import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

import PropTypes from 'prop-types';

const CheckboxAddditionalInput = ({ control, defaultValue }) => (
  <div className="flex flex-wrap">
    <div className="w-full  py-1 ">
      ระบบจะแสดงช่องว่างเมื่อมีการทำเลือก (ทำเครื่องหมายถูก) ในรายการ
    </div>
    <div className="w-full  py-1 ">
      <Controller
        control={control}
        defaultValue={defaultValue?.label_on_additional_text}
        name="label_on_additional_text"
        render={({ field }) => (
          <TextField
            {...field}
            size="small"
            fullWidth
            label="Label ที่จะโชว์ในช่องกรอกข้อความเพิ่มเติม"
          />
        )}
      />
    </div>
  </div>
);

CheckboxAddditionalInput.propTypes = {
  control: PropTypes.object,
  defaultValue: PropTypes.object,
};

export default CheckboxAddditionalInput;
