/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
import { MANUFAC_ORDER_STATUS } from '@iarcpsu/emanufac-constant';
import dayjs from 'dayjs';

const StepColumn = [
  {
    label: 'ลำดับที่',
    value: 'index',
  },
  {
    label: 'หมายเลขใบสั่งผลิต',
    value: 'mo_number',
  },
  {
    label: 'สถานะ',
    value: (col) => MANUFAC_ORDER_STATUS[col?.status]?.description,
  },
  {
    label: 'วันที่มอบหมายงาน',
    value: (col) => dayjs(col?.createdAt).format('D/MM/BBBB'),
  },
  {
    label: 'วันที่ต้องการสินค้า',
    value: (col) => dayjs(col?.expected_date).format('D/MM/BBBB'),
  },
  {
    label: 'ชื่องาน',
    value: 'name',
  },
  {
    label: 'รหัสสินค้า',
    value: (col) => col?.product?.type_code || '-',
  },
  {
    label: 'ชื่อสินค้า',
    value: (col) => col?.product?.name || '-',
  },
  {
    label: 'รหัสลูกค้า',
    value: (col) => col?.customer?.type_code || '-',
  },
  {
    label: 'ชื่อลูกค้า',
    value: (col) => col?.customer?.name || '-',
  },
  {
    label: 'แผนกของงาน',
    value: (col) => col?.responsible?.department?.name || '-',
  },
  {
    label: 'ผู้รับผิดชอบ',
    value: (col) =>
      `${col?.responsible?.employee?.firstname || '-'} ${
        col?.responsible?.employee?.lastname || ''
      }`,
  },
];

export default StepColumn;
