export const MATH_OPERATION = {
  ADD: {
    type_code: 'ADD',
    description: 'บวก',
    symbol: '+',
  },
  SUBTRACT: {
    type_code: 'SUBTRACT',
    description: 'ลบ',
    symbol: '-',
  },
  MULTIPLY: {
    type_code: 'MULTIPLY',
    description: 'คูณ',
    symbol: 'x',
  },
  DIVIDE: {
    type_code: 'DIVIDE',
    description: 'หาร',
    symbol: '÷',
  },
  END_OFF_CALCULATION: {
    type_code: 'END_OFF_CALCULATION',
    description: 'สิ้นสุดสมการ',
    symbol: '',
  },
};

export default MATH_OPERATION;
