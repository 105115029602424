import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import BuddhistEra from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';

import 'dayjs/locale/th';

dayjs.extend(BuddhistEra);

export const StepInfoFrom = ({ Controller, control, errors, step }) => {
  const department = useSelector((state) => state.department);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        await dispatch(actions.departmentAll({}));
      } catch (error) {
        console.error('Cannot Fetch Department');
      }
    };

    fetchDepartment();
    return () => {};
  }, []);

  // Components Rendering
  const renderStepInfo = () => (
    <div>
      <div className="flex flex-row flex-wrap ">
        <div className="w-full  px-1 py-1">
          <div className="py-1">ชื่องาน </div>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            defaultValue={step?.name}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                size={'small'}
                helperText={errors.name && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>{' '}
        <div className=" w-full  px-1 py-1">
          <div className="py-1">รายละเอียด </div>
          <Controller
            name="description"
            control={control}
            defaultValue={step?.description}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                multiline
                rows={3}
                fullWidth
                size={'small'}
              />
            )}
          />
        </div>{' '}
        <div className=" w-full  px-1 py-1">
          <div className="py-1">แผนก </div>
          <Controller
            name="responsible.department"
            control={control}
            defaultValue={step?.responsible?.department?._id}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel>แผนก</InputLabel>
                <Select {...field} label="แผนก" fullWidth size="small">
                  {_.map(department?.rows, (_department, index) => (
                    <MenuItem key={index} value={_department?._id}>
                      {_department?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </div>{' '}
        <div className="w-full px-1 py-1">
          <Controller
            control={control}
            name="material_associate"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="เกี่ยวข้องกับวัตถุดิบ"
              />
            )}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div className="w-full ">{renderStepInfo()}</div>
    </div>
  );
};

StepInfoFrom.propTypes = {
  errors: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
  step: PropTypes.object,
};

export default StepInfoFrom;
