import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import Proptypes from 'prop-types';

import { PROCESS_TEMPLATE_TYPE } from '@/utils/constants';
import { WMS } from '@/utils/Proptypes';

export default function ProcessTemplateTable({
  product,
  page,
  size,
  total,
  handleChangeRowsPerPage = () => {},
  handleChangePage = () => {},
  handleDelete = () => {},
  showDeleteButton = false,
  placeURL = 'product-process',
  setSelectedProcessTemplate = () => {},
  setModalOpen = () => {},
}) {
  const displayStepData = (selectedProduct) => {
    if (_.isEmpty(selectedProduct?.steps)) {
      return <div>ยังไม่มีวิธีการผลิต</div>;
    }

    return (
      <div>
        <ul className="list-decimal list-inside">
          {_.map(selectedProduct?.steps, (_step, index) => (
            <li key={index}> {_step?.name} </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="w-full my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">สินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">รูปแบบโฟลว์</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">รายละเอียด</div>
                </TableCell>

                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(product.rows) ? (
                product.rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>{`${row?.type_code || ''} ${
                      row?.name
                    }`}</TableCell>
                    <TableCell style={{ width: '15%' }}>
                      {
                        PROCESS_TEMPLATE_TYPE[row?.process_template_type]
                          ?.description
                      }
                    </TableCell>
                    <TableCell style={{ width: '30%' }}>
                      {displayStepData(row)}
                    </TableCell>
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Link to={`${placeURL}/view/${row?._id}`}>
                          <Button variant="contained" color="info" size="small">
                            รายละเอียด
                          </Button>
                        </Link>
                        <Link to={`${placeURL}/edit/${row?._id}`}>
                          <Button
                            variant="contained"
                            color={'warning'}
                            size={'small'}
                          >
                            แก้ไข
                          </Button>
                        </Link>
                        {row?.process_template_type ===
                          PROCESS_TEMPLATE_TYPE.MATERIAL_SPLITTING
                            .status_code && (
                          <Link to={`${placeURL}/edit/table/${row?._id}`}>
                            <Button
                              variant="contained"
                              color="secondary"
                              size="small"
                            >
                              แก้ไขแบบตาราง
                            </Button>
                          </Link>
                        )}

                        {showDeleteButton && (
                          <Button
                            variant="contained"
                            color="teal"
                            size="small"
                            onClick={() => {
                              setSelectedProcessTemplate(row);
                              setModalOpen(true);
                            }}
                          >
                            ทำซ้ำ
                          </Button>
                        )}
                        {showDeleteButton && (
                          <Button
                            variant="contained"
                            color="error"
                            size="small"
                            onClick={() => handleDelete(row?._id)}
                          >
                            ลบ
                          </Button>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );
}

ProcessTemplateTable.propTypes = {
  product: WMS.Product,
  page: Proptypes.number,
  size: Proptypes.number,
  total: Proptypes.number,
  handlePushToEditPage: Proptypes.func.isRequired,
  handleChangeRowsPerPage: Proptypes.func.isRequired,
  handleChangePage: Proptypes.func.isRequired,
  handleToInfoPage: Proptypes.func.isRequired,
  placeURL: Proptypes.string,
  handleDelete: Proptypes.func,
  showDeleteButton: Proptypes.bool,
};
