import React, { useRef } from 'react';
import Barcode from 'react-barcode';
import { Button } from '@mui/material';
import { toPng } from 'html-to-image';
import PropTypes from 'prop-types';

function ProductStockLotSticker({ information, productStockLot }) {
  const stickerRef = useRef(null);
  return (
    <div>
      <div className="border rounded-md">
        <div ref={stickerRef}>
          <div className="flex gap-2 m-1 justify-center">
            <img src={information?.logo?.url} className="w-20" />
            <div className="text-sm">
              {productStockLot?.product?.type_code}{' '}
              {productStockLot?.product?.name}
            </div>
          </div>
          <div className="w-full flex justify-center">
            <Barcode
              value={productStockLot?.running_number}
              width={2.5}
              height={30}
              fontSize={12}
              // displayValue={false}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-center my-2">
        <Button
          size="small"
          variant="outlined"
          onClick={() =>
            toPng(stickerRef.current, {
              cacheBust: true,
              backgroundColor: 'white',
            })
              .then((dataUrl) => {
                const link = document.createElement('a');

                link.download = `${productStockLot?.product?.type_code} ${productStockLot?.product?.name} ${productStockLot?.lot_number}.png`;
                link.href = dataUrl;
                link.click();
              })
              .catch((err) => {
                alert(`ดาวน์โหลดรูปไม่สำเร็จ ${err?.message}`);
              })
          }
        >
          ดาวน์โหลด
        </Button>
      </div>
    </div>
  );
}

export default ProductStockLotSticker;

ProductStockLotSticker.propTypes = {
  information: PropTypes.object,
  productStockLot: PropTypes.object,
};
