import api from '../../../configs/api';
import graphqlClient from '../../../configs/graphqlClient';
import {
  MAT_SPLITTING_ALL,
  MAT_SPLITTING_DELETE,
  MAT_SPLITTING_ERROR,
  MAT_SPLITTING_GET,
  MAT_SPLITTING_LOADING,
  MAT_SPLITTING_POST,
  MAT_SPLITTING_PUT,
} from '../types';

export const materialSplittingCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MAT_SPLITTING_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/material-splitting-process`,
      {
        ...payload,
      },
    );
    dispatch({ type: MAT_SPLITTING_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MAT_SPLITTING_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const materialSplittingAll = ({
  size = 10,
  page = 1,
  processTemplate = '',
  currentProcess = '',
}) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/material-splitting-process?processTemplate=${processTemplate}&process=${currentProcess}&page=${page}&size=${size}`,
    );
    if (status === 200) {
      dispatch({ type: MAT_SPLITTING_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MAT_SPLITTING_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const materialSplittingAllQuery = ({ query }) => async (dispatch) => {
  try {
    const queryResult = await graphqlClient.request(query);
    const data = queryResult?.findUnits;
    //  console.log('Query Data', queryResult);
    if (!data) {
      dispatch({ type: MAT_SPLITTING_ERROR });
    }

    dispatch({ type: MAT_SPLITTING_ALL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MAT_SPLITTING_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const materialSplittingGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/material-splitting-process/${id}`,
    );
    if (status === 200) {
      dispatch({ type: MAT_SPLITTING_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MAT_SPLITTING_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const materialSplittingGetByProcess = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${
        process.env.REACT_APP_API_URL
      }/material-splitting-process/process?process=${id || ''}`,
    );
    if (status === 200) {
      dispatch({ type: MAT_SPLITTING_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MAT_SPLITTING_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const materialSplittingStateSet = (data) => async (dispatch) => {
  dispatch({ type: MAT_SPLITTING_ALL, payload: data });
};

export const materialSplittingStateOneSet = (data) => async (dispatch) => {
  dispatch({ type: MAT_SPLITTING_GET, payload: data });
};

export const materialSplittingReset = () => async (dispatch) => {
  dispatch({ type: MAT_SPLITTING_LOADING });
};

export const materialSplittingError = () => async (dispatch) => {
  dispatch({ type: MAT_SPLITTING_ERROR });
};

export const materialSplittingPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: MAT_SPLITTING_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/material-splitting-process/${id}`,
      payload,
    );
    dispatch({ type: MAT_SPLITTING_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MAT_SPLITTING_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};
export const materialSplittingDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: MAT_SPLITTING_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/material-splitting-process/${id}`,
    );
    dispatch({ type: MAT_SPLITTING_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MAT_SPLITTING_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};
