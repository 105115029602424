import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardContent } from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { ViewTitle } from '@iarcpsu/emanufac-components/src/components/ViewTitle';
import * as actions from '../../redux/actions';
import { ConversionForm } from '@iarcpsu/emanufac-components/src/components/Forms';
import Loading from '@iarcpsu/emanufac-components/src/components/Loading';
import { Error } from '@iarcpsu/emanufac-components/src/components/Error';
import { BackButton } from '@iarcpsu/emanufac-components/src/components/Button';
import { app as appConfig } from '../../configs';

export default function EditConversion({ title, subtitle }) {
  const dispatch = useDispatch();
  const conversion = useSelector((state) => state.conversion);
  const units = useSelector((state) => state.unit);
  const params = useParams();
  const history = useHistory();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ defaultValues: conversion });

  const onSubmit = async (data) => {
    try {
      await dispatch(actions.conversionPut(params.id, data));
      alert('แก้ไขการแปลงหน่วยสำเร็จ');
      history.goBack();
    } catch (error) {
      window.alert(`แก้ไขการแปลงหน่วยไม่สำเร็จ${error?.message}`);
      console.error('Error on Edit Conversion', error);
    }
  };

  const getAllUnit = async () => {
    try {
      dispatch(actions.unitAll({ page: 1, size: appConfig.maxFetchSize }));
    } catch (error) {
      console.error('Get All Units Error', error);
    }
  };

  useEffect(() => {
    dispatch(actions.conversionGet(params.id));
    getAllUnit();
    return () => {};
  }, []);

  useEffect(() => {
    if (conversion) {
      setValue('unit_a', conversion?.unit_a);
      setValue('unit_b', conversion?.unit_b);
      setValue('conversion_factor', conversion?.conversion_factor);
    }
    return () => {};
  }, [conversion]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderForm = () => (
    <div className="my-2">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardContent>
            <ConversionForm
              units={units}
              control={control}
              errors={errors}
              conversion={conversion}
            />
          </CardContent>
        </Card>
        <div className="flex flex-row justify-end gap-1 py-4">
          <Button variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  );

  const renderBackButton = () => (
    <div className="flex justify-start">
      <BackButton />
    </div>
  );

  if (conversion.isLoading || conversion?.rows || units?.isLoading) {
    return <Loading />;
  }

  if (
    !conversion.isLoading &&
    conversion.isCompleted &&
    !units.isLoading &&
    units.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}
        {renderForm()}
      </div>
    );
  }
  return <Error />;
}
EditConversion.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditConversion.defaultProps = {
  title: '',
  subtitle: '',
};
