import React from 'react';
import Chart from 'react-apexcharts';
import { Check as CheckIcon, X as XIcon } from 'react-feather';
import _ from 'lodash';
import PropTypes from 'prop-types';

const ProcessStatusBox = ({ selectedProcess, showGraph = true }) => {
  const renderProcessStatus = () => {
    // เสร็จแล้ว
    if (selectedProcess?.completed) {
      return (
        <div className="flex gap-1 text-green-600   ">
          <CheckIcon size={16} className="self-center mr-2" />
          <div className="self-center">การผลิตเสร็จสิ้น</div>
        </div>
      );
    }

    // ยกเลิกแล้ว
    if (selectedProcess?.deleted) {
      return (
        <div>
          <div className="flex gap-1 text-red-600   ">
            <XIcon size={16} className="self-center mr-2" />
            <div className="self-center">
              ไลน์การผลิตนี้ถูกยกเลิก
              <p className="text-red-500 text-sm">
                {' '}
                {selectedProcess?.delete_note}{' '}
              </p>
            </div>
          </div>
        </div>
      );
    }

    // ยังไม่เริ่ม
    if (
      selectedProcess?.current?.index === null ||
      selectedProcess?.current?.index === -1
    ) {
      return (
        <div className="flex gap-1  text-red-500  ">
          <div className="self-center">ยังไม่เริ่มการผลิต</div>
        </div>
      );
    }

    return (
      <div>
        <div>
          ขั้นตอนที่ {selectedProcess?.current?.index + 1} /{' '}
          {_.size(selectedProcess?.steps)}{' '}
          {selectedProcess?.current?.step?.name}
        </div>
        <div>
          {showGraph && (
            <Chart
              type="bar"
              series={[
                {
                  name: 'ทำไปแล้ว',
                  data: [selectedProcess?.current?.index + 1],
                },
                {
                  name: 'ทั้งหมด',
                  data: [
                    _.size(selectedProcess?.steps) -
                      (selectedProcess?.current?.index + 1),
                  ],
                },
              ]}
              options={{
                chart: {
                  type: 'bar',
                  stacked: true,
                  stackType: '100%',
                  toolbar: {
                    show: false,
                  },
                  offsetX: 0,
                  offsetY: 0,
                },
                plotOptions: {
                  bar: {
                    horizontal: true,
                  },
                },
                xaxis: {
                  categories: ['งานที่ทำ'],
                  labels: {
                    show: false,
                  },
                  axisBorder: {
                    show: false,
                  },
                  axisTicks: {
                    show: false,
                  },
                },
                yaxis: {
                  show: false,
                  axisTicks: {
                    show: false,
                  },
                },
                legend: {
                  show: false,
                },
                grid: {
                  show: false,
                  xaxis: {
                    lines: {
                      show: false,
                    },
                  },
                  yaxis: {
                    lines: {
                      show: false,
                    },
                  },
                },
                colors: ['#00E396', '#F2F3F4'],
                padding: {
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                },
              }}
              height={80}
            />
          )}
        </div>
      </div>
    );
  };
  return (
    <div>
      <h4 className="font-bold font-display">สถานะการผลิต</h4>
      <div className="w-full mt-2">{renderProcessStatus()}</div>
    </div>
  );
};

ProcessStatusBox.propTypes = {
  selectedProcess: PropTypes.object,
  showGraph: PropTypes.bool,
};

export default ProcessStatusBox;
