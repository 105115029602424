import React from 'react';
import dayjs from 'dayjs';
import { Button } from '@mui/material';
import { ChevronLeft, ChevronRight } from 'react-feather';
import PropTypes from 'prop-types';

/**
 * @author theethawat
 * @function MonthyUpAndDownButton
 * @description ปุ่มสำหรับขยับเดือนขึ้นหรือลง ใช้กับ State ของวันที่
 * โดยเราตั้ง Cursor Date เอาไว้ว่าจะให้มันลงที่วันไหน มันจะเป็น cursorDate ถึง cursorDate-1
 */

const MonthyUpAndDownButton = ({
  selectedStartDate,
  setSelectedStartDate,
  setSelectedEndDate,
  cursorDate, // ให้มันเป็นวันที่เท่าไหร่ของเดือน
}) => {
  const handlePreviousMonth = () => {
    const currentMonth = dayjs(selectedStartDate).month();
    if (currentMonth === 0) {
      const currentYear = dayjs(selectedStartDate).year();
      const previousMonthDay = dayjs(selectedStartDate)
        .year(currentYear - 1)
        .month(11)
        .date(cursorDate);
      const endOfPreviousMonth = dayjs(previousMonthDay)
        .add(1, 'month')
        .subtract(1, 'days');
      setSelectedStartDate(previousMonthDay);
      setSelectedEndDate(endOfPreviousMonth);
    } else {
      const previousMonthDay = dayjs(selectedStartDate)
        .month(currentMonth - 1)
        .date(cursorDate);
      const endOfPreviousMonth = dayjs(previousMonthDay)
        .add(1, 'month')
        .subtract(1, 'days');
      setSelectedStartDate(previousMonthDay);
      setSelectedEndDate(endOfPreviousMonth);
    }
  };

  const handleUpcomingMonth = () => {
    const currentMonth = dayjs(selectedStartDate).month();
    if (currentMonth === 11) {
      const currentYear = dayjs(selectedStartDate).year();
      const upcomingMonthDay = dayjs(selectedStartDate)
        .year(currentYear + 1)
        .month(0)
        .date(cursorDate);
      const endOfUpcomingMonth = dayjs(upcomingMonthDay)
        .add(1, 'month')
        .subtract(1, 'days');
      setSelectedStartDate(upcomingMonthDay);
      setSelectedEndDate(endOfUpcomingMonth);
    } else {
      const upcomingMonthDay = dayjs(selectedStartDate)
        .month(currentMonth + 1)
        .date(cursorDate);
      const endOfUpcomingMonth = dayjs(upcomingMonthDay)
        .add(1, 'month')
        .subtract(1, 'days');
      setSelectedStartDate(upcomingMonthDay);
      setSelectedEndDate(endOfUpcomingMonth);
    }
  };
  return (
    <div>
      <div className="flex gap-2 my-2">
        <Button
          variant="outlined"
          color="inherit"
          onClick={handlePreviousMonth}
          startIcon={<ChevronLeft size="16" />}
        >
          เดือนก่อนหน้า
        </Button>
        <Button
          variant="outlined"
          color="inherit"
          endIcon={<ChevronRight size="16" />}
          onClick={handleUpcomingMonth}
        >
          เดือนถัดไป
        </Button>
      </div>
    </div>
  );
};

MonthyUpAndDownButton.propTypes = {
  selectedStartDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  setSelectedStartDate: PropTypes.func,
  setSelectedEndDate: PropTypes.func,
  cursorDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default MonthyUpAndDownButton;
