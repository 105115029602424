export const WAREHOUSE_STOCK_REQ_STATUS = {
  PENDING_APPROVAL: {
    status_code: 'PENDING_APPROVAL',
    description: 'รอการอนุมัติ',
    css: 'text-center bg-blue-200 text-sm text-black',
  },
  APPROVED: {
    status_code: 'APPROVED',
    description: 'อนุมัติ กำลังดำเนินการ',
    css: 'text-center bg-yellow-200 text-sm text-black',
  },
  SUCCESS: {
    status_code: 'SUCCESS',
    description: 'สำเร็จ',
    css: 'text-center bg-green-500  text-sm text-white',
  },
  REJECT: {
    status_code: 'REJECT',
    description: 'ปฏิเสธ',
    css: 'text-center bg-red-500  text-sm text-white',
  },
};

export default WAREHOUSE_STOCK_REQ_STATUS;
