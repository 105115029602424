import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Error,
  ExportExcelContainer,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import {
  Button,
  Card,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { app as appConfig, useQuery } from '@/configs';
import * as actions from '@/redux/actions';
import { ProductUtil } from '@/utils/excelColumn';

function Product({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();

  const product = useSelector((state) => state.product);
  const productType = useSelector((state) => state.productType);
  const information = useSelector((state) => state.information);
  const availableModule = useSelector((state) => state.availableModule);
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(
    query.get('page') ? parseInt(query.get('page'), 10) : 1,
  );
  const [size, setSize] = useState(
    query.get('size') ? parseInt(query.get('size'), 10) : 10,
  );
  const [total, setTotal] = useState(undefined);
  const [selectProductTypeType, setSelectProductType] = useState(
    query.get('type') || '',
  );

  const productAll = () => {
    dispatch(
      actions.productAll({
        name,
        page,
        size,
        selectProductTypeType,
        fetchStockLot: false,
      }),
    );
  };

  useEffect(() => {
    productAll();
    dispatch(actions.productTypeAll(''));

    return () => {};
  }, [name, page, size, selectProductTypeType]);

  useEffect(() => {
    setTotal(product?.total);
    return () => {};
  }, [product]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleDeleteproduct = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.productDelete(id));
        productAll();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
    history.push({
      search: `?${new URLSearchParams({
        page,
        size: event.target.value,
      }).toString()}`,
    });
  };

  const handleSelectProductType = (type) => {
    setSelectProductType(type);
    history.push({
      search: `?${new URLSearchParams({
        page,
        size,
        type: type || '',
      }).toString()}`,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    history.push({
      search: `?${new URLSearchParams({ page: newPage + 1, size }).toString()}`,
    });
  };

  const renderAddButton = () => (
    <div className="flex flex-row justify-end pb-4 gap-2">
      <Link to="/wms/product/create">
        <Button variant="contained">เพิ่ม</Button>
      </Link>
      <Link to="/wms/product/import">
        <Button variant="contained" color="success">
          อัพโหลด
        </Button>
      </Link>
      {/* <Link
        to={{
          pathname: '/wms/product/edit-many',
          state: {
            name,
            page,
            size,
          },
        }}
      >
        <Button variant="contained" color="warning">
          แก้ไขหลายชนิด
        </Button>
      </Link> */}
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row gap-2">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/2">
          <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
            <InputLabel id="type" size={'small'}>
              ค้นหาด้วยประเภทสินค้า
            </InputLabel>
            <Select
              label="ค้นหาด้วยประเภทสินค้า"
              size={'small'}
              fullWidth
              onChange={(e) => {
                handleSelectProductType(e.target.value);
              }}
            >
              <MenuItem value="">ทั้งหมด</MenuItem>
              {_.map(productType.rows, (_productType) => (
                <MenuItem value={_productType?._id} key={_productType?._id}>
                  {_productType?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="w-full md:w-1/4 flex justify-end">
          <ExportExcelContainer
            columnList={ProductUtil.columnWithoutStock}
            currentData={product?.rows}
            dataAPIEndPoint="material"
            dataQuery={{
              fetchStockLot: false,
              selectProductTypeType,
              stockStatus: '',
              fetchProcess: false,
              page: 1,
              size: appConfig.maxFetchSize,
            }}
            sheetName="Product"
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> รหัสสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> หน่วย</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ประเภทสินค้า</div>
                </TableCell>
                {information?.setting?.wms?.productAttribute
                  ?.prefer_customer && (
                  <TableCell>
                    <div className="font-bold"> ลูกค้า</div>
                  </TableCell>
                )}
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(product.rows) ? (
                product.rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>{row.type_code || row?.tag || '-'}</TableCell>
                    <TableCell>
                      {row.name}
                      {information?.external_software?.ewarehouse?.enable && (
                        <div className="text-gray-500 text-sm">
                          น้ำหนักสินค้าต่อหน่วย {row?.weight || '-'} กก.
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      {row?.use_unit_conversion ? (
                        <div>
                          <div>{row?.unit_input?.name}</div>{' '}
                          <div>{row?.unit_output?.name}</div>{' '}
                        </div>
                      ) : (
                        row?.unit
                      )}
                    </TableCell>
                    <TableCell>{`${
                      row.product_type?.name || row?.type?.name || ''
                    }`}</TableCell>
                    {information?.setting?.wms?.productAttribute
                      ?.prefer_customer && (
                      <TableCell>
                        <div> {row?.prefer_customer?.name}</div>
                      </TableCell>
                    )}
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Link to={`/wms/product/${row?._id}`}>
                          <Button
                            variant="contained"
                            color={'info'}
                            size={'small'}
                          >
                            รายละเอียด
                          </Button>
                        </Link>
                        {availableModule?.MMS && (
                          <Link
                            to={`/setting/mms/product-process/edit/${row?.id}`}
                          >
                            <Button
                              variant="contained"
                              color="teal"
                              size={'small'}
                            >
                              แก้ไขสูตรการผลิต
                            </Button>
                          </Link>
                        )}
                        <Link to={`/wms/product/edit/${row?._id}`}>
                          <Button
                            variant="contained"
                            color={'warning'}
                            size={'small'}
                          >
                            แก้ไข
                          </Button>
                        </Link>
                        <Button
                          variant="contained"
                          color={'error'}
                          size={'small'}
                          onClick={() => {
                            handleDeleteproduct(row?.id);
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (product.isLoading) {
    return <Loading />;
  }
  if (!product.isLoading && product.isCompleted) {
    return (
      <div>
        {' '}
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButton()}</div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error message={product?.message} />;
}

Product.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Product.defaultProps = {
  title: '',
  subtitle: '',
};

export default Product;
