import React, { useEffect } from 'react';
import { ArrowDown, Check as CheckIcon, X as NoIcon } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  BackButton,
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import {
  CAPITAL_COST_ESTIMATION_METHOD,
  LABOR_COST_ESTIMATION_METHOD,
  MANUFACTURING_MODE,
  MATERIAL_COST_ESTIMATION_METHOD,
} from '@iarcpsu/emanufac-constant';
import { Card, CardContent } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';

/**
 * @function DetailCostEsimationProfile
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function DetailCostEsimationProfile({ title, subtitle }) {
  const dispatch = useDispatch();
  const costEstimationProfile = useSelector(
    (state) => state.costEstimationProfile,
  );
  const params = useParams();

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.costEstimationProfileGet(params.id));
    } catch (error) {
      dispatch(actions.costEstimationProfileError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (costEstimationProfile.isLoading) {
    return <Loading />;
  }

  if (!costEstimationProfile.isLoading && costEstimationProfile.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <Card>
            <CardContent>
              <div>
                <h1>{costEstimationProfile.name}</h1>
                <p>
                  <span className="font-semibold">รูปแบบการผลิต:</span>{' '}
                  {
                    MANUFACTURING_MODE[
                      costEstimationProfile?.manufacturing_mode
                    ]?.description
                  }
                </p>
              </div>
            </CardContent>
          </Card>
          <div className="my-2">
            <Card>
              <CardContent>
                {_.map(
                  costEstimationProfile?.warehouses,
                  (eachWarehouse, index) => (
                    <div key={index}>
                      <div className="my-2 border-2 rounded-md p-4">
                        <h2 className="text-lg font-semibold py-1">
                          {eachWarehouse?.use_warehouse_instead
                            ? eachWarehouse?.warehouse?.name
                            : eachWarehouse?.place?.name}{' '}
                        </h2>
                        <div className="flex flex-wrap">
                          <div className="w-full md:w-1/3">
                            <h3 className="font-semibold">ต้นทุนจากวัตถุดิบ</h3>
                            {eachWarehouse?.material?.enable ? (
                              <div>
                                {
                                  MATERIAL_COST_ESTIMATION_METHOD[
                                    eachWarehouse?.material?.method
                                  ]?.description
                                }
                                {eachWarehouse?.material
                                  ?.use_cost_from_pervious_material && (
                                  <div>ดึงต้นทุนจากวัตถุดิบคลังก่อนหน้า</div>
                                )}
                              </div>
                            ) : (
                              <div>-</div>
                            )}
                          </div>
                          <div className="w-full md:w-1/3">
                            <h3 className="font-semibold">ต้นทุนค่าแรง</h3>
                            {eachWarehouse?.labor?.enable ? (
                              <div>
                                {
                                  LABOR_COST_ESTIMATION_METHOD[
                                    eachWarehouse?.labor?.method
                                  ]?.description
                                }
                              </div>
                            ) : (
                              <div>-</div>
                            )}
                          </div>
                          <div className="w-full md:w-1/3">
                            <h3 className="font-semibold">ต้นทุนการลงทุน</h3>
                            {eachWarehouse?.capital_cost?.enable ? (
                              <div>
                                {
                                  CAPITAL_COST_ESTIMATION_METHOD[
                                    eachWarehouse?.capital_cost?.method
                                  ]?.description
                                }{' '}
                                <ul>
                                  <li className="flex items-center">
                                    <span>
                                      {eachWarehouse?.capital_cost?.element
                                        ?.machine ? (
                                        <CheckIcon size={14} />
                                      ) : (
                                        <NoIcon size={14} />
                                      )}
                                    </span>{' '}
                                    เครื่องจักร
                                  </li>{' '}
                                  <li className="flex items-center">
                                    <span>
                                      {eachWarehouse?.capital_cost?.element
                                        ?.electric ? (
                                        <CheckIcon size={14} />
                                      ) : (
                                        <NoIcon size={14} />
                                      )}
                                    </span>{' '}
                                    ค่าไฟฟ้า
                                  </li>{' '}
                                  <li className="flex items-center">
                                    <span>
                                      {eachWarehouse?.capital_cost?.element
                                        ?.water_supply ? (
                                        <CheckIcon size={14} />
                                      ) : (
                                        <NoIcon size={14} />
                                      )}
                                    </span>{' '}
                                    ค่าน้ำประปา
                                  </li>
                                </ul>
                              </div>
                            ) : (
                              <div>-</div>
                            )}
                          </div>
                        </div>
                      </div>
                      {index !==
                        _.size(costEstimationProfile?.warehouses) - 1 && (
                        <div className="flex justify-center">
                          <ArrowDown />
                        </div>
                      )}
                    </div>
                  ),
                )}
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailCostEsimationProfile.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailCostEsimationProfile.defaultProps = {
  title: '',
  subtitle: '',
};
