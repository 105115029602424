import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import theme from '../../theme';

export default function ViewTitle({ title, subtitle }) {
  return (
    <div
      className="py-4 mb-4 px-2 cursor-pointer border-l-4 "
      style={{ borderColor: theme.palette.primary.main }}
    >
      <Helmet>
        <title> {title || '-'} - E-Manufac </title>
      </Helmet>
      <div className="text-2xl font-medium font-display text-gray-600 hover:text-black  px-2  ">
        {title}
      </div>{' '}
      <div className="text-xs font-medium font-display text-gray-600 hover:text-black  px-2 ">
        {subtitle}
      </div>
    </div>
  );
}

ViewTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ViewTitle.defaultProps = {
  title: '',
  subtitle: '',
};
