import React, { useEffect, useState } from 'react';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { config } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import { MMS } from '@/utils/functions';

import { MultiUnitProdOrMatBox } from '../../Box';

function MergingProcessForm({ watchedMaterial, selectedProcess, setValue }) {
  const [mainMaterials, setMainMaterials] = useState([]);
  const [sumTwoProcessMaterial, setSumTwoProcessMaterial] = useState([]);
  const {
    MaterialAnalyzer: { unNullManufacMaterialAnalyzedArrayAnalzer },
  } = MMS;
  const query = gql`
    query FindManufacturingMaterials(
      $manufacturingMaterialInput: ManufacturingMaterialInput
    ) {
      findManufacturingMaterials(input: $manufacturingMaterialInput) {
        rows {
          _id
          material {
            _id
            name
            type_code
            use_unit_conversion
            unit
            conversion_from {
              unit_a
              unit_b
              conversion_factor
            }
            conversion_to {
              unit_b
              unit_a
              conversion_factor
            }
            unit_input {
              _id
              short_sign
              name
            }
            unit_output {
              _id
              short_sign
              name
            }
            common_price
          }
          transaction_type
          begin
          quantity
          amount
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        manufacturingMaterialInput: {
          page: 1,
          size: config.maxFetchSize,
          process: selectedProcess?.main_process,
        },
      });

      const manufacturingMaterialData = queryResult?.findManufacturingMaterials;

      const tempMaterialAnalyzer = unNullManufacMaterialAnalyzedArrayAnalzer(
        manufacturingMaterialData?.rows,
      );

      const newMainProcessMaterial = _.map(
        tempMaterialAnalyzer,
        (_material) => {
          const eachNewMaterialData = {
            begin: _material?.resultAmount,
            end: _material?.resultAmount,
            return: _material?.returnAmount,
            material: _material?.materialInfo,
          };
          return eachNewMaterialData;
        },
      );

      setMainMaterials(newMainProcessMaterial);
      console.log('Manufacturing Material Data', newMainProcessMaterial);

      const sumMaterial = _.map(newMainProcessMaterial, (eachMaterial) => {
        const foundOnWatchedMaterial = _.find(
          watchedMaterial,
          (eachWatchedMaterial) =>
            eachWatchedMaterial.material._id === eachMaterial.material._id,
        );
        console.log('Found on Watched Material', foundOnWatchedMaterial);
        return {
          ...eachMaterial,
          end: (foundOnWatchedMaterial?.end || 0) + (eachMaterial?.end || 0),
          quantity: foundOnWatchedMaterial?.end,
        };
      });

      setSumTwoProcessMaterial(sumMaterial);
      setValue('main_process_materials', sumMaterial);
    } catch (error) {
      alert(`ไม่สามารถดึงข้อมูลได้ ${error?.message}`);
    }
  };

  useEffect(() => {
    queryDataFromServer();

    return () => {};
  }, [watchedMaterial]);

  return (
    <div>
      <div>รวมวัตถุดิบไปยังไลน์การผลิตหลัก</div>
      <div className="flex flex-wrap items-center">
        <div className="w-5/12 p-2">
          <div className="border p-2 rounded-md">
            <div className=" p-2 rounded-md border-2">
              <div className="font-semibold">วัตถุดิบในไลน์นี้</div>
              {_.map(watchedMaterial, (eachMaterial, index) => (
                <div key={index}>
                  <div className="text-lg">
                    {eachMaterial.material.type_code}{' '}
                    {eachMaterial.material.name}
                  </div>
                  <div>
                    <MultiUnitProdOrMatBox
                      foundMaterial={eachMaterial?.material}
                      noWrap
                      quantity={eachMaterial.end}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="my-4 w-full flex justify-center">
              <i className="fas fa-plus-circle text-2xl text-center"></i>
            </div>
            <div className="rounded-md border-2 p-2">
              <div className="font-semibold">วัตถุดิบในไลน์ผลิตหลัก</div>
              {_.map(
                _.filter(mainMaterials, (each) => each?.end !== 0),
                (eachMaterial, index) => (
                  <div key={index}>
                    <div className="text-lg">
                      {eachMaterial?.material?.type_code}{' '}
                      {eachMaterial?.material?.name}
                    </div>
                    <div>
                      <MultiUnitProdOrMatBox
                        foundMaterial={eachMaterial?.material}
                        noWrap
                        quantity={eachMaterial?.end}
                      />
                    </div>
                  </div>
                ),
              )}
            </div>
          </div>
        </div>
        <div className="w-2/12 p-1 flex justify-center">
          <div className="text-2xl">
            <i className="fas fa-arrow-alt-circle-right"></i>
          </div>
        </div>
        <div className="w-5/12">
          <div className="border-2 p-2 rounded-md">
            <div className="font-semibold">ผลรวม</div>

            {_.map(
              _.filter(sumTwoProcessMaterial, (each) => each?.end !== 0),
              (eachMaterial, index) => (
                <div key={index}>
                  <div className="text-lg">
                    {eachMaterial?.material?.type_code}{' '}
                    {eachMaterial?.material?.name}
                  </div>
                  <div>
                    <MultiUnitProdOrMatBox
                      foundMaterial={eachMaterial?.material}
                      noWrap
                      quantity={eachMaterial?.end}
                    />
                  </div>
                </div>
              ),
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MergingProcessForm;

MergingProcessForm.propTypes = {
  watchedMaterial: PropTypes.object,
  selectedProcess: PropTypes.object,
  setValue: PropTypes.func,
};
