import React from 'react';
import { Button } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import CardManufacturingOrder from './CardManufacturingOrder';

/**
 * @function CardMOList
 * @memberof Component/Card
 * @description ลิสต์ของ Card Manufacturing Order สำหรับใช้ในหน้ารายการคำสั่งผลิต
 * ในแบบของ Mobile Mode
 * @returns {React.Component} React Component
 */
const CardMOList = ({
  manufacturingOrder,
  handleDelete,
  displayCancleButton = true,
  displayStatusBadge = true,
  page,
  size,
  total,
  handleChangeRowsPerPage,
}) => (
  <div>
    <div>
      {_.map(manufacturingOrder?.rows, (eachManufacturingOrder, index) => (
        <CardManufacturingOrder
          key={index}
          displayCancleButton={displayCancleButton}
          displayStatusBadge={displayStatusBadge}
          handleDelete={handleDelete}
          manufacturingOrder={eachManufacturingOrder}
        />
      ))}
    </div>
    <div className="flex justify-center my-2">
      <div>
        <div className="flex justify-center">
          <Button
            variant="outlined"
            disabled={page * size >= total}
            onClick={() => {
              handleChangeRowsPerPage({ target: { value: size + 5 } });
            }}
          >
            + เพิ่ม
          </Button>
        </div>
        <div className="text-sm">
          {page * size >= total ? total : page * size} จากทั้งหมด {total || 1}{' '}
          รายการ{' '}
        </div>
      </div>
    </div>
  </div>
);

CardMOList.propTypes = {
  manufacturingOrder: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  handleDelete: PropTypes.func,
  displayCancleButton: PropTypes.bool,
  displayStatusBadge: PropTypes.bool,
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  handleChangeRowsPerPage: PropTypes.func,
};

export default CardMOList;
