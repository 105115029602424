import api from '../../../configs/api';
import { BaseAction } from '../../class';
import {
  INTERNAL_DEPOSIT_ALL,
  INTERNAL_DEPOSIT_DELETE,
  INTERNAL_DEPOSIT_ERROR,
  INTERNAL_DEPOSIT_GET,
  INTERNAL_DEPOSIT_LOADING,
  INTERNAL_DEPOSIT_POST,
  INTERNAL_DEPOSIT_PUT,
} from '../types';

const internalDepositAction = new BaseAction('internal-deposit', {
  allConst: INTERNAL_DEPOSIT_ALL,
  getConst: INTERNAL_DEPOSIT_GET,
  postConst: INTERNAL_DEPOSIT_POST,
  putConst: INTERNAL_DEPOSIT_PUT,
  deleteConst: INTERNAL_DEPOSIT_DELETE,
  errorConst: INTERNAL_DEPOSIT_ERROR,
  loadingConst: INTERNAL_DEPOSIT_LOADING,
});

export const internalDepositCreate = (data) =>
  internalDepositAction.create(data);
export const internalDepositAll = ({ name, page, size, ...query }) =>
  internalDepositAction.getAllData({ name, page, size, ...query });
export const internalDepositGet = (id) => internalDepositAction.getOneData(id);
export const internalDepositPut = (id, data) =>
  internalDepositAction.editOne(id, data);

export const internalDepositStateSet = (data) =>
  internalDepositAction.stateSet(data);
export const internalDepositStateOneSet = (data) =>
  internalDepositAction.stateOneSet(data);
export const internalDepositReset = () => internalDepositAction.reset();
export const internalDepositError = () => internalDepositAction.onError();

// Extend Action
export const internalDepositDelete = (id, employeeId) => async (dispatch) => {
  try {
    dispatch({ type: INTERNAL_DEPOSIT_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/internal-deposit/${id}?employee=${employeeId}`,
    );
    dispatch({ type: INTERNAL_DEPOSIT_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: INTERNAL_DEPOSIT_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};
