import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  DaySpotAndRangePicker,
  Error,
  ExportExcelContainer,
  Loading,
  QuotationReport,
  QuotationTable,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Card, CardContent, Switch, TextField } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { config } from '@/configs';
import * as actions from '@/redux/actions';
import { QuotationUtils } from '@/utils/excelColumn';

/**
 * @function QuotationLogs
 * @description Display a collections or a list of Quotations from database
 */

export default function QuotationLogs({ title, subtitle }) {
  const dispatch = useDispatch();
  const quotationLog = useSelector((state) => state.quotationLog);
  const information = useSelector((state) => state.information);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [total, setTotal] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [allowTimeQuery, setAllowTimeQuery] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const history = useHistory();

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.quotationLogAll({
          page,
          size,
          startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
          endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : '',
          name,
        }),
      );
    } catch (error) {
      dispatch(actions.quotationLogError());
    }
  };

  const handlePrintPdf = (row) => {
    try {
      QuotationReport(row, information, true);
    } catch (error) {
      QuotationReport(row, information);
    }
  };
  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, startDate, endDate, name]);

  useEffect(() => {
    if (quotationLog?.rows) {
      setTotal(quotationLog?.total);
    }

    return () => {};
  }, [quotationLog]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const quotationQuery = {
    page: 1,
    size: config.maxFetchSize,
    startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
    endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : '',
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (quotationLog.isLoading) {
    return <Loading />;
  }
  if (!quotationLog.isLoading && quotationLog.isCompleted) {
    return (
      <div>
        <div className="flex justify-between items-center">{renderTitle()}</div>
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="flex flex-wrap justify-between">
                <div className="w-full md:w-1/2 lg:w-1/3">
                  <TextField
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    size="small"
                    label="ค้นหา"
                    fullWidth
                  />
                </div>
                <div className="w-1/2 lg:w-1/6 ml-4 flex justify-end">
                  <ExportExcelContainer
                    columnList={QuotationUtils.columns}
                    currentData={quotationLog?.rows}
                    dataAPIEndPoint="quotation-log"
                    dataQuery={quotationQuery}
                    sheetName="Quotation"
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="flex flex-wrap items-center">
                <div className="w-full lg:w-1/3 flex items-center">
                  <Switch
                    value={allowTimeQuery}
                    onChange={(event) => {
                      setAllowTimeQuery(event.target.checked);
                      setStartDate(null);
                      setEndDate(null);
                      if (event.target.checked !== true) {
                        setStartDate(null);
                        setEndDate(null);
                        history.push(`?page=${page}&size=${size}&start=&end=`);
                      }
                    }}
                  />
                  <div>เปิดใช้การกรองเวลา</div>
                </div>
                <div className="w-full lg:w-2/3">
                  <DaySpotAndRangePicker
                    addSearchQuery={true}
                    anotherQuery={`?page=${page}&size=${size}&start=${
                      startDate ? dayjs(startDate).format('YYYY-MM-DD') : ''
                    }&end=${
                      endDate ? dayjs(endDate).format('YYYY-MM-DD') : ''
                    }`}
                    flex
                    size="small"
                    enable={allowTimeQuery}
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="my-2">
          <QuotationTable
            quotation={quotationLog}
            page={page}
            size={size}
            total={total}
            setPage={setPage}
            setSize={setSize}
            handleDelete={() => {}}
            handlePrintPdf={handlePrintPdf}
          />
        </div>
      </div>
    );
  }
  return <Error />;
}

QuotationLogs.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

QuotationLogs.defaultProps = {
  title: '',
  subtitle: '',
};
