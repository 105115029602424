import PropTypes from 'prop-types';

export const Product = PropTypes.shape({
  type_code: PropTypes.string,
  name: PropTypes.string,
  unit: PropTypes.string,
  product_type: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  packing_size: PropTypes.number,
  packing_unit: PropTypes.string,
  net: PropTypes.number,
  unit_net: PropTypes.string,
  detail: PropTypes.string,
  steps: PropTypes.object,
});

export const ProductState = PropTypes.shape({
  rows: PropTypes.arrayOf(Product),
  total: PropTypes.number,
  lastPage: PropTypes.number,
  currPage: PropTypes.number,
});

export default { Product, ProductState };
