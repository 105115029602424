import React from 'react';
import { findParentAndChildLot } from '@iarcpsu/emanufac-utils/functions';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { previousLotReciptDateSafe } from '@/utils/functions';

import EmployeeTimePairInEquation from '../Product/EmployeeTimePairInEquation';

const EmployeeCostEquationBox = ({ previousLotList, employee }) => {
  const parentWithChildLots = findParentAndChildLot(previousLotList);

  return (
    <div className="flex gap-2 items-center">
      {_.map(parentWithChildLots, (each, index) => {
        const orderedLot = _.orderBy(
          previousLotReciptDateSafe(
            [each, ...(each?.child_lot || [])],
            'receipt_date',
          ),
        );
        const groupByDatedLots = _.groupBy(orderedLot, 'receipt_date');

        const timeKey = _.keys(groupByDatedLots);
        const pairTimeKey = _.zip(timeKey, timeKey.slice(1));
        const filterPairTime = _.filter(
          pairTimeKey,
          (eachFilterPair) => eachFilterPair?.[1] !== undefined,
        );

        return (
          <div key={index}>
            {_.map(filterPairTime, (eachPair, pairIndex) => (
              <EmployeeTimePairInEquation
                materialStockLotGroupByDated={groupByDatedLots}
                timePair={eachPair}
                key={pairIndex}
                employee={employee}
              />
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default EmployeeCostEquationBox;

EmployeeCostEquationBox.propTypes = {
  previousLotList: PropTypes.array,
  employee: PropTypes.object,
};
