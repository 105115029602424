import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import PropTypes from 'prop-types';

import 'dayjs/locale/th';

import MultiUnitBox from '../Rendering/MultiUnitProdOrMatBox';

dayjs.extend(buddhistEra);
dayjs.locale('th');

const ProductResultBox = ({ selectedProcess, information }) => {
  const {
    product_stock_lots: productStockLots = [{}],
    product_as_materials: productAsMaterials = [{}],
  } = selectedProcess;

  const {
    setting: { wms: wmsSetting },
  } = information;

  return (
    <div className="px-2">
      <div className="">
        {_.map(productStockLots, (_stocklot, index) => (
          <div className="w-full  my-1 p-1" key={index}>
            <div className="rounded-md border p-4 shadow-md">
              <div className="flex justify-between">
                <div className="w-2/3">
                  <Link
                    to={`/wms/product-stock/${_stocklot?.product?._id}/${_stocklot?.warehouse?._id}`}
                  >
                    <h6 className="font-semibold  font-display hover:text-gray-500">
                      {_stocklot?.product?.type_code} {_stocklot?.product?.name}{' '}
                    </h6>
                  </Link>
                  <h6 className="text-lg font-semibold">
                    <MultiUnitBox
                      foundMaterial={_stocklot?.product}
                      quantity={_stocklot?.quantity}
                      noWrap
                    />{' '}
                  </h6>
                </div>
                <div className="w-1/3">
                  {' '}
                  {wmsSetting?.display?.lot_number && (
                    <div>
                      <div className="text-sm font-semibold">หมายเลขล็อต</div>
                      <div>{_stocklot?.lot_number || '-'}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-wrap mt-2">
                <div className=" lg:w-1/3">
                  <div className="text-sm font-semibold ">คลังสินค้า</div>
                  <div>{_stocklot?.warehouse?.name}</div>
                </div>
                <div className="flex lg:w-2/3 ">
                  {wmsSetting?.display?.production_date && (
                    <div className="">
                      <div className="text-sm font-semibold ">วันผลิต</div>
                      <div>
                        {dayjs(_stocklot?.production_date).format('D MMM BBBB')}
                      </div>
                    </div>
                  )}
                  {wmsSetting?.display?.expiration_date && (
                    <div className=" w-1/2">
                      <div className="text-sm font-semibold ">วันหมดอายุ</div>
                      <div>
                        {dayjs(_stocklot?.expiration_date).format('D MMM BBBB')}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {_.isEmpty(productStockLots) && _.isEmpty(productAsMaterials) && (
        <div className="text-center mb-4">ยังไม่มีผลผลิตในคลังสินค้า</div>
      )}
    </div>
  );
};

ProductResultBox.propTypes = {
  selectedProcess: PropTypes.object,
  information: PropTypes.object,
};

export default ProductResultBox;
