import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { DAYS } from '@iarcpsu/emanufac-constant';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import * as actions from '@/redux/actions';

/**
 * @function SettingCEM
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function SettingCEM({ title, subtitle }) {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  const history = useHistory();
  const params = useParams();
  const { control, handleSubmit } = useForm();

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.informationAll());
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, []);

  const handleUpdateSetting = async (data) => {
    try {
      const payload = {
        setting: {
          ...information?.setting,
          pams: {
            ...information?.setting?.pams,
            ...data.setting.pams,
          },
        },
      };
      console.log('payload', payload);
      await dispatch(actions.informationPut(information?._id), payload);
      Swal.fire({
        icon: 'success',
        title: 'บันทึกข้อมูลสำเร็จ',
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'บันทึกข้อมูลไม่สำเร็จ',
        text: error.message,
      });
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (information.isLoading) {
    return <Loading />;
  }

  if (!information.isLoading && information.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div>
          <form onSubmit={handleSubmit(handleUpdateSetting)}>
            <Card>
              <CardContent>
                <div className="flex flex-wrap">
                  <div className="font-semibold w-full">ตั้งค่าวันทำงาน</div>
                  <div className="w-full md:w-1/2 my-1">วันทำงาน</div>
                  <div className="w-full md:w-1/2 my-1">
                    {_.map(DAYS, (day, key) => (
                      <Controller
                        key={key}
                        control={control}
                        name={`setting.pams.working_day.${day.lowercase}`}
                        defaultValue={
                          information?.setting?.pams?.working_day[day.lowercase]
                        }
                        render={({ field }) => (
                          <FormControlLabel
                            label={day.description}
                            control={
                              <Checkbox
                                {...field}
                                defaultChecked={field.value}
                              />
                            }
                          />
                        )}
                      />
                    ))}
                  </div>
                  <div className="w-full md:w-1/2 my-1">
                    ชั่วโมงทำงาน
                    <div className="text-sm">
                      ใช้สำหรับคำนวณการต้นทุนการใช้งานเครื่องจักร ค่าน้ำ ค่าไฟ
                      และต้นทุนการลงทุนอื่นๆ
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 my-1">
                    <Controller
                      control={control}
                      name="setting.pams.cc_working_hour_per_day"
                      defaultValue={
                        information?.setting?.pams?.cc_working_hour_per_day
                      }
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="number"
                          size="small"
                          label="จำนวนชั่วโมง / วัน"
                        />
                      )}
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
            <div className="flex justify-end">
              <Button variant="contained" type="submit">
                บันทึก
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
  return <Error />;
}

SettingCEM.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SettingCEM.defaultProps = {
  title: '',
  subtitle: '',
};
