import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { currencyFormatter, MMS } from '@/utils/functions';

const OneResultingFunctionBox = ({
  eachFunction,
  processMeasurement,
  processResultingFunction,
}) => {
  const { findVariableAndExecute } = MMS;
  const renderFunctionResult = (
    currentFunction,
    stepMeasurements,
    allFunctions,
  ) => {
    try {
      console.log(
        'Resulting Function Type that use for execute',
        currentFunction,
      );
      // console.log('Step Measurement', stepMeasurements);

      // FIXME: Make as a state avoid redundancy
      const anotherFunctions = _.map(allFunctions, (eachAnotherFunction) => ({
        _id: eachAnotherFunction?._id,
        value: findVariableAndExecute({
          measurementVars: stepMeasurements,
          resultingFunctionType: eachAnotherFunction,
          enableLog: false,
        }),
      }));

      const executedResult = findVariableAndExecute({
        measurementVars: stepMeasurements,
        resultingFunctionType: currentFunction,
        enableLog: false,
        anotherMeasurementFT: anotherFunctions,
      });
      return { value: executedResult };
    } catch (error) {
      console.error('Cannot Execute Resulting Function', error);
      return '-';
    }
  };

  return (
    <div className="w-1/2 md:w-1/4 lg:w-1/6 min-w-36 p-2 ">
      <div className="border rounded-md p-2 shadow-md lg:h-36 ">
        <div className="font-semibold self-center my-1">
          {eachFunction?.name}
        </div>
        <div className="p-1 text-xl font-semibold">
          {currencyFormatter.format(
            renderFunctionResult(
              eachFunction,
              processMeasurement,
              processResultingFunction,
            )?.value,
          )}
        </div>
      </div>
    </div>
  );
};

OneResultingFunctionBox.propTypes = {
  eachFunction: PropTypes.object,
  processMeasurement: PropTypes.arrayOf(PropTypes.object),
  processResultingFunction: PropTypes.arrayOf(PropTypes.object),
};

export default OneResultingFunctionBox;
