import {
  QUOTATION_ALL,
  QUOTATION_DELETE,
  QUOTATION_ERROR,
  QUOTATION_GET,
  QUOTATION_LOADING,
  QUOTATION_POST,
  QUOTATION_PUT,
} from '../../actions/types';
import { BaseReducer } from '../../class';

const basedReducerObject = new BaseReducer({
  allConst: QUOTATION_ALL,
  getConst: QUOTATION_GET,
  postConst: QUOTATION_POST,
  putConst: QUOTATION_PUT,
  deleteConst: QUOTATION_DELETE,
  errorConst: QUOTATION_ERROR,
  loadingConst: QUOTATION_LOADING,
});

const initialState = {
  isLoading: true,
  isCompleted: true,
};

const QuotationReducer = (state = initialState, action) =>
  basedReducerObject.getReducer(state, action);

export default QuotationReducer;
