import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Card, CardContent, Chip } from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  ProcessTable,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';

import graphqlClient from '../../configs/graphqlClient';
import * as actions from '../../redux/actions';

/**
 * @function HistoryMachine
 * @description Display a collections or a list of HistoryMachine from database
 */

export default function HistoryMachine({ title, subtitle }) {
  const dispatch = useDispatch();
  const machine = useSelector((state) => state.machine);
  const processes = useSelector((state) => state.process);
  const params = useParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState();

  const query = gql`
    query FindDetailMachine(
      $machineinput: MachineInput
      $processInput: ProcessInput
    ) {
      findOneMachine(input: $machineinput) {
        _id
        name
        licence_number
        is_conveyor
      }
      findProcesses(input: $processInput) {
        total
        rows {
          _id
          manufacturing_order {
            running_number
            start_date
          }
          product {
            type_code
            name
          }
          base_project {
            type_code
            name
          }
          produce_material_instead
          produce_base_project_instead
          product_as_material {
            type_code
            name
          }
          completed
          deleted
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        machineinput: {
          id: params.id,
        },
        processInput: {
          machine: params.id,
          fetchProduct: true,
          page,
          size,
          fetchBaseProject: true,
          fetchMaterial: true,
        },
      });
      const machineData = queryResult?.findOneMachine;
      const processData = queryResult?.findProcesses;
      dispatch(actions.machineStateOneSet(machineData));
      dispatch(actions.processStateSet(processData));
    } catch (error) {
      console.error('Query Error', error);
      dispatch(actions.machineError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, params]);

  useEffect(() => {
    if (!_.isEmpty(processes?.rows)) {
      setTotal(processes?.total);
    }

    return () => {};
  }, [processes]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderBackButton = () => (
    <div className="flex my-2">
      <BackButton />
    </div>
  );

  if (machine.isLoading) {
    return <Loading />;
  }

  if (!machine.isLoading && machine.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}
        <div className="my-4">
          <h4 className="font-semibold font-display mb-2">
            {machine?.name}{' '}
            <span className="font-normal">{machine?.licence_number}</span>
          </h4>
          <ProcessTable
            handleChangePage={handleChangePage}
            handleChangeRowPerPage={handleChangeRowsPerPage}
            page={page}
            processData={processes?.rows}
            size={size}
            total={total}
          />
        </div>
      </div>
    );
  }
  return <Error />;
}

HistoryMachine.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

HistoryMachine.defaultProps = {
  title: '',
  subtitle: '',
};
