import React, { useCallback } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Plus as PlusIcon, Trash } from 'react-feather';
import { Controller, useFieldArray } from 'react-hook-form';
import { Button, Card, CardContent, Slider } from '@mui/material';
import _, { remove } from 'lodash';
import PropTypes from 'prop-types';

import { DASHBOARD_TYPE } from '@/utils/constants';

const MMSDashboardDragAndDrop = ({
  control,
  watch,
  dashboardElement,
  size,
  setSize,
}) => {
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'elements',
  });

  const onBeforeCapture = useCallback(() => {
    /* ... */
  }, []);
  const onBeforeDragStart = useCallback(() => {
    /* ... */
  }, []);
  const onDragStart = useCallback(() => {
    /* ... */
  }, []);
  const onDragUpdate = useCallback(() => {
    /* ... */
  }, []);
  const onDragEnd = useCallback((result) => {
    console.log('Result', result);
    const destinationDropable = result?.destination?.droppableId;
    const destinationIndex =
      _.parseInt(_.last(_.split(result?.destination?.droppableId, '-'))) || 0;
    const sourceDropable = result?.source?.droppableId;
    const sourceIndex = result?.source?.index;

    if (_.startsWith(sourceDropable, 'dashboard-element')) {
      const selectedDashboardElement = dashboardElement?.rows?.[sourceIndex];
      const payload = {
        index: destinationIndex,
        dashboard_element: selectedDashboardElement,
        width: 6,
        height: 1,
      };
      if (_.endsWith(destinationDropable, 'end')) {
        append(payload);
      } else {
        update(destinationIndex, payload);
      }
    }
  }, []);

  return (
    <div>
      <DragDropContext
        onBeforeCapture={onBeforeCapture}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onBeforeDragStart={onBeforeDragStart}
        onDragUpdate={onDragUpdate}
      >
        <div className="flex flex-wrap">
          <div className="w-full lg:w-2/3 px-1">
            <h4 className="font-semibold font-display my-2">
              ส่วนประกอบที่เลือกและขนาด
            </h4>
            <div className="w-full grid grid-cols-12">
              {_.map(_.orderBy(fields, ['index']), (eachField, index) => (
                <div
                  key={eachField.id}
                  className={` rounded-md h-full p-2 col-span-${watch(
                    `elements[${index}].width`,
                  )} row-span-${watch(`elements[${index}].height`)}`}
                >
                  <Card className="h-full">
                    <CardContent>
                      <div className="flex justify-between">
                        <div className="font-semibold">
                          {eachField?.dashboard_element?.name}{' '}
                        </div>
                        <div>
                          <Button
                            size="small"
                            color="error"
                            startIcon={<Trash size={16} />}
                            onClick={() => {
                              remove(index);
                            }}
                          >
                            ลบ
                          </Button>
                        </div>
                      </div>
                      <div>
                        <div>ความกว้าง</div>
                        <Controller
                          name={`elements[${index}].width`}
                          control={control}
                          render={({ field }) => (
                            <Slider
                              aria-label="Width"
                              defaultValue={field.value}
                              valueLabelDisplay="auto"
                              step={1}
                              marks
                              min={1}
                              max={12}
                              {...field}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <div>ความสูง</div>
                        <Controller
                          name={`elements[${index}].height`}
                          control={control}
                          render={({ field }) => (
                            <Slider
                              aria-label="Height"
                              defaultValue={field.value}
                              valueLabelDisplay="auto"
                              step={1}
                              marks
                              min={1}
                              max={4}
                              {...field}
                            />
                          )}
                        />
                      </div>
                    </CardContent>
                  </Card>
                </div>
              ))}
              <div className="col-span-3">
                <Droppable droppableId="display-dropable-end" type="PERSON">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      className="bg-gray-200 rounded-md"
                      style={{
                        backgroundColor: snapshot.isDraggingOver
                          ? 'lightGray'
                          : '',
                      }}
                      {...provided.droppableProps}
                    >
                      <div className=" h-48 flex flex-col ">
                        <div className="my-auto text-gray-400 text-center text-sm">
                          <PlusIcon className="mx-auto  " />
                          <div className="px-2">
                            ลากส่วนประกอบที่ต้องการมาวาง{' '}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Droppable>{' '}
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/3 px-2">
            <Card>
              <CardContent>
                <h4 className="font-semibold font-display">
                  ส่วนประกอบของแดชบอร์ด
                </h4>
                <Droppable
                  droppableId="dashboard-element-dropable"
                  type="PERSON"
                >
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {provided.placeholder}
                      <div className="grid lg:grid-cols-2">
                        {_.map(dashboardElement?.rows, (eachRow, index) => (
                          <Draggable
                            draggableId={`draggable-${index}`}
                            index={index}
                          >
                            {(dragableProvider) => (
                              <div
                                ref={dragableProvider.innerRef}
                                {...dragableProvider.draggableProps}
                                {...dragableProvider.dragHandleProps}
                              >
                                <div
                                  key={index}
                                  className="p-2 rounded-sm border m-1 shadow-md h-full"
                                >
                                  <div className="text-sm font-semibold">
                                    <i
                                      className={
                                        DASHBOARD_TYPE[eachRow?.dashboard_type]
                                          ?.icon || ''
                                      }
                                    />{' '}
                                    {
                                      DASHBOARD_TYPE[eachRow?.dashboard_type]
                                        ?.description
                                    }
                                  </div>
                                  {eachRow?.name}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </div>
                    </div>
                  )}
                </Droppable>{' '}
                {size < dashboardElement?.total && (
                  <div className="my-2 flex justify-center">
                    <Button
                      size="small"
                      variant="contained"
                      color="inherit"
                      onClick={() => setSize(size + 10)}
                    >
                      เพิ่ม{' '}
                      {dashboardElement?.total - size > 10
                        ? '10 รายการ'
                        : `${dashboardElement?.total - size} รายการ`}
                    </Button>
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
        </div>
      </DragDropContext>
    </div>
  );
};

MMSDashboardDragAndDrop.propTypes = {
  control: PropTypes.object,
  watch: PropTypes.func,
  dashboardElement: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
    total: PropTypes.number,
  }),
  size: PropTypes.number,
  setSize: PropTypes.func,
};

export default MMSDashboardDragAndDrop;
