import React from 'react';
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Proptypes, { CRM, MMS, SPM } from '@/utils/Proptypes';

export const OrderForm = ({
  errors,
  order,
  Controller,
  control,
  customers,
  shippingType,
  paymentType,
  productTransactionType,
  materialTransactionType,
  me,
}) => {
  const renderCustomerInfo = () => (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'customer'}
          control={control}
          rules={{ required: true }}
          defaultValue={order ? order?.customer : customers?.rows[0]}
          render={({ field }) => (
            <Autocomplete
              size={'small'}
              disableClearable
              {...field}
              onChange={(e, newValue) => {
                field.onChange(newValue);
              }}
              options={customers?.rows}
              getOptionLabel={(option) => `${option?.name}`}
              required
              renderInput={(params) => (
                <TextField {...params} label="ลูกค้า" required />
              )}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'product_transaction_type'}
          control={control}
          defaultValue={
            order?.product_transaction_type
              ? order?.product_transaction_type?._id
              : ''
          }
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true} required>
              <InputLabel id="type" size={'small'}>
                ประเภทการดำเนินการ สำหรับนำสินค้าออกจากสต็อก
              </InputLabel>
              <Select
                {...field}
                label="ประเภทการดำเนินการ สำหรับนำสินค้าออกจากสต็อก"
                size={'small'}
                fullWidth
                onChange={(e) => {
                  field.onChange(e.target.value);
                }}
              >
                {_.size(productTransactionType) ? (
                  _.map(productTransactionType, (_productTransactionType) => {
                    if (_productTransactionType?.direction === 'desc') {
                      return (
                        <MenuItem
                          key={_productTransactionType.id}
                          value={_productTransactionType.id}
                        >
                          {_productTransactionType.name}
                        </MenuItem>
                      );
                    }
                  })
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'material_transaction_type'}
          control={control}
          defaultValue={
            order?.material_transaction_type
              ? order?.material_transaction_type?._id
              : ''
          }
          rules={{ required: false }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                ประเภทการดำเนินการ สำหรับการเอาวัตถุดิบออกจากสต็อก
              </InputLabel>
              <Select
                {...field}
                label="ประเภทการดำเนินการ สำหรับการเอาวัตถุดิบออกจากสต็อก"
                size={'small'}
                fullWidth
                onChange={(e) => {
                  field.onChange(e.target.value);
                }}
              >
                {_.size(materialTransactionType) ? (
                  _.map(materialTransactionType, (_materialTransactionType) => {
                    if (_materialTransactionType?.direction === 'desc') {
                      return (
                        <MenuItem
                          key={_materialTransactionType.id}
                          value={_materialTransactionType.id}
                        >
                          {_materialTransactionType.name}
                        </MenuItem>
                      );
                    }
                  })
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full px-1 py-2 self-end">
        <Controller
          name={'commerce.payment'}
          control={control}
          rules={{ required: false }}
          defaultValue={order?.commerce ? order?.commerce?.payment?._id : ''}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true} required>
              <InputLabel id="type" size={'small'}>
                ประเภทการชำระเงิน
              </InputLabel>
              <Select
                {...field}
                label="ประเภทการชำระเงิน"
                size={'small'}
                fullWidth
              >
                {_.size(paymentType?.rows) ? (
                  _.map(paymentType.rows, (row, index) => (
                    <MenuItem key={index} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full px-1">
        <label className="text-sm">วันที่ขาย/วันเปิดบิล</label>
        <Controller
          name={'commerce.sale_date'}
          control={control}
          rules={{ required: false }}
          defaultValue={
            order?.commerce?.sale_date
              ? dayjs(order?.commerce?.sale_date)
                  .locale('th')
                  .format('YYYY-MM-DD')
              : dayjs(new Date()).locale('th').format('YYYY-MM-DD')
          }
          render={({ field }) => (
            <TextField
              {...field}
              type="date"
              fullWidth
              size={'small'}
              helperText={errors?.commerce?.sale_date && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 mb-2">
        <label className="text-sm">วันที่กำหนดจัดส่ง </label>
        <Controller
          name={'expected_date'}
          control={control}
          defaultValue={
            order
              ? dayjs(order.expected_date).locale('th').format('YYYY-MM-DD')
              : dayjs(new Date()).locale('th').format('YYYY-MM-DD')
          }
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              type="date"
              fullWidth
              size={'small'}
              helperText={errors.expected_date && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'delivery.shipping'}
          control={control}
          rules={{ required: false }}
          defaultValue={order?.delivery ? order?.delivery?.shipping?._id : ''}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true} required>
              <InputLabel id="type" size={'small'}>
                ประเภทการจัดส่ง
              </InputLabel>
              <Select
                {...field}
                label="ประเภทการจัดส่ง"
                size={'small'}
                fullWidth
              >
                {_.size(shippingType?.rows) ? (
                  _.map(shippingType.rows, (row, index) => (
                    <MenuItem key={index} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'delivery.tracking_number'}
          control={control}
          rules={{ required: false }}
          defaultValue={order?.delivery ? order?.delivery?.tracking_number : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="หมายเลขติดตามสินค้า"
              fullWidth
              size={'small'}
              helperText={errors.delivery?.tracking_number && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name="delivery.shipping_cost"
          control={control}
          rules={{ required: false }}
          defaultValue={order?.delivery ? order?.delivery?.shipping_cost : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="ค่าจัดส่ง"
              fullWidth
              size={'small'}
              helperText={errors.delivery?.shipping_cost && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'commerce.seller'}
          control={control}
          rules={{ required: false }}
          defaultValue={
            order?.commerce
              ? order?.commerce?.seller
              : `${me?.userData?.firstname || ' '}  ${
                  me?.userData?.lastname || ' '
                }`
          }
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อพนักงานขาย"
              fullWidth
              size={'small'}
              helperText={errors?.commerce?.seller && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>{' '}
      <div className="w-full px-1 py-2">
        <Controller
          name={'remark'}
          control={control}
          defaultValue={order ? order?.remark : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="หมายเหตุ"
              fullWidth
              size={'small'}
              rows={3}
              multiline={true}
              helperText={errors.detail && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
    </div>
  );
  return (
    <div>
      <div className="flex flex-wrap gap-4">{renderCustomerInfo()}</div>
    </div>
  );
};

OrderForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    detail: PropTypes.object,
    status: PropTypes.object,
    steps: MMS.processTemplate,
    expected_date: PropTypes.object,
    delivery: PropTypes.object,
    commerce: Proptypes.object,
    product_transaction_type: PropTypes.object,
  }),
  order: PropTypes.oneOfType([SPM.Order, SPM.OrderArray]),
  Controller: PropTypes.func,
  control: PropTypes.object,
  customers: CRM.CustomerState.isRequired,
  paymentType: SPM.PaymentTypeArray,
  shippingType: SPM.ShippingTypeArray,
  isConfirmDailogOpen: PropTypes.bool,
  setIsConfirmDialogOpen: PropTypes.func.isRequired,
  productTransactionType: PropTypes.object,
  me: PropTypes.object,
};

OrderForm.defaultProps = {
  order: null,
  customers: null,
  paymentType: null,
  shippingType: null,
  productTransactionType: null,
};

export default OrderForm;
