import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { config } from '@/configs';
import * as actions from '@/redux/actions';
import { DATA_TYPE } from '@/utils/constants';

/**
 * @function EditProcessTemplateMeasurement
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function EditProcessTemplateResultingFunction({
  title,
  subtitle,
}) {
  const dispatch = useDispatch();
  const processTemplate = useSelector((state) => state.processTemplate);
  const resultingFunctionType = useSelector(
    (state) => state.resultingFunctionType,
  );
  const history = useHistory();
  const params = useParams();

  const { control, setValue, handleSubmit } = useForm({
    defaultValues: {
      resultingFunctionTypes:
        processTemplate?.steps?.[params.index]?.resultingFunctionTypes,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'resultingFunctionTypes',
  });

  const stepIndex = parseInt(params.index, 10);

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.processTemplateGet(params.id));
      dispatch(
        actions.resultingFunctionTypeAll({
          page: 1,
          size: config.maxFetchSize,
        }),
      );
    } catch (error) {
      dispatch(actions.processTemplateError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  useEffect(() => {
    if (processTemplate?.steps?.[params.index]?.resultingFunctionTypes) {
      setValue(
        'measurementTypes',
        processTemplate?.steps?.[params.index]?.resultingFunctionTypes,
      );
    }

    return () => {};
  }, [processTemplate]);

  const onUpdateProcessResultingFunction = async (data) => {
    const stepPayload = processTemplate?.steps?.[stepIndex];
    stepPayload.resultingFunctionTypes = data.resultingFunctionTypes;
    const payload = {
      steps: [
        ...processTemplate?.steps?.slice(0, stepIndex),
        stepPayload,
        ...processTemplate?.steps?.slice(stepIndex + 1),
      ],
    };

    console.log('Payload', payload);
    await dispatch(actions.processTemplatePut(params.id, payload));
    history.push('/mms/process-template/');
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (processTemplate.isLoading) {
    return <Loading />;
  }

  if (!processTemplate.isLoading && processTemplate.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="flex flex-wrap">
                <div className="w-1/5 font-semibold">สูตรการผลิต:</div>
                <div className="w-4/5 font-semibold">
                  {processTemplate?.name}
                </div>
                <div className="w-1/5 font-semibold">ขั้นตอนที่:</div>
                <div className="w-4/5">
                  {stepIndex + 1}. {processTemplate?.steps?.[stepIndex]?.name}
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="my-2">
          <form onSubmit={handleSubmit(onUpdateProcessResultingFunction)}>
            <Card>
              <CardContent>
                <div className="font-semibold my-2">ฟังก์ชันการแสดงผล</div>
                <div className="grid lg:grid-cols-3 p-4">
                  {_.map(fields, (eachField, index) => (
                    <div className="flex my-2" key={eachField.id}>
                      <div className="w-4/5 px-2">
                        {!_.isEmpty(resultingFunctionType?.rows) && (
                          <Controller
                            control={control}
                            name={`resultingFunctionTypes[${index}]`}
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                disablePortal
                                options={resultingFunctionType?.rows}
                                size="small"
                                placeholder="เลือกฟังก์ชั่น"
                                onChange={(e, newValue) => {
                                  field.onChange(newValue);
                                }}
                                className="z-40"
                                // prettier-ignore
                                getOptionLabel={(option) => `${option?.name || ''}`

                            }
                                renderOption={(props, option) => (
                                  <Box component="li" {...props}>
                                    <div className="flex flex-col">
                                      <div className="font-semibold">
                                        {option?.name}
                                      </div>
                                    </div>
                                  </Box>
                                )}
                                renderInput={(inputParams) => (
                                  <TextField
                                    label={` ${index + 1} เลือกฟังก์ชั่น`}
                                    {...inputParams}
                                  />
                                )}
                              />
                            )}
                          />
                        )}
                      </div>
                      <div className="w-1/5 px-1">
                        <Button
                          color="error"
                          size="small"
                          variant="contained"
                          onClick={() => remove(index)}
                        >
                          ลบ
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="pl-4 pb-4">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => append()}
                  >
                    เพิ่ม
                  </Button>
                </div>
              </CardContent>
            </Card>
            <div className="flex justify-end my-2">
              <Button variant="contained" type="submit">
                บันทึก
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditProcessTemplateResultingFunction.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditProcessTemplateResultingFunction.defaultProps = {
  title: '',
  subtitle: '',
};
