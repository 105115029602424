import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  MeasurementFilledForm,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';

/**
 * @function EditStepMeasurement
 * @description Display a collections or a list of MainDetailProcess from database
 */

export default function EditStepMeasurement({ title, subtitle }) {
  const dispatch = useDispatch();
  const step = useSelector((state) => state.step);
  const measurement = useSelector((state) => state.measurement);
  const params = useParams();
  const history = useHistory();

  const query = gql`
    query FindMainDetailProcess(
      $stepInput: StepInput
      $measurementInput: MeasurementInput
    ) {
      findOneStep(input: $stepInput) {
        _id
      }
      findMeasurements(input: $measurementInput) {
        total
        rows {
          _id
          measurement_type {
            _id
            name
            description
            data_type
            type_code
            default_value
            label_left
            label_right
            default_boolean_value
            label_on_additional_text
            required
          }
          value
          boolean_value
          date_value
          person_value {
            _id
            firstname
            lastname
            image {
              url
            }
          }
          order
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        stepInput: { id: params.id },
        measurementInput: {
          step: params.id,
        },
      });
      const stepData = queryResult?.findOneStep;
      const measurementData = queryResult?.findMeasurements;
      dispatch(actions.stepStateOneSet(stepData));
      dispatch(actions.measurementStateSet(measurementData));
    } catch (error) {
      console.error('Data Fetch Error', error);
    }
  };

  const { control, handleSubmit, watch, setValue } = useForm({});

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  useEffect(() => {
    if (measurement?.rows) {
      const measurementWithDefaultValue = _.map(
        _.orderBy(measurement.rows, ['order'], ['asc']),
        (eachMeasurement) => {
          if (!eachMeasurement?.value || eachMeasurement?.value === '') {
            eachMeasurement.value =
              eachMeasurement?.measurement_type?.default_value;
          }
          return eachMeasurement;
        },
      );
      setValue('measurements', measurementWithDefaultValue);
    }

    return () => {};
  }, [measurement]);

  const handleEditMeasurementData = async (data) => {
    try {
      await dispatch(
        actions.measurementPut('0', { measurementArray: data?.measurements }),
      );
      alert('แก้ค่าการวัดค่าสำเร็จ');
      history.goBack();
    } catch (error) {
      console.error('Error on Edit Measurement Value', error);
      alert('แก้ไขการวัดค่าไม่สำเร็จ');
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderBackButton = () => (
    <div className="flex">
      <BackButton />
    </div>
  );

  const renderForm = () => (
    <div className="my-2">
      <Card>
        <CardContent>
          <form onSubmit={handleSubmit(handleEditMeasurementData)}>
            <MeasurementFilledForm control={control} watch={watch} />
            <div className="flex justify-end">
              <Button variant="contained" type="submit">
                บันทึก
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
  if (step.isLoading) {
    return <Loading />;
  }
  if (!step.isLoading && step.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}
        {renderForm()}
      </div>
    );
  }
  return <Error />;
}

EditStepMeasurement.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditStepMeasurement.defaultProps = {
  title: '',
  subtitle: '',
};
