export const PAYROLL_TYPE = {
  MONTHY: {
    status_code: 'MONTHY',
    description: 'รายเดือน',
  },
  DAILY: {
    status_code: 'DAILY',
    description: 'รายวัน',
  },
};

export default PAYROLL_TYPE;
