const QUOTATION_STATUS = {
  IN_PROGRESS: {
    status_code: "IN_PROGRESS",
    description: "กำลังดำเนินงาน",
    query: {
      completed: false,
      deleted: false,
    },
  },
  CANCLE: {
    status_code: "CANCLE",
    description: "ปฏิเสธ",
    query: {
      completed: false,
      deleted: true,
      revised: false,
    },
  },
  SUCCESS: {
    status_code: "SUCCESS",
    description: "สั่งผลิตแล้ว",
    query: {
      completed: true,
      deleted: false,
    },
  },
  REVISED: {
    status_code: "REVISED",
    description: "ถูกแก้ไข",
    query: {
      revised: true,
      deleted: true,
    },
  },
  REVISING: {
    status_code: "REVISING",
    description: "กำลังดำเนินการ และเป็นรายการแก้ไข",
    // ไม่ถูกเอาเป็นสถานะใน Dashboard
    query: {
      revising: true,
      deleted: false,
      completed: false,
    },
  },
};

module.exports = QUOTATION_STATUS;
