import React from 'react';
import { Controller } from 'react-hook-form';
import { FormControlLabel, Switch, TextField } from '@mui/material';
import PropTypes from 'prop-types';

function ExternalSoftwareSettingForm({ control, information }) {
  return (
    <div className="flex flex-wrap">
      <div className="w-full py-1">ระบบคลังสินค้า E-Warehouse</div>
      <div className="w-full py-1">
        <Controller
          name="ewarehouse.enable"
          control={control}
          defaultValue={information?.external_software?.ewarehouse?.enable}
          render={({ field }) => (
            <FormControlLabel
              label="เปิดใช้งาน"
              control={<Switch {...field} defaultChecked={field?.value} />}
            />
          )}
        />
      </div>{' '}
      <div className="w-full py-1">URL ระบบ</div>
      <div className="w-full py-1">
        <Controller
          name="ewarehouse.url"
          control={control}
          defaultValue={information?.external_software?.ewarehouse?.url}
          render={({ field }) => (
            <TextField {...field} label="URL ระบบ" size="small" fullWidth />
          )}
        />
      </div>
    </div>
  );
}

export default ExternalSoftwareSettingForm;

ExternalSoftwareSettingForm.propTypes = {
  control: PropTypes.object,
  information: PropTypes.object,
};
