import React from 'react';
import Chart from 'react-apexcharts';
import { ExternalLink } from 'react-feather';
import { Link } from 'react-router-dom';
import { MANUFAC_ORDER_STATUS } from '@iarcpsu/emanufac-constant';
import { Card, CardContent, Skeleton } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import PieChart from '../Chart/PieChart';

// eslint-disable-next-line arrow-body-style
const StepAnalysisDashboardElements = ({
  step,
  stepLog,
  showResultProduct = true,
  isReady = true,
  manufacturingOrder,
  startDate,
  endDate,
  showMoChart = true,
  selectedEmployeeId = '',
  showClaimedWork = false,
}) => {
  const allSteps = [...(step?.rows || []), ...(stepLog?.rows || [])];
  console.log('All Steps', allSteps);
  // Working Status
  const amountOfSteps = _.size(allSteps);
  const successSteps = _.filter(allSteps, { completed: true });
  const failSteps = _.filter(allSteps, { deleted: true });
  const lateSteps = _.filter(
    allSteps,
    (each) =>
      each?.completed === false &&
      each?.deleted === false &&
      dayjs().isAfter(each?.expected_date),
  );
  const runningSteps =
    amountOfSteps -
    _.size(successSteps) -
    _.size(failSteps) -
    _.size(lateSteps);

  // Working Title
  const stepGroupByName = _.groupBy(allSteps, 'name');
  const groupByNameWorkInfo = _.map(stepGroupByName, (value, key) => {
    const success = _.filter(value, { completed: true });
    const fail = _.filter(value, { deleted: true });
    return {
      name: key,
      total: _.size(value),
      success: _.size(success),
      fail: _.size(fail),
    };
  });

  // Working Product
  const stepGroupByProduct = _.groupBy(allSteps, 'product.type_code');
  const groupByProductWorkInfo = _.map(stepGroupByProduct, (value, key) => {
    const success = _.filter(value, { completed: true });
    const fail = _.filter(value, { deleted: true });
    return {
      type_code: key,
      name: value?.[0]?.product?.name,
      total: _.size(value),
      success: _.size(success),
      fail: _.size(fail),
    };
  });

  const productChartOptions = {
    xaxis: {
      categories: _.map(
        groupByProductWorkInfo,
        (each) => `${each.type_code || ''} - ${each.name || ''}`,
      ),
      labels: {
        show: true,
      },
    },
    yaxis: [
      {
        title: {
          text: 'ปริมาณ',
        },
        labels: {
          formatter: (value) => parseFloat(value).toFixed(2),
        },
      },
    ],
    dataLabels: {
      enabled: true,
      enabledOnSeries: [5],
      formatter: (value) => parseFloat(value).toFixed(2),
    },
  };
  const chartStep = () => (
    <div>
      <div className="text-center">
        สถานะของ<b>งาน</b>ที่ทำ
      </div>

      <PieChart
        donut
        labels={[
          MANUFAC_ORDER_STATUS.SUCCESS.description,
          MANUFAC_ORDER_STATUS.CANCEL.description,
          MANUFAC_ORDER_STATUS.LATED.description,
          MANUFAC_ORDER_STATUS.IN_PROGRESS.description,
        ]}
        series={[
          _.size(successSteps) || 0,
          _.size(failSteps) || 0,
          _.size(lateSteps) || 0,
          _.size(runningSteps) || 0,
        ]}
        height={300}
        anotherOptions={{
          legend: {
            position: 'bottom',
          },
          colors: ['#00e29b', '#ff465d', '#ffae2a', '#2541B2'],
        }}
      />
    </div>
  );

  const chartManufacturingOrder = () => (
    <div>
      <div className="text-center">สถานะคำสั่งผลิต</div>

      <PieChart
        donut
        labels={[
          MANUFAC_ORDER_STATUS.SUCCESS.description,
          MANUFAC_ORDER_STATUS.CANCEL.description,
          MANUFAC_ORDER_STATUS.LATED.description,
          MANUFAC_ORDER_STATUS.IN_PROGRESS.description,
        ]}
        series={[
          manufacturingOrder?.success || 0,
          manufacturingOrder?.fail || 0,
          manufacturingOrder?.late || 0,
          manufacturingOrder?.incumbent || 0,
        ]}
        height={300}
        anotherOptions={{
          legend: {
            position: 'bottom',
          },
          colors: ['#00e29b', '#ff465d', '#ffae2a', '#2541B2'],
        }}
      />
    </div>
  );
  return (
    <div className="grid grid-cols-2 gap-2 md:grid-cols-4 lg:grid-cols-6">
      <Card className="col-span-2 p-2">
        <CardContent>
          {showMoChart ? chartManufacturingOrder() : chartStep()}
        </CardContent>
      </Card>
      <Card className="col-span-2 lg:col-span-4 p-2">
        <CardContent>
          <h3 className="font-semibold my-2">สรุปงานต่างๆ</h3>
          <div className="grid grid-cols-3 gap-2 justify-between">
            <div>
              <div>
                <Link
                  to={`/mms/works/by-status/?startDate=${dayjs(
                    startDate,
                  ).format('YYYY-MM-DD')}&endDate=${dayjs(endDate).format(
                    'YYYY-MM-DD',
                  )}&employee=${selectedEmployeeId}`}
                  className="flex gap-1 items-center hover:underline hover:text-gray-700"
                >
                  จำนวนงานทั้งหมด
                  <ExternalLink size={16} />
                </Link>
              </div>
              {isReady ? (
                <div>
                  <span className="text-2xl">{amountOfSteps}</span> งาน
                </div>
              ) : (
                <Skeleton variant="text" width={100} height={30} />
              )}
            </div>
            <div>
              <div>
                <Link
                  to={`/mms/works/by-status/?startDate=${dayjs(
                    startDate,
                  ).format('YYYY-MM-DD')}&endDate=${dayjs(endDate).format(
                    'YYYY-MM-DD',
                  )}&status=${
                    MANUFAC_ORDER_STATUS.SUCCESS.status_code
                  }&employee=${selectedEmployeeId}`}
                  className="flex gap-1 items-center hover:underline hover:text-gray-700"
                >
                  งานที่สำเร็จ <ExternalLink size={16} />
                </Link>
              </div>
              {isReady ? (
                <div>
                  <span className="text-2xl">{_.size(successSteps)}</span> งาน
                </div>
              ) : (
                <Skeleton variant="text" width={100} height={30} />
              )}
            </div>
            <div>
              <Link
                to={`/mms/works/by-status/?startDate=${dayjs(startDate).format(
                  'YYYY-MM-DD',
                )}&endDate=${dayjs(endDate).format('YYYY-MM-DD')}&status=${
                  MANUFAC_ORDER_STATUS.CANCEL.status_code
                }&employee=${selectedEmployeeId}`}
                className="flex gap-1 items-center hover:underline hover:text-gray-700"
              >
                งานที่ไม่สำเร็จ / ยกเลิก
                <ExternalLink size={16} />
              </Link>
              {isReady ? (
                <div>
                  <span className="text-2xl">{_.size(failSteps)}</span> งาน
                </div>
              ) : (
                <Skeleton variant="text" width={100} height={30} />
              )}
            </div>
            <div>
              <Link
                to={`/mms/works/by-status/?startDate=${dayjs(startDate).format(
                  'YYYY-MM-DD',
                )}&endDate=${dayjs(endDate).format('YYYY-MM-DD')}&status=${
                  MANUFAC_ORDER_STATUS.LATED.status_code
                }&employee=${selectedEmployeeId}`}
                className="flex gap-1 items-center hover:underline hover:text-gray-700"
              >
                งานที่ช้ากว่ากำหนด
                <ExternalLink size={16} />
              </Link>
              {isReady ? (
                <div>
                  <span className="text-2xl">{_.size(lateSteps)}</span> งาน
                </div>
              ) : (
                <Skeleton variant="text" width={100} height={30} />
              )}
            </div>
            <div>
              <Link
                to={`/mms/works/by-status/?startDate=${dayjs(startDate).format(
                  'YYYY-MM-DD',
                )}&endDate=${dayjs(endDate).format('YYYY-MM-DD')}&status=${
                  MANUFAC_ORDER_STATUS.IN_PROGRESS.status_code
                }&employee=${selectedEmployeeId}`}
                className="flex gap-1 items-center hover:underline hover:text-gray-700"
              >
                กำลังดำเนินการ
                <ExternalLink size={16} />
              </Link>
              {isReady ? (
                <div>
                  <span className="text-2xl">{runningSteps}</span> งาน
                </div>
              ) : (
                <Skeleton variant="text" width={100} height={30} />
              )}
            </div>
          </div>
          {showClaimedWork && (
            <div>
              <h3 className="font-semibold my-2">งานเคลม</h3>
              <div className="grid grid-cols-3 gap-2 justify-between">
                <div>
                  <div>
                    <Link
                      to={`/mms/manufacturing-orders/by-status/?startDate=${dayjs(
                        startDate,
                      ).format('YYYY-MM-DD')}&endDate=${dayjs(endDate).format(
                        'YYYY-MM-DD',
                      )}&claimed=true`}
                      className="flex gap-1 items-center hover:underline hover:text-gray-700"
                    >
                      จำนวนคำสั่งผลิตเคลม
                      <ExternalLink size={16} />
                    </Link>
                  </div>
                  {isReady ? (
                    <div>
                      <span className="text-2xl">
                        {manufacturingOrder?.claimed}
                      </span>{' '}
                      รายการ
                    </div>
                  ) : (
                    <Skeleton variant="text" width={100} height={30} />
                  )}
                </div>
              </div>
            </div>
          )}
        </CardContent>
      </Card>
      {showResultProduct && (
        <Card className="col-span-3 p-2">
          <CardContent>
            <div>
              <div>ชื่องานที่ทำ</div>
              {_.size(groupByNameWorkInfo) > 0 ? (
                <PieChart
                  labels={_.map(groupByNameWorkInfo, 'name')}
                  series={_.map(groupByNameWorkInfo, 'total')}
                  donut={true}
                  height={300}
                  anotherOptions={{
                    legend: {
                      position: 'bottom',
                    },
                  }}
                />
              ) : (
                <div className="text-center my-2">ไม่มีข้อมูล</div>
              )}
            </div>
          </CardContent>
        </Card>
      )}
      {showResultProduct && (
        <Card className="col-span-3 p-2">
          <CardContent>
            <div>
              <div>สินค้าที่ผลิต</div>
              {_.size(groupByProductWorkInfo) > 0 ? (
                <Chart
                  type="bar"
                  series={[
                    {
                      name: 'จำนวน',
                      data: _.map(groupByProductWorkInfo, 'total'),
                    },
                  ]}
                  options={productChartOptions}
                  height={300}
                />
              ) : (
                <div className="text-center my-2">ไม่มีข้อมูล</div>
              )}
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default StepAnalysisDashboardElements;

StepAnalysisDashboardElements.propTypes = {
  step: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
    total: PropTypes.number,
  }),
  stepLog: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  showResultProduct: PropTypes.bool,
  isReady: PropTypes.bool,
  manufacturingOrder: PropTypes.shape({
    success: PropTypes.number,
    fail: PropTypes.number,
    late: PropTypes.number,
    incumbent: PropTypes.number,
    claimed: PropTypes.number,
  }),
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  showMoChart: PropTypes.bool,
  selectedEmployeeId: PropTypes.string,
};
