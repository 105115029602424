import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  BackButton,
  CardEmployeeInfo,
  DaySpotAndRangePicker,
  Error,
  Loading,
  LoadingLinear,
  ManufacturingOrderCalendarByStep,
  StepAnalysisDashboardElements,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Card, CardContent } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { config } from '@/configs';
import * as actions from '@/redux/actions';
import { useQuery } from '@/utils/functions';

/**
 * @function EmployeeDashboard
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function EmployeeDashboard({ title, subtitle }) {
  const dispatch = useDispatch();
  const browserQuery = useQuery();
  const employee = useSelector((state) => state.employee);
  const step = useSelector((state) => state.step);
  const stepLog = useSelector((state) => state.stepLog);
  const params = useParams();
  const [startDate, setStartDate] = useState(
    browserQuery.get('startDate')
      ? dayjs(browserQuery.get('startDate')).startOf('month').toDate()
      : dayjs().startOf('month').toDate(),
  );
  const [endDate, setEndDate] = useState(
    browserQuery.get('endDate')
      ? dayjs(browserQuery.get('endDate')).endOf('month').toDate()
      : dayjs().endOf('month').toDate(),
  );

  const queryEmployee = async () => {
    try {
      dispatch(actions.employeeGet(params.id));
    } catch (error) {
      dispatch(actions.employeeError());
    }
  };

  const querySteps = async () => {
    try {
      dispatch(actions.stepLoading());
      dispatch(
        actions.stepAll({
          employee: params.id,
          startDate: dayjs(startDate).format('YYYY-MM-DD'),
          endDate: dayjs(endDate).format('YYYY-MM-DD'),
          page: 1,
          size: config.maxFetchSize,
          all: true,
          fetchManufacturingOrder: true,
        }),
      );
      dispatch(
        actions.stepLogAll({
          employee: params.id,
          startDate: dayjs(startDate).format('YYYY-MM-DD'),
          endDate: dayjs(endDate).format('YYYY-MM-DD'),
          page: 1,
          size: config.maxFetchSize,
          forceWithoutCache: true,
          fetchManufacturingOrder: true,
        }),
      );
    } catch (error) {
      dispatch(actions.stepError());
    }
  };

  useEffect(() => {
    queryEmployee();
    return () => {};
  }, [params]);

  useEffect(() => {
    querySteps();
    return () => {};
  }, [startDate, endDate]);

  const setCurrentDayFunction = (selectedDate) => {
    console.log('Selected Date');
    setStartDate(dayjs(selectedDate).startOf('month').toISOString());
    setEndDate(dayjs(selectedDate).endOf('month').toISOString());
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (employee.isLoading) {
    return <Loading />;
  }

  if (!employee.isLoading && employee.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />{' '}
        <div className="my-2">
          <CardEmployeeInfo employee={employee} />
        </div>
        <div className="my-2">
          <Card>
            <CardContent>
              <DaySpotAndRangePicker
                endDate={endDate}
                setEndDate={setEndDate}
                startDate={startDate}
                setStartDate={setStartDate}
                state="month"
                flex
              />
            </CardContent>
          </Card>
        </div>
        {step.isLoading ? (
          <LoadingLinear />
        ) : (
          <div className="my-2">
            <Card className="my-2">
              <CardContent>
                <div>
                  รายงานตั้งแต่วันที่ {dayjs(startDate).format('D MMMM BBBB')}{' '}
                  ถึงวันที่ {dayjs(endDate).format('D MMMM BBBB')}
                </div>
              </CardContent>
            </Card>
            <StepAnalysisDashboardElements
              step={step}
              stepLog={stepLog}
              startDate={startDate}
              endDate={endDate}
              showMoChart={false}
              selectedEmployeeId={employee?._id}
            />
            <div className="my-2">
              <ManufacturingOrderCalendarByStep
                step={step}
                stepLog={stepLog}
                setCurrentDay={setCurrentDayFunction}
                currentDay={startDate}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
  return <Error />;
}

EmployeeDashboard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EmployeeDashboard.defaultProps = {
  title: '',
  subtitle: '',
};
