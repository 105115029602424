import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  DaySpotAndRangePicker,
  Error,
  Loading,
  PlaceLaborCostTable,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import { config } from '@/configs';
import * as actions from '@/redux/actions';

/**
 * @function PlaceLaborCosts
 * @description Display a collections or a list of PlaceLaborCosts from database
 */

export default function PlaceLaborCosts({ title, subtitle }) {
  const dispatch = useDispatch();
  const placeLaborCost = useSelector((state) => state.placeLaborCost);
  const place = useSelector((state) => state.place);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [total, setTotal] = useState(0);

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.placeLaborCostAll({
          page,
          size,
          startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
          endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : '',
          place: selectedPlace?.id || '',
        }),
      );
    } catch (error) {
      dispatch(actions.placeLaborCostError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, startDate, endDate, selectedPlace]);

  useEffect(() => {
    dispatch(actions.placeAll({ page: 1, size: config.maxFetchSize }));

    return () => {};
  }, []);

  useEffect(() => {
    if (placeLaborCost) {
      setTotal(placeLaborCost?.total);
    }

    return () => {};
  }, [placeLaborCost]);

  const handleDelete = async (id) => {
    try {
      Swal.fire({
        icon: 'warning',
        text: 'ยืนยันการลบข้อมูล',
        showConfirmButton: true,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await dispatch(actions.placeLaborCostDelete(id));
          queryDataFromServer();
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'ลบข้อมูลไม่สำเร็จ',
        text: error?.message,
      });
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (placeLaborCost.isLoading) {
    return <Loading />;
  }
  if (!placeLaborCost.isLoading && placeLaborCost.isCompleted) {
    return (
      <div>
        <div className="flex justify-between items-center">
          {renderTitle()}
          <div className="flex items-center gap-2">
            <Link to="/cem/place-labor-cost/create">
              <Button variant="contained">เพิ่ม</Button>
            </Link>
            <Link to="/cem/place-labor-cost/by-day">
              <Button variant="contained" color="secondary">
                มุมมองรายวัน
              </Button>
            </Link>
          </div>
        </div>
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="flex justify-between items-center">
                <div className="w-full lg:w-4/5">
                  <DaySpotAndRangePicker
                    enable
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    size="small"
                    flex
                  />
                </div>
                <div className="w-full lg:w-1/5 px-2">
                  {!_.isEmpty(place?.rows) && (
                    <Autocomplete
                      disablePortal
                      options={place?.rows}
                      size="small"
                      placeholder="เลือกระบบ"
                      onChange={(e, newValue) => {
                        setSelectedPlace(newValue);
                      }}
                      className="z-40"
                      // prettier-ignore
                      getOptionLabel={(option) => `${option?.name || ''}`}
                      renderInput={(params) => (
                        <TextField label="เลือกคลัง" {...params} />
                      )}
                    />
                  )}
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="my-2">
          <PlaceLaborCostTable
            page={page}
            placeLaborCost={placeLaborCost}
            setPage={setPage}
            size={size}
            total={total}
            setSize={setSize}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    );
  }
  return <Error />;
}

PlaceLaborCosts.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

PlaceLaborCosts.defaultProps = {
  title: '',
  subtitle: '',
};
