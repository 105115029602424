import React from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  converseMaterialOrProductUnit,
  currencyFormatter,
} from '@/utils/functions';

import MultiUnitProdOrMatBox from '../Rendering/MultiUnitProdOrMatBox';

function MaterialQuantityDisplay({
  requestFromProcess,
  requireConfirmation,
  usingFIFO,
  dataField,
  control,
  watch,
  material,
  index,
  stocklots,
}) {
  const information = useSelector((state) => state.information);

  const renderLotOfMaterial = (materialId) => {
    const findedMaterial = _.find(
      material?.rows,
      (_material) => _material?._id === materialId,
    );

    // กรองที่มีปริมาณมากกว่า 0
    const allLots = _.filter(stocklots, (_lot) => _lot?.amount > 0);

    if (!allLots || _.isEmpty(allLots)) {
      return <MenuItem className="font-system"> ไม่มีวัตถุดิบในคลัง</MenuItem>;
    }

    const notAllLot = information?.setting?.ims?.allowManufacturingUseWIPOnly
      ? _.filter(allLots, (each) => each?.place?.wip_place === true)
      : allLots;

    console.log('All Lots', allLots);
    return _.map(notAllLot, (_lot, lotIdx) => {
      const allAmount = _lot.amount;

      return (
        <MenuItem key={lotIdx} value={_lot?._id}>
          {' '}
          ล็อต {_lot?.lot_number} วันที่{' '}
          {_lot?.receipt_date
            ? dayjs(_lot?.receipt_date).locale('th').format('D MMM BBBB')
            : ''}{' '}
          คงเหลือ{' '}
          <MultiUnitProdOrMatBox
            foundMaterial={findedMaterial}
            noWrap
            quantity={allAmount}
          />
        </MenuItem>
      );
    });
  };

  if (usingFIFO) {
    const foundedMaterial = _.find(
      material?.rows,
      (_material) =>
        _material?._id === watch(`materialLots[${index}].material`)?._id,
    );

    const totalMaterial = _.sumBy(stocklots, 'amount');

    if (foundedMaterial?.use_unit_conversion) {
      const amountWithConversion = converseMaterialOrProductUnit(
        foundedMaterial,
        totalMaterial,
      );

      return (
        <div>
          {currencyFormatter.format(totalMaterial)}{' '}
          {foundedMaterial?.unit_input?.short_sign}
          <br />({currencyFormatter.format(amountWithConversion)}{' '}
          {foundedMaterial?.unit_output?.short_sign})
        </div>
      );
    }

    return (
      <div>
        {currencyFormatter.format(totalMaterial)} {foundedMaterial?.unit}
      </div>
    );
  }

  if (!(requestFromProcess && requireConfirmation)) {
    return (
      <div className="w-72">
        {dataField?.fromDatabase === true ? (
          <div>เบิกวัตถุดิบเรียบร้อยแล้ว</div>
        ) : (
          <Controller
            control={control}
            name={`materialLots[${index}].lotId`}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl fullWidth required size="small">
                <InputLabel>ล็อตวัตถุดิบ</InputLabel>
                <Select {...field} label="ล็อตวัตถุดิบ" defaultValue="">
                  {renderLotOfMaterial(
                    watch(`materialLots[${index}].material`)?._id,
                  )}
                </Select>
              </FormControl>
            )}
          />
        )}{' '}
      </div>
    );
  }

  return <div></div>;
}

MaterialQuantityDisplay.propTypes = {
  requestFromProcess: PropTypes.bool,
  requireConfirmation: PropTypes.bool,
  usingFIFO: PropTypes.bool,
  dataField: PropTypes.object,
  control: PropTypes.func,
  watch: PropTypes.func,
  material: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  index: PropTypes.number,
  stocklots: PropTypes.arrayOf(PropTypes.object),
};

export default MaterialQuantityDisplay;
