import {
  PLACE_LABOR_COST_ALL,
  PLACE_LABOR_COST_DELETE,
  PLACE_LABOR_COST_ERROR,
  PLACE_LABOR_COST_GET,
  PLACE_LABOR_COST_LOADING,
  PLACE_LABOR_COST_POST,
  PLACE_LABOR_COST_PUT,
} from '../../actions/types';
import { BaseReducer } from '../../class';

const basedReducerObject = new BaseReducer({
  allConst: PLACE_LABOR_COST_ALL,
  getConst: PLACE_LABOR_COST_GET,
  postConst: PLACE_LABOR_COST_POST,
  putConst: PLACE_LABOR_COST_PUT,
  deleteConst: PLACE_LABOR_COST_DELETE,
  errorConst: PLACE_LABOR_COST_ERROR,
  loadingConst: PLACE_LABOR_COST_LOADING,
});

const initialState = {
  isLoading: true,
  isCompleted: true,
};

const PlaceLaborCostReducer = (state = initialState, action) =>
  basedReducerObject.getReducer(state, action);

export default PlaceLaborCostReducer;
