import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { currencyFormatter } from '@/utils/functions';

const PayrollTable = ({
  payroll,
  page,
  size,
  total,
  handlePushToEditPayroll = () => {},
  handleDeletePayroll = () => {},
  handleChangePage = () => {},
  handleChangeRowsPerPage = () => {},
  isDailyPayroll = true,
  showActionButtons = true,
  showEmployee = true,
}) => (
  <Paper>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            {showEmployee && (
              <TableCell>
                <div className="font-bold"> พนักงาน</div>
              </TableCell>
            )}
            <TableCell>
              <div className="font-bold">
                {' '}
                {isDailyPayroll ? 'วันที่' : 'เดือน'}
              </div>
            </TableCell>

            {isDailyPayroll ? (
              <TableCell>
                <div className="font-bold">ช่วงเวลาที่ทำงาน</div>
              </TableCell>
            ) : (
              <></>
            )}

            <TableCell>
              <div className="font-bold"> ค่าตอบแทนปกติ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> การทำงานล่วงเวลา</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ค่าล่วงเวลา</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> รวมค่าตอบแทนที่ได้รับ</div>
            </TableCell>

            <TableCell>
              <div className="font-bold"> ดำเนินการ</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!_.isEmpty(payroll.rows) ? (
            payroll.rows.map((row, index) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {(page - 1) * size + index + 1}
                </TableCell>
                {showEmployee && (
                  <TableCell>{`${row?.employee?.firstname} ${row?.employee?.lastname}`}</TableCell>
                )}
                <TableCell>
                  {isDailyPayroll
                    ? dayjs(row?.date).format('D MMM BBBB')
                    : dayjs(row?.date).format('MMM BBBB')}
                </TableCell>

                {isDailyPayroll ? (
                  <TableCell>
                    {dayjs(row?.daily_attribute?.start_time).format('HH:mm')} -{' '}
                    {dayjs(row?.daily_attribute?.end_time).format('HH:mm')}
                  </TableCell>
                ) : (
                  <></>
                )}

                <TableCell>
                  {currencyFormatter.format(row?.main_cost)} บาท{' '}
                </TableCell>
                <TableCell>
                  {_.map(row?.overtimes, (eachOvertime, overtimeIndex) => (
                    <div key={`ot${overtimeIndex}`} className="my-2">
                      {dayjs(eachOvertime?.start_time)?.format('HH:mm')} -
                      {dayjs(eachOvertime?.end_time)?.format('HH:mm')} <br />
                      ค่าตอบแทน {currencyFormatter.format(
                        eachOvertime?.cost,
                      )}{' '}
                      บาท
                    </div>
                  ))}
                  <div>
                    รวม {currencyFormatter.format(row?.total_overtime)} บาท
                  </div>
                </TableCell>
                <TableCell>
                  {currencyFormatter.format(row?.total_overtime)} บาท{' '}
                </TableCell>
                <TableCell>
                  {currencyFormatter.format(row?.total_cost)} บาท{' '}
                </TableCell>

                <TableCell>
                  <div className="flex flex-row flex-wrap gap-1">
                    <Link to={`/pams/detail/${row?._id}`}>
                      <Button variant="contained" color={'info'} size={'small'}>
                        รายละเอียด
                      </Button>
                    </Link>

                    {showActionButtons && (
                      <Link to={`/pams/payroll/edit/${row?._id}`}>
                        <Button
                          variant="contained"
                          color={'warning'}
                          size={'small'}
                        >
                          แก้ไข
                        </Button>
                      </Link>
                    )}
                    {showActionButtons && (
                      <Button
                        variant="contained"
                        color={'error'}
                        size={'small'}
                        onClick={() => {
                          handleDeletePayroll(row?.id);
                        }}
                      >
                        ลบ
                      </Button>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6}>
                <div className="text-center">ไม่มีข้อมูล</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      component="div"
      onRowsPerPageChange={handleChangeRowsPerPage}
      page={page - 1}
      count={total || 1}
      rowsPerPage={size}
      onPageChange={handleChangePage}
    />
  </Paper>
);

PayrollTable.propTypes = {
  payroll: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  handlePushToEditPayroll: PropTypes.func,
  handleDeletePayroll: PropTypes.func,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  isDailyPayroll: PropTypes.bool,
  showActionButtons: PropTypes.bool,
  showEmployee: PropTypes.bool,
};

export default PayrollTable;
