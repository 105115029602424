import * as actions from '../../../redux/actions';
import MMSMiddleWare from '../../middleware/MMS';

const handleMOToSuccess = async ({
  dispatch,
  manufacturingOrderId,
  handleSuccess = () => {},
}) => {
  try {
    await dispatch(
      actions.manufacturingOrderPut(manufacturingOrderId, { completed: true }),
    );
    handleSuccess();
    await dispatch(actions.manufacturingOrderGet(manufacturingOrderId));
  } catch (error) {
    window.alert('ไม่สามารถปิดคำสั่งผลิตได้');
    console.error('Handle Manufacturing Order Success Fail', error);
  }
};

const handleMOToCancle = async ({
  dispatch,
  manufacturingOrderId,
  handleSuccess = () => {},
}) => {
  try {
    await dispatch(
      actions.manufacturingOrderPut(manufacturingOrderId, { deleted: true }),
    );
    handleSuccess();
    await dispatch(actions.manufacturingOrderGet(manufacturingOrderId));
  } catch (error) {
    window.alert('ไม่สามารถยกเลิกคำสั่งผลิตได้');
    console.error('Handle Manufacturing Order Cancle Fail', error);
  }
};

const handleEditManufacturingOrder = async ({
  dispatch,
  manufacturingOrder,
  data,
  handleSuccess = () => {},
}) => {
  try {
    await dispatch(
      actions.manufacturingOrderPut(
        manufacturingOrder?._id,
        MMSMiddleWare.ManufacturingOrder(data),
      ),
    );
    await dispatch(actions.manufacturingOrderGet(manufacturingOrder?._id));
    handleSuccess();
  } catch (error) {
    console.error('Edit ManufacturingOrder Fail', error);
    window.alert('ไม่สามารถแก้ไขคำสั่งผลิตได้', error?.message);
  }
};

export default {
  handleMOToSuccess,
  handleMOToCancle,
  handleEditManufacturingOrder,
};
