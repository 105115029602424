import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { converseMaterialOrProductUnit } from '@/utils/functions/converseMaterialOrProductUnit';
import { customNumberFormat } from '@/utils/functions/currencyFormatterTH';

/**
 * การแสดงหน่วยของสินค้า หรือ วัตถุดิบ ทั้งแสดงทั้งหน่วยเดียวและสองหน่วย
 * การเรียกจากภายนอกโฟลเดอร์ Component
 * ``` import {MultiUnitProdOrMatBox} from '@/components' ```
 * เรียกจากภายใน จะอยู่ที่ `/components/Box/Rendering/MultiUnitProdOrMatBox`
 * เช่น เรียก import MultiUnitProdOrMatBox from '../../Box/Rendering/MultiUnitProdOrMatBox';
 */

const MultiUnitProdOrMatBox = ({ foundMaterial, quantity, noWrap = false }) => {
  const information = useSelector((state) => state.information);
  const floatingPoint = information?.setting?.floatingPoint || 2;
  if (foundMaterial?.use_unit_conversion) {
    return (
      <div className={`${noWrap ? 'flex gap-2' : ''}`}>
        <div>
          {customNumberFormat(quantity, floatingPoint)}{' '}
          {foundMaterial?.unit_input?.short_sign}
        </div>
        <div>
          ({' '}
          {customNumberFormat(
            converseMaterialOrProductUnit(foundMaterial, quantity),
            floatingPoint,
          )}{' '}
          {foundMaterial?.unit_output?.short_sign} ){' '}
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="whitespace-nowrap">
        {customNumberFormat(quantity, floatingPoint)} {foundMaterial?.unit}
      </div>
    </div>
  );
};

MultiUnitProdOrMatBox.propTypes = {
  /**
   * Object ของ วัตถุดิบหรือสินค้าที่ต้องการแสดง
   */
  foundMaterial: PropTypes.shape({
    use_unit_conversion: PropTypes.bool,
    unit: PropTypes.string,
    unit_input: PropTypes.shape({
      name: PropTypes.string,
      short_sign: PropTypes.string,
    }),
    unit_output: PropTypes.shape({
      name: PropTypes.string,
      short_sign: PropTypes.string,
    }),
  }),
  /**
   * ปริมาณของวัตถุดิบหรือสินค้า
   */
  quantity: PropTypes.number,
  /**
   * ไม่ให้ขึ้นบรรทัดใหม่หรือไม่
   */
  noWrap: PropTypes.bool,
};

export default MultiUnitProdOrMatBox;
