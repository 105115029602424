import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';

function ProcessTemplateTableForm({ control, watch, setValue, material }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'steps',
  });
  return (
    <div>
      <div className="my-2">
        <Card>
          <CardContent>
            <div className="flex gap-2 ">
              <div className="w-1/2 md:w-1/4">
                <div>ปริมาณรวม</div>
                <div className="font-semibold text-lg">
                  {_.sum(
                    _.map(watch('steps'), (eachField) =>
                      parseFloat(eachField?.percent_amount || 0),
                    ),
                  )}{' '}
                  %{' '}
                </div>{' '}
              </div>
              <div className="w-1/2 md:w-1/4">
                <div>เศษ</div>
                <div className="font-semibold text-lg">
                  {100 -
                    _.sum(
                      _.map(watch('steps'), (eachField) =>
                        parseFloat(eachField?.percent_amount || 0),
                      ),
                    )}{' '}
                  %{' '}
                </div>
              </div>
            </div>
            <div>กดบันทึกเพื่อดูค่าข้อมูลที่เปลี่ยนไป</div>
          </CardContent>
        </Card>
      </div>
      <TableContainer>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ลำดับที่</TableCell>
                <TableCell>ชื่อขั้นตอน</TableCell>
                <TableCell>วัตถุดิบ</TableCell>
                <TableCell>ต้องกรอก</TableCell>
                <TableCell>ขั้นตอนที่ส่งมา</TableCell>
                <TableCell>สัดส่วนโดยทั่วไป</TableCell>
                <TableCell>ลบ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(fields, (eachField, index) => (
                <TableRow key={eachField?.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <Controller
                      control={control}
                      name={`steps[${index}].name`}
                      defaultValue={eachField?.name}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="ชื่อ"
                          fullWidth
                          size="small"
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    {!_.isEmpty(material?.rows) && (
                      <Controller
                        control={control}
                        name={`steps[${index}].main_material`}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            disablePortal
                            options={material?.rows}
                            size="small"
                            placeholder="เลือกวัตถุดิบ"
                            onChange={(e, newValue) => {
                              field.onChange(newValue);
                            }}
                            className="z-40"
                            // prettier-ignore
                            getOptionLabel={(option) => `${option?.name || ''}`
                     }
                            renderInput={(params) => (
                              <TextField label="เลือกวัตถุดิบ" {...params} />
                            )}
                          />
                        )}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <Controller
                      control={control}
                      name={`steps[${index}].material_associate`}
                      defaultValue={eachField?.material_associate}
                      render={({ field }) => (
                        <FormControlLabel
                          label="เกี่ยวข้องกับวัตถุดิบ"
                          control={
                            <Checkbox {...field} defaultChecked={field.value} />
                          }
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <div className="w-full p-1">
                      <Controller
                        control={control}
                        name={`steps[${index}].splited_step`}
                        defaultValue={eachField?.splited_step}
                        render={({ field }) => (
                          <FormControlLabel
                            label="แยกขั้นตอน"
                            control={
                              <Checkbox
                                defaultChecked={field.value}
                                {...field}
                              />
                            }
                          />
                        )}
                      />
                    </div>
                    {watch(`steps[${index}].splited_step`) && (
                      <div className="w-full p-1">
                        <div>
                          ขั้นตอนที่ส่งมาที่ขั้นตอนนี้
                          {watch(`steps[${index}].index`)}
                        </div>
                        <Controller
                          control={control}
                          name={`steps[${index}].from_index`}
                          defaultValue={eachField?.from_index}
                          render={({ field }) => (
                            <Select
                              size="small"
                              label="ขั้นตอนที่ส่งมา"
                              {...field}
                              fullWidth
                              onChange={(e) => {
                                field.onChange(e);
                                // setValue(
                                //   `steps[${index}].index`,
                                //   e.target.value + 1,
                                // );
                              }}
                            >
                              {_.map(watch('steps'), (eachStep, stepIndex) => (
                                <MenuItem
                                  value={eachStep.index}
                                  key={stepIndex}
                                >
                                  {eachStep.index} {eachStep?.name}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    {watch(`steps[${index}].material_associate`) &&
                      watch(`steps[${index}].index`) !== 0 && (
                        <Controller
                          control={control}
                          name={`steps[${index}].percent_amount`}
                          defaultValue={eachField?.percent_amount || 0}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="ปริมาณ (%)"
                              size="small"
                            />
                          )}
                        />
                      )}
                  </TableCell>{' '}
                  <TableCell>
                    <Button
                      color="error"
                      onClick={() => remove(index)}
                      variant="contained"
                    >
                      ลบ
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={7}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      append({
                        index: fields.length + 1,
                        name: '',
                        use_set_of_material: false,
                        splited_step: true,
                      });
                    }}
                  >
                    เพิ่ม
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </TableContainer>
    </div>
  );
}

export default ProcessTemplateTableForm;
