import _ from 'lodash';

import { MANUFACTURING_TRANSACTION_TYPE } from '../../constants';
import converseMaterialOrProductUnit from '../converseMaterialOrProductUnit';

export const materialReportDataListGenerator = (selectedDataList) =>
  _.map(selectedDataList, (eachDataList, dataListIndex) => ({
    index: dataListIndex + 1,
    material_typecode: eachDataList?.material?.type_code,
    material_name: eachDataList?.material?.name,
    request:
      _.find(
        eachDataList?.analyzed,
        (eachAnalyzed) =>
          eachAnalyzed?._id ===
          MANUFACTURING_TRANSACTION_TYPE.MATERIAL_REQUEST.status_code,
      )?.sum || '-',
    waste:
      _.find(
        eachDataList?.analyzed,
        (eachAnalyzed) =>
          eachAnalyzed?._id ===
          MANUFACTURING_TRANSACTION_TYPE.MATERIAL_WASTED.status_code,
      )?.sum || '-',
    return:
      _.find(
        eachDataList?.analyzed,
        (eachAnalyzed) =>
          eachAnalyzed?._id ===
          MANUFACTURING_TRANSACTION_TYPE.MATERIAL_RETURN.status_code,
      )?.sum || '-',
    unit: eachDataList?.material?.use_unit_conversion
      ? eachDataList?.material?.unit_input?.short_sign
      : eachDataList?.material?.unit,
    handover_second_unit: eachDataList?.material?.use_unit_conversion
      ? converseMaterialOrProductUnit(
          eachDataList?.material,
          _.find(
            eachDataList?.analyzed,
            (eachAnalyzed) =>
              eachAnalyzed?._id ===
              MANUFACTURING_TRANSACTION_TYPE.MATERIAL_HANDOVER.status_code,
          )?.sum,
        )
      : '-',
    request_second_unit: eachDataList?.material?.use_unit_conversion
      ? converseMaterialOrProductUnit(
          eachDataList?.material,
          _.find(
            eachDataList?.analyzed,
            (eachAnalyzed) =>
              eachAnalyzed?._id ===
              MANUFACTURING_TRANSACTION_TYPE.MATERIAL_REQUEST.status_code,
          )?.sum,
        )
      : '-',
    waste_second_unit: eachDataList?.material?.use_unit_conversion
      ? converseMaterialOrProductUnit(
          eachDataList?.material,
          _.find(
            eachDataList?.analyzed,
            (eachAnalyzed) =>
              eachAnalyzed?._id ===
              MANUFACTURING_TRANSACTION_TYPE.MATERIAL_WASTED.status_code,
          )?.sum,
        )
      : '-',
    return_second_unit: eachDataList?.material?.use_unit_conversion
      ? converseMaterialOrProductUnit(
          eachDataList?.material,
          _.find(
            eachDataList?.analyzed,
            (eachAnalyzed) =>
              eachAnalyzed?._id ===
              MANUFACTURING_TRANSACTION_TYPE.MATERIAL_RETURN.status_code,
          )?.sum,
        )
      : '-',
    unit_second: eachDataList?.material?.use_unit_conversion
      ? eachDataList?.material?.unit_output?.short_sign
      : '-',
  }));

export default materialReportDataListGenerator;
