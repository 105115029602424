import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  MultiUnitProdOrMatBox,
  ProductStockLotSticker,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import * as actions from '@/redux/actions';
/**
 * @function ProcessBarcodePrint
 * @description Display a collections or a list of ProcessBarcodePrint from database
 */

export default function ProcessBarcodePrint({ title, subtitle }) {
  const dispatch = useDispatch();
  const selectedProcess = useSelector((state) => state.process);
  const productStockLot = useSelector((state) => state.productStockLot);
  const information = useSelector((state) => state.information);
  const params = useParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [total, setTotal] = useState(null);
  const {
    setting: { wms: wmsSetting },
  } = information;

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.processGet(params.id));
      dispatch(
        actions.productStockLotAll({ page, size, selectedProcess: params.id }),
      );
    } catch (error) {
      dispatch(actions.processError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const handleGenerateBarcode = async (id) => {
    try {
      await dispatch(actions.productStockLotUpdateRunningNumber(id));
      dispatch(
        actions.productStockLotAll({ page, size, selectedProcess: params.id }),
      );
    } catch (error) {
      alert(`เกิดข้อผิดพลาดในการสร้างบาร์โค้ด  ${error?.message}`);
    }
  };

  useEffect(() => {
    setTotal(productStockLot?.total);

    return () => {};
  }, [productStockLot]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (selectedProcess.isLoading) {
    return <Loading />;
  }

  if (!selectedProcess.isLoading && selectedProcess.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <Paper>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="font-bold">ลำดับที่</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">สติ๊กเกอร์</div>
                    </TableCell>{' '}
                    {wmsSetting?.display?.lot_number && (
                      <TableCell>
                        <div className="font-bold">หมายเลขล็อต</div>
                      </TableCell>
                    )}
                    <TableCell>
                      <div className="font-bold">สินค้า</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">คลังสินค้า</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">ปริมาณ</div>
                    </TableCell>{' '}
                    <TableCell>
                      <div className="font-bold">รายละเอียด</div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!_.isEmpty(productStockLot?.rows) ? (
                    _.map(productStockLot?.rows, (row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {(page - 1) * size + index + 1}
                        </TableCell>
                        <TableCell>
                          {row?.running_number ? (
                            <ProductStockLotSticker
                              productStockLot={row}
                              information={information}
                            />
                          ) : (
                            <div>
                              <Button
                                size="small"
                                variant="contained"
                                onClick={() => handleGenerateBarcode(row?._id)}
                              >
                                สร้างบาร์โค้ด
                              </Button>
                            </div>
                          )}
                        </TableCell>
                        {wmsSetting?.display?.lot_number && (
                          <TableCell>
                            <div>{row?.lot_number}</div>
                          </TableCell>
                        )}

                        <TableCell>
                          {row?.product?.type_code} {row?.product?.name}
                        </TableCell>
                        <TableCell>{row?.warehouse?.name}</TableCell>
                        <TableCell>
                          {' '}
                          <MultiUnitProdOrMatBox
                            foundMaterial={row?.product}
                            quantity={row?.quantity}
                            noWrap
                          />
                        </TableCell>
                        <TableCell>
                          {wmsSetting?.display?.production_date && (
                            <div className="">
                              <div className="text-sm font-semibold ">
                                วันผลิต
                              </div>
                              <div>
                                {dayjs(row?.production_date).format(
                                  'D MMM BBBB',
                                )}
                              </div>
                            </div>
                          )}
                          {wmsSetting?.display?.expiration_date && (
                            <div className=" w-1/2">
                              <div className="text-sm font-semibold ">
                                วันหมดอายุ
                              </div>
                              <div>
                                {dayjs(row?.expiration_date).format(
                                  'D MMM BBBB',
                                )}
                              </div>
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <div className="text-center">ไม่มีข้อมูล</div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              page={page - 1}
              count={total || 1}
              rowsPerPage={size}
              onRowsPerPageChange={(e) => {
                setSize(e.target.value);
                setPage(1);
              }}
              onPageChange={(e, newPage) => setPage(newPage + 1)}
            />
          </Paper>
        </div>
      </div>
    );
  }
  return <Error />;
}

ProcessBarcodePrint.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ProcessBarcodePrint.defaultProps = {
  title: '',
  subtitle: '',
};
