const WAREHOUSE_STOCK_REQ_STATUS = {
  PENDING_APPROVAL: {
    status_code: "PENDING_APPROVAL",
    description: "รอการอนุมัติ",
  },
  APPROVED: {
    status_code: "APPROVED",
    description: "อนุมัติ กำลังดำเนินการ",
  },
  SUCCESS: {
    status_code: "SUCCESS",
    description: "สำเร็จ",
  },
  REJECT: {
    status_code: "REJECT",
    description: "ปฏิเสธ",
  },
};

module.exports = WAREHOUSE_STOCK_REQ_STATUS;
