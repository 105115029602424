import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { WordLocalizeContext } from '@/contexts/WordLocalizeContext';

import MultiUnitProdOrMatBox from '../../Box/Rendering/MultiUnitProdOrMatBox';

function MaterialLotCostEstimationTable({
  imsSetting,
  materialStockLot,
  page,
  total,
  size,
  setPage,
  setSize,
}) {
  const { findWord } = useContext(WordLocalizeContext);
  return (
    <div>
      <Card>
        <TableContainer>
          <Table className="border-lg mb-4">
            <colgroup>
              <col width="7%" /> {/** ลำดับที่  */}
              <col width="9%" /> {/** เลขอ้างอิง  */}
              <col width="10%" /> {/** วัตถุดิบ  */}
              <col width="10%" /> {/** คลัง  */}
              <col width="12%" /> {/** วันที่รับ  */} {/** วันที่ซื้อ  */}
              {imsSetting?.display?.purchase_date && <col width="12%" />}
              {/** หมายเลขล็อต  */}
              {imsSetting?.display?.lot_number && <col width="10%" />}
              <col width="8%" /> {/** จำนวน  */}
              {/** ต้นทุน  */}
              <col width="15%" />
              <col width="15%" />
              {/** ดำเนินการ  */}
              <col width="10%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่ </div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">เลขอ้างอิง </div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วัตถุดิบ </div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">คลัง </div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">
                    {findWord('recipt_date') || 'วันที่รับวัตถุดิบ'}
                  </div>
                </TableCell>
                {imsSetting?.display?.purchase_date && (
                  <TableCell>
                    <div className="font-bold">
                      {findWord('purchase_date') || 'วันที่ซื้อ'}
                    </div>
                  </TableCell>
                )}
                {imsSetting?.display?.lot_number && (
                  <TableCell>
                    <div className="font-bold"> หมายเลขล็อต</div>
                  </TableCell>
                )}
                <TableCell>
                  <div className="font-bold"> จำนวน</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold"> ต้นทุน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(materialStockLot?.rows) ? (
                materialStockLot?.rows.map((_lot, index) => (
                  <TableRow key={index}>
                    <TableCell>{(page - 1) * size + index + 1}</TableCell>
                    <TableCell>{_lot?.running_number}</TableCell>
                    <TableCell>
                      <b>{_lot?.material?.type_code}</b> {_lot?.material?.name}{' '}
                    </TableCell>
                    <TableCell>{_lot?.place?.name}</TableCell>
                    <TableCell>
                      {_lot?.receipt_date
                        ? dayjs(_lot?.receipt_date)
                            .locale('th')
                            .format('D MMM BBBB HH:mm')
                        : '-'}
                    </TableCell>{' '}
                    {imsSetting?.display?.purchase_date && (
                      <TableCell>
                        {_lot?.purchase_date
                          ? dayjs(_lot?.purchase_date)
                              .locale('th')
                              .format('D MMM BBBB')
                          : '-'}
                      </TableCell>
                    )}
                    {imsSetting?.display?.lot_number && (
                      <TableCell>{_lot?.lot_number || '-'}</TableCell>
                    )}
                    <TableCell>
                      <MultiUnitProdOrMatBox
                        foundMaterial={_lot?.material}
                        quantity={_lot?.quantity}
                      />
                    </TableCell>
                    <TableCell>ต้นทุน</TableCell>
                    <TableCell>
                      <div className="flex flex-col gap-1">
                        <Link to={`/ims/material-stock-lot/${_lot?.id}`}>
                          <Button
                            size="small"
                            variant="contained"
                            color="info"
                            fullWidth
                          >
                            รายละเอียดล็อต
                          </Button>
                        </Link>{' '}
                        <Link to={`/ims/cost-driver/${_lot?.id}`}>
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            fullWidth
                          >
                            รายละเอียดต้นทุน
                          </Button>
                        </Link>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow key={''}>
                  <TableCell colSpan={10}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>{' '}
          <TablePagination
            component="div"
            page={page - 1}
            count={total || 1}
            rowsPerPage={size}
            onRowsPerPageChange={(e) => {
              setSize(e.target.value);
              setPage(1);
            }}
            onPageChange={(e, newValue) => {
              setPage(newValue + 1);
            }}
            showFirstButton
            showLastButton
          />
        </TableContainer>{' '}
      </Card>
    </div>
  );
}

export default MaterialLotCostEstimationTable;

MaterialLotCostEstimationTable.propTypes = {
  /**
   * ค่า Config ของ Inventory System จาก Information
   */
  imsSetting: PropTypes.object,
  /**
   * ข้อมูลของวัตถุดิบที่มีอยู่ใน Stock Lot
   */
  materialStockLot: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  /**
   * หน้าของตาราง
   */
  page: PropTypes.number,
  /**
   * จำนวนรายการทั้งหมด
   */
  total: PropTypes.number,
  /**
   * จำนวนรายการต่อหน้า
   */
  size: PropTypes.number,
  setPage: PropTypes.func,
  setSize: PropTypes.func,
};
