/* eslint-disable import/no-named-as-default-member */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  BackButton,
  CommentBox,
  Loading,
  LoadingDialogWithTimeout,
  ManufacturingMaterialTable,
  SocketIOLoading,
  StepControlBox,
  StepInfoBox,
  StepMeasurementAndMaterialDisplayBox,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import _ from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';
import { MANUFACTURING_TRANSACTION_TYPE, MQ_TASK } from '@/utils/constants';
import { MMS } from '@/utils/functions';
/**
 * @namespace MMS/Work
 * @description การดำเนินการเกี่ยวกับ งาน  และ Step จะเน้นไปที่ผู้ใช้ระดับ User ในการเข้ามาดำเนินการ
 * ต่างๆ ในระบบ
 */

/**
 * @function DetailWork
 * @memberof MMS/Work
 * @description รายละเอียดของงาน หรือรายละเอียดของ Step หน้านี้ใช้การ Get Process มาเป็น API อยู่
 */
const DetailWork = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const step = useSelector((state) => state.step);
  const me = useSelector((state) => state.me);
  const process = useSelector((state) => state.process);
  const comment = useSelector((state) => state.comment);
  const manufacturingMaterial = useSelector(
    (state) => state.manufacturingMaterial,
  );

  const [commentSize, setCommentSize] = useState(200);
  const size = 10;
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(undefined);
  const [sendWorkDialogOpen, setSendWorkDialogOpen] = useState(false);
  const [loadingDialogOn, setLoadingDialogOn] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState();

  const {
    MaterialAnalyzer: { unNullManufacMaterialAnalyzedArrayAnalzer },
  } = MMS;

  const disableEditing =
    step?.completed ||
    step?.deleted ||
    // ดักให้เฉพาะผู้รับ
    (me?.userData?.role?.level <= 0 &&
      (!step?.responsible?.employee ||
        step?.responsible?.employee?._id !== me?.userData?._id));

  useEffect(() => {
    setTotal(manufacturingMaterial?.total);
    return () => {};
  }, [manufacturingMaterial]);

  const handleStepGet = async () => {
    try {
      dispatch(actions.stepReset());

      await dispatch(actions.stepGet(params?.id));
    } catch (error) {
      alert(`Error ${error?.message}`);
      console.error(error);
    }
  };

  // Data Initialize
  useEffect(() => {
    handleStepGet();

    return () => {};
  }, [params]);

  useEffect(() => {
    const getProcess = async () => {
      if (step?.process?._id) {
        try {
          await dispatch(actions.processGet(step?.process?._id));
        } catch (error) {
          console.error(error);
        }
      }
    };
    const fetchManufacturingMaterials = async () => {
      try {
        await dispatch(
          actions.manufacMaterialAll({
            processId: process?._id,
            step: params.id,
          }),
        );
      } catch (error) {
        console.error(error);
      }
    };

    fetchManufacturingMaterials();
    getProcess();

    return () => {};
  }, [step]);

  useEffect(() => {
    const fetchComment = async () => {
      if (step?.process?._id) {
        try {
          await dispatch(
            actions.commentAll({
              processId: step?.process?._id,
              size: commentSize,
              page: 1,
            }),
          );
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchComment();

    return () => {};
  }, [commentSize]);

  const handleSocketIOFunction = {
    onSuccess: () => {
      setLoadingDialogOn(false);
      handleStepGet();
      window.alert('บันทึกข้อมูลให้กระบวนการผลิตสำเร็จ');
    },
    onFail: (args) => {
      setLoadingDialogOn(false);
      console.log('Error', args?.error);
      window.alert(`บันทึกข้อมูลไม่สำเร็จ ${args?.message}`);
    },
  };

  const handleSendwork = async (tempRefNo) => {
    const payload = {
      process: process?._id,
      materials: _.map(
        unNullManufacMaterialAnalyzedArrayAnalzer(
          process?.manufacturing_materials,
        ),
        (_material) => ({
          material: _material?.materialInfo?._id,
          end: _material?.resultAmount,
          wastes: 0,
        }),
      ),
      employeeId: me?.userData?._id,
      manufacturing_transaction_type:
        MANUFACTURING_TRANSACTION_TYPE.WORK_CLOSE.status_code,
      referenceNumber: tempRefNo,
    };
    console.log('Next Step Payload', payload);
    setReferenceNumber(tempRefNo);
    dispatch(actions.stepPost(payload));
    setLoadingDialogOn(true);
  };

  const renderManufacMaterialTxn = () => (
    <div className="w-full  p-1">
      <ManufacturingMaterialTable
        handleChangePage={(selectedPage) => setPage(selectedPage)}
        manufacturingMaterials={manufacturingMaterial?.rows}
        page={page}
        size={size}
        total={total}
      />
    </div>
  );

  // UI Rendering
  const renderTitle = () => (
    <ViewTitle title={`${title} ${step?.name}`} subtitle={subtitle} />
  );

  const renderSendWorkDialog = () => (
    <Dialog
      open={sendWorkDialogOpen}
      onClose={() => {
        setSendWorkDialogOpen(false);
      }}
    >
      <DialogTitle>ส่งงาน</DialogTitle>
      <DialogContent>
        คุณได้บันทึกการใช้วัตถุดิบแล้ว ต้องการส่งงานเลยหรือไม่
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          variant="contained"
          onClick={async () => {
            const tempRefNo = hash({
              user: me?.userData?._id,
              date: new Date(),
            });
            handleSendwork(tempRefNo);
            setSendWorkDialogOpen(false);
          }}
        >
          ส่งงานเลย
        </Button>
        <Button
          variant="contained"
          onClick={() => setSendWorkDialogOpen(false)}
        >
          ภายหลัง
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderMaterialPanel = () => (
    <StepMeasurementAndMaterialDisplayBox
      manufacturingMaterials={process?.manufacturing_materials}
      step={step}
      measurements={step?.measurements}
      selectedProcess={process}
    />
  );

  const renderMaterialPart = () => (
    <div>
      <div className="w-full my-2">
        {renderMaterialPanel()}
        <div className="font-semibold font-display text-xl px-1 mt-4">
          การใช้วัตถุดิบ{' '}
        </div>
        {renderManufacMaterialTxn()}
      </div>
    </div>
  );

  if (step?.isLoading) {
    return <Loading />;
  }

  if (!step?.isLoading && step?.isCompleted) {
    return (
      <div>
        <LoadingDialogWithTimeout
          isLoading={loadingDialogOn}
          label="กำลังดำเนินการส่งงาน"
        />

        <SocketIOLoading
          taskCode={MQ_TASK.MOVING_STEP_UP.status_code}
          handleSuccess={handleSocketIOFunction.onSuccess}
          handleFail={handleSocketIOFunction.onFail}
          referenceNumber={referenceNumber}
        />

        {renderSendWorkDialog()}
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>

        <StepInfoBox step={step} process={process} />
        <StepControlBox
          step={step}
          process={process}
          handleSendwork={handleSendwork}
        />

        {step?.material_associate || !_.isEmpty(step?.measurements) ? (
          renderMaterialPart()
        ) : (
          <div></div>
        )}

        <div className="my-2">
          <div className="font-semibold font-display text-xl px-1  mt-4 mb-2 ">
            ความคิดเห็น{' '}
          </div>
          <CommentBox
            comment={comment}
            processId={step?.process?._id}
            size={commentSize}
            showTitle={false}
            setSize={setCommentSize}
            handleSuccess={async () => {
              await dispatch(
                actions.commentAll({
                  processId: step?.process?._id,
                  commentSize,
                  page: 1,
                }),
              );
            }}
          />
        </div>
      </div>
    );
  }
  return <div></div>;
};

DetailWork.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailWork.defaultProps = {
  title: '',
  subtitle: '',
};

export default DetailWork;
