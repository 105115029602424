import {
  PROCESS_TEMPLATE_ALL,
  PROCESS_TEMPLATE_GET,
  PROCESS_TEMPLATE_POST,
  PROCESS_TEMPLATE_PUT,
  PROCESS_TEMPLATE_DEL,
  PROCESS_TEMPLATE_ERROR,
  PROCESS_TEMPLATE_LOADING,
} from '../types';

import api from '../../../configs/api';
import graphqlClient from '../../../configs/graphqlClient';

export const processTemplateCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PROCESS_TEMPLATE_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/process-template`,
      {
        ...payload,
      },
    );
    dispatch({ type: PROCESS_TEMPLATE_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PROCESS_TEMPLATE_ERROR });
    throw new Error(error);
  }
};

export const processTemplateAll = ({
  name = '',
  size = '',
  page = 1,
  productName = '',
  productId = '',
}) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/process-template?name=${name}&size=${size}&page=${page}&productName=${productName}&productId=${productId}`,
    );
    if (status === 200) {
      dispatch({ type: PROCESS_TEMPLATE_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PROCESS_TEMPLATE_ERROR });
    throw new Error(error);
  }
};

export const processTemplateAllQuery = ({ query }) => async (dispatch) => {
  try {
    // console.log('Query', query);
    const queryResult = await graphqlClient.request(query);
    const data = queryResult?.findProcessTemplates;
    // console.log('Query Data', queryResult);
    if (!data) {
      dispatch({ type: PROCESS_TEMPLATE_ERROR });
    }

    dispatch({ type: PROCESS_TEMPLATE_ALL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PROCESS_TEMPLATE_ERROR });
    throw new Error(error);
  }
};

export const processTemplateGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/process-template/${id}`,
    );
    if (status === 200) {
      dispatch({ type: PROCESS_TEMPLATE_GET, payload: data });
    }
    if (status === 404) {
      dispatch({ type: PROCESS_TEMPLATE_ERROR, payload: { message: '' } });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PROCESS_TEMPLATE_ERROR });
    throw new Error(error);
  }
};

export const processTemplateStateSet = (data) => async (dispatch) => {
  dispatch({ type: PROCESS_TEMPLATE_ALL, payload: data });
};

export const processtemplateStateOneSet = (data) => async (dispatch) => {
  dispatch({ type: PROCESS_TEMPLATE_GET, payload: data });
};

export const processtemplateReset = () => async (dispatch) => {
  dispatch({ type: PROCESS_TEMPLATE_LOADING });
};

export const processTemplateError = () => async (dispatch) => {
  dispatch({ type: PROCESS_TEMPLATE_ERROR });
};

export const processTemplatePut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: PROCESS_TEMPLATE_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/process-template/${id}`,
      payload,
    );
    dispatch({ type: PROCESS_TEMPLATE_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PROCESS_TEMPLATE_ERROR });
    throw new Error(error);
  }
};

export const processTemplateDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: PROCESS_TEMPLATE_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/process-template/${id}`,
    );
    dispatch({ type: PROCESS_TEMPLATE_DEL, payload: data });
  } catch (error) {
    const { status } = error.request;
    console.error(error);

    if (status === 403) {
      // prettier-ignore
      const message = 'ไม่สามารถลบข้อมูลได้';
      dispatch({ type: PROCESS_TEMPLATE_ERROR, payload: { message } });
      throw new Error(message);
    } else {
      const message = 'เกิดข้อผิดพลาด';
      dispatch({ type: PROCESS_TEMPLATE_ERROR, payload: { message } });
      throw new Error(message);
    }
  }
};
