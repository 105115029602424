import React from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { Link } from 'react-router-dom';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  converseMaterialOrProductUnit,
  currencyFormatter,
} from '@/utils/functions';

const ProductAmountTable = ({
  product,
  setOrderByField,
  orderBy,
  setOrderBy,
  orderByField,
  page,
  size,
  total,
  setPage,
  setSize,
  placeId,
}) => {
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  return (
    <Paper>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <div className="font-bold">ลำดับที่</div>
              </TableCell>
              <TableCell
                onClick={() => {
                  setOrderByField('type_code');
                  if (orderBy === 'asc') {
                    setOrderBy('desc');
                  } else {
                    setOrderBy('asc');
                  }
                }}
              >
                <div className="font-bold flex gap-2">
                  ชื่อสินค้า
                  {orderByField === 'type_code' && orderBy === 'desc' ? (
                    <ChevronDown size="16" className="self-center" />
                  ) : (
                    <ChevronUp size="16" className="self-center" />
                  )}
                </div>
              </TableCell>
              <TableCell
                onClick={() => {
                  setOrderByField('total');
                  if (orderBy === 'asc') {
                    setOrderBy('desc');
                  } else {
                    setOrderBy('asc');
                  }
                }}
              >
                <div className="font-bold flex gap-2">
                  {' '}
                  คงเหลือ
                  {orderByField === 'total' && orderBy === 'desc' ? (
                    <ChevronDown size="16" className="self-center" />
                  ) : (
                    <ChevronUp size="16" className="self-center" />
                  )}
                </div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> ดำเนินการ</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!_.isEmpty(product?.rows) ? (
              product?.rows.map((row, index) => (
                <TableRow
                  key={row._id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {(page - 1) * size + index + 1}
                  </TableCell>
                  <TableCell>
                    <div>
                      <span className="font-display font-bold">
                        {row?.type_code}
                      </span>{' '}
                      <span>{row?.name}</span>
                    </div>
                  </TableCell>
                  <TableCell>
                    {currencyFormatter.format(row?.total)}{' '}
                    {row?.use_unit_conversion
                      ? row?.unit_input?.short_sign
                      : row?.unit}
                    {row?.use_unit_conversion && (
                      <div>
                        ({' '}
                        {currencyFormatter.format(
                          converseMaterialOrProductUnit(row, row?.total),
                        )}{' '}
                        {row?.unit_output?.short_sign} )
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    {' '}
                    <div className="flex flex-row flex-wrap gap-1">
                      <Link
                        to={`/wms/product-stock/${row?._id}/${placeId || ''}`}
                      >
                        <Button
                          variant="contained"
                          color={'info'}
                          size={'small'}
                        >
                          รายละเอียด
                        </Button>
                      </Link>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <div className="text-center">ไม่มีข้อมูล</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page - 1}
        count={total || 1}
        rowsPerPage={size}
        onPageChange={handleChangePage}
      />
    </Paper>
  );
};

export default ProductAmountTable;

ProductAmountTable.propTypes = {
  product: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  setOrderByField: PropTypes.func,
  orderBy: PropTypes.string,
  setOrderBy: PropTypes.func,
  orderByField: PropTypes.string,
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  setPage: PropTypes.func,
  setSize: PropTypes.func,
  placeId: PropTypes.string,
};
