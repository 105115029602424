/* eslint-disable import/no-named-as-default-member */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import {
  EmployeeWorkLoadCard,
  Error,
  Loading,
  ManufacturingOrderCalendar,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import * as actions from '@/redux/actions';

function ManufactoringOrders({ title, subtitle }) {
  const dispatch = useDispatch();
  const manufacturingOrder = useSelector((state) => state.manufacturingOrder);
  const employee = useSelector((state) => state.employee);
  const step = useSelector((state) => state.step);

  const [periodText, setPeriodText] = useState('ในเดือนล่าสุด');
  const [endDate, setEndDate] = useState(dayjs().endOf('month').toISOString());
  const [startDate, setStartDate] = useState(
    dayjs().startOf('month').toISOString(),
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleChangePeriod = (value) => {
    if (value === 'week') {
      setStartDate(dayjs().startOf('week').toISOString());
      setEndDate(dayjs().endOf('week').toISOString());
      setPeriodText('ในสัปดาห์ล่าสุด');
    } else if (value === 'month') {
      setStartDate(dayjs().startOf('month').toISOString());
      setEndDate(dayjs().endOf('month').toISOString());
      setPeriodText('ในเดือนล่าสุด');
    } else if (value === 'year') {
      setStartDate(dayjs().startOf('year').toISOString());
      setEndDate(dayjs().endOf('year').toISOString());
      setPeriodText('ในปีล่าสุด');
    }
  };

  useEffect(() => {
    const fetchManufacturingOrder = async () => {
      try {
        await dispatch(
          actions.manufacturingOrderAll({
            all: true,
            startDate,
            endDate,
            analyzed: true,
          }),
        );
      } catch (error) {
        console.error('Error on Get Manufacturing order ', error);
      }
    };

    const fetchStep = async () => {
      try {
        await dispatch(actions.stepAll({ all: true, startDate, endDate }));
      } catch (error) {
        console.error(error);
      }
    };

    const fetchEmployee = async () => {
      try {
        await dispatch(actions.employeeAll({}));
      } catch (error) {
        console.error(error);
      }
    };

    fetchManufacturingOrder();
    fetchStep();
    fetchEmployee();

    return () => {};
  }, [startDate, endDate]);

  const renderPeriod = () => (
    <div>
      <FormControl sx={{ minWidth: 120 }}>
        <InputLabel id="type" size={'small'}>
          ช่วงเวลา
        </InputLabel>
        <Select
          label="ช่วงเวลา"
          size={'small'}
          fullWidth
          defaultValue="month"
          onChange={(e) => {
            handleChangePeriod(e.target.value);
          }}
        >
          <MenuItem value="week">สัปดาห์ล่าสุด</MenuItem>
          <MenuItem value="month">เดือนล่าสุด</MenuItem>
          <MenuItem value="year">ปีล่าสุด</MenuItem>
        </Select>
      </FormControl>
    </div>
  );

  const setCurrentDayFunction = (selectedDate) => {
    console.log('Selected Date');
    setStartDate(dayjs(selectedDate).startOf('month').toISOString());
    setEndDate(dayjs(selectedDate).endOf('month').toISOString());
  };

  if (manufacturingOrder?.isLoading) {
    return <Loading />;
  }

  if (!manufacturingOrder?.isLoading && manufacturingOrder?.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderPeriod()}</div>
        </div>
        <div>
          <div>
            <ManufacturingOrderCalendar
              manufacturingOrder={manufacturingOrder}
              periodText={periodText}
              step={step}
              setCurrentDay={setCurrentDayFunction}
            />
            <EmployeeWorkLoadCard
              employee={employee}
              step={step}
              periodText={periodText}
            />
          </div>
        </div>
      </div>
    );
  }
  return <Error />;
}

ManufactoringOrders.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ManufactoringOrders.defaultProps = {
  title: '',
  subtitle: '',
};

export default ManufactoringOrders;
