import React from 'react';
import { Check as CheckIcon, X as CrossIcon } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Alert,
  Backdrop,
  Button,
  Card,
  CardContent,
  Modal,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import PropTypes from 'prop-types';

import 'dayjs/locale/th';

import { MMS as MMSFunction } from '@/utils/functions';
import ProductResultBox from '../../Box/Product/ProductResultBox';

dayjs.extend(buddhistEra);

const CancleProcessModal = ({
  isOpen,
  handleClose,
  successHandle,
  selectedProcess,
  manufacturingOrder,
}) => {
  const information = useSelector((state) => state.information);
  const {
    StepStatusCheck,
    ProcessStatusCheck,
    MaterialAnalyzer: { manufacMaterialAnalyzedArrayAnalzer },
  } = MMSFunction;
  const CurrentStepStatus = StepStatusCheck(selectedProcess?.current?.step);
  const CurrentProcessStatus = ProcessStatusCheck(
    selectedProcess,
    selectedProcess?.current?.step,
  );

  const analyzedMaterial = manufacMaterialAnalyzedArrayAnalzer(
    selectedProcess?.manufacturing_materials,
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '60vw',
    maxHeight: '70vh',
    p: 4,
    overflowY: 'auto',
  };

  const remainingStepData = () => {
    if (
      CurrentProcessStatus.processStatus.isLastStepCompleteButProcessNotComplete
    ) {
      return (
        <div className="px-1 my-4">
          <h3 className="font-semibold">การทำงานปัจจุบัน</h3>
          <div className="flex gap-2 ">
            <div className="self-center mr-2">
              <div className="p-2 rounded-full bg-gray-100 max-w-max">
                <CheckIcon />
              </div>
            </div>
            <div>
              <div className="my-1 text-lg">งานทั้งหมดเสร็จสิ้นแล้ว</div>
              <div className="my-1 text-sm">
                เหลือเพียงการดำเนินการกับผลผลิตเท่านั้น
                โปรดดำเนินการกับผลผลิตให้แล้วเสร็จ
                ไลน์การผลิตนี้ไม่จำเป็นต้องยกเลิก
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="px-1 my-4">
        <h3 className="font-semibold">การทำงานปัจจุบัน</h3>
        <div className="flex gap-2 ">
          <div className="self-center mr-2">
            {CurrentStepStatus.isNonVacant ? (
              <div className="p-2 rounded-full bg-gray-100 max-w-max">
                <CrossIcon />
              </div>
            ) : (
              <div className="p-2 rounded-full bg-gray-100 max-w-max">
                <CheckIcon />
              </div>
            )}
          </div>
          <div>
            <div className="my-1 text-lg">
              {selectedProcess?.current?.step?.name}
            </div>
            <div className="my-1 text-sm">
              {CurrentStepStatus.isNonVacant ? (
                <div>
                  งานนี้มีผู้รับผิดชอบอยู่ กรุณาให้งานชิ้นล่าสุดสำเร็จก่อน
                </div>
              ) : (
                <div>ยังไม่มีผู้รับผิดชอบ สามารถยกเลิกงานที่ขั้นตอนนี้ได้</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const remainingMaterialData = () => (
    <div className="px-1 my-4">
      <h3 className="font-semibold">วัตถุดิบคงเหลือ</h3>
      <div className="lg:w-1/2">
        <Alert severity="warning" className="mt-2">
          <p className=" ">
            กรุณาตรวจสอบวัตถุดิบ
            และคืนวัตถุดิบที่ไม่ได้ใช้ให้แล้วเสร็จก่อนจะกดยกเลิก
            เมื่อยกเลิกไลน์การผลิตไปแล้ว จะไม่สามารถย้อนกลับมาคืนวัตถุดิบได้
            และวัตถุดิบที่เหลือทั้งหมดจะคิดเป็นของเสีย
          </p>
        </Alert>
      </div>
      <div className="my-2 font-system">
        {_.map(
          _.filter(
            analyzedMaterial,
            (_material) => _material?.materialInfo?._id !== undefined,
          ),
          (_material, index) => (
            <li key={index}>
              <span className="font-semibold">
                {_material?.materialInfo?.type_code}{' '}
              </span>
              {_material?.materialInfo?.name} ใช้ได้ {_material?.resultAmount}{' '}
              {_material?.materialInfo?.unit}
            </li>
          ),
        )}
      </div>
    </div>
  );

  // prettier-ignore
  const remainingProductData = () => !_.isEmpty(selectedProcess?.product_stock_lots) && (
      <div className="px-1 my-4">
        <h3 className="font-semibold">สินค้าที่ได้</h3>
        <div className="text-sm">กรณีมีสินค้า ที่ได้ออกมาระหว่างการผลิต</div>
        <div className="my-4">
          <ProductResultBox selectedProcess={selectedProcess} information={information} />
          <Link to={`/process/add-warehouse/${selectedProcess?._id}`}>
          <Button variant="contained" >
            เพิ่มลงในคลังสินค้า
          </Button>
          </Link>
        </div>
      </div>
    );

  const remainingProcessInMo = () => {
    const processStatus = _.map(
      manufacturingOrder?.process,
      // prettier-ignore
      (_process) => _process?._id === selectedProcess?._id ||
        _process?.deleted ||
        _process?.completed,
    );

    if (
      !_.includes(processStatus, false) &&
      !(
        CurrentStepStatus.isNonVacant ||
        CurrentProcessStatus.processStatus
          .isLastStepCompleteButProcessNotComplete
      )
    ) {
      return (
        <div>
          <Alert>
            <p>
              เนื่องจากเป็นไลน์การผลิตสุดท้ายในคำสั่งผลิต
              คำสั่งผลิตนี้จะถูกยกเลิกทันทีหลังจากยกเลิกไลน์ผลิตนี้
            </p>
          </Alert>
        </div>
      );
    }

    return <div></div>;
  };

  const renderFormInput = () => {
    if (
      !(
        CurrentStepStatus.isStarted ||
        CurrentProcessStatus.processStatus
          .isLastStepCompleteButProcessNotComplete
      )
    ) {
      return (
        <div className="my-2">
          <Controller
            name="delete_note"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="เหตุผลที่ต้องการยกเลิก"
                rows={2}
                multiline
                fullWidth
                required
                helperText={
                  errors?.delete_note && 'กรุณากรอกเหตุผลที่ต้องการเบิก'
                }
              />
            )}
          />
        </div>
      );
    }
    return <div></div>;
  };

  // TODO: Keep Lot that user withdraw
  return (
    <Modal
      open={isOpen}
      onClose={() => handleClose()}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Card style={style}>
        <CardContent>
          <div className="p-4">
            <h4 className="font-semibold text-lg my-2">ยกเลิกไลน์การผลิต</h4>
            {remainingStepData()}
            {!CurrentProcessStatus.processStatus
              .isLastStepCompleteButProcessNotComplete && (
              <div>
                {CurrentProcessStatus.processStatus.isRequestMaterial &&
                  remainingMaterialData()}
                {!CurrentProcessStatus.processStatus.isNoCurrentStep &&
                  remainingProductData()}
              </div>
            )}
            {renderFormInput()} {remainingProcessInMo()}
            <br />
            <div className="flex gap-2 justify-end my-2">
              <Button
                type="button"
                color="primary"
                variant="contained"
                disabled={
                  CurrentStepStatus.isStarted ||
                  CurrentProcessStatus.processStatus
                    .isLastStepCompleteButProcessNotComplete
                }
                onClick={handleSubmit(successHandle)}
              >
                ยืนยัน
              </Button>
              <Button
                type="button"
                onClick={() => handleClose()}
                color="inherit"
                variant="contained"
              >
                ยกเลิก
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default CancleProcessModal;

CancleProcessModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  successHandle: PropTypes.func,
  selectedProcess: PropTypes.object,
  manufacturingOrder: PropTypes.object,
};
