import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

const NumberInput = ({
  control,
  measurementType,
  measurementControllerName = '',
  defaultValue,
}) => (
  <div>
    <div>
      <Controller
        control={control}
        name={`${measurementControllerName}.value`}
        defaultValue={defaultValue}
        render={({ field }) => (
          <TextField
            {...field}
            label={measurementType?.label}
            required={measurementType?.required}
            fullWidth
            pattern="[0-9]"
          />
        )}
      />
    </div>
  </div>
);

NumberInput.propTypes = {
  control: PropTypes.object,
  measurementType: PropTypes.object,
  measurementControllerName: PropTypes.string,
  defaultValue: PropTypes.number,
};

export default NumberInput;
