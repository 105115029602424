import React, { useEffect, useState } from 'react';
import {
  ArrowDown,
  ArrowUp,
  Grid as GridIcon,
  List as ListIcon,
} from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  BackButton,
  CardListProductOrMaterial,
  Error,
  ExportExcelContainer,
  LoadingLinear,
  ProductAmountTable,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import {
  Autocomplete,
  Button,
  Card,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { app as appConfig } from '@/configs';
import * as actions from '@/redux/actions';
import { DISPLAY_TYPE } from '@/utils/constants';
import { ProductUtil } from '@/utils/excelColumn';

function DetailProductStock({ title, subtitle }) {
  const dispatch = useDispatch();
  const warehouse = useSelector((state) => state.warehouse);
  const product = useSelector((state) => state.product);
  const productType = useSelector((state) => state.productType);
  const { id } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [selectedProductType, setSelectedProductType] = useState('');
  const [orderByField, setOrderByField] = useState('type_code');
  const [orderBy, setOrderBy] = useState('asc');
  const [selectedView, setSelectedView] = useState(
    DISPLAY_TYPE.CARD.status_code,
  );
  const history = useHistory();

  useEffect(() => {
    dispatch(actions.productReset());
    const getWarehouse = async () => {
      try {
        await dispatch(actions.warehouseGet(id));
      } catch (error) {
        console.log('Cannot Get Warehouse or non specify warehouse');
      }
    };
    getWarehouse();
    dispatch(
      actions.productAll({
        name,
        size,
        page,
        warehouse: id || '',
        selectProductTypeType: selectedProductType?._id || '',
        fetchStockLot: true,
        orderByField,
        orderBy,
      }),
    );
    return () => {};
  }, [name, page, size, selectedProductType, orderBy, orderByField]);

  useEffect(() => {
    dispatch(actions.productTypeAll({ size: appConfig.maxFetchSize }));
    dispatch(actions.productTransactionTypeAll({}));
    return () => {};
  }, []);

  useEffect(() => {
    setTotal(product?.total);
    return () => {};
  }, [product]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleSetSelectView = (tempSelectedView) => {
    if (tempSelectedView !== null) {
      if (tempSelectedView === DISPLAY_TYPE.CARD.status_code) {
        setSize(20);
        setPage(1);
      }
      setSelectedView(tempSelectedView);
    }
  };

  const renderTitle = () => (
    <ViewTitle title={title + (warehouse?.name || '')} subtitle={subtitle} />
  );

  const handlePushToDetailProductStock = async (productId) => {
    history.push(`/wms/product-stock/${productId}/${id || ''}`);
  };

  const productQuery = ProductUtil.query({ id, selectedProductType });

  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4 gap-2">
      <Button
        variant="contained"
        startIcon={<ArrowDown size={16} />}
        onClick={() => history.push(`/wms/product-stock/create/${id || ''}`)}
      >
        เพิ่มสินค้า
      </Button>
      <Button
        variant="contained"
        startIcon={<ArrowUp size={16} />}
        color="error"
        onClick={() => history.push(`/wms/product-stock/withdraw/${id || ''}`)}
      >
        เบิกสินค้า
      </Button>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="lg:w-3/12 px-2">
          <Autocomplete
            disablePortal
            options={productType?.rows}
            size="small"
            placeholder="เลือกระบบ"
            onChange={(e, newValue) => {
              setSelectedProductType(newValue);
            }}
            className="z-40"
            // prettier-ignore
            getOptionLabel={(option) => `${option?.name || ''}`
        }
            renderInput={(params) => (
              <TextField label="ประเภทสินค้า" {...params} />
            )}
          />
        </div>
        <div className="lg:w-2/12 flex justify-end ">
          <ExportExcelContainer
            columnList={ProductUtil.columns}
            currentData={product?.rows}
            dataAPIEndPoint="product"
            dataQuery={productQuery}
            sheetName="ProductStock"
          />
        </div>
        <div className="lg:w-1/12 justify-end flex">
          <ToggleButtonGroup
            value={selectedView}
            exclusive
            size="small"
            onChange={(event, value) => {
              handleSetSelectView(value);
            }}
          >
            <ToggleButton value={DISPLAY_TYPE.TABLE.status_code}>
              <ListIcon size="16" />
            </ToggleButton>
            <ToggleButton value={DISPLAY_TYPE.CARD.status_code}>
              <GridIcon size="16" />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <ProductAmountTable
        orderBy={orderBy}
        orderByField={orderByField}
        page={page}
        placeId={id}
        product={product}
        setOrderBy={setOrderBy}
        setOrderByField={setOrderByField}
        setPage={setPage}
        setSize={setSize}
        size={size}
        total={total}
      />
    </div>
  );

  const renderCard = () => (
    <div className="my-2">
      <CardListProductOrMaterial
        payload={product}
        page={page}
        size={size}
        total={total}
        setSize={setSize}
        handlePushToStock={handlePushToDetailProductStock}
      />
    </div>
  );

  const renderProduct = () => (
    <div>
      {selectedView === DISPLAY_TYPE.TABLE.status_code
        ? renderTable()
        : renderCard()}
    </div>
  );

  if (!product?.isLoading && !product?.isCompleted) {
    return <Error message={product?.message} />;
  }

  return (
    <div>
      {renderTitle()}
      <div className="flex justify-between">
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <div>{renderAddButtom()}</div>
      </div>
      {renderSearch()}
      {product?.isLoading ? (
        <div>
          <LoadingLinear label="กำลังดาวน์โหลดข้อมูล" />
        </div>
      ) : (
        renderProduct()
      )}
    </div>
  );
}

DetailProductStock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailProductStock.defaultProps = {
  title: '',
  subtitle: '',
};

export default DetailProductStock;
