import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import DashboardElementRenderingComponent from './DashboardElementRenderingComponent';

const MMSDashboardRenderingComponent = ({
  mmsDashboard,
  isMoLevel,
  isExample,
  manufacturingOrder,
}) => {
  if (isMoLevel) {
    return (
      <div>
        <div className="w-full md:grid md:grid-cols-12">
          {_.map(
            _.orderBy(mmsDashboard?.elements, ['index']),
            (eachElement, index) => (
              <div
                className={`py-2 pr-2 md:col-span-${eachElement?.width} md:row-span-${eachElement?.height}`}
                key={index}
              >
                <DashboardElementRenderingComponent
                  showAsExample={isExample}
                  type={eachElement?.dashboard_element?.dashboard_type}
                  dashboardElement={eachElement?.dashboard_element}
                  manufacturingOrder={manufacturingOrder}
                />
              </div>
            ),
          )}
        </div>
      </div>
    );
  }

  return (
    <div>
      ยังไม่พร้อมให้บริการ
      <div className="md:col-span-1"></div>
      <div className="md:col-span-2"></div>
      <div className="md:col-span-3"></div>
      <div className="md:col-span-4"></div>
      <div className="md:col-span-5"></div>
      <div className="md:col-span-6"></div>
      <div className="md:col-span-7"></div>
      <div className="md:col-span-8"></div>
      <div className="md:col-span-9"></div>
      <div className="md:col-span-10"></div>
      <div className="md:col-span-11"></div>
      <div className="md:col-span-12"></div>
      <div className="md:row-span-1"></div>
      <div className="md:row-span-2"></div>
      <div className="md:row-span-3"></div>
      <div className="md:row-span-4"></div>
      <div className="md:row-span-5"></div>
      <div className="md:row-span-6"></div>
      <div className="md:row-span-7"></div>
      <div className="md:row-span-8"></div>
      <div className="md:row-span-9"></div>
      <div className="md:row-span-10"></div>
      <div className="md:row-span-11"></div>
      <div className="md:row-span-12"></div>
    </div>
  );
};

MMSDashboardRenderingComponent.propTypes = {
  mmsDashboard: PropTypes.shape({
    elements: PropTypes.arrayOf(PropTypes.object),
  }),
  isMoLevel: PropTypes.bool,
  isExample: PropTypes.bool,
  manufacturingOrder: PropTypes.object,
};

export default MMSDashboardRenderingComponent;
