const PROCESS_TEMPLATE_TYPE = {
  SEQUENCIAL: {
    status_code: "SEQUENCIAL",
    description: "แบบเป็นลำดับขั้นตอน",
  },
  MATERIAL_SPLITTING: {
    status_code: "MATERIAL_SPLITTING",
    description: "แบบการกระจายของวัตถุดิบ",
  },
  MATERIAL_MOVING: {
    status_code: "MATERIAL_MOVING",
    description: "การเดินทางของวัตถุดิบ",
  },
};

module.exports = PROCESS_TEMPLATE_TYPE;
