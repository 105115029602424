import React, { useContext } from 'react';
import { Card, CardContent } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { currencyFormatter } from '@/utils/functions';

import { WordLocalizeContext } from '@/contexts/WordLocalizeContext';
import NameBox from '../Box/Common/NameBox';
import MultiUnitProdOrMatBox from '../Box/Rendering/MultiUnitProdOrMatBox';

const CardInfoProductStockLot = ({ productStockLot, information }) => {
  const wmsSetting = information?.setting?.wms;
  return (
    <div>
      <Card>
        <CardContent>
          <div className="text-lg font-semibold my-1">
            ข้อมูลล็อตสินค้าปัจจุบัน
          </div>
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/2">
              <div className="flex w-full my-2">
                <div className="w-1/3 font-semibold">หมายเลขอ้างอิง</div>
                <div className="w-2/3">{productStockLot?.running_number}</div>
              </div>
              {wmsSetting?.display?.lot_number && (
                <div className="flex w-full my-2">
                  <div className="w-1/3 font-semibold">หมายเลขล็อต</div>
                  <div className="w-2/3">{productStockLot?.lot_number}</div>
                </div>
              )}
              <div className="flex w-full my-2">
                <div className="w-1/3 font-semibold">วันที่รับเข้าคลัง</div>
                <div className="w-2/3">
                  {dayjs(productStockLot?.inventory_date).format(
                    wmsSetting?.showTime ? 'D MMM BBBB HH:mm' : 'D MMM BBBB',
                  )}
                </div>
              </div>
              {wmsSetting?.display?.production_date && (
                <div className="flex w-full my-2">
                  <div className="w-1/3 font-semibold">วัดผลิต</div>
                  <div className="w-2/3">
                    {dayjs(productStockLot?.production_date).format(
                      'D MMM BBBB',
                    )}
                  </div>
                </div>
              )}
              {wmsSetting?.display?.expiration_date && (
                <div className="flex w-full my-2">
                  <div className="w-1/3 font-semibold">วันหมดอายุ</div>
                  <div className="w-2/3">
                    {dayjs(productStockLot?.expiration_date).format(
                      'D MMM BBBB',
                    )}
                  </div>
                </div>
              )}{' '}
              <br className="my-2" />
              <div className="flex w-full my-2">
                <div className="w-1/3 font-semibold">ผู้สร้างล็อต</div>
                <div className="w-2/3">
                  {productStockLot?.employee ? (
                    <NameBox user={productStockLot?.employee} />
                  ) : (
                    productStockLot?.consignee_name
                  )}
                </div>
              </div>{' '}
              <div className="flex w-full my-2">
                <div className="w-1/3 font-semibold">วันที่บันทึกข้อมูล</div>
                <div className="w-2/3">
                  {dayjs(productStockLot?.createdAt).format('D MMM BBBB HH.mm')}
                </div>
              </div>{' '}
              <div className="flex w-full my-2">
                <div className="w-1/3 font-semibold">อัพเดทล่าสุด</div>
                <div className="w-2/3">
                  {dayjs(productStockLot?.updatedAt).format('D MMM BBBB HH.mm')}
                </div>
              </div>{' '}
            </div>{' '}
            <div className="w-full lg:w-1/2">
              <div className="flex w-full my-2">
                <div className="w-1/3 font-semibold">สินค้า</div>
                <div className="w-2/3">
                  {productStockLot?.product?.type_code || ''}{' '}
                  {productStockLot?.product?.name}
                </div>
              </div>{' '}
              <div className="flex w-full my-2">
                <div className="w-1/3 font-semibold">คลังสินค้า</div>
                <div className="w-2/3">
                  {productStockLot?.warehouse?.name || '-'}
                </div>
              </div>{' '}
              <div className="flex w-full my-2">
                <div className="w-1/3 font-semibold">ลูกค้า</div>
                <div className="w-2/3">
                  {productStockLot?.customer?.name || '-'}
                </div>
              </div>
              <hr className="my-4 w-3/4" />
              <div className="flex w-full my-2">
                <div className="w-1/3 font-semibold">ปริมาณเริ่มต้น</div>
                <div className="w-2/3">
                  <MultiUnitProdOrMatBox
                    foundMaterial={productStockLot?.product}
                    noWrap
                    quantity={productStockLot?.quantity}
                  />{' '}
                </div>
              </div>{' '}
              <div className="flex w-full my-2">
                <div className="w-1/3 font-semibold">คงเหลือ</div>
                <div className="w-2/3">
                  <MultiUnitProdOrMatBox
                    foundMaterial={productStockLot?.product}
                    noWrap
                    quantity={productStockLot?.amount}
                  />{' '}
                </div>
              </div>
              {wmsSetting?.display?.cost_price && (
                <div className="flex w-full my-2">
                  <div className="w-1/3 font-semibold">ต้นทุนต่อหน่วย</div>
                  <div className="w-2/3">
                    {currencyFormatter.format(
                      parseFloat(productStockLot?.cost_price) /
                        productStockLot?.quantity,
                    )}{' '}
                    บาท
                  </div>
                </div>
              )}
              {wmsSetting?.display?.cost_price && (
                <div className="flex w-full my-2">
                  <div className="w-1/3 font-semibold">ต้นทุนรวม</div>
                  <div className="w-2/3">
                    {currencyFormatter.format(productStockLot?.cost_price)} บาท
                  </div>
                </div>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default CardInfoProductStockLot;

CardInfoProductStockLot.propTypes = {
  productStockLot: PropTypes.object,
  information: PropTypes.object,
};
