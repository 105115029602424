import {
  METAL_DIVIDE_ALL,
  METAL_DIVIDE_DELETE,
  METAL_DIVIDE_ERROR,
  METAL_DIVIDE_GET,
  METAL_DIVIDE_LOADING,
  METAL_DIVIDE_POST,
  METAL_DIVIDE_PUT,
} from '../types';

const initialState = {
  bomChecking: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case METAL_DIVIDE_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case METAL_DIVIDE_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case METAL_DIVIDE_POST:
      return { isLoading: false, isCompleted: true };
    case METAL_DIVIDE_PUT:
      return { isLoading: false, isCompleted: true };
    case METAL_DIVIDE_DELETE:
      return { isLoading: false, isCompleted: true };
    case METAL_DIVIDE_LOADING:
      return { isLoading: true, isCompleted: false };
    case METAL_DIVIDE_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
