import React from 'react';
import { Chip } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { currencyFormatter } from '@/utils/functions';

const BoxQuotationCostByProductOrMaterial = ({
  quotationByProducts,
  typeKey,
  typeKeyTH,
}) => {
  const orderByPrice = _.orderBy(quotationByProducts, ['price'], ['desc']);

  const mostQuantity = orderByPrice[0];
  const anotherQuantity = _.tail(orderByPrice);

  return (
    <div>
      <div className="text-lg font-semibold mb-2">
        มูลค่า{typeKeyTH}ที่เสนอราคา
      </div>
      <div className="w-full">
        <h4 className="text-xl flex items-center">
          <Chip label="#1" size="small" className="mr-1" />{' '}
          {mostQuantity?.[typeKey]?.name}
        </h4>
        <div className="flex justify-end">
          {currencyFormatter.format(mostQuantity?.price)} บาท
        </div>
      </div>
      <hr className="my-2" />
      {_.map(anotherQuantity, (eachProduct) => (
        <div className="flex justify-between">
          <div>{eachProduct?.[typeKey]?.name}</div>
          {currencyFormatter.format(eachProduct?.price)} บาท
        </div>
      ))}
    </div>
  );
};

export default BoxQuotationCostByProductOrMaterial;

BoxQuotationCostByProductOrMaterial.propTypes = {
  quotationByProducts: PropTypes.arrayOf(PropTypes.object),
  typeKey: PropTypes.string,
  typeKeyTH: PropTypes.string,
};
