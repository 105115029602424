/* eslint-disable no-nested-ternary */
import React from 'react';
import _ from 'lodash';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Namebox from '../Common/NameBox';

const StepEmployee = ({ steps }) => {
  const renderEachStep = (stepInfo) => (
    <div className="self-center rounded-md   px-4 py-2 min-w-48 border border-gray-300 shadow-sm">
      <div className="flex justify-between">
        <h4 className="font-semibold font-display text-lg">
          {stepInfo?.index + 1} {stepInfo?.name}{' '}
        </h4>
        <Link to={`/mms/works/view/${stepInfo?._id}`}>
          <Button size="small" variant="outlined">
            ดูใบงาน
          </Button>
        </Link>
      </div>
      <div className="my-2">
        <h6 className="font-semibold font-display text-sm my-1 ">
          ผู้รับผิดชอบ{' '}
        </h6>
        <div className=" ">
          {stepInfo?.responsible?.employee ? (
            <div className="px-1 py-1 ">
              <Namebox user={stepInfo?.responsible?.employee} bold={false} />
            </div>
          ) : stepInfo?.completed ? (
            <p>สำเร็จโดยผู้ควบคุมการผลิต</p>
          ) : (
            <p>ยังไม่มีผู้รับงาน</p>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {_.map(_.orderBy(steps, ['index'], ['asc']), (_step, index) => (
        <div key={index} className="my-2">
          {renderEachStep(_step)}
        </div>
      ))}
    </div>
  );
};

StepEmployee.propTypes = { steps: PropTypes.arrayOf(PropTypes.object) };

export default StepEmployee;
