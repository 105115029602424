import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import MultiUnitProdOrMatBox from '../Rendering/MultiUnitProdOrMatBox';

const BoxPreferMaterial = ({ processMaterialList }) => (
  <div>
    <div className="my-2 ml-2 p-1">
      <div className="my-2">วัตถุดิบที่ต้องเบิก</div>
      {_.map(processMaterialList, (eachMaterial, index) => (
        <div key={index} className="p-2 py-3 shadow-md rounded-md flex">
          <div className="w-2/6">
            <div className="text-xs">{eachMaterial?.material?.type_code}</div>
            <div>{eachMaterial?.material?.name}</div>
          </div>
          <div className="w-4/6 self-center">
            <MultiUnitProdOrMatBox
              foundMaterial={eachMaterial?.material}
              noWrap
              quantity={eachMaterial?.begin}
            />
          </div>
        </div>
      ))}
    </div>
  </div>
);

BoxPreferMaterial.propTypes = {
  processMaterialList: PropTypes.arrayOf(PropTypes.object),
};

export default BoxPreferMaterial;
