import React from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';

const RadioAndDescribeInput = ({
  control,
  measurementType,
  measurementControllerName = '',
  watch,
}) => (
  <div>
    <div>
      <Controller
        control={control}
        name={`${measurementControllerName}.boolean_value`}
        render={({ field }) => (
          <FormControl>
            <RadioGroup row {...field}>
              <FormControlLabel
                value={true}
                control={<Radio />}
                label={measurementType?.label_left}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={measurementType?.label_right}
              />
            </RadioGroup>
          </FormControl>
        )}
      />

      {watch(`${measurementControllerName}.boolean_value`) !== undefined &&
        watch(`${measurementControllerName}.boolean_value`) === 'false' && (
          <div className="my-2">
            {' '}
            <Controller
              control={control}
              name={`${measurementControllerName}.additional_value`}
              render={({ field }) => (
                <TextField
                  {...field}
                  multiline
                  rows={3}
                  label={measurementType?.label_on_additional_text}
                  fullWidth
                />
              )}
            />{' '}
          </div>
        )}
    </div>
  </div>
);

RadioAndDescribeInput.propTypes = {
  control: PropTypes.object,
  measurementType: PropTypes.object,
  measurementControllerName: PropTypes.string,
  watch: PropTypes.func,
};

export default RadioAndDescribeInput;
