import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { config } from '@/configs';

import graphqlClient from '../../configs/graphqlClient';

const ManufacturingOrderSelecter = ({ control, name }) => {
  const [manufacturingOrderData, setManufacturingOrderData] = useState([]);
  const [isError, setIsError] = useState(false);
  const query = gql`
    query FindManufacturingOrders($input: ManufacturingOrderInput) {
      findManufacturingOrderWithoutLookup(input: $input) {
        rows {
          _id
          prefix
          running_number
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        input: {
          page: 1,
          size: config.maxFetchSize,
        },
      });
      const tempManufacturingOrderData =
        queryResult?.findManufacturingOrderWithoutLookup;
      setManufacturingOrderData(tempManufacturingOrderData?.rows);
      console.log('manufacturingOrderData', tempManufacturingOrderData?.rows);
      setIsError(false);
    } catch (error) {
      setIsError(true);
      console.error(
        'Error on fetching manufacturing order without lookup',
        error.message,
      );
    }
  };

  useEffect(() => {
    queryDataFromServer();

    return () => {};
  }, []);

  if (isError) {
    return <div>ไม่สามารถดึงข้อมูลคำสั่งผลิตได้</div>;
  }
  return (
    <div>
      {!_.isEmpty(manufacturingOrderData) && (
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <Autocomplete
              {...field}
              disablePortal
              options={manufacturingOrderData}
              size="small"
              placeholder="เลือกคำสั่งผลิต"
              onChange={(e, newValue) => {
                field.onChange(newValue);
              }}
              className="z-40"
              // prettier-ignore
              getOptionLabel={(option) => `${option?.prefix || ''}${option?.running_number || ''}`
        }
              renderInput={(params) => (
                <TextField label="เลือกคำสั่งผลิต" {...params} />
              )}
            />
          )}
        />
      )}
    </div>
  );
};

export default ManufacturingOrderSelecter;

ManufacturingOrderSelecter.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
};
