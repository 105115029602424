import React from 'react';
import { ChevronDown, ChevronUp, Trash } from 'react-feather';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { usedPlugin } from '@/configs/plugins';
import { pluginList } from '@/plugins';

import MaterialComingMethodForm from './MaterialComingMethodForm';
import ProcessTemplateMaterialForm from './ProcessTemplateMaterialForm';

function ProcessStepForm({
  index,
  fieldInfo,
  control,
  errors,
  product,
  watch,
  move,
  remove,
  fields,
  setValue,
}) {
  const material = useSelector((state) => state.material);
  const departments = useSelector((state) => state.department);
  const urlParams = useParams();

  const renderStepPlugin = () => (
    <div className="flex flex-wrap justify-center">
      <div className="w-full px-2 py-1">
        <Controller
          control={control}
          name={`steps[${index}].plugin`}
          render={({ field }) => (
            <FormControl fullWidth>
              <FormLabel className="my-2 font-semibold">ปลั๊กอิน</FormLabel>
              <RadioGroup {...field}>
                {_.map(pluginList, (eachPlugin, pluginIndex) => (
                  <FormControlLabel
                    value={eachPlugin?.id}
                    control={<Radio />}
                    key={`plug-${pluginIndex}`}
                    label={
                      <div>
                        <div className="font-semibold">{eachPlugin.id} </div>
                        <div>{eachPlugin?.name}</div>
                      </div>
                    }
                  />
                ))}{' '}
                {_.map(usedPlugin, (eachPlugin, pluginIndex) => (
                  <FormControlLabel
                    value={eachPlugin?.id}
                    control={<Radio />}
                    key={`plug-${pluginIndex}`}
                    label={
                      <div>
                        <div className="font-semibold">{eachPlugin.id} </div>
                        <div>{eachPlugin?.name}</div>
                      </div>
                    }
                  />
                ))}
              </RadioGroup>
              {_.isEmpty(pluginList) && <div>ไม่มีปลั๊กอินในระบบ</div>}
            </FormControl>
          )}
        />
      </div>
    </div>
  );

  const renderStepProcessing = () => (
    <div className="flex flex-row flex-wrap">
      <div className="w-full p-1 py-2 font-semibold">การแยกขั้นตอนคู่ขนาน</div>
      <div className="w-full p-1">
        <Controller
          control={control}
          name={`steps[${index}].splited_step`}
          defaultValue={fieldInfo?.splited_step}
          render={({ field }) => (
            <FormControlLabel
              label="แยกขั้นตอน"
              control={<Checkbox defaultChecked={field.value} {...field} />}
            />
          )}
        />
      </div>
      {watch(`steps[${index}].splited_step`) && (
        <div className="w-full p-1">
          <div>ขั้นตอนที่ส่งมาที่ขั้นตอนนี้</div>
          <Controller
            control={control}
            name={`steps[${index}].from_index`}
            defaultValue={fieldInfo?.from_index}
            render={({ field }) => (
              <Select
                size="small"
                label="ขั้นตอนที่ส่งมา"
                {...field}
                fullWidth
                onChange={(e) => {
                  field.onChange(e);
                  // setValue(`steps[${index}].index`, e.target.value + 1);
                }}
              >
                {_.map(watch('steps'), (eachStep, stepIndex) => (
                  <MenuItem value={eachStep.index} key={stepIndex}>
                    {eachStep?.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </div>
      )}

      <div className="w-full px-1 py-2 font-semibold">วัตถุดิบ</div>

      <div className="w-full p-1 lg:w-1/2 my-2">
        <Controller
          control={control}
          name={`steps[${index}].use_set_of_material`}
          defaultValue={fieldInfo?.use_set_of_material}
          render={({ field }) => (
            <div className="flex justify-center items-center gap-2 rounded-md border p-2">
              <div>ใช้วัตถุดิบหลักตัวเดียว</div>
              <FormControlLabel
                label="ใช้วัตถุดิบหลายตัว"
                control={
                  <Switch
                    defaultChecked={field.value}
                    {...field}
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                      if (e.target.checked) {
                        setValue(`steps[${index}].main_material`, null);
                      } else {
                        setValue(`steps[${index}].materials`, []);
                      }
                    }}
                  />
                }
              />
            </div>
          )}
        />
      </div>
      {watch(`steps[${index}].use_set_of_material`) &&
        !_.isEmpty(material?.rows) && (
          <div className="w-full px-1 py-1">
            <ProcessTemplateMaterialForm
              control={control}
              material={material}
              stepIndex={index}
              product={product}
              watch={watch}
              // alternativeName={`steps[${index}].materials`}
            />
          </div>
        )}
      {!watch(`steps[${index}].use_set_of_material`) && (
        <div className="w-full p-1">
          <Controller
            name={`steps[${index}].main_material`}
            defaultValue={fieldInfo?.main_material}
            control={control}
            render={({ field }) => (
              <div>
                {!_.isEmpty(material?.rows) && (
                  <Autocomplete
                    {...field}
                    size={'small'}
                    onChange={(e, newValue) => {
                      field.onChange(newValue);
                    }}
                    options={material?.rows}
                    getOptionLabel={(option) =>
                      `${option?.type_code || ''} ${option?.name || ''} `
                    }
                    required
                    renderInput={(params) => (
                      <TextField {...params} label="เลือกวัตถุดิบ" />
                    )}
                  />
                )}
              </div>
            )}
          />
        </div>
      )}
      <div className="w-full p-1">
        <MaterialComingMethodForm
          control={control}
          fieldInfo={fieldInfo}
          index={index}
          watch={watch}
        />
      </div>

      <div className="w-full px-1 py-1">
        <div className="font-semibold">การแยกและการรวมไลน์ผลิต</div>
        <div>
          <Controller
            control={control}
            name={`steps[${index}].is_process_splitting_point`}
            defaultValue={fieldInfo?.is_process_splitting_point}
            render={({ field }) => (
              <FormControlLabel
                label="เป็นจุดเริ่มต้นของการแยก"
                control={<Checkbox {...field} defaultChecked={field.value} />}
              />
            )}
          />
        </div>
        <div>
          <Controller
            control={control}
            name={`steps[${index}].is_process_merging_point`}
            defaultValue={fieldInfo?.is_process_merging_point}
            render={({ field }) => (
              <FormControlLabel
                label="เป็นจุดสิ้นสุดของการแยก"
                control={<Checkbox {...field} defaultChecked={field.value} />}
              />
            )}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-wrap w-full border  bg-white px-2 rounded-md shadow-lg my-2 ">
      <div className="w-11/12 p-4">
        <div className="my-4">{watch(`steps[${index}].name`)}</div>

        <div className="flex flex-row flex-wrap">
          <div className="w-full lg:w-2/12 px-1 py-2">
            <Controller
              name={`steps[${index}].index`}
              control={control}
              defaultValue={index + 1}
              rules={{
                min: 0,
                max: 99,
                minLength: 1,
                maxLength: 2,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ลำดับ"
                  fullWidth
                  size={'small'}
                  disabled
                  value={field.value + 1}
                  multiline={false}
                  required
                  helperText={
                    errors.steps?.[index]?.index && 'กรุณาใส่ตัวเลขให้ถูกต้อง'
                  }
                />
              )}
            />
          </div>
          <div className="w-full lg:w-4/12 px-1 py-2">
            <Controller
              name={`steps[${index}].name`}
              control={control}
              defaultValue={fieldInfo?.name}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อ"
                  fullWidth
                  size={'small'}
                  multiline={false}
                  required
                  helperText={errors.steps?.[index]?.detail && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-6/12 px-1 py-2">
            <Controller
              name={`steps[${index}].responsible.department`}
              control={control}
              defaultValue={fieldInfo?.responsible?.department?._id || null}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  options={departments?.rows}
                  size="small"
                  placeholder="แผนก"
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                  }}
                  className="z-40"
                  // prettier-ignore
                  getOptionLabel={(option) => `${option?.name || ''}`
}
                  renderInput={(params) => (
                    <TextField label="แผนก" {...params} />
                  )}
                />
              )}
            />
          </div>
        </div>
        <div className="flex flex-row flex-wrap">
          <div className="w-full  px-1 py-2">
            <Controller
              name={`steps[${index}].detail`}
              control={control}
              defaultValue={product ? product?.steps?.[index]?.detail : ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="รายละเอียด"
                  fullWidth
                  size={'small'}
                  rows={2}
                  multiline={true}
                  helperText={errors.steps?.[index]?.detail && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
        </div>
        <div className="flex flex-wrap justify-center">
          <div className="w-full lg:w-1/2  px-1 py-2">
            <Controller
              name={`steps[${index}].material_associate`}
              control={control}
              defaultValue={
                product ? product?.steps?.[index]?.material_associate : true
              }
              render={({ field }) => (
                <FormControlLabel
                  label="เกี่ยวข้องกับการใช้วัตถุดิบ"
                  control={<Checkbox defaultChecked={field.value} {...field} />}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-1/2  px-1 py-2">
            <Controller
              name={`steps[${index}].use_plugin_instead`}
              control={control}
              defaultValue={
                product ? product?.steps?.[index]?.use_plugin_instead : false
              }
              render={({ field }) => (
                <FormControlLabel
                  label="ใช้ปลั๊กอินแทนการตั้งค่าแบบทั่วไป"
                  control={<Checkbox defaultChecked={field.value} {...field} />}
                />
              )}
            />
          </div>
        </div>
        {watch(`steps[${index}].use_plugin_instead`)
          ? renderStepPlugin(index)
          : renderStepProcessing(index)}
        <div className="font-semibold my-1">
          การใส่ตัวแปรรับค่า และค่าแสดงผล
        </div>
        <div className="flex gap-2">
          <Link
            to={`/mms/process-template/measurement/edit/${urlParams.id}/${index}`}
          >
            <Button variant="contained">ตั้งค่าตัวแปรรับค่า</Button>
          </Link>
          <Link
            to={`/mms/process-template/resulting-function/edit/${urlParams.id}/${index}`}
          >
            <Button variant="contained" color="secondary">
              ตั้งค่าฟังก์ชันการแสดงผล
            </Button>
          </Link>
        </div>
      </div>

      <div className="w-1/12 p-4">
        <div className="flex flex-col gap-2">
          <Button
            className="w-full whitespace-nowrap "
            color="inherit"
            variant="contained"
            disabled={index === 0}
            endIcon={<ChevronUp size={16} />}
            onClick={() => {
              move(index, index - 1);
            }}
          >
            เลือนขึ้น
          </Button>
          <Button
            className="w-full"
            color="error"
            variant="contained"
            endIcon={<Trash size={16} />}
            onClick={() => {
              remove(index);
            }}
          >
            ลบ
          </Button>
          <Button
            className="w-full whitespace-nowrap"
            color="inherit"
            variant="contained"
            disabled={index === _.size(fields) - 1}
            endIcon={<ChevronDown size={16} />}
            onClick={() => {
              move(index, index + 1);
            }}
          >
            เลื่อนลง
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ProcessStepForm;

ProcessStepForm.propTypes = {
  index: PropTypes.number,
  fieldInfo: PropTypes.object,
  control: PropTypes.object,
  errors: PropTypes.object,
  product: PropTypes.object,
  watch: PropTypes.func,
  move: PropTypes.func,
  remove: PropTypes.func,
  isReady: PropTypes.bool,
  setIsReady: PropTypes.func,
  fields: PropTypes.arrayOf(PropTypes.object),
  setValue: PropTypes.func,
  measurementType: PropTypes.object,
  resultingFunctionType: PropTypes.object,
};
