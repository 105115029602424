import React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  Paper,
  TableContainer,
  Button,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

const ProcessTable = ({
  processData,
  page,
  total,
  size,
  handleChangePage,
  handleChangeRowPerPage,
}) => (
  <div>
    <Paper>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <div className="font-bold">ลำดับที่</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">วันที่</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">คำสั่งผลิต</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">สินค้า</div>
              </TableCell>{' '}
              <TableCell>
                <div className="font-bold">สถานะ</div>
              </TableCell>{' '}
              <TableCell>
                <div className="font-bold">ดำเนินการ</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!_.isEmpty(processData) ? (
              _.map(processData, (row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    <div className="text-xs">{index + 1}</div>
                  </TableCell>
                  <TableCell>
                    {dayjs(row?.manufacturing_order?.start_date).format(
                      'D MMM BBBB',
                    )}
                  </TableCell>
                  <TableCell>
                    <div>
                      MO{row?.manufacturing_order?.running_number || ''}
                    </div>
                  </TableCell>
                  <TableCell>
                    {row?.produce_material_instead && (
                      <div>
                        {row?.product_as_material?.type_code || ''}{' '}
                        {row?.product_as_material?.name}
                      </div>
                    )}
                    {row?.produce_base_project_instead && (
                      <div>
                        {row?.base_projec?.type_code || ''}{' '}
                        {row?.base_projec?.name}
                      </div>
                    )}
                    {!row?.produce_base_project_instead &&
                      !row?.produce_material_instead && (
                        <div>
                          {row?.product?.type_code || ''} {row?.product?.name}
                        </div>
                      )}
                  </TableCell>
                  <TableCell>
                    {row?.completed && <div>สำเร็จ</div>}
                    {row?.deleted && <div>ยกเลิก</div>}
                    {!row?.deleted && !row?.completed && (
                      <div>กำลังดำเนินการ</div>
                    )}
                  </TableCell>
                  <TableCell>
                    <Button variant="contained" color="info" size="small">
                      รายละเอียด
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <div className="text-center">ไม่มีข้อมูล</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TablePagination
            page={page - 1}
            count={total || 1}
            rowsPerPage={size}
            onPageChange={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
          />
        </Table>
      </TableContainer>
    </Paper>
  </div>
);

ProcessTable.propTypes = {
  processData: PropTypes.arrayOf(PropTypes.object),
  page: PropTypes.number,
  total: PropTypes.number,
  size: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowPerPage: PropTypes.func,
};

export default ProcessTable;
