import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

const UnitForm = ({ control, errors }) => (
  <div className="flex flex-row flex-wrap">
    <div className="w-full px-1 py-2">
      <Controller
        name={'name'}
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            {...field}
            label="ชื่อหน่วย"
            fullWidth
            required
            size={'small'}
            helperText={errors?.name && 'ชื่อหน่วย'}
          />
        )}
      />
    </div>
    <div className="w-full px-1 py-2">
      <Controller
        name={'short_sign'}
        control={control}
        rules={{ required: false }}
        render={({ field }) => (
          <TextField
            {...field}
            label="สัญลักษณ์ / ตัวย่อ"
            fullWidth
            size={'small'}
            helperText={errors.short_sign && 'กรุณาใส่ข้อมูล'}
          />
        )}
      />
    </div>
  </div>
);

export default UnitForm;

UnitForm.propTypes = {
  control: PropTypes.func,
  errors: PropTypes.func,
};
