import React, { useEffect, useState } from 'react';
import { ArrowRight } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  MATERIAL_TRANSACTION_TYPE,
  MQ_TASK,
  PRODUCT_STOCK_STATUS,
  PRODUCT_TRANSACTION_TYPE,
} from '@iarcpsu/emanufac-constant';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Skeleton,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import {
  BackButton,
  Error,
  Loading,
  LoadingDialogWithTimeout,
  MaterialAmountAddList,
  MMSSelectMaterialForm,
  SelectProductToMoveForm,
  SocketIOLoading,
  TransactionTypeSelect,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { api, config } from '@/configs';
import * as actions from '@/redux/actions';
import { useQuery } from '@/utils/functions';

/**
 * @function TransfromProductToMaterial
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function TransfromProductToMaterial({ title, subtitle }) {
  const dispatch = useDispatch();
  const productStockLot = useSelector((state) => state.productStockLot);
  const materialTransactionType = useSelector(
    (state) => state.materialTransactionType,
  );
  const productTransactionType = useSelector(
    (state) => state.productTransactionType,
  );
  const material = useSelector((state) => state.material);
  const warehouse = useSelector((state) => state.warehouse);
  const me = useSelector((state) => state.me);
  const information = useSelector((state) => state.information);
  const place = useSelector((state) => state.place);
  const history = useHistory();
  const params = useParams();
  const query = useQuery();
  const [destinationPlace, setDestinationPlace] = useState();
  const [openDialogLoading, setOpenDialogLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [referenceNumber, setReferenceNumber] = useState('');
  const [rerender, setRerender] = useState(false);

  /** Initial the form */
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      materialIn: [],
      productOut: [],
    },
  });

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.productStockLotAll({
          page: 1,
          size: config.maxFetchSize,
          ids: query.get('lots'),
          warehouse: query.get('source'),
          stockStatus: PRODUCT_STOCK_STATUS.IN_STOCK.status_code,
        }),
      );

      // Transaction Type
      dispatch(actions.materialTransactionTypeAll({}));
      dispatch(actions.productTransactionTypeAll({}));
      // Get Source Place
      dispatch(actions.warehouseGet(query.get('source')));
      // Get Destination Place เอาเข้าผ่าน Redux ไม่ได้เพราะว่าเราเอา Source เข้าเสียแล้ว
      if (query?.get('destination')) {
        const res = await api.get(
          `${process.env.REACT_APP_API_URL}/place/${query.get('destination')}`,
        );
        setDestinationPlace(res.data);
      } else {
        dispatch(actions.placeAll({ page: 1, size: config.maxFetchSize }));
      }
    } catch (error) {
      dispatch(actions.productStockLotError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  useEffect(() => {
    if (destinationPlace?._id) {
      dispatch(
        actions.materialAll({
          page: 1,
          size: config.maxFetchSize,
          placeRestriction: true,
          place: destinationPlace?._id || '',
        }),
      );
    }
    return () => {};
  }, [destinationPlace]);

  // Divide Transaction Type
  const inFilterTxnType = _.filter(
    materialTransactionType?.rows,
    (each) => each?.direction === MATERIAL_TRANSACTION_TYPE.add.status_code,
  );

  const outFilterTxnType = _.filter(
    productTransactionType?.rows,
    (each) => each?.direction === PRODUCT_TRANSACTION_TYPE.desc.status_code,
  );

  // Setting Input (Out Stock)
  useEffect(() => {
    const inStockProduct = _.filter(
      productStockLot?.rows,
      (each) => each?.status === PRODUCT_STOCK_STATUS.IN_STOCK.status_code,
    );
    const instockWithSelect = _.map(inStockProduct, (each) => ({
      ...each,
      selected: true,
    }));
    setValue('productOut', instockWithSelect);

    return () => {};
  }, [productStockLot]);

  // Generate Reference Number for long run task
  useEffect(() => {
    const refNo = hash({
      date: new Date(),
      user: me?.userData?._id,
    });

    setReferenceNumber(refNo);

    return () => {};
  }, []);

  // Handle While Finishing Main Fuction
  const handleSocketIOFunction = {
    onSuccess: () => {
      setOpenDialogLoading(false);
      Swal.fire({
        icon: 'success',
        title: 'แปลงกลับเป็นวัตถุดิบสำเร็จ',
      }).then(() => {
        if (query.get('inplace')) {
          history.goBack();
        } else {
          history.push(`/wms/product-stock/warehouse/${query.get('source')}`);
        }
      });
    },
    onFail: (args) => {
      setOpenDialogLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: args?.message,
      });
    },
  };

  // Main Logic
  const handleTransformToMaterial = (data) => {
    const materialIn = _.map(data?.processes?.[0]?.materials, (each) => ({
      ...each,
      quantity: each?.begin,
      material: each?.material?._id,
    }));

    const productOut = _.map(
      _.filter(data?.productOut, (eachFilter) => eachFilter?.selected === true),
      (each) => ({
        product: each?.product?._id,
        lotId: each?._id,
        quantity: parseFloat(each?.amount),
        amount: each?.quantity - parseFloat(each?.amount),
      }),
    );

    const payload = {
      ...data,
      referenceNumber,
      employee: me?.userData?._id,
      productOut,
    };
    payload.materialIn = materialIn;
    payload.destination = destinationPlace?._id;
    console.log('Payload', payload);

    try {
      dispatch(actions.productTransferBackToMaterial(payload));
      setOpenDialogLoading(true);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: error?.message,
      });
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (
    productStockLot.isLoading ||
    productTransactionType?.isLoading ||
    materialTransactionType?.isLoading
  ) {
    return <Loading />;
  }

  if (
    !productStockLot.isLoading &&
    productStockLot.isCompleted &&
    productTransactionType?.isCompleted &&
    materialTransactionType?.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <BackButton />
        </div>
        <LoadingDialogWithTimeout
          isLoading={openDialogLoading}
          label={`กำลังดำเนินการเปลี่ยนวัตถุดิบ ${statusMessage || ''}`}
        />

        <SocketIOLoading
          taskCode={MQ_TASK.TRANSFORM_PRODUCT_TO_MATERIAL.status_code}
          handleSuccess={handleSocketIOFunction.onSuccess}
          handleFail={handleSocketIOFunction.onFail}
          referenceNumber={referenceNumber}
          setStatusMessage={setStatusMessage}
        />

        <div>
          <div>
            <Card>
              <CardContent>
                <div className="flex flex-wrap justify-center items-center">
                  <div className="w-1/3 lg:w-1/6 p-2">
                    <div>คลังสินค้าต้นทาง</div>
                    <div className="text-lg font-semibold">
                      {warehouse?.name}
                    </div>
                  </div>
                  <div className="w-1/3 lg:w-1/6">
                    <ArrowRight />
                  </div>
                  <div className="w-1/3 lg:w-1/6">
                    {' '}
                    <div>คลังวัตถุดิบปลายทาง</div>
                    <div className="text-lg font-semibold">
                      {query.get('inplace') === 'true'
                        ? warehouse?.name
                        : destinationPlace?.name}
                      {!query.get('destination') && (
                        <div>
                          <Autocomplete
                            disablePortal
                            autoHighlight
                            options={place?.rows}
                            size="small"
                            placeholder="เลือกคลังเป้าหมาย"
                            onChange={(e, newValue) => {
                              setDestinationPlace(newValue);
                            }}
                            className="z-40"
                            // prettier-ignore
                            getOptionLabel={(option) => `${option?.name || ''}`
                      }
                            renderInput={(inputParam) => (
                              <TextField
                                label="เลือกคลังเป้าหมาย"
                                {...inputParam}
                              />
                            )}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
          <form onSubmit={handleSubmit(handleTransformToMaterial)}>
            <div className="w-full">
              <div className="w-full font-semibold text-lg my-4 ">
                วัตถุดิบต้นทาง
              </div>
              <TransactionTypeSelect
                control={control}
                controllerName="out_txn"
                errors={errors}
                transactionList={outFilterTxnType}
              />

              <div className="my-2">
                <SelectProductToMoveForm
                  control={control}
                  controlKey={'productOut'}
                  information={information}
                  setValue={setValue}
                  watch={watch}
                  rerender={rerender}
                  setRerender={setRerender}
                />
              </div>

              <div className="w-full font-semibold text-lg my-4">
                วัตถุดิบปลายทาง
              </div>
              <TransactionTypeSelect
                control={control}
                controllerName="in_txn"
                errors={errors}
                transactionList={inFilterTxnType}
              />
            </div>
            {destinationPlace?._id && (
              <div className="w-full my-2">
                {query.get('fixedoutput') === 'true' ? (
                  <div>
                    {!material?.isLoading ? (
                      <MaterialAmountAddList
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        enableAddPreferProduct={
                          destinationPlace?.place_action
                            ?.enable_add_prefer_product
                        }
                        enableCustomer={
                          destinationPlace?.place_action?.enable_customer
                        }
                        place={destinationPlace}
                        rerender={rerender}
                        setRerender={setRerender}
                        information={information}
                        materialRows={material?.rows}
                      />
                    ) : (
                      <div>
                        <Skeleton />
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <Card>
                      <CardContent>
                        <MMSSelectMaterialForm
                          control={control}
                          errors={errors}
                          material={material?.rows}
                          productIndex={0}
                          setValue={setValue}
                          watch={watch}
                        />{' '}
                      </CardContent>
                    </Card>
                  </div>
                )}{' '}
              </div>
            )}
          </form>{' '}
          <div className="flex justify-end gap-2 my-4">
            <Button
              type="button"
              onClick={handleSubmit(handleTransformToMaterial)}
              color="primary"
              variant="contained"
            >
              ยืนยัน
            </Button>
          </div>
        </div>
      </div>
    );
  }
  return <Error />;
}

TransfromProductToMaterial.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

TransfromProductToMaterial.defaultProps = {
  title: '',
  subtitle: '',
};
