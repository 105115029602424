export const PRODUCTION_TYPE = {
  PRODUCT: {
    type_code: 'PRODUCT',
    description: 'สินค้า',
  },
  MATERIAL: {
    type_code: 'MATERIAL',
    description: 'วัตถุดิบ',
  },
  BASED_PROJECT: {
    type_code: 'BASED_PROJECT',
    description: 'โปรเจกต์พื้นฐาน',
  },
};

export default PRODUCTION_TYPE;
