import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  BackButton,
  CostEstimationProfileForm,
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Button } from '@mui/material';
import { gql } from 'graphql-request';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import { config } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';

/**
 * @function CreateCostEstimationProfile
 * @description Display a collections or a list of CreateCostEstimationProfile from database
 */

export default function EditCostEstimationProfile({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const place = useSelector((state) => state.place);
  const warehouse = useSelector((state) => state.warehouse);
  const costEstimationProfile = useSelector(
    (state) => state.costEstimationProfile,
  );
  const { handleSubmit, watch, control, setValue } = useForm();

  const query = gql`
    query FindCreateCostEstimationProfile(
      $placeInput: PlaceInput
      $warehouseInput: WarehouseInput
    ) {
      findPlaces(input: $placeInput) {
        rows {
          _id
          name
        }
      }
      findWarehouses(input: $warehouseInput) {
        rows {
          _id
          name
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        placeInput: { page: 1, size: config.maxFetchSize },
        warehouseInput: { page: 1, size: config.maxFetchSize },
      });
      const placeData = queryResult?.findPlaces;
      const warehouseData = queryResult?.findWarehouses;
      dispatch(actions.placeStateSet(placeData));
      dispatch(actions.warehouseStateSet(warehouseData));
      dispatch(actions.costEstimationProfileGet(params.id));
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const handleEditProfile = async (data) => {
    try {
      dispatch(actions.costEstimationProfilePut(params.id, data));
      Swal.fire({
        icon: 'success',
        title: 'แก้ไขโปรไฟล์เสร็จสิ้น',
      }).then(() => {
        history.goBack();
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: error?.message,
      });
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  useEffect(() => {
    if (costEstimationProfile && !costEstimationProfile.rows) {
      setValue('warehouses', costEstimationProfile?.warehouses);
      setValue('manufacturing_mode', costEstimationProfile?.manufacturing_mode);
    }

    return () => {};
  }, [costEstimationProfile]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (costEstimationProfile?.isLoading || costEstimationProfile?.rows) {
    return <Loading />;
  }

  if (!costEstimationProfile?.rows && costEstimationProfile?.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <form onSubmit={handleSubmit(handleEditProfile)}>
            <CostEstimationProfileForm
              control={control}
              place={place}
              warehouse={warehouse}
              watch={watch}
              defaultValue={costEstimationProfile}
            />
            <div className="flex justify-end my-1">
              <Button variant="contained" type="submit">
                บันทึก
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditCostEstimationProfile.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditCostEstimationProfile.defaultProps = {
  title: '',
  subtitle: '',
};
