import React from 'react';
import { useFieldArray } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';

import MeasurementAddingRenderingCompanent from '../../Measurement/MeasurementAddingRenderingComponent';

const MeasurementFilledForm = ({ control, watch }) => {
  const { fields: measurementFields } = useFieldArray({
    control,
    name: 'measurements',
  });

  if (_.isEmpty(measurementFields)) {
    return <div></div>;
  }

  return (
    <div>
      {' '}
      <div className="font-semibold w-full text-xl">การวัดค่า</div>
      <div className="flex flex-wrap overflow-x-auto ">
        {_.map(measurementFields, (_field, index) => (
          <div key={_field.id} className="my-2 w-full lg:w-1/3 lg:px-2 pt-2">
            <div className="font-semibold">
              {' '}
              {_field?.measurement_type?.name}{' '}
            </div>
            <div className="my-2 text-gray-600 text-sm">
              {_field?.measurement_type?.description || ''}
            </div>
            <div className="my-1">
              <MeasurementAddingRenderingCompanent
                control={control}
                measurementControllerName={`measurements[${index}]`}
                measurementType={_field?.measurement_type}
                watch={watch}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

MeasurementFilledForm.propTypes = {
  control: PropTypes.func,
  watch: PropTypes.func,
};

export default MeasurementFilledForm;
