import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { io } from 'socket.io-client';

const SocketIOLoading = ({
  referenceNumber,
  handleSuccess,
  handleFail,
  taskCode,
  setStatusMessage = () => {},
}) => {
  const socket = useRef();

  useEffect(() => {
    if (referenceNumber) {
      socket.current = io(process.env.REACT_APP_SOCKET_IO_URL);

      socket?.current?.on('connect', () => {
        console.log('Socket IO Connecting OK');
      });

      socket?.current?.on('disconnect', () => {
        console.log('Socket IO Disconnect');
      });

      console.log('Listening', referenceNumber);
      socket?.current?.on(referenceNumber, (args) => {
        console.log('Listening Arguement', args);
        if (args?.task === taskCode && args?.success === true) {
          handleSuccess(args);
        }
        if (args?.error) {
          handleFail(args);
        }
        if (args?.status) {
          setStatusMessage(args?.status);
        }
      });
    }

    return () => {
      socket?.current?.off('connect');
      socket?.current?.off('disconnect');
      socket?.current?.off(referenceNumber);
    };
  }, [referenceNumber]);

  return <div></div>;
};

SocketIOLoading.propTypes = {
  referenceNumber: PropTypes.string,
  handleSuccess: PropTypes.func,
  handleFail: PropTypes.func,
  taskCode: PropTypes.string,
  setStatusMessage: PropTypes.func,
};

export default SocketIOLoading;
