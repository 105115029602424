import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Backdrop, Card, CardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import DonutLove from '../../assets/images/donutLove.png';

import LoadingLinear from './LoadingLinear';

/**
 * @typedef LoadingDialogWithTimeoutProp
 * @property {boolean} isLoading Is Now a waiting for data to load
 * @property {string}  label Label to Display
 * @property {('GO_BACK'|'REFRESH')} action action todo after loading is reach timeout
 *
 * @function LoadingDialogWithTimeout
 * @param {LoadingDialogWithTimeoutProp} data
 * @returns {React.Component} React Component
 */

export default function LoadingDialogWithTimeout({
  isLoading,
  label,
  action = 'GO_BACK',
}) {
  const useStyles = makeStyles({
    backdrop: {
      zIndex: 100,
      color: '#fff',
    },
  });

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    console.log('Pre setting');
    let timeout;
    if (isLoading) {
      timeout = setTimeout(() => {
        console.log('GO Back');
        if (action === 'GO_BACK') {
          history.goBack();
        }
        if (action === 'REFRESH') {
          window.location.reload();
        }
      }, 10000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isLoading]);

  return (
    <div>
      <Backdrop open={isLoading} className={classes.backdrop}>
        <Card>
          <CardContent>
            <div className="flex w-full justify-center my-2">
              <img src={DonutLove} className="w-48 lg:w-80" />
            </div>

            <div className="text-center">{label}</div>
            <div className="px-4 p-2">
              <LoadingLinear isLoading={isLoading} />
            </div>
          </CardContent>
        </Card>
      </Backdrop>
    </div>
  );
}

LoadingDialogWithTimeout.propTypes = {
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  action: PropTypes.string,
};

LoadingDialogWithTimeout.defaultProps = {
  isLoading: true,
};
