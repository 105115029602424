import React from 'react';
import { useSelector } from 'react-redux';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { currencyFormatter } from '@/utils/functions';

function MaterialSplittingTable({ materialSplitting }) {
  const information = useSelector((state) => state.information);
  const filterStep = _.orderBy(
    _.filter(
      materialSplitting?.steps,
      (eachProcessStep) =>
        eachProcessStep?.material_associate &&
        !eachProcessStep?.is_waste &&
        eachProcessStep?.splited_step,
    ),
    ['amount'],
    ['desc'],
  );

  const totalWaste = _.sumBy(
    _.filter(materialSplitting?.steps, (each) => each?.is_waste === true),
    'amount',
  );

  const totalAmount = _.sumBy(filterStep, 'amount') + totalWaste;
  const firstStep = _.find(
    materialSplitting?.steps,
    (each) => each?.index === 0,
  );

  const totalPercentAmount = _.sumBy(filterStep, 'percent_amount');

  const undefineCondition = firstStep?.amount - totalAmount;
  return (
    <div className="p-4">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อขั้นตอน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ปริมาณ</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">สัดส่วนต่อทั้งหมด</div>
                </TableCell>
                {information?.setting?.mms?.materialSplitting
                  ?.displayStatisticalBeside && (
                  <TableCell>
                    <div className="font-bold">ปริมาณจากสถิติ</div>
                  </TableCell>
                )}
                {information?.setting?.mms?.materialSplitting
                  ?.displayStatisticalBeside && (
                  <TableCell>
                    <div className="font-bold">ความแตกต่าง</div>
                  </TableCell>
                )}{' '}
                {information?.setting?.mms?.materialSplitting
                  ?.displayStatisticalBeside && (
                  <TableCell>
                    <div className="font-bold">% ความแตกต่าง</div>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(filterStep) ? (
                _.map(filterStep, (row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="text-xs">{index + 1}</div>
                    </TableCell>
                    <TableCell>{row?.name}</TableCell>
                    <TableCell>
                      {currencyFormatter.format(row?.amount)}
                    </TableCell>
                    <TableCell>
                      {currencyFormatter.format(
                        (row?.amount * 100) / totalAmount,
                      )}{' '}
                      %
                    </TableCell>
                    {information?.setting?.mms?.materialSplitting
                      ?.displayStatisticalBeside && (
                      <TableCell>
                        {currencyFormatter.format(
                          (row?.percent_amount * totalAmount) / 100,
                        )}
                      </TableCell>
                    )}
                    {information?.setting?.mms?.materialSplitting
                      ?.displayStatisticalBeside && (
                      <TableCell>
                        {currencyFormatter.format(
                          row?.amount -
                            (row?.percent_amount * totalAmount) / 100,
                        )}
                      </TableCell>
                    )}{' '}
                    {information?.setting?.mms?.materialSplitting
                      ?.displayStatisticalBeside && (
                      <TableCell>
                        {currencyFormatter.format(
                          ((row?.amount -
                            row?.percent_amount * totalAmount * 0.01) *
                            100) /
                            (row?.percent_amount * totalAmount * 0.01),
                        )}{' '}
                        %
                      </TableCell>
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell component="th" scope="row">
                  <div className="text-xs">{_.size(filterStep) + 1}</div>
                </TableCell>
                <TableCell>เศษเหลือ</TableCell>
                <TableCell>{currencyFormatter.format(totalWaste)}</TableCell>
                <TableCell>
                  {currencyFormatter.format((totalWaste * 100) / totalAmount)} %
                </TableCell>
                {information?.setting?.mms?.materialSplitting
                  ?.displayStatisticalBeside && (
                  <TableCell>
                    {currencyFormatter.format(
                      (100 - totalPercentAmount) * 0.01 * totalAmount,
                    )}
                  </TableCell>
                )}{' '}
                {information?.setting?.mms?.materialSplitting
                  ?.displayStatisticalBeside && (
                  <TableCell>
                    {currencyFormatter.format(
                      totalWaste -
                        (100 - totalPercentAmount) * 0.01 * totalAmount,
                    )}
                  </TableCell>
                )}
                {information?.setting?.mms?.materialSplitting
                  ?.displayStatisticalBeside && (
                  <TableCell>
                    {currencyFormatter.format(
                      ((totalWaste -
                        (100 - totalPercentAmount) * 0.01 * totalAmount) *
                        100) /
                        ((100 - totalPercentAmount) * 0.01 * totalAmount),
                    )}{' '}
                    %
                  </TableCell>
                )}
              </TableRow>
              {undefineCondition !== 0 && (
                <TableRow>
                  <TableCell component="th" scope="row">
                    <div className="text-xs">{_.size(filterStep) + 2}</div>
                  </TableCell>
                  <TableCell>ไม่ทราบสาเหตุ</TableCell>
                  <TableCell>{undefineCondition}</TableCell>
                  <TableCell>-</TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell component="th" scope="row" colSpan={2}>
                  <div className="font-semibold text-center">รวม</div>
                </TableCell>

                <TableCell>
                  <div className="font-semibold">
                    {currencyFormatter.format(totalAmount)}
                  </div>
                </TableCell>
                <TableCell>
                  <div className="font-semibold">
                    {currencyFormatter.format(
                      (totalAmount / firstStep?.amount) * 100,
                    )}
                    %
                  </div>
                </TableCell>
                {information?.setting?.mms?.materialSplitting
                  ?.displayStatisticalBeside && (
                  <TableCell>{currencyFormatter.format(totalAmount)}</TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default MaterialSplittingTable;

MaterialSplittingTable.propTypes = {
  materialSplitting: PropTypes.shape({
    steps: PropTypes.arrayOf(PropTypes.object),
  }),
};
