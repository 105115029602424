import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as CONSTANT from '@/utils/constants';

import 'dayjs/locale/th';

dayjs.locale('th');
dayjs.extend(buddhistEra);

const EditManufacturingMaterialForm = ({ control, me, setValue, watch }) => {
  // Your function Go here

  const percentYieldFormatting = (row) => {
    try {
      if ((row?.amount / row?.begin) * 100 === Infinity) {
        return '100';
      }
      if ((row?.amount / row?.begin) * 100 === -Infinity) {
        return '-';
      }
      return ((row?.amount / row?.begin) * 100)?.toFixed(2);
    } catch (error) {
      return '-';
    }
  };

  const { fields } = useFieldArray({
    control,
    name: 'manufacturingMaterialArrays',
  });

  const handleChangeAmount = (value, index) => {
    const beginAmount = watch(`manufacturingMaterialArrays[${index}].begin`);
    if (_.last(value) === '.') {
      setValue(`manufacturingMaterialArrays[${index}].amount`, value);
    } else {
      const endAmount = parseFloat(value);
      const quantityValue = beginAmount - endAmount;
      setValue(`manufacturingMaterialArrays[${index}].quantity`, quantityValue);
      setValue(
        `manufacturingMaterialArrays[${index}].amount`,
        parseFloat(value),
      );

      let parsedValue = endAmount;
      for (let count = index + 1; count < _.size(fields); count++) {
        const thatCountQuantity = watch(
          `manufacturingMaterialArrays[${count}].quantity`,
        );
        const thatCountDirection = watch(
          `manufacturingMaterialArrays[${count}].transaction_dir`,
        );

        const thatTransactionType = watch(
          `manufacturingMaterialArrays[${count}].transaction_type`,
        );

        if (
          thatTransactionType ===
          CONSTANT.MANUFACTURING_TRANSACTION_TYPE.MATERIAL_HANDOVER.status_code
        ) {
          setValue(
            `manufacturingMaterialArrays[${count}].quantity`,
            parsedValue,
          );
          setValue(`manufacturingMaterialArrays[${count}].amount`, parsedValue);
        } else {
          setValue(`manufacturingMaterialArrays[${count}].begin`, parsedValue);

          const parsedAmount =
            parsedValue +
            (thatCountDirection === true ? 1 : -1) * thatCountQuantity;
          setValue(
            `manufacturingMaterialArrays[${count}].amount`,
            parsedAmount,
          );

          parsedValue = parsedAmount;
        }
      }
    }
  };

  return (
    <div className="my-4">
      <Card>
        <CardContent>
          <div>
            <div className="my-2">
              คุณจะสามารถแก้ไขได้เฉพาะส่วนที่คุณรับผิดชอบเท่านั้น
              เว้นว่าคุณได้รับสิทธิเป็นผู้ดูแลระบบ
              จึงจะสามารถแก้ไขส่วนที่ผู้อื่นรับผิดชอบได้
            </div>
          </div>
          <div className="overflow-x-auto">
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className="font-bold">ลำดับที่</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold"> วันที่</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold">การดำเนินการ</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold">วัตถุดิบ</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold">จากขั้นตอน</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold">เดิม</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold">ดำเนินการ</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold">คงเหลือ</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold">Yield</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!_.isEmpty(fields) ? (
                  _.map(fields, (field, index) => (
                    <TableRow
                      key={field.id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="text-xs">{index + 1}</div>
                      </TableCell>
                      <TableCell>
                        {dayjs(field?.createdAt)?.format('D MMM BBBB')} <br />
                        {dayjs(field?.createdAt)?.format('HH.mm น.')}
                      </TableCell>
                      <TableCell>
                        <div
                          className={`font-semibold ${
                            field?.transaction_dir === true
                              ? 'text-green-500'
                              : 'text-red-500'
                          }`}
                        >
                          {
                            CONSTANT.MANUFACTURING_TRANSACTION_TYPE[
                              field?.transaction_type
                            ]?.description
                          }
                        </div>
                      </TableCell>
                      <TableCell>
                        {field?.material?.type_code} {field?.material?.name}
                      </TableCell>
                      <TableCell>{field?.step?.name}</TableCell>
                      <TableCell>
                        {watch(`manufacturingMaterialArrays[${index}].begin`)}
                      </TableCell>
                      <TableCell>
                        {watch(
                          `manufacturingMaterialArrays[${index}].quantity`,
                        )}
                      </TableCell>
                      <TableCell>
                        <Controller
                          name={`manufacturingMaterialArrays[${index}].amount`}
                          control={control}
                          render={({ field: fieldData }) => (
                            <TextField
                              {...fieldData}
                              size="small"
                              label="คงเหลือ"
                              focused={
                                !!(
                                  (field?.employee?._id === me?.userData?._id ||
                                    me?.userData?.role?.level >= 1) &&
                                  field?.transaction_type ===
                                    CONSTANT.MANUFACTURING_TRANSACTION_TYPE
                                      .MATERIAL_WASTED.status_code
                                )
                              }
                              InputProps={{
                                readOnly:
                                  (field?.employee?._id !== me?.userData?._id &&
                                    me?.userData?.role?.level < 1) ||
                                  field?.transaction_type !==
                                    CONSTANT.MANUFACTURING_TRANSACTION_TYPE
                                      .MATERIAL_WASTED.status_code,
                              }}
                              onChange={(event) =>
                                handleChangeAmount(event.target.value, index)
                              }
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        {percentYieldFormatting(
                          watch(`manufacturingMaterialArrays[${index}]`),
                        )}{' '}
                        %
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9}>
                      <div className="text-center">ไม่มีข้อมูล</div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

EditManufacturingMaterialForm.propTypes = {
  control: PropTypes.func,
  me: PropTypes.object,
  setValue: PropTypes.func,
  watch: PropTypes.func,
};

export default EditManufacturingMaterialForm;
