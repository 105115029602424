import React, { useState } from 'react';
import { SpeedDial, SpeedDialAction } from '@mui/material';

import turtleSlow from '@/assets/images/turtle-slow.svg';

import SystemSlowModal from '../Modals/General/SystemSlowModal';

function FeedbackButton() {
  const [modalSlowOpen, setModalSlowOpen] = useState(false);
  return (
    <div>
      <SystemSlowModal open={modalSlowOpen} setOpen={setModalSlowOpen} />
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        icon={<i className="fas fa-flag"></i>}
      >
        <SpeedDialAction
          key={'ระบบช้า'}
          icon={<img src={turtleSlow} alt="turtle-slow" className="w-6 h-6" />}
          tooltipTitle={'ระบบช้า'}
          onClick={() => setModalSlowOpen(true)}
          tooltipOpen
        />
        <SpeedDialAction
          key={'ปัญหาอื่นๆ'}
          icon={<i className="fas fa-exclamation-circle"></i>}
          tooltipTitle={'ปัญหาอื่นๆ'}
          tooltipOpen
        />
      </SpeedDial>
    </div>
  );
}

export default FeedbackButton;
