export const MANUFACTURING_TRANSACTION_TYPE = {
  PROCESS_CREATE: {
    status_code: 'PROCESS_CREATE',
    description: 'สร้างไลน์การผลิต',
  },
  PROCESS_START: {
    status_code: 'PROCESS_START',
    description: 'เริ่มการผลิต',
  },
  PROCESS_CANCLE: {
    status_code: 'PROCESS_CANCLE',
    description: 'ยกเลิกไลน์การผลิต',
  },
  PROCESS_CLOSE: {
    status_code: 'PROCESS_CLOSE',
    description: 'ปิดไลน์การผลิต',
  },
  PROCESS_UPDATE: {
    status_code: 'PROCESS_UPDATE',
    description: 'อัพเดทไลน์การผลิต',
  },
  WAREHOUSE_ADD: {
    status_code: 'WAREHOUSE_ADD',
    description: 'เพิ่มสินค้าลงในคลังสินค้า',
  },
  MATERIAL_REQUEST: {
    status_code: 'MATERIAL_REQUEST',
    description: 'เบิกวัตถุดิบ',
  },
  MATERIAL_RETURN: {
    status_code: 'MATERIAL_RETURN',
    description: 'คืนวัตถุดิบ',
  },
  MATERIAL_WASTED: {
    status_code: 'MATERIAL_WASTED',
    description: 'เกิดของเสีย',
  },
  MATERIAL_HANDOVER: {
    status_code: 'MATERIAL_HANDOVER',
    description: 'ส่งต่อวัตถุดิบ',
  },

  MATERIAL_MERGED: {
    status_code: 'MATERIAL_MERGED',
    description: 'ถูกรวม',
  },
  MATERIAL_MERGING: {
    status_code: 'MATERIAL_MERGING',
    description: 'มาจากการรวม',
  },
  WORK_CREATE: {
    status_code: 'WORK_CREATE',
    description: 'สร้างงาน',
  },
  WORK_ACCEPT: {
    status_code: 'WORK_ACCEPT',
    description: 'รับงาน',
  },
  WORK_START: {
    status_code: 'WORK_START',
    description: 'เริ่มงาน',
  },
  WORK_CLOSE: {
    status_code: 'WORK_CLOSE',
    description: 'ปิดงาน',
  },
  WORK_RESIGN: {
    status_code: 'WORK_RESIGN',
    description: 'ถอนตัวจากงาน',
  },
  WORK_CANCLE: {
    status_code: 'WORK_CANCLE',
    description: 'ยกเลิกงาน',
  },
};

export default MANUFACTURING_TRANSACTION_TYPE;
