import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  ReportRendering,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import PropTypes from 'prop-types';

/**
 * @function ReportDisplay
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function ReportDisplay({ title, subtitle }) {
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const location = useLocation();

  console.log('state', location.state);
  return (
    <div>
      {renderTitle()}
      <div>
        <ReportRendering
          materialStockLot={location?.state?.materialStockLot}
          report={location?.state?.report}
          startDate={location?.state?.startDate}
          endDate={location?.state?.endDate}
          pointDate={location?.state?.pointDate}
        />
      </div>
    </div>
  );
}

ReportDisplay.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ReportDisplay.defaultProps = {
  title: '',
  subtitle: '',
};
