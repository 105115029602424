import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, Card, CardContent } from '@mui/material';
import _ from 'lodash';

import {
  ViewTitle,
  MachineForm,
  BackButton,
} from '@iarcpsu/emanufac-components/src/components';
import * as actions from '../../redux/actions';

/**
 * @function CreateMachine
 * @description Create New Machine
 */

export default function CreateMachine({ title, subtitle }) {
  const dispatch = useDispatch();
  const machine = useSelector((state) => state.machine);
  const history = useHistory();
  const [image, setImage] = useState([]);
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      if (!_.isEmpty(image)) {
        // eslint-disable-next-line no-param-reassign
        data.image = {
          image: image[0]?.data_url,
          imageType: 'machine',
          alt: '',
        };
      }

      await dispatch(actions.machineCreate(data));
      setImage([]);
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      console.log(error);
      alert('เพิ่มเครื่องจักร / รางการผลิตใหม่ไม่สำเร็จ');
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  return (
    <div>
      {renderTitle()}
      <div className="my-2">
        <BackButton />
      </div>
      <div>
        <Card>
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <MachineForm
                control={control}
                images={image}
                setImages={setImage}
                machine={machine}
                errors={errors}
                watch={watch}
              />
              <div className="flex justify-end">
                <Button color="success" variant="contained" type="submit">
                  บันทึก
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

CreateMachine.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateMachine.defaultProps = {
  title: '',
  subtitle: '',
};
