export const DASHBOARD_TYPE = {
  TABLE: {
    status_code: 'TABLE',
    description: 'ตาราง',
    icon: 'fas fa-table',
    enableOnMO: true,
    enableOnProcess: true,
  },
  BAR_CHART: {
    status_code: 'BAR_CHART',
    description: 'กราฟแท่ง',
    icon: 'fas fa-chart-bar',
    enableOnMO: true,
    enableOnProcess: true,
  },
};

export default DASHBOARD_TYPE;
