export const ReportHeader = (
  information,
  renderLogo = false,
  renderRightLogo,
) => ({
  alignment: 'justify',
  columns: [
    renderLogo
      ? {
          image: 'logo',
          width: 80,
          margin: [20, 0, 0, 20],
        }
      : { text: '', width: 80, margin: [20, 0, 0, 20] },
    [
      {
        text: information?.owner?.name || '',
        fontSize: '16',
        bold: true,
        margin: [40, 0],
      },
      {
        text: `${information?.owner?.address?.house_number || ''} ${
          information?.owner?.address?.village_number || ''
        } ${information?.owner?.address?.road || ''} ${
          information?.owner?.address?.subdistrict || ''
        } ${information?.owner?.address?.district || ''} ${
          information?.owner?.address?.province || ''
        } ${information?.owner?.address?.postcode || ''}`,
        fontSize: '10',
        margin: [40, 2],
      },
      {
        text: `อีเมล ${information?.owner?.email || ''} โทร ${
          information?.owner?.telephone || ''
        }`,
        fontSize: '10',
        margin: [40, 2],
      },
      {
        text: `เลขที่ผู้เสียภาษี ${information?.owner?.tax || ''}`,
        fontSize: '10',
        margin: [40, 2],
      },
    ],
    renderRightLogo
      ? {
          image: 'right_logo',
          width: 80,
          margin: [20, 0, 0, 20],
        }
      : { text: '', width: 80, margin: [20, 0, 0, 20] },
  ],
});

export default ReportHeader;
