import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Backdrop, Button, Card, CardContent, Modal } from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { config } from '@/configs';
import api from '@/configs/api';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';
import { currencyFormatter } from '@/utils/functions';

import * as metalDivideActions from '../redux/actions';

import AddMetalToWarehouseForm from './AddMetalToWarehouseForm';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70vw',
  height: '70vh',
  p: 4,
  overflowY: 'auto',
};

function AddMetalToWarehouseModal({
  isOpen,
  handleClose,
  selectedDivided,
  conveyorWidth,
  divideIndex,
  manufacturingOrder,
  currentProcess,
  overAllMetalDivide,
  metalDivideId,
}) {
  const product = useSelector((state) => state.product);
  const warehouse = useSelector((state) => state.warehouse);
  const productTransactionType = useSelector(
    (state) => state.productTransactionType,
  );
  const dispatch = useDispatch();
  const { control, handleSubmit, watch, setValue } = useForm();

  const fetchMetalDivide = () => {
    dispatch(
      metalDivideActions.metalDivideAll({
        selectProcess: currentProcess?._id,
        size: config.maxFetchSize,
      }),
    );
  };

  const onHandleToWarehouse = async (data) => {
    try {
      const selecedProdTxnType = _.first(
        _.filter(
          productTransactionType?.rows,
          (each) => each?.direction === 'add',
        ),
      );

      const payload = {
        product_transaction_type: selecedProdTxnType?._id,
        product: _.map(data?.product_stock_lot, (eachStockLot) => ({
          ...eachStockLot,
          amount: eachStockLot?.quantity,
          customer: selectedDivided?.customer,
          product_transaction_type: selecedProdTxnType?._id,
          status: 'IN_STOCK',
          production_number: manufacturingOrder?._id,
          process_number: currentProcess?._id,
          inventory_date: new Date(),
        })),
      };

      const { data: prodStockLotData } = await api.post(
        `${process.env.REACT_APP_API_URL}/product-stock-lot`,
        {
          ...payload,
        },
      );

      const stockLotList = prodStockLotData?.product_stock_lot;
      const stockLotId = _.map(stockLotList, (each) => ({
        lotId: each?.lotId,
      }));

      // FIXME: Move to Worker
      const allDivideSet = overAllMetalDivide;
      allDivideSet[divideIndex].in_warehouse = true;
      allDivideSet[divideIndex].product_stock_lot = stockLotId;

      console.log('Stock ID List', stockLotId);

      await dispatch(
        metalDivideActions.metalDividePut(metalDivideId, {
          divide_sets: allDivideSet,
        }),
      );

      fetchMetalDivide();
      alert('สำเร็จ');
    } catch (error) {
      alert(`ไม่สามารถเพิ่มลงคลังสินค้าได้ ${error?.message}`);
    }
  };

  const query = gql`
    query FindProduct(
      $productInput: ProductInput
      $warehouseInput: WarehouseInput
    ) {
      findProducts(input: $productInput) {
        rows {
          _id
          name
          type_code
          use_unit_conversion
          unit
          conversion_from {
            unit_a
            unit_b
            conversion_factor
          }
          conversion_to {
            unit_b
            unit_a
            conversion_factor
          }
          unit_input {
            _id
            short_sign
            name
          }
          unit_output {
            _id
            short_sign
            name
          }
        }
      }
      findWarehouses(input: $warehouseInput) {
        rows {
          _id
          name
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        productInput: {
          name: '',
          page: 1,
          size: config.maxFetchSize,
          fetchStockLot: false,
        },
        warehouseInput: { name: '', page: 1, size: config.maxFetchSize },
      });
      const productData = queryResult?.findProducts;
      console.log('Product Data', productData);
      const warehouseData = queryResult?.findWarehouses;
      dispatch(actions.productStateSet(productData));
      dispatch(actions.warehouseStateSet(warehouseData));
      dispatch(actions.productTransactionTypeAll({ name: '' }));
    } catch (error) {
      dispatch(actions.productError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, []);

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={() => handleClose()}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Card style={style}>
          <CardContent>
            <h3 className="font-display text-xl">เพิ่มสินค้าลงคลัง</h3>
            <div className="p-2">
              <div className="my-2 font-semibold font-display">
                ข้อมูลคอนกรีตที่ตัดได้
              </div>
              <div className="flex flex-wrap border rounded-md p-4">
                <div className="w-full md:w-1/3">
                  <div className="font-semibold">ลูกค้า</div>
                  <div> {selectedDivided?.customer?.name} </div>
                </div>
                <div className="w-full md:w-1/3">
                  <div className="font-semibold">ส่วนที่ได้</div>
                  <div>
                    ความยาว {currencyFormatter.format(selectedDivided?.length)}{' '}
                    x จำนวน {selectedDivided?.amount} ชิ้น ={' '}
                    {currencyFormatter.format(
                      selectedDivided?.length * selectedDivided?.amount,
                    )}{' '}
                    ม.
                  </div>
                  <div>
                    พื้นที่{' '}
                    {currencyFormatter.format(
                      selectedDivided?.length *
                        selectedDivided?.amount *
                        conveyorWidth,
                    )}{' '}
                    ตารางเมตร
                  </div>
                </div>
              </div>
            </div>
            <div className="p-2">
              <form>
                <AddMetalToWarehouseForm
                  area={
                    selectedDivided?.length *
                    selectedDivided?.amount *
                    conveyorWidth
                  }
                  control={control}
                  customer={selectedDivided?.customer}
                  product={product}
                  warehouse={warehouse}
                  setValue={setValue}
                  watch={watch}
                />
                <div className="flex justify-end my-2">
                  <Button
                    size="small"
                    color="success"
                    variant="contained"
                    onClick={handleSubmit(onHandleToWarehouse)}
                  >
                    บันทึก
                  </Button>
                </div>
              </form>
            </div>
          </CardContent>
        </Card>
      </Modal>
    </div>
  );
}

export default AddMetalToWarehouseModal;

AddMetalToWarehouseModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedDivided: PropTypes.object,
  conveyorWidth: PropTypes.number,
  divideIndex: PropTypes.number,
  manufacturingOrder: PropTypes.object,
  currentProcess: PropTypes.object,
};
