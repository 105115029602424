import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { ErrorBoundary } from 'react-error-boundary';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { allStepMaterialAnalyze } from '@/utils/functions/MMS/materialAnalyzer';

import { Error } from '../Error';

export default function ProcessYieldPieChart({
  processMaterial,
  allSteps,
  materialId,
}) {
  const [dataSeries, setDataSeries] = useState([]);
  const [dataLabels, setDataLabels] = useState([]);

  useEffect(() => {
    const labels = [];
    const materialOnEnd = [];
    const wastes = [];
    const percentYield = [];
    const materialOnBegin = [];
    const materialReturn = [];
    const realUsed = [];

    // Initial Step
    const selectedProcessMaterial = _.find(
      processMaterial,
      (_material) => _material?.material?._id === materialId,
    );

    const materialResult = allStepMaterialAnalyze(
      selectedProcessMaterial,
      allSteps,
    );

    _.map(materialResult, (_stepResult) => {
      labels.push(_stepResult.name);
      materialOnEnd.push(_stepResult.analyze.remain);
      wastes.push(_stepResult.analyze.usedAmount - _stepResult.analyze.remain);
      // console.log('Select Material', selectedMaterial?.end);
      percentYield.push(_stepResult.analyze.yield);
      materialOnBegin.push(_stepResult.analyze.requestAmount);
      materialReturn.push(_stepResult.analyze.returnAmount);
      realUsed.push(_stepResult.analyze.usedAmount);
    });

    setDataSeries([
      { name: 'เบิกวัตถุดิบรวม', data: materialOnBegin, type: 'column' },
      { name: 'คืนของ', data: materialReturn, type: 'column' },
      { name: 'ใช้จริง', data: realUsed, type: 'column' },
      { name: 'ได้เป็นผลผลิต', data: materialOnEnd, type: 'column' },
      { name: 'ของเสีย', data: wastes, type: 'column' },
      { name: 'Yield ของผลผลิต', data: percentYield, type: 'line' },
    ]);
    setDataLabels(labels);
    return () => {};
  }, [processMaterial]);

  const options = {
    labels: dataLabels,
    // colors: ['#2541B2', '#FF6347', '#008B8B'],
    yaxis: [
      {
        title: {
          text: 'ปริมาณวัตถุดิบ',
        },
        labels: {
          formatter: (value) => parseFloat(value).toFixed(2),
        },
      },
    ],
    dataLabels: {
      enabled: true,
      enabledOnSeries: [5],
      formatter: (value) => parseFloat(value).toFixed(2),
    },
  };

  const ErrorFallback = ({ error }) => <Error message={error?.message} />;

  if (!_.isEmpty(dataSeries)) {
    return (
      <div className="my-2">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Chart type="line" series={dataSeries} options={options} />
        </ErrorBoundary>
      </div>
    );
  }

  return <div></div>;
}

ProcessYieldPieChart.propTypes = {
  allSteps: PropTypes.arrayOf(PropTypes.object),
  materialId: PropTypes.string,
  processMaterial: PropTypes.shape({
    material: PropTypes.object,
    yield: PropTypes.number,
    wastes: PropTypes.number,
    begin: PropTypes.number,
    end: PropTypes.number,
  }),
  error: PropTypes.object,
};
