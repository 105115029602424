export const DATA_TYPE = {
  STRING: {
    type_code: 'STRING',
    description: 'ข้อความสั้น',
    icon: 'fas fa-font',
    requirable: true,
    is_basic: true,
  },
  PARAGRAPH: {
    type_code: 'PARAGRAPH',
    description: 'ข้อความยาว',
    icon: 'fas fa-align-center',
    requirable: true,
  },
  NUMBER: {
    type_code: 'NUMBER',
    description: 'ตัวเลข',
    icon: 'fas fa-sort-numeric-up',
    requirable: true,
    is_basic: true,
  },
  BOOLEAN: {
    type_code: 'BOOLEAN',
    description: 'เช็คบ็อก',
    icon: 'fas fa-check-circle',
  },
  SWITCH: {
    type_code: 'SWITCH',
    description: 'สวิตซ์',
    icon: 'fas fa-toggle-on',
    is_basic: true,
  },
  TIME: {
    type_code: 'TIME',
    description: 'เวลา',
    icon: 'fas fa-clock',
    // requirable: true,
  },
  DATETIME: {
    type_code: 'DATETIME',
    description: 'วัน/เวลา',
    icon: 'fas fa-calendar-plus',
    //  requirable: true,
  },
  DATE: {
    type_code: 'DATE',
    description: 'วันที่',
    icon: 'fas fa-calendar',
    // requirable: true,
  },
  CHECK_AND_DESCRIBE: {
    type_code: 'CHECK_AND_DESCRIBE',
    description: 'ทำเครื่องหมายและกรอกข้อความยาว',
    icon: 'fas fa-spell-check',
  },
  RADIO_AND_DESCRIBE: {
    type_code: 'RADIO_AND_DESCRIBE',
    description: 'เลือกข้อใดข้อหนึ่งแล้วกรอกข้อความ',
    icon: 'fas fa-spell-check',
  },
  PERSON: {
    type_code: 'PERSON',
    description: 'บุคคล',
    icon: 'fas fa-user',
  },
};

export default DATA_TYPE;
