import React from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

import { SPM } from '@/utils/Proptypes';

export const ShippingTypeForm = ({
  errors,
  shippingType,
  Controller,
  control,
}) => (
  <div className="flex flex-row flex-wrap">
    <div className="w-full px-1 py-2">
      <Controller
        name={'type_code'}
        control={control}
        defaultValue={shippingType ? shippingType.type_code : ''}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            {...field}
            label="รหัส"
            fullWidth
            size={'small'}
            multiline={true}
            helperText={errors.type_code && 'กรุณาใส่ข้อมูล'}
          />
        )}
      />
    </div>
    <div className="w-full px-1 py-2">
      <Controller
        name={'name'}
        control={control}
        defaultValue={shippingType ? shippingType.name : ''}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            {...field}
            label="ชื่อวิธีการจัดส่ง"
            fullWidth
            size={'small'}
            helperText={errors.name && 'กรุณาใส่ข้อมูล'}
          />
        )}
      />
    </div>
    <div className="w-full px-1 py-2">
      <Controller
        name={'description'}
        control={control}
        defaultValue={shippingType ? shippingType.description : ''}
        rules={{ required: false }}
        render={({ field }) => (
          <TextField
            {...field}
            label="รายละเอียด"
            fullWidth
            size={'small'}
            multiline={true}
            helperText={errors.description && 'กรุณาใส่ข้อมูล'}
          />
        )}
      />
    </div>
  </div>
);

ShippingTypeForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    description: PropTypes.object,
    type_code: PropTypes.object,
  }),
  shippingType: SPM.ShippingType,
  Controller: PropTypes.func,
  control: PropTypes.object,
};

ShippingTypeForm.defaultProps = {
  shippingType: null,
};

export default ShippingTypeForm;
