import React, { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  Backdrop,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';
import * as CONSTANT from '@/utils/constants';

import { Loading } from '../../Loading';

const AddInventoryModal = ({ isOpen, handleClose, purchaseOrder }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: purchaseOrder,
  });

  const { fields } = useFieldArray({
    control,
    name: 'material',
  });
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const place = useSelector((state) => state.place);
  const materialTransactionType = useSelector(
    (state) => state.materialTransactionType,
  );
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    const fetchMaterterial = async () => {
      try {
        dispatch(actions.materialAll({}));
      } catch (error) {
        console.error('Fetch Material Fail', error);
      }
    };

    const fetchPlace = async () => {
      try {
        dispatch(actions.placeAll({ name: '' }));
      } catch (error) {
        console.error('Fetch Place ', error);
      }
    };

    const fetchDepartment = async () => {
      try {
        dispatch(actions.departmentAll({ name: '' }));
      } catch (error) {
        console.error('Fetch Department Fail', error);
      }
    };

    const fetchCustomer = async () => {
      try {
        dispatch(actions.customerAll({ name: '' }));
      } catch (error) {
        console.error('Fetch Customer Fail', error);
      }
    };

    const fetchMaterialTxnType = async () => {
      try {
        dispatch(actions.materialTransactionTypeAll({ name: '' }));
      } catch (error) {
        console.error('Fetch Material Txn Type', error);
      }
    };

    const fetchPurchaseOrder = async () => {
      try {
        dispatch(actions.purchaseOrderGet(id));
      } catch (error) {
        console.error('Fetch Purchase Order', error);
      }
    };

    fetchPurchaseOrder();
    fetchPlace();
    fetchMaterterial();
    fetchDepartment();
    fetchCustomer();
    fetchMaterialTxnType();
    return () => {};
  }, []);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70vw',
    height: '70vh',
    p: 4,
    overflowY: 'scroll',
  };

  const handleAddInventory = async (data) => {
    const materialLot = [];
    const editedPurchseOrder = {
      material: _.map(purchaseOrder.material, (_material, index) => ({
        ..._material,
        amount: _material?.amount - data?.material?.[index].quantity,
        _id: undefined,
        material: _material?.material?._id,
      })),
      status: CONSTANT.MATERIAL_PURCHASE_STATUS.RECEIVED.status_code,
      bill_number: data?.bill_number,
      source: data?.source,
      material_transaction_type: data?.material_transaction_type?._id,
    };

    const modifiedMaterial = _.map(data?.material, (_material) => {
      if (_material?.quantity !== 0) {
        return {
          ..._material,
          amount: _material?.quantity,
          _id: undefined,
          material: _material?.material?._id,
        };
      }
    });

    const reduce = modifiedMaterial?.reduce((r, a) => {
      r[a.place] = [...(r[a.place] || []), a];
      return r;
    }, {});
    const reduceValue = _.values(reduce);

    // eslint-disable-next-line no-restricted-syntax
    for await (const _reduceValue of reduceValue) {
      const payload = {
        ...data,
        supplier: purchaseOrder?.supplier?._id,
        status: CONSTANT.MATERIAL_STOCK_STATUS.IN_STOCK.status_code,
        place: _reduceValue[0]?.place,
        material: _reduceValue,
        _id: undefined,
        purchase_date: new Date(purchaseOrder?.purchase_date),
        receipt_date: new Date(),
        recipient_name: `${me?.userData?.firstname} ${me?.userData?.lastname}`,
        purchase_order: purchaseOrder?._id,
        createdAt: undefined,
        updatedAt: undefined,
      };
      materialLot.push(payload);
    }

    const materialOnMessage = _.map(
      data.material,
      (_material, index) =>
        `${index + 1}. ${_material?.material?.name} จำนวน ${
          _material?.quantity
        } ${_material?.material?.unit} \n`,
    );
    try {
      editedPurchseOrder.material_lot = materialLot;
      await dispatch(
        actions.purchaseOrderPut(purchaseOrder?._id, editedPurchseOrder),
      );
      handleClose();
      alert('เพิ่มวัตถุดิบสำเร็จ');
      await dispatch(actions.purchaseOrderGet(purchaseOrder?._id));
      await dispatch(
        actions.notifyDirect({
          employeeId: purchaseOrder?.purchaser?._id,
          message: `วัตถุดิบที่ท่านสั่งซื้อ \n${materialOnMessage}ได้มาถึงแล้ว`,
        }),
      );

      history.goBack();
    } catch (error) {
      window.alert('เพิ่มวัตถุดิบไม่สำเร็จ');
      console.error(error);
    }
  };

  const renderBillInfo = () => (
    <div className="w-full flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'material_transaction_type._id'}
          defaultValue={
            purchaseOrder?.material_transaction_type?._id
              ? purchaseOrder?.material_transaction_type?._id
              : ''
          }
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl fullWidth size="small">
              <InputLabel>ประเภทการดำเนินการ*</InputLabel>
              <Select {...field} label="ประเภทการดำเนินการ*" required>
                {_.map(materialTransactionType?.rows, (_txnType, index) => {
                  if (_txnType?.direction === 'add') {
                    return (
                      <MenuItem key={index} value={_txnType?._id}>
                        {_txnType?.name}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'bill_number'}
          control={control}
          defaultValue={purchaseOrder?.bill_number}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เลขที่บิล"
              fullWidth
              size={'small'}
              helperText={errors.bill_number && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'source'}
          control={control}
          defaultValue={purchaseOrder?.source}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="แหล่งวัตถุดิบ"
              fullWidth
              size={'small'}
              helperText={errors.source && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-full px-1 py-2">
        <Controller
          name={'remark'}
          control={control}
          defaultValue={''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="หมายเหตุ"
              fullWidth
              size={'small'}
              multiline={true}
              rows={3}
              helperText={errors.remark && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
    </div>
  );

  const renderForm = () => (
    <Card style={style}>
      <CardContent>
        <form onSubmit={handleSubmit(handleAddInventory)}>
          <h4 className="font-semibold text-lg my-2">
            เพิ่มวัตถุดิบลงในคลังวัตถุดิบ
          </h4>
          <div className="flex flex-wrap my-2">
            <div className="w-full px-2"> {renderBillInfo()}</div>
          </div>

          <h4 className="font-bold">วัตถุดิบ</h4>
          <div className="w-full mx-2">
            <TableContainer>
              <Table
                size="small"
                className="border-lg mt-2"
                sx={{ minWidth: 650 }}
              >
                <colgroup>
                  <col width="10%" />
                  <col width="15%" />
                  <col width="15%" />
                  <col width="10%" />
                  <col width="15%" />
                  <col width="15%" />
                  <col width="15%" />
                  <col width="15%" />
                </colgroup>
                <TableHead className="bg-blue-100">
                  <TableCell>ลำดับที่</TableCell>
                  <TableCell>วัตถุดิบ</TableCell>
                  <TableCell>คลังวัตถุดิบ</TableCell>
                  <TableCell>หมายเลขล็อต</TableCell>
                  <TableCell>ปริมาณ</TableCell>
                  <TableCell>ราคาซื้อ</TableCell>
                  <TableCell>วันผลิต</TableCell>
                  <TableCell>วันหมดอายุ</TableCell>
                </TableHead>
                <TableBody>
                  {_.map(fields, (_field, index) => (
                    <TableRow key={_field?.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{_field?.material?.name}</TableCell>
                      <TableCell>
                        <div className="w-48">
                          <Controller
                            name={`material[${index}].place`}
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <FormControl fullWidth size="small">
                                <InputLabel>คลังวัตถุดิบ</InputLabel>
                                <Select
                                  {...field}
                                  defaultValue=""
                                  label="คลังวัตถุดิบ"
                                  required
                                >
                                  {_.map(place?.rows, (_place, placeIndex) => (
                                    <MenuItem
                                      key={placeIndex}
                                      value={_place?._id}
                                    >
                                      {_place?.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                          />{' '}
                        </div>
                      </TableCell>
                      <TableCell>
                        {' '}
                        <div className="w-36">
                          <Controller
                            name={`material[${index}].lot_number`}
                            defaultValue={''}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                label="หมายเลขล็อต"
                                fullWidth
                                {...field}
                              />
                            )}
                          />
                        </div>
                      </TableCell>
                      <TableCell>
                        {' '}
                        <div className="w-24">
                          <Controller
                            name={`material[${index}].quantity`}
                            defaultValue={
                              purchaseOrder?.material?.[index]?.amount
                            }
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                label="ปริมาณ"
                                fullWidth
                                type="number"
                                {...field}
                              />
                            )}
                          />
                        </div>
                      </TableCell>
                      <TableCell>
                        {' '}
                        <div className="w-24">
                          <Controller
                            name={`material[${index}].price`}
                            defaultValue={0}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                label="ราคาซื้อ"
                                fullWidth
                                type="number"
                                {...field}
                              />
                            )}
                          />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="w-48">
                          <Controller
                            name={`material[${index}].production_date`}
                            control={control}
                            defaultValue={new Date()}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                type="date"
                                fullWidth
                                size={'small'}
                                helperText={
                                  errors.production_date && 'กรุณาใส่ข้อมูล'
                                }
                              />
                            )}
                          />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="w-48">
                          <Controller
                            name={`material[${index}].expiration_date`}
                            control={control}
                            defaultValue={new Date()}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                type="date"
                                fullWidth
                                size={'small'}
                                helperText={
                                  errors.expiration_date && 'กรุณาใส่ข้อมูล'
                                }
                              />
                            )}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="flex justify-end gap-2 my-4">
            <Button type="submit" color="primary" variant="contained">
              ยืนยัน
            </Button>
            <Button
              type="button"
              color="inherit"
              variant="contained"
              onClick={() => handleClose()}
            >
              ยกเลิก
            </Button>
          </div>
        </form>{' '}
      </CardContent>
    </Card>
  );

  if (purchaseOrder?.isLoading) {
    return <Loading />;
  }
  if (!purchaseOrder?.isLoading && purchaseOrder?.isCompleted) {
    return (
      <Modal
        open={isOpen}
        onClose={() => handleClose()}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {renderForm()}
      </Modal>
    );
  }

  return <div></div>;
};

export default AddInventoryModal;

AddInventoryModal.propTypes = {
  process: PropTypes.object,
  purchaseOrder: PropTypes.object,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};
