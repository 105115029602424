import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, ButtonGroup, FormControlLabel, Switch } from '@mui/material';
import PropTypes from 'prop-types';

import {
  BackButton,
  MaterialSplittingBranchingStep,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';

/**
 * @function ExpandMaterialSplittingSplitting
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function ExpandMaterialSplitting({ title, subtitle }) {
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const location = useLocation();
  const materialSplitting = location?.state?.materialSplitting;
  const [height, setHeight] = useState(2500);
  const [allowDrag, setAllowDrag] = useState(false);

  return (
    <div>
      {renderTitle()}
      <BackButton />
      <div>
        <div className="flex justify-end">
          <div className="flex gap-4">
            <ButtonGroup size="small">
              <Button
                startIcon={<i className="fas fa-minus-circle"></i>}
                onClick={() => {
                  setHeight(height - 100);
                }}
              >
                ลดพื้นที่
              </Button>
              <Button
                startIcon={<i className="fas fa-plus-circle"></i>}
                onClick={() => {
                  setHeight(height + 100);
                }}
              >
                เพิ่มพื้นที่
              </Button>
            </ButtonGroup>
            <div className="px-2">
              <FormControlLabel
                label="ย่อขยาย/เลื่อน ไดอะแกรม"
                control={
                  <Switch
                    defaultChecked={allowDrag}
                    onChange={(e) => setAllowDrag(e.target.checked)}
                  />
                }
              />
            </div>
          </div>
        </div>
        <MaterialSplittingBranchingStep
          allowDrag={allowDrag}
          processSteps={materialSplitting?.steps}
          height={height}
        />
      </div>
    </div>
  );
}

ExpandMaterialSplitting.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ExpandMaterialSplitting.defaultProps = {
  title: '',
  subtitle: '',
};
