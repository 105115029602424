import React from 'react';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import _ from 'lodash';

import { currencyFormatter } from '@/utils/functions';

import MultiUnitProdOrMatBox from '../../Box/Rendering/MultiUnitProdOrMatBox';

const TotalMaterialBomTable = ({ bomChecking }) => (
  <div>
    {' '}
    <TableContainer component={Paper}>
      <Paper>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <div className="font-bold">ลำดับที่</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> วัตถุดิบ</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">ปริมาณตามสูตร</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">ปริมาณที่เบิกไป</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">ปริมาณที่ใช้จริง</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">
                  ส่วนต่างระหว่าง
                  <br /> สูตรและใช้จริง
                </div>
              </TableCell>
              <TableCell>
                <div className="font-bold">ราคาตามสูตร</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">ราคาจากที่เบิกไป</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">ราคาที่ใช้จริง</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!_.isEmpty(bomChecking?.analyzed) ? (
              _.map(bomChecking?.analyzed, (row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    <div className="text-xs">{index + 1}</div>
                  </TableCell>
                  <TableCell>
                    <span className="font-semibold">
                      {row?.material?.type_code || ''}
                    </span>{' '}
                    {row?.material?.name}{' '}
                  </TableCell>
                  <TableCell>
                    <MultiUnitProdOrMatBox
                      foundMaterial={row?.material}
                      quantity={row?.totalPrefer}
                    />
                  </TableCell>
                  <TableCell>
                    <MultiUnitProdOrMatBox
                      foundMaterial={row?.material}
                      quantity={row?.totalDeposit}
                    />
                  </TableCell>
                  <TableCell>
                    {' '}
                    <MultiUnitProdOrMatBox
                      foundMaterial={row?.material}
                      quantity={row?.totalActual}
                    />
                  </TableCell>
                  <TableCell>
                    <MultiUnitProdOrMatBox
                      foundMaterial={row?.material}
                      quantity={row?.difference}
                    />
                  </TableCell>
                  <TableCell>
                    {currencyFormatter.format(row?.formularCost)} บาท
                  </TableCell>
                  <TableCell>
                    {currencyFormatter.format(row?.depositCost)} บาท
                  </TableCell>
                  <TableCell>
                    {currencyFormatter.format(row?.actualCost)} บาท
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <div className="text-center">ไม่มีข้อมูล</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    </TableContainer>
  </div>
);

TotalMaterialBomTable.propTypes = {
  bomChecking: PropTypes.shape({
    analyzed: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default TotalMaterialBomTable;
