import React from 'react';
import {
  Check as CheckIcon,
  Info as InfoIcon,
  MoreHorizontal as Detail,
} from 'react-feather';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';
import dayjs from 'dayjs';
import BuddhistEra from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import PropTypes from 'prop-types';

import 'dayjs/locale/th';

dayjs.extend(BuddhistEra);

const ProgressStep = ({ templateSteps, availableSteps, currentIndex }) => {
  const pathArrow = () => (
    <div className="hidden lg:flex max-w-xs flex-1 items-center justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z" />
      </svg>
    </div>
  );

  const renderEachStep = (eachStep, index) => {
    const realStepData = _.find(
      availableSteps,
      (_availableSteps) => _availableSteps?.index === index,
    );
    if (eachStep?.index === currentIndex && realStepData?.completed === true) {
      return (
        <div
          className="w-full max-w-xs mt-2 md:min-w-60  md:mt-0 text-center px-6  "
          key={index}
        >
          <div className="bg-green-200 rounded-lg flex items-center justify-center border border-green-100 ">
            <div className="flex w-full items-center justify-center">
              <div className="w-1/3 bg-transparent h-20 flex flex-col items-center justify-center icon-step">
                <CheckIcon size={32} />
              </div>
              <div className="w-2/3 bg-green-100 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step">
                <h2 className="font-bold text-sm">
                  <div className="flex">
                    <div className="self-center">{realStepData?.name} </div>
                    <div className="self-center mt-2">
                      <Link to={`/mms/works/view/${realStepData?._id}`}>
                        <IconButton variant="text" size="small">
                          <Detail size={12} />
                        </IconButton>
                      </Link>
                    </div>
                  </div>
                </h2>
                <p className="text-xs">
                  แผนก{eachStep?.responsible?.department?.name}
                </p>
                <p className="text-xs text-gray-600">
                  {dayjs(realStepData?.updatedAt).format('D MMM')} เวลา{' '}
                  {dayjs(realStepData?.updatedAt).format('HH.mm น.')}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (eachStep?.index === currentIndex) {
      return (
        <div
          className="w-full max-w-xs md:min-w-60 mt-2 lg:mt-0 text-center px-6"
          key={index}
        >
          <div className="bg-green-300 rounded-lg flex items-center justify-center border border-green-200">
            <div className="flex w-full items-center justify-center">
              <div className="w-1/3 bg-transparent h-20 flex items-center justify-center icon-step">
                {eachStep?.index + 1} / {_.size(templateSteps)}
              </div>
              <div className="w-2/3 bg-green-200 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step">
                <div className="flex">
                  <div className="self-center font-bold">
                    {realStepData?.name}{' '}
                  </div>
                  <div className="self-center  mt-2">
                    <Link to={`/mms/works/view/${realStepData?._id}`}>
                      <IconButton variant="text" size="small">
                        <Detail size={12} />
                      </IconButton>
                    </Link>
                  </div>
                </div>
                <p className="text-xs text-gray-600">
                  {' '}
                  แผนก{eachStep?.responsible?.department?.name}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (eachStep?.index <= currentIndex) {
      return (
        <div
          className="w-full max-w-xs mt-2 md:min-w-60  md:mt-0 text-center px-6  "
          key={index}
        >
          <div className="bg-green-200 rounded-lg flex items-center justify-center border border-green-100 ">
            <div className="flex w-full items-center justify-center">
              <div className="w-1/3 bg-transparent h-20 flex flex-col items-center justify-center icon-step">
                <CheckIcon size={32} />
              </div>
              <div className="w-2/3 bg-green-100 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step">
                <h2 className="font-bold text-sm">
                  <div className="flex">
                    <div className="self-center">{realStepData?.name} </div>
                    <div className="self-center  mt-2">
                      <Link to={`/mms/works/view/${realStepData?._id}`}>
                        <IconButton variant="text" size="small">
                          <Detail size={12} />
                        </IconButton>
                      </Link>
                    </div>
                  </div>
                </h2>
                <p className="text-xs">
                  แผนก{eachStep?.responsible?.department?.name}
                </p>
                <p className="text-xs text-gray-600">
                  {dayjs(realStepData?.updatedAt).format('D MMM')} เวลา{' '}
                  {dayjs(realStepData?.updatedAt).format('HH.mm น.')}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className="w-full max-w-xs mt-2 md:min-w-60  md:mt-0 text-center px-6  "
        key={index}
      >
        <div className="bg-gray-300 rounded-lg flex items-center justify-center border border-gray-200 ">
          <div className="flex w-full items-center justify-center">
            <div className="w-1/3 bg-transparent h-20 flex items-center justify-center icon-step">
              {eachStep?.index + 1} / {_.size(templateSteps)}
            </div>
            <div className="w-2/3 bg-gray-200 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step">
              <h2 className="font-bold text-sm">{eachStep?.name}</h2>
              <p className="text-xs text-gray-600">
                แผนก{eachStep?.responsible?.department?.name}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="mt-4">
      <div>
        <div className="flex w-full flex-wrap lg:flex-nowrap  md:overflow-x-auto">
          {_.map(templateSteps, (_step, index) => (
            <div key={index} className="flex w-full ">
              {renderEachStep(_step, index)}
              {index + 1 !== _.size(templateSteps) ? (
                pathArrow()
              ) : (
                <div className="w-1"></div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProgressStep;

ProgressStep.propTypes = {
  templateSteps: PropTypes.arrayOf(PropTypes.object),
  availableSteps: PropTypes.arrayOf(PropTypes.object),
  currentIndex: PropTypes.number,
};
