import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { previousLotReciptDateSafe } from '@/utils/functions';

import EachMachineTimePair from './EachMachineTimePair';

const MachineDrivenOnEachParent = ({ previousLotList }) => {
  const orderedLot = _.orderBy(
    previousLotReciptDateSafe(previousLotList),
    'receipt_date',
  );
  const groupByDatedLots = _.groupBy(orderedLot, 'receipt_date');

  const timeKey = _.keys(groupByDatedLots);
  const pairTimeKey = _.zip(timeKey, timeKey.slice(1));
  const filterPairTime = _.filter(
    pairTimeKey,
    (each) => each?.[1] !== undefined,
  );

  return (
    <div>
      {_.map(filterPairTime, (eachPair, index) => (
        <EachMachineTimePair
          materialStockLotGroupByDated={groupByDatedLots}
          timePair={eachPair}
          key={index}
        />
      ))}
    </div>
  );
};

export default MachineDrivenOnEachParent;

MachineDrivenOnEachParent.propTypes = {
  previousLotList: PropTypes.array,
};
