import React from 'react';
import { Card, CardContent } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { MMS } from '@/utils/functions';
import BoxRemainingMaterial from '../Material/BoxRemainingMaterial';
import BoxResultingFunction from '../MMS/BoxResultingFunction';
import MeasurementVariableListBox from '../MMS/MeasurementVariableListBox';

const StepMeasurementAndMaterialDisplayBox = ({
  manufacturingMaterials,
  step,
  measurements,
  selectedProcess,
}) => {
  const {
    MaterialAnalyzer: { unNullManufacMaterialAnalyzedArrayAnalzer },
    findVariableAndExecute,
  } = MMS;

  const analyzedMaterial = unNullManufacMaterialAnalyzedArrayAnalzer(
    manufacturingMaterials,
  );

  const renderFunctionResult = (currentFunction) => {
    try {
      const executedResult = findVariableAndExecute({
        measurementVars: measurements,
        resultingFunctionType: currentFunction,
        enableLog: true,
      });
      return executedResult;
    } catch (error) {
      console.error('Cannot Execute Resulting Function', error);
      return '-';
    }
  };

  const renderMaterialInfo = () => (
    <div>
      <Card>
        <CardContent>
          <h5 className="font-semibold font-display pb-2">
            วัตถุดิบที่ได้รับมา
          </h5>
          <BoxRemainingMaterial
            manufacturingMaterials={manufacturingMaterials}
            showGraph
            selectedProcess={selectedProcess}
          />{' '}
          {_.isEmpty(analyzedMaterial) && <div>ไม่มีวัตถุดิบที่ได้รับ</div>}
        </CardContent>
      </Card>
    </div>
  );

  const renderMeasurementInfo = () => (
    <div>
      <Card>
        <CardContent>
          {!_.isEmpty(step?.measurements) && (
            <h5 className="font-semibold font-display  my-2">ค่าที่วัดได้</h5>
          )}

          <MeasurementVariableListBox
            selectedProcess={selectedProcess}
            selectedStep={step}
          />
        </CardContent>
      </Card>
    </div>
  );

  const renderResultingFunctionInfo = () => (
    <div>
      {!_.isEmpty(step?.resultingFunctionTypes) && (
        <Card>
          <CardContent>
            {' '}
            <h5 className="font-semibold font-display  my-2">ผลจากการวัด</h5>
            <ul className="list-decimal px-6">
              {_.map(
                step?.resultingFunctionTypes,
                (eachFunction, innerIndex) => (
                  <li key={`rf-${innerIndex}`}>
                    <span>{eachFunction?.name}</span>{' '}
                    {renderFunctionResult(eachFunction)}
                  </li>
                ),
              )}
            </ul>
            <BoxResultingFunction
              currentProcess={selectedProcess}
              currentStepIndex={step?.index}
              displayStepName
            />
          </CardContent>
        </Card>
      )}
    </div>
  );

  return (
    <div className="flex w-full flex-wrap">
      <div className="w-full lg:w-1/3 px-1">{renderMaterialInfo()}</div>{' '}
      <div className="w-full lg:w-2/3 px-1">
        {renderMeasurementInfo()}
        {renderResultingFunctionInfo()}
      </div>
    </div>
  );
};

StepMeasurementAndMaterialDisplayBox.propTypes = {
  manufacturingMaterials: PropTypes.arrayOf(PropTypes.object),
  step: PropTypes.object,
  measurements: PropTypes.arrayOf(PropTypes.object),
  selectedProcess: PropTypes.object,
};

export default StepMeasurementAndMaterialDisplayBox;
