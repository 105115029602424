const MATERIAL_GROUP_OPTION = {
  TAG: {
    status_code: "TAG",
    description: "แทก หรือ Attribute พิเศษ",
  },
  MATERIAL_TYPE: {
    status_code: "MATERIAL_TYPE",
    description: "ประเภทวัตถุดิบ",
  },
};

module.exports = MATERIAL_GROUP_OPTION;
