import React from 'react';
import { Controller } from 'react-hook-form';
import { CAPITAL_COST_TYPE } from '@iarcpsu/emanufac-constant';
import {
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { DesktopDatepicker } from '../../DatePicker';

const FactoryCapitalCostForm = ({ control, watch, defaultValue }) => (
  <Card>
    <CardContent>
      <div className="flex flex-wrap">
        <div className="w-full">
          <Controller
            control={control}
            name="cost_type"
            defaultValue={defaultValue?.cost_type}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel>ชนิดของต้นทุน</InputLabel>
                <Select
                  size="small"
                  label="ชนิดของต้นทุน"
                  fullWidth
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                >
                  {_.map(CAPITAL_COST_TYPE, (value, key) => (
                    <MenuItem key={key} value={value.status_code}>
                      {value.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </div>
        {watch('cost_type') === CAPITAL_COST_TYPE.OTHER.status_code && (
          <div className="w-full my-1">
            <Controller
              control={control}
              name="cost_name"
              defaultValue={defaultValue?.cost_name}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อค่าใช้จ่าย"
                  size="small"
                  fullWidth
                />
              )}
            />
          </div>
        )}
        <div className="w-full my-1">
          <Controller
            control={control}
            name="cost"
            defaultValue={defaultValue?.cost}
            render={({ field }) => (
              <TextField
                {...field}
                label="มูลค่าค่าใช้จ่าย"
                size="small"
                fullWidth
              />
            )}
          />
        </div>
        <div className="w-full md:w-1/2 my-1 pr-1">
          <Controller
            control={control}
            name="start_date"
            defaultValue={defaultValue?.start_date}
            render={({ field }) => (
              <DesktopDatepicker
                fullWidth
                {...field}
                size="small"
                label="วันที่เริ่มต้นมีผล"
              />
            )}
          />
        </div>
        <div className="w-full md:w-1/2 my-1">
          <Controller
            control={control}
            name="end_date"
            defaultValue={defaultValue?.end_date}
            render={({ field }) => (
              <DesktopDatepicker
                fullWidth
                {...field}
                size="small"
                label="วันที่สิ้นสุดการมีผล"
              />
            )}
          />
        </div>
        <div className="w-full my-1">
          <Controller
            control={control}
            name="remark"
            defaultValue={defaultValue?.remark}
            render={({ field }) => (
              <TextField
                {...field}
                label="หมายเหตุ"
                size="small"
                fullWidth
                rows={3}
                multiline
              />
            )}
          />
        </div>
      </div>
    </CardContent>
  </Card>
);

export default FactoryCapitalCostForm;

FactoryCapitalCostForm.propTypes = {
  control: PropTypes.object,
  watch: PropTypes.func,
  defaultValue: PropTypes.object,
};
