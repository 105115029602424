import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardContent } from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  ProductBomBox,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import graphqlClient from '../../../configs/graphqlClient';
import * as actions from '../../../redux/actions';

/**
 * @function SettingViewProductBom
 * @description Display a collections or a list of SettingViewProductBom from database
 */

export default function SettingViewProductBom({ title, subtitle }) {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  const params = useParams();

  const query = gql`
    query FindSettingViewProductBom($productInput: ProductInput) {
      findOneProduct(input: $productInput) {
        _id
        name
        type_code
        bill_of_materials {
          amount
          tag
          material {
            _id
            name
            type_code
            use_unit_conversion
            unit
            conversion_from {
              unit_a
              unit_b
              conversion_factor
            }
            conversion_to {
              unit_b
              unit_a
              conversion_factor
            }
            unit_input {
              _id
              short_sign
              name
            }
            unit_output {
              _id
              short_sign
              name
            }
          }
        }
        use_unit_conversion
        unit
        conversion_from {
          unit_a
          unit_b
          conversion_factor
        }
        conversion_to {
          unit_b
          unit_a
          conversion_factor
        }
        unit_input {
          _id
          short_sign
          name
        }
        unit_output {
          _id
          short_sign
          name
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        productInput: { id: params.id, fetchBOM: true },
      });
      const productData = queryResult?.findOneProduct;
      dispatch(actions.productStateOneSet(productData));
    } catch (err) {
      console.error('Query Error', err);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (product.isLoading) {
    return <Loading />;
  }

  if (!product.isLoading && product.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <BackButton />
        </div>
        <div>
          <Card>
            <CardContent>
              <div className="text-lg font-semibold font-display">
                สินค้า {product?.type_code || ''} {product?.name}
              </div>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-1/2 p-2">
                  <ProductBomBox
                    product={product}
                    selectedUnit={
                      product?.use_unit_conversion
                        ? product?.unit_input?.name
                        : product?.unit
                    }
                  />
                </div>
                {product?.use_unit_conversion && (
                  <div className="w-full lg:w-1/2 p-2">
                    <ProductBomBox
                      product={product}
                      selectedUnit={product?.unit_output?.name}
                      isInverse
                    />
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

SettingViewProductBom.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SettingViewProductBom.defaultProps = {
  title: '',
  subtitle: '',
};
