const MATERIAL_COST_ESTIMATION_METHOD = {
  NONE: {
    status_code: "NONE",
    description: "ไม่คิดต้นทุนวัตถุดิบในขั้นนี้",
  },
  USE_AMOUNT_AND_UNIT_COST: {
    status_code: "USE_AMOUNT_AND_UNIT_COST",
    description: "ใช้จำนวนและราคาต่อหน่วย",
  },
};

const LABOR_COST_ESTIMATION_METHOD = {
  NONE: {
    status_code: "NONE",
    description: "ไม่คิดต้นทุนแรงงานในขั้นนี้",
  },
  TDABC: {
    status_code: "TDABC",
    description: "ต้นทุนกิจกรรมตามเวลา (Time-Driven Activity-Based Costing)",
  },
  LUMPSUM_BY_MATERIAL: {
    status_code: "LUMPSUM_BY_MATERIAL",
    description: "คิดค่าแรงตามวัตถุดิบที่ได้",
  },
  DIVIDE_BY_LOT_WEIGHT: {
    status_code: "DIVIDE_BY_LOT_WEIGHT",
    description: "หารค่าแรงทั้งหมดจากน้ำหนักที่มีในล็อต",
  },
};

const CAPITAL_COST_ESTIMATION_METHOD = {
  NONE: {
    status_code: "NONE",
    description: "ไม่คิดต้นทุนทุนส่วนทุนในขั้นนี้",
  },
  TDABC: {
    status_code: "TDABC",
    description: "ต้นทุนกิจกรรมตามเวลา (Time-Driven Activity-Based Costing)",
  },
  DIVIDE_BY_LOT_WEIGHT: {
    status_code: "DIVIDE_BY_LOT_WEIGHT",
    description: "หารค่าแรงทั้งหมดจากน้ำหนักที่มีในล็อต",
  },
};

module.exports = {
  MATERIAL_COST_ESTIMATION_METHOD,
  LABOR_COST_ESTIMATION_METHOD,
  CAPITAL_COST_ESTIMATION_METHOD,
};
