const PRODUCT_TXN_TYPE = {
  add: {
    status_code: "add",
    description: "เข้า",
  },
  desc: {
    status_code: "desc",
    description: "ออก",
  },
};

module.exports = PRODUCT_TXN_TYPE;
