import _ from 'lodash';

/**
 * @function findParentAndChildLot
 * เป็นการหาว่า Lot ไหนเป็น Lot แรกที่นำเข้ามาเลย (ไม่มี Previous Lot) เราจะเรียกว่า Parent Lot
 * และ Lot อื่นๆที่มี Previous Lot จะเป็น Child Lot ของ Parent Lot
 * โดยจะทำการ Group แต่ละ Parent Lot กับ Child Lot ของมันเข้าด้วยกัน
 */

export default function findParentAndChildLot(previousLotList) {
  // We must split the previousLotList based on first previous_lot
  const parentLot = _.filter(previousLotList, (lot) =>
    _.isEmpty(lot?.previous_lot),
  );

  // In All Previous lot must be grouped by based lot
  const parentWithChildLots = _.map(parentLot, (eachBasedLot) => {
    let childLot = [];
    let currentBasedLot = [eachBasedLot];
    let nextStepLot = [];

    do {
      nextStepLot = [];
      // eslint-disable-next-line no-loop-func
      _.map(currentBasedLot, (eachCurrentBasedLot) => {
        const tempLot = _.filter(previousLotList, (prevLot) => {
          const previousLotIdList = _.map(
            prevLot?.previous_lot,
            (insidePrevLot) => insidePrevLot?._id?.toString(),
          );

          return _.includes(
            previousLotIdList,
            eachCurrentBasedLot?._id?.toString(),
          );
        });
        _.map(tempLot, (eachTempLot) => {
          nextStepLot.push(eachTempLot);
        });
        childLot = [...childLot, ...tempLot];
        //  console.log('Next Step Lot', nextStepLot);
      });
      currentBasedLot = nextStepLot;
      // console.log('New Current Lot', currentBasedLot);
    } while (!_.isEmpty(currentBasedLot));

    // console.log('Child Lot', childLot);
    return {
      ...eachBasedLot,
      child_lot: childLot,
    };
  });

  return parentWithChildLots;
}
