import {
  MAT_SPLITTING_ALL,
  MAT_SPLITTING_DELETE,
  MAT_SPLITTING_ERROR,
  MAT_SPLITTING_GET,
  MAT_SPLITTING_LOADING,
  MAT_SPLITTING_POST,
  MAT_SPLITTING_PUT,
} from '../../actions/types';

const initialState = {
  materialSplitting: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function MatterialSplittingProcessReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case MAT_SPLITTING_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MAT_SPLITTING_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MAT_SPLITTING_POST:
      return { isLoading: false, isCompleted: true };
    case MAT_SPLITTING_PUT:
      return { isLoading: false, isCompleted: true };
    case MAT_SPLITTING_DELETE:
      return { isLoading: false, isCompleted: true };
    case MAT_SPLITTING_LOADING:
      return { isLoading: true, isCompleted: true };
    case MAT_SPLITTING_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
