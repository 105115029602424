import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { useQuery } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';

/**
 * @function MaterialSources
 * @description Display a collections or a list of MaterialSources from database
 */

export default function MaterialSources({ title, subtitle }) {
  const dispatch = useDispatch();
  const browserQuery = useQuery();
  const history = useHistory();
  const materialSource = useSelector((state) => state.materialSource);
  const [page, setPage] = useState(
    browserQuery.get('page') ? parseInt(browserQuery.get('page'), 10) : 1,
  );
  const [size, setSize] = useState(
    browserQuery.get('size') ? parseInt(browserQuery.get('size'), 10) : 10,
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [total, setTotal] = useState();

  const query = gql`
    query FindMaterialSources($materialSourceinput: MaterialSourceInput) {
      findMaterialSources(input: $materialSourceinput) {
        currPage
        lastPage
        total
        rows {
          _id
          type_code
          supplier {
            type_code
            name
          }
          name
          overall_code
          licence_number
          owner
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        materialSourceinput: { page, size, name },
      });
      const materialSourceData = queryResult?.findMaterialSources;
      dispatch(actions.materialSourceStateSet(materialSourceData));
    } catch (error) {
      dispatch(actions.materialSourceError());
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      if (materialSource?.rows) {
        setPage(1);
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, name]);

  useEffect(() => {
    if (materialSource) {
      setTotal(materialSource?.total);
    }

    return () => {};
  }, [materialSource]);

  const handleDelete = async (id) => {
    try {
      const confirm = window.confirm('ยืนยันการลบ');
      if (confirm) {
        await dispatch(actions.materialSourceDelete(id));
      }
      queryDataFromServer();
    } catch (error) {
      alert(`ลบไม่สำเร็จ ${error?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (materialSource.isLoading) {
    return <Loading />;
  }

  if (!materialSource.isLoading && materialSource.isCompleted) {
    return (
      <div>
        <div className="flex justify-between items-center">
          {renderTitle()}
          <div className="flex gap-2">
            <Link to={'/crm/material-source/create'}>
              <Button variant="contained">เพิ่ม</Button>
            </Link>
            <Link to={'/crm/material-source/import'}>
              <Button variant="contained" color="info">
                อัพโหลด
              </Button>
            </Link>
          </div>
        </div>
        <div>
          <Card>
            <div className="p-4 flex flex-row">
              <div className="w-full md:w-1/2">
                <TextField
                  label="ค้นหา"
                  fullWidth
                  size="small"
                  id="outlined-start-adornment"
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <i className="fas fa-search"></i>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </Card>
        </div>
        <div className="my-2">
          <Paper>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="font-bold">ลำดับที่</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">รหัสรวม</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">
                        ชื่อแหล่งวัตถุดิบ / ชื่อเรือ
                      </div>
                    </TableCell>{' '}
                    <TableCell>
                      <div className="font-bold">ผู้ควบคุม / เจ้าของ</div>
                    </TableCell>{' '}
                    <TableCell>
                      <div className="font-bold">คู่ค้า</div>
                    </TableCell>{' '}
                    <TableCell>
                      <div className="font-bold">ทะเบียน</div>
                    </TableCell>{' '}
                    <TableCell>
                      <div className="font-bold">ดำเนินการ</div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!_.isEmpty(materialSource?.rows) ? (
                    _.map(materialSource?.rows, (row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <div className="text-xs">
                            {(page - 1) * size + index + 1}
                          </div>
                        </TableCell>
                        <TableCell>{row?.overall_code}</TableCell>

                        <TableCell>{row?.name}</TableCell>
                        <TableCell>{row?.owner}</TableCell>
                        <TableCell>{row?.supplier?.name}</TableCell>
                        <TableCell>{row?.licence_number}</TableCell>
                        <TableCell>
                          <div className="flex gap-1">
                            {' '}
                            <Link
                              to={`/crm/material-source/detail/${row?._id}`}
                            >
                              <Button
                                size="small"
                                variant="contained"
                                color="info"
                              >
                                รายละเอียด
                              </Button>
                            </Link>
                            <Link to={`/crm/material-source/edit/${row?._id}`}>
                              <Button
                                size="small"
                                variant="contained"
                                color="warning"
                              >
                                แก้ไข
                              </Button>
                            </Link>
                            <Button
                              size="small"
                              color="error"
                              variant="contained"
                              onClick={() => handleDelete(row?._id)}
                            >
                              ลบ
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <div className="text-center">ไม่มีข้อมูล</div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              page={page - 1}
              count={total || 1}
              rowsPerPage={size}
              onPageChange={(event, newPage) => {
                setPage(newPage + 1);
                history.push({
                  search: `?${new URLSearchParams({
                    page: newPage + 1,
                    size,
                  }).toString()}`,
                });
              }}
              onRowsPerPageChange={(event) => {
                setSize(event.target.value);
                setPage(1);
                history.push({
                  search: `?${new URLSearchParams({
                    page: 1,
                    size: event.target.value,
                  }).toString()}`,
                });
              }}
            />
          </Paper>
        </div>
      </div>
    );
  }
  return <Error />;
}

MaterialSources.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MaterialSources.defaultProps = {
  title: '',
  subtitle: '',
};
