import {
  CUSTOMER_COMMENT_ALL,
  CUSTOMER_COMMENT_DELETE,
  CUSTOMER_COMMENT_ERROR,
  CUSTOMER_COMMENT_GET,
  CUSTOMER_COMMENT_LOADING,
  CUSTOMER_COMMENT_POST,
  CUSTOMER_COMMENT_PUT,
} from '../../actions/types';
import { BaseReducer } from '../../class';

const basedReducerObject = new BaseReducer({
  allConst: CUSTOMER_COMMENT_ALL,
  getConst: CUSTOMER_COMMENT_GET,
  postConst: CUSTOMER_COMMENT_POST,
  putConst: CUSTOMER_COMMENT_PUT,
  deleteConst: CUSTOMER_COMMENT_DELETE,
  errorConst: CUSTOMER_COMMENT_ERROR,
  loadingConst: CUSTOMER_COMMENT_LOADING,
});

const initialState = {
  isLoading: true,
  isCompleted: true,
};

const CustomerCommentReducer = (state = initialState, action) =>
  basedReducerObject.getReducer(state, action);

export default CustomerCommentReducer;
