import React, { useRef, useState } from 'react';
import {
  Image as ImageIcon,
  Paperclip,
  Trash as DeleteIcon,
} from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  IconButton,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import BuddhistEra from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';

import 'dayjs/locale/th';

import { ModalUploadFile, ModalUploadImage } from '../../Modals';

dayjs.extend(BuddhistEra);

export default function CommentBox({
  comment,
  processId,
  size,
  setSize,
  showTitle = true,
  container = true,
  handleSuccess = () => {},
}) {
  const dispatch = useDispatch();
  const { control, handleSubmit, reset } = useForm();
  const me = useSelector((state) => state.me);
  const information = useSelector((state) => state.information);
  const [modalImageOpen, setModalImageOpen] = useState(false);
  const [modalFileOpen, setModalFileOpen] = useState(false);
  const [image, setImage] = useState([]);
  const [file, setFile] = useState([]);
  const boxRef = useRef();

  const onSubmit = async (data) => {
    try {
      await dispatch(
        actions.commentCreate({
          ...data,
          image: _.isEmpty(image)
            ? undefined
            : { image: image?.[0]?.data_url, imageType: 'comment', alt: '' },
          file: _.isEmpty(file) ? undefined : file[0],
          employee: me?.userData?._id,
          process: processId,
          employeeName: me?.userData?.firstname,
        }),
      );
      setImage([]);
      setFile([]);
      reset({ message: '' });
      handleSuccess();
    } catch (error) {
      window.alert('ความคิดเห็นไม่สำเร็จ');
      console.error('Comment Unsuccessful', error);
    }
  };

  const handleIncrementSize = () => {
    setSize(size + 5);
  };

  const handleDeleteComment = async (id) => {
    const confirm = window.confirm('ยืนยันการลบความคิดเห็น');
    if (confirm) {
      try {
        await dispatch(actions.commentDelete(id));
        handleSuccess();
      } catch (error) {
        window.alert('ลบความคิดเห็นไม่สำเร็จ');
        console.error('Cannot Delete Comment', error);
      }
    }
  };
  const renderCommentLists = () => (
    <div className="mt-2 min-h-96 overflow-y-auto">
      {_.size(comment?.rows) === 0 && (
        <div className="p-4 text-center">ยังไม่มีความคิดเห็นในการผลิตนี้</div>
      )}
      {size < (comment?.total || 0) && (
        <div>
          <Button onClick={handleIncrementSize}> โหลดเพิ่ม</Button>
        </div>
      )}
      {_.map(comment?.rows, (_comment, index) => (
        <div key={index} className="my-2">
          <div className="flex gap-2 ">
            <Avatar src={_comment?.employee?.image?.url} className="uppercase">
              {_.first(_comment?.employee?.firstname)}
              {_.first(_comment?.employee?.lastname)}
            </Avatar>
            <Card>
              <CardContent>
                <div className="text-xs text-gray-500 flex">
                  <div className="self-center">
                    {' '}
                    <span className="font-bold font-display text-black">
                      {_comment?.employee?.firstname}{' '}
                      {_comment?.employee?.lastname}
                    </span>{' '}
                    <br />
                    เมื่อวันที่{' '}
                    {dayjs(_comment?.updatedAt).format(
                      'D MMM BBBB เวลา HH.mm น.',
                    )}
                  </div>
                  <div className="self-center">
                    {_comment?.employee?._id === me?.userData?._id &&
                      information?.setting?.mms?.allow_remove_comment && (
                        <IconButton
                          color="error"
                          onClick={() => {
                            handleDeleteComment(_comment?._id);
                          }}
                        >
                          <DeleteIcon size={12} />
                        </IconButton>
                      )}
                  </div>
                </div>
                <div className="mt-1 font-system">{_comment?.message}</div>
                {_comment?.image && (
                  <div className="mt-1">
                    <img src={_comment?.image?.url} className="h-48" />
                  </div>
                )}
                {_comment?.file && (
                  <div className="mt-1">
                    <Link href={_comment?.file?.url}>
                      <div className="flex gap-2">
                        <Paperclip size={16} className="self-center" />
                        <Typography className="self-center">
                          {_comment?.file?.file_name}
                        </Typography>
                      </div>
                    </Link>
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
        </div>
      ))}
      <div ref={boxRef}></div>
    </div>
  );

  const renderCommentCreate = () => (
    <div className="mt-4">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="message"
          control={control}
          defaultValue=""
          placeholder="ใส่ข้อความของคุณ"
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label="ความคิดเห็น"
              fullWidth
              required
            />
          )}
        />
        {_.size(image) > 0 && (
          <div className="my-2 ">
            <Link>
              <div className="flex gap-2">
                <ImageIcon size={16} className="self-center" />
                <Typography className="self-center">
                  {image?.[0]?.file?.name}
                </Typography>
                <IconButton color="error" onClick={() => setImage([])}>
                  <DeleteIcon size={16} />
                </IconButton>
              </div>
            </Link>
          </div>
        )}
        {_.size(file) > 0 && (
          <div className="my-2 ">
            <Link>
              <div className="flex gap-2">
                <Paperclip size={16} className="self-center" />
                <Typography className="self-center">
                  {file?.[0]?.path}
                </Typography>
                <IconButton color="error" onClick={() => setFile([])}>
                  <DeleteIcon size={16} />
                </IconButton>
              </div>
            </Link>
          </div>
        )}
        <div className="flex justify-start gap-2 my-2">
          <Button
            type="button"
            size="small"
            onClick={() => setModalFileOpen(true)}
            startIcon={<Paperclip size={16} />}
          >
            ไฟล์
          </Button>
          <Button
            type="button"
            size="small"
            startIcon={<ImageIcon size={16} />}
            onClick={() => setModalImageOpen(true)}
          >
            รูปภาพ
          </Button>
        </div>
        <div className="flex justify-end mt-2 gap-2">
          <Button type="submit" color="primary" variant="contained">
            แสดงความเห็น
          </Button>
        </div>
      </form>
    </div>
  );

  return (
    <div>
      <ModalUploadImage
        isOpen={modalImageOpen}
        handleClose={() => {
          setModalImageOpen(false);
        }}
        images={image}
        setImages={setImage}
      />
      <ModalUploadFile
        isOpen={modalFileOpen}
        files={file}
        setFiles={setFile}
        handleClose={() => setModalFileOpen(false)}
      />
      {container ? (
        <Card>
          <CardContent>
            {showTitle && (
              <div className="font-semibold  font-display text-lg">
                ความคิดเห็น
              </div>
            )}
            {renderCommentLists()}
            {renderCommentCreate()}
          </CardContent>
        </Card>
      ) : (
        <div>
          {showTitle && (
            <div className="font-semibold  font-display text-lg">
              ความคิดเห็น
            </div>
          )}
          {renderCommentLists()}
          {renderCommentCreate()}
        </div>
      )}
    </div>
  );
}

CommentBox.propTypes = {
  comment: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
    currPage: PropTypes.number,
    isLoading: PropTypes.bool,
    isCompleted: PropTypes.bool,

    lastPage: PropTypes.number,
    total: PropTypes.number,
  }),
  processId: PropTypes.string,
  size: PropTypes.number,
  showTitle: PropTypes.bool,
  setSize: PropTypes.func,
  handleSuccess: PropTypes.func,
  container: PropTypes.bool,
};
