import api from '../../../configs/api';
import graphqlClient from '../../../configs/graphqlClient';
import {
  EMPLOYEE_ALL,
  EMPLOYEE_DEL,
  EMPLOYEE_ERROR,
  EMPLOYEE_GET,
  EMPLOYEE_LOADING,
  EMPLOYEE_POST,
  EMPLOYEE_PUT,
  EMPLOYEE_RESET,
} from '../types';

export const employeeCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: EMPLOYEE_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/employee`,
      payload,
    );
    dispatch({ type: EMPLOYEE_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: EMPLOYEE_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const employeeAll = ({
  name = '',
  size = '',
  page = 1,
  selectDepartment = '',
}) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/employee?name=${name}&size=${size}&page=${page}&departmentId=${selectDepartment}`,
    );
    if (status === 200) {
      dispatch({ type: EMPLOYEE_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: EMPLOYEE_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const employeeAllQuery = ({ query }) => async (dispatch) => {
  try {
    const queryResult = await graphqlClient.request(query);
    const data = queryResult?.findEmployees;
    //  console.log('Query Data', queryResult);
    if (!data) {
      dispatch({ type: EMPLOYEE_ERROR });
    }

    dispatch({ type: EMPLOYEE_ALL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: EMPLOYEE_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const employeeStateSet = (data) => async (dispatch) => {
  dispatch({ type: EMPLOYEE_ALL, payload: data });
};

export const employeeStateOneSet = (data) => async (dispatch) => {
  dispatch({ type: EMPLOYEE_GET, payload: data });
};

export const employeeGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/employee/${id}`,
    );
    if (status === 200) {
      dispatch({ type: EMPLOYEE_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: EMPLOYEE_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const employeePut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: EMPLOYEE_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/employee/${id}`,
      payload,
    );
    dispatch({ type: EMPLOYEE_PUT, payload: data });
  } catch (error) {
    console.error(error?.response?.data?.error?.message);
    dispatch({ type: EMPLOYEE_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};
export const employeeDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: EMPLOYEE_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/employee/${id}`,
    );
    dispatch({ type: EMPLOYEE_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: EMPLOYEE_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const employeeReset = () => async (dispatch) => {
  try {
    dispatch({ type: EMPLOYEE_RESET });
  } catch (error) {
    console.error(error);
    dispatch({ type: EMPLOYEE_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const employeeError = () => async (dispatch) => {
  dispatch({ type: EMPLOYEE_ERROR });
};
