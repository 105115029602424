import React, { useEffect, useState } from 'react';
import { ExternalLink } from 'react-feather';
import { Link } from 'react-router-dom';
import { LABOR_COST_ESTIMATION_METHOD } from '@iarcpsu/emanufac-constant';
import {
  currencyFormatter,
  filterAllowanceMaterial,
  getPlaceLaborCostInDay,
  getTotalMaterialUsageOnPlace,
} from '@iarcpsu/emanufac-utils/functions';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { api, config } from '@/configs';

import MultiUnitProdOrMatBox from '../../Rendering/MultiUnitProdOrMatBox';

function LaborCostDivideByWeight({
  previousLotList,
  place,
  currentLot,
  isCurrentAsProductLot,
}) {
  const [totalAllLotWeight, setTotalAllLotWeight] = useState(0);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [totalLaborCost, setTotalLaborCost] = useState(0);

  // ถ้าเป็น Product Stock Lot ดึงข้อมูลจาก inventory_date
  // ถ้าเป็น Material Stock Lot ดึงข้อมูลจาก receipt_date
  const currentDate = isCurrentAsProductLot
    ? currentLot?.inventory_date
    : currentLot?.receipt_date;

  const getTotalWeightAndUnit = async () => {
    try {
      const { total, unit } = await getTotalMaterialUsageOnPlace({
        place,
        currentDate,
        axios: api,
        maxFetchSize: config.maxFetchSize,
        isFrontend: true,
      });
      setTotalAllLotWeight(total);
      setSelectedUnit(unit);
    } catch (error) {
      console.error('Cannot Get Total Weight And Unit', error);
    }
  };

  const getAllLaborCost = async () => {
    try {
      const tempAllLaborCost = await getPlaceLaborCostInDay({
        axios: api,
        currentDate,
        placeId: place?._id,
        isFrontend: true,
        maxFetchSize: config.maxFetchSize,
      });
      setTotalLaborCost(tempAllLaborCost);
    } catch (error) {
      console.error('Cannot Get Total Weight And Unit', error);
    }
  };

  useEffect(() => {
    getTotalWeightAndUnit();
    getAllLaborCost();
    return () => {};
  }, []);

  // หาวัตถุดิบที่อนุญาคิให้อยู่ในคลังนี้
  const allowMaterial = filterAllowanceMaterial(previousLotList, place);
  const costPerWeight = totalLaborCost / totalAllLotWeight;

  const materialListWithCost = _.map(allowMaterial, (each) => {
    const materialCost = each?.quantity * costPerWeight;
    return {
      ...each,
      cost: materialCost,
    };
  });

  return (
    <div className="my-2">
      <div className="flex justify-between">
        <div className="my-2 font-semibold">
          ต้นทุนแรงงาน:{' '}
          {LABOR_COST_ESTIMATION_METHOD.DIVIDE_BY_LOT_WEIGHT.description}
        </div>
        <Link
          to={`/cem/place-labor-cost/by-day?start=${dayjs(currentDate).format(
            'YYYY-MM-DD',
          )}`}
        >
          <Button size="small" variant="outlined" color="warning">
            แก้ไขค่าแรงในคลัง
          </Button>
        </Link>
      </div>
      <div>
        น้ำหนักวัตถุดิบทั้งหมดที่ผลิตได้ใน {place?.name} ของวันที่{' '}
        {dayjs(currentDate).format('D MMM BBBB')}
      </div>
      <div>
        <Link
          to={`/ims/cost-driver/weight-in-place/${currentLot?._id}?date=${dayjs(
            currentDate,
          ).format('YYYY-MM-DD')}`}
          className="flex items-center gap-1 hover:underline hover:text-gray-600"
        >
          <span className="text-lg font-semibold">
            {currencyFormatter.format(totalAllLotWeight)}
          </span>{' '}
          {selectedUnit} <ExternalLink size={16} />
        </Link>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/2">
          <div>ต้นทุนรวมเฉลี่ยของพนักงานในคลังวันนั้น</div>
          <div>
            <span className="text-lg font-semibold">
              {currencyFormatter.format(totalLaborCost)}
            </span>
            บาท
          </div>
        </div>
        <div className="w-full md:w-1/2">
          <div>เฉลี่ยต่อหน่วยวัตถุดิบ</div>{' '}
          <div>
            <span className="text-lg font-semibold">
              {currencyFormatter.format(costPerWeight)}
            </span>
            บาท
          </div>
        </div>
      </div>

      <div className="my-2">
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> เลขอ้างอิงล็อต</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วัตถุดิบ</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">ปริมาณ</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">ต้นทุน</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(materialListWithCost) ? (
                _.map(materialListWithCost, (row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="text-xs">{index + 1}</div>
                    </TableCell>
                    <TableCell>{row?.running_number}</TableCell>
                    <TableCell>{row?.material?.name}</TableCell>
                    <TableCell>
                      <MultiUnitProdOrMatBox
                        foundMaterial={row?.material}
                        noWrap
                        quantity={row?.quantity}
                      />
                    </TableCell>
                    <TableCell>{currencyFormatter.format(row?.cost)}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
              {!_.isEmpty(materialListWithCost) && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <div className="text-center font-semibold">รวม</div>
                  </TableCell>
                  <TableCell>
                    <div>
                      {currencyFormatter.format(
                        _.sumBy(materialListWithCost, 'cost'),
                      )}{' '}
                      บาท
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default LaborCostDivideByWeight;

LaborCostDivideByWeight.propTypes = {
  previousLotList: PropTypes.arrayOf(PropTypes.object),
  place: PropTypes.object,
  currentLot: PropTypes.object,
  isCurrentAsProductLot: PropTypes.bool,
};
