/* eslint-disable import/prefer-default-export */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';

import { MMS, notNan, percentYieldFormat } from '@/utils/functions';

import 'addthaifont-pdfmake/build/vfs_fonts';

import { ReportHeader } from './common';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};

const { manufacMaterialAnalyzedArrayAnalzer } = MMS.MaterialAnalyzer;
const renderProcessStatus = (processInfo) => {
  if (processInfo?.current?.index === -1) {
    return 'ยังไม่เริ่มการผลิต';
  }
  if (processInfo?.completed) {
    return 'ดำเนินงานเสร็จสิ้น';
  }
  if (processInfo?.deleted) {
    return 'ยกเลิกงาน';
  }
  return 'อยู่ระหว่างการดำเนินงาน';
};

const renderWorkStatus = (stepInfo) => {
  if (stepInfo?.completed) {
    return 'สำเร็จ';
  }
  if (stepInfo?.deleted) {
    return 'ยกเลิก';
  }
  if (
    stepInfo?.responsible?.employee &&
    !_.isEmpty(stepInfo?.responsible?.employee)
  ) {
    return 'อยู่ระหว่างการทำงาน';
  }
  if (!stepInfo?.responsible?.employee) {
    return 'รอผู้รับงาน';
  }
  return 'ไม่ทราบสถานะ';
};

const genWorkRunning = (allSteps) =>
  _.map(allSteps, (_step, index) => [
    {
      text: `${index + 1}`,
      colSpan: 1,
      fontSize: 10,
      border: [true, true, true, true],
    },
    {
      stack: [
        `${_step?.name || '-'}`,
        {
          text: `แผนก${_step?.responsible?.department?.name || '-'}`,
          bold: true,
          fontSize: 8,
        },
      ],

      colSpan: 1,
      fontSize: 10,
      border: [true, true, true, true],
    },
    {
      text: `${_step?.responsible?.employee?.firstname || '-'} ${
        _step?.responsible?.employee?.lastname || ''
      }`,
      colSpan: 1,
      fontSize: 10,
      border: [true, true, true, true],
    },
    {
      text: `${dayjs(_step?.createdAt).format('D MMM BBBB')}  ${dayjs(
        _step?.createdAt,
      ).format('HH:mm น.')}`,
      colSpan: 1,
      fontSize: 10,
      border: [true, true, true, true],
    },
    {
      text: `${
        _step?.completed ? dayjs(_step?.updatedAt).format('D MMM BBBB  ') : '-'
      } 
      ${_step?.completed ? dayjs(_step?.updatedAt).format('HH:mm น. ') : ''}
      `,
      colSpan: 1,
      fontSize: 10,
      border: [true, true, true, true],
    },
    {
      text: renderWorkStatus(_step),
      bold: true,
      colSpan: 1,
      fontSize: 10,
      border: [true, true, true, true],
    },
  ]);

export const ProcessReport = (
  processInfo,
  manufacturingOrder,
  information,
  isError,
) => {
  const workData = genWorkRunning(processInfo?.steps);

  const genMaterialUsage = () =>
    _.map(
      _.filter(
        manufacMaterialAnalyzedArrayAnalzer(
          processInfo.manufacturing_materials,
        ),
        (_material) => _material?.materialInfo?._id !== undefined,
      ),
      (_material, index) => [
        {
          text: `${index + 1}`,
          colSpan: 1,
          fontSize: 10,
          border: [true, true, true, true],
        },
        {
          text: `${_material?.materialInfo?.type_code}  ${_material?.materialInfo?.name}`,
          colSpan: 1,
          fontSize: 10,
          border: [true, true, true, true],
        },

        {
          text: `${notNan(_material?.requestAmount)}  `,
          colSpan: 1,
          fontSize: 10,
          border: [true, true, true, true],
        },
        {
          text: `${notNan(_material?.returnAmount)}  `,
          colSpan: 1,
          fontSize: 10,
          border: [true, true, true, true],
        },
        {
          text: `${notNan(_material?.usedAmount)}  `,
          colSpan: 1,
          fontSize: 10,
          border: [true, true, true, true],
        },
        {
          text: `${notNan(_material?.resultAmount)}  `,
          colSpan: 1,
          fontSize: 10,
          border: [true, true, true, true],
        },
        {
          text: `${notNan(_material?.wastesAmount)}  `,
          colSpan: 1,
          fontSize: 10,
          border: [true, true, true, true],
        },
        {
          text: `${percentYieldFormat(_material?.yield)} %`,
          colSpan: 1,
          fontSize: 10,
          border: [true, true, true, true],
        },
        {
          text: `${_material?.materialInfo?.unit} `,
          colSpan: 1,
          fontSize: 10,
          border: [true, true, true, true],
        },
      ],
    );

  const genProductresult = () => {
    const { product_stock_lots: productStockLots = [{}] } = processInfo;

    return _.map(productStockLots, (_stocklot, index) => [
      {
        text: `${index + 1} `,
        colSpan: 1,
        fontSize: 10,
        border: [true, true, true, true],
      },
      {
        text: `${_stocklot?.product?.type_code} ${_stocklot?.product?.name} `,
        colSpan: 1,
        fontSize: 10,
        border: [true, true, true, true],
      },
      {
        text: 'สินค้า',
        colSpan: 1,
        fontSize: 10,
        border: [true, true, true, true],
      },
      {
        text: `${_stocklot?.lot_number || '-'} `,
        colSpan: 1,
        fontSize: 10,
        border: [true, true, true, true],
      },
      {
        text: `${_stocklot?.warehouse?.name || '-'} `,
        colSpan: 1,
        fontSize: 10,
        border: [true, true, true, true],
      },
      {
        text: `${dayjs(_stocklot?.production_date).format('D MMM BBBB')} `,
        colSpan: 1,
        fontSize: 10,
        border: [true, true, true, true],
      },
      {
        text: `${
          _stocklot?.expiration_date
            ? dayjs(_stocklot?.expiration_date).format('D MMM BBBB')
            : ''
        } `,
        colSpan: 1,
        fontSize: 10,
        border: [true, true, true, true],
      },
    ]);
  };

  const genProductAsMaterialResult = () => {
    const { product_as_materials: productAsMaterials = [{}] } = processInfo;

    return _.map(productAsMaterials, (_stocklot, index) => [
      {
        text: `${index + (_.size(processInfo?.product_stock_lots) || 0) + 1} `,
        colSpan: 1,
        fontSize: 10,
        border: [true, true, true, true],
      },
      {
        text: `${_stocklot?.material?.type_code} ${_stocklot?.material?.name} `,
        colSpan: 1,
        fontSize: 10,
        border: [true, true, true, true],
      },
      {
        text: 'วัตถุดิบ',
        colSpan: 1,
        fontSize: 10,
        border: [true, true, true, true],
      },
      {
        text: `${_stocklot?.lot_number || '-'} `,
        colSpan: 1,
        fontSize: 10,
        border: [true, true, true, true],
      },
      {
        text: `${_stocklot?.place?.name || '-'} `,
        colSpan: 1,
        fontSize: 10,
        border: [true, true, true, true],
      },
      {
        text: `${dayjs(_stocklot?.production_date).format('D MMM BBBB')} `,
        colSpan: 1,
        fontSize: 10,
        border: [true, true, true, true],
      },
      {
        text: `${
          _stocklot?.expiration_date
            ? dayjs(_stocklot?.expiration_date).format('D MMM BBBB')
            : ''
        } `,
        colSpan: 1,
        fontSize: 10,
        border: [true, true, true, true],
      },
    ]);
  };

  const genMaterialSource = () => {
    const { material_transactions: materialTransactions = [] } = processInfo;
    let index = 0;
    const transactions = _.map(materialTransactions, (_transaction) =>
      _.map(_transaction?.material_stock_lot, (_stocklot) => {
        // eslint-disable-next-line no-plusplus
        index++;
        return [
          {
            text: `${index} `,
            colSpan: 1,
            fontSize: 10,
            border: [true, true, true, true],
          },
          {
            text: `${_stocklot?.lotId?.material?.type_code} ${_stocklot?.lotId?.material?.name} `,
            colSpan: 1,
            fontSize: 10,
            border: [true, true, true, true],
          },
          {
            text: `${_stocklot?.quantity} ${_stocklot?.lotId?.material?.unit} `,
            colSpan: 1,
            fontSize: 10,
            border: [true, true, true, true],
          },
          {
            text: `${_stocklot?.lotId?.lot_number || '-'} `,
            colSpan: 1,
            fontSize: 10,
            border: [true, true, true, true],
          },
          {
            text: `${_stocklot?.lotId?.bill_number || '-'} `,
            colSpan: 1,
            fontSize: 10,
            border: [true, true, true, true],
          },
          {
            text: `${
              _stocklot?.lotId?.production_date
                ? dayjs(_stocklot?.lotId?.production_date).format('D MMM BBBB')
                : '-'
            } `,
            colSpan: 1,
            fontSize: 10,
            border: [true, true, true, true],
          },
          {
            text: `${
              _stocklot?.lotId?.expiration_date
                ? dayjs(_stocklot?.lotId?.expiration_date).format('D MMM BBBB')
                : '-'
            }`,
            colSpan: 1,
            fontSize: 10,
            border: [true, true, true, true],
          },
        ];
      }),
    );

    return _.flatten(transactions);
  };

  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 30, 40, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '16',
    },
    info: {
      title: 'สถานะไลน์การผลิตสินค้า',
    },
    content: [
      isError ? ReportHeader(information, false) : ReportHeader(information),
      {
        // หัวข้อ
        alignment: 'center',
        margin: [0, 5, 0, 10],
        text: 'สถานะไลน์การผลิตสินค้า',
        fontSize: '16',
        bold: true,
      },
      {
        style: 'tableExample',
        margin: [0, 5, 0, 10],

        table: {
          widths: ['13%', '37%', '13%', '37%'],
          heights: [15],
          body: [
            [
              {
                text: 'สินค้า',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: ` ${
                  processInfo?.produce_material_instead
                    ? processInfo?.product_as_material?.type_code
                    : processInfo?.product?.type_code
                } ${
                  processInfo?.produce_material_instead
                    ? processInfo?.product_as_material?.name
                    : processInfo?.product?.name || '-'
                }`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: 'จำนวน',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: ` ${
                  processInfo?.quantity !== 0
                    ? processInfo?.quantity || '-'
                    : '-'
                } ${
                  processInfo?.quantity !== 0
                    ? processInfo?.additional_unit ||
                      (processInfo?.produce_material_instead
                        ? processInfo?.product_as_material?.unit
                        : processInfo?.product?.unit) ||
                      '-'
                    : ''
                }`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'เลขที่คำสั่งผลิต',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: ` ${manufacturingOrder?.running_number || ''} `,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: 'สถานะ',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: renderProcessStatus(processInfo),
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        // หัวข้อย่อย
        margin: [0, 10, 0, 5],
        text: 'การใช้วัตถุดิบ',
        fontSize: 12,
        bold: true,
      },
      {
        margin: [0, 5, 0, 0],
        style: 'tableExample',
        table: {
          widths: ['10%', '*', '10%', '10%', '10%', '10%', '10%', '10%', '10%'],
          heights: [15],
          headerRows: 1,
          body: [
            [
              {
                text: 'ลำดับที่',
                bold: true,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: 'วัตถุดิบ',
                bold: true,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: 'เบิกมา',
                bold: true,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: 'คืน',
                bold: true,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: 'ใช้จริง',
                bold: true,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: 'ได้ผลผลิต',
                bold: true,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: 'ของเสีย',
                bold: true,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: 'Yield',
                bold: true,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: 'หน่วย',
                bold: true,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, true],
              },
            ],
            ...genMaterialUsage(),
          ],
        },
      },
      {
        // หัวข้อย่อย
        margin: [0, 10, 0, 5],
        text: 'แหล่งที่มาของวัตถุดิบ',
        fontSize: 12,
        bold: true,
      },
      {
        margin: [0, 5, 0, 0],
        style: 'tableExample',
        table: {
          widths: ['10%', '*', '10%', '10%', '10%', '15%', '15%'],
          heights: [15],
          headerRows: 1,
          body: [
            [
              {
                text: 'ลำดับที่',
                bold: true,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: 'วัตถุดิบ',
                bold: true,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: 'ปริมาณ',
                bold: true,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: 'หมายเลขล็อต',
                bold: true,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: 'เลขที่บิล',
                bold: true,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: 'วันผลิต',
                bold: true,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: 'วันหมดอายุ',
                bold: true,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, true],
              },
            ],
            ...genMaterialSource(),
          ],
        },
      },

      _.isEmpty(processInfo?.product_stock_lots) &&
      _.isEmpty(processInfo?.product_as_materials)
        ? {}
        : {
            // หัวข้อย่อย
            margin: [0, 10, 0, 5],
            text: 'ผลผลิตที่ได้',
            fontSize: 12,
            bold: true,
          },
      _.isEmpty(processInfo?.product_stock_lots) &&
      _.isEmpty(processInfo?.product_as_materials)
        ? {}
        : {
            margin: [0, 5, 0, 0],
            style: 'tableExample',
            table: {
              widths: ['8%', '*', '10%', '15%', '15%', '15%', '15%'],
              heights: [15],
              headerRows: 1,
              body: [
                [
                  {
                    text: 'ลำดับที่',
                    bold: true,
                    alignment: 'center',
                    colSpan: 1,
                    fontSize: 10,
                    border: [true, true, true, true],
                  },
                  {
                    text: 'ผลผลิต',
                    alignment: 'center',
                    bold: true,
                    colSpan: 1,
                    fontSize: 10,
                    border: [true, true, true, true],
                  },
                  {
                    text: 'ชนิด',
                    alignment: 'center',
                    bold: true,
                    colSpan: 1,
                    fontSize: 10,
                    border: [true, true, true, true],
                  },
                  {
                    text: 'หมายเลขล็อต',
                    alignment: 'center',
                    bold: true,
                    colSpan: 1,
                    fontSize: 10,
                    border: [true, true, true, true],
                  },
                  {
                    text: 'คลัง',
                    alignment: 'center',
                    bold: true,
                    colSpan: 1,
                    fontSize: 10,
                    border: [true, true, true, true],
                  },
                  {
                    text: 'วันผลิต',
                    alignment: 'center',
                    bold: true,
                    colSpan: 1,
                    fontSize: 10,
                    border: [true, true, true, true],
                  },
                  {
                    text: 'วันหมดอายุ',
                    bold: true,
                    alignment: 'center',
                    colSpan: 1,
                    fontSize: 10,
                    border: [true, true, true, true],
                  },
                ],
                ...genProductresult(),
                ...genProductAsMaterialResult(),
              ],
            },
          },
      {
        // หัวข้อย่อย
        margin: [0, 10, 0, 5],
        text: 'กระบวนการทำงาน',
        fontSize: 12,
        bold: true,
      },
      {
        margin: [0, 5, 0, 0],
        style: 'tableExample',
        table: {
          widths: ['8%', '*', '19%', '15%', '15%', '12%'],
          heights: [15],
          headerRows: 1,
          body: [
            [
              {
                text: 'ลำดับที่',
                bold: true,
                alignment: 'center',
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: 'งาน',
                alignment: 'center',
                bold: true,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: 'ผู้รับผิดชอบ',
                alignment: 'center',
                bold: true,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: 'วันที่สั่งงาน',
                alignment: 'center',
                bold: true,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: 'วันที่สิ้นสุด',
                alignment: 'center',
                bold: true,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: 'สถานะงาน',
                bold: true,
                alignment: 'center',
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, true],
              },
            ],
            ...workData,
          ],
        },
      },
    ],
    images: {
      logo: information?.logo?.url,
    },
  };
  pdfMake.createPdf(docDefinition).open();
};
