import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import {
  Button,
  Card,
  TextField,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import Loading from '@iarcpsu/emanufac-components/src/components/Loading';
import { Error } from '@iarcpsu/emanufac-components/src/components/Error';
import { OrderTable } from '@iarcpsu/emanufac-components/src/components/Tables';
import { ViewTitle } from '@iarcpsu/emanufac-components/src/components/ViewTitle';
import * as CONFIG from '../../utils/constants';
import { ORDER_STATUS } from '../../utils/constants';

const OrdersManagement = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.order);
  const information = useSelector((state) => state.information);
  const history = useHistory();
  const { control } = useForm();

  // Setting React hook form

  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [statusCode, setStatusCode] = useState('');

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        await dispatch(
          actions.orderAll({
            name,
            page,
            size,
            statusCode,
          }),
        );
        dispatch(actions.informationAll());
      } catch (error) {
        console.error(error);
      }
    };

    fetchOrder();

    return () => {};
  }, [name, page, size, statusCode]);

  useEffect(() => {
    setTotal(orders?.total);
    return () => {};
  }, [orders]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleDelete = async (id) => {
    const confirm = window.confirm('ยืนยันการยกเลิกคำสั่งซื้อ');
    if (confirm) {
      try {
        const data = {
          status: CONFIG.ORDER_STATUS.ORDER_CANCEL.status_code,
        };
        await dispatch(actions.orderPut(id, data));
        await dispatch(actions.orderAll({ name, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleSuccess = async (id) => {
    const confirm = window.confirm('ยืนยันการส่งมอบสินค้า');
    if (confirm) {
      try {
        const data = {
          status: CONFIG.ORDER_STATUS.ORDER_SUCCESS.status_code,
        };
        await dispatch(actions.orderPut(id, data));
        await dispatch(actions.orderAll({ name, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handlePending = async (id) => {
    const confirm = window.confirm('ยืนยันการเตรียมสินค้า');
    if (confirm) {
      try {
        const data = {
          status: CONFIG.ORDER_STATUS.ORDER_PENDING.status_code,
        };
        await dispatch(actions.orderPut(id, data));
        await dispatch(actions.orderAll({ name, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handlePacking = async (id) => {
    history.push(`order/decrease/${id}`);
  };

  const handlePushToEditPage = async (id) => {
    history.push(`order/edit/${id}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeStatus = (status) => {
    setStatusCode(status);
  };

  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4">
      <Link to="/spm/order/create">
        <Button variant="contained">เพิ่ม</Button>
      </Link>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row gap-2">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/2">
          <Controller
            name={'status_code'}
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                <InputLabel id="type" size={'small'}>
                  ค้นหาด้วยสถานะ
                </InputLabel>
                <Select
                  {...field}
                  label="ค้นหาด้วยสถานะ"
                  size={'small'}
                  fullWidth
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    handleChangeStatus(e.target.value);
                  }}
                >
                  <MenuItem value="">ทั้งหมด</MenuItem>
                  {_.map(ORDER_STATUS, (_ORDER_STATUS) => (
                    <MenuItem
                      value={_ORDER_STATUS?.status_code}
                      key={_ORDER_STATUS?.status_code}
                    >
                      {_ORDER_STATUS?.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <OrderTable
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleDelete={handleDelete}
      handlePushToEditPage={handlePushToEditPage}
      handleSuccess={handleSuccess}
      handlePending={handlePending}
      handlePacking={handlePacking}
      orders={orders}
      page={page}
      size={size}
      total={total}
      information={information}
    />
  );

  if (orders.isLoading) {
    return <Loading />;
  }
  if (!orders.isLoading && orders.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButtom()}</div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error message={orders?.message} />;
};

OrdersManagement.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

OrdersManagement.defaultProps = {
  title: '',
  subtitle: '',
};

export default OrdersManagement;
