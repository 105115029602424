import {
  CUSTOMER_RATING_ALL,
  CUSTOMER_RATING_DELETE,
  CUSTOMER_RATING_ERROR,
  CUSTOMER_RATING_GET,
  CUSTOMER_RATING_LOADING,
  CUSTOMER_RATING_POST,
  CUSTOMER_RATING_PUT,
} from '../../actions/types';
import { BaseReducer } from '../../class';

const basedReducerObject = new BaseReducer({
  allConst: CUSTOMER_RATING_ALL,
  getConst: CUSTOMER_RATING_GET,
  postConst: CUSTOMER_RATING_POST,
  putConst: CUSTOMER_RATING_PUT,
  deleteConst: CUSTOMER_RATING_DELETE,
  errorConst: CUSTOMER_RATING_ERROR,
  loadingConst: CUSTOMER_RATING_LOADING,
});

const initialState = {
  isLoading: true,
  isCompleted: true,
};

const CustomerRatingReducer = (state = initialState, action) =>
  basedReducerObject.getReducer(state, action);

export default CustomerRatingReducer;
