/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar';
import { withErrorBoundary } from 'react-error-boundary';
import { Card } from '@mui/material';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import th from 'date-fns/locale/th';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import PropTypes from 'prop-types';

import ManufacturingOrderToEvent from '@/utils/functions/ManufacturingOrderToEvent';

import Error from '../../Error/Error';

import CalendarColorLegend from './CalendarColorLegend';

import '../../../assets/styles/calendar.css';

const ManufacturingOrderCalendar = ({
  periodText,
  manufacturingOrder,
  setCurrentDay = () => {},
  currentDay,
}) => {
  const locales = {
    th,
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (manufacturingOrder.rows) {
      setEvents(ManufacturingOrderToEvent(manufacturingOrder.rows));
    }

    return () => {};
  }, [manufacturingOrder]);

  const eventStyleGetter = (event) => {
    let backgroundColor = '#D6EAF8';
    if (event.type === 'pending') {
      backgroundColor = '#2541B2';
    } else if (event.type === 'deleted') {
      backgroundColor = '#FECACA';
    } else if (event.type === 'late') {
      backgroundColor = '#ffae2a';
    } else if (event.type === 'completed') {
      backgroundColor = '#10B981';
    }
    const style = {
      backgroundColor,
      borderRadius: '4px',
      opacity: 0.8,
      xx: 'white',
      border: '0px',
      display: 'block',
    };
    return {
      style,
    };
  };

  // console.log('Events', events);

  return (
    <div>
      <div className="flex flex-wrap  gap-4">
        <div className="w-full">
          <Card className="p-6">
            <div className="flex flex-wrap">
              <div className="w-full font-display">
                <div>คำสั่งผลิต{periodText}</div>
                <div className="text-5xl text-right p-4">
                  {manufacturingOrder.total || 0}{' '}
                  <span className="text-xl px-4">รายการ</span>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap my-4">
              <div className="w-full">
                <div className="font-display">
                  ตารางปฏิทินคำสั่งผลิต{periodText}
                </div>
                <CalendarColorLegend />
                <div>
                  <div style={{ height: '200vh' }}>
                    <Calendar
                      culture={'th'}
                      localizer={localizer}
                      events={events}
                      views={{ month: true, week: true }}
                      startAccessor="start"
                      endAccessor="end"
                      eventPropGetter={eventStyleGetter}
                      style={{ height: '200vh' }}
                      dayLayoutAlgorithm="no-overlap"
                      popup={true}
                      defaultView={Views.MONTH}
                      defaultDate={currentDay}
                      onNavigate={(selectedDate) => {
                        console.log('Selected Date', selectedDate);
                        setCurrentDay(selectedDate);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </Card>
        </div>
      </div>
    </div>
  );
};

const MMSDashboardWithBoundary = withErrorBoundary(ManufacturingOrderCalendar, {
  fallback: <Error />,
  onError(error, info) {
    // Do something with the error
    // E.g. log to an error logging client here
    console.error(error);
  },
});

export default MMSDashboardWithBoundary;

ManufacturingOrderCalendar.propTypes = {
  periodText: PropTypes.string,
  manufacturingOrder: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
    total: PropTypes.number,
  }),

  employee: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
    total: PropTypes.number,
  }),
  setCurrentDay: PropTypes.func,
  currentDay: PropTypes.instanceOf(Date),
};
