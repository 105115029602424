import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  BackButton,
  Error,
  FactoryCapitalCostForm,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import * as actions from '@/redux/actions';

/**
 * @function CreatePriceLaborCost
 * @description Display a collections or a list of CreatePriceLaborCost from database
 */

export default function EditFactoryCapitalCost({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const factoryCapitalCost = useSelector((state) => state.factoryCapitalCost);
  const { handleSubmit, watch, control, setValue } = useForm({});

  useEffect(() => {
    dispatch(actions.factoryCapitalCostGet(params.id));

    return () => {};
  }, [params]);

  const handleCreatePlaceLaborCost = async (data) => {
    try {
      await dispatch(actions.factoryCapitalCostPut(params.id, data));
      Swal.fire({
        icon: 'success',
        title: 'แก้ไขรายการสำเร็จ',
      }).then(() => {
        history.goBack();
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'ไม่สามารถแก้ไขรายการได้',
        text: error?.message,
      });
    }
  };

  useEffect(() => {
    if (factoryCapitalCost && !factoryCapitalCost?.rows) {
      setValue('cost_type', factoryCapitalCost?.cost_type);
    }

    return () => {};
  }, [factoryCapitalCost]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (factoryCapitalCost?.isLoading || factoryCapitalCost?.rows) {
    return <Loading />;
  }
  if (factoryCapitalCost?.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <form onSubmit={handleSubmit(handleCreatePlaceLaborCost)}>
            <FactoryCapitalCostForm
              control={control}
              watch={watch}
              defaultValue={factoryCapitalCost}
            />
            <div className="flex justify-end my-2">
              <Button type="submit" variant="contained">
                บันทึก
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return <Error />;
}

EditFactoryCapitalCost.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditFactoryCapitalCost.defaultProps = {
  title: '',
  subtitle: '',
};
