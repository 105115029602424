import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  Error,
  Loading,
  MultiUnitProdOrMatBox,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import graphqlClient from '../../../configs/graphqlClient';
import * as actions from '../../../redux/actions';

/**
 * @function BomTemplates
 * @memberof MMS/Setting
 * @description รายการ Template สำหรับ Bill of Material / รายการวัตถุดิบส่วนผสม
 * ที่สร้างมาเหมือนเป็น Template ของสินค้าใดก็ได้ จะคล้ายกับ Product BOM แต่ ProductBOM
 * จะเปนของส้นค้าชิ้นใดชิ้นหนึ่ง
 */

export default function BomTemplates({ title, subtitle }) {
  const dispatch = useDispatch();
  const bomTemplate = useSelector((state) => state.bomTemplate);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [total, setTotal] = useState();

  const query = gql`
    query FindBomTemplates($bomTemplateInput: BomTemplateInput) {
      findBomTemplates(input: $bomTemplateInput) {
        total
        rows {
          _id
          name
          bill_of_materials {
            amount
            tag
            material {
              _id
              name
              type_code
              use_unit_conversion
              unit
              conversion_from {
                unit_a
                unit_b
                conversion_factor
              }
              conversion_to {
                unit_b
                unit_a
                conversion_factor
              }
              unit_input {
                _id
                short_sign
                name
              }
              unit_output {
                _id
                short_sign
                name
              }
            }
          }
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    const queryResult = await graphqlClient.request(query, {
      bomTemplateInput: { page, size, name },
    });
    const bomTemplateData = queryResult?.findBomTemplates;
    dispatch(actions.bomTemplateStateSet(bomTemplateData));
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size]);

  useEffect(() => {
    setTotal(bomTemplate?.total);

    return () => {};
  }, [bomTemplate]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleChangePage = (event, newValue) => {
    setPage(newValue + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const hadleRemoveBomTemplate = async (selectedId) => {
    try {
      await dispatch(actions.bomTemplateDelete(selectedId));
      queryDataFromServer();
      alert('สำเร็จ');
    } catch (error) {
      alert(`ลบสูตรส่วนผสมไม่สำเร็จ ${error?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderSearch = () => (
    <Card className="my-2">
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );
  if (bomTemplate.isLoading) {
    return <Loading />;
  }
  if (!bomTemplate.isLoading && bomTemplate.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          {renderTitle()}
          <div className="self-center">
            <Link to="bom-template/create">
              <Button variant="contained">เพิ่ม</Button>
            </Link>
          </div>
        </div>
        {renderSearch()}
        <div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className="font-bold">ลำดับที่</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold">ชื่อสูตรส่วนผสม</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold">ส่วนผสม</div>
                  </TableCell>{' '}
                  <TableCell>
                    <div className="font-bold">ดำเนินการ</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!_.isEmpty(bomTemplate?.rows) ? (
                  _.map(bomTemplate?.rows, (row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="text-xs">{index + 1}</div>
                      </TableCell>
                      <TableCell>{row?.name}</TableCell>
                      <TableCell>
                        {' '}
                        {!_.isEmpty(row?.bill_of_materials) &&
                          _.map(
                            _.take(row?.bill_of_materials, 5),
                            (eachMaterial, materialIndex) => (
                              <div
                                key={`mat${materialIndex}`}
                                className="lg:flex gap-2"
                              >
                                <div>
                                  {materialIndex + 1}.{' '}
                                  {eachMaterial?.material?.name}{' '}
                                </div>
                                <MultiUnitProdOrMatBox
                                  foundMaterial={eachMaterial?.material}
                                  noWrap
                                  quantity={eachMaterial?.amount}
                                />
                              </div>
                            ),
                          )}
                        {_.size(row?.bill_of_materials) > 5 && (
                          <div>
                            และอีก {_.size(row?.bill_of_materials) - 5} รายการ
                          </div>
                        )}
                        {_.isEmpty(row?.bill_of_materials) && (
                          <div>ไม่มีรายการส่วนผสม</div>
                        )}
                      </TableCell>
                      <TableCell>
                        <div className="flex gap-1">
                          <Link to={`bom-template/view/${row?._id}`}>
                            <Button size="small" variant="contained">
                              รายละเอียด
                            </Button>
                          </Link>
                          <Link to={`bom-template/edit/${row?._id}`}>
                            <Button
                              size="small"
                              variant="contained"
                              color="warning"
                            >
                              แก้ไข
                            </Button>
                          </Link>
                          <Button
                            size="small"
                            variant="contained"
                            color="error"
                            onClick={() => hadleRemoveBomTemplate(row?._id)}
                          >
                            ลบ
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <div className="text-center">ไม่มีข้อมูล</div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>{' '}
              <TablePagination
                page={page - 1}
                count={total || 1}
                rowsPerPage={size}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />{' '}
            </Table>
          </TableContainer>
        </div>
      </div>
    );
  }
  return <Error />;
}

BomTemplates.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

BomTemplates.defaultProps = {
  title: '',
  subtitle: '',
};
