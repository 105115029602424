import dayjs from 'dayjs';
import _ from 'lodash';

import { MATERIAL_STOCK_STATUS } from '../../constants';

const groupByMaterialPayload = ({
  unNullLotList,
  place,
  nonRestrictPlace,
  keyByDate,
  keyByMaterial,
  keyBySource,
  eachByMaterial,
  keyByProcess = null,
  groupByMaterialTypeQuantity,
}) => {
  // Create Action According to Condition in  Place
  let action = '';
  const detail = `/ims/many-material-lots?lots=${_.map(
    _.uniq(unNullLotList),
    (each) => each,
  )}&place=${place?._id}`;
  // console.log('Lot List', unNullLotList);
  if (place?.place_action?.enable) {
    if (nonRestrictPlace) {
      if (
        place?.place_action?.for_stranger?.move &&
        place?.place_action?.for_stranger?.transform
      ) {
        // TODO: To be Implemented
        action = `/ims/fixed-transform?source=${place?._id}&lots=${_.map(
          _.uniq(unNullLotList),
          (each) => each,
        )}&inplace=true&fixedoutput=true`;
      }
      // Only Move
      else if (place?.place_action?.for_stranger?.move) {
        action = `/ims/fixed-move?source=${place?._id}&lots=${_.map(
          _.uniq(unNullLotList),
          (each) => each,
        )}&destination=${
          place?.place_action?.destination_place?._id
        }&forcemove=true`;
      }
      // Only Transform
      else if (place?.place_action?.for_stranger?.transform) {
        action = `/ims/fixed-transform?source=${place?._id}&lots=${_.map(
          _.uniq(unNullLotList),
          (each) => each,
        )}&inplace=true&fixedoutput=true`;
      }
    } else if (place?.place_action?.move && place?.place_action?.transform) {
      action = `/ims/fixed-transform?source=${place?._id}&lots=${_.map(
        _.uniq(unNullLotList),
        (each) => each,
      )}&destination=${
        place?.place_action?.destination_place?._id
      }&forcemove=true&fixedoutput=true`;
    } else if (place?.place_action?.move) {
      action = `/ims/fixed-move?source=${place?._id}&lots=${_.map(
        _.uniq(unNullLotList),
        (each) => each,
      )}&destination=${
        place?.place_action?.destination_place?._id
      }&forcemove=true&move-after-action=true`;
    } else if (place?.place_action?.add_to_product_warehouse) {
      action = `/ims/fixed-move-transform-warehouse?source=${
        place?._id
      }&lots=${_.map(_.uniq(unNullLotList), (each) => each)}&destination=${
        place?.place_action?.destination_warehouse
      }&forcemove=true`;
    }
  } else {
    action = null;
  }

  // ปรับ Key ให้ผลรวมของแต่ละหมวดหมู่ โดยใส่ _total ต่อท้ายด้วยชื่อหมวดหมู่
  const sumByCategory = _.reduce(
    groupByMaterialTypeQuantity,
    (result, value, key) => {
      result[`${key}_total`] = value || 0;
      return result;
    },
    {},
  );

  // จำนวนวัตถุดิบของขั้นก่อนหน้า (ของล็อตที่เป็นบรรพบุรุษของล็อตนี้)
  const previousAmount = _.sumBy(eachByMaterial, 'previous_amount');

  // หา Yiled ของแต่ละหมวหมู่ โดยสร้าง Key ใหม่เป็นชื่อของหมวดหมู่ ต่อท้ายด้วย _yield
  const yieldByCategory = _.reduce(
    groupByMaterialTypeQuantity,
    (result, value, key) => {
      result[`${key}_yield`] = (value / previousAmount) * 100;
      return result;
    },
    {},
  );

  // หาปริมาณวัตถุดิบที่ผลิตได้ ออกมาเป็น List
  const quantityList = _.map(
    groupByMaterialTypeQuantity,
    (each) => each?.quantity,
  );

  // หาปริมาณที่ผลิตได้ทั้งหมด
  const totalProducedQuantity = _.sum(quantityList);

  const payload = {
    date: keyByDate,
    source: keyBySource,
    material: keyByMaterial,
    material_id: eachByMaterial?.[0]?._id,
    material_type: eachByMaterial?.[0]?.material_type,
    quantity: _.sumBy(eachByMaterial, 'quantity'),
    amount: _.sumBy(eachByMaterial, 'amount'),
    detail,
    action: _.sumBy(eachByMaterial, 'amount') > 0 ? action : null,
    status:
      _.sumBy(eachByMaterial, 'amount') > 0
        ? MATERIAL_STOCK_STATUS.IN_STOCK.status_code
        : MATERIAL_STOCK_STATUS.OUT_STOCK.status_code,
    process: keyByProcess,
    previous_amount: previousAmount,
    // ให้มี Record ของผลรวมและ Yield ด้วย
    // โดบในแต่ละ Record ของวัตถุดิบ ผลรวมตามหมวดหมู่จะเท่ากัน
    ...(sumByCategory || {}),
    ...(yieldByCategory || {}),
    totalProduct: totalProducedQuantity,
    remainder: previousAmount - totalProducedQuantity,
  };

  return payload;
};

export const generateMaterialByMaterialSource = ({
  materialStockLotBySource,
  material,
  isTotalBySource,
  place,
  filterOutZero,
  nonRestrictPlace = false,
  divideByProcess = false,
}) => {
  const result = [];

  if (nonRestrictPlace) {
    _.map(materialStockLotBySource, (eachStockLot) => {
      if (!_.isEmpty(eachStockLot?.rows)) {
        _.map(eachStockLot?.rows, (insideLot) => {
          let action = null;
          if (place?.place_action?.enable) {
            if (
              place?.place_action?.for_stranger?.move &&
              place?.place_action?.for_stranger?.transform
            ) {
              // TODO: To be Implemented Durable Action Selection
              action = `/ims/fixed-transform?source=${place?._id}&lots=${insideLot?._id}&inplace=true&fixedoutput=true`;
            } else if (place?.place_action?.for_stranger?.move) {
              action = `/ims/fixed-move?source=${place?._id}&lots=${insideLot?._id}&destination=${place?.place_action?.destination_place?._id}&forcemove=true`;
            } else if (place?.place_action?.for_stranger?.transform) {
              action = `/ims/fixed-transform?source=${place?._id}&lots=${insideLot?._id}&inplace=true&fixedoutput=true`;
            } else {
              action = null;
            }
          }
          if (divideByProcess) {
            const payload = {
              // ใช้ Purchase Date เพราะเป็นเลขที่จะถูกยกไปเรื่อยๆ
              //  ตั้งแต่ล็อตแรก จนถึงล็อตเป้าหมาย
              date: dayjs(insideLot?.purchase_date).format('MM/DD'),
              source: eachStockLot?.source?.overall_code,
              material: insideLot?.material?.name,
              quantity: insideLot?.quantity,
              amount: insideLot?.amount,
              lot: insideLot?._id,
              runningNumber: insideLot?.running_number,
              status:
                insideLot?.amount > 0
                  ? MATERIAL_STOCK_STATUS.IN_STOCK.status_code
                  : MATERIAL_STOCK_STATUS.OUT_STOCK.status_code,
              action: insideLot?.amount > 0 ? action : null,
              process: insideLot?.process?.running_number,
            };
            result.push(payload);
          } else {
            const payload = {
              // ใช้ Purchase Date เพราะเป็นเลขที่จะถูกยกไปเรื่อยๆ
              //  ตั้งแต่ล็อตแรก จนถึงล็อตเป้าหมาย
              date: dayjs(insideLot?.purchase_date).format('MM/DD'),
              source: eachStockLot?.source?.overall_code,
              material: insideLot?.material?.name,
              quantity: insideLot?.quantity,
              amount: insideLot?.amount,
              lot: insideLot?._id,
              runningNumber: insideLot?.running_number,
              status:
                insideLot?.amount > 0
                  ? MATERIAL_STOCK_STATUS.IN_STOCK.status_code
                  : MATERIAL_STOCK_STATUS.OUT_STOCK.status_code,
              action: insideLot?.amount > 0 ? action : null,
            };
            result.push(payload);
          }
        });
      }
    });
  } else {
    // console.log('Material Stocklot By Source', materialStockLotBySource);
    _.map(materialStockLotBySource, (eachStockLot) => {
      _.map(material, (eachMaterial) => {
        const foundStockLot = _.filter(
          eachStockLot?.rows,
          (each) => each?.material?._id === eachMaterial?._id,
        );

        if (!_.isEmpty(foundStockLot)) {
          _.map(foundStockLot, (insideLot) => {
            let action = '';
            if (place?.place_action?.enable) {
              if (place?.place_action?.move && place?.place_action?.transform) {
                // TODO: To be Implemented
              } else if (place?.place_action?.move) {
                action = `/ims/fixed-move?source=${place?._id}&lots=${insideLot?._id}&destination=${place?.place_action?.destination_place?._id}&forcemove=true&move-after-action=true`;
              } else if (place?.place_action?.add_to_product_warehouse) {
                action = `/ims/fixed-move-transform-warehouse?source=${place?._id}&lots=${insideLot?._id}&destination=${place?.place_action?.destination_warehouse}&forcemove=true`;
              } else {
                action = null;
              }
              const payload = {
                // ใช้ Purchase Date เพราะเป็นเลขที่จะถูกยกไปเรื่อยๆ
                // ตั้งแต่ล็อตแรก จนถึงล็อตเป้าหมาย
                date: dayjs(insideLot?.purchase_date).format('MM/DD'),
                source: eachStockLot?.source?.overall_code,
                material: eachMaterial?.name,
                material_type: eachMaterial?.material_type?.name,
                material_id: eachMaterial?._id,
                quantity: insideLot?.quantity,
                amount: insideLot?.amount,
                lot: insideLot?._id,
                process: insideLot?.process?.running_number,
                status:
                  insideLot?.amount > 0
                    ? MATERIAL_STOCK_STATUS.IN_STOCK.status_code
                    : MATERIAL_STOCK_STATUS.OUT_STOCK.status_code,
                runningNumber: insideLot?.running_number,
                detail: `/ims/material-stock-lot/${insideLot?._id}&place=${place?._id}`,
                action: insideLot?.amount > 0 ? action : null,
                previous_amount: _.sumBy(insideLot?.previous_lot, 'amount'),
              };
              result.push(payload);
            }
          });
        }
      });
    });
  }

  if (!isTotalBySource) {
    if (filterOutZero) {
      return result;
    }

    if (nonRestrictPlace) {
      return result;
    }

    // While Over Material Again for find which has no lot
    _.map(material, (eachMaterial) => {
      const foundMaterialInGroup = _.find(
        result,
        (each) => each?.material_id === eachMaterial?._id,
      );
      if (!foundMaterialInGroup) {
        const emptyPayload = {
          date: result?.[0]?.date || '-',
          source: result?.[0]?.source || '-',
          material: eachMaterial?.name,
          material_id: eachMaterial?._id,
          material_type: eachMaterial?.material_type?.name,
          quantity: 0,
          amount: 0,
          action: null,
          detail: null,
        };
        result.push(emptyPayload);
      }
    });
    return result;
  }

  const groupByDateResult = _.groupBy(result, 'date');
  // console.log('Result', groupByDateResult);
  const groupResult = [];

  _.map(groupByDateResult, (value, keyByDate) => {
    const groupBySourceResult = _.groupBy(value, 'source');
    if (divideByProcess) {
      _.map(groupBySourceResult, (valueBySource, keyBySource) => {
        const groupByProcessResult = _.groupBy(valueBySource, 'process');
        _.map(groupByProcessResult, (valueByProcess, keyByProcess) => {
          const groupByMaterialType = _.groupBy(
            valueByProcess,
            'material_type',
          );

          const groupByMaterialTypeQuantity = _.reduce(
            groupByMaterialType,
            (reduceResult, reduceValue, key) => {
              reduceResult[key] = _.sumBy(reduceValue, 'quantity');
              return reduceResult;
            },
            {},
          );

          const groupByMaterial = _.groupBy(valueByProcess, 'material');
          // console.log('Group By Material Result', groupByMaterial);
          const lotList = [];
          _.map(groupByMaterial, (eachByMaterial) => {
            _.map(
              _.filter(eachByMaterial, (each) => each?.lot !== null),
              (each) => {
                lotList.push(each?.lot);
              },
            );
          });

          const unNullLotList = _.filter(lotList, (each) => each);

          _.map(groupByMaterial, (eachByMaterial, keyByMaterial) => {
            const payload = groupByMaterialPayload({
              eachByMaterial,
              keyByDate,
              keyByMaterial,
              keyBySource,
              nonRestrictPlace,
              place,
              unNullLotList,
              keyByProcess,
              groupByMaterialTypeQuantity,
            });

            if (payload.quantity > 0) {
              groupResult.push(payload);
            }
          });
        });
      });
    } else {
      _.map(groupBySourceResult, (valueBySource, keyBySource) => {
        const groupByMaterialType = _.groupBy(valueBySource, 'material_type');

        const groupByMaterialTypeQuantity = _.reduce(
          groupByMaterialType,
          (reduceResult, reduceValue, key) => {
            reduceResult[key] = _.sumBy(reduceValue, 'quantity');
            return reduceResult;
          },
          {},
        );
        const groupByMaterial = _.groupBy(valueBySource, 'material');
        // console.log('Group By Material Result', groupByMaterial);
        const lotList = [];
        _.map(groupByMaterial, (eachByMaterial) => {
          _.map(
            _.filter(eachByMaterial, (each) => each?.lot !== null),
            (each) => {
              lotList.push(each?.lot);
            },
          );
        });

        const unNullLotList = _.filter(lotList, (each) => each);

        _.map(groupByMaterial, (eachByMaterial, keyByMaterial) => {
          const payload = groupByMaterialPayload({
            eachByMaterial,
            keyByDate,
            keyByMaterial,
            keyBySource,
            nonRestrictPlace,
            place,
            unNullLotList,
            groupByMaterialTypeQuantity,
          });
          groupResult.push(payload);
        });
      });
    }
  });
  // console.log('groupResult', groupResult);

  if (filterOutZero) {
    return groupResult;
  }

  if (nonRestrictPlace) {
    return groupResult;
  }

  // While Over Material Again for find which has no lot
  _.map(material, (eachMaterial) => {
    const foundMaterialInGroup = _.find(
      groupResult,
      (each) => each?.material_id === eachMaterial?._id,
    );
    if (!foundMaterialInGroup) {
      const emptyPayload = {
        date: groupResult?.[0]?.date || '-',
        source: groupResult?.[0]?.source || '-',
        material: eachMaterial?.name,
        material_id: eachMaterial?._id,
        material_type: eachMaterial?.material_type?.name,
        quantity: 0,
        amount: 0,
        action: null,
        detail: null,
      };
      groupResult.push(emptyPayload);
    }
  });

  return groupResult;
};

export default generateMaterialByMaterialSource;
