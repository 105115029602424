import {
  MEASUREMENT_TYPE_ALL,
  MEASUREMENT_TYPE_GET,
  MEASUREMENT_TYPE_POST,
  MEASUREMENT_TYPE_PUT,
  MEASUREMENT_TYPE_DELETE,
  MEASUREMENT_TYPE_ERROR,
  MEASUREMENT_TYPE_LOADING,
} from '../../actions/types';

const initialState = {
  measurementType: null,
  isLoading: true,
  isCompleted: true,
};

export default function MeasurementTypeReducer(state = initialState, action) {
  switch (action.type) {
    case MEASUREMENT_TYPE_LOADING:
      return { isLoading: true, isCompleted: true };
    case MEASUREMENT_TYPE_ERROR:
      return { isLoading: false, isCompleted: false, ...action.payload };
    case MEASUREMENT_TYPE_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MEASUREMENT_TYPE_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MEASUREMENT_TYPE_POST:
      return { isLoading: false, isCompleted: true };
    case MEASUREMENT_TYPE_PUT:
      return { isLoading: false, isCompleted: true };
    case MEASUREMENT_TYPE_DELETE:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
