import api from '../../../configs/api';
import graphqlClient from '../../../configs/graphqlClient';
import {
  PRODUCT_ALL,
  PRODUCT_DEL,
  PRODUCT_ERROR,
  PRODUCT_GET,
  PRODUCT_LOADING,
  PRODUCT_POST,
  PRODUCT_PUT,
} from '../types';

export const productCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/product`,
      {
        ...payload,
      },
    );
    dispatch({ type: PRODUCT_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_ERROR });
    throw new Error(error);
  }
};

export const productAll = ({
  name = '',
  size = 2000,
  page = 1,
  stockStatus = '',
  warehouse = '',
  statusCode = '',
  selectProductTypeType = '',
  fetchStockLot = false,
  orderByField = 'type_code',
  orderBy = 'desc',
  fetchBOM = '',
}) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/product?name=${name}&size=${size}&page=${page}&status=${stockStatus}&warehouse=${warehouse}&statusCode=${statusCode}&selectProductTypeType=${selectProductTypeType}&fetchStockLot=${fetchStockLot}&orderBy=${orderBy}&orderByField=${orderByField}&fetchBOM=${fetchBOM}`,
    );
    if (status === 200) {
      dispatch({ type: PRODUCT_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_ERROR });
    throw new Error(error);
  }
};

export const productAllQuery = ({ query }) => async (dispatch) => {
  try {
    const queryResult = await graphqlClient.request(query);
    const data = queryResult?.findProducts;
    //  console.log('Query Data', queryResult);
    if (!data) {
      dispatch({ type: PRODUCT_ERROR });
    }

    dispatch({ type: PRODUCT_ALL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_ERROR });
    throw new Error(error);
  }
};

export const productStateSet = (data) => async (dispatch) => {
  dispatch({ type: PRODUCT_ALL, payload: data });
};

export const productStateOneSet = (data) => async (dispatch) => {
  dispatch({ type: PRODUCT_GET, payload: data });
};

export const productReset = () => async (dispatch) => {
  dispatch({ type: PRODUCT_LOADING });
};

export const productError = () => async (dispatch) => {
  dispatch({ type: PRODUCT_ERROR });
};

export const productGet = (
  id,
  { fetchStockLot = false, warehouseId = '' } = {},
) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_LOADING });
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/product/${id}?fetchStockLot=${fetchStockLot}&warehouse=${warehouseId}`,
    );
    if (status === 200) {
      dispatch({ type: PRODUCT_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_ERROR });
    throw new Error(error);
  }
};

export const productPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/product/${id}`,
      payload,
    );
    dispatch({ type: PRODUCT_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_ERROR });
    throw new Error(error);
  }
};
export const productDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/product/${id}`,
    );
    dispatch({ type: PRODUCT_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_ERROR });
    throw new Error(error);
  }
};
