const MQ_TASK = {
  UPDATE_MATERIAL_REDIS: {
    status_code: "UPDATE_MATERIAL_REDIS",
    description:
      "Update this material data in Redis to be as same as on Database",
  },
  UPDATE_MO_REDIS_CREATE: {
    status_code: "UPDATE_MO_REDIS_CREATE",
    description:
      "Update Manufacturing Order on Redis when New Manufacturing Order is Created",
  },
  UPDATE_MO_REDIS_EDIT: {
    status_code: "UPDATE_MO_REDIS_EDIT",
    description:
      "Update Manufacturing Order on Redis when New Manufacturing Order is edited/updated",
  },
  UPDATE_MO_REDIS_SUCCESS_DELETE: {
    status_code: "UPDATE_MO_REDIS_SUCCESS_DELETE",
    description:
      "Update Manufacturing Order on Redis when New Manufacturing Order is success or deleted",
  },
  UPDATE_STEP_REDIS: {
    status_code: "UPDATE_STEP_REDIS",
    description: "Update Redis Data for Step",
  },
  UPDATE_EMPLOYEE_QUEUE: {
    status_code: "UPDATE_EMPLOYEE_QUEUE",
    description: "Update Redis Step that for each Employee",
  },
  INSERT_MANUFAC_ORDER: {
    status_code: "INSERT_MANUFAC_ORDER",
    description: "Insert Manufacturing Order",
  },
  UPDATE_MANUFAC_ORDER: {
    status_code: "UPDATE_MANUFAC_ORDER",
    description: "Update Manufacturing Order",
  },
  WITHDRAW_MATERIAL_WITH_LOT: {
    status_code: "WITHDRAW_MATERIAL_WITH_LOT",
    description: "Withdraw Material with specific stock lot Id",
  },
  WITHDRAW_MATERIAL_FIFO: {
    status_code: "WITHDRAW_MATERIAL_FIFO",
    description:
      "Withdraw Material with out specific stock lot Id using First In First Out",
  },
  RETURN_MATERIAL: {
    status_code: "RETURN_MATERIAL",
    description: "Return Material to stock",
  },
  INSERT_MATERIAL: {
    status_code: "INSERT_MATERIAL",
    description: "Insert Material into Material Inventory",
  },
  MANUFACTURING_WITHDRAW_MATERIAL_WITH_LOT: {
    status_code: "MANUFACTURING_WITHDRAW_MATERIAL_WITH_LOT",
    description: "Withdraw Material in Manufacturing Process with Stocklot",
  },
  MANUFACTURING_WITHDRAW_MATERIAL_FIFO: {
    status_code: "MANUFACTURING_WITHDRAW_MATERIAL_FIFO",
    description:
      "Withdraw Material in Manufacturing Process First In First Out",
  },
  MANUFACTURING_MATERIAL_REQUEST: {
    status_code: "MANUFACTURING_MATERIAL_REQUEST",
    description: "Create Material Request on Manufacturing Process",
  },
  REQUEST_ACCESS_TOKEN: {
    status_code: "REQUEST_ACCESS_TOKEN",
    description: "Create Acess token to Line For anyone",
  },
  NOTIFY_DIRECT: {
    status_code: "NOTIFY_DIRECT",
    description: "Notify to one Person",
  },
  NOTIFY_OVER_DEPARTMENT: {
    status_code: "NOTIFY_OVER_DEPARTMENT",
    description: "Create Notify to everyone in department",
  },
  NOTIFY_OVER_MODULE: {
    status_code: "NOTIFY_OVER_MODULE",
    description: "Create Notify to everyone who take a part in module",
  },
  CREATE_STEP_FROM_PROCESS: {
    status_code: "CREATE_STEP_FROM_PROCESS",
    description: "Create list of step from process",
  },
  MOVING_STEP_UP: {
    status_code: "MOVING_STEP_UP",
    description: "Moving to next step",
  },
  UPDATE_PROCESS: {
    status_code: "UPDATE_PROCESS",
    description: "Update Process info or/and delete and complete",
  },
  UPLOAD_MATERIAL_WITH_STOCKLOT: {
    status_code: "UPLOAD_MATERIAL_WITH_STOCKLOT",
    description: "Upload Material With Stocklot",
  },
  SYSTEM_INITIALIZE: {
    status_code: "SYSTEM_INITIALIZE",
    description: "System Intialization",
  },
  RECIEVE_MATERIAL: {
    status_code: "RECIEVE_MATERIAL",
    description: "Recieve Material Direct in Manufacturing Process",
  },
  REMOVE_TRANSACTION: {
    status_code: "REMOVE_TRANSACTION",
    description: "Remove Transaction",
  },
  RECIEVE_MINOR_MATERIAL: {
    status_code: "RECIEVE_MINOR_MATERIAL",
    description: "Recieve Material Direct in Manufacturing Process",
  },
  AUDIT_MANUFACTURING_ORDER: {
    status_code: "AUDIT_MANUFACTURING_ORDER",
    description:
      "Audit Manufacturing Order which is completed or deleted to manufacturing order log",
  },
  AUDIT_PROCESS: {
    status_code: "AUDIT_PROCESS",
    description: "Audit Process which is completed or deleted to process log",
  },
  AUDIT_STEP: {
    status_code: "AUDIT_STEP",
    description: "Audit Step which is completed or deleted to step log",
  },
  MOVE_MATERIAL: {
    status_code: "MOVE_MATERIAL",
    description: "Move Material from one place to another place",
  },
  PROCESS_MORE_SPLITTING: {
    status_code: "PROCESS_MORE_SPLITTING",
    description:
      "แยกโพรเซสหรือไลน์การผลิตเพิ่ม ที่นอกเหนือจากในหน้า HandleNextStep และนอกเหนือจากฟังก์ชัน Moving Step Up",
  },
  SPLIT_PROCESS_CLOSE: {
    status_code: "SPLIT_PROCESS_CLOSE",
    description: "ใส่วัตถุดิบลงคลังและปิดการผลิต",
  },
  TRANSFORM_MATERIAL: {
    status_code: "TRANSFORM_MATERIAL",
    description: "เปลี่ยนวัตถุดิบจากชนิดหนึ่งเป็นอีกชนิดหนึ่ง",
  },
  FORCE_AUDIT_MANUFACTURING_ORDER: {
    status_code: "FORCE_AUDIT_MANUFACTURING_ORDER",
    description:
      "Audit Manufacturing Order which is from selected Date to manufacturing order log",
  },
  FORCE_AUDIT_STEP: {
    status_code: "FORCE_AUDIT_STEP",
    description: "Audit Step which is from selected Date to step log",
  },
  FORCE_MOVE_MM: {
    status_code: "FORCE_MOVE_MM",
    description:
      "Force Move Manufacturing Material to Manufacturing Material log",
  },
  TRANSFORM_MATERIAL_TO_PRODUCT: {
    status_code: "TRANSFORM_MATERIAL_TO_PRODUCT",
    description: "ย้ายและแปลงวัตถุดิบเป็นสินค้า",
  },
  TRANSFORM_PRODUCT_TO_MATERIAL: {
    status_code: "TRANSFORM_PRODUCT_TO_MATERIAL",
    description: "ย้ายและแปลงสินค้ากลับเป็นวัตถุดิบ",
  },
  WITHDRAW_PRODUCT_LOT: {
    status_code: "WITHDRAW_PRODUCT_LOT",
    description: "ดึงสินค้าออกเป็นล็อต",
  },
  AUDIT_MATERIAL_LOT_DURATION: {
    status_code: "AUDIT_MATERIAL_LOT_DURATION",
    description: "เพิ่มเวลาไปยังล็อตวัตถุดิบ",
  },
  AUDIT_MATERIAL_LOT_SUPPLIER: {
    status_code: "AUDIT_MATERIAL_LOT_SUPPLIER",
    description: "ปรับปรุง Supplier และ Ancestor ใน Lot วัตถุดิบ",
  },
};

module.exports = MQ_TASK;
