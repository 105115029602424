/* eslint-disable no-return-assign */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import OneResultingFunctionBox from './OneResultingFunctionBox';

const BoxResultingFunction = ({
  currentProcess,
  currentStepIndex = null,
  displayStepName = true,
}) => {
  let processMeasurement = [];
  _.map(
    currentProcess?.steps,
    (eachStep) =>
      (processMeasurement = _.concat(
        processMeasurement,
        eachStep?.measurements,
      )),
  );

  let processResultingFunction = [];
  _.map(
    currentProcess?.steps,
    (eachStep) =>
      (processResultingFunction = _.concat(
        processResultingFunction,
        eachStep?.resultingFunctionTypes,
      )),
  );

  let selectedSteps = currentProcess?.steps;
  if (currentStepIndex !== null) {
    selectedSteps = _.filter(
      currentProcess?.steps,
      (eachStep) => eachStep?.index === currentStepIndex,
    );
  }
  //  console.log('Process Measurement', processMeasurement);
  return _.map(selectedSteps, (eachStep, index) => {
    if (!_.isEmpty(eachStep?.resultingFunctionTypes)) {
      return (
        <div key={index} className="my-2">
          {displayStepName && (
            <div className="text-base font-semibold font-display bg-gray-200 p-2 rounded-md">
              {eachStep?.name}{' '}
            </div>
          )}
          <div className="flex flex-wrap my-2">
            {_.map(
              eachStep?.resultingFunctionTypes,
              (eachFunction, innerIndex) => (
                <OneResultingFunctionBox
                  key={`in-${innerIndex}`}
                  eachFunction={eachFunction}
                  processMeasurement={processMeasurement}
                  processResultingFunction={processResultingFunction}
                />
              ),
            )}
          </div>
        </div>
      );
    }
    return <div key={index}></div>;
  });
};

BoxResultingFunction.propTypes = {
  currentProcess: PropTypes.object,
  currentStepIndex: PropTypes.number,
  displayStepName: PropTypes.bool,
};

export default BoxResultingFunction;
