import React, { useEffect, useState } from 'react';
import { Avatar } from '@mui/material';
import PropTypes from 'prop-types';

import api from '@/configs/api';

import NameBox from '../../Box/Common/NameBox';

const PersonGauge = ({ measurementData }) => {
  const [selectedEmployee, setSelectedEmployee] = useState();

  const getCurrentPerson = async () => {
    try {
      const { data } = await api.get(
        `${process.env.REACT_APP_API_URL}/employee/${measurementData?.person_value}`,
      );
      setSelectedEmployee(data);
    } catch (error) {
      console.log('ไม่จบพนักงาน ', error);
    }
  };
  useEffect(() => {
    if (measurementData?.person_value) {
      getCurrentPerson();
    }

    return () => {};
  }, [measurementData?.person_value]);

  return (
    <div>
      <div className="text-xl font-semibold">
        {selectedEmployee ? (
          <div className="flex">
            <Avatar src={selectedEmployee?.image?.url} />
            <div>
              {selectedEmployee?.firstname} {selectedEmployee?.lastname}
            </div>
          </div>
        ) : (
          '-'
        )}
      </div>
    </div>
  );
};

PersonGauge.propTypes = {
  measurementData: PropTypes.object,
};

export default PersonGauge;
