import React from 'react';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import PropTypes from 'prop-types';

import 'dayjs/locale/th';

import MultiUnitProdOrMatBox from '../Rendering/MultiUnitProdOrMatBox';

dayjs.extend(buddhistEra);
dayjs.locale('th');

const MaterialSourceBox = ({ selectedProcess, information }) => {
  const { material_transactions: materialTransactions = [] } = selectedProcess;
  const { setting: { ims: imsSetting } = {} } = information;

  const renderDetailData = (_stocklot, index, sIndex) => (
    <div className="my-2 w-full lg:w-1/2 px-1" key={`${index}-${sIndex}`}>
      <div className="border p-4 rounded-md shadow-md">
        <div className="font-semibold font-display">
          {' '}
          {_stocklot?.lotId?.material?.type_code}{' '}
          {_stocklot?.lotId?.material?.name}{' '}
        </div>
        <div>
          {' '}
          <MultiUnitProdOrMatBox
            foundMaterial={_stocklot?.lotId?.material}
            quantity={_stocklot?.quantity}
            noWrap
          />
        </div>
        <div className="flex flex-wrap my-2">
          <div className="w-full ">
            <div className="text-sm font-semibold font-display">เลขอ้างอิง</div>
            <div>{_stocklot?.lotId?.running_number || '-'}</div>
          </div>
          {imsSetting?.display?.lot_number && (
            <div className="w-full md:w-1/2">
              <div className="text-sm font-semibold font-display">
                หมายเลขล็อต
              </div>
              <div>{_stocklot?.lotId?.lot_number || '-'}</div>
            </div>
          )}

          <div className="w-full md:w-1/2">
            {' '}
            <div className="text-sm font-semibold font-display">เลขที่บิล</div>
            <div>{_stocklot?.lotId?.bill_number || '-'}</div>
          </div>
        </div>
        <div className="flex flex-wrap my-2">
          {imsSetting?.display?.production_date && (
            <div className="w-full md:w-1/2">
              <div className="text-sm font-semibold font-display">วันผลิต</div>
              <div>
                {_stocklot?.lotId?.production_date
                  ? dayjs(_stocklot?.lotId?.production_date).format(
                      'D MMM BBBB',
                    )
                  : '-'}
              </div>
            </div>
          )}
          {imsSetting?.display?.expiration_date && (
            <div className="w-full md:w-1/2">
              {' '}
              <div className="text-sm font-semibold font-display">
                วันหมดอายุ
              </div>
              <div>
                {' '}
                {_stocklot?.lotId?.expiration_date
                  ? dayjs(_stocklot?.lotId?.expiration_date).format(
                      'D MMM BBBB',
                    )
                  : '-'}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div style={{ maxHeight: '512px' }} className=" overflow-y-auto mr-4">
      {_.map(materialTransactions, (_transaction, index) => (
        <div className="px-2 my-5" key={index}>
          <div className="w-full">
            <div className="text-sm self-center">
              <b> เบิกครั้งที่</b> {index + 1} <b>วันที่</b>{' '}
              {dayjs(_transaction?.createdAt).format('D MMMM BBBB HH:mm')}{' '}
            </div>
          </div>
          <div className="flex flex-wrap my-2 w-full">
            {_.map(_transaction?.material_stock_lot, (_stocklot, sIndex) =>
              renderDetailData(_stocklot, index, sIndex),
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

MaterialSourceBox.propTypes = {
  selectedProcess: PropTypes.object,
  information: PropTypes.object,
};

export default MaterialSourceBox;
