import React from 'react';
import { Controller } from 'react-hook-form';
import { INVENTORY_WORKFLOW } from '@iarcpsu/emanufac-constant';
import {
  Card,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

const WorkflowCostSelectForm = ({ control, pamsSetting, localization }) => (
  <Card className="p-4">
    <div className="flex flex-wrap w-full ">
      <div className="w-full py-2 px-2 font-semibold font-display ">
        ตั้งค่า Workflow ที่มีส่วนประกอบของต้นทุน
      </div>

      <div className="w-1/3 py-2 px-2">Workflow</div>
      <div className="w-2/3 py-2 px-2">
        <div className="flex flex-col">
          {_.map(INVENTORY_WORKFLOW, (each) => (
            <Controller
              control={control}
              name={`setting.pams.wbm.costed_procedure.${each.status_code}`}
              defaultValue={
                pamsSetting?.wbm?.costed_procedure?.[each?.status_code]
              }
              render={({ field }) => (
                <div className="flex gap-2">
                  <Checkbox
                    {...field}
                    defaultChecked={field.value}
                    label={each.description}
                  />
                  <span className="font-semibold">{each?.status_code}</span>
                  {each.description}
                </div>
              )}
            />
          ))}
        </div>
      </div>

      <div className="w-full py-2 px-2 font-semibold font-display ">
        ตังค่าข้อความที่ใช้แทน
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <div className="font-bold">รหัส</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">ชื่อกระบวนการดั้งเดิม</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">ชื่อที่จะใช้</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(INVENTORY_WORKFLOW, (row, index) => (
              <TableRow
                key={index}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  <div className="text-xs">{row?.status_code}</div>
                </TableCell>
                <TableCell>{row?.description}</TableCell>
                <TableCell>
                  <Controller
                    control={control}
                    name={`localization.${row.status_code}`}
                    defaultValue={localization?.[row.status_code]}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="ชื่อที่จะใช้"
                        fullWidth
                        size="small"
                      />
                    )}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  </Card>
);

WorkflowCostSelectForm.propTypes = {
  control: PropTypes.object,
  pamsSetting: PropTypes.object,
  localization: PropTypes.object,
};

export default WorkflowCostSelectForm;
