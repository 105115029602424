import React from 'react';
import { ChevronDown, ChevronUp, Trash } from 'react-feather';
import { Controller, useFieldArray } from 'react-hook-form';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { PRODUCTION_TYPE } from '@/utils/constants';

export function MOTempalteForm({
  errors,
  control,
  watch,
  moTemplate,
  product,
  material,
  processTemplate,
  setValue,
  baseProject,
  warehouse,
  bomTemplate,
  place,
}) {
  const { fields, append, move, remove } = useFieldArray({
    control,
    name: 'processes',
  });

  const renderEachProcess = (selectedField, index) => (
    <div
      key={selectedField?.id}
      className="flex flex-wrap border rounded-md p-2"
    >
      <div className="w-1/12 px-1 self-center">
        ลำดับ
        <div className="text-xl font-semibold text-center">{index + 1}</div>
      </div>
      <div className="w-9/12 px-1">
        <div>
          <div className="py-2">ประเภทของสิ่งที่ต้องการจะผลิต</div>
          <Controller
            control={control}
            name={`processes[${index}].production_type`}
            defaultValue={moTemplate?.[index]?.production_type}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel>ประเภทของสิ่งที่ต้องการจะผลิต</InputLabel>
                <Select
                  {...field}
                  fullWidth
                  label="ประเภทของสิ่งที่ต้องการจะผลิต"
                  size="small"
                >
                  <MenuItem>กรุณาเลือก</MenuItem>
                  {_.map(PRODUCTION_TYPE, (each, ptindex) => (
                    <MenuItem key={ptindex} value={each?.type_code}>
                      {each?.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </div>
        <div className="my-2">
          {!watch(`processes[${index}].no_product`) && (
            <div>
              {watch(`processes[${index}].production_type`) ===
                PRODUCTION_TYPE.MATERIAL.type_code && (
                <Controller
                  control={control}
                  name={`processes[${index}].product_as_material`}
                  defaultValue={
                    moTemplate
                      ? moTemplate?.processes?.[index]?.product_as_material
                      : null
                  }
                  render={({ field }) => (
                    <div>
                      {!_.isEmpty(material?.rows) && (
                        <Autocomplete
                          size={'small'}
                          disableClearable
                          defaultValue={field?.value}
                          {...field}
                          onChange={(e, newValue) => {
                            field.onChange(newValue);
                          }}
                          options={material?.rows}
                          getOptionLabel={(option) => {
                            if (option?.type_code) {
                              return `${option?.type_code} | ${option?.name}`;
                            }
                            return `${option?.name}`;
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="วัตถุดิบ" />
                          )}
                        />
                      )}
                    </div>
                  )}
                />
              )}
              {watch(`processes[${index}].production_type`) ===
                PRODUCTION_TYPE.PRODUCT.type_code && (
                <Controller
                  control={control}
                  name={`processes[${index}].product`}
                  defaultValue={
                    moTemplate ? moTemplate?.processes?.[index]?.product : null
                  }
                  render={({ field }) => (
                    <div>
                      {!_.isEmpty(product?.rows) && (
                        <Autocomplete
                          size={'small'}
                          disableClearable
                          {...field}
                          onChange={(e, newValue) => {
                            field.onChange(newValue);
                          }}
                          defaultValue={field?.value}
                          options={product?.rows}
                          getOptionLabel={(option) => {
                            if (option?.type_code) {
                              return `${option?.type_code} | ${option?.name}`;
                            }
                            return `${option?.name}`;
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="สินค้า" />
                          )}
                        />
                      )}
                    </div>
                  )}
                />
              )}

              {watch(`processes[${index}].production_type`) ===
                PRODUCTION_TYPE.BASED_PROJECT.type_code && (
                <Controller
                  control={control}
                  name={`processes[${index}].based_project`}
                  defaultValue={
                    moTemplate
                      ? moTemplate?.processes?.[index]?.based_project
                      : null
                  }
                  render={({ field }) => (
                    <div>
                      {!_.isEmpty(baseProject?.rows) && (
                        <Autocomplete
                          size={'small'}
                          disableClearable
                          {...field}
                          onChange={(e, newValue) => {
                            field.onChange(newValue);
                          }}
                          defaultValue={field?.value}
                          options={baseProject?.rows}
                          getOptionLabel={(option) => {
                            if (option?.type_code) {
                              return `${option?.type_code} | ${option?.name}`;
                            }
                            return `${option?.name}`;
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="โปรเจกต์พื้นฐาน" />
                          )}
                        />
                      )}
                    </div>
                  )}
                />
              )}
            </div>
          )}
          <Controller
            control={control}
            name={`processes[${index}].no_product`}
            defaultValue={moTemplate?.[index]?.no_product}
            render={({ field }) => (
              <FormControlLabel
                label="ไม่ระบุสินค้า / วัตถุดิบ"
                control={
                  <Checkbox
                    {...field}
                    onChange={(event) => {
                      const isChecked = event.target.checked;
                      field.onChange(event.target.checked);
                      if (isChecked) {
                        setValue(
                          `processes[${index}].product_as_material`,
                          null,
                        );
                        setValue(`processes[${index}].product`, null);
                      }
                    }}
                    defaultChecked={field.value}
                  />
                }
              />
            )}
          />
        </div>
        <hr className="my-2" />
        <div className="py-2">สูตรการผลิต</div>
        <Controller
          control={control}
          name={`processes[${index}].process_template`}
          defaultValue={
            moTemplate ? moTemplate?.processes?.[index]?.process_template : null
          }
          render={({ field }) => (
            <div>
              {!_.isEmpty(processTemplate?.rows) && (
                <Autocomplete
                  size={'small'}
                  disableClearable
                  {...field}
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                  }}
                  defaultValue={field?.value}
                  options={processTemplate?.rows}
                  required
                  getOptionLabel={(option) => {
                    if (option?.type_code) {
                      return `${option?.type_code} | ${option?.name}`;
                    }
                    return `${option?.name}`;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="สูตรการผลิต"
                      required
                      helperText={
                        (errors?.processes?.[index]?.process_tempalte &&
                          'กรุณาเลือกสูตรการผลิต') ||
                        ''
                      }
                    />
                  )}
                />
              )}
            </div>
          )}
        />
        <Controller
          control={control}
          name={`processes[${index}].use_bom_template`}
          defaultValue={moTemplate?.[index]?.use_bom_template}
          render={({ field }) => (
            <FormControlLabel
              label="ใช้สูตรส่วนผสม (สูตร BOM)"
              control={
                <Checkbox
                  {...field}
                  onChange={(event) => {
                    field.onChange(event.target.checked);
                  }}
                  defaultChecked={field.value}
                />
              }
            />
          )}
        />{' '}
        {watch(`processes[${index}].use_bom_template`) && (
          <Controller
            control={control}
            name={`processes[${index}].bom_template`}
            defaultValue={
              moTemplate ? moTemplate?.processes?.[index]?.bom_template : null
            }
            render={({ field }) => (
              <div>
                {!_.isEmpty(bomTemplate?.rows) && (
                  <Autocomplete
                    size={'small'}
                    disableClearable
                    {...field}
                    onChange={(e, newValue) => {
                      field.onChange(newValue);
                    }}
                    defaultValue={field?.value}
                    options={bomTemplate?.rows}
                    required
                    getOptionLabel={(option) => `${option?.name}`}
                    renderInput={(params) => (
                      <TextField {...params} label="สูตรส่วนผสม (สูตร BOM)" />
                    )}
                  />
                )}
              </div>
            )}
          />
        )}
        <Controller
          control={control}
          name={`processes[${index}].deliver_to_material_inventory`}
          defaultValue={moTemplate?.[index]?.deliver_to_material_inventory}
          render={({ field }) => (
            <FormControlLabel
              label="เพิ่มลงในคลังวัตถุดิบหลังจากสิ้นสุดการผลิต"
              control={
                <Checkbox
                  {...field}
                  onChange={(event) => {
                    field.onChange(event.target.checked);
                  }}
                  defaultChecked={field.value}
                />
              }
            />
          )}
        />
        {watch(`processes[${index}].deliver_to_material_inventory`) && (
          <Controller
            control={control}
            name={`processes[${index}].target_inventory`}
            defaultValue={
              moTemplate
                ? moTemplate?.processes?.[index]?.target_inventory
                : null
            }
            render={({ field }) => (
              <div>
                {!_.isEmpty(place?.rows) && (
                  <Autocomplete
                    size={'small'}
                    disableClearable
                    {...field}
                    onChange={(e, newValue) => {
                      field.onChange(newValue);
                    }}
                    defaultValue={field?.value}
                    options={place?.rows}
                    required
                    getOptionLabel={(option) => `${option?.name}`}
                    renderInput={(params) => (
                      <TextField {...params} label="คลังวัตถุดิบ" />
                    )}
                  />
                )}
              </div>
            )}
          />
        )}
        <Controller
          control={control}
          name={`processes[${index}].deliver_to_product_warehouse`}
          defaultValue={moTemplate?.[index]?.deliver_to_product_warehouse}
          render={({ field }) => (
            <FormControlLabel
              label="เพิ่มลงในคลังสินค้าหลังจากสิ้นสุดการผลิต"
              control={
                <Checkbox
                  {...field}
                  onChange={(event) => {
                    field.onChange(event.target.checked);
                  }}
                  defaultChecked={field.value}
                />
              }
            />
          )}
        />
        {watch(`processes[${index}].deliver_to_product_warehouse`) && (
          <Controller
            control={control}
            name={`processes[${index}].target_warehouse`}
            defaultValue={
              moTemplate
                ? moTemplate?.processes?.[index]?.target_warehouse
                : null
            }
            render={({ field }) => (
              <div>
                {!_.isEmpty(warehouse?.rows) && (
                  <Autocomplete
                    size={'small'}
                    disableClearable
                    {...field}
                    onChange={(e, newValue) => {
                      field.onChange(newValue);
                    }}
                    defaultValue={field?.value}
                    options={warehouse?.rows}
                    required
                    getOptionLabel={(option) => `${option?.name}`}
                    renderInput={(params) => (
                      <TextField {...params} label="คลังสินค้า" />
                    )}
                  />
                )}
              </div>
            )}
          />
        )}
      </div>
      <div className="w-1/12 px-1 self-center">
        <div className="flex ">
          <IconButton
            variant="contained"
            color="inherit"
            onClick={() => {
              if (index === 0) {
                // Do Nothing
              } else {
                move(index, index - 1);
              }
            }}
          >
            <ChevronUp size="16" />
          </IconButton>
          <IconButton
            variant="contained"
            color="inherit"
            onClick={() => {
              if (index === _.size(fields) - 1) {
                // Do Nothing
              } else {
                move(index, index + 1);
              }
            }}
          >
            <ChevronDown size="16" />
          </IconButton>{' '}
          <IconButton
            variant="contained"
            color="error"
            onClick={() => {
              remove(index);
            }}
          >
            <Trash size="16" />
          </IconButton>
        </div>{' '}
      </div>
    </div>
  );

  return (
    <div className="flex flex-wrap">
      <div className="w-full py-2">
        <Controller
          control={control}
          name="name"
          defaultValue={moTemplate?.name}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อ"
              fullWidth
              size="small"
              required
              helperText={(errors?.name && 'กรุณาระบุชื่อ') || ''}
            />
          )}
        />
      </div>
      <div className="w-full py-2">
        <Controller
          control={control}
          name="sequential_process"
          defaultValue={moTemplate?.sequential_process}
          render={({ field }) => (
            <FormControlLabel
              label="เป็นกระบวนการต่อเนื่องตามขั้นตอน"
              control={
                <Checkbox
                  {...field}
                  onChange={(event) => {
                    field.onChange(event.target.checked);
                  }}
                  defaultChecked={field.value}
                />
              }
            />
          )}
        />
      </div>
      <div className="w-full py-2">
        {_.map(fields, (eachField, index) =>
          renderEachProcess(eachField, index),
        )}
      </div>
      <div className="w-full py-2">
        <Button variant="contained" size="small" onClick={() => append({})}>
          เพิ่มขั้นตอน
        </Button>
      </div>
    </div>
  );
}

MOTempalteForm.propTypes = {
  errors: PropTypes.object,
  control: PropTypes.object,
  watch: PropTypes.func,
  moTemplate: PropTypes.object,
  material: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  product: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  processTemplate: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  baseProject: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  setValue: PropTypes.func,
};

export default MOTempalteForm;
