import React from 'react';
import _ from 'lodash';

import MultiUnitProdOrMatBox from '../Rendering/MultiUnitProdOrMatBox';

function BoxMaterialFindFromLot({
  materialStockLotRows,
  material,
  showAmount,
}) {
  const foundLot = _.filter(
    materialStockLotRows,
    (each) => each?.material?._id === material?._id,
  );
  const totalQuantity = _.sumBy(foundLot, 'quantity'); // ปริมาณทั้งหมด
  const totalAmount = _.sumBy(foundLot, 'amount'); // คงเหลือ
  return (
    <div>
      <div className="font-semibold">
        {material?.type_code} {material?.name}
      </div>
      <div>
        <MultiUnitProdOrMatBox
          foundMaterial={material}
          quantity={totalQuantity}
        />
      </div>
      {_.map(foundLot, (each, index) => (
        <div key={index}>
          {each?.prefer_product && (
            <div>
              ผลิตภัณฑ์ : {each?.prefer_product?.type_code}{' '}
              {each?.prefer_product?.name}
            </div>
          )}
          {each?.customer && (
            <div>
              ลูกค้า : {each?.customer?.type_code} {each?.customer?.name}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default BoxMaterialFindFromLot;
