import dayjs from 'dayjs';
import _ from 'lodash';

const previousLotReciptDateSafe = (previousLotList) => _.map(previousLotList, (each) => {
  let reciptDate;
  if (each?.isProduct) {
    if (each?.inventory_date) {
      reciptDate = dayjs(each?.inventory_date)
        .locale('th')
        .format('YYYY-MM-DDTHH:mm');
    } else {
      reciptDate = dayjs(each?.createdAt)
        .locale('th')
        .format('YYYY-MM-DDTHH:mm');
    }
  } else if (each?.receipt_date) {
    reciptDate = dayjs(each?.receipt_date)
      .locale('th')
      .format('YYYY-MM-DDTHH:mm');
  } else {
    reciptDate = dayjs(each?.createdAt)
      .locale('th')
      .format('YYYY-MM-DDTHH:mm');
  }

  return { ...each, receipt_date: reciptDate };
});

export default previousLotReciptDateSafe;
