import React from 'react';
import FactoryIamge from '../../assets/images/undraw_factory_dy0a.svg';

const Landing = () => (
    <div className="p-8 my-8 flex flex-col justify-center  ">
      <img
        src={FactoryIamge}
        alt="Factory Graphic"
        className="h-80 opacity-75 "
      />
    </div>
  );

export default Landing;
