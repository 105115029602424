import React from 'react';
import { Link } from 'react-router-dom';
import {
  currencyFormatter,
  findAmountOfWorkDayFromInformation,
} from '@iarcpsu/emanufac-utils/functions';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';

const PlaceLaborCostOnPlaceTableCell = ({
  placeId,
  allPlaceLaborCost,
  information,
  startDate,
}) => {
  const filterPLCOnPlace = _.filter(allPlaceLaborCost, (eachPLC) => {
    const placeArray = _.map(eachPLC.places, (eachPlace) => eachPlace._id);
    return _.includes(placeArray, placeId);
  });

  const renderPLCRecord = (eachPLC, index) => {
    const amountOfDay = findAmountOfWorkDayFromInformation({
      information,
      startPeriod: eachPLC.start_date,
      endPeriod: eachPLC.end_date,
    });

    const costPerDay = eachPLC?.cost / amountOfDay;
    const amountOfPlace = _.size(eachPLC.places);
    const costPerDayPerPlace = costPerDay / amountOfPlace;

    return (
      <div key={index}>
        รายการที่ {index + 1}: ต้นทุน{' '}
        {currencyFormatter.format(costPerDayPerPlace)} บาท{' '}
        <Link to={`/cem/place-labor-cost/edit/${eachPLC?._id}`}>
          <Button variant="text" color="warning" size="small">
            แก้ไข
          </Button>
        </Link>
        <div className="text-xs">
          {currencyFormatter.format(eachPLC?.cost)} มีผล{' '}
          {dayjs(eachPLC?.start_date).format('D MMM')} -{' '}
          {dayjs(eachPLC?.end_date).format('D MMM')}{' '}
        </div>
      </div>
    );
  };

  return (
    <div>
      {_.map(filterPLCOnPlace, (eachPLC, plcIndex) =>
        renderPLCRecord(eachPLC, plcIndex),
      )}
      <Link
        to={`/cem/place-labor-cost/create/?addFromSearchQuery=${true}&place=${placeId}&is_daily=${true}&start_date=${dayjs(
          startDate,
        ).format('YYYY-MM-DD')}`}
      >
        <button className="bg-gray-100 rounded-md py-1 px-3 my-1 text-gray-500 hover:bg-gray-200 cursor-pointer text-sm">
          เพิ่ม
        </button>
      </Link>
    </div>
  );
};

export default PlaceLaborCostOnPlaceTableCell;
