import {
  UNIT_ALL,
  UNIT_GET,
  UNIT_PUT,
  UNIT_DELETE,
  UNIT_POST,
  UNIT_LOADING,
  UNIT_ERROR,
} from '../../actions/types';

const initialState = {
  UNIT: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case UNIT_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case UNIT_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case UNIT_POST:
      return { isLoading: false, isCompleted: true };
    case UNIT_PUT:
      return { isLoading: false, isCompleted: true };
    case UNIT_DELETE:
      return { isLoading: false, isCompleted: true };
    case UNIT_LOADING:
      return { isLoading: true, isCompleted: true };
    case UNIT_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
