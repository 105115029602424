import _ from 'lodash';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export const decodeTimeValue = (dateStringInput) => {
  const splitedDate = _.split(dateStringInput, ':');
  const hour = splitedDate[0];
  const min = splitedDate[1];
  console.log('hour', hour, 'min', min);
  return dayjs()
    .hour(parseInt(hour, 10))
    .minute(parseInt(min, 10))
    .second(0)
    .toDate();
};

export default decodeTimeValue;
