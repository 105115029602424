import React, { useEffect, useState } from 'react';
import { ExcelRenderer, OutTable } from 'react-excel-renderer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  BackButton,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import ExcelSheetGenerator from '@iarcpsu/emanufac-components/src/components/ExcelDownload/ExcelSheetGenerator';
import { Button, Card, CardContent } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import { config } from '@/configs';
import * as actions from '@/redux/actions';

/**
 * @function ImportExcelProduct
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function ImportExcelUpdateTypeCode({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const information = useSelector((state) => state.information);
  const [openDownload, setOpenDownload] = useState(false);
  const [columnList, setColumnList] = useState([]);
  const [artifactDataSet, setArtifactDataSet] = useState([]);
  const [inputData, setInputData] = useState({});
  const [allowUploadToServer, setAllowUploadToServer] = useState(false);

  const fileHandler = (event) => {
    const fileObj = event.target.files[0];

    // just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        setInputData({
          cols: resp.cols,
          rows: resp.rows,
        });
        setAllowUploadToServer(true);
      }
    });
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  useEffect(() => {
    const tempColumnList = [
      {
        label: 'ลำดับที่',
        value: 'index',
      },
      {
        label: 'รหัสคู่ค้า/ลูกค้า เก่า',
        value: 'old_type_code',
      },
      {
        label: 'รหัสคู่ค้า/ลูกค้า ใหม่',
        value: 'type_code',
      },
      {
        label: 'ขื่อคู้ค้า/ลูกค้า',
        value: 'name',
      },
      {
        label: 'ที่อยู่',
        value: 'address',
      },
      {
        label: 'หมายเลขประจำตัวผู้เสียภาษี',
        value: 'taxes',
      },
      {
        label: 'หมายเลขโทรศัพท์',
        value: 'phone_number',
      },
      {
        label: 'Fax',
        value: 'phone_number',
      },
    ];

    const tempArtifact = {};
    _.map(tempColumnList, (each) => {
      tempArtifact[each?.value] = each?.value;
    });

    console.log('Temp Artifact', [tempArtifact]);
    setColumnList(tempColumnList);
    setArtifactDataSet([tempArtifact]);
    return () => {};
  }, [information]);

  const handleOnFileLoad = async () => {
    try {
      console.log('File Data');
      console.log('-----');

      console.log('Input Data', inputData);

      // ชื่อของ Key จะอยู่ใน แถวที่ index 1 (แถวที่ 2 ใน Excel) และเอาแถวสุดท้ายออก
      const fileDataKey = inputData?.rows?.[1];
      const fileData = _.slice(inputData?.rows, 2);

      console.log('File Data Key', fileDataKey);
      console.log('File Data', fileData);
      const fileInFormat = _.map(fileData, (_fileData) =>
        _.zipObject(fileDataKey, _fileData),
      );

      console.log('File In Format', fileInFormat);
      const filterNotUndefined = fileInFormat.filter(
        (each) => !_.every(each, _.isUndefined),
      );

      console.log('Filter Not Undefined', filterNotUndefined);
      await dispatch(
        actions.customerUpdateTypeCode({ arr: filterNotUndefined }),
      );
      Swal.fire({
        icon: 'success',
        title: 'อัพโหลดข้อมูลสำเร็จ',
      }).then(() => {
        history.goBack();
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: error?.message,
      });
    }
  };

  return (
    <div>
      {renderTitle()}
      <BackButton />
      <div className="my-2">
        <Card>
          <CardContent>
            <div>ดาวน์โหลดเทมเพลต</div>
            <div>ฟิลด์ใดที่ไม่ใมใส่ ข้อมูลจะเป็นไปตามข้อมูลเดิมในระบบ</div>
            <Button
              variant="contained"
              className="my-2"
              onClick={() => setOpenDownload(true)}
            >
              ดาวน์โหลด
            </Button>
            {openDownload ? (
              <ExcelSheetGenerator
                columnList={columnList}
                dataSets={artifactDataSet}
                sheetName={'CustomerUpdateTypeCodeTemp'}
                spreadedColumn={''}
              />
            ) : (
              <div></div>
            )}
          </CardContent>
        </Card>
      </div>
      <div className="my-2">
        <Card>
          <CardContent>
            <div>อัพโหลดไฟล์ข้อมูล</div>
            <input type="file" onChange={fileHandler} className="my-2" />
            <div className="flex justify-end">
              {allowUploadToServer && (
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => handleOnFileLoad()}
                >
                  อัพโหลดไปที่เซิฟเวอร์
                </Button>
              )}
            </div>
            {inputData?.rows && (
              <OutTable
                data={inputData?.rows}
                columns={inputData?.cols}
                tableClassName="ExcelTable2007"
                tableHeaderRowClass="heading bg-gray-200"
              />
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

ImportExcelUpdateTypeCode.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ImportExcelUpdateTypeCode.defaultProps = {
  title: '',
  subtitle: '',
};
