import { QUOTATION_STATUS } from '@iarcpsu/emanufac-constant';

const findQuotationStatus = (selectedQuotation) => {
  let status;

  if (selectedQuotation.deleted) {
    status = QUOTATION_STATUS.CANCLE.status_code;
  }

  if (selectedQuotation.revised) {
    status = QUOTATION_STATUS.REVISED.status_code;
  }

  if (selectedQuotation.completed) {
    status = QUOTATION_STATUS.SUCCESS.status_code;
  }

  if (selectedQuotation.revising) {
    status = QUOTATION_STATUS.REVISING.status_code;
  }

  if (!status) {
    status = QUOTATION_STATUS.IN_PROGRESS.status_code;
  }

  return status;
};

export default findQuotationStatus;
