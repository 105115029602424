import api from '../../../configs/api';
import {
  MANUFACTURING_TRANSACTION_ALL,
  MANUFACTURING_TRANSACTION_DELETE,
  MANUFACTURING_TRANSACTION_ERROR,
  MANUFACTURING_TRANSACTION_GET,
  MANUFACTURING_TRANSACTION_LOADING,
  MANUFACTURING_TRANSACTION_POST,
  MANUFACTURING_TRANSACTION_PUT,
} from '../types';

export const manufacTxnCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MANUFACTURING_TRANSACTION_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/manufacturing-transaction`,
      {
        ...payload,
      },
    );
    dispatch({ type: MANUFACTURING_TRANSACTION_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MANUFACTURING_TRANSACTION_ERROR });
    throw new Error(error);
  }
};

export const manufacTxnAll = (params) => async (dispatch) => {
  try {
    const {
      processId = '',
      stepId = '',
      size = '',
      page = 1,
      selectType = '',
    } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/manufacturing-transaction?size=${size}&page=${page}&selectType=${selectType}&processId=${processId}&stepId=${stepId}`,
    );
    if (status === 200) {
      dispatch({ type: MANUFACTURING_TRANSACTION_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MANUFACTURING_TRANSACTION_ERROR });
    throw new Error(error);
  }
};

export const manufacTxnGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/manufacturing-transaction/${id}`,
    );
    if (status === 200) {
      dispatch({ type: MANUFACTURING_TRANSACTION_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MANUFACTURING_TRANSACTION_ERROR });
    throw new Error(error);
  }
};

export const manufacTxnStateSet = (data) => async (dispatch) => {
  dispatch({ type: MANUFACTURING_TRANSACTION_ALL, payload: data });
};

export const manufacTxnPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: MANUFACTURING_TRANSACTION_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/manufacturing-transaction/${id}`,
      payload,
    );
    dispatch({ type: MANUFACTURING_TRANSACTION_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MANUFACTURING_TRANSACTION_ERROR });
    throw new Error(error);
  }
};
export const manufacTxnDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: MANUFACTURING_TRANSACTION_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/manufacturing-transaction/${id}`,
    );
    dispatch({ type: MANUFACTURING_TRANSACTION_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MANUFACTURING_TRANSACTION_ERROR });
    throw new Error(error);
  }
};
