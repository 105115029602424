const MATERIAL_TRANSACTION_TYPE = {
  add: {
    description: "เข้า",
    status_code: "add",
  },
  desc: {
    description: "ออก",
    status_code: "desc",
  },
};

module.exports = MATERIAL_TRANSACTION_TYPE;
