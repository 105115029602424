import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Error,
  ExportExcelContainer,
  InternalDepositReport,
  InternalDepositTable,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import { config } from '@/configs';
import * as actions from '@/redux/actions';
import { InternalDepositUtil } from '@/utils/excelColumn';

/**
 * @function Quotations
 * @description Display a collections or a list of Quotations from database
 */

export default function InternalDeposits({ title, subtitle }) {
  const dispatch = useDispatch();
  const internalDeposit = useSelector((state) => state.internalDeposit);
  const information = useSelector((state) => state.information);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [total, setTotal] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState('');
  const [includeLog, setIncludeLog] = useState(false);
  const [name, setName] = useState('');
  const me = useSelector((state) => state.me);

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.internalDepositAll({
          page,
          size,
          completed: includeLog ? '' : 'false',
          deleted: includeLog ? '' : 'false',
          name,
        }),
      );
    } catch (error) {
      dispatch(actions.internalDepositAll());
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, includeLog, name]);

  useEffect(() => {
    if (internalDeposit?.rows) {
      setTotal(internalDeposit?.total);
    }

    return () => {};
  }, [internalDeposit]);

  const handlePrintPdf = (row) => {
    InternalDepositReport(row, information);
  };

  const handleDelete = async (id) => {
    try {
      Swal.fire({
        icon: 'question',
        title: 'ยืนยันการลบใบเบิกใช้ภายใน',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await dispatch(actions.internalDepositDelete(id, me?.userData?._id));
          queryDataFromServer();
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'ไม่สามารถลบใบเบิกใช้ภายใน',
        text: error?.message,
      });
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (internalDeposit.isLoading) {
    return <Loading />;
  }
  if (!internalDeposit.isLoading && internalDeposit.isCompleted) {
    return (
      <div>
        <div className="flex justify-between items-center">
          {renderTitle()}
          <div>
            <Link to={'/spm/internal-deposit/create'}>
              <Button variant="contained">เพิ่ม</Button>
            </Link>
          </div>
        </div>
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="flex flex-wrap">
                <div className="w-full md:w-1/2 lg:w-1/3">
                  <TextField
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    size="small"
                    label="ค้นหา"
                    fullWidth
                  />
                </div>
                <div className="w-1/2 lg:w-1/5 ml-4">
                  <FormControlLabel
                    label="รวมประวัติ"
                    control={
                      <Switch
                        checked={includeLog}
                        onChange={(e) => setIncludeLog(e.target.checked)}
                      />
                    }
                  />
                </div>
                <div className="w-1/2 lg:w-1/5 ml-4 flex justify-end">
                  <ExportExcelContainer
                    columnList={InternalDepositUtil.columns}
                    currentData={internalDeposit?.rows}
                    dataAPIEndPoint="internal-deposit"
                    dataQuery={{
                      page: 1,
                      size: config.maxFetchSize,
                      completed: includeLog ? '' : 'false',
                      deleted: includeLog ? '' : 'false',
                    }}
                    sheetName="InternalDeposit"
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="my-2">
          <InternalDepositTable
            internalDeposit={internalDeposit}
            page={page}
            size={size}
            total={total}
            setPage={setPage}
            setSize={setSize}
            handleDelete={handleDelete}
            handlePrintPdf={handlePrintPdf}
          />
        </div>
      </div>
    );
  }
  return <Error />;
}

InternalDeposits.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

InternalDeposits.defaultProps = {
  title: '',
  subtitle: '',
};
