import { PRODUCT_QUANTITY_DIVIDE_BY } from '@iarcpsu/emanufac-constant';
import dayjs from 'dayjs';
import _ from 'lodash';

const generateMaterialToProductPayload = ({
  materialStockLotRows,
  place,
  productStockLotRows,
  showCustomer,
}) => {
  const findPredictionAmount = (amount, preferProduct) => {
    switch (place?.place_action?.product_quantity_divide_by) {
      case PRODUCT_QUANTITY_DIVIDE_BY.NONE.status_code:
        return amount;
      case PRODUCT_QUANTITY_DIVIDE_BY.NET.status_code:
        return amount / preferProduct?.net;
      case PRODUCT_QUANTITY_DIVIDE_BY.PACKAGE_SIZE.status_code:
        return amount / preferProduct.package_size;
      default:
        return amount;
    }
  };

  // FIXME: Edit Filter on Aggregation
  const filterProductLot = (selectedLot) =>
    _.filter(productStockLotRows, (eachLot) => {
      const previousLot = _.map(
        eachLot?.previous_lot,
        (eachPreviousLot) => eachPreviousLot?._id,
      );
      // Include this lot Id
      return _.includes(previousLot, selectedLot?._id);
    });

  const result = [];

  const groupByProductPayload = _.groupBy(
    materialStockLotRows,
    'prefer_product._id',
  );

  _.map(groupByProductPayload, (eachByProduct, productIdKey) => {
    const eachByProductConvertToDateCode = _.map(eachByProduct, (each) => ({
      ...each,
      date: dayjs(each?.purchase_date).format('MM/DD'),
    }));

    const groupByPurchaseDateCode = _.groupBy(
      eachByProductConvertToDateCode,
      'date',
    );
    _.map(groupByPurchaseDateCode, (eachByDateCode, dateCodeKey) => {
      const groupBySource = _.groupBy(eachByDateCode, 'source.overall_code');
      _.map(groupBySource, (eachBySource) => {
        if (showCustomer) {
          const groupByCustomer = _.groupBy(eachBySource, 'customer._id');
          _.map(groupByCustomer, (eachByCustomer) => {
            const totalQuantityOfSourceByCustomer = _.sumBy(
              eachByCustomer,
              'quantity',
            );
            const totalAmountOfSourceByCustomer = _.sumBy(
              eachByCustomer,
              'amount',
            );
            _.map(eachByCustomer, (each) => {
              const sumAmountProduct = _.sumBy(
                filterProductLot(each),
                'quantity',
              );
              const payload = {
                product: each?.prefer_product?.name,
                product_id: productIdKey,
                product_type: each?.prefer_product?.additional?.['ชนิด'],
                date: dateCodeKey,
                // customer: each?.customer?.name,
                lot_id: each?._id,
                material: each?.material?.name,
                lot: each?.source?.overall_code,
                quantity: totalQuantityOfSourceByCustomer,
                amount: totalAmountOfSourceByCustomer,
                predicted_amount: findPredictionAmount(
                  totalQuantityOfSourceByCustomer,
                  each?.prefer_product,
                ),
                net:
                  totalQuantityOfSourceByCustomer / each?.prefer_product?.net,
                package_size:
                  totalQuantityOfSourceByCustomer /
                  each?.prefer_product?.package_size,
                real_amount: sumAmountProduct,
                status: each?.status,
                product_info_net: each?.prefer_product?.net,
                confirm: `/ims/confirm-material-to-product?lotid=${each?._id}`,
                edit: `/ims/edit-material-to-product?lotid=${each?._id}`,
                customer: each?.customer?.name,
                customer_id: each?.customer?._id,
                productObject: each?.prefer_product,
              };

              result.push(payload);
            });
          });
        } else {
          const totalQuantityOfSource = _.sumBy(eachBySource, 'quantity');
          const totalAmountOfSource = _.sumBy(eachBySource, 'amount');
          _.map(eachBySource, (each) => {
            const sumAmountProduct = _.sumBy(
              filterProductLot(each),
              'quantity',
            );
            const payload = {
              product: each?.prefer_product?.name,
              product_id: productIdKey,
              product_type: each?.prefer_product?.additional?.['ชนิด'],
              date: dateCodeKey,
              lot_id: each?._id,
              material: each?.material?.name,
              lot: each?.source?.overall_code,
              quantity: totalQuantityOfSource,
              amount: totalAmountOfSource,
              predicted_amount: findPredictionAmount(
                totalQuantityOfSource,
                each?.prefer_product,
              ),
              net: totalQuantityOfSource / each?.prefer_product?.net,
              package_size:
                totalQuantityOfSource / each?.prefer_product?.package_size,
              real_amount: sumAmountProduct,
              status: each?.status,
              product_info_net: each?.prefer_product?.net,
              product_info_package_size: each?.prefer_product?.package_size,
              confirm: `/ims/confirm-material-to-product?lotid=${each?._id}`,
              edit: `/ims/edit-material-to-product?lotid=${each?._id}`,
              productObject: each?.prefer_product,
            };

            result.push(payload);
          });
        }
      });
    });
  });

  const newResult = _.map(result, (each) => {
    let filterLot = _.filter(
      materialStockLotRows,
      (eachFilter) => eachFilter?.prefer_product?._id === each?.product_id,
    );

    if (showCustomer) {
      const newFilterLot = _.filter(
        filterLot,
        (eachFilterLot) => eachFilterLot?.customer?._id === each?.customer_id,
      );
      filterLot = newFilterLot;
    }

    const confirmAction = `/ims/confirm-material-to-product?lotid=${_.map(
      filterLot,
      (eachLot) => eachLot?._id,
    )}&source=${place?._id}&destination=${
      place?.place_action?.destination_warehouse
    }&product=${each?.product_id}`;

    const editAction = `/ims/edit-material-to-product?lotid=${_.map(
      filterLot,
      (eachLot) => eachLot?._id,
    )}&source=${place?._id}&destination=${
      place?.place_action?.destination_warehouse
    }&product=${each?.product_id}`;
    const amount = _.sumBy(filterLot, 'amount');
    const detail = `/ims/product-preview?lots=${_.map(
      _.uniq(filterLot),
      (eachFilterLot) => eachFilterLot?._id,
    )}&place=${place?._id}&product=${each?.product_id}`;
    return {
      ...each,
      quantity: _.sumBy(filterLot, 'quantity'),
      predicted_amount: findPredictionAmount(
        _.sumBy(filterLot, 'quantity'),
        each.productObject,
      ),
      confirm: amount !== 0 ? confirmAction : null,
      edit: amount <= 0 ? editAction : null,
      detail,
    };
  });

  return _.filter(newResult, (each) => each?.quantity > 0);
};

export default generateMaterialToProductPayload;
