import _ from 'lodash';
import * as CONSTANT from '../constants';

export const recieveAllMaterials = (currentMaterialRequest) =>
  _.isEmpty(
    _.filter(
      currentMaterialRequest,
      (_request) =>
        _request.status ===
        CONSTANT.MATERIAL_WITHDRAW_STATUS.PENDING_APPROVAL.status_code,
    ),
  ) &&
  _.isEmpty(
    _.filter(
      currentMaterialRequest,
      (_request) =>
        _request.status ===
        CONSTANT.MATERIAL_WITHDRAW_STATUS.REJECT.status_code,
    ),
  );

export default { recieveAllMaterials };
