import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { DASHBOARD_TYPE } from '@/utils/constants';

import DashboardElementDragAndDrop from './DashboardElementDragAndDrop';

export default function DashboardElementForm({
  control,
  setValue,
  watch,
  errors,
  resultingFuctionType,
  measurementType,
  defaultValue,
  size,
  setSize,
}) {
  return (
    <div>
      <Card>
        <CardContent>
          <div className="w-full py-2 px-1">
            <Controller
              name="name"
              defaultValue={defaultValue?.name || ''}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size="small"
                  label="ชื่อ *"
                  helperText={errors?.name && 'กรุณาระบุชื่อ'}
                />
              )}
            />
          </div>
          <div className="w-full py-2 px-1">
            <Controller
              name="is_mo_level"
              control={control}
              defaultValue={defaultValue?.is_mo_level}
              render={({ field }) => (
                <FormControl fullWidth>
                  <div className="flex gap-2">
                    <div className="self-center">ระดับไลน์การผลิต</div>
                    <FormControlLabel
                      label="ระดับคำสั่งผลิต"
                      control={
                        <Switch {...field} defaultChecked={field.value} />
                      }
                    />
                  </div>
                </FormControl>
              )}
            />
          </div>
          <div className="w-full py-2 px-1">
            <Controller
              name="dashboard_type"
              control={control}
              defaultValue={defaultValue?.dashboard_type}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel>ประเเภทของส่วนประกอบ</InputLabel>
                  {watch('is_mo_level') ? (
                    <Select label="ประเภทของส่วนประกอบ" {...field}>
                      {_.map(
                        _.filter(
                          DASHBOARD_TYPE,
                          (eachType) => eachType.enableOnMO === true,
                        ),
                        (eachElementType, index) => (
                          <MenuItem
                            ket={index}
                            value={eachElementType.status_code}
                          >
                            <i className={`${eachElementType.icon} mr-2`} />{' '}
                            {eachElementType.description}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  ) : (
                    <Select label="ประเภทของส่วนประกอบ" {...field}>
                      {_.map(
                        _.filter(
                          DASHBOARD_TYPE,
                          (eachType) => eachType.enableOnProcess === true,
                        ),
                        (eachElementType, index) => (
                          <MenuItem
                            ket={index}
                            value={eachElementType.status_code}
                          >
                            <i className={`${eachElementType.icon} mr-2`} />{' '}
                            {eachElementType.description}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  )}
                </FormControl>
              )}
            />
          </div>
        </CardContent>
      </Card>
      {watch('is_mo_level') && (
        <DashboardElementDragAndDrop
          control={control}
          measurementType={measurementType}
          resultingFuctionType={resultingFuctionType}
          size={size}
          setSize={setSize}
        />
      )}
    </div>
  );
}

DashboardElementForm.propTypes = {
  control: PropTypes.object,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  errors: PropTypes.object,
  resultingFuctionType: PropTypes.shape({
    total: PropTypes.number,
    rows: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
  }),
  measurementType: PropTypes.shape({
    total: PropTypes.number,
    rows: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
  }),
  defaultValue: PropTypes.object,
  size: PropTypes.number,
  setSize: PropTypes.func,
};
