/* eslint-disable import/prefer-default-export */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';

import currencyFormatterTH from '@/utils/functions/currencyFormatterTH';

import 'addthaifont-pdfmake/build/vfs_fonts';

import { ReportHeader } from './common';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};

const genPurchaseOrder = (data) =>
  _.map(data, (_data, index) => [
    {
      text: index + 1,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${_data?.material?.type_code || ''} ${
        _data?.material?.name || ''
      }`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${currencyFormatterTH.format(_data?.quantity)} ${
        _data?.material?.unit
      }`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${_data?.quantity - _data?.amount} ${_data?.material?.unit}`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
  ]);

export const PurchaseOrderReport = (purchaseOrder, information, isError) => {
  const purchaseOrderData = genPurchaseOrder(purchaseOrder?.material);

  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 30, 40, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '16',
    },
    info: {
      title: 'รายการสั่งซื้อวัตถุดิบ',
    },
    content: [
      isError ? ReportHeader(information, false) : ReportHeader(information),
      {
        // หัวข้อ
        alignment: 'center',
        margin: [0, 5, 0, 10],
        text: 'ใบสั่งซื้อวัตถุดิบ',
        fontSize: '16',
        bold: true,
      },
      {
        style: 'tableExample',
        table: {
          widths: ['7%', '45%', '15%', '37%'],
          heights: [15],
          body: [
            [
              {
                text: 'คู่ค้า',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, false, false],
              },
              {
                text: `${purchaseOrder?.supplier?.name || '-'}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, true, true, false],
              },
              {
                text: 'เลขที่บิล',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, false, false],
              },
              {
                text: `${purchaseOrder?.bill_number || '-'}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, true, true, false],
              },
            ],
            [
              {
                text: 'ที่อยู่',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, false],
              },
              {
                text: `${purchaseOrder?.supplier?.address || '-'}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: 'ผู้สั่งซื้อ',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, false],
              },
              {
                text: `${purchaseOrder?.purchaser?.firstname || ''} ${
                  purchaseOrder?.purchaser?.lastname || '-'
                }`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: '',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, true],
              },
              {
                text: `โทร. ${purchaseOrder?.supplier?.phone_number || '-'}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, true],
              },
              {
                text: 'แหล่งวัตถุดิบ',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, true],
              },
              {
                text: `${purchaseOrder?.source || '-'}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, true, true],
              },
            ],
          ],
        },
      },
      {
        margin: [0, 5, 0, 0],
        style: 'tableExample',
        table: {
          widths: ['10%', '49%', '25%', '20%'],
          heights: [15],
          body: [
            [
              {
                text: 'ลำดับ',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: 'ชื่อวัตถุดิบ',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: 'จำนวนที่สั่ง',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
              },

              {
                text: 'จำนวนที่ได้รับ',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
              },
            ],
            ...purchaseOrderData,
          ],
        },
      },
    ],
    images: {
      logo: information?.logo?.url,
    },
  };
  pdfMake.createPdf(docDefinition).open();
};
