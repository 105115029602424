import React from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, Controller } from 'react-hook-form';
import _ from 'lodash';
import {
  Autocomplete,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';

import ProdOrMatQuantityInput from '../../Inputs/ProdOrMatQuantityInput';

/**
 * @function RequestAddWarehouseForm
 * @memberof Form/MMS
 * @description ฟอร์มการส่งผลิตภัณฑ์เข้าคลังโดยต้องได้รับการอนุญาตจากคลังสินค้าอีกครั้ง
 * @returns {React.Component} React Component
 */

const RequestAddWarehouseForm = ({ control, product, watch, setValue }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'products',
  });

  return (
    <div>
      <div className="my-2">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-semibold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-semibold">สินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-semibold">ปริมาณ</div>
                </TableCell>
                <TableCell>
                  <div className="font-semibold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(fields, (eachField, index) => (
                <TableRow key={eachField?.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <div className="w-64">
                      {!_.isEmpty(product.rows) && (
                        <Controller
                          name={`products[${index}].product`}
                          control={control}
                          defaultValue={eachField?.product}
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              size={'small'}
                              onChange={(e, newValue) => {
                                field.onChange(newValue);
                              }}
                              options={product?.rows}
                              getOptionLabel={(option) => {
                                if (option.type_code) {
                                  return `${option?.type_code || ''} | ${
                                    option?.name
                                  }`;
                                }
                                return option?.name;
                              }}
                              fullWidth
                              required
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="สินค้า"
                                  required
                                  fullWidth
                                />
                              )}
                            />
                          )}
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    <ProdOrMatQuantityInput
                      controllerName={`products[${index}].quantity`}
                      artificialControllerName={`products[${index}].quantity_artificial`}
                      index={index}
                      selectedUnitControllerName={`products[${index}].selected_unit`}
                      setValue={setValue}
                      control={control}
                      watch={watch}
                      selectedProduct={watch(`products[${index}].product`)}
                      defaultValue={eachField?.quantity}
                    />
                  </TableCell>

                  <TableCell>
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={() => remove(index)}
                    >
                      ลบ
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={4}>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => append({})}
                  >
                    เพิ่ม
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="w-full py-2 mx-1">
        <div className="w-full py-2 px-1">
          <Controller
            name="requester_remark"
            // defaultValue={baseProject?.name || ''}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                size="small"
                label="หมายเหตุ"
                multiline
                rows={3}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

RequestAddWarehouseForm.propTypes = {
  control: PropTypes.object,
  product: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  watch: PropTypes.func,
  setValue: PropTypes.func,
};

export default RequestAddWarehouseForm;
