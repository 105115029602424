import React, { useState } from 'react';
import { X as XIcon } from 'react-feather';
import {
  Backdrop,
  Card,
  CardContent,
  CardHeader,
  IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';

function CampaignModal({ campaignCode, title, children }) {
  const [backdropOpen, setBackdropOpen] = useState(true);

  const handleClose = () => {
    localStorage.setItem(campaignCode, 'TRUE');
    setBackdropOpen(false);
  };

  if (localStorage.getItem(campaignCode) === 'TRUE') {
    return <div></div>;
  }

  return (
    <div>
      <Backdrop open={backdropOpen} sx={{ zIndex: 100 }}>
        <div className="w-full lg:w-1/3 mx-4">
          <Card>
            <CardHeader
              title={title}
              action={
                <IconButton onClick={() => handleClose()}>
                  <XIcon />
                </IconButton>
              }
            />
            <CardContent>{children}</CardContent>
          </Card>
        </div>
      </Backdrop>
    </div>
  );
}

export default CampaignModal;

CampaignModal.propTypes = {
  campaignCode: PropTypes.string,
  children: PropTypes.any,
  title: PropTypes.string,
};
