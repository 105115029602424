import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { MATERIAL_COST_ESTIMATION_METHOD } from '@iarcpsu/emanufac-constant';
import {
  currencyFormatter,
  findMaterialOriginalCost,
} from '@iarcpsu/emanufac-utils/functions';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import MultiUnitProdOrMatBox from '../../Rendering/MultiUnitProdOrMatBox';

function MaterialAmountAndUnit({
  useCostFromPreviousMaterial,
  previousLotList,
  place,
}) {
  const materialOnPlace = _.filter(
    previousLotList,
    (each) => each?.place?._id === place?._id,
  );
  const allowCategory = place?.allow_categories;

  // หาวัตถุดิบที่อนุญาคิให้อยู่ในคลังนี้
  const filterAllowanceMaterial = _.filter(materialOnPlace, (each) =>
    _.includes(allowCategory, each?.material?.material_type),
  );

  const originalCostList = findMaterialOriginalCost({
    allowanceMaterial: filterAllowanceMaterial,
    allLotList: previousLotList,
    materialOnPlace,
    useCostFromPrevious: useCostFromPreviousMaterial,
  });

  // console.log('Original Cost List', originalCostList);

  const findAverageCost = (eachMaterialOriginalCost) => {
    const amountOfList = _.size(eachMaterialOriginalCost);
    const totalCost = _.sumBy(
      eachMaterialOriginalCost,
      (each) => each?.unit_cost || 0,
    );
    return totalCost / amountOfList;
  };

  const totalFromNoUsePreviousCost = _.sum(
    _.map(
      originalCostList,
      (each) => each?.unit_cost || 0 * each?.quantity || 0,
    ),
  );

  const totalFromUsePreviousCost = _.sum(
    _.map(
      originalCostList,
      (each) => findAverageCost(each?.original_costs) * each?.quantity || 0,
    ),
  );

  return (
    <div className="my-2">
      <div className="font-semibold my-2">
        ต้นทุนวัตถุดิบ:{' '}
        {MATERIAL_COST_ESTIMATION_METHOD.USE_AMOUNT_AND_UNIT_COST.description}
      </div>
      <div className="my-2">
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">เลขอ้างอิงล็อต</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วัตถุดิบ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ปริมาณที่ได้</div>
                </TableCell>
                {useCostFromPreviousMaterial && (
                  <TableCell>
                    <div className="font-bold">ต้นกำเนิด</div>
                  </TableCell>
                )}{' '}
                <TableCell>
                  <div className="font-bold">ต้นทุนต่อหน่วย</div>
                </TableCell>
                {useCostFromPreviousMaterial && (
                  <TableCell>
                    <div className="font-bold">ต้นทุนต่อเฉลี่ย</div>
                  </TableCell>
                )}{' '}
                <TableCell>
                  <div className="font-bold">ต้นทุน</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(originalCostList) ? (
                _.map(originalCostList, (row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="text-xs">{index + 1}</div>
                    </TableCell>
                    <TableCell>{row?.lot?.running_number}</TableCell>
                    <TableCell>{row?.material?.name}</TableCell>
                    <TableCell>
                      <MultiUnitProdOrMatBox
                        foundMaterial={row?.material}
                        quantity={row?.quantity}
                      />
                    </TableCell>
                    {useCostFromPreviousMaterial && (
                      <TableCell>
                        {_.map(row?.original_costs, (each, InsideIndex) => (
                          <TableRow key={InsideIndex} sx={{ border: 0 }}>
                            {each?.material?.name}
                            {console.log('Each', each)}
                            <Link
                              to={`/ims/material-stock-lot/${each?.material_stock_lot}`}
                              className="hover:text-gray-600 hover:underline"
                            >
                              <div className="text-xs">
                                {each?.running_number}
                              </div>
                            </Link>
                          </TableRow>
                        ))}
                      </TableCell>
                    )}
                    {/** ต้นทุนต่อหน่วย ของ useFromPrevious */}
                    {useCostFromPreviousMaterial && (
                      <TableCell>
                        {_.map(row?.original_costs, (each, InsideIndex) => (
                          <TableRow key={InsideIndex}>
                            {currencyFormatter.format(each?.unit_cost)}
                          </TableRow>
                        ))}{' '}
                      </TableCell>
                    )}
                    {/** ต้นทุนต่อหน่วยเฉลี่ยของ useFromPrevious */}
                    {useCostFromPreviousMaterial && (
                      <TableCell>
                        {currencyFormatter.format(
                          findAverageCost(row?.original_costs),
                        )}
                      </TableCell>
                    )}{' '}
                    {/** ต้นทุน สำหรับ useFromPrevious */}
                    {useCostFromPreviousMaterial && (
                      <TableCell>
                        {currencyFormatter.format(
                          row?.quantity * findAverageCost(row?.original_costs),
                        )}
                      </TableCell>
                    )}
                    {!useCostFromPreviousMaterial && (
                      <TableCell>
                        {currencyFormatter.format(row?.unit_cost)}
                      </TableCell>
                    )}
                    {!useCostFromPreviousMaterial && (
                      <TableCell>
                        {currencyFormatter.format(
                          row?.unit_cost * row?.quantity,
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
              {!_.isEmpty(originalCostList) && (
                <TableRow>
                  <TableCell colSpan={useCostFromPreviousMaterial ? 6 : 4}>
                    <div className="text-center">รวม</div>
                  </TableCell>
                  <TableCell colSpan={2}>
                    {currencyFormatter.format(
                      useCostFromPreviousMaterial
                        ? totalFromUsePreviousCost
                        : totalFromNoUsePreviousCost,
                    )}{' '}
                    บาท
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default MaterialAmountAndUnit;

MaterialAmountAndUnit.propTypes = {
  useCostFromPreviousMaterial: PropTypes.bool,
  previousLotList: PropTypes.array,
  place: PropTypes.object,
};
