import { GraphQLClient } from 'graphql-request';

const authToken = localStorage.getItem('token');
const client = new GraphQLClient(process.env.REACT_APP_GQL_URL, {
  headers: {
    authorization: `Bearer ${authToken}`,
  },
});

export default client;
