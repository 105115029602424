import _ from 'lodash';

import resultingFunctionExecutor from './resultingFunctionExecutor';

/**
 * @typedef {object} Payload
 * @property {object} resultingFunctionType Resulting Fn Type object that from DB
 * @property {Array} measurementVars Array of MeasurementVar can be from step.measurement or another
 * @property {Boolean} enableLog Enable Console.log in Development or not
 *
 * @function findVariableAndExecute
 * @description find variable in group of measurement variables and caculate with resulting Function
 * @param {Payload} {}
 * @returns {object} Object Resuting value
 */
const findVariableAndExecute = ({
  resultingFunctionType,
  measurementVars,
  anotherMeasurementFT,
  enableLog = false,
}) => {
  // Filter using constant out of calculation
  const onlyFromMeasurement = _.filter(
    resultingFunctionType?.executor,
    (eachExecutor) => eachExecutor?.using_constant !== true,
  );

  // Find Require Measurement Type ID
  const requireMeasurementType = _.map(onlyFromMeasurement, (eachExecutor) => {
    // กรณีที่มันไม่ได้ต้องการข้อมูลจาก Measurement Var แต่มันต้องการจากฟังก์ชันอื่ย
    if (eachExecutor?.using_another_rft) {
      if (eachExecutor?.another_rft?._id) {
        return {
          anotherRFT: eachExecutor?.another_rft?._id,
          useAnotherRFT: true,
        };
      }
      return { anotherRFT: eachExecutor.another_rft, useAnotherRFT: true };
    }

    // กรณีที่มันต้องการจาก Measurement Var
    if (eachExecutor?.measurement_type?._id) {
      return {
        measurementVar: eachExecutor?.measurement_type?._id,
        useAnotherRFT: false,
      };
    }
    return {
      measurementVar: eachExecutor.measurement_type,
      useAnotherRFT: false,
    };
  });

  if (enableLog) {
    console.log('Resulting Function Type', resultingFunctionType?.name);
    console.log('Required Measurement Type ', requireMeasurementType);
  }

  // Find Measurement Variables that use this measurement type
  const foundedVariable = _.map(
    requireMeasurementType,
    (eachRequireExecutor) => {
      // กรณีต้องการค่าจาก Function อื่น
      if (eachRequireExecutor.useAnotherRFT) {
        const foundedFunctionValue = _.find(
          anotherMeasurementFT,
          (eachFunction) =>
            eachFunction?._id === eachRequireExecutor.anotherRFT ||
            eachFunction === eachRequireExecutor.anotherRFT,
        );

        if (foundedFunctionValue) {
          return parseFloat(foundedFunctionValue?.value);
        }
        return 0;
      }

      // กรณีที่ต้องการ Measurement Variable
      const foundedVaraible = _.find(
        measurementVars,
        (eachMeasurementVar) =>
          eachMeasurementVar?.measurement_type?._id ===
            eachRequireExecutor.measurementVar ||
          eachMeasurementVar?.measurement_type ===
            eachRequireExecutor.measurementVar,
      );

      if (foundedVaraible) return parseFloat(foundedVaraible?.value);
      return 0;
    },
  );

  if (enableLog) {
    console.log('Founded Measurement Type ', foundedVariable);
  }

  return resultingFunctionExecutor({
    resultingFunctionType,
    input: foundedVariable,
    enableLog,
  });
};

export default findVariableAndExecute;
