export const TIMING = {
  DAILY: {
    status_code: 'DAILY',
    description: 'รายวัน',
  },
  MONTHY: {
    status_code: 'MONTHY',
    description: 'รายเดือน',
  },
  //   QUARTER: {
  //     status_code: 'QUARTER',
  //     description: 'รายไตรมาส',
  //   },
  YEARLY: {
    status_code: 'YEARLY',
    description: 'รายปี',
  },
};

export default TIMING;
