// import React from 'react';
// import { FlowAnalysisGraph } from '@ant-design/graphs';
// import { useTheme } from '@mui/styles';
// import _ from 'lodash';
// import PropTypes from 'prop-types';

// const FlowingChart = ({ nodes }) => {
//   const theme = useTheme();

//   const modifiedStep = {
//     nodes,
//     edges: _.map(nodes, (each) => {
//       if (each?.id === _.size(nodes) - 1) {
//         return {
//           source: (each?.id - 1)?.toString(),
//           target: each?.id?.toString(),
//         };
//       }
//       return {
//         source: each?.id?.toString(),
//         target: (_.size(nodes) - 1)?.toString(),
//       };
//     }),
//   };

//   const config = {
//     data: modifiedStep,
//     height: 400,
//     nodeCfg: {
//       size: [200, 25],
//       style: (cfg, group, type) => {
//         const styles = {
//           fill: '#E6EAF1',
//           stroke: cfg?.value?.active
//             ? theme?.palette?.primary?.light
//             : '#B2BED5',
//           lineWidth: cfg?.value?.active ? 2 : 1,
//           radius: [2, 2, 2, 2],
//           text: {
//             fontFamily: ['Noto Sans Thai Looped', 'Noto Sans'],
//           },
//         };
//         return styles;
//       },

//       title: {
//         containerStyle: {
//           fill: 'transparent',
//         },
//         style: {
//           fill: '#000',
//           fontSize: 12,
//           fontFamily: ['Noto Sans Thai Looped', 'Noto Sans'],
//         },
//       },
//       items: {
//         containerStyle: {
//           fill: '#fff',
//         },
//         padding: 6,
//         style: (cfg, group, type) => {
//           const styles = {
//             icon: {
//               width: 12,
//               height: 12,
//             },
//             text: {
//               fill: '#aaa',
//               fontFamily: ['Noto Sans Thai Looped', 'Noto Sans'],
//             },
//             value: {
//               fontFamily: ['Noto Sans Thai Looped', 'Noto Sans'],
//             },
//           };
//           return styles[type];
//         },
//       },
//     },
//     edgeCfg: {
//       type: 'polyline',
//       endArrow: true,
//       label: {
//         style: {
//           fill: '#aaa',
//           fontSize: 12,
//           fillOpacity: 1,
//           fontFamily: ['Noto Sans Thai Looped', 'Noto Sans'],
//         },
//       },
//     },
//     markerCfg: (cfg) => {
//       const { edges } = modifiedStep;
//       return {
//         position: 'right',
//         show: edges?.find((item) => item?.source === cfg?.id),
//       };
//     },
//     behaviors: [
//       //   allowDrag ? 'drag-canvas' : '',
//       //   allowDrag ? 'zoom-canvas' : '',
//       //   'drag-node'
//     ],
//   };

//   return (
//     <div>
//       <FlowAnalysisGraph {...config} />
//     </div>
//   );
// };

// export default FlowingChart;

// FlowingChart.propTypes = {
//   nodes: PropTypes.arrayOf(PropTypes.object),
// };

import React from 'react';

export default function FlowingChart({}) {
  return <div></div>;
}
