import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  DaySpotAndRangePicker,
  Error,
  FactoryCapitalCostTable,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import { CAPITAL_COST_TYPE } from '@iarcpsu/emanufac-constant';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import * as actions from '@/redux/actions';

/**
 * @function PlaceLaborCosts
 * @description Display a collections or a list of PlaceLaborCosts from database
 */

export default function FactoryCapitalCosts({ title, subtitle }) {
  const dispatch = useDispatch();
  const factoryCapitalCost = useSelector((state) => state.factoryCapitalCost);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedCostType, setSelectedCostType] = useState(null);
  const [total, setTotal] = useState(0);

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.factoryCapitalCostAll({
          page,
          size,
          startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
          endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : '',
          costType: selectedCostType || '',
        }),
      );
    } catch (error) {
      dispatch(actions.factoryCapitalCostError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, startDate, endDate, selectedCostType]);

  useEffect(() => {
    if (factoryCapitalCost) {
      setTotal(factoryCapitalCost?.total);
    }

    return () => {};
  }, [factoryCapitalCost]);

  const handleDelete = async (id) => {
    try {
      Swal.fire({
        icon: 'warning',
        text: 'ยืนยันการลบข้อมูล',
        showConfirmButton: true,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await dispatch(actions.factoryCapitalCostDelete(id));
          queryDataFromServer();
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'ลบข้อมูลไม่สำเร็จ',
        text: error?.message,
      });
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (factoryCapitalCost.isLoading) {
    return <Loading />;
  }
  if (!factoryCapitalCost.isLoading && factoryCapitalCost.isCompleted) {
    return (
      <div>
        <div className="flex justify-between items-center">
          {renderTitle()}
          <Link to="/cem/factory-capital-cost/create">
            <Button variant="contained">เพิ่ม</Button>
          </Link>
        </div>
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="flex justify-between items-center">
                <div className="w-full lg:w-4/5">
                  <DaySpotAndRangePicker
                    enable
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    size="small"
                    flex
                  />
                </div>
                <div className="w-full lg:w-1/5 px-2">
                  <FormControl fullWidth size="small">
                    <InputLabel>ชนิดของต้นทุน</InputLabel>
                    <Select
                      size="small"
                      label="ชนิดของต้นทุน"
                      fullWidth
                      value={selectedCostType}
                      onChange={(e) => setSelectedCostType(e.target.value)}
                    >
                      <MenuItem value={''}>ทั้งหมด</MenuItem>
                      {_.map(CAPITAL_COST_TYPE, (value, key) => (
                        <MenuItem key={key} value={value.status_code}>
                          {value.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="my-2">
          <FactoryCapitalCostTable
            page={page}
            factoryCapitalCost={factoryCapitalCost}
            setPage={setPage}
            size={size}
            total={total}
            setSize={setSize}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    );
  }
  return <Error />;
}

FactoryCapitalCosts.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

FactoryCapitalCosts.defaultProps = {
  title: '',
  subtitle: '',
};
