const REPORT_SUB_PLACE_IN_WAREHOUSE = {
  WAREHOUSE_STOCK: {
    status_code: "WAREHOUSE_STOCK",
    description: "หน้าสินค้าในคลัง",
  },
  DETAIL_STOCK_LOT: {
    status_code: "DETAIL_STOCK_LOT",
    description: "รายละเอียดของ Stock Lot",
  },
  GROUP_BY_IN_PLACE: {
    status_code: "GROUP_BY_IN_PLACE",
    description: "หน้าสินค้าในคลังที่ Group จากสิ่งต่างๆ (ในคลังที่เหมาะสม)",
  },
  GROUP_BY_NOT_IN_PLACE: {
    status_code: "GROUP_BY_NOT_IN_PLACE",
    description: "หน้าสินค้าในคลังที่ Group จากสิ่งต่างๆ (ในคลังที่ไม่เหมาะสม)",
  },
};

module.exports = REPORT_SUB_PLACE_IN_WAREHOUSE;
