import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import _ from 'lodash';
import { io } from 'socket.io-client';

export function MainFooter() {
  const socket = useRef();
  const information = useSelector((state) => state.information);
  const me = useSelector((state) => state.me);
  const [isHealthCheckOk, setIsHealthCheckOk] = useState(false);

  const renderBottomNavigation = () => (
    <div className="fixed  bottom-0  w-full lg:hidden print-disable">
      <BottomNavigation showLabels>
        {_.map(information?.mobile_quick_menu, (eachQuickMenu, index) => (
          <BottomNavigationAction
            label={eachQuickMenu?.name}
            key={index}
            LinkComponent={Link}
            to={eachQuickMenu?.link}
            icon={<i className={`fas ${eachQuickMenu?.icon}`}></i>}
          />
        ))}
      </BottomNavigation>
    </div>
  );

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SOCKET_IO_URL);
    if (me?.userData) {
      socket.current.emit('health-check', {
        referenceNumber: me?.userData?._id,
        client: navigator.userAgent,
      });
    }
  }, [me]);

  useEffect(() => {
    socket.current.on(me?.userData?._id, (data) => {
      if (data?.success) {
        setIsHealthCheckOk(true);
      }
    });
  }, [me]);

  return (
    <div>
      {!_.isEmpty(information?.mobile_quick_menu) && renderBottomNavigation()}
      <footer className="container  font-system mx-auto items-center bottom-0 z-10  print-disable">
        <hr className="mb-4 border-b-1 border-gray-300" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4">
            <div className="text-sm text-gray-600 font-normal py-1 text-center md:text-left">
              Copyright © {new Date().getFullYear()} Prince Of Songkla
              University by{'  '}
              <a
                href="https://iarc.psu.ac.th/"
                className="text-gray-600 hover:text-gray-800 text-sm font-normal py-1"
              >
                Intelligent Automation Research Center
              </a>
              , Sponsored By{' '}
              <a
                href={information?.sponsor?.website}
                className="text-gray-600 hover:text-gray-800 text-sm font-normal py-1"
              >
                {information?.sponsor?.name}
              </a>{' '}
              <br />
              <br />
              E-Manufac Ver {process.env.REACT_APP_VERSION} <br />
              {isHealthCheckOk ? 'HealthCheck OK' : 'Socket IO Connect Fail'} |
              Serv No. {information?.serverNumber}
            </div>
          </div>
          <div className="w-full md:w-8/12 px-4">
            <ul className="flex flex-wrap list-none md:justify-end  justify-center">
              <li>
                <a
                  href="https://emanufac.efactory.biz/documents"
                  className="text-gray-700 hover:text-gray-900 text-sm font-normal block py-1 px-3"
                >
                  User Manual
                </a>
              </li>
              <li>
                <a
                  href="https://emanufac.efactory.biz/"
                  className="text-gray-700 hover:text-gray-900 text-sm font-normal block py-1 px-3"
                >
                  About E-Manufac
                </a>
              </li>
              <li>
                <a
                  href="https://iarc.psu.ac.th/"
                  className="text-gray-700 hover:text-gray-900 text-sm font-normal block py-1 px-3"
                >
                  IARC PSU
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>{' '}
    </div>
  );
}

export default MainFooter;
