import _ from 'lodash';

const findMergeIncomingResult = (currentProcess, watchedMaterial) => {
  const currentProcessIndex = currentProcess?.current?.index;

  const nextProcess = _.find(
    currentProcess?.steps,
    (each) => each?.index === currentProcessIndex + 1,
  );

  const nextProcessMainMaterial = nextProcess?.main_material;

  const inputMaterialList = _.map(
    nextProcessMainMaterial?.input_materials,
    (each) => {
      const foundMaterial = _.find(
        watchedMaterial,
        (eachMaterial) => eachMaterial?.material?._id === each?.material?._id,
      );
      return { ...each, material: foundMaterial };
    },
  );

  const mergedIncomingAndResult = [
    ...inputMaterialList,
    {
      material: {
        material: nextProcessMainMaterial,
        result: true,
        end: _.sum(
          _.map(
            inputMaterialList,
            (eachIncoming) =>
              parseFloat(eachIncoming?.material?.end) * eachIncoming?.fraction,
          ),
        ),
      },
    },
  ];

  console.log('Merged Material', mergedIncomingAndResult);
  return mergedIncomingAndResult;
};

export default findMergeIncomingResult;
