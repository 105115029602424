const PROCESS_STATUS = {
  PROCESS_PENDING: {
    status_code: "PROCESS_PENDING",
    description: "รอการทำงาน",
  },
  PROCESS_IN_PROGRESS: {
    status_code: "PROCESS_IN_PROGRESS",
    description: "กำลังดำเนินงาน",
  },
  PROCESS_DONE: {
    status_code: "PROCESS_DONE",
    description: "สำเร็จ",
  },
};

module.exports = PROCESS_STATUS;
