import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

const DatetimeGauge = ({ measurementData }) => (
  <div>
    <div className="text-xl font-semibold">
      {measurementData?.date_value
        ? dayjs(measurementData?.date_value).format('D MMM BBBB HH:mm')
        : '-'}
    </div>
  </div>
);

DatetimeGauge.propTypes = {
  measurementData: PropTypes.object,
};

export default DatetimeGauge;
