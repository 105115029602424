import api from '../../../configs/api';
import graphqlClient from '../../../configs/graphqlClient';
import {
  WITHDRAW_DESTINATION_ALL,
  WITHDRAW_DESTINATION_DELETE,
  WITHDRAW_DESTINATION_ERROR,
  WITHDRAW_DESTINATION_GET,
  WITHDRAW_DESTINATION_LOADING,
  WITHDRAW_DESTINATION_POST,
  WITHDRAW_DESTINATION_PUT,
} from '../types';

export const withdrawDestinationCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: WITHDRAW_DESTINATION_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/withdraw-destination`,
      {
        ...payload,
      },
    );
    dispatch({ type: WITHDRAW_DESTINATION_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: WITHDRAW_DESTINATION_ERROR });
    throw new Error(error);
  }
};

export const withdrawDestinationAll = ({
  name = '',
  size = 10,
  page = 1,
}) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/withdraw-destination?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: WITHDRAW_DESTINATION_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: WITHDRAW_DESTINATION_ERROR });
    throw new Error(error);
  }
};

export const withdrawDestinationError = async (dispatch) => {
  dispatch({ type: WITHDRAW_DESTINATION_ERROR });
};

export const withdrawDestinationAllQuery = ({ query }) => async (dispatch) => {
  try {
    const queryResult = await graphqlClient.request(query);
    const data = queryResult?.findResultingFunctionTypes;
    //  console.log('Query Data', queryResult);
    if (!data) {
      dispatch({ type: WITHDRAW_DESTINATION_ALL });
    }

    dispatch({ type: WITHDRAW_DESTINATION_ALL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: WITHDRAW_DESTINATION_ERROR });
    throw new Error(error);
  }
};

export const withdrawDestinationGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/withdraw-destination/${id}`,
    );
    if (status === 200) {
      dispatch({ type: WITHDRAW_DESTINATION_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: WITHDRAW_DESTINATION_ERROR });
    throw new Error(error);
  }
};

export const withdrawDestinationStateSet = (data) => async (dispatch) => {
  dispatch({ type: WITHDRAW_DESTINATION_ALL, payload: data });
};

export const withdrawDestinationPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: WITHDRAW_DESTINATION_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/withdraw-destination/${id}`,
      payload,
    );
    dispatch({ type: WITHDRAW_DESTINATION_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: WITHDRAW_DESTINATION_ERROR });
    throw new Error(error);
  }
};
export const withdrawDestinationDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: WITHDRAW_DESTINATION_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/withdraw-destination/${id}`,
    );
    dispatch({ type: WITHDRAW_DESTINATION_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: WITHDRAW_DESTINATION_ERROR });
    throw new Error(error);
  }
};
