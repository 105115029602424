import {
  ORG_CONSTANT_ALL,
  ORG_CONSTANT_GET,
  ORG_CONSTANT_PUT,
  ORG_CONSTANT_DELETE,
  ORG_CONSTANT_POST,
  ORG_CONSTANT_LOADING,
  ORG_CONSTANT_ERROR,
} from '../../actions/types';

const initialState = {
  ORG_CONSTANT: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case ORG_CONSTANT_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case ORG_CONSTANT_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case ORG_CONSTANT_POST:
      return { isLoading: false, isCompleted: true };
    case ORG_CONSTANT_PUT:
      return { isLoading: false, isCompleted: true };
    case ORG_CONSTANT_DELETE:
      return { isLoading: false, isCompleted: true };
    case ORG_CONSTANT_LOADING:
      return { isLoading: true, isCompleted: true };
    case ORG_CONSTANT_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
