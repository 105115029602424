/* eslint-disable import/no-named-as-default-member */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  DaySpotAndRangePicker,
  Error,
  Loading,
  ManufacturingOrderTable,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { MANUFAC_ORDER_STATUS } from '@iarcpsu/emanufac-constant';
import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';

function HistoryManufacturingOrder({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const manufacturingOrderLog = useSelector(
    (state) => state.manufacturingOrderLog,
  );
  const me = useSelector((state) => state.me);

  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState('');
  const [isIncludeIncumbant, setIsIncludeIncumbant] = useState(false);
  const [orderByField, setOrderByField] = useState('running_number');
  const [orderBy, setOrderBy] = useState('desc');

  const query = gql`
    query FindManufacturingOrder($input: ManufacturingOrderInput) {
      findManufacturingOrderLogs(input: $input) {
        currPage
        lastPage
        total
        rows {
          _id
          id
          assigner {
            firstname
            lastname
          }
          prefix
          running_number
          customer {
            type_code
            name
            address
            phone_number
          }
          process {
            product {
              type_code
              name
            }
            produce_material_instead
            product_as_material {
              type_code
              name
            }
            current {
              index
              step {
                name
              }
            }
            customer {
              type_code
              name
            }
            quantity
            price_per_unit
            additional_unit
            remark
          }
          discount
          completed
          deleted
          start_date
          expected_date
          remark
          status
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        input: {
          page,
          size,
          name,
          enableFetchInside: false,
          fetchCurrentStep: false,
          fetchCustomer: false,
          fetchMaterial: false,
          fetchProduct: false,
          fetchProcess: false,
          stage: isIncludeIncumbant ? undefined : 'HISTORY',
          includeIncumbant: isIncludeIncumbant ? true : undefined,
          startDate: startDate
            ? dayjs(startDate).format('YYYY-MM-DD')
            : undefined,
          endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : undefined,
          status: status === '' ? undefined : status,
          orderByField,
          orderBy,
        },
      });

      const manufacturingOrderData = queryResult?.findManufacturingOrderLogs;

      dispatch(actions.manufacturingOrderLogStateSet(manufacturingOrderData));
    } catch (err) {
      console.error('Mo,  Have an errors');
    }
  };

  const callInsideFunction = async () => {
    console.log('Call Function Inside');
    const insideQuery = await graphqlClient.request(query, {
      input: {
        page,
        size,
        name,
        enableFetchInside: false,
        fetchCurrentStep: true,
        fetchCustomer: true,
        fetchMaterial: false,
        fetchProduct: true,
        fetchProcess: true,
        stage: isIncludeIncumbant ? undefined : 'HISTORY',
        includeIncumbant: isIncludeIncumbant ? true : undefined,
        startDate: startDate
          ? dayjs(startDate).format('YYYY-MM-DD')
          : undefined,
        endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : undefined,
        status: status === '' ? undefined : status,
        orderByField,
        orderBy,
      },
    });

    const insidedQueryResult = insideQuery?.findManufacturingOrderLogs;

    dispatch(actions.manufacturingOrderLogStateSet(insidedQueryResult));
  };

  useEffect(() => {
    queryDataFromServer();
    console.log('Waiting for time to fetch inside');
    const fetchInsideTimeout = setTimeout(() => {
      callInsideFunction();
    }, 1000);
    return () => {
      console.log('Clear Fetch inside Timeout');
      clearTimeout(fetchInsideTimeout);
    };
  }, [
    name,
    page,
    size,
    status,
    startDate,
    endDate,
    isIncludeIncumbant,
    orderByField,
    orderBy,
  ]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    setTotal(manufacturingOrderLog?.total);
    return () => {};
  }, [manufacturingOrderLog]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-6/12">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="md:w-4/12 px-2">
          <FormControlLabel
            control={
              <Switch
                onChange={(e) => setIsIncludeIncumbant(e.target.checked)}
                checked={isIncludeIncumbant}
              />
            }
            label="รวมรายการที่กำลังดำเนินการ"
          />
        </div>{' '}
        <div className="md:w-3/12 px-1">
          <FormControl fullWidth>
            <InputLabel>สถานะ</InputLabel>
            <Select
              label="สถานะ"
              onChange={(e) => setStatus(e.target.value)}
              value={status}
              fullWidth
              size="small"
            >
              <MenuItem value={''}>ไม่กรองสถานะ</MenuItem>
              {_.map(MANUFAC_ORDER_STATUS, (each) => (
                <MenuItem value={each.status_code}>
                  {each?.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </Card>
  );

  const handleToInfoPage = (id) => {
    history.push(`/mms/manufacturing-orders/view/${id}`);
  };

  const handlePushToEditPage = (id) => {
    history.push(`/mms/manufacturing-orders/edit/${id}`);
  };

  const handleToDistributeMaterial = (id) => {
    history.push(`/mms/material-distribute/${id}`);
  };

  const handleDelete = async (id) => {
    const confirm = window.confirm('ยืนยันการลบคำสั่งผลิต');
    if (confirm) {
      try {
        await dispatch(actions.manufacturingOrderDelete(id));
        await dispatch(actions.manufacturingOrderAll({ name, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleCreateProductionLine = (manufacOrderId, productId) => {
    history.push(`create-production-line/${manufacOrderId}/${productId}`);
  };

  const tableProps = {
    handleChangePage,
    handleChangeRowsPerPage,
    handleCreateProductionLine,
    handleDelete,
    handlePushToEditPage,
    handleToDistributeMaterial,
    handleToInfoPage,
    manufacturingOrder: manufacturingOrderLog,
    page,
    size,
    total,
    statusCenter: true,
    displayCancleButton: false,
    displayStatusBadge: false,
    orderByField,
    orderBy,
    setOrderByField,
    setOrderBy,
  };

  const renderTable = () => (
    <div>
      <ManufacturingOrderTable {...tableProps} />
    </div>
  );

  if (manufacturingOrderLog?.isLoading) {
    return <Loading />;
  }

  if (!manufacturingOrderLog?.isLoading && manufacturingOrderLog?.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        {renderSearch()}
        <Card className="my-2">
          <CardContent>
            <DaySpotAndRangePicker
              enable
              flex
              setEndDate={setEndDate}
              setStartDate={setStartDate}
              startDate={startDate}
              endDate={endDate}
              size="small"
            />
          </CardContent>
        </Card>
        {renderTable()}
      </div>
    );
  }
  return <Error />;
}

HistoryManufacturingOrder.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

HistoryManufacturingOrder.defaultProps = {
  title: '',
  subtitle: '',
};

export default HistoryManufacturingOrder;
