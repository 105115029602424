import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ArrowRight } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  BackButton,
  CardMaterialInOutTotalOnMove,
  Error,
  Loading,
  LoadingDialogWithTimeout,
  LoadingLinear,
  LotInformationForm,
  MaterialAmountAddList,
  MMSSelectMaterialForm,
  ModalConfirmTransformMaterial,
  SelectMaterialToMoveForm,
  SocketIOLoading,
  TransactionTypeSelect,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import {
  INVENTORY_WORKFLOW,
  MATERIAL_STOCK_STATUS,
  MQ_TASK,
} from '@iarcpsu/emanufac-constant';
import { Alert, Button, Card, CardContent, Skeleton } from '@mui/material';
import dayjs from 'dayjs';
import { gql } from 'graphql-request';
import _, { set } from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import { api, app as appConfig, config } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import { WordLocalizeContext } from '@/contexts/WordLocalizeContext';
import * as actions from '@/redux/actions';

/**
 * @function TransformMaterial
 * @description Display a collections or a list of WithdrawMaterial from database
 */

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export default function FixedTransformMaterial({ title, subtitle }) {
  const dispatch = useDispatch();
  const browserQuery = useQuery();
  const history = useHistory();
  const { findWordInPlace } = useContext(WordLocalizeContext);

  const me = useSelector((state) => state.me);
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const employee = useSelector((state) => state.employee);
  const department = useSelector((state) => state.department);
  const materialTransactionType = useSelector(
    (state) => state.materialTransactionType,
  );
  const material = useSelector((state) => state.material);
  const customer = useSelector((state) => state.customer);
  const product = useSelector((state) => state.product);
  const place = useSelector((state) => state.place);
  const information = useSelector((state) => state.information);
  const machine = useSelector((state) => state.machine);

  const [dialogLoading, setOpenDialogLoading] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [destinationPlace, setDestinationPlace] = useState();
  const [rerender, setRerender] = useState(false);
  const [modalConfirmOn, setModalConfirmOn] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const imsSetting = information?.setting?.ims;

  // Generate Reference Number for long run task
  useEffect(() => {
    const refNo = hash({
      date: new Date(),
      user: me?.userData?._id,
    });

    setReferenceNumber(refNo);

    return () => {};
  }, []);

  /** Initial the form */
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      materialLots: [],
      materialIn: [],
    },
  });

  /** เตรียมข้อมูล */
  const graphqlQuery = gql`
    query FindWithdrawMaterial(
      $customerInput: CustomerInput
      $employeeInput: EmployeeInput
      $departmentInput: DepartmentInput
      $machineInput: MachineInput
    ) {
      findCustomers(input: $customerInput) {
        total
        rows {
          _id
          type_code
          name
        }
      }
      findEmployees(input: $employeeInput) {
        rows {
          _id
          firstname
          lastname
          is_virtual_group_employee
          image {
            url
          }
        }
      }
      findDepartments(input: $departmentInput) {
        rows {
          _id
          id
          name
        }
      }
      findMachines(input: $machineInput) {
        rows {
          _id
          id
          name
        }
      }
    }
  `;

  // Prepare Data
  const queryDataFromServer = async () => {
    try {
      dispatch(actions.materialReset());
      const materialWithoutStockResult = await graphqlClient.request(
        graphqlQuery,
        {
          customerInput: {
            page: 1,
            size: appConfig.maxFetchSize,
          },
          employeeInput: {
            page: 1,
            size: appConfig.maxFetchSize,
          },
          departmentInput: {
            page: 1,
            size: appConfig.maxFetchSize,
          },
          machineInput: {
            page: 1,
            size: appConfig.maxFetchSize,
          },
        },
      );

      const customerData = materialWithoutStockResult?.findCustomers;
      const employeeData = materialWithoutStockResult?.findEmployees;
      const departmentData = materialWithoutStockResult?.findDepartments;
      const machineData = materialWithoutStockResult?.findMachines;

      dispatch(actions.customerStateSet(customerData));
      dispatch(actions.employeeStateSet(employeeData));
      dispatch(actions.departmentStateSet(departmentData));
      dispatch(actions.machineStateSet(machineData));
      dispatch(
        actions.productAll({
          page: 1,
          size: config.maxFetchSize,
          fetchStockLot: '',
        }),
      );
      setIsReady(false);
      await dispatch(
        actions.materialAll({
          fetchStockLot: '',
          page: 1,
          size: config.maxFetchSize,
          placeRestriction: true,
          place:
            browserQuery.get('inplace') === 'true'
              ? browserQuery.get('source')
              : browserQuery.get('destination'),
        }),
      );
      setIsReady(true);
      // Transaction Type
      dispatch(actions.materialTransactionTypeAll({}));
      // Get Source Place
      dispatch(actions.placeGet(browserQuery.get('source')));
      // Get Destination Place เอาเข้าผ่าน Redux ไม่ได้เพราะว่าเราเอา Source เข้าเสียแล้ว
      if (browserQuery?.get('destination')) {
        const res = await api.get(
          `${process.env.REACT_APP_API_URL}/place/${browserQuery.get(
            'destination',
          )}`,
        );
        setDestinationPlace(res.data);
      }
    } catch (error) {
      console.error('Query Error', error);
    }
  };

  const getMaterialStockLot = () => {
    try {
      dispatch(
        actions.materialStockLotAll({
          size: appConfig.maxFetchSize,
          page: 1,
          ids: browserQuery.get('lots'),
          stockStatus: '',
        }),
      );
    } catch (error) {
      console.error('Material StockLot Cannot Get', error);
    }
  };

  // Divide Transaction Type
  const inFilterTxnType = _.filter(
    materialTransactionType?.rows,
    (each) => each?.direction === 'add',
  );

  const outFilterTxnType = _.filter(
    materialTransactionType?.rows,
    (each) => each?.direction === 'desc',
  );

  useEffect(() => {
    queryDataFromServer();
    getMaterialStockLot();

    return () => {};
  }, []);

  // Handle While Finishing Main Fuction
  const handleSocketIOFunction = {
    onSuccess: (args) => {
      setOpenDialogLoading(false);
      Swal.fire({
        icon: 'success',
        title: 'แปลงวัตถุดิบสำเร็จ',
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        if (browserQuery.get('inplace')) {
          console.log('args', args);
          history.push(
            `/ims/material-stock/transfer/success/${
              args?.payload?.previousLot?.[0]?._id
            }?source=${browserQuery.get('source')}`,
          );
        } else {
          history.push(
            `/ims/material-stock/place/${browserQuery.get('destination')}`,
          );
        }
      });
    },
    onFail: (args) => {
      setOpenDialogLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: args?.message,
      });
    },
  };

  // Setting Input (Out Stock)
  useEffect(() => {
    const inStockMaterial = _.filter(
      materialStockLot?.rows,
      (each) => each?.status === MATERIAL_STOCK_STATUS.IN_STOCK.status_code,
    );
    const instockWithSelect = _.map(inStockMaterial, (each) => ({
      ...each,
      selected: true,
    }));
    setValue('materialOut', instockWithSelect);

    return () => {};
  }, [materialStockLot]);

  /** Main Logic */

  const handleTransformMaterial = async (data) => {
    try {
      setModalConfirmOn(false);
      setOpenDialogLoading(true);
      //   console.log('Data', data);
      let materialIn = [];
      if (browserQuery.get('fixedoutput') === 'true') {
        materialIn = _.filter(data?.materialIn, (each) => each?.quantity !== 0);
      } else {
        materialIn = _.map(data?.processes?.[0]?.materials, (each) => ({
          ...each,
          quantity: each?.begin,
          amount: each?.begin,
        }));
      }

      console.log('Material In', materialIn);
      const materialOut = _.map(
        _.filter(
          data?.materialOut,
          (eachFilter) => eachFilter?.selected === true,
        ),
        (each) => ({
          material: each?.material?._id,
          lotId: each?._id,
          quantity: parseFloat(each?.amount),
          amount: each?.quantity - parseFloat(each?.amount),
        }),
      );

      if (_.isEmpty(materialOut)) {
        alert('กรุณาเลือกล็อตที่ต้องการ');
        setOpenDialogLoading(false);
        return;
      }
      const anotherEmployees = _.map(data?.another_employees, (each) => ({
        employee: each?.employee?._id,
        amount: parseFloat(each?.amount),
      }));

      const filterAnotherEmployees = _.filter(
        anotherEmployees,
        (each) => each?.amount > 0,
      );

      const payload = {
        materialOut,
        materialIn,
        in_txn: data?.out_material_transaction_type,
        out_txn: data?.material_transaction_type,
        place: browserQuery.get('source'),
        inPlace: Boolean(browserQuery.get('inplace')),
        destination: browserQuery.get('inplace')
          ? null
          : browserQuery.get('destination'),
        referenceNumber,
        employee: data?.employee?._id,
        order_department: data?.order_department,
        machine: data?.machine?._id,
        remark: data?.remark,
        receipt_date: dayjs(data.receipt_date).toDate(),
        another_employees: filterAnotherEmployees,
        duration: data.duration,
        inventory_workflow: INVENTORY_WORKFLOW.IN_PROGRESS.status_code,
      };

      console.log('Payload', payload);

      await dispatch(actions.materialStockLotTransform(payload));
    } catch (error) {
      alert(`เกิดความผิดพลาด ${error?.message}`);
    }
  };

  /** Display a component  */
  const renderTitle = () => (
    <ViewTitle
      title={findWordInPlace('transfrom_to_another') || title}
      subtitle={subtitle}
    />
  );

  if (materialTransactionType?.isLoading || !materialTransactionType?.rows) {
    return <Loading />;
  }

  if (
    !materialTransactionType.isLoading &&
    materialTransactionType.isCompleted &&
    materialTransactionType?.rows
  ) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <BackButton />
        </div>
        <LoadingDialogWithTimeout
          isLoading={dialogLoading}
          label={`กำลังดำเนินการเปลี่ยนวัตถุดิบ ${statusMessage || ''}`}
        />
        <ModalConfirmTransformMaterial
          open={modalConfirmOn}
          handleConfirm={handleSubmit(handleTransformMaterial)}
          materialIn={watch('materialIn')}
          onClose={() => setModalConfirmOn(false)}
        />
        <SocketIOLoading
          taskCode={MQ_TASK.TRANSFORM_MATERIAL.status_code}
          handleSuccess={handleSocketIOFunction.onSuccess}
          handleFail={handleSocketIOFunction.onFail}
          referenceNumber={referenceNumber}
          setStatusMessage={setStatusMessage}
        />

        <div>
          <div>
            <Card>
              <CardContent>
                <div className="flex flex-wrap justify-center items-center">
                  <div className="w-1/3 lg:w-1/6 p-2">
                    <div>คลังต้นทาง</div>
                    <div className="text-lg font-semibold">{place?.name}</div>
                  </div>
                  <div className="w-1/3 lg:w-1/6">
                    <ArrowRight />
                  </div>
                  <div className="w-1/3 lg:w-1/6">
                    {' '}
                    <div>คลังปลายทาง</div>
                    <div className="text-lg font-semibold">
                      {browserQuery.get('inplace') === 'true'
                        ? place?.name
                        : destinationPlace?.name}
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
          <form onSubmit={handleSubmit(handleTransformMaterial)}>
            <div className="w-full">
              <div className="my-2">
                <Card>
                  <CardContent>
                    <LotInformationForm
                      control={control}
                      defaultValue={null}
                      employee={employee}
                      departments={department?.rows}
                      me={me?.userData}
                      machine={machine}
                      allowShowMachineInput={true}
                      allowGroupEmployeeInput={
                        imsSetting?.operationDisplay
                          ?.showGroupEmployeeInputWhenInTransform
                      }
                      allowDurationInput={
                        imsSetting?.operationDisplay
                          ?.showDurationInputWhenInTransform
                      }
                      previousLotReciptDate={
                        materialStockLot?.rows?.[0]?.receipt_date
                      }
                      setValue={setValue}
                      watch={watch}
                    />{' '}
                  </CardContent>
                </Card>
              </div>
              <div className="w-full font-semibold text-lg my-4 ">
                วัตถุดิบต้นทาง
              </div>
              <TransactionTypeSelect
                control={control}
                controllerName="material_transaction_type"
                errors={errors}
                transactionList={outFilterTxnType}
              />

              <div className="my-2">
                <SelectMaterialToMoveForm
                  control={control}
                  controlKey={'materialOut'}
                  information={information}
                  setValue={setValue}
                  watch={watch}
                  rerender={rerender}
                  setRerender={setRerender}
                />
              </div>

              <div className="w-full font-semibold text-lg my-4">
                วัตถุดิบปลายทาง
              </div>
              <TransactionTypeSelect
                control={control}
                controllerName="out_material_transaction_type"
                errors={errors}
                transactionList={inFilterTxnType}
              />
            </div>
            {place?.place_action?.for_stranger?.show_total_inout && (
              <div className="w-full my-2">
                <CardMaterialInOutTotalOnMove
                  materialIn={watch('materialIn')}
                  materialOut={watch('materialOut')}
                />
              </div>
            )}
            {!localStorage.getItem('TAB_NOTIFY_HIDE') && (
              <Alert severity="info">
                <div className="flex gap-2">
                  <div>
                    ท่านสามารถกดปุ่ม <b>Tab</b>{' '}
                    เพื่อเลื่อนตำแหน่งปัจจุบันของช่องที่ท่านกรอกได้
                  </div>
                  <div>
                    <Button
                      size="small"
                      onClick={() => {
                        localStorage.setItem('TAB_NOTIFY_HIDE', 'true');
                      }}
                      variant="outlined"
                    >
                      ไม่ต้องแสดงอีกในคร้งต่อไป
                    </Button>
                  </div>
                </div>
              </Alert>
            )}
            {isReady ? (
              <div className="w-full my-2">
                {browserQuery.get('fixedoutput') === 'true' ? (
                  <div>
                    {!material?.isLoading ? (
                      <MaterialAmountAddList
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        enableAddPreferProduct={
                          place?.place_action?.enable_add_prefer_product
                        }
                        enableCustomer={place?.place_action?.enable_customer}
                        customer={customer}
                        product={product}
                        place={place}
                        rerender={rerender}
                        setRerender={setRerender}
                        information={information}
                        materialRows={material?.rows}
                      />
                    ) : (
                      <div>
                        <Skeleton />
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <Card>
                      <CardContent>
                        <MMSSelectMaterialForm
                          control={control}
                          errors={errors}
                          material={material}
                          productIndex={0}
                          setValue={setValue}
                          watch={watch}
                        />{' '}
                      </CardContent>
                    </Card>
                  </div>
                )}{' '}
              </div>
            ) : (
              <LoadingLinear />
            )}
          </form>{' '}
          <div className="flex justify-end gap-2 my-4">
            <Button
              type="button"
              // onClick={handleSubmit(handleTransformMaterial)}
              color="primary"
              variant="contained"
              onClick={() => setModalConfirmOn(true)}
            >
              ยืนยัน
            </Button>
          </div>
        </div>
      </div>
    );
  }
  return <Error />;
}

FixedTransformMaterial.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

FixedTransformMaterial.defaultProps = {
  title: '',
  subtitle: '',
};
