import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  DaySpotAndRangePicker,
  Error,
  ExportExcelContainer,
  Loading,
  QuotationReport,
  QuotationTable,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import { config } from '@/configs';
import * as actions from '@/redux/actions';
import { QuotationUtils } from '@/utils/excelColumn';

/**
 * @function Quotations
 * @description Display a collections or a list of Quotations from database
 */

export default function Quotations({ title, subtitle }) {
  const dispatch = useDispatch();
  const quotation = useSelector((state) => state.quotation);
  const information = useSelector((state) => state.information);
  const me = useSelector((state) => state.me);
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [total, setTotal] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState('');
  const [includeLog, setIncludeLog] = useState(false);
  const [allowTimeQuery, setAllowTimeQuery] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [name, setName] = useState('');
  const [expressOnly, setExpressOnly] = useState(false);

  const handlePrintPdf = (row) => {
    try {
      QuotationReport(row, information, true);
    } catch (error) {
      QuotationReport(row, information);
    }
  };

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.quotationAll({
          page,
          size,
          withLog: includeLog || '',
          startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
          endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : '',
          express: expressOnly || '',
          name,
        }),
      );
    } catch (error) {
      dispatch(actions.quotationError());
    }
  };

  const quotationQuery = {
    page: 1,
    size: config.maxFetchSize,
    withLog: includeLog || '',
    startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
    endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : '',
    express: expressOnly || '',
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, includeLog, startDate, endDate, expressOnly, name]);

  useEffect(() => {
    if (quotation?.rows) {
      setTotal(quotation?.total);
    }

    return () => {};
  }, [quotation]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleDelete = async (id) => {
    try {
      Swal.fire({
        icon: 'question',
        title: 'ยืนยันการลบใบเสนอราคา',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await dispatch(actions.quotationDelete(id, me?.userData?._id));
          queryDataFromServer();
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'ไม่สามารถลบใบเสนอราคา',
        text: error?.message,
      });
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (quotation.isLoading) {
    return <Loading />;
  }
  if (!quotation.isLoading && quotation.isCompleted) {
    return (
      <div>
        <div className="flex justify-between items-center">
          {renderTitle()}
          <div>
            <Link to={'/spm/quotation/create'}>
              <Button variant="contained">เพิ่ม</Button>
            </Link>
          </div>
        </div>
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="flex flex-wrap">
                <div className="w-full md:w-1/2 lg:w-1/3">
                  <TextField
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    size="small"
                    label="ค้นหา"
                    fullWidth
                  />
                </div>
                <div className="w-1/2 lg:w-1/6 ml-4">
                  <FormControlLabel
                    label="รวมประวัติ"
                    control={
                      <Switch
                        checked={includeLog}
                        onChange={(e) => setIncludeLog(e.target.checked)}
                      />
                    }
                  />
                </div>{' '}
                <div className="w-1/2 lg:w-1/6 ml-4">
                  <FormControlLabel
                    label="เฉพาะด่วนเท่านั้น"
                    control={
                      <Switch
                        checked={expressOnly}
                        onChange={(e) => setExpressOnly(e.target.checked)}
                      />
                    }
                  />
                </div>
                <div className="w-1/2 lg:w-1/6 ml-4 flex justify-end">
                  <ExportExcelContainer
                    columnList={QuotationUtils.columns}
                    currentData={quotation?.rows}
                    dataAPIEndPoint="quotation"
                    dataQuery={quotationQuery}
                    sheetName="Quotation"
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="flex flex-wrap items-center">
                <div className="w-full lg:w-1/3 flex items-center">
                  <Switch
                    value={allowTimeQuery}
                    onChange={(event) => {
                      setAllowTimeQuery(event.target.checked);
                      setStartDate(null);
                      setEndDate(null);
                      if (event.target.checked !== true) {
                        setStartDate(null);
                        setEndDate(null);
                        history.push(`?page=${page}&size=${size}&start=&end=`);
                      }
                    }}
                  />
                  <div>เปิดใช้การกรองเวลา</div>
                </div>
                <div className="w-full lg:w-2/3">
                  <DaySpotAndRangePicker
                    addSearchQuery={true}
                    anotherQuery={`?page=${page}&size=${size}&start=${
                      startDate ? dayjs(startDate).format('YYYY-MM-DD') : ''
                    }&end=${
                      endDate ? dayjs(endDate).format('YYYY-MM-DD') : ''
                    }`}
                    flex
                    size="small"
                    enable={allowTimeQuery}
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="my-2">
          <QuotationTable
            quotation={quotation}
            page={page}
            size={size}
            total={total}
            setPage={setPage}
            setSize={setSize}
            handleDelete={handleDelete}
            handlePrintPdf={handlePrintPdf}
          />
        </div>
      </div>
    );
  }
  return <Error />;
}

Quotations.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Quotations.defaultProps = {
  title: '',
  subtitle: '',
};
