import {
  INTERNAL_DEPOSIT_ALL,
  INTERNAL_DEPOSIT_DELETE,
  INTERNAL_DEPOSIT_ERROR,
  INTERNAL_DEPOSIT_GET,
  INTERNAL_DEPOSIT_LOADING,
  INTERNAL_DEPOSIT_POST,
  INTERNAL_DEPOSIT_PUT,
} from '../../actions/types';
import { BaseReducer } from '../../class';

const basedReducerObject = new BaseReducer({
  allConst: INTERNAL_DEPOSIT_ALL,
  getConst: INTERNAL_DEPOSIT_GET,
  postConst: INTERNAL_DEPOSIT_POST,
  putConst: INTERNAL_DEPOSIT_PUT,
  deleteConst: INTERNAL_DEPOSIT_DELETE,
  errorConst: INTERNAL_DEPOSIT_ERROR,
  loadingConst: INTERNAL_DEPOSIT_LOADING,
});

const initialState = {
  isLoading: true,
  isCompleted: true,
};

const InternalDepositReducer = (state = initialState, action) =>
  basedReducerObject.getReducer(state, action);

export default InternalDepositReducer;
