import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  BackButton,
  Error,
  Loading,
  QuotationTransactionTable,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';

/**
 * @function EachQuotationTransaction
 * @description Display a collections or a list of EachQuotationTransaction from database
 */

export default function EachQuotationTransaction({ title, subtitle }) {
  const dispatch = useDispatch();
  const qtAndIDTransaction = useSelector((state) => state.qtAndIDTransaction);
  const quotation = useSelector((state) => state.quotation);
  const params = useParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(undefined);

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.qtAndIdTransactionAll({
          page,
          size,
          quotation: params.id,
        }),
      );
      dispatch(actions.quotationGet(params.id));
    } catch (error) {
      dispatch(actions.qtAndIdTransactionError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, params]);

  useEffect(() => {
    if (qtAndIDTransaction) {
      setTotal(qtAndIDTransaction?.total);
    }

    return () => {};
  }, [qtAndIDTransaction]);

  const renderTitle = () => (
    <ViewTitle
      title={`${title} ของใบเสนอราคา ${quotation?.running_number}`}
      subtitle={subtitle}
    />
  );

  if (qtAndIDTransaction.isLoading) {
    return <Loading />;
  }
  if (!qtAndIDTransaction.isLoading && qtAndIDTransaction.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <QuotationTransactionTable
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            total={total}
            quotationTransaction={qtAndIDTransaction}
          />
        </div>
      </div>
    );
  }
  return <Error />;
}

EachQuotationTransaction.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EachQuotationTransaction.defaultProps = {
  title: '',
  subtitle: '',
};
