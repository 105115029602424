import React from 'react';
import { Backdrop, Button, Card, Fade, Modal } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import MultiUnitProdOrMatBox from '../../Box/Rendering/MultiUnitProdOrMatBox';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50vw',
  boxShadow: 24,
  p: 4,
};

function ModalConfirmTransformMaterial({
  open,
  onClose,
  handleConfirm,
  materialIn,
}) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style} className="max-w-lg">
          <h3 className="font-semibold text-lg">วัตถุดิบปลายทางที่ได้รับ</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 max-h-96 overflow-y-auto">
            {_.map(
              _.filter(
                materialIn,
                (eachFilter) =>
                  eachFilter?.quantity !== 0 && eachFilter?.quantity !== '0',
              ),
              (each, index) => (
                <div key={index} className="flex gap-2 items-center">
                  <div>{each?.material?.name}</div>
                  <MultiUnitProdOrMatBox
                    foundMaterial={each?.material}
                    quantity={each?.quantity}
                  />
                </div>
              ),
            )}
          </div>
          <div className="flex justify-end gap-2">
            <Button
              variant="outlined"
              type="button"
              onClick={() => {
                onClose();
              }}
            >
              ยกเลิก
            </Button>
            <Button
              variant="contained"
              type="button"
              onClick={() => handleConfirm()}
            >
              ยืนยัน
            </Button>
          </div>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalConfirmTransformMaterial.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  materialIn: PropTypes.arrayOf(PropTypes.object),
};

export default ModalConfirmTransformMaterial;
