import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  BackButton,
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Button, Card, CardContent, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import * as actions from '@/redux/actions';

/**
 * @function RejectQuotation
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function RejectQuotation({ title, subtitle }) {
  const dispatch = useDispatch();
  const quotation = useSelector((state) => state.quotation);
  const history = useHistory();
  const params = useParams();
  const { control, handleSubmit } = useForm();

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.quotationGet(params.id));
    } catch (error) {
      dispatch(actions.quotationError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const handleRejectQuotation = async (data) => {
    try {
      const payload = {
        ...data,
        deleted: true,
      };
      await dispatch(actions.quotationPut(params.id, payload));
      Swal.fire({
        icon: 'success',
        title: 'ยกเลิกใบเสนอราคาสำเร็จ',
      }).then(() => {
        history.goBack();
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'ไม่สามารถยกเลิกใบเสนอราคาได้',
        text: error.message,
      });
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (quotation.isLoading) {
    return <Loading />;
  }

  if (!quotation.isLoading && quotation.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <form onSubmit={handleSubmit(handleRejectQuotation)}>
            <Card>
              <CardContent>
                <div>
                  <div className="flex flex-wrap w-full">
                    <div className="w-full my-1">
                      <span className="font-semibold">หมายเลขใบเสนอราคา</span>{' '}
                      {quotation?.prefix}
                      {quotation?.running_number}
                    </div>{' '}
                    <div className="w-full my-1">
                      <Controller
                        control={control}
                        name="delete_note"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="เหตุผลการปฏิเสธ"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>{' '}
            <div className="flex gap-2 my-2 justify-end">
              <Button color="primary" variant="contained" type="submit">
                ยืนยัน
              </Button>
            </div>{' '}
          </form>
        </div>
      </div>
    );
  }
  return <Error />;
}

RejectQuotation.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

RejectQuotation.defaultProps = {
  title: '',
  subtitle: '',
};
