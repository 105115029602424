import React from 'react';
import { useSelector } from 'react-redux';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import MultiUnitProdOrMatBox from '../../Box/Rendering/MultiUnitProdOrMatBox';

function MaterialStockLotWithoutMaterialNameTable({
  foundedMaterialStockLot,
  selectedMaterial,
  page,
  size,
  setPage,
  setSize,
}) {
  const information = useSelector((state) => state.information);
  const {
    setting: { ims: imsSetting },
  } = information;
  return (
    <div>
      {' '}
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">เลขอ้างอิง</div>
                </TableCell>
                {imsSetting?.display?.lot_number && (
                  <TableCell>
                    <div className="font-bold">หมายเลขล็อต</div>
                  </TableCell>
                )}
                <TableCell>
                  <div className="font-bold">วันที่เข้าคลัง</div>
                </TableCell>
                {(imsSetting?.display?.production_date ||
                  imsSetting?.display?.expiration_date) && (
                  <TableCell>
                    <div className="font-bold">วันผลิต/วันหมดอายุ</div>
                  </TableCell>
                )}
                <TableCell>
                  <div className="font-bold">คลังสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">จำนวน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">คงเหลือ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(foundedMaterialStockLot?.rows) ? (
                _.map(foundedMaterialStockLot?.rows, (row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="text-xs">
                        {(page - 1) * size + index + 1}
                      </div>
                    </TableCell>
                    <TableCell>{row?.running_number || '-'}</TableCell>
                    {imsSetting?.display?.lot_number && (
                      <TableCell>{row?.lot_number}</TableCell>
                    )}
                    <TableCell>
                      {dayjs(row?.receipt_date || row?.createdAt).format(
                        'D MMM BBBB HH:mm',
                      ) || '-'}
                    </TableCell>
                    {(imsSetting?.display?.production_date ||
                      imsSetting?.display?.expiration_date) && (
                      <TableCell>
                        {imsSetting?.display?.production_date && (
                          <div>
                            <div>วันผลิต</div>
                            <div>
                              {dayjs(row?.production_date).format('D MMM BBBB')}{' '}
                            </div>
                          </div>
                        )}
                        {imsSetting?.display?.expiration_date && (
                          <div>
                            <div>วันหมดอายุ</div>
                            <div>
                              {dayjs(row?.expiration_date).format('D MMM BBBB')}{' '}
                            </div>
                          </div>
                        )}
                      </TableCell>
                    )}
                    <TableCell>{row?.place?.name}</TableCell>
                    <TableCell>
                      <MultiUnitProdOrMatBox
                        foundMaterial={selectedMaterial}
                        quantity={row?.quantity}
                      />
                    </TableCell>
                    <TableCell>
                      <MultiUnitProdOrMatBox
                        foundMaterial={selectedMaterial}
                        quantity={row?.amount}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          page={page - 1}
          count={foundedMaterialStockLot?.total || 1}
          rowsPerPage={size}
          onPageChange={(e, newValue) => {
            setPage(newValue + 1);
          }}
          onRowsPerPageChange={(e) => {
            setSize(+e.target.value);
            setPage(1);
          }}
        />
      </Paper>
    </div>
  );
}

export default MaterialStockLotWithoutMaterialNameTable;

MaterialStockLotWithoutMaterialNameTable.propTypes = {
  foundedMaterialStockLot: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
    total: PropTypes.number,
  }),
  selectedMaterial: PropTypes.object,
  page: PropTypes.number,
  size: PropTypes.number,
  setPage: PropTypes.func,
  setSize: PropTypes.func,
};
