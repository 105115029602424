import { BaseAction } from '../../class';
import {
  PLACE_LABOR_COST_ALL,
  PLACE_LABOR_COST_DELETE,
  PLACE_LABOR_COST_ERROR,
  PLACE_LABOR_COST_GET,
  PLACE_LABOR_COST_LOADING,
  PLACE_LABOR_COST_POST,
  PLACE_LABOR_COST_PUT,
} from '../types';

const placeLaborCostAction = new BaseAction('place-labor-cost', {
  allConst: PLACE_LABOR_COST_ALL,
  getConst: PLACE_LABOR_COST_GET,
  postConst: PLACE_LABOR_COST_POST,
  putConst: PLACE_LABOR_COST_PUT,
  deleteConst: PLACE_LABOR_COST_DELETE,
  errorConst: PLACE_LABOR_COST_ERROR,
  loadingConst: PLACE_LABOR_COST_LOADING,
});

export const placeLaborCostCreate = (data) => placeLaborCostAction.create(data);
export const placeLaborCostAll = ({ name, page, size, ...query }) =>
  placeLaborCostAction.getAllData({ name, page, size, ...query });
export const placeLaborCostGet = (id) => placeLaborCostAction.getOneData(id);
export const placeLaborCostPut = (id, data) =>
  placeLaborCostAction.editOne(id, data);
export const placeLaborCostDelete = (id) => placeLaborCostAction.deleteOne(id);
export const placeLaborCostStateSet = (data) =>
  placeLaborCostAction.stateSet(data);
export const placeLaborCostStateOneSet = (data) =>
  placeLaborCostAction.stateOneSet(data);
export const placeLaborCostReset = () => placeLaborCostAction.reset();
export const placeLaborCostError = () => placeLaborCostAction.onError();
