import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import {
  Autocomplete,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FONT_AWESOME_ICON_LIST as fontAwesomeIconName } from '@/utils/constants';

export function QuickMenuForm({ control }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'mobile_quick_menu',
  });

  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2 ">
        <div className="flex gap-2">
          <div> รายการเมนู </div>
          <div>
            {' '}
            <Button size="small" variant="contained" onClick={() => append({})}>
              เพิ่ม
            </Button>{' '}
          </div>
        </div>
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableCell>ลำดับที่</TableCell>
            <TableCell>ชื่อ</TableCell>
            <TableCell>ไอคอน</TableCell>
            <TableCell>ลิงค์</TableCell>
            <TableCell>ดำเนินการ</TableCell>
          </TableHead>
          <TableBody>
            {_.map(fields, (eachField, index) => (
              <TableRow key={eachField.id}>
                <TableCell> {index + 1} </TableCell>
                <TableCell>
                  <Controller
                    name={`mobile_quick_menu[${index}].name`}
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} label="ชื่อปุ่ม" size="small" />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name={`mobile_quick_menu[${index}].icon`}
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        disableClearable
                        name={field.name}
                        ref={field.ref}
                        defaultValue={field.value}
                        options={fontAwesomeIconName}
                        autoHighlight
                        getOptionLabel={(option) => `${option}`}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{
                              fontSize: 15,
                              '& > span': { mr: '10px', fontSize: 18 },
                            }}
                            {...props}
                          >
                            <div className="flex gap-2">
                              <i className={`fas ${option}`}></i>
                              {option}
                            </div>
                          </Box>
                        )}
                        onChange={(e, value) => {
                          field.onChange(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="เลือกไอคอน"
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name={`mobile_quick_menu[${index}].link`}
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} label="ลิงค์" size="small" />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    size="small"
                    variant="contained"
                    color="error"
                    onClick={() => remove(index)}
                  >
                    ลบ
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

QuickMenuForm.propTypes = {
  control: PropTypes.object,
};

export default QuickMenuForm;
