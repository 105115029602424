import React from 'react';
import { Link } from 'react-router-dom';
import { LABOR_COST_ESTIMATION_METHOD } from '@iarcpsu/emanufac-constant';
import { currencyFormatter } from '@iarcpsu/emanufac-utils/functions';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import MultiUnitProdOrMatBox from '../../Rendering/MultiUnitProdOrMatBox';

function LaborCostLumpsum({
  previousLotList,
  place,
  currentLot,
  isCurrentAsProductLot,
}) {
  const currentLotMaterial = currentLot?.material;
  const filterLotOnPlace = _.filter(
    previousLotList,
    (each) => each?.place?._id === place?._id,
  );
  const allowCategory = place?.allow_categories;
  const allowCategoryWithComma = _.join(allowCategory, ',');

  // หาวัตถุดิบที่อนุญาคิให้อยู่ในคลังนี้
  let filterAllowanceMaterial;
  // กรณีเป็น Work in process เอามาเฉพาะที่สืบอดมาได้เท่านั้น
  if (currentLotMaterial?.is_workin_process) {
    const inputMaterialList = _.map(
      currentLotMaterial?.input_materials,
      (each) => each?.material,
    );

    filterAllowanceMaterial = _.filter(
      filterLotOnPlace,
      (each) =>
        _.includes(allowCategory, each?.material?.material_type) &&
        _.includes(inputMaterialList, each?.material?._id),
    );
    // กรณีไม่เป็น WIP เอามาทุกตัว
  } else {
    filterAllowanceMaterial = _.filter(filterLotOnPlace, (each) =>
      _.includes(allowCategory, each?.material?.material_type),
    );
  }

  const totalCostList = _.map(
    filterAllowanceMaterial,
    (each) => each?.quantity * (each?.material?.unit_labor_cost || 0),
  );
  const totalCost = _.sum(totalCostList);
  // TODO: ยังไม่ได้ถูกต้องทั้งหมด เพราะว่าจริงๆ สินค้าชิ้นนี้ไม่ได้มาจากวัตถุดิบทุกตัว

  if (isCurrentAsProductLot) {
    return <div>ระบบยังไม่รองรับจากคลังสินค้า</div>;
  }
  return (
    <div className="my-2">
      <div className="flex justify-between">
        <div className="font-semibold my-2">
          ต้นทุนแรงงาน:{' '}
          {LABOR_COST_ESTIMATION_METHOD.LUMPSUM_BY_MATERIAL.description}
        </div>
        <div className="flex gap-1">
          <Link
            to={`/ims/materials/ingredient/edit/${currentLotMaterial?._id}`}
          >
            <Button variant="outlined" color="warning" size="small">
              แก้ไขวัตถุดิบส่วนประกอบ
            </Button>
          </Link>
          <Link
            to={`/ims/materials/labor-cost/edit?material-type=${allowCategoryWithComma}`}
          >
            <Button variant="outlined" color="warning" size="small">
              แก้ไขค่าแรง
            </Button>
          </Link>
        </div>
      </div>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">เลขอ้างอิงล็อต</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">วัตถุดิบ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">ปริมาณที่ได้</div>
            </TableCell>{' '}
            <TableCell>
              <div className="font-bold">ค่าแรงต่อหน่วย</div>
            </TableCell>{' '}
            <TableCell>
              <div className="font-bold">รวมมูลค่า</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!_.isEmpty(filterAllowanceMaterial) ? (
            _.map(filterAllowanceMaterial, (row, index) => (
              <TableRow
                key={index}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  <div className="text-xs">{index + 1}</div>
                </TableCell>
                <TableCell>{row?.running_number}</TableCell>
                <TableCell>{row?.material?.name}</TableCell>
                <TableCell>
                  <MultiUnitProdOrMatBox
                    foundMaterial={row?.material}
                    quantity={row?.quantity}
                  />
                </TableCell>
                <TableCell>
                  {row?.material?.unit_labor_cost
                    ? currencyFormatter.format(row?.material?.unit_labor_cost)
                    : '-'}{' '}
                  บาท
                </TableCell>
                <TableCell>
                  {currencyFormatter.format(
                    row?.quantity * (row?.material?.unit_labor_cost || 0),
                  )}{' '}
                  บาท
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6}>
                <div className="text-center">ไม่มีข้อมูล</div>
              </TableCell>
            </TableRow>
          )}
          {!_.isEmpty(filterAllowanceMaterial) && (
            <TableRow
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              <TableCell component="th" scope="row" colSpan={5}>
                <div className="font-semibold">รวม</div>
              </TableCell>
              <TableCell>{currencyFormatter.format(totalCost)} บาท</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}

export default LaborCostLumpsum;

LaborCostLumpsum.propTypes = {
  previousLotList: PropTypes.array,
  place: PropTypes.object,
};
