const CRM_TYPE = {
  CUSTOMER: {
    status_code: "CUSTOMER",
    description: "ลูกค้า",
  },
  SUPPLIER: {
    status_code: "SUPPLIER",
    description: "คู่ค้า",
  },
  BOTH: {
    status_code: "BOTH",
    description: "ลูกค้าและคู่ค้า",
  },
};

module.exports = CRM_TYPE;
