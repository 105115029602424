import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Card, CardContent, TextField } from '@mui/material';
import _ from 'lodash';

import { Loading } from '@iarcpsu/emanufac-components/src/components/Loading';
import { Error } from '@iarcpsu/emanufac-components/src/components/Error';
import { ViewTitle } from '@iarcpsu/emanufac-components/src/components/ViewTitle';
import { BackButton } from '@iarcpsu/emanufac-components/src/components/Button';
import * as actions from '../../redux/actions';
import * as CONSTANT from '../../utils/constants';
import { MMS } from '../../utils/functions';
import dayjs from 'dayjs';

function DetailPayroll({ title, subtitle }) {
  const params = useParams();
  const payroll = useSelector((state) => state.payroll);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.payrollGet(params.id));
    return () => {};
  }, [params.id]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderBackButton = () => (
    <div>
      <BackButton />
    </div>
  );

  const isDailyPayroll =
    payroll?.payroll_type === CONSTANT.PAYROLL_TYPE.DAILY.status_code;

  const sumOvertimeCost = _.sum(
    _.map(payroll?.overtimes, (eachPayroll) => eachPayroll?.cost),
  );
  const renderDetail = () => (
    <div className="my-4">
      <Card>
        <CardContent>
          <div className="flex flex-wrap divide-y">
            <div className="w-full py-4 text-lg font-semibold font-display ">
              การจ่ายค่าตอบแทน
              {CONSTANT.PAYROLL_TYPE[payroll?.payroll_type]?.description}
            </div>
            <div className="w-full md:w-1/3 py-4 px-2 font-semibold font-display">
              พนักงาน
            </div>
            <div className="w-full md:w-2/3 py-4 px-2 ">
              {payroll?.employee?.firstname} {payroll?.employee?.lastname}{' '}
            </div>
            <div className="w-full md:w-1/3 py-4 px-2 font-semibold font-display">
              ประจำ
              {isDailyPayroll ? 'วันที่' : 'เดือน'}
            </div>
            <div className="w-full md:w-2/3 py-4 px-2 ">
              {isDailyPayroll
                ? dayjs(payroll.date).format('D MMMM BBBB')
                : dayjs(payroll.date).format('MMMM BBBB')}
            </div>
            {isDailyPayroll && (
              <div className="w-full md:w-1/3 py-4 px-2 font-semibold font-display">
                ค่าตอบแทนในเวลางาน
              </div>
            )}
            {isDailyPayroll && (
              <div className="w-full md:w-2/3 py-4 px-2 ">
                <div>
                  เข้างาน{' '}
                  {dayjs(payroll?.daily_attribute?.start_time).format(
                    'HH.mm น.',
                  )}{' '}
                  - เลิกงาน{' '}
                  {dayjs(payroll?.daily_attribute?.end_time).format('HH.mm น.')}{' '}
                </div>

                <div> ค่าตอบแทน {payroll?.main_cost} บาท</div>
              </div>
            )}
            <div className="w-full md:w-1/3 py-4 px-2 font-semibold font-display">
              ทำงานล่วงเวลา
            </div>
            {!_.isEmpty(payroll?.overtimes) ? (
              <div className="w-full md:w-2/3 py-4 px-2 ">
                {_.map(payroll?.overtimes, (eachOvertime, index) => (
                  <div key={index}>
                    - ช่วงเวลา{' '}
                    {dayjs(eachOvertime.start_time).format('HH.mm น.')} -{' '}
                    {dayjs(eachOvertime.end_time).format('HH.mm น.')} ค่าตอบแทน{' '}
                    {eachOvertime.cost} บาท
                  </div>
                ))}
                <div>รวม {sumOvertimeCost} บาท </div>
              </div>
            ) : (
              <div className="w-full md:w-2/3 py-4 px-2 ">
                ไม่มีการทำล่วงเวลา
              </div>
            )}{' '}
            <div className="w-full md:w-1/3 py-4 px-2 font-semibold font-display">
              ค่าตอบแทนรวม
            </div>{' '}
            <div className="w-full md:w-2/3 py-4 px-2 ">
              {payroll?.total_cost} บาท
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );

  if (payroll.isLoading) {
    return <Loading />;
  }
  if (!payroll.isLoading && payroll.isCompleted) {
    return (
      <div>
        <div className="flex flex-wrap justify-between">{renderTitle()}</div>
        {renderBackButton()}
        {renderDetail()}
      </div>
    );
  }
  return <Error />;
}

DetailPayroll.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default DetailPayroll;
