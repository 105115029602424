import React, { useEffect, useRef, useState } from 'react';
import { ArrowDown, ArrowUp, ChevronDown, Upload } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Error,
  ExportExcelContainer,
  Loading,
  RatingTag,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { CRM_TYPE } from '@iarcpsu/emanufac-constant';
import { CustomerColumn } from '@iarcpsu/emanufac-utils/columns';
import {
  Button,
  ButtonGroup,
  Card,
  ClickAwayListener,
  FormControl,
  Grow,
  InputAdornment,
  InputLabel,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { config, useQuery } from '@/configs';

import * as actions from '../../redux/actions';

function Customers({ title, subtitle }) {
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const { control } = useForm();
  const importButtonRef = useRef();

  const customer = useSelector((state) => state.customer);
  const customerType = useSelector((state) => state.customerType);

  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(
    query.get('page') ? parseInt(query.get('page'), 10) : 1,
  );
  const [size, setSize] = useState(
    query.get('size') ? parseInt(query.get('size'), 10) : 10,
  );
  const [total, setTotal] = useState(undefined);
  const [selectCustomerType, setSelectCustomerType] = useState(
    query.get('type') || '',
  );
  const [importCustomerOpen, setImportCustomerOpen] = useState(false);
  const [orderByField, setOrderByField] = useState('type_code');
  const [orderBy, setOrderBy] = useState('asc');

  const customerAll = () => {
    try {
      dispatch(
        actions.customerAll({
          name,
          page,
          size,
          selectCustomerType,
          orderBy,
          orderByField,
        }),
      );
    } catch (error) {
      console.error('Customer Get All Error', error);
    }
  };

  useEffect(() => {
    customerAll();
    return () => {};
  }, [name, page, size, selectCustomerType, orderBy, orderByField]);

  useEffect(() => {
    dispatch(actions.customerTypeAll({ name: '' }));
    dispatch(actions.paymentTypeAll({ name: '' }));

    return () => {};
  }, []);

  useEffect(() => {
    setTotal(customer?.total);
    return () => {};
  }, [customer]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      if (customer?.rows) {
        setPage(1);
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleDeleteCustomer = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.customerDelete(id));
        customerAll();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handlePushToCreateCustomer = () => {
    history.push('/crm/customer/create');
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
    history.push({
      search: `?${new URLSearchParams({ page, size }).toString()}`,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    history.push({
      search: `?${new URLSearchParams({ page: newPage + 1, size }).toString()}`,
    });
  };

  const handleSelectCustomerType = (type) => {
    setSelectCustomerType(type);
    history.push({
      search: `?${new URLSearchParams({
        page,
        size,
        type: type || '',
      }).toString()}`,
    });
  };

  const apiQuery = {
    name: '',
    page: 1,
    size: config.maxFetchSize,
    selectCustomerType: selectCustomerType || '',
    orderBy: orderBy || '',
    orderByField: orderByField || '',
  };

  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4 gap-2">
      <Button
        variant="contained"
        onClick={() => {
          handlePushToCreateCustomer();
        }}
      >
        เพิ่ม
      </Button>
      <React.Fragment>
        <ButtonGroup
          variant="contained"
          ref={importButtonRef}
          aria-label="split button"
          color="success"
        >
          <Button
            variant="contained"
            onClick={() => {
              history.push('customer/upload');
            }}
            startIcon={<Upload />}
          >
            Import
          </Button>
          <Button
            size="small"
            aria-controls={importCustomerOpen ? 'split-button-menu' : undefined}
            aria-expanded={importCustomerOpen ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={() => setImportCustomerOpen(!importCustomerOpen)}
          >
            <ChevronDown />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={importCustomerOpen}
          anchorEl={importButtonRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={() => {
                    setImportCustomerOpen(false);
                  }}
                >
                  <MenuList id="split-button-menu" autoFocusItem>
                    <MenuItem
                      onClick={() => {
                        history.push('customer/upload');
                      }}
                    >
                      Import จากไฟล์ Excel
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        history.push('customer/upload-edit-code');
                      }}
                    >
                      Import เพื่อแก้รหัสลูกค้า
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </React.Fragment>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row gap-2">
        <div className="w-full md:w-1/3">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/3">
          <Controller
            name={'status_code'}
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                <InputLabel id="type" size={'small'}>
                  ค้นหาด้วยประเภทลูกค้า
                </InputLabel>
                <Select
                  {...field}
                  label="ค้นหาด้วยประเภทลูกค้า"
                  size={'small'}
                  fullWidth
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    handleSelectCustomerType(e.target.value);
                  }}
                >
                  <MenuItem value="">ทั้งหมด</MenuItem>
                  {_.map(customerType.rows, (_customerType) => (
                    <MenuItem
                      value={_customerType?._id}
                      key={_customerType?._id}
                    >
                      {_customerType?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </div>{' '}
        <div className="w-full md:w-1/3">
          <ExportExcelContainer
            columnList={CustomerColumn}
            currentData={customer?.rows}
            dataAPIEndPoint="customer"
            dataQuery={apiQuery}
            sheetName="Customer"
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell
                  className="cursor-pointer hover:bg-gray-100"
                  onClick={() => {
                    setOrderByField('type_code');
                    if (orderBy === 'asc') {
                      setOrderBy('desc');
                    } else {
                      setOrderBy('asc');
                    }
                  }}
                >
                  <div className="font-bold flex gap-1">
                    รหัสลูกค้า
                    {orderByField === 'type_code' && orderBy === 'desc' ? (
                      <ArrowDown size="16" className="self-center" />
                    ) : (
                      <ArrowUp size="16" className="self-center" />
                    )}
                  </div>
                </TableCell>
                <TableCell
                  className="cursor-pointer hover:bg-gray-100"
                  onClick={() => {
                    setOrderByField('name');
                    if (orderBy === 'asc') {
                      setOrderBy('desc');
                    } else {
                      setOrderBy('asc');
                    }
                  }}
                >
                  <div className="font-bold flex gap-1">
                    {' '}
                    ชื่อ
                    {orderByField === 'name' && orderBy === 'desc' ? (
                      <ArrowDown size="16" className="self-center" />
                    ) : (
                      <ArrowUp size="16" className="self-center" />
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> สถานะ</div>
                </TableCell>
                <TableCell
                  className="cursor-pointer hover:bg-gray-100"
                  onClick={() => {
                    setOrderByField('type.name');
                    if (orderBy === 'asc') {
                      setOrderBy('desc');
                    } else {
                      setOrderBy('asc');
                    }
                  }}
                >
                  <div className="font-bold flex gap-1">
                    {' '}
                    ประเภท
                    {orderByField === 'type.name' && orderBy === 'desc' ? (
                      <ArrowDown size="16" className="self-center" />
                    ) : (
                      <ArrowUp size="16" className="self-center" />
                    )}
                  </div>
                </TableCell>{' '}
                <TableCell
                  className="cursor-pointer hover:bg-gray-100"
                  onClick={() => {
                    setOrderByField('createdAt');
                    if (orderBy === 'asc') {
                      setOrderBy('desc');
                    } else {
                      setOrderBy('asc');
                    }
                  }}
                >
                  <div className="font-bold flex gap-1">
                    {' '}
                    วันที่สร้าง
                    {orderByField === 'createdAt' && orderBy === 'desc' ? (
                      <ArrowDown size="16" className="self-center" />
                    ) : (
                      <ArrowUp size="16" className="self-center" />
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(customer.rows) ? (
                customer.rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>{row.type_code || '-'}</TableCell>
                    <TableCell>
                      {row.name} <RatingTag customer={row} asBox />{' '}
                    </TableCell>
                    <TableCell>
                      {CRM_TYPE[row?.status]?.description || ''}
                    </TableCell>
                    <TableCell>{row?.type?.name || '-'}</TableCell>
                    <TableCell>
                      {dayjs(row?.createdAt).format('D MMM BBBB')}
                    </TableCell>
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        {' '}
                        <Link to={`/crm/customer/detail/${row.id}`}>
                          <Button
                            variant="contained"
                            color={'info'}
                            size={'small'}
                          >
                            รายละเอียด
                          </Button>
                        </Link>
                        <Link to={`/crm/customer/edit/${row.id}`}>
                          <Button
                            variant="contained"
                            color={'warning'}
                            size={'small'}
                          >
                            แก้ไข
                          </Button>
                        </Link>
                        <Button
                          variant="contained"
                          color={'error'}
                          size={'small'}
                          onClick={() => {
                            handleDeleteCustomer(row?.id);
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
          showFirstButton
          showLastButton
        />
      </Paper>
    </div>
  );

  if (customer.isLoading) {
    return <Loading />;
  }
  if (!customer.isLoading && customer.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButtom()}</div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error />;
}

Customers.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Customers.defaultProps = {
  title: '',
  subtitle: '',
};

export default Customers;
