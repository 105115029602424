import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

const StringInput = ({
  control,
  measurementType,
  measurementControllerName = '',
  defaultValue,
}) => (
  <div>
    <div>
      <Controller
        control={control}
        name={`${measurementControllerName}.value`}
        defaultValue={defaultValue}
        render={({ field }) => (
          <TextField
            {...field}
            label={measurementType?.label}
            required={measurementType?.required}
            size="small"
            fullWidth
          />
        )}
      />
    </div>
  </div>
);

StringInput.propTypes = {
  control: PropTypes.object,
  measurementType: PropTypes.object,
  measurementControllerName: PropTypes.string,
  defaultValue: PropTypes.string,
};

export default StringInput;
