import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  DaySpotAndRangePicker,
  Error,
  LoadingLinear,
  ProductStockFilterBox,
  ProductStockLotTable,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import { Card, CardContent, Switch } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';
import { useQuery } from '@/utils/functions';

import { config } from '../../configs';

/**
 * @function MaterialCosts
 * @description Display a collections or a list of MaterialCosts from database
 */

export default function ProductLotList({ title, subtitle }) {
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const productStockLot = useSelector((state) => state.productStockLot);
  const information = useSelector((state) => state.information);
  const warehouse = useSelector((state) => state.warehouse);
  const product = useSelector((state) => state.product);
  const customer = useSelector((state) => state.customer);
  const [page, setPage] = useState(query.get('page') || 1);
  const [size, setSize] = useState(query.get('size') || 20);
  const [startDate, setStartDate] = useState(
    query.get('start') ? new Date(query.get('start')) : null,
  );
  const [endDate, setEndDate] = useState(
    query.get('end') ? new Date(query.get('end')) : null,
  );
  const [allowTimeQuery, setAllowTimeQuery] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState(
    query.get('warehouse') ? { _id: query.get('warehouse') } : null,
  );
  const [selectedCustomer, setSelectedCustomer] = useState(
    query.get('customer') ? { _id: query.get('customer') } : null,
  );

  const [selectedStockStatus, setSelectedStockStatus] = useState(
    query.get('stockStatus') ? { _id: query.get('stockStatus') } : null,
  );
  const [selectedProduct, setSelectedProduct] = useState(
    query.get('product') ? { _id: query.get('product') } : null,
  );
  const [isLoading, setIsLoading] = useState(false);

  const queryDataFromServer = async () => {
    try {
      setIsLoading(true);
      dispatch(
        actions.productStockLotAll({
          page,
          size,
          warehouse: selectedWarehouse?._id || '',
          product: selectedProduct?._id || '',
          customer: selectedCustomer?._id || '',
          stockStatus: selectedStockStatus || '',
          startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
          endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : '',
        }),
      );
      setIsLoading(false);
    } catch (error) {
      dispatch(actions.productStockLotError());
    }
  };

  useEffect(() => {
    dispatch(
      actions.warehouseAll({
        page: 1,
        size: config.maxFetchSize,
      }),
    );

    dispatch(
      actions.customerAll({
        page: 1,
        size: config.maxFetchSize,
      }),
    );

    dispatch(
      actions.productAll({
        page: 1,
        size: config.max,
      }),
    );

    return () => {};
  }, []);

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [
    page,
    size,
    selectedWarehouse,
    selectedCustomer,
    selectedStockStatus,
    selectedProduct,
    startDate,
    endDate,
  ]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (!productStockLot.isLoading && !productStockLot.isCompleted) {
    return <Error />;
  }

  return (
    <div>
      <div className="flex justify-between items-center">{renderTitle()}</div>

      <div className="my-2">
        <Card>
          <CardContent>
            <div className="flex flex-wrap items-center">
              <div className="w-full lg:w-1/3 flex items-center">
                <Switch
                  value={allowTimeQuery}
                  onChange={(event) => {
                    setAllowTimeQuery(event.target.checked);
                    if (event.target.checked !== true) {
                      setStartDate(null);
                      setEndDate(null);
                      history.push(
                        `?page=${page}&size=${size}&place=${
                          selectedWarehouse?._id || ''
                        }&product=${selectedProduct?._id || ''}&customer=${
                          selectedCustomer?._id || ''
                        }&stockStatus=${selectedStockStatus || ''}&start=&end=`,
                      );
                    }
                  }}
                />
                <div>เปิดใช้การกรองเวลา</div>
              </div>
              <div className="w-full lg:w-2/3">
                <DaySpotAndRangePicker
                  addSearchQuery={true}
                  anotherQuery={`?page=${page}&size=${size}&place=${
                    selectedWarehouse?._id || ''
                  }&product=${selectedProduct?._id || ''}&customer=${
                    selectedCustomer?._id || ''
                  }&stockStatus=${selectedStockStatus || ''}&start=${
                    startDate ? dayjs(startDate).format('YYYY-MM-DD') : ''
                  }&end=${endDate ? dayjs(endDate).format('YYYY-MM-DD') : ''}`}
                  flex
                  size="small"
                  enable={allowTimeQuery}
                  setEndDate={setEndDate}
                  setStartDate={setStartDate}
                  startDate={startDate}
                  endDate={endDate}
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      <div className="my-2">
        <ProductStockFilterBox
          customer={customer}
          product={product}
          warehouse={warehouse}
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          selectedStockStatus={selectedStockStatus}
          setSelectedStockStatus={setSelectedStockStatus}
          selectedWarehouse={selectedWarehouse}
          setSelectedWarehouse={setSelectedWarehouse}
          showSupplierFilter
          allowAddToSeachQuery
          anotherQuery={`?page=${page}&size=${size}&start=${
            startDate ? dayjs(startDate).format('YYYY-MM-DD') : ''
          }&end=${endDate ? dayjs(endDate).format('YYYY-MM-DD') : ''}`}
        />
      </div>
      {productStockLot.isLoading || isLoading ? (
        <LoadingLinear />
      ) : (
        <div className="my-2">
          <ProductStockLotTable
            handleDelete={() => {}}
            multiProduct
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            productStockLot={productStockLot?.rows}
            showActionButton
            showStatus
            total={productStockLot.total}
            wmsSetting={information?.setting?.wms}
          />
        </div>
      )}
    </div>
  );
}

ProductLotList.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ProductLotList.defaultProps = {
  title: '',
  subtitle: '',
};
