import _ from 'lodash';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOfBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { DAYS } from '@iarcpsu/emanufac-constant';

dayjs.extend(isBetween);
dayjs.extend(isSameOfBefore);
dayjs.extend(isSameOrAfter);

export const findAmountOfWorkDay = ({
  workingDayList,
  startPeriod,
  endPeriod,
}) => {
  const startDate = dayjs(startPeriod);
  const endDate = dayjs(endPeriod);

  const newDayArray = _.map(workingDayList, (value, key) => ({
    day: key,
    status: value,
  }));

  const inactiveDay = _.filter(newDayArray, (day) => day.status === false);
  const inActiveDayList = _.map(inactiveDay, (day) => day.day);
  const inActiveDayListInNumber = _.map(
    inActiveDayList,
    (selectedDay) =>
      _.find(DAYS, (value) => value.lowercase === selectedDay)?.dayOfWeek,
  );

  // console.log(
  //   'In Active Day',
  //   inActiveDayList,
  //   ' In Number ',
  //   inActiveDayListInNumber,
  // );

  const noWorkingDay = [];

  // Code from and Inspire from Microsoft Copilot AI
  // Loop Until All Day in period

  let currentDay = startDate;
  while (currentDay.isSameOrBefore(endDate)) {
    if (_.includes(inActiveDayListInNumber, currentDay.day())) {
      noWorkingDay.push(currentDay.toDate());
    }

    currentDay = currentDay.add(1, 'day');
  }

  const totalDay = endDate.diff(startDate, 'day') + 1;
  const noWorkingDayAmount = _.size(noWorkingDay);
  const workingDayAmount = totalDay - noWorkingDayAmount;
  // console.log(
  //   'Total Day',
  //   totalDay,
  //   ' No Working Day Amount',
  //   noWorkingDayAmount,
  //   ' Working Day Amount',
  //   workingDayAmount,
  // );
  return workingDayAmount;
};

export const findAmountOfWorkDayFromInformation = ({
  information,
  startPeriod,
  endPeriod,
}) => {
  const workingDayList = information?.setting?.pams?.working_day;
  return findAmountOfWorkDay({ workingDayList, startPeriod, endPeriod });
};
