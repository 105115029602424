/* eslint-disable no-confusing-arrow */
import { QUOTATION_STATUS } from '@iarcpsu/emanufac-constant';
import dayjs from 'dayjs';

import findQuotationStatus from '../functions/findQuotationStatus';

const columns = [
  {
    label: 'ลำดับที่',
    value: 'index',
  },
  {
    label: 'หมายเลขใบเสนอราคา',
    value: (col) => `${col?.prefix}${col?.running_number}`,
  },
  {
    label: 'วันที่เสนอราคา',
    value: (col) => dayjs(col?.start_date).format('D/MM/BBBB'),
  },
  {
    label: 'วันกำหนดจัดส่ง',
    value: (col) => dayjs(col?.expected_date).format('D/MM/BBBB'),
  },
  {
    label: 'ลูกค้า',
    value: (col) =>
      `${col?.customer?.type_code || ''} ${col?.customer?.name || ''}`,
  },
  {
    label: 'ผู้เสนอราคา / พนักงานขาย',
    value: (col) =>
      `${col?.sales?.firstname || ''} ${col?.sales?.lastname || ''}`,
  },
  {
    label: 'ผู้พิมพ์เข้าในระบบ',
    value: (col) =>
      `${col?.creator?.firstname || ''} ${col?.creator?.lastname || ''}`,
  },
  {
    label: 'สถานะ',
    value: (col) => QUOTATION_STATUS[findQuotationStatus(col)]?.description,
  },
  {
    label: 'ความเร่งด่วนของงาน',
    value: (col) => (col?.express ? 'เร่งด่วน' : 'ปกติ'),
  },
  {
    label: 'ราคาสินค้า',
    value: (col) => col?.product_price,
  },
  {
    label: 'ค่าธรรมเนียมการผลิตด่วย',
    value: (col) => col?.express_fee || 0,
  },
  {
    label: 'ส่วนลด',
    value: (col) => col?.discount || 0,
  },
  {
    label: 'ราคาสุทธิ',
    value: (col) => col?.total_price,
  },
];

export default { columns };
