const FRONT_TEMPLATE = {
  DEFAULT: {
    status_code: "DEFAULT",
    description: "รูปแบบพื้นฐาน",
  },
  LOWER_MENU: {
    status_code: "LOWER_MENU",
    description: "เมนูด้านล่าง",
  },
  LEFT_MENU: {
    status_code: "LEFT_MENU",
    description: "เมนูด้านซ้าย",
  },
  RIGHT_MENU: {
    status_code: "RIGHT_MENU",
    description: "เมนูด้านขวา",
  },
};

module.exports = FRONT_TEMPLATE;
