import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  BackButton,
  Error,
  Loading,
  PDFReportForm,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Button, Card, CardContent } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { config } from '@/configs';
import * as actions from '@/redux/actions';

/**
 * @function EditPDFReport
 * @description Display a collections or a list of CreatePDFReport from database
 */

export default function EditPDFReport({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const pdfReport = useSelector((state) => state.pdfReport);
  const place = useSelector((state) => state.place);
  const warehouse = useSelector((state) => state.warehouse);
  const processTemplate = useSelector((state) => state.processTemplate);

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: pdfReport,
  });

  const handleEditPDFReport = async (data) => {
    try {
      await dispatch(actions.pdfReportUpdate(params.id, data));
      alert('แก้ไขรายงานสำเร็จ');
      await dispatch(actions.pdfReportGet(params.id));
    } catch (error) {
      alert(`ไม่สามารถสร้างรายงานได้ ${error.message}`);
    }
  };

  useEffect(() => {
    dispatch(actions.pdfReportGet(params.id));
    return () => {};
  }, [params.id]);

  useEffect(() => {
    dispatch(actions.warehouseAll({ page: 1, size: config.maxFetchSize }));
    dispatch(actions.placeAll({ page: 1, size: config.maxFetchSize }));
    dispatch(
      actions.processTemplateAll({ page: 1, size: config.maxFetchSize }),
    );

    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (pdfReport?.isLoading || !_.isEmpty(pdfReport?.rows)) {
    return <Loading />;
  }

  if (!pdfReport?.isLoading && pdfReport?.isCompleted && !pdfReport?.rows) {
    return (
      <div>
        {renderTitle()}
        <div className="flex justify-between items-center">
          <BackButton />
          <Button
            variant="contained"
            color="success"
            onClick={handleSubmit(handleEditPDFReport)}
          >
            บันทึก
          </Button>
        </div>
        <div className="my-2">
          <Card>
            <CardContent>
              <form>
                <PDFReportForm
                  control={control}
                  pdfReport={pdfReport}
                  place={place}
                  setValue={setValue}
                  warehouse={warehouse}
                  watch={watch}
                  processTempalte={processTemplate}
                />
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }

  return <Error />;
}

EditPDFReport.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditPDFReport.defaultProps = {
  title: '',
  subtitle: '',
};
