import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  Autocomplete,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import ProdOrMatQuantityInput from '../../Inputs/ProdOrMatQuantityInput';

function SplittingProcessLineCreation({
  control,
  watchedMaterial,
  watch,
  setValue,
}) {
  const information = useSelector((state) => state.information);
  const machine = useSelector((state) => state.machine);
  const { append, remove, fields } = useFieldArray({
    control,
    name: 'splitting_line_list',
  });
  console.log('watchedMaterial', watchedMaterial);
  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ไลน์ที่</TableCell>
            <TableCell>วัตถุดิบ</TableCell>
            {information?.setting?.mms?.machinery?.attachMachine && (
              <TableCell>เครื่องจักร</TableCell>
            )}
            <TableCell>ลบ</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(fields, (each, index) => (
            <TableRow key={each?.id}>
              <TableCell>{index + 1}</TableCell>
              <TableCell rowSpan={1}>
                {_.map(watchedMaterial, (material, materialIndex) => (
                  <div key={materialIndex} className="flex items-center my-2">
                    <div className="w-1/2">
                      {material?.material?.name}
                      <div className="hidden">
                        <Controller
                          control={control}
                          name={`splitting_line_list[${index}].materials[${materialIndex}].material`}
                          defaultValue={material?.material?._id}
                          render={(field) => <input {...field} />}
                        />
                      </div>
                    </div>
                    <div className="w-1/2">
                      <ProdOrMatQuantityInput
                        controllerName={`splitting_line_list[${index}].materials[${materialIndex}].amount`}
                        artificialControllerName={`splitting_line_list[${index}].materials[${materialIndex}].artificial`}
                        index={index}
                        selectedUnitControllerName={`splitting_line_list[${index}].materials[${materialIndex}].selected_unit`}
                        setValue={setValue}
                        watch={watch}
                        control={control}
                        selectedProduct={material?.material}
                      />
                    </div>
                  </div>
                ))}
              </TableCell>
              <TableCell>
                {!_.isEmpty(machine?.rows) && (
                  <Controller
                    control={control}
                    name={`splitting_line_list[${index}].machine`}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        disablePortal
                        options={machine?.rows}
                        size="small"
                        placeholder="เลือกเครื่องจักร"
                        onChange={(e, newValue) => {
                          field.onChange(newValue);
                        }}
                        className="z-40"
                        // prettier-ignore
                        getOptionLabel={(option) => `${option?.name || ''}`
                    }
                        renderInput={(params) => (
                          <TextField label="เครื่องจักร" {...params} />
                        )}
                      />
                    )}
                  />
                )}
              </TableCell>
              <TableCell>
                <Button
                  color="error"
                  variant="contained"
                  size="small"
                  onClick={() => remove(index)}
                >
                  ลบ
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div>
        {console.log('Splitted line list', watch('splitting_line_list'))}
      </div>
      <div>
        <Button size="small" variant="contained" onClick={() => append({})}>
          เพิ่ม
        </Button>
      </div>
    </div>
  );
}

export default SplittingProcessLineCreation;

SplittingProcessLineCreation.propTypes = {
  control: PropTypes.object,
  watchedMaterial: PropTypes.arrayOf(PropTypes.object),
  watch: PropTypes.func,
  setValue: PropTypes.func,
};
