const CAPITAL_COST_TYPE = {
  ELECTRICITY: {
    status_code: "ELECTRICITY",
    description: "ค่าไฟฟ้า",
    settingKey: "electric",
  },
  WATER_SUPPLY: {
    status_code: "WATER_SUPPLY",
    description: "ค่าน้ำประปา",
    settingKey: "water_supply",
  },
  TOTAL_MACHINE: {
    status_code: "TOTAL_MACHINE",
    description: "ค่าเครื่องจักรทั้งหมด",
    settingKey: "machine",
  },
  OTHER: {
    status_code: "OTHER",
    description: "อื่นๆ",
    settingKey: "other",
  },
};

module.exports = CAPITAL_COST_TYPE;
