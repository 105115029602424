import {
  MACHINE_ALL,
  MACHINE_GET,
  MACHINE_DELETE,
  MACHINE_PUT,
  MACHINE_POST,
  MACHINE_LOADING,
  MACHINE_ERROR,
} from '../types';

import graphqlClient from '../../../configs/graphqlClient';
import api from '../../../configs/api';

export const machineCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MACHINE_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/machine`,
      {
        ...payload,
      },
    );
    dispatch({ type: MACHINE_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MACHINE_ERROR });
    throw new Error(error);
  }
};

export const machineAll = ({ name = '', size = 10, page = 1 }) => async (
  dispatch,
) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/machine?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: MACHINE_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MACHINE_ERROR });
    throw new Error(error);
  }
};

export const machineAllQuery = ({ query }) => async (dispatch) => {
  try {
    const queryResult = await graphqlClient.request(query);
    const data = queryResult?.findmachines;
    //  console.log('Query Data', queryResult);
    if (!data) {
      dispatch({ type: MACHINE_ALL });
    }

    dispatch({ type: MACHINE_ALL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MACHINE_ERROR });
    throw new Error(error);
  }
};

export const machineGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/machine/${id}`,
    );
    if (status === 200) {
      dispatch({ type: MACHINE_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MACHINE_ERROR });
    throw new Error(error);
  }
};

export const machineStateSet = (data) => async (dispatch) => {
  dispatch({ type: MACHINE_ALL, payload: data });
};

export const machineStateOneSet = (data) => async (dispatch) => {
  dispatch({ type: MACHINE_GET, payload: data });
};

export const machineReset = () => async (dispatch) => {
  dispatch({ type: MACHINE_LOADING });
};

export const machineError = () => async (dispatch) => {
  dispatch({ type: MACHINE_ERROR });
};

export const machinePut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: MACHINE_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/machine/${id}`,
      payload,
    );
    dispatch({ type: MACHINE_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MACHINE_ERROR });
    throw new Error(error);
  }
};
export const machineDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: MACHINE_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/machine/${id}`,
    );
    dispatch({ type: MACHINE_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MACHINE_ERROR });
    throw new Error(error);
  }
};
