export const percentYieldFormat = (data) => {
  try {
    if (isNaN(data)) {
      return '-';
    }
    if (data === Infinity) {
      return '100';
    }
    if (data === -Infinity) {
      return '-';
    }
    return data?.toFixed(2);
  } catch (error) {
    return '-';
  }
};

export default percentYieldFormat;
