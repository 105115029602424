/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Button, Card, CardContent, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  MultiUnitProdOrMatBox,
  ProductLotReport,
  ProductStockTab,
  ProductTransactionTab,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import * as actions from '../../redux/actions';

export default function DetailProductStockLotOfProduct({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id, warehouseId } = useParams();
  const warehouse = useSelector((state) => state.warehouse);
  const product = useSelector((state) => state.product);
  const information = useSelector((state) => state.information);

  const [selectedTab, setSelectedTab] = useState('product-stock-lot');

  const handlePrintPdf = async () => {
    try {
      await ProductLotReport(product, information);
    } catch (error) {
      await ProductLotReport(product, information, true);
    }
  };

  useEffect(() => {
    dispatch(
      actions.productGet(id, {
        warehouseId: warehouseId || '',
        fetchStockLot: true,
      }),
    );
    if (warehouseId) {
      dispatch(actions.warehouseGet(warehouseId));
    }
    dispatch(actions.informationAll());
    return () => {};
  }, [id]);

  const renderTitle = () => (
    <ViewTitle
      title={`${title}ของ ${product?.type_code || ''} ${product?.name} ${
        warehouseId ? warehouse?.name || '' : ''
      }`}
      subtitle={subtitle}
    />
  );

  if (product.isLoading || product.rows) {
    return <Loading />;
  }

  if (!product.isLoading && product.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex justify-between">
          <div>
            <BackButton />
          </div>
          <div>
            <Button
              variant="contained"
              color="teal"
              onClick={() => handlePrintPdf()}
            >
              พิมพ์
            </Button>
          </div>
        </div>{' '}
        <div className="my-4">
          <Card>
            <CardContent>
              <div className="flex flex-wrap justify-between">
                <div className="w-full md:w-1/2">
                  <h2 className="font-semibold text-xl items-center">
                    {product?.type_code || ''} {product?.name}
                  </h2>
                  <div className="text-gray-600">
                    หมวดหมู่ {product?.product_type?.name}
                  </div>{' '}
                  {information?.setting?.wms?.productAttribute
                    ?.prefer_customer && (
                    <div className="text-gray-600">
                      ลูกค้า {product?.prefer_customer?.name || '-'}
                    </div>
                  )}
                </div>
                <div className="w-full md:w-1/4">
                  <div className="">ปริมาณคงเหลือในคลัง</div>
                  <div className="text-lg font-semibold">
                    <MultiUnitProdOrMatBox
                      foundMaterial={product}
                      quantity={product?.total}
                    />
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div>
          <Card>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={selectedTab}
                  onChange={(e, value) => setSelectedTab(value)}
                  aria-label="basic tabs example"
                >
                  <Tab label="รายการล็อต" value="product-stock-lot" />
                  <Tab
                    label="ประวัติการเข้าออกวัตถุดิบ"
                    value="product-transaction"
                  />
                </Tabs>
              </Box>
            </Box>
          </Card>
          {selectedTab === 'product-stock-lot' && (
            <ProductStockTab selectedProduct={product} place={warehouse} />
          )}{' '}
          {selectedTab === 'product-transaction' && <ProductTransactionTab />}
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailProductStockLotOfProduct.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailProductStockLotOfProduct.defaultProps = {
  title: '',
  subtitle: '',
};
