import React from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';

import {
  backConverseMaterialOrProductUnit,
  converseMaterialOrProductUnit,
} from '@/utils/functions';

/**
 * @author theethawat
 * @typedef {object} ProductAmountInputProp
 * @property {object} control React-hook-form control object
 * @property {number} index Index of Product Input
 * @property {function} setReload Method to set Reload
 * @property {boolean} reload Current state of reload
 * @property {function} setValue Method to set React-hook-form value
 * @property {function} watch Method to watch React-hook-form state
 * @property {function} onChangeFunction Some function that will run on state change optional
 * @property {string} controllerName  ชื่อที่เราจะใช้เป็นชื่อของฟอร์ม ใส่ลงไปใน Controller
 * สำหรับเก็บค่าหลัก ซึ่งก็คือค่า Quantity หรือ ค่า Amount
 * / Name that use to keep value of this form maybe name to keep value of quality or amount
 * @property {string} artificialControllerName ชื่อที่จะใช้ั่วคราวในการใส่ลงใน Controller ของการ
 * จะเก็บจำนวนสินค้าในอีกหน่วยนึง / Name to keep this form value in another unit
  during the display, don't need to keep in on database
 * @property {string} selectedUnitControllerName Name to keep this from selected Unit
 * @property {object} selectedProduct product object that come with unit conversion

 * @function ProductAmountInput
 * @description Input Product Amount or Quantity in many units
 * @param {ProductAmountInputProp}
 * @returns {Component} React Components
 */
const ProductAmountInput = ({
  control,
  index,
  reload = false,
  watch,
  setValue,
  setReload = () => {},
  controllerName = `processes[${index}].quantity`,
  artificialControllerName = `processes[${index}].quantity_articial`,
  selectedUnitControllerName = `processes[${index}].selected_unit`,
  selectedProduct = {},
  onChangeFunction = () => {},
  defaultValue = 0,
  disabled = false,
  required = false,
  autoFocus = false,
}) => {
  // console.log('SELETECTED PRODUCT', selectedProduct);

  const unitSelector = () => (
    <Controller
      name={selectedUnitControllerName}
      defaultValue={selectedProduct?.unit_input?._id}
      control={control}
      render={({ field }) => (
        <FormControl fullWidth>
          <InputLabel>หน่วย</InputLabel>
          <Select
            fullWidth
            label="หน่วย"
            size="small"
            {...field}
            disabled={disabled}
            defaultValue={field.value}
            placeholder="เลือกหน่วย"
          >
            <MenuItem value="-">เลือกหน่วย</MenuItem>
            <MenuItem value={selectedProduct?.unit_input?._id}>
              {selectedProduct?.unit_input?.short_sign}
            </MenuItem>
            <MenuItem value={selectedProduct?.unit_output?._id}>
              {selectedProduct?.unit_output?.short_sign}
            </MenuItem>
          </Select>
        </FormControl>
      )}
    />
  );

  const renderRealQuantityInput = () => (
    <Controller
      name={controllerName}
      control={control}
      rules={{ required }}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField
          {...field}
          autoFocus={autoFocus}
          onChange={(e) => {
            field.onChange(e.target.value);
            setValue(
              artificialControllerName,
              converseMaterialOrProductUnit(
                selectedProduct,
                parseFloat(e.target.value),
              ),
            );
            setReload(!reload);
            onChangeFunction();
          }}
          label={`จำนวน ${
            selectedProduct?.use_unit_conversion
              ? ''
              : `(${selectedProduct?.unit || ''})`
          }`}
          fullWidth
          defaultValue={1}
          required={required}
          disabled={disabled}
          inputProps={{
            min: 0,
            style: { textAlign: 'center' },
          }}
          size="small"
        />
      )}
    />
  );

  const renderArtificialQuantityInput = () => (
    <Controller
      name={artificialControllerName}
      control={control}
      rules={{ required }}
      defaultValue={0}
      render={({ field }) => (
        <TextField
          {...field}
          autoFocus={autoFocus}
          onChange={(e) => {
            field.onChange(e.target.value);
            setValue(
              controllerName,
              backConverseMaterialOrProductUnit(
                selectedProduct,
                parseFloat(e.target.value),
              ),
            );
            setReload(!reload);
            onChangeFunction();
          }}
          label="จำนวน"
          fullWidth
          disabled={disabled}
          required={required}
          inputProps={{
            min: 0,
            style: { textAlign: 'center' },
          }}
          size={'small'}
        />
      )}
    />
  );

  if (selectedProduct?.use_unit_conversion) {
    return (
      <div className="flex flex-wrap">
        <div className="w-1/2 pr-2 self-center">{unitSelector()}</div>
        <div className="self-center w-1/2">
          {watch(selectedUnitControllerName) !==
            selectedProduct?.unit_output?._id && (
            <div className="">
              {/* ใช้หน่วยหลัก  */}
              <div className="flex gap-2 ">
                <div className="my-1 w-3/4">{renderRealQuantityInput()}</div>
                <div className="w-1/4text-sm self-center">
                  {selectedProduct?.unit_input?.short_sign}
                </div>
              </div>
              <div className="text-xs text-center">
                คิดเป็น {parseFloat(watch(artificialControllerName)).toFixed(2)}{' '}
                {selectedProduct?.unit_output?.short_sign}
              </div>
            </div>
          )}
          {watch(selectedUnitControllerName) ===
            selectedProduct?.unit_output?._id && (
            <div>
              {' '}
              {/* ใช้หน่วยรอง */}
              <div className="flex gap-2">
                <div className="my-1">{renderArtificialQuantityInput()}</div>
                <div className="text-sm self-center">
                  {selectedProduct?.unit_output?.short_sign}
                </div>
              </div>
              <div className="text-xs text-center">
                คิดเป็น {parseFloat(watch(controllerName)).toFixed(2)}{' '}
                {selectedProduct?.unit_input?.short_sign}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  return <div>{renderRealQuantityInput()}</div>;
};

ProductAmountInput.propTypes = {
  control: PropTypes.func,
  index: PropTypes.number,
  setReload: PropTypes.func,
  reload: PropTypes.bool,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  controllerName: PropTypes.string,
  artificialControllerName: PropTypes.string,
  selectedUnitControllerName: PropTypes.string,
  /** วัตถุดิบ หรือ สินค้าที่เลือก */
  selectedProduct: PropTypes.object,
  onChangeFunction: PropTypes.func,
  defaultValue: PropTypes.number,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

export default ProductAmountInput;
