import React from 'react';
import PropTypes from 'prop-types';

const BoxDetailProcess = (props) => {
  return <div>BoxDetailProcess</div>;
};

BoxDetailProcess.propTypes = {};

export default BoxDetailProcess;
