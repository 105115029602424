import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Error,
  Loading,
  QuotationReport,
  QuotationTable,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';

/**
 * @function PreviousQuotations
 * @description Display a collections or a list of PreviousQuotations from database
 */

export default function PreviousQuotations({ title, subtitle }) {
  const dispatch = useDispatch();
  const quotation = useSelector((state) => state.quotation);
  const information = useSelector((state) => state.information);
  const params = useParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.quotationAll({
          page,
          size,
          originalQuotation: params.id,
          withLog: true,
        }),
      );
    } catch (error) {
      dispatch(actions.quotationError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size]);

  useEffect(() => {
    if (quotation?.rows) {
      setTotal(quotation?.total);
    }

    return () => {};
  }, [quotation]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const handlePrintPdf = (row) => {
    try {
      QuotationReport(row, information, true);
    } catch (error) {
      QuotationReport(row, information);
    }
  };
  if (quotation.isLoading) {
    return <Loading />;
  }
  if (!quotation.isLoading && quotation.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <QuotationTable
            quotation={quotation}
            page={page}
            setPage={setPage}
            setSize={setSize}
            size={size}
            total={total}
            information={information}
            handleDelete={() => {}}
            handlePrintPdf={handlePrintPdf}
            showPreviousQuotation={false}
          />
        </div>
      </div>
    );
  }
  return <Error />;
}

PreviousQuotations.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

PreviousQuotations.defaultProps = {
  title: '',
  subtitle: '',
};
