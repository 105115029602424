import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Card } from '@mui/material';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import { SelectMaterialRequestForm } from '@iarcpsu/emanufac-components/src/components/Forms';
import Loading from '@iarcpsu/emanufac-components/src/components/Loading';
import { Error } from '@iarcpsu/emanufac-components/src/components/Error';
import { ViewTitle } from '@iarcpsu/emanufac-components/src/components/ViewTitle';
import { BackButton } from '@iarcpsu/emanufac-components/src/components/Button';
import {
  MATERIAL_PURCHASE_STATUS,
  MATERIAL_REQUEST_STATUS,
} from '../../utils/constants';

const defaultValues = {
  material: '',
  material_transaction_type: '',
  bill_number: '',
  supplier: '',
  source: '',
  remark: '',
};

const CreatePurchaseOrder = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const material = useSelector((state) => state.material);
  const me = useSelector((state) => state.me);

  const history = useHistory();
  const { handleSubmit, reset } = useForm();
  const [name] = useState('');
  const [page] = useState(1);
  const [size] = useState('');
  const [selectMaterial, setSelectMaterial] = useState([]);

  useEffect(() => {
    const fetchMaterterial = async () => {
      try {
        await dispatch(
          actions.materialAll({
            name,
            size,
            page,
            statusCode: MATERIAL_REQUEST_STATUS.APPROVAL.status_code,
          }),
        );
      } catch (error) {
        console.error(error);
      }
    };

    const fetchMe = async () => {
      try {
        await dispatch(
          actions.meGet({
            name,
            size,
            page,
          }),
        );
      } catch (error) {
        console.error(error);
      }
    };

    fetchMaterterial();
    fetchMe();

    return () => {};
  }, [name, page, size]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data, event) => {
    try {
      if (_.isEmpty(selectMaterial[0]?.material)) {
        alert('กรุณาเลือกวัตถุดิบ');
      } else {
        const reduce = selectMaterial.reduce((r, a) => {
          r[a.supplier] = [...(r[a.supplier] || []), a];
          return r;
        }, {});
        data.material = _.values(reduce);
        data.status = MATERIAL_PURCHASE_STATUS.PURCHASE.status_code;
        data.purchaser = me?.userData?._id;
        data.purchase_date = new Date();
        event.preventDefault();
        await dispatch(actions.purchaseOrderCreate(data));
        reset(defaultValues);
        alert('สำเร็จ');
        history.goBack();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderSelectMaterial = () => (
    <Card>
      <SelectMaterialRequestForm
        material={material.rows}
        setSelectMaterial={setSelectMaterial}
      />
    </Card>
  );

  if (material.isLoading) {
    return <Loading />;
  }
  if (!material.isLoading && material.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {renderSelectMaterial()}
          <div className="flex flex-row justify-end gap-1 py-4">
            <Button variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    );
  }
  return <Error message={material?.message} />;
};

CreatePurchaseOrder.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreatePurchaseOrder.defaultProps = {
  title: '',
  subtitle: '',
};

export default CreatePurchaseOrder;
