import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import {
  NotAuthorized,
  NotFound,
} from '@iarcpsu/emanufac-components/src/components/Error';
import { MainFooter } from '@iarcpsu/emanufac-components/src/components/Footers';
import { Loading } from '@iarcpsu/emanufac-components/src/components/Loading';
import { HomeNavbar } from '@iarcpsu/emanufac-components/src/components/Navbars';
import { MainSidebar } from '@iarcpsu/emanufac-components/src/components/Sidebar';

import * as actions from '../redux/actions';
import accessRight from '../utils/functions/accessRight';
import {
  CostEstimationProfiles,
  CreateCostEstimationProfile,
  CreateFactoryCapitalCost,
  CreatePlaceLaborCost,
  DetailCostEstimationProfile,
  DetailFactoryCapitalCost,
  DetailPlaceLaborCost,
  EditCostEstimationProfile,
  EditFactoryCapitalCost,
  EditPlaceLaborCost,
  FactoryCapitalCosts,
  MaterialTypeListForLaborCost,
  PlaceLaborCostByDay,
  PlaceLaborCosts,
} from '../views/CEM';
import { CostEstimation } from '../views/IMS';

// Cost Estimation Management
export const CEM = () => {
  const module = 'CEM';
  const prefix = '/cem';
  const name = 'การประมาณการต้นทุน';
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();

  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);
  const information = useSelector((state) => state.information);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };

  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout: CEM');
    dispatch(actions.meGet());
    dispatch(actions.informationAll());
    dispatch(actions.availableModuleAll());
    return () => {};
  }, []);

  if (!me?.userData) {
    return <Loading />;
  }

  if (accessRight(me, module, 0, availableModule)) {
    return (
      <div className="min-h-screen">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {name} - {information?.name || 'E-Manufac'}
          </title>
        </Helmet>
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
          isTaskbarOpen={sidebarOpen}
          setIsTaskbarOpen={setSidebarOpen}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div
          className={`${
            sidebarOpen ? 'lg:ml-64' : ''
          }   min-h-screen  pt-16 px-8 `}
        >
          <div className="py-4">
            <Switch>
              <Redirect
                exact
                from={`${prefix}`}
                to={`${prefix}/place-labor-cost`}
              />
              <Redirect
                from="/:url*(/+)"
                to={`${pathname.slice(0, -1)}${search || ''}`}
              />{' '}
              <Route exact path={`${prefix}/cost-estimation-profile`}>
                <CostEstimationProfiles
                  title="โปรไฟล์การประเมินต้นทุน"
                  subtitle={name}
                />
              </Route>{' '}
              <Route exact path={`${prefix}/cost-estimation-profile/create`}>
                <CreateCostEstimationProfile
                  title="สร้างโปรไฟล์การประเมินต้นทุน"
                  subtitle={name}
                />
              </Route>{' '}
              <Route exact path={`${prefix}/cost-estimation-profile/edit/:id`}>
                <EditCostEstimationProfile
                  title="แก้ไขโปรไฟล์การประเมินต้นทุน"
                  subtitle={name}
                />
              </Route>{' '}
              <Route
                exact
                path={`${prefix}/cost-estimation-profile/detail/:id`}
              >
                <DetailCostEstimationProfile
                  title="รายละเอียดโปรไฟล์การประเมินต้นทุน"
                  subtitle={name}
                />
              </Route>{' '}
              <Route exact path={`${prefix}/place-labor-cost`}>
                <PlaceLaborCosts
                  title="ค่าแรงในแต่ละวัน/ช่วงเวลา"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/place-labor-cost/create`}>
                <CreatePlaceLaborCost
                  title="สร้างค่าแรงในแต่ละวัน/ช่วงเวลา"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/place-labor-cost/edit/:id`}>
                <EditPlaceLaborCost
                  title="แก้ไขค่าแรงในแต่ละวัน/ช่วงเวลา"
                  subtitle={name}
                />
              </Route>{' '}
              <Route exact path={`${prefix}/place-labor-cost/detail/:id`}>
                <DetailPlaceLaborCost
                  title="รายละเอียดค่าแรงในแต่ละวัน/ช่วงเวลา"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/place-labor-cost/by-day`}>
                <PlaceLaborCostByDay
                  title="ตารางค่าแรงในแต่ละวัน/ช่วงเวลา"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/factory-capital-cost`}>
                <FactoryCapitalCosts
                  title="ต้นทุนการลงทุน ค่าน้ำค่าไฟ และอื่นๆ"
                  subtitle={name}
                />
              </Route>{' '}
              <Route exact path={`${prefix}/factory-capital-cost/create`}>
                <CreateFactoryCapitalCost
                  title="สร้างรายการต้นทุนการลงทุน ค่าน้ำค่าไฟ และอื่นๆ"
                  subtitle={name}
                />
              </Route>{' '}
              <Route exact path={`${prefix}/factory-capital-cost/edit/:id`}>
                <EditFactoryCapitalCost
                  title="แก้ไขรายการต้นทุนการลงทุน ค่าน้ำค่าไฟ และอื่นๆ"
                  subtitle={name}
                />
              </Route>{' '}
              <Route exact path={`${prefix}/factory-capital-cost/detail/:id`}>
                <DetailFactoryCapitalCost
                  title="รายละเอียดต้นทุนการลงทุน ค่าน้ำค่าไฟ"
                  subtitle={name}
                />
              </Route>{' '}
              <Route exact path={`${prefix}/material-type-labor-cost`}>
                <MaterialTypeListForLaborCost
                  title="วัตถุดิบที่ใช้คำนวณค่าแรง"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/materials/labor-cost`}>
                <CostEstimation.MaterialLumpSumLaborCost
                  title="ต้นทุนค่าแรงต่อหน่วยวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/materials/labor-cost/edit`}>
                <CostEstimation.EditMaterialLumpSumLaborCost
                  title="แก้ไขต้นทุนค่าแรงต่อหน่วยวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <NotAuthorized />
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    </div>
  );
};

export default CEM;
