import React, { useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import {
  Autocomplete,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import OnCreateMOMaterialQuantityInput from '../../Inputs/OnCreateMOMaterialQuantityInput';

export function MMSSelectMaterialForm({
  errors,
  control,
  material,
  watch,
  productIndex,
  setValue,
}) {
  const [reload, setReload] = useState(false);

  const { fields, append, remove } = useFieldArray({
    control,
    name: `processes[${productIndex}].materials`,
  });

  const displayMaterial = () => (
    <Table size="small" className="border-lg">
      <colgroup>
        <col width="10%" />
        <col width="35%" />
        <col width="30%" />
        <col width="25%" />
      </colgroup>
      <TableHead className="bg-graygp-100">
        <TableRow>
          <TableCell>
            <div className="font-bold">ลำดับที่</div>
          </TableCell>
          <TableCell>
            <div className="font-bold"> วัตถุดิบ</div>
          </TableCell>
          <TableCell>
            <div className="font-bold"> จำนวน</div>
          </TableCell>
          <TableCell>
            <div className="font-bold"> ดำเนินการ</div>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {_.size(fields) ? (
          fields.map((_field, index) => (
            <TableRow key={_field.id}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>
                <div className="w-full px-1">
                  <div>
                    {watch(`processes[${productIndex}].materials[${index}]`)
                      ?.tag &&
                      watch(`processes[${productIndex}].materials[${index}]`)
                        ?.tag !== '' && (
                        <div className="my-2">
                          กลุ่ม{' '}
                          {
                            watch(
                              `processes[${productIndex}].materials[${index}]`,
                            )?.tag
                          }
                        </div>
                      )}
                  </div>
                  <Controller
                    name={`processes[${productIndex}].materials[${index}].material`}
                    control={control}
                    rules={{ required: true }}
                    defaultValue={
                      watch(
                        `processes[${productIndex}].materials[${index}].material`,
                      ) || material?.[0]
                    }
                    render={({ field }) => (
                      <Autocomplete
                        size={'small'}
                        disableClearable
                        {...field}
                        onChange={(e, newValue) => {
                          field.onChange(newValue);
                          setReload(!reload);
                        }}
                        options={_.map(
                          material,
                          (eachMaterial, materialIndex) => ({
                            ...eachMaterial,
                            index: materialIndex,
                          }),
                        )}
                        getOptionLabel={(option) => {
                          if (option?.type_code) {
                            return `${option?.index || ''} ${
                              option?.type_code || ''
                            } | ${option?.name}`;
                          }
                          return `${option?.index || ''} ${option?.name}`;
                        }}
                        required
                        renderInput={(params) => (
                          <TextField {...params} label="วัตถุดิบ" required />
                        )}
                      />
                    )}
                  />
                </div>
              </TableCell>
              <TableCell>
                <OnCreateMOMaterialQuantityInput
                  control={control}
                  errors={errors}
                  materialIndex={index}
                  productIndex={productIndex}
                  reload={reload}
                  setReload={setReload}
                  watch={watch}
                  setValue={setValue}
                />
              </TableCell>
              <TableCell>
                <Button
                  color={'error'}
                  variant="contained"
                  size={'small'}
                  onClick={() => remove(index)}
                >
                  ลบ
                </Button>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={4}>
              <div className="text-center py-2">ไม่มีรายการ</div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );

  return (
    <div>
      <div className="">{displayMaterial()}</div>
      <div className="px-2 py-2">
        <Button
          color={'secondary'}
          size={'small'}
          variant="contained"
          onClick={() => append({ material: material[0], begin: 1 })}
        >
          เพิ่มวัตถุดิบ
        </Button>
      </div>
    </div>
  );
}

MMSSelectMaterialForm.propTypes = {
  errors: PropTypes.object,
  material: PropTypes.object,
  manufacturingOrder: PropTypes.object,
  control: PropTypes.object,
  productIndex: PropTypes.number,
  watch: PropTypes.func,
  setValue: PropTypes.func,
};

export default MMSSelectMaterialForm;
