import dayjs from 'dayjs';
import _ from 'lodash';

import * as actions from '../../../redux/actions';
import * as CONSTANT from '../../constants';

import { unNullManufacMaterialAnalyzedArrayAnalzer } from './materialAnalyzer';
import { stepStatusCheck } from './stepStatusCheck';

export const handleSendwork = async ({
  dispatch,
  step,
  currentProcess,
  employeeId,
  manufacMaterials,
  handleSuccess = () => {},
  fetchManufacTxn = true,
}) => {
  if (stepStatusCheck(step)?.isNonVacant) {
    try {
      const materialPayload = _.map(
        unNullManufacMaterialAnalyzedArrayAnalzer(manufacMaterials),
        (_material) => ({
          material: _material?.materialInfo?._id,
          end: _material?.resultAmount,
          wastes: 0,
        }),
      );

      await dispatch(
        actions.stepPost({
          process: currentProcess?._id,
          employeeId,
          manufacturing_transaction_type:
            CONSTANT.MANUFACTURING_TRANSACTION_TYPE.WORK_CLOSE.status_code,
          materials: materialPayload,
        }),
      );

      if (fetchManufacTxn) {
        await dispatch(
          actions.manufacTxnAll({
            processId: step?.process?._id,
            size: 200,
            page: 1,
          }),
        );
      }

      window.alert('ปิดงานสำเร็จ');
      handleSuccess();
    } catch (error) {
      window.alert(`ปิดงานไม่สำเร็จ ${error?.message}`);
      console.error('Cannot Send Work', error);
    }
  }
};

export const handleStartWork = async ({
  dispatch,
  step,
  employeeId,
  handleSuccess = () => {},
}) => {
  try {
    await dispatch(
      actions.stepPut(step?._id, {
        started: true,
        employeeId,
        start_date: dayjs().toDate(),
        manufacturing_transaction_type:
          CONSTANT.MANUFACTURING_TRANSACTION_TYPE.WORK_START.status_code,
      }),
    );

    handleSuccess();
  } catch (error) {
    window.alert('เริ่มงานไม่สำเร็จ');
    console.error('Error on Start Work', error);
  }
};

export const handleAcceptWork = async ({
  dispatch,
  step,
  employeeId,
  handleSuccess = () => {},
  fetchManufacTxn = true,
}) => {
  const confirm = window.confirm('ยืนยันการรับงาน');
  if (confirm) {
    try {
      await dispatch(
        actions.stepPut(step?._id, {
          responsible: {
            department: step?.responsible?.department?._id,
            employee: employeeId,
            accepted_date: dayjs().toISOString(),
          },
          employeeId,
          manufacturing_transaction_type:
            CONSTANT.MANUFACTURING_TRANSACTION_TYPE.WORK_ACCEPT.status_code,
          started: true,
          start_date: dayjs().toDate(),
        }),
      );

      if (fetchManufacTxn) {
        await dispatch(
          actions.manufacTxnAll({
            processId: step?.process?._id,
            size: 200,
            page: 1,
          }),
        );
      }

      handleSuccess();
    } catch (error) {
      window.alert(`รับงานไม่สำเร็จ ${error?.message}`);
      console.error('Error on Accept Work', error);
    }
  }
};

export const handleRejectWork = async ({
  dispatch,
  step,
  employeeId,
  handleSuccess = () => {},
  fetchManufacTxn = true,
}) => {
  const confirm = window.confirm(
    'ยืนยันที่จะถอนตัวจากงานนี้ งานนี้จะถูกเปิดขึ้นมาให้คนอื่นรับอีกครั้ง',
  );
  if (confirm) {
    try {
      await dispatch(
        actions.stepPut(step?._id, {
          responsible: {
            department: step?.responsible?.department?._id,
            employee: null,
            accepted_date: dayjs().toISOString(),
          },
          employeeId,
          manufacturing_transaction_type:
            CONSTANT.MANUFACTURING_TRANSACTION_TYPE.WORK_RESIGN.status_code,
        }),
      );

      if (fetchManufacTxn) {
        await dispatch(
          actions.manufacTxnAll({
            processId: step?.process?._id,
            size: 200,
            page: 1,
          }),
        );
      }

      window.alert('ถอนตัวจากงานสำเร็จ');
      handleSuccess();
    } catch (error) {
      window.alert(`ถอนตัวจากงานไม่สำเร็จ ${error?.message}`);
      console.error('Error on Accept Work', error);
    }
  }
};

export const handleEditMaterialYield = async ({
  dispatch,
  data,
  step,
  employeeId,
  successHandle = () => {},
  information,
  selectedProcess,
}) => {
  const {
    setting: { mms: mmsSetting },
  } = information;
  try {
    const preMaterials = _.map(data?.materials, (_material) => ({
      ..._material,
      end: mmsSetting?.fillYieldUsingWaste
        ? parseFloat(_material?.begin) - parseFloat(_material?.wastes)
        : parseFloat(_material?.end),
      wastes: mmsSetting?.fillYieldUsingWaste
        ? parseFloat(_material?.wastes)
        : parseFloat(_material?.begin) - parseFloat(_material?.end),
    }));

    console.log('Data', data);
    console.log('Pre-Material', preMaterials);

    const allBegins = _.map(preMaterials, (_material) => _material.begin);
    if (_.includes(allBegins, undefined)) {
      window.alert('ขออภัย ค่าที่ใส่มามีข้อผิดพลาด');
      return;
    }

    // เตรียมข้อมูลสำหรับสร้าง ManufacMaterial
    const manufacMaterialArray = _.map(preMaterials, (_preMaterial) => {
      console.log('ข้อมูลที่เพิ่มลงในรายการใช้วัตถุดิบ / วัตถุดิบเสีย');
      console.table(
        [
          {
            ชื่อวัตถุดิบ: _preMaterial?.material?.name,
            จำนวนเริ่มต้น: _preMaterial.begin,
            จำนวนที่ขอลด: parseFloat(_preMaterial.wastes).toFixed(2),
            คงเหลือในการผลิต: _preMaterial.end,
          },
        ],
        [
          'ชื่อวัตถุดิบ',
          'จำนวนเริ่มต้น',
          'จำนวนที่ขอเพิ่ม',
          'คงเหลือในการผลิต',
        ],
      );
      return {
        material: _preMaterial?.material?._id || _preMaterial?.material,
        begin: _preMaterial.begin,
        quantity: parseFloat(_preMaterial.wastes),
        amount: _preMaterial.end,
      };
    });

    // กรองเฉพาะอันที่ Quantity ไม่เท่ากัน 0
    const filteredManufacMaterialArr = _.filter(
      manufacMaterialArray,
      (_manufacMaterial) => _manufacMaterial?.quantity !== 0,
    );

    // สร้างรายการการเบิก / ใช้วัตถุดิบ ในกระบวนการการผลิต
    await dispatch(
      actions.manufacMaterialCreate({
        process: selectedProcess?._id,
        step: step?._id,
        transaction_type:
          CONSTANT.MANUFACTURING_TRANSACTION_TYPE.MATERIAL_WASTED.status_code,
        transaction_dir: false,
        employee: employeeId,
        materialArray: filteredManufacMaterialArr,
      }),
    );

    // แก้ไข Measurement Data
    const measurementArray = data.measurements;
    await dispatch(actions.measurementPut(0, { measurementArray }));

    successHandle();
  } catch (error) {
    window.alert(`อัพเดทข้อมูลไม่สำเร็จ ${error?.message}`);
    console.error('Error On Edit Step ', error);
  }
};

export const handleReturnMaterial = async ({
  dispatch,
  data,
  step,
  me,
  successHandle,
  handleClose,
  currentProcess,
}) => {
  try {
    console.log('Data on Form', data);
    // Validation
    const falseArray = _.map(data?.material, (_material) => {
      if (parseFloat(_material?.return) < 0) {
        window.alert('ไม่สามารถคืนวัตถุดิบเป็นจำนวนติดลบได้');
        return false;
      }
      if (parseFloat(_material?.return) > 0 && _material?.lotId === 'NO') {
        window.alert('โปรดเลือกล็อตวัตถุดิบ');
        return false;
      }
      return true;
    });

    // กรณีที่เลือกจำนวนเป็น 0 และ lotID เป็นไม่เลือกล็อตใด ทุกอัน หรือ มันเท่ากับไม่ได้คืนอะไรเลย
    const isEmptyRequest = _.filter(
      data?.material,
      // prettier-ignore
      (_material) => parseFloat(_material?.return) === 0 && _material?.lotId === 'NO',
    );
    if (_.size(isEmptyRequest) >= _.size(data?.material)) {
      window.alert('ท่านไม่ได้เลือกคืนวัตถุดิบชิ้นใด');
    }

    if (
      !_.includes(falseArray, false) &&
      _.size(isEmptyRequest) < _.size(data?.material)
    ) {
      const payload = {
        ...data,
        increaseMode: true,
        materialLots: _.map(
          _.filter(
            data?.material,
            // prettier-ignore
            (_material) => typeof _material?.lotId === 'string' && _material?.lotId !== 'NO',
          ),
          (_material) => ({
            lotId: _material?.lotId,
            material: _material?.material?._id || _material?.material,
            quantity: _material?.return,
          }),
        ),
        request_name: `${me?.userData?.firstname} ${me?.userData?.lastname}`,
        request_department:
          me?.userData?.department?._id || me?.userData?.department,
        remark: data?.remark,
        employee: me?.userData?._id,
        employeeId: me?.userData?._id,
        manufacturing_transaction_type:
          CONSTANT.MANUFACTURING_TRANSACTION_TYPE.MATERIAL_RETURN.status_code,
      };

      // เตรียมข้อมูลสำหรับสร้าง ManufacMaterial
      const manufacMaterialArray = _.map(data.material, (_preMaterial) => {
        console.log('ข้อมูลที่เพิ่มลงในรายการใช้วัตถุดิบ / วัตถุดิบเสีย');
        console.table(
          [
            {
              ชื่อวัตถุดิบ: _preMaterial?.materialInfo?.name,
              จำนวนเริ่มต้น: _preMaterial?.resultAmount,
              จำนวนที่ขอลด: parseFloat(_preMaterial.return).toFixed(2),
              คงเหลือในการผลิต:
                _preMaterial?.resultAmount - parseFloat(_preMaterial.return),
            },
          ],
          [
            'ชื่อวัตถุดิบ',
            'จำนวนเริ่มต้น',
            'จำนวนที่ขอเพิ่ม',
            'คงเหลือในการผลิต',
          ],
        );
        return {
          material: _preMaterial?.material?._id || _preMaterial?.material,
          begin: _preMaterial?.resultAmount,
          quantity: parseFloat(_preMaterial.return),
          amount: _preMaterial?.resultAmount - parseFloat(_preMaterial.return),
        };
      });

      // กรองเฉพาะอันที่ Quantity ไม่เท่ากัน 0
      const filteredManufacMaterialArr = _.filter(
        manufacMaterialArray,
        (_manufacMaterial) => _manufacMaterial?.quantity !== 0,
      );

      console.log('Filtered ManufacMaterial Arr', filteredManufacMaterialArr);
      // สร้างรายการการเบิก / ใช้วัตถุดิบ ในกระบวนการการผลิต
      await dispatch(
        actions.manufacMaterialCreate({
          process: currentProcess?._id,
          step: step?._id,
          transaction_type:
            CONSTANT.MANUFACTURING_TRANSACTION_TYPE.MATERIAL_RETURN.status_code,
          transaction_dir: false,
          employee: me?.userData?._id,
          materialArray: filteredManufacMaterialArr,
        }),
      );

      await dispatch(actions.materialStockLotIncrease(payload));
      handleClose();
      successHandle();
      // // กัน Bug
      window.alert('คืนวัตถุดิบสำเร็จ');
    }
  } catch (error) {
    console.log('Error on Return Material ', error);
    window.alert('คืนวัตถุดิบไม่สำเร็จ');
  }
};

export default {
  handleSendwork,
  handleAcceptWork,
  handleRejectWork,
  handleEditMaterialYield,
  handleStartWork,
  handleReturnMaterial,
};
