import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Error,
  Loading,
  SocketIOLoading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { MQ_TASK } from '@iarcpsu/emanufac-constant';
import { Card } from '@mui/material';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';

/**
 * @function RecieveProductInMO
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function RecieveMO({ title, subtitle }) {
  const dispatch = useDispatch();
  const manufacturingOrder = useSelector((state) => state.manufacturingOrder);
  const history = useHistory();
  const params = useParams();
  const [referenceNumber, setReferenceNumber] = useState('');
  const [dialogLoadingOpen, setDialogLoadingOpen] = useState(false);

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.manufacturingOrderGet(params.id));
    } catch (error) {
      dispatch(actions.manufacturingOrderError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const handleSocketIOFunction = {
    onSuccess: () => {
      setDialogLoadingOpen(false);
      window.alert('ยกเลิกไลน์การผลิตสำเร็จ');
    },
    onFail: (args) => {
      setDialogLoadingOpen(false);
      console.log('Error', args?.error);
      window.alert(args?.message);
    },
  };

  const handleEditManufacturingOrder = async (data) => {
    try {
      await dispatch(
        actions.manufacturingOrderPut(params.id, { ...data, referenceNumber }),
      );

      setDialogLoadingOpen(true);
    } catch (error) {
      console.error('Edit ManufacturingOrder Fail', error);
      window.alert('ไม่สามารถแก้ไขคำสั่งผลิตได้', error?.message);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (manufacturingOrder.isLoading) {
    return <Loading />;
  }

  if (!manufacturingOrder.isLoading && manufacturingOrder.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <SocketIOLoading
          taskCode={MQ_TASK.UPDATE_PROCESS.status_code}
          handleSuccess={handleSocketIOFunction.onSuccess}
          referenceNumber={referenceNumber}
          handleFail={handleSocketIOFunction.onFail}
        />
        <div>
          <Card className="p-4">
            <form>
              <div className="flex  w-full justify-between">
                <div className="flex flex-wrap">
                  <div className="w-full md:w-3/6 py-1 md:py-2 px-2 font-semibold font-display ">
                    เลขที่คำสั่งผลิต
                  </div>
                  <div className="w-full md:w-3/6 py-1 md:py-2 px-2 font-semibold font-display">
                    {manufacturingOrder?.running_number}
                  </div>
                </div>
              </div>
            </form>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

RecieveMO.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

RecieveMO.defaultProps = {
  title: '',
  subtitle: '',
};
