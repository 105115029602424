import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

import { notNan } from '@/utils/functions';

export default function MaterialUsageBox({
  isRequested,
  selectedMaterial,
  process,
}) {
  const materialInfo = () => {
    if (isRequested || process?.current?.index !== -1) {
      return (
        <div className="grid grid-cols-2 p-2">
          <div className="my-2">
            <h4>เบิกมา</h4>
            <div className="flex gap-2">
              <div className="font-bold  font-display text-2xl">
                {notNan(selectedMaterial?.requestAmount)}{' '}
              </div>
              <div className="text-sm self-end">
                {selectedMaterial?.materialInfo?.unit}{' '}
              </div>
            </div>
          </div>
          <div className="my-2">
            <h4>ใช้จริง</h4>
            <div className="flex gap-2">
              <div className="font-bold  font-display text-2xl">
                {notNan(selectedMaterial?.usedAmount)}
              </div>
              <div className="text-sm self-end">
                {selectedMaterial?.materialInfo?.unit}{' '}
              </div>
            </div>
          </div>
          <div className="my-2">
            <h4>ได้เป็นผลผลิต</h4>
            <div className="flex gap-2">
              <div className="font-bold  font-display text-2xl">
                {' '}
                {notNan(selectedMaterial?.resultAmount)}
              </div>
              <div className="text-sm self-end">
                {selectedMaterial?.materialInfo?.unit}{' '}
              </div>
            </div>
          </div>
          <div className="my-2">
            <h4>คืน</h4>
            <div className="flex gap-2">
              <div className="font-bold  font-display text-2xl">
                {' '}
                {notNan(selectedMaterial?.returnAmount)}
              </div>
              <div className="text-sm self-end">
                {selectedMaterial?.materialInfo?.unit}{' '}
              </div>
            </div>
          </div>
          <div className="my-2">
            <h4>ของเสีย</h4>
            <div className="flex gap-2">
              <div className="font-bold  font-display text-2xl">
                {' '}
                {notNan(selectedMaterial?.wastesAmount)}
              </div>
              <div className="text-sm self-end">
                {selectedMaterial?.materialInfo?.unit}{' '}
              </div>
            </div>
          </div>
          <div className="my-2">
            <h4>Yield ของการผลิต</h4>
            <div className="flex gap-2">
              <div className="font-bold  font-display text-2xl">
                {' '}
                {parseFloat(selectedMaterial?.yield).toFixed(2)} %
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div>
        {' '}
        <h4 className=" text-lg text-yellow-500 py-4">ยังไม่ได้เบิกวัตถุดิบ</h4>
      </div>
    );
  };

  return (
    <div className="px-2 w-full md:w-1/2 lg:w-1/3 ">
      <div className="self-center rounded-md  px-4 py-2 min-w-56 border border-gray-300 shadow-md">
        <div className="flex justify-between">
          <h4 className="font-semibold font-display text-lg my-2">
            {selectedMaterial?.materialInfo?.type_code}{' '}
            {selectedMaterial?.materialInfo?.name}{' '}
          </h4>
          <Link
            className="self-center"
            to={`/mms/manufacturing-orders/material/${process?._id}/${selectedMaterial?.materialInfo?._id}`}
          >
            <Button size="small">รายละเอียด</Button>
          </Link>
        </div>

        {materialInfo()}
      </div>
    </div>
  );
}

MaterialUsageBox.propTypes = {
  isRequested: PropTypes.bool,
  selectedMaterial: PropTypes.object,
  process: PropTypes.object,
};
