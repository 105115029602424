import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Link as MUILink, TextField } from '@mui/material';
import PropTypes from 'prop-types';

import { PassportAuth } from '../../contexts/AuthContext';

export default function Register({ information }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const { handleSignup } = useContext(PassportAuth);
  const history = useHistory();
  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      await handleSignup(
        data.username,
        data.password,
        data.firstname,
        data.lastname,
      );

      history.push('/');
    } catch (error) {
      window.alert('ไม่สามารถสมัครเข้าสู่ระบบได้');
    }
  };

  return (
    <div className="container mx-auto px-4 h-full">
      <div className="flex content-center items-center justify-center h-full">
        <div className="w-full lg:w-6/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <div className="flex justify-center py-2">
                <img src={information?.logo?.url} className="h-24" />
              </div>
              <div className="rounded-t mb-0 px-6 py-2">
                <div className="text-center mb-3">
                  <h6 className="text-gray-600 my-2 text-lg font-bold font-display">
                    ขอสมัครสมาชิก
                  </h6>
                  <div>
                    เพื่อสร้างบัญชีในการเข้าสู่ระบบ โดยหลังจากการสมัครแล้ว
                    ท่านจะยังไม่สามารถเข้าใช้ระบบได้
                    จนกว่าจะได้รับอนุมัติจากผู้ดูแลระบบ
                  </div>
                </div>
              </div>
              <hr />
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="my-4">
                  <b>ชื่อผู้ใช้</b> สำหรับการลงชื่อเข้าใช้ (Login)
                  เข้าระบบครั้งต่อไป{' '}
                  <div className="text-gray-500">
                    ชื่อผู้ใช้ควรเป็นภาษาอังกฤษ หรือ ตัวเลข และ พิมพ์เล็กทั้งหมด
                  </div>
                </div>
                <div className="my-2">
                  <TextField
                    name="username"
                    label="ชื่อผู้ใช้"
                    variant="outlined"
                    fullWidth
                    {...register('username', { required: true })}
                    helperText={errors.username && 'กรุณาใส่ข้อมูล'}
                  />
                </div>
                <div className="my-4">
                  <b>รหัสผ่าน</b> สำหรับการเข้าใช้
                  <div className="text-gray-500">
                    ชื่อผู้ใช้ควรเป็นภาษาอังกฤษ หรือ ตัวเลข
                    เพื่อการจดจำที่ง่ายขึ้น
                  </div>
                </div>
                <div className="my-2">
                  <TextField
                    label="รหัสผ่าน"
                    variant="outlined"
                    fullWidth
                    {...register('password', { required: true })}
                    helperText={errors.password && 'กรุณาใส่ข้อมูล'}
                  />
                </div>
                <div className="my-4">
                  <div className="text-gray-500">
                    ท่านสามารถบันทึกภาพหน้าจอตรงนี้ได้ เพื่อไม่ลืมรหัสผ่าน
                  </div>
                </div>{' '}
                <div className="my-2">
                  <div className="font-semibold">ข้อมูลส่วนตัว</div>
                </div>
                <div className="my-2 flex ">
                  <div className="my-2 w-full lg:w-1/2 pr-2">
                    <TextField
                      name="firstname"
                      label="ชื่อ"
                      variant="outlined"
                      fullWidth
                      {...register('firstname', { required: true })}
                      helperText={errors.firstname && 'กรุณาใส่ข้อมูล'}
                    />
                  </div>{' '}
                  <div className="my-2 w-full lg:w-1/2 pl-2">
                    <TextField
                      name="lastname"
                      label="นามสกุล"
                      variant="outlined"
                      fullWidth
                      {...register('lastname')}
                      helperText={errors.lastname && 'กรุณาใส่ข้อมูล'}
                    />
                  </div>
                </div>
                <div className="my-4">
                  <Button fullWidth type="submit" variant="contained">
                    บันทึก
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Register.propTypes = {
  information: PropTypes.object,
};
