import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Button, Card } from '@mui/material';
import { useParams, useHistory } from 'react-router';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import { BackButton } from '@iarcpsu/emanufac-components/src/components/Button';
import { DecreaseOrderForm } from '@iarcpsu/emanufac-components/src/components/Forms';
import { Loading } from '@iarcpsu/emanufac-components/src/components/Loading';
import { Error } from '@iarcpsu/emanufac-components/src/components/Error';
import { ViewTitle } from '@iarcpsu/emanufac-components/src/components/ViewTitle';
import * as CONFIG from '../../utils/constants';

const DecreaseOrder = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const order = useSelector((state) => state.order);
  const customer = useSelector((state) => state.customer);
  const shippingType = useSelector((state) => state.shippingType);
  const paymentType = useSelector((state) => state.paymentType);
  const product = useSelector((state) => state.product);
  const material = useSelector((state) => state.material);
  const history = useHistory();

  const [name] = useState('');
  const [page] = useState(1);
  const [size] = useState('');
  const [selectStockLot, setSelectStockLot] = useState([]);
  const [selectMaterialLot, setSelectMaterialLot] = useState([]);

  const { handleSubmit } = useForm();

  useEffect(() => {
    const getOrder = async () => {
      try {
        await dispatch(actions.orderGet(id));
      } catch (error) {
        console.error('Cannot Get Order');
      }
    };

    const fetchCustomers = async () => {
      try {
        await dispatch(actions.customerAll({ name, page, size }));
      } catch (error) {
        console.error('Customers get all error', error);
      }
    };
    const fetchShippingType = async () => {
      try {
        await dispatch(actions.shippingTypeAll({ name, page, size }));
      } catch (error) {
        console.error('Cannot Fetch Shipping Type');
      }
    };

    const fetchPaymentType = async () => {
      try {
        await dispatch(actions.paymentTypeAll({ name, page, size }));
      } catch (error) {
        console.error('Cannot Fetch Payment Type');
      }
    };

    const fetchProducts = async () => {
      try {
        await dispatch(
          actions.productAll({
            name: '',
            page,
            size: '',
            fetchStockLot: true,
            stockStatus: 'IN_STOCK',
          }),
        );
      } catch (error) {
        console.error('Cannot Fetch Product');
      }
    };

    const fetchMaterial = async () => {
      try {
        await dispatch(
          actions.materialAll({
            name: '',
            page,
            size: '',
            fetchStockLot: true,
            stockStatus: 'IN_STOCK',
          }),
        );
      } catch (error) {
        console.error('Cannot Fetch Material');
      }
    };

    getOrder();
    fetchCustomers();
    fetchShippingType();
    fetchPaymentType();
    fetchProducts();
    fetchMaterial();

    return () => {};
  }, [id]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data, event) => {
    const confirm = window.confirm('ยืนยันการตัดสต๊อกสินค้า');
    if (confirm) {
      try {
        const channgStatus = {
          ...order.commerce,
          ...order.delivery,
          ...order.payment,
          order: order._id,
          remark: order.remark,
          product_transaction_type: order.product_transaction_type,
          material_transaction_type: order.material_transaction_type,
          customer: order.customer,
          status: CONFIG.ORDER_STATUS.ORDER_PACK.status_code,
          productLots: selectStockLot,
          materialLots: selectMaterialLot,
        };
        event.preventDefault();
        await dispatch(actions.orderPut(id, channgStatus));
        alert('สำเร็จ');
        await dispatch(actions.orderGet(id));
        history.goBack();
      } catch (error) {
        alert(`ดำเนินการไม่สำเร็จ ${error?.message}`);
        console.log(error);
      }
    }
  };

  // prettier-ignore
  const isPageLoading = () => customer.isLoading || order.isLoading || order.rows ||
    shippingType.isLoading ||
    paymentType.isLoading;

  // prettier-ignore
  const isPageNotLoading = () => !customer.isLoading && !order.isLoading && !order.rows &&
    !shippingType.isLoading &&
    !paymentType.isLoading;

  // prettier-ignore
  const isPageComplete = () => customer.isCompleted && order.isCompleted &&
    shippingType.isCompleted &&
    paymentType.isCompleted;

  const renderSelectProduct = () => (
    <DecreaseOrderForm
      product={product.rows}
      material={material.rows}
      setSelectStockLot={setSelectStockLot}
      setSelectMaterialLot={setSelectMaterialLot}
      order={order}
    />
  );

  if (isPageLoading()) {
    return <Loading />;
  }

  if (isPageNotLoading() && isPageComplete()) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card className="px-6 py-4">
            <div className="">
              <b>ข้อมูลสินค้า</b>
              {renderSelectProduct()}
            </div>
            <div className="flex flex-row justify-end gap-1 py-2">
              <Button
                variant="contained"
                type="submit"
                disabled={_.find(selectStockLot, { lotId: '' })}
              >
                บันทึก
              </Button>
            </div>
          </Card>
        </form>
      </div>
    );
  }
  return <Error />;
};

DecreaseOrder.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DecreaseOrder.defaultProps = {
  title: '',
  subtitle: '',
};

export default DecreaseOrder;
