import React from 'react';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as CONSTANT from '@/utils/constants';

const MeasurementTypeTable = ({
  measurementTypes,
  page,
  size,
  total,
  handlePushToEditPage,
  handleChangeRowsPerPage,
  handleChangePage,
  handleDelete,
}) => (
  <div>
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">รหัสตัวแปร</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">ชื่อตัวแปร</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">ชนิดตัวแปร</div>
            </TableCell>{' '}
            <TableCell>
              <div className="font-bold">หน่วย</div>
            </TableCell>{' '}
            <TableCell>
              <div className="font-bold">ค่าเริ่มต้น</div>
            </TableCell>{' '}
            <TableCell>
              <div className="font-bold">ดำเนินการ</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(measurementTypes?.rows, (_measurementType, index) => (
            <TableRow key={index}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{_measurementType?.type_code || '-'}</TableCell>
              <TableCell>
                <div className="font-semibold">{_measurementType?.name}</div>
                <div>{_measurementType?.description}</div>
              </TableCell>
              <TableCell>
                <i
                  className={`${
                    CONSTANT.DATA_TYPE[_measurementType.data_type]?.icon
                  } text-lg mr-2`}
                />
                {CONSTANT.DATA_TYPE[_measurementType.data_type]?.description}
              </TableCell>
              <TableCell>
                {_measurementType?.date_type ===
                CONSTANT.DATA_TYPE.NUMBER.description
                  ? _measurementType?.unit?.name || '-'
                  : '-'}
              </TableCell>
              <TableCell>{_measurementType?.default_value || '-'}</TableCell>
              <TableCell>
                <div className="flex flex-row gap-1">
                  <Button
                    size="small"
                    variant="contained"
                    color="warning"
                    onClick={() => handlePushToEditPage(_measurementType?._id)}
                  >
                    แก้ไข
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    color="error"
                    onClick={() => handleDelete(_measurementType?._id)}
                  >
                    ลบ
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page - 1}
        count={total || 1}
        rowsPerPage={size}
        onPageChange={handleChangePage}
      />
    </Paper>
  </div>
);

MeasurementTypeTable.propTypes = {
  measurementTypes: PropTypes.shape({
    total: PropTypes.number,
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  handlePushToEditPage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  handleChangePage: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default MeasurementTypeTable;
