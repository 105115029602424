import {
  QT_ID_TRANSACTION_ALL,
  QT_ID_TRANSACTION_DELETE,
  QT_ID_TRANSACTION_ERROR,
  QT_ID_TRANSACTION_GET,
  QT_ID_TRANSACTION_LOADING,
  QT_ID_TRANSACTION_POST,
  QT_ID_TRANSACTION_PUT,
} from '../../actions/types';
import { BaseReducer } from '../../class';

const basedReducerObject = new BaseReducer({
  allConst: QT_ID_TRANSACTION_ALL,
  getConst: QT_ID_TRANSACTION_GET,
  postConst: QT_ID_TRANSACTION_POST,
  putConst: QT_ID_TRANSACTION_PUT,
  deleteConst: QT_ID_TRANSACTION_DELETE,
  errorConst: QT_ID_TRANSACTION_ERROR,
  loadingConst: QT_ID_TRANSACTION_LOADING,
});

const initialState = {
  isLoading: true,
  isCompleted: true,
};

const QTAndIDTransactionReducer = (state = initialState, action) =>
  basedReducerObject.getReducer(state, action);

export default QTAndIDTransactionReducer;
