import {
  MANUFACTURING_TRANSACTION_ALL,
  MANUFACTURING_TRANSACTION_GET,
  MANUFACTURING_TRANSACTION_DELETE,
  MANUFACTURING_TRANSACTION_PUT,
  MANUFACTURING_TRANSACTION_POST,
  MANUFACTURING_TRANSACTION_LOADING,
  MANUFACTURING_TRANSACTION_ERROR,
} from '../../actions/types';

const initialState = {
  manufacturingTransaction: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function ManufacturungTransactionReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case MANUFACTURING_TRANSACTION_LOADING:
      return { isLoading: true, isCompleted: true };
    case MANUFACTURING_TRANSACTION_ERROR:
      return { isLoading: false, isCompleted: false, ...action.payload };
    case MANUFACTURING_TRANSACTION_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MANUFACTURING_TRANSACTION_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MANUFACTURING_TRANSACTION_POST:
      return { isLoading: false, isCompleted: true };
    case MANUFACTURING_TRANSACTION_PUT:
      return { isLoading: false, isCompleted: true };
    case MANUFACTURING_TRANSACTION_DELETE:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
