import api from '../../../configs/api';
import {
  PAYROLL_ADJ_ALL,
  PAYROLL_ADJ_DELETE,
  PAYROLL_ADJ_ERROR,
  PAYROLL_ADJ_GET,
  PAYROLL_ADJ_LOADING,
  PAYROLL_ADJ_POST,
  PAYROLL_ADJ_PUT,
} from '../types';

export const payrollAdjustmentCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PAYROLL_ADJ_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/payroll-adjustment`,
      {
        ...payload,
      },
    );
    dispatch({ type: PAYROLL_ADJ_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PAYROLL_ADJ_ERROR });
    throw new Error(error);
  }
};

export const payrollAdjustmentAll = ({
  name = '',
  size = 10,
  page = 1,
  employee = '',
}) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/payroll-adjustment?name=${name}&size=${size}&page=${page}&employee=${employee}`,
    );
    if (status === 200) {
      dispatch({ type: PAYROLL_ADJ_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PAYROLL_ADJ_ERROR });
    throw new Error(error);
  }
};

export const payrollAdjustmentError = async (dispatch) => {
  dispatch({ type: PAYROLL_ADJ_ERROR });
};

export const payrollAdjustmentGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/payroll-adjustment/${id}`,
    );
    if (status === 200) {
      dispatch({ type: PAYROLL_ADJ_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PAYROLL_ADJ_ERROR });
    throw new Error(error);
  }
};

export const payrollAdjustmentStateSet = (data) => async (dispatch) => {
  dispatch({ type: PAYROLL_ADJ_ALL, payload: data });
};
export const payrollAdjustmentStateOneSet = (data) => async (dispatch) => {
  dispatch({ type: PAYROLL_ADJ_GET, payload: data });
};

export const payrollAdjustmentPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: PAYROLL_ADJ_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/payroll-adjustment/${id}`,
      payload,
    );
    dispatch({ type: PAYROLL_ADJ_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PAYROLL_ADJ_ERROR });
    throw new Error(error);
  }
};
export const payrollAdjustmentDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: PAYROLL_ADJ_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/payroll-adjustment/${id}`,
    );
    dispatch({ type: PAYROLL_ADJ_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PAYROLL_ADJ_ERROR });
    throw new Error(error);
  }
};
