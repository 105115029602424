import React from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';

import { Error } from '../Error';

export default function YieldPieChart({ materialOnStep }) {
  const series = [
    materialOnStep?.end,
    materialOnStep?.wastes,
    materialOnStep?.return,
  ];
  const labels = ['ผลผลิตที่ได้', 'ของเสีย', 'คืนของ'];
  const options = {
    labels,
    colors: ['#2541B2', '#FF6347', '#008B8B'],
    yaxis: {
      labels: {
        formatter: (value) => parseFloat(value).toFixed(2),
      },
    },
    legend: {
      show: false,
    },
  };

  const ErrorFallback = ({ error }) => {
    return <Error message={error?.message} />;
  };

  return (
    <div className="my-2">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Chart type="pie" series={series} options={options} />
      </ErrorBoundary>
    </div>
  );
}

YieldPieChart.propTypes = {
  materialOnStep: PropTypes.shape({
    material: PropTypes.object,
    yield: PropTypes.number,
    wastes: PropTypes.number,
    begin: PropTypes.number,
    end: PropTypes.number,
    return: PropTypes.number,
  }),
  error: PropTypes.object,
};
