import api from '../../../configs/api';
import graphqlClient from '../../../configs/graphqlClient';
import {
  MATERIAL_SOURCE_ALL,
  MATERIAL_SOURCE_DELETE,
  MATERIAL_SOURCE_ERROR,
  MATERIAL_SOURCE_GET,
  MATERIAL_SOURCE_LOADING,
  MATERIAL_SOURCE_POST,
  MATERIAL_SOURCE_PUT,
} from '../types';

export const materialSourceCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_SOURCE_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/material-source`,
      {
        ...payload,
      },
    );
    dispatch({ type: MATERIAL_SOURCE_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_SOURCE_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const materialSourceAll = ({ name = '', size = 10, page = 1 }) => async (
  dispatch,
) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/material-source?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: MATERIAL_SOURCE_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_SOURCE_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const materialSourceGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/material-source/${id}`,
    );
    if (status === 200) {
      dispatch({ type: MATERIAL_SOURCE_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_SOURCE_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const materialSourceStateSet = (data) => async (dispatch) => {
  dispatch({ type: MATERIAL_SOURCE_ALL, payload: data });
};

export const materialSourceStateOneSet = (data) => async (dispatch) => {
  dispatch({ type: MATERIAL_SOURCE_GET, payload: data });
};

export const materialSourceReset = () => async (dispatch) => {
  dispatch({ type: MATERIAL_SOURCE_LOADING });
};

export const materialSourceError = () => async (dispatch) => {
  dispatch({ type: MATERIAL_SOURCE_ERROR });
};

export const materialSourcePut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_SOURCE_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/material-source/${id}`,
      payload,
    );
    dispatch({ type: MATERIAL_SOURCE_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_SOURCE_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};
export const materialSourceDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_SOURCE_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/material-source/${id}`,
    );
    dispatch({ type: MATERIAL_SOURCE_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_SOURCE_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};
