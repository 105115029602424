import React, { useState } from 'react';
import { ChevronDown, ChevronRight } from 'react-feather';
import { useSelector } from 'react-redux';
import {
  matchPath,
  NavLink as RouterLink,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { Accordion, Collapse, List, ListItemButton } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import accessRight from '@/utils/functions/accessRight';

const NavSubItem = ({ href, title, sub, moduleName, me }) => {
  const availableModule = useSelector((state) => state.availableModule);
  const history = useHistory();
  const location = useLocation();
  const active = href
    ? !!matchPath(location.pathname, {
        path: href,
      })
    : false;
  const [open, setOpen] = useState(active);

  const handleClick = () => {
    setOpen(!open);
    history.push(href);
  };

  return (
    <div className="pl-6 py-2">
      <Accordion
        elevation={0}
        expanded={open}
        onClick={() => {
          handleClick();
        }}
        sx={{
          ...(active && {
            color: 'primary.main',
          }),
        }}
        TransitionProps={{ unmountOnExit: true }}
        className="pl-6 cursor-pointer"
      >
        <div className="flex gap-1 text-sm">
          <span>{title}</span>
          {_.size(sub) ? (
            <div>
              {open ? <ChevronDown size="20" /> : <ChevronRight size="20" />}
            </div>
          ) : (
            <></>
          )}
        </div>
      </Accordion>
      {_.size(sub) ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {_.map(sub, (item, index) => {
            if (accessRight(me, moduleName, item.level, availableModule)) {
              return (
                <div key={index}>
                  <List component="div" disablePadding>
                    <ListItemButton
                      component={RouterLink}
                      to={item.href}
                      sx={{
                        pl: 5,
                        ...(item.href === location.pathname && {
                          color: 'primary.main',
                          backgroundColor: 'grey.100',
                        }),
                      }}
                    >
                      <div className=" text-sm">{item.title} </div>
                    </ListItemButton>
                  </List>
                </div>
              );
            }
            return <></>;
          })}
        </Collapse>
      ) : (
        <></>
      )}
    </div>
  );
};

NavSubItem.propTypes = {
  href: PropTypes.string,

  title: PropTypes.string,
  sub: PropTypes.array,
  me: PropTypes.object,
  moduleName: PropTypes.string,
};

export default NavSubItem;
