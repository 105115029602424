/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  DesktopDatepicker,
  EmployeePayrollReport,
  Error,
  Loading,
  MonthyUpAndDownButton,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { app as appConfig } from '../../configs';
import graphqlClient from '../../configs/graphqlClient';
import * as actions from '../../redux/actions';
import { PAYROLL_TYPE } from '../../utils/constants';
import { currencyFormatter } from '../../utils/functions';

/**
 * @function EmployeePayroll
 * @description Display a collections or a list of EmployeePayroll from database
 */

export default function EmployeePayroll({ title, subtitle }) {
  const dispatch = useDispatch();
  const employee = useSelector((state) => state.employee);
  const information = useSelector((state) => state.information);
  const payroll = useSelector((state) => state.payroll);
  const {
    setting: { pams: pamsSetting },
  } = information;
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [name, setName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState(
    dayjs().date(pamsSetting?.period_date).subtract(1, 'month').toDate(),
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    dayjs().date(pamsSetting?.period_date).subtract(1, 'days').toDate(),
  );
  const [total, setTotal] = useState();
  const [earningType, setEarningType] = useState(null);
  const [isUseDuration, setIsUseDuration] = useState(true);
  const [selectedDate, setSelectedDate] = useState(dayjs().toDate());

  const query = gql`
    query FindEmployeePayroll(
      $input: EmployeeInput
      $payrollInput: PayrollInput
    ) {
      findEmployees(input: $input) {
        total
        currPage
        lastPage
        rows {
          _id
          firstname
          lastname
          phone_number
          department {
            name
          }
          other_departments {
            name
          }
          earnings {
            amount
            is_pay_monthy
          }
          payroll_total
          maincost_total
          ot_total
        }
      }
      findPayrolls(input: $payrollInput) {
        totalAmount
        mainCost
        overtimeCost
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        input: {
          fetchPayroll: true,
          page,
          size,
          name,
          earnings_type: earningType,
          startDate: isUseDuration
            ? dayjs(selectedStartDate).format('YYYY-MM-DD')
            : dayjs(selectedDate).format('YYYY-MM-DD'),
          endDate: isUseDuration
            ? dayjs(selectedEndDate).format('YYYY-MM-DD')
            : dayjs(selectedDate).format('YYYY-MM-DD'),
        },
        payrollInput: {
          date: !isUseDuration
            ? dayjs(selectedDate).format('YYYY-MM-DD')
            : null,
          date_start: isUseDuration
            ? dayjs(selectedStartDate).format('YYYY-MM-DD')
            : null,
          date_end: isUseDuration
            ? dayjs(selectedEndDate).format('YYYY-MM-DD')
            : null,
          payroll_type: isUseDuration ? null : PAYROLL_TYPE.DAILY.status_code,
        },
      });

      const employeeData = queryResult?.findEmployees;
      const payrollData = queryResult?.findPayrolls;
      dispatch(actions.employeeStateSet(employeeData));
      dispatch(actions.payrollStateSet(payrollData));
    } catch (error) {
      console.error('Error on Fetch Emplyoee or payroll', error);
      dispatch(actions.employeeError());
    }
  };

  useEffect(() => {
    dispatch(actions.employeeReset());
    dispatch(actions.payrollReset());

    return () => {};
  }, []);

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [
    page,
    size,
    selectedStartDate,
    selectedEndDate,
    name,
    earningType,
    isUseDuration,
    selectedDate,
  ]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    if (employee) {
      setTotal(employee?.total);
    }

    return () => {};
  }, [employee]);

  const renderAllAndPrint = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        input: {
          fetchPayroll: true,
          startDate: dayjs(selectedStartDate).format('YYYY-MM-DD'),
          endDate: dayjs(selectedEndDate).format('YYYY-MM-DD'),
          page: 1,
          size: appConfig.maxFetchSize,
          earnings_type: earningType,
        },
      });

      const allEmployees = queryResult?.findEmployees;
      EmployeePayrollReport(allEmployees?.rows, information, {
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        payroll_type: earningType,
      });
    } catch (err) {
      alert(`ไม่สามารถพิมพ์ PDF ได้ ${err?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderSearch = () => (
    <div>
      <Card>
        <CardContent>
          <div className="flex flex-wrap justify-start ">
            <div className="w-full lg:w-1/4 my-1 self-center ">
              <TextField
                label="ค้นหา"
                fullWidth
                id="outlined-start-adornment"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <i className="fas fa-search"></i>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="my-1 px-2  lg:w-2/4">
              {isUseDuration && (
                <div>
                  <div className="flex gap-2 flex-wrap ">
                    <div>
                      <DesktopDatepicker
                        value={selectedStartDate}
                        setValue={setSelectedStartDate}
                        label="ตั้งแต่"
                        inputFormat="DD MMM BBBB"
                      />
                    </div>
                    <div>
                      <DesktopDatepicker
                        value={selectedEndDate}
                        setValue={setSelectedEndDate}
                        label="จนถึง"
                        inputFormat="DD MMM BBBB"
                      />
                    </div>
                  </div>
                  <MonthyUpAndDownButton
                    cursorDate={pamsSetting?.period_date}
                    selectedStartDate={selectedStartDate}
                    setSelectedEndDate={setSelectedEndDate}
                    setSelectedStartDate={setSelectedStartDate}
                  />
                </div>
              )}

              {!isUseDuration && (
                <DesktopDatepicker
                  value={selectedDate}
                  setValue={setSelectedDate}
                  label="วันที่ต้องการค้นหา"
                  inputFormat="DD MMM BBBB"
                />
              )}

              <div>
                <FormControlLabel
                  label="เลือกเป็นช่วงเวลา"
                  control={
                    <Checkbox
                      checked={isUseDuration}
                      onChange={(event) =>
                        setIsUseDuration(event.target.checked)
                      }
                    />
                  }
                />
              </div>
            </div>

            <div className="w-full lg:w-1/4 my-1">
              <FormControl fullWidth>
                <InputLabel>ประเภทของค่าตอบแทน</InputLabel>
                <Select
                  label="ประเภทของค่าตอบแทน"
                  fullWidth
                  onChange={(event) => {
                    setEarningType(event.target.value);
                  }}
                  value={earningType}
                >
                  <MenuItem key={'00'} value={null}>
                    ทั้งหมด
                  </MenuItem>
                  {_.map(PAYROLL_TYPE, (eachPayrollType, payrollTypeIndex) => (
                    <MenuItem
                      key={payrollTypeIndex}
                      value={eachPayrollType?.status_code}
                    >
                      {' '}
                      {eachPayrollType.description}{' '}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );

  const renderDetailPanel = () => (
    <div className="my-2">
      <Card>
        <CardContent>
          <div className="flex flex-wrap ">
            <div className="w-full lg:w-1/4 my-1">
              <div className="font-display">รวมค่าใช้จ่าย</div>
              <div className="text-3xl font-semibold">
                {currencyFormatter.format(payroll?.totalAmount || 0)} บาท{' '}
              </div>
            </div>
            <div className="w-full lg:w-1/4 my-1">
              <div className="font-display">การทำงานปกติ</div>
              <div className="text-3xl font-semibold">
                {currencyFormatter.format(payroll?.mainCost)} บาท
              </div>
            </div>{' '}
            <div className="w-full lg:w-1/4 my-1">
              <div className="font-display">การทำงานล่วงเวลา</div>
              <div className="text-3xl font-semibold">
                {currencyFormatter.format(payroll?.overtimeCost)} บาท
              </div>
            </div>
            <div className="w-full lg:w-1/4 my-1">
              <div className="font-display">
                ร้อยละของค่าใช้จ่ายการทำงานปกติ
              </div>
              <div className="text-3xl font-semibold">
                {((payroll?.mainCost / payroll?.totalAmount) * 100).toFixed(2)}{' '}
                %{' '}
              </div>
            </div>
            <div className="w-full lg:w-1/4 my-1">
              <div className="font-display">ร้อยละจากเงินที่สามารถจ่ายได้</div>
              <div className="text-3xl font-semibold">
                {(
                  (payroll?.totalAmount /
                    information?.setting?.pams?.monthly_margin) *
                  100
                ).toFixed(2)}{' '}
                %{' '}
              </div>
            </div>
            <div className="w-full lg:w-1/4 my-1">
              <div className="font-display">เงินที่สามารถจ่ายได้</div>
              <div className="text-3xl font-semibold">
                {currencyFormatter.format(
                  information?.setting?.pams?.monthly_margin,
                )}{' '}
                บาท{' '}
              </div>
            </div>
            <div className="w-full lg:w-1/4 my-1">
              <div className="font-display">เงินที่ยังสามารถจ่ายได้</div>
              <div className="text-3xl font-semibold">
                {currencyFormatter.format(
                  information?.setting?.pams?.monthly_margin -
                    payroll?.totalAmount,
                )}{' '}
                บาท{' '}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อ - นามสกุล</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">แผนก</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ประเภทค่าตอบแทน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ค่าตอบแทนปกติ</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">ค่าล่วงเวลา</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">ค่าตอบแทนที่ได้รับ</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(employee?.rows) ? (
                _.map(employee?.rows, (row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="text-xs">{index + 1}</div>
                    </TableCell>
                    <TableCell>
                      {row?.firstname} {'  '} {row?.lastname}
                    </TableCell>
                    <TableCell>
                      {/** Main Department */}
                      {row?.department?.name}
                      {/** Another Department */}
                      <div>
                        {_.map(
                          row?.other_departments,
                          (eachDept, deptIndex) => (
                            <div key={deptIndex}>{eachDept?.name} </div>
                          ),
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      {row?.earnings?.is_pay_monthy
                        ? PAYROLL_TYPE.MONTHY.description
                        : PAYROLL_TYPE.DAILY.description}
                      <div>
                        {row?.earnings?.amount || '-'} บาท /{' '}
                        {row?.earnings?.is_pay_monthy ? 'เดือน' : 'วัน'}
                      </div>
                    </TableCell>
                    <TableCell>
                      {currencyFormatter.format(row?.maincost_total || 0)} บาท
                    </TableCell>
                    <TableCell>
                      {currencyFormatter.format(row?.ot_total || 0)} บาท
                    </TableCell>
                    <TableCell>
                      {currencyFormatter.format(row?.payroll_total || 0)} บาท
                    </TableCell>
                    <TableCell>
                      <Link to={`employee/payroll/${row?._id}`}>
                        <Button
                          size="small"
                          color="secondary"
                          variant="contained"
                        >
                          รายละเอียด
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>{' '}
            <TablePagination
              page={page - 1}
              count={total || 1}
              rowsPerPage={size}
              onPageChange={(event, currPage) => {
                setPage(currPage + 1);
              }}
              onRowsPerPageChange={(event) => {
                setSize(event.target.value);
                setPage(0);
              }}
            />
          </Table>{' '}
        </TableContainer>
      </Paper>
    </div>
  );

  if (employee.isLoading) {
    return <Loading />;
  }

  if (!employee.isLoading && employee.isCompleted) {
    return (
      <div>
        <div className="flex my-2 justify-between">
          {renderTitle()}
          <div className="self-center">
            <div className="flex gap-2">
              <Link to="monthy/create">
                <Button variant="contained">จ่ายค่าตอบแทนรายเดือน</Button>
              </Link>
              <Link to="daily/create">
                <Button variant="contained">จ่ายค่าตอบแทนรายวัน</Button>
              </Link>
              <div>
                <Button
                  variant="contained"
                  color="teal"
                  onClick={renderAllAndPrint}
                >
                  พิมพ์
                </Button>
              </div>
            </div>
          </div>
        </div>
        {renderSearch()}
        {renderDetailPanel()}
        {renderTable()}
      </div>
    );
  }
  return <Error />;
}

EmployeePayroll.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EmployeePayroll.defaultProps = {
  title: '',
  subtitle: '',
};
