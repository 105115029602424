import React from 'react';
import PropTypes from 'prop-types';

import { SidemenuBox } from '../Box';
import ButtonMenuList from '../Button/ButtonMenuList';

function LeftMenu({ me, availableModule, information }) {
  return (
    <div className="min-h-screen bg-white">
      <div className="container pt-40 py-20 mr-auto ml-auto">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-1/2">
            <SidemenuBox information={information} me={me} />
          </div>
          <div className="w-full lg:w-1/2">
            <ButtonMenuList me={me} availableModule={availableModule} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftMenu;

LeftMenu.propTypes = {
  me: PropTypes.object,
  availableModule: PropTypes.arrayOf(PropTypes.object),
  information: PropTypes.object,
};
