import {
  WITHDRAW_DESTINATION_ALL,
  WITHDRAW_DESTINATION_DELETE,
  WITHDRAW_DESTINATION_ERROR,
  WITHDRAW_DESTINATION_GET,
  WITHDRAW_DESTINATION_LOADING,
  WITHDRAW_DESTINATION_POST,
  WITHDRAW_DESTINATION_PUT,
} from '../../actions/types';

const initialState = {
  withdrawDestination: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function WithdrawDestinationReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case WITHDRAW_DESTINATION_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case WITHDRAW_DESTINATION_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case WITHDRAW_DESTINATION_POST:
      return { isLoading: false, isCompleted: true };
    case WITHDRAW_DESTINATION_PUT:
      return { isLoading: false, isCompleted: true };
    case WITHDRAW_DESTINATION_DELETE:
      return { isLoading: false, isCompleted: true };
    case WITHDRAW_DESTINATION_LOADING:
      return { isLoading: true, isCompleted: true };
    case WITHDRAW_DESTINATION_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
