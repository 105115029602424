import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { measurementStringRender } from '@iarcpsu/emanufac-utils/functions';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

const MeasurementDataTable = ({
  measurement,
  page,
  size,
  total,
  handleChangePage,
  handleChangeRowPerPage,
}) => {
  const information = useSelector((state) => state.information);
  const getDetailMOLink = (manufacturingOrder) => {
    if (information?.setting?.mms?.dashboard?.showDashboardC) {
      return `/mms/manufacturing-orders-c/view/${manufacturingOrder?._id}`;
    }
    return `/mms/manufacturing-orders/view/${manufacturingOrder?._id}`;
  };

  return (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <colgroup>
              <col width="5%" />
              <col width="10%" />
              <col width="15%" />
              <col width="10%" />
              <col width="20%" />
              <col width="15%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> คำสั่งผลิต</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ไลน์การผลิต</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">ค่าที่ได้</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">วันที่อัพเดทค่า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(measurement?.rows) ? (
                _.map(measurement?.rows, (row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="text-xs">
                        {(page - 1) * size + index + 1}
                      </div>
                    </TableCell>
                    <TableCell>
                      MO{row?.manufacturing_order?.running_number}
                    </TableCell>
                    <TableCell>
                      {row?.process?.produce_base_project_instead ? (
                        <div>{row?.process?.base_project?.name}</div>
                      ) : (
                        <div>
                          {row?.process?.produce_material_instead ? (
                            <span>
                              {row?.process?.product_as_material?.type_code ||
                                ''}
                              {row?.process?.product_as_material?.name || '-'}
                            </span>
                          ) : (
                            <span>
                              {row?.process?.product?.type_code || ''}
                              {row?.process?.product?.name || '-'}
                            </span>
                          )}
                        </div>
                      )}
                    </TableCell>
                    <TableCell>{measurementStringRender(row)}</TableCell>
                    <TableCell>
                      {dayjs(row?.updatedAt).format('D MMM BBBB HH:mm')}
                    </TableCell>
                    <TableCell>
                      <Link to={getDetailMOLink(row?.manufacturing_order)}>
                        <Button variant="contained" size="small">
                          คำสั่งผลิต
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowPerPage}
        />
      </Paper>
    </div>
  );
};

MeasurementDataTable.propTypes = {
  measurement: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowPerPage: PropTypes.func,
};

export default MeasurementDataTable;
