import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  BackButton,
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Card, Chip } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';

export default function DetailMaterial({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const material = useSelector((state) => state.material);
  const information = useSelector((state) => state.information);

  useEffect(() => {
    dispatch(
      actions.materialGet(id, {
        placeId: '',
        fetchStockLot: '',
        stockStatus: '',
      }),
    );
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (material.isLoading || material.rows) {
    return <Loading />;
  }

  if (!material.isLoading && material.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <div>
          <Card className="p-4 ">
            <div className="flex flex-wrap divide-y">
              <div className="w-full py-4 text-lg font-semibold font-display ">
                {'รายละเอียด'}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold font-display">
                {'รหัสวัตถุดิบ'}
              </div>
              <div className="w-1/2 py-4 ">{`${
                material?.type_code || '-'
              }`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold font-display">
                {'ชื่อ'}
              </div>
              <div className="w-1/2 py-4 ">
                {material?.name}{' '}
                {material?.is_workin_process && (
                  <Chip size="small" color="warning" label="WIP" />
                )}
              </div>
              {material?.detail ? (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold font-display ">
                    {'รายละเอียด'}
                  </div>
                  <div className="w-1/2 py-4 ">{`${
                    material?.detail || ''
                  } `}</div>
                </>
              ) : (
                <></>
              )}
              <div className="w-1/2 py-4 px-2 font-semibold font-display">
                {'หน่วย'}
              </div>
              <div className="w-1/2 py-4 ">
                {material?.use_unit_conversion
                  ? `${material?.unit_input?.short_sign || ''} - ${
                      material?.unit_output?.short_sign
                    }`
                  : material.unit}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold font-display">
                {'ประเภทวัตถุดิบ'}
              </div>
              <div className="w-1/2 py-4 ">{`${
                material?.material_type?.name || ''
              }`}</div>
              {material?.net ? (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold font-display">
                    {'ปริมาตรสุทธิ'}
                  </div>
                  <div className="w-1/2 py-4 ">{`${material?.net} ${material?.unit_net}`}</div>
                </>
              ) : (
                <></>
              )}
              {material?.register_number ? (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold font-display">
                    {'เลขทะเบียน'}
                  </div>
                  <div className="w-1/2 py-4 ">{`${
                    material?.register_number || ''
                  }`}</div>
                </>
              ) : (
                <></>
              )}
              <div className="w-1/2 py-4 px-2 font-semibold font-display">
                {'จำนวนขั้นต่ำของวัตถุดิบ'}
              </div>
              <div className="w-1/2 py-4 ">
                {`${material?.minimum_amount || '-'}`} {material?.unit}
              </div>{' '}
              {material?.is_workin_process && (
                <div className="w-1/2 py-4 px-2 font-semibold font-display">
                  ส่วนผสมของ Work In Process
                </div>
              )}
              {material?.is_workin_process && (
                <div className="w-1/2 py-4 ">
                  {_.map(material?.input_materials, (each, index) => (
                    <li key={index}>
                      <b>{each?.material?.type_code} </b> {each?.material?.name}{' '}
                      x {each?.fraction} {each?.material?.unit || 'หน่วย'}
                    </li>
                  ))}
                </div>
              )}
              {information?.setting?.ims?.materialAttribute?.common_price && (
                <div className="w-1/2 py-4 px-2 font-semibold font-display">
                  ราคาโดยทั่วไปของวัตถุดิบ
                </div>
              )}
              {information?.setting?.ims?.materialAttribute?.common_price && (
                <div className="w-1/2 py-4 ">
                  {`${material?.common_price || '-'}`} บาท
                </div>
              )}
              {material?.additional &&
                _.map(material?.additional, (item, index) => (
                  <div className="w-full flex" key={index}>
                    <div className="w-1/2 py-4 px-2 font-semibold font-display">
                      {index}
                    </div>
                    <div className="w-1/2 py-4 ">{item?.value}</div>
                  </div>
                ))}
              {!_.isEmpty(material?.supplier) ? (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold font-display">
                    {'คู่ค้า'}
                  </div>
                  <div className="w-1/2 py-4 ">
                    <div className="flex flex-wrap">
                      {_.map(material?.supplier, (customer) => (
                        <div
                          key={customer?._id}
                          className="bg-gray-300 mb-2 p-3 rounded-md text-sm mr-2 "
                        >
                          <div>
                            <b>ชื่อ :</b> {`${customer?.name}`}
                          </div>
                          <div>
                            <b>อีเมล : </b>
                            {`${customer?.email}`}
                          </div>
                          <div>
                            <b>โทร :</b>
                            {` ${customer?.phone_number}`}
                          </div>
                          <div>
                            <b>ที่อยู่ :</b>
                            {` ${customer?.address}`}{' '}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailMaterial.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailMaterial.defaultProps = {
  title: '',
  subtitle: '',
};
