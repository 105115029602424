import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  BackButton,
  Error,
  Loading,
  LoadingLinear,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import { config } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';

/**
 * @function EditMaterialIngredient
 * @description Display a collections or a list of EditMaterialIngredient from database
 */

export default function EditMaterialIngredient({ title, subtitle }) {
  const dispatch = useDispatch();
  const material = useSelector((state) => state.material);
  const history = useHistory();
  const params = useParams();
  const [isReady, setIsReady] = useState(false);
  const [materialList, setMaterialList] = useState([]);
  const { control, setValue, handleSubmit, watch } = useForm({});
  const { fields } = useFieldArray({
    control,
    name: 'input_materials',
  });

  const query = gql`
    query FindEditMaterialIngredient($materialInput: MaterialInput) {
      findMaterials(input: $materialInput) {
        rows {
          _id
          name
          type_code
          material_type {
            name
          }
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        materialInput: { page: 1, size: config.maxFetchSize },
      });
      const materialData = queryResult?.findMaterials;
      setMaterialList(materialData.rows);
    } catch (error) {
      console.error('Error', error);
      dispatch(actions.materialError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    dispatch(actions.materialGet(params.id, {}));
    return () => {};
  }, [params]);

  const handleUpdateMaterialIngredient = async (data) => {
    try {
      const inputMaterials = _.filter(
        data.input_materials,
        (each) => each?.selected === true,
      );
      const updateData = {
        ...data,
        input_materials: _.map(inputMaterials, (each) => ({
          material: each?._id,
        })),
      };
      console.log('updated data', updateData);
      await dispatch(actions.materialPut(params.id, updateData));
      Swal.fire({
        icon: 'success',
        title: 'บันทึกสำเร็จ',
      }).then(() => {
        history.goBack();
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: error.message,
      });
    }
  };

  useEffect(() => {
    if ((material, !_.isEmpty(materialList))) {
      setValue('is_workin_process', material?.is_workin_process);
      const materialWithSelect = _.map(materialList, (each) => {
        const isIncludeInMaterial = _.find(
          material?.input_materials,
          (inputMaterial) => inputMaterial.material._id === each._id,
        );

        return { ...each, selected: !!isIncludeInMaterial };
      });
      setValue('input_materials', materialWithSelect);
      setIsReady(true);
    }

    return () => {};
  }, [materialList, material]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (material.isLoading) {
    return <Loading />;
  }

  if (!material.isLoading && material.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex justify-between">
          <BackButton />
          <Button
            variant="contained"
            onClick={handleSubmit(handleUpdateMaterialIngredient)}
          >
            บันทึก
          </Button>
        </div>
        <div>
          <form>
            <Card className="my-2">
              <CardContent>
                <Controller
                  control={control}
                  name="is_workin_process"
                  defaultValue={material?.is_workin_process}
                  render={({ field }) => (
                    <div className="flex gap-2 items-center">
                      <div>วัตถุดิบทั่วไป ส่วนประกอบมาได้จากวัตถุดิบทุกตัว</div>
                      <FormControlLabel
                        label="เป็นวัตถุดิบแบบ Work In Process มาจากรายการวัตถุดิบที่เลือก"
                        control={
                          <Switch {...field} defaultChecked={field.value} />
                        }
                      />
                    </div>
                  )}
                />
              </CardContent>
            </Card>
            {isReady ? (
              <Paper>
                {watch('is_workin_process') && (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <div className="font-bold">เลือก</div>
                          </TableCell>
                          <TableCell>
                            <div className="font-bold"> ลำดับที่</div>
                          </TableCell>
                          <TableCell>
                            <div className="font-bold">
                              วัตถุดิบที่เป็นส่วนประกอบ
                            </div>
                          </TableCell>{' '}
                          <TableCell>
                            <div className="font-bold">หมวดหมู่</div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!_.isEmpty(fields) ? (
                          _.map(fields, (row, index) => (
                            <TableRow
                              key={row.id}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                <Controller
                                  control={control}
                                  name={`input_materials[${index}].selected`}
                                  render={({ field }) => (
                                    <Checkbox
                                      {...field}
                                      defaultChecked={field.value}
                                    />
                                  )}
                                />
                              </TableCell>
                              <TableCell>
                                <div className="text-xs">{index + 1}</div>
                              </TableCell>
                              <TableCell>{row?.name}</TableCell>
                              <TableCell>{row?.material_type?.name}</TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={3}>
                              <div className="text-center">ไม่มีข้อมูล</div>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Paper>
            ) : (
              <LoadingLinear />
            )}
          </form>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditMaterialIngredient.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditMaterialIngredient.defaultProps = {
  title: '',
  subtitle: '',
};
