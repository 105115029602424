import api from '../../../configs/api';
import {
  INFORMATION_ALL,
  INFORMATION_ERROR,
  INFORMATION_LOADING,
  INFORMATION_PUT,
} from '../types';

export const informationAll = () => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/information`,
    );
    if (status === 200) {
      const usingCustomColor = localStorage.getItem('ERP_USE_CUSTOM_COLOR');
      if (usingCustomColor !== 'true' && data?.setting?.colors) {
        const colorSetting = data?.setting?.colors;
        localStorage.setItem('ERP_MAIN_COLOR', colorSetting?.primary);
        localStorage.setItem('ERP_MAIN_SECONDARY', colorSetting?.secondary);
        localStorage.setItem('ERP_MAIN_TEAL', colorSetting?.teal);
        localStorage.setItem('ERP_MAIN_SUCCESS', colorSetting?.success);
        localStorage.setItem('ERP_MAIN_ERROR', colorSetting?.error);
        localStorage.setItem('ERP_MAIN_WARNING', colorSetting?.warning);
      }
      dispatch({ type: INFORMATION_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: INFORMATION_ERROR });
    throw new Error(error);
  }
};

export const informationPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: INFORMATION_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/information/${id}`,
      payload,
    );
    dispatch({ type: INFORMATION_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: INFORMATION_ERROR });
    throw new Error(error);
  }
};
