import React from 'react';
import { Card, CardContent } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { GeneralTableRendering } from '../../Tables';
/**
 * @function TableDashboardElementRendering
 * @description เป็นการเตรียมข้อมูลจากตัวสร้าง Dashbaord ที่เป็นประเภท Table แล้วส่งไปที่ General
 * Table Rendering เป็นตัวแสดงผล โดยตัวหลัก ๆ เลยคือต้องส่ง rows และ columns ไป
 * โดย Columns จะเป็นรายการหลัก โดยส่งเป็น Array ของ Object ที่มี Attribute ว่า name เก็บชื่อ
 * และ ref เก็บชื่อ Ref ที่จะไปใช้กับ Rows
 * ส่วน Rows จะเป็นรายการของแต่ละแถว จะเป็น Array ของ Object ทีมี Key เป็น Ref
 * @param {*} props
 * @returns {React.Component} React Component
 */
const TableDashboardElementRendering = ({
  dashboardElement,
  showAsExample = false,
  dashboardRows,
}) => {
  const columnGenerator = _.map(
    _.orderBy(dashboardElement?.elements, ['index']),
    (eachElement) => ({
      ...eachElement,
      ref: eachElement?.name,
    }),
  );

  const rowGenerator = showAsExample ? [] : dashboardRows;
  return (
    <div className="p-1">
      <Card>
        <CardContent>
          <h3 className="m-1 font-display font-semibold">
            {dashboardElement?.name}
          </h3>
          <GeneralTableRendering
            columns={columnGenerator}
            rows={rowGenerator}
          />
        </CardContent>
      </Card>
    </div>
  );
};

TableDashboardElementRendering.propTypes = {
  dashboardElement: PropTypes.object,
  showAsExample: PropTypes.bool,
  dashboardRows: PropTypes.arrayOf(PropTypes.object),
};

export default TableDashboardElementRendering;
