import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import { getPlaceLaborCostInDay } from '@iarcpsu/emanufac-utils/functions';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';

/**
 * @function DetailLaborCostOnPlaceDate
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function DetailLaborCostOnPlaceDate({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const getAllLaborCost = async () => {
    try {
      const tempAllLaborCost = await getPlaceLaborCostInDay({
        axios: api,
        currentDate,
        placeId: place?._id,
        isFrontend: true,
        maxFetchSize: config.maxFetchSize,
      });
      setTotalLaborCost(tempAllLaborCost);
    } catch (error) {
      console.error('Cannot Get Total Weight And Unit', error);
    }
  };
  useEffect(() => {
    console.log('');
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  return (
    <div>
      {renderTitle()}
      <div></div>
    </div>
  );
}

DetailLaborCostOnPlaceDate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailLaborCostOnPlaceDate.defaultProps = {
  title: '',
  subtitle: '',
};
