import dayjs from 'dayjs';
import measurementStringRendering from '../functions/measurementStringRender';

const MeasurementColumns = (measurementType) => [
  {
    label: 'ลำดับที่',
    value: 'index',
  },
  {
    label: 'ค่าที่บันทึก',
    value: (col) => col?.measurement_type?.name,
  },
  {
    label: 'หมายเลขใบสั่งผลิต',
    value: (col) => `MO${col?.manufacturing_order?.running_number}`,
  },
  {
    label: 'ไลน์การผลิต',
    value: (col) => {
      if (col?.process?.produce_base_project_instead) {
        return col?.process?.base_project?.name;
      }
      if (col?.process?.produce_material_instead) {
        return `${col?.process?.product_as_material?.type_code || ''}${
          col?.process?.product_as_material?.name || '-'
        }`;
      }
      return `${col?.process?.product?.type_code || ''}${
        col?.process?.product?.name || '-'
      }`;
    },
  },
  {
    label: measurementType?.name || 'ค่าที่วัดได้',
    value: (col) => measurementStringRendering(col),
  },
  {
    label: 'วันที่อัพเดทค่า',
    value: (col) => dayjs(col?.updatedAt).format('D/MM/BBBB'),
  },
];

export default MeasurementColumns;
