// import React from 'react';
// import { FlowAnalysisGraph } from '@ant-design/graphs';
// import _ from 'lodash';
// import PropTypes from 'prop-types';

// const VerticleBranchingStepTimeline = ({ processSteps }) => {
//   const modifiedStep = {
//     nodes: _.map(processSteps, (each) => ({
//       id: each?.index?.toString(),
//       value: {
//         title: each?.name,
//         items: [
//           {
//             text: 'วัตถุดิบ',
//             value: each?.use_set_of_material
//               ? _.map(each?.materials, (each) => each?.name)
//               : each?.main_material?.name || '-',
//           },
//         ],
//       },
//     })),

//     edges: _.map(processSteps, (each) => {
//       if (each?.splited_step) {
//         return {
//           source: each?.from_index?.toString(),
//           target: each?.index?.toString(),
//         };
//       }
//       return {
//         source: each?.index?.toString(),
//         target: (each?.index + 1)?.toString(),
//       };
//     }),
//   };

//   const config = {
//     data: modifiedStep,
//     layout: {
//       rankdir: 'TB',
//       ranksepFunc: () => 20,
//     },
//     nodeCfg: {
//       anchorPoints: [
//         [0.5, 0],
//         [0.5, 1],
//       ],
//       size: [140, 25],
//       style: {
//         fill: '#E6EAF1',
//         stroke: '#B2BED5',
//         radius: [2, 2, 2, 2],
//         text: {
//           fontFamily: ['Noto Sans Thai Looped', 'Noto Sans'],
//         },
//       },
//       title: {
//         containerStyle: {
//           fill: 'transparent',
//         },
//         style: {
//           fill: '#000',
//           fontSize: 12,
//           fontFamily: ['Noto Sans Thai Looped', 'Noto Sans'],
//         },
//       },
//       items: {
//         containerStyle: {
//           fill: '#fff',
//         },
//         padding: 6,
//         style: (cfg, group, type) => {
//           const styles = {
//             icon: {
//               width: 12,
//               height: 12,
//             },
//             text: {
//               fill: '#aaa',
//               fontFamily: ['Noto Sans Thai Looped', 'Noto Sans'],
//             },
//             value: {
//               fontFamily: ['Noto Sans Thai Looped', 'Noto Sans'],
//             },
//           };
//           return styles[type];
//         },
//       },
//     },
//     edgeCfg: {
//       type: 'polyline',
//       endArrow: true,
//     },
//     markerCfg: (cfg) => ({
//       position: 'bottom',
//       show: modifiedStep.edges.filter((item) => item.source === cfg.id)?.length,
//     }),
//     behaviors: [
//       'drag-canvas',
//       'zoom-canvas',
//       //    'drag-node'
//     ],
//   };

//   return <FlowAnalysisGraph {...config} />;
// };

// export default VerticleBranchingStepTimeline;

// VerticleBranchingStepTimeline.propTypes = {
//   processSteps: PropTypes.arrayOf(PropTypes.object),
// };

import React from 'react';

export default function VerticleBranchingStepTimeline({}) {
  return <div></div>;
}
