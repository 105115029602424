import React, { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { BackButton } from '@iarcpsu/emanufac-components/src/components/Button';
import ProcessTemplateMeasurementTypeForm from '@iarcpsu/emanufac-components/src/components/Forms/MMS/ProcessTemplateMeasurementTypeForm';
import { Loading } from '@iarcpsu/emanufac-components/src/components/Loading';
import { ViewTitle } from '@iarcpsu/emanufac-components/src/components/ViewTitle';
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import BuddhistCalendar from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import PropTypes from 'prop-types';

import 'dayjs/locale/th';

import * as actions from '../../../redux/actions';

dayjs.extend(BuddhistCalendar);

/**
 * @function SettingProcess
 * @memberof MMS/Setting
 * @description ตั้งค่ากระบวนการทำงาน การเบิกวัตถุดิบ การคืนของ
 * การเพิ่มลงในคลังสินค้า คลังวัตถุดิบ หริืออื่นๆ
 */

const SettingProcess = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  const department = useSelector((state) => state.department);
  const measurementType = useSelector((state) => state.measurementType);
  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: { setting: information?.setting },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'setting.mms.quick_button_on_detail_mo',
  });

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        await dispatch(actions.departmentAll({}));
      } catch (error) {
        console.error('Fetch Department Error', error);
      }
    };
    const fetchMeasurementType = async () => {
      try {
        await dispatch(actions.measurementTypeAll({}));
      } catch (error) {
        console.error('Fetch Department Error', error);
      }
    };
    fetchMeasurementType();
    fetchDepartment();
    return () => {};
  }, []);

  useEffect(() => {
    setValue(
      'setting.mms.quick_button_on_detail_mo',
      information?.setting?.mms?.quick_button_on_detail_mo,
    );
    return () => {};
  }, [information]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleChangeSetting = async (data) => {
    console.log('Data', data);
    const confirm = window.confirm('ยืนยันการปรับการตั้งค่า');
    if (confirm) {
      try {
        await dispatch(
          actions.informationPut(information?._id, {
            ...data,
            setting: {
              ...data.setting,
              mms: {
                ...data.setting.mms,
                fixedLastStep: {
                  ...data.setting.mms.fixedLastStep,
                  measurementTypes: _.map(
                    data?.setting?.mms?.fixedLastStep?.measurementTypes,
                    (_eachMMType) => _eachMMType?._id,
                  ),
                },
              },
            },
          }),
        );
        await dispatch(actions.informationAll());
        window.alert('แก้ไขการตั้งค่าสำเร็จ');
      } catch (error) {
        window.alert('แก้ไขการตั้งค่าไม่สำเร็จ');
        console.error('แก้ไขการตั้งค่าไม่สำเร็จ', error);
      }
    }
  };

  const renderGeneralSetting = () => (
    <Card className="p-4">
      <div className="flex flex-wrap w-full ">
        <div className="w-full py-2 px-2 font-semibold font-display ">
          การเบิกวัตถุดิบ / การเพิ่มลงคลังสินค้า
        </div>
        <div className="w-1/2 py-2 px-2  ">
          ต้องได้รับการอนุญาตจากคลังวัตถุดิบ
        </div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.verifiedMaterialRequest"
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      defaultChecked={
                        information?.setting?.mms?.verifiedMaterialRequest
                      }
                    />
                  }
                  label="เปิดใช้งาน"
                />
              </FormControl>
            )}
          />
        </div>
        <div className="w-1/2 py-2 px-2  ">
          ต้องได้รับการอนุญาตจากคลังสินค้า ในการเพิ่มสินค้า
        </div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.verifiedWarehouseAdd"
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      defaultChecked={
                        information?.setting?.mms?.verifiedWarehouseAdd
                      }
                    />
                  }
                  label="เปิดใช้งาน"
                />
              </FormControl>
            )}
          />
        </div>
        <div className="w-full py-2 px-2 font-semibold font-display ">
          กระบวนการทำงาน
        </div>
        <div className="w-1/2 py-2 px-2  ">
          สร้างงานโดยอัตโนมัติหลังจากสร้างคำสั่งผลิต
        </div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.automaticWorkCreate"
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      defaultChecked={
                        information?.setting?.mms?.automaticWorkCreate
                      }
                    />
                  }
                  label="เปิดใช้งาน"
                />
              </FormControl>
            )}
          />
        </div>
        {watch('setting.mms.automaticWorkCreate') && (
          <div className="w-1/2 py-2 px-2  ">
            เริ่มการทำงานขั้นตอนแรกด้วยตนเอง
          </div>
        )}
        {watch('setting.mms.automaticWorkCreate') && (
          <div className="w-1/2 py-2 px-2">
            <Controller
              control={control}
              name="setting.mms.manuallyStartFirstStep"
              render={({ field }) => (
                <FormControl>
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        defaultChecked={
                          information?.setting?.mms?.manuallyStartFirstStep
                        }
                      />
                    }
                    label="เปิดใช้งาน"
                  />
                </FormControl>
              )}
            />
          </div>
        )}
        <div className="w-1/2 py-2 px-2  ">อนุญาตให้สั่งผลิตวัตถุดิบ</div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.allowProduceMaterial"
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      defaultChecked={
                        information?.setting?.mms?.allowProduceMaterial
                      }
                    />
                  }
                  label="เปิดใช้งาน"
                />
              </FormControl>
            )}
          />
        </div>
        <div className="w-1/2 py-2 px-2  ">ข้ามหน้ามอบหมายงาน</div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.skipAssignmentPage"
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      defaultChecked={
                        information?.setting?.mms?.skipAssignmentPage
                      }
                    />
                  }
                  label="เปิดใช้งาน"
                />
              </FormControl>
            )}
          />
        </div>
        <div className="w-1/2 py-2 px-2  ">
          ให้มีการรับสินค้าก่อนที่จะจบการผลิตในระดับคำสั่งผลิต
          (มีการใส่วันที่สำเร็จ)
        </div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.enableRecieveWorkBeforeEndMO"
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      defaultChecked={
                        information?.setting?.mms?.enableRecieveWorkBeforeEndMO
                      }
                    />
                  }
                  label="เปิดใช้งาน"
                />
              </FormControl>
            )}
          />
        </div>
        <div className="w-full py-2 px-2 font-semibold font-display ">
          กระบวนการทำงาน
        </div>
        <div className="w-1/2 py-2 px-2  ">
          การกรอกปริมาณวัตถุดิบเพื่อคิด Yield ของการผลิต
        </div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.fillYieldUsingWaste"
            render={({ field }) => (
              <FormControl>
                <div className="flex gap-2">
                  <div className="self-center text-black font-system">
                    ใส่ยอดคงเหลือ
                  </div>
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        defaultChecked={
                          information?.setting?.mms?.fillYieldUsingWaste
                        }
                      />
                    }
                    label="ใส่ของเสีย"
                  />
                </div>
              </FormControl>
            )}
          />
        </div>
        <div className="w-1/2 py-2 px-2  ">
          การตั้งต่าการดำเนินการในไลน์การผลิต
        </div>
        <div className="w-1/2 py-2 px-2 flex flex-col">
          <Controller
            control={control}
            name="setting.mms.process.showFileAvailableStatus"
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={<Checkbox {...field} defaultChecked={field.value} />}
                  label="ให้มีการกรอกสถานะว่ามีไฟล์หรือไม่"
                />
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="setting.mms.process.addDeliverProcessBeforeEnd"
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={<Checkbox {...field} defaultChecked={field.value} />}
                  label="ให้มีการเพิ่มกระบวนการส่งของก่อนปิดไลน์การผลิต"
                />
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="setting.mms.process.addCustomerInsideProcess"
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={<Checkbox {...field} defaultChecked={field.value} />}
                  label="ใส่ลูกค้าในไลน์การผลิตแแยก"
                />
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="setting.mms.process.showRoundNumber"
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={<Checkbox {...field} defaultChecked={field.value} />}
                  label="แสดงหมายเลขรอบ"
                />
              </FormControl>
            )}
          />
        </div>
        <div className="w-full py-2 px-2 font-semibold font-display ">
          การบันทึกเวลา
        </div>
        <div className="w-1/2 py-2 px-2  ">แสดงเวลา </div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.timing.showTime"
            render={({ field }) => (
              <FormControl>
                <div className="flex gap-2">
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        defaultChecked={
                          information?.setting?.mms?.timing?.showTime
                        }
                      />
                    }
                    label="เปิดใช้งาน"
                  />
                </div>
              </FormControl>
            )}
          />
        </div>
        <div className="w-1/2 py-2 px-2">
          ใส่เวลาด้วยตนเอง (แทนที่การใช้ Timestamp){' '}
        </div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.timing.manuallyTimeStamping"
            render={({ field }) => (
              <FormControl>
                <div className="flex gap-2">
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        defaultChecked={
                          information?.setting?.mms?.timing
                            ?.manuallyTimeStamping
                        }
                      />
                    }
                    label="เปิดใช้งาน"
                  />
                </div>
              </FormControl>
            )}
          />
        </div>
        <div className="w-1/2 py-2 px-2">
          แสดงเวลาที่ต่างกันระหว่างเวลาเวลาสั่งงานกับเวลาเริ่มงาน
        </div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.timing.showDurationBetweenAssignAndStartWork"
            render={({ field }) => (
              <FormControl>
                <div className="flex gap-2">
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        defaultChecked={
                          information?.setting?.mms?.timing
                            ?.showDurationBetweenAssignAndStartWork
                        }
                      />
                    }
                    label="เปิดใช้งาน"
                  />
                </div>
              </FormControl>
            )}
          />
        </div>
        <div className="w-full py-2 px-2 font-semibold font-display ">
          การทำงานกับเครื่องจักร
        </div>
        <div className="w-1/2 py-2 px-2  ">
          ระบุเครื่องจักรในการทำงานแต่ละไลน์การผลิต{' '}
        </div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.machinery.attachMachine"
            render={({ field }) => (
              <FormControl>
                <div className="flex gap-2">
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        defaultChecked={
                          information?.setting?.mms?.machinery?.attachMachine
                        }
                      />
                    }
                    label="เปิดใช้งาน"
                  />
                </div>
              </FormControl>
            )}
          />
        </div>
        <div className="w-1/2 py-2 px-2">มีการทำงานเป็นกะ</div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.machinery.attachShiftWorkSelector"
            render={({ field }) => (
              <FormControl>
                <div className="flex gap-2">
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        defaultChecked={
                          information?.setting?.mms?.machinery
                            ?.attachShiftWorkSelector
                        }
                      />
                    }
                    label="เปิดใช้งาน"
                  />
                </div>
              </FormControl>
            )}
          />
        </div>
        <div className="w-full py-2 px-2 font-semibold font-display ">
          การทำงานงานในขั้นตอนสุดท้าย
        </div>
        <div className="w-1/2 py-2 px-2">
          อนุญาตให้ผู้รับผิดชอบขั้นตอนสุดท้ายดำเนินการกับผลผลิตได้
        </div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.allowLastStepHandleProduct"
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      defaultChecked={
                        information?.setting?.mms?.allowLastStepHandleProduct
                      }
                    />
                  }
                  label="เปิดใช้งาน"
                />
              </FormControl>
            )}
          />
        </div>
        <div className="w-1/2 py-2 px-2">กำหนดขั้นตอนสุดท้ายเดียวกัน</div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.fixedLastStep.enable"
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      defaultChecked={
                        information?.setting?.mms?.fixedLastStep?.enable
                      }
                    />
                  }
                  label="เปิดใช้งาน"
                />
              </FormControl>
            )}
          />
        </div>
        <div className="w-1/2 py-2 px-2">ชื่อขั้นตอนสุดท้าย</div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.fixedLastStep.name"
            render={({ field }) => (
              <TextField
                {...field}
                label="ชื่อขั้นตอนสุดท้าย"
                size="small"
                disabled={watch('setting.mms.fixedLastStep.enable') !== true}
              />
            )}
          />
        </div>
        <div className="w-1/2 py-2 px-2">แผนกที่รับผิดชอบขั้นตอนสุดท้าย</div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.fixedLastStep.department"
            defaultValue={
              information?.setting?.mms?.fixedLastStep?.department?._id
            }
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel>แผนกที่รับผิดชอบขั้นตอนสุดท้าย</InputLabel>
                <Select
                  size="small"
                  fullWidth
                  disabled={watch('setting.mms.fixedLastStep.enable') !== true}
                  label="แผนกที่รับผิดชอบขั้นตอนสุดท้าย"
                  {...field}
                >
                  {_.map(department?.rows, (_department, index) => (
                    <MenuItem key={index} value={_department?._id}>
                      {' '}
                      {_department?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </div>
        <div className="w-1/2 py-2 px-2">ตัวแปรรับค่าในขั้นตอนสุดท้าย</div>
        <div className="w-1/2 py-2 px-2">
          <ProcessTemplateMeasurementTypeForm
            control={control}
            measurementType={measurementType}
            stepIndex={0}
            alternativeName="setting.mms.fixedLastStep.measurementTypes"
            watch={watch}
          />
        </div>
        <div className="w-full py-2 px-2 font-semibold font-display ">
          วัตถุดิบส่วนผสม Bill Of Material (BOM)
        </div>
        <div className="w-1/2 py-2 px-2">
          เปิดใช้การคำนวณจากวัตถุดิบส่วนผสม (BOM)
        </div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.allowUseMaterialBOM"
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      defaultChecked={
                        information?.setting?.mms?.allowUseMaterialBOM
                      }
                    />
                  }
                  label="เปิดใช้งาน"
                />
              </FormControl>
            )}
          />
        </div>
        <div className="w-full py-2 px-2 font-semibold font-display ">
          โปรเจกต์ / สินค้าต้นแบบ
        </div>
        <div className="w-1/2 py-2 px-2">
          เปิดใช้การสั่งผลิตงานโปรเจกต์/สินค้าต้นแบบ แทนการสั่งผลิตสินค้า หรือ
          วัตถุดิบ
        </div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.allowUseBaseProject"
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      defaultChecked={
                        information?.setting?.mms?.allowUseBaseProject
                      }
                    />
                  }
                  label="เปิดใช้งาน"
                />
              </FormControl>
            )}
          />
        </div>{' '}
        <div className="w-full py-2 px-2 font-semibold font-display ">
          การผลิตแบบการแยกของวัตถุดิบ
        </div>
        <div className="w-1/2 py-2 px-2">
          แสดงทุกๆ ขั้นตอนในทุกๆ คน แม้จะไม่ได้สิทธิให้ใส่ข้อมูล
        </div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.materialSplitting.displayStepForAllAccess"
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      defaultChecked={
                        information?.setting?.mms?.materialSplitting
                          ?.displayStepForAllAccess
                      }
                    />
                  }
                  label="เปิดใช้งาน"
                />
              </FormControl>
            )}
          />
        </div>
        <div className="w-1/2 py-2 px-2">
          แสดงเฉพาะขั้นตอนที่มีวัตถุดิบที่มีจำนวนมากกว่า 0
        </div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.materialSplitting.displayStepOnlyForUnZero"
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      defaultChecked={
                        information?.setting?.mms?.materialSplitting
                          ?.displayStepOnlyForUnZero
                      }
                    />
                  }
                  label="เปิดใช้งาน"
                />
              </FormControl>
            )}
          />
        </div>
        <div className="w-1/2 py-2 px-2">
          แสดงเปอร์เซนต์และปริมาณตามสัดส่วนที่ระบุในสูตร
        </div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.materialSplitting.displayStatisticalBeside"
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      defaultChecked={
                        information?.setting?.mms?.materialSplitting
                          ?.displayStatisticalBeside
                      }
                    />
                  }
                  label="เปิดใช้งาน"
                />
              </FormControl>
            )}
          />
        </div>
        <div className="w-1/2 py-2 px-2">อัพเดทสัดส่วนในสูตรอัตโนมัติ</div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.materialSplitting.automaticFormularPercentUpdate"
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      defaultChecked={
                        information?.setting?.mms?.materialSplitting
                          ?.automaticFormularPercentUpdate
                      }
                    />
                  }
                  label="เปิดใช้งาน"
                />
              </FormControl>
            )}
          />
        </div>{' '}
        <div className="w-1/2 py-2 px-2">แสดงปุ่มเบิกวัตถุดิบ</div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.materialSplitting.showWithdrawMaterialButton"
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      defaultChecked={
                        information?.setting?.mms?.materialSplitting
                          ?.showWithdrawMaterialButton
                      }
                    />
                  }
                  label="เปิดใช้งาน"
                />
              </FormControl>
            )}
          />
        </div>{' '}
        <div className="w-1/2 py-2 px-2">แสดงปุ่มรับวัตถุดิบเข้าสู่การผลิต</div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.materialSplitting.showRecieveMaterialButton"
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      defaultChecked={
                        information?.setting?.mms?.materialSplitting
                          ?.showRecieveMaterialButton
                      }
                    />
                  }
                  label="เปิดใช้งาน"
                />
              </FormControl>
            )}
          />
        </div>{' '}
        <div className="w-full py-2 px-2 font-semibold font-display ">
          การประมาณการต้นทุน (Experiment)
        </div>{' '}
        <div className="w-1/2 py-2 px-2">ระบบประมาณการต้นทุน</div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.cost_estimation.enable"
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      defaultChecked={
                        information?.setting?.mms?.cost_estimation?.enable
                      }
                    />
                  }
                  label="เปิดใช้งาน"
                />
              </FormControl>
            )}
          />
        </div>{' '}
        <div className="w-full py-2 px-2 font-semibold font-display ">
          ปุ่มดำเนินการ Quick Button
        </div>{' '}
        <div className="w-1/2 py-2 px-2">
          ปุ่ม Quick Button หน้ารายละเอียดคำสั่งผลิต
        </div>
        <div className="w-1/2 py-2 px-2">
          <div>
            พิมพ์คำว่า ID ตัวใหญ่เพื่อให้ส่วนนั้นในลิงค์แทนที่ด้วย id
            ของคำสั่งผลิต
          </div>
          {_.map(fields, (eachField, index) => (
            <div key={eachField.id}>
              <div className="my-1 flex flex-wrap">
                <div className="w-2/3">
                  <Controller
                    control={control}
                    name={`setting.mms.quick_button_on_detail_mo[${index}].name`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="ชื่อปุ่ม"
                        size="small"
                        fullWidth
                      />
                    )}
                  />{' '}
                </div>
                <div className="w-1/3">
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => remove(index)}
                    size="small"
                  >
                    ลบ
                  </Button>
                </div>
                <div className="w-full">
                  <Controller
                    control={control}
                    name={`setting.mms.quick_button_on_detail_mo[${index}].link`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="ลิงค์"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          ))}
          <Button variant="contained" size="small" onClick={() => append({})}>
            เพิ่ม
          </Button>
        </div>
      </div>
    </Card>
  );

  if (measurementType.isLoading) {
    return <Loading />;
  }

  return (
    <div>
      {renderTitle()}
      <div className="flex flex-row justify-start pb-4">
        <div>
          <BackButton />
        </div>
      </div>
      <form onSubmit={handleSubmit(handleChangeSetting)}>
        <div>{renderGeneralSetting()}</div>
        <div className="flex justify-end mt-2">
          <Button color="primary" variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  );
};

SettingProcess.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SettingProcess.defaultProps = {
  title: '',
  subtitle: '',
};
export default SettingProcess;
