import { BaseAction } from '../../class';
import {
  QT_ID_TRANSACTION_ALL,
  QT_ID_TRANSACTION_DELETE,
  QT_ID_TRANSACTION_ERROR,
  QT_ID_TRANSACTION_GET,
  QT_ID_TRANSACTION_LOADING,
  QT_ID_TRANSACTION_POST,
  QT_ID_TRANSACTION_PUT,
} from '../types';

const qtAndIdTransactionAction = new BaseAction('qt-id-transaction', {
  allConst: QT_ID_TRANSACTION_ALL,
  getConst: QT_ID_TRANSACTION_GET,
  postConst: QT_ID_TRANSACTION_POST,
  putConst: QT_ID_TRANSACTION_PUT,
  deleteConst: QT_ID_TRANSACTION_DELETE,
  errorConst: QT_ID_TRANSACTION_ERROR,
  loadingConst: QT_ID_TRANSACTION_LOADING,
});

export const qtAndIdTransactionCreate = (data) =>
  qtAndIdTransactionAction.create(data);
export const qtAndIdTransactionAll = ({ name, page, size, ...query }) =>
  qtAndIdTransactionAction.getAllData({ name, page, size, ...query });
export const qtAndIdTransactionGet = (id) =>
  qtAndIdTransactionAction.getOneData(id);
export const qtAndIdTransactionPut = (id, data) =>
  qtAndIdTransactionAction.editOne(id, data);
export const qtAndIdTransactionDelete = (id) =>
  qtAndIdTransactionAction.deleteOne(id);
export const qtAndIdTransactionStateSet = (data) =>
  qtAndIdTransactionAction.stateSet(data);
export const qtAndIdTransactionStateOneSet = (data) =>
  qtAndIdTransactionAction.stateOneSet(data);
export const qtAndIdTransactionReset = () => qtAndIdTransactionAction.reset();
export const qtAndIdTransactionError = () => qtAndIdTransactionAction.onError();
