import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import axios from 'axios';

import background from '../assets/images/BG-2.webp';
import { AuthFooter } from '@iarcpsu/emanufac-components/src/components/Footers';
import { Loading } from '@iarcpsu/emanufac-components/src/components/Loading';
import { AuthNavbar } from '@iarcpsu/emanufac-components/src/components/Navbars';
import { Login, Register } from '../views/Auth';

export const Auth = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [information, setInformation] = useState(null);

  useEffect(() => {
    async function fetchInfo() {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/information`,
      );
      setInformation(data);
    }
    if (!information) fetchInfo();
    return () => {};
  }, [information]);

  useEffect(() => {
    console.log('Layout : Auth');

    setIsLoading(false);
    return () => {};
  }, []);

  if (!isLoading) {
    return (
      <div className="bg-white">
        <AuthNavbar information={information} />
        <section className="relative w-full h-full py-20 lg:py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-gray-900 bg-no-repeat bg-full"
            style={{
              backgroundImage: `url(${background})`,
            }}
          ></div>
          <Switch>
            {window.localStorage.token ? (
              <Redirect from="/" to="/" />
            ) : (
              <Switch>
                <Route path="/auth/login" exact>
                  <Login information={information} />
                </Route>{' '}
                <Route path="/auth/register" exact>
                  <Register information={information} />
                </Route>
                <Redirect from="/auth" to="/auth/login" />
              </Switch>
            )}
          </Switch>
        </section>
        <AuthFooter />
      </div>
    );
  }
  return <Loading />;
};

export default Auth;
