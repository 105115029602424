import api from '../../../configs/api';
import {
  MATERIAL_TRANSACTION_ALL,
  MATERIAL_TRANSACTION_DEL,
  MATERIAL_TRANSACTION_ERROR,
  MATERIAL_TRANSACTION_GET,
  MATERIAL_TRANSACTION_LOADING,
  MATERIAL_TRANSACTION_POST,
  MATERIAL_TRANSACTION_PUT,
  MATERIAL_TRANSACTION_SEARCH,
} from '../types';

export const materialTransactionCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_TRANSACTION_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/material-transaction`,
      {
        ...payload,
      },
    );
    dispatch({ type: MATERIAL_TRANSACTION_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_TRANSACTION_ERROR });
    throw new Error(error);
  }
};

export const materialTransactionAll = ({
  materialName = '',
  size = '',
  page = 1,
  selectType = '',
  withdrawDestination = '',
  date = '',
  start = '',
  end = '',
  materialType = '',
}) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/material-transaction?materialName=${materialName}&size=${size}&page=${page}&selectType=${selectType}&withdrawDestination=${withdrawDestination}&date=${date}&start=${start}&end=${end}&materialType=${materialType}`,
    );
    if (status === 200) {
      dispatch({ type: MATERIAL_TRANSACTION_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_TRANSACTION_ERROR });
    throw new Error(error);
  }
};

export const materialTransactionSearch = ({
  materialName = '',
  material = '',
  size = '',
  page = 1,
  month = '',
  selectType = '',
  date = '',
  start = '',
  end = '',
  place = '',
  materialType = '',
}) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/material-transaction?materialName=${materialName}&size=${size}&page=${page}&month=${month}&selectType=${selectType}&date=${date}&start=${start}&end=${end}&place=${place}&material=${material}&materialType=${materialType}`,
    );
    if (status === 200) {
      dispatch({ type: MATERIAL_TRANSACTION_SEARCH, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_TRANSACTION_ERROR });
    throw new Error(error);
  }
};

export const materialTransactionStateSet = (data) => async (dispatch) => {
  dispatch({ type: MATERIAL_TRANSACTION_ALL, payload: data });
};

export const materialTransactionError = () => async (dispatch) => {
  dispatch({ type: MATERIAL_TRANSACTION_ERROR });
};

export const materialTransactionGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/material-transaction/${id}`,
    );
    if (status === 200) {
      dispatch({ type: MATERIAL_TRANSACTION_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_TRANSACTION_ERROR });
    throw new Error(error);
  }
};

export const materialTransactionPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_TRANSACTION_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/material-transaction/${id}`,
      payload,
    );
    dispatch({ type: MATERIAL_TRANSACTION_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_TRANSACTION_ERROR });
    throw new Error(error);
  }
};
export const materialTransactionDelete = (id, referenceNumber) => async (
  dispatch,
) => {
  try {
    dispatch({ type: MATERIAL_TRANSACTION_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/material-transaction/${id}?referenceNumber=${referenceNumber}`,
    );
    dispatch({ type: MATERIAL_TRANSACTION_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_TRANSACTION_ERROR });
    throw new Error(error);
  }
};

export const materialTransactionLoading = () => async (dispatch) => {
  dispatch({ type: MATERIAL_TRANSACTION_LOADING, payload: {} });
};
