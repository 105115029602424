const MANUFAC_ORDER_STATUS = {
  IN_PROGRESS: {
    status_code: "IN_PROGRESS",
    description: "อยู่ในระหว่างการผลิต",
  },
  LATED: {
    status_code: "LATED",
    description: "เลยกำหนด",
  },
  SUCCESS: {
    status_code: "SUCCESS",
    description: "สำเร็จ",
  },
  CANCEL: {
    status_code: "CANCEL",
    description: "ยกเลิก",
  },
};

module.exports = MANUFAC_ORDER_STATUS;
