/* eslint-disable function-paren-newline */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { MMS, WMS } from '@/utils/Proptypes';

import ProcessStepForm from './ProcessStepForm';

const ProcessTemplateNewForm = ({
  control,
  errors,
  fields,
  remove,
  product,
  move,
  watch,
  setValue,
  append = () => {},
}) => {
  const [isReady, setIsReady] = useState(true);
  const information = useSelector((state) => state.information);

  const [showAddLastStepDialog, setShowAddLastStepDialog] = useState(false);

  const {
    name: selectedName = '',
    department: selectedDepartment = '',
    measurementTypes: selectedMeasurementType = [],
    materials: selectedMaterials = [],
  } = information?.setting?.mms?.fixedLastStep;
  const lastStepPayload = {
    name: selectedName,
    detail: '',
    department: selectedDepartment,
    attachMaterial: false,
    is_fixed_step: true,
    measurementTypes: selectedMeasurementType,
    materials: selectedMaterials,
  };

  useEffect(() => {
    if (_.isEmpty(product?.steps)) {
      if (information?.setting?.mms?.fixedLastStep?.enable === true) {
        setValue('steps', [lastStepPayload]);
      }
    } else if (product?.steps) {
      console.log('PRODUCT STEP', product?.steps);
      setValue(
        'steps',
        _.map(product?.steps, (_productStep) => ({
          ..._productStep,
          measurementTypes: _.map(
            _productStep.measurementTypes,
            (_eachMeasurementType) => _eachMeasurementType,
          ),
          resultingFunctionTypes: _productStep?.resultingFunctionTypes || [],
          materials: _productStep?.materials || [],
        })),
      );
      if (
        information?.setting?.mms?.fixedLastStep?.enable === true &&
        !_.last(product?.steps)?.is_fixed_step
      ) {
        setShowAddLastStepDialog(true);
      }
    }

    return () => {};
  }, [product]);

  const renderDialogAddLastStep = () => (
    <Dialog
      open={showAddLastStepDialog}
      onClose={() => {
        setShowAddLastStepDialog(false);
      }}
    >
      <DialogTitle>เพิ่มงานขั้นสุดท้าย</DialogTitle>
      <DialogContent>
        วิธีการผลิตนี้ถูกสร้างขึ้น ก่อนที่จะมีการตั้งค่าระบบให้มีขั้นตอน{' '}
        <b>{selectedName}</b> เป็นขั้นตอนสุดท้ายในการผลิต
        <br />
        ท่านต้องการเพิ่มขั้นตอนนี้ลงในกาารผลิตหรือไม่
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="success"
          onClick={() => {
            append(lastStepPayload);
            setShowAddLastStepDialog(false);
          }}
        >
          เพิ่ม
        </Button>
        <Button
          variant="contained"
          color="inherit"
          onClick={() => {
            setShowAddLastStepDialog(false);
          }}
        >
          ไม่เพิ่ม
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div>
      {renderDialogAddLastStep()}

      <div>
        <div className="flex flex-wrap">
          <div className={'w-full'}>
            {_.map(fields, (_field, index) => (
              <ProcessStepForm
                control={control}
                errors={errors}
                fieldInfo={_field}
                fields={fields}
                index={index}
                move={move}
                product={product}
                remove={remove}
                isReady={isReady}
                setIsReady={setIsReady}
                watch={watch}
                setValue={setValue}
              />
            ))}
          </div>{' '}
        </div>{' '}
      </div>
    </div>
  );
};

ProcessTemplateNewForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    detail: PropTypes.object,
    status: PropTypes.object,
    steps: MMS.processTemplate,
  }),
  control: PropTypes.object,
  product: WMS.Product,
  fields: PropTypes.arrayOf(PropTypes.object),
  remove: PropTypes.func,
  move: PropTypes.func,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  append: PropTypes.func,
  material: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  resultingFunctionType: PropTypes.object,
  handleSubmit: PropTypes.func,
  submitButton: PropTypes.element,
};

ProcessTemplateNewForm.defaultProps = {
  processTemplate: null,
};

export default ProcessTemplateNewForm;
