import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { MetalDivideDisplayTable } from '../components';
import config from '../config';
import * as actions from '../redux/actions';

const DisplayMetalDivide = ({
  manufacturingOrder,
  currentProcess,
  currentStep,
}) => {
  const bjMetalDividing = useSelector((state) => state.bjMetalDividing);
  const dispatch = useDispatch();
  // ขั้นตอนที่มีการเริ่มใช้ Plugin
  const stepWhosePlugin = _.find(
    currentProcess?.steps,
    (eachStep) => eachStep?.plugin === config.id,
  );

  useEffect(() => {
    dispatch(actions.metalDivideAll({ selectProcess: currentProcess?._id }));
    return () => {};
  }, []);

  if (currentStep?.index <= stepWhosePlugin?.index) {
    return <div></div>;
  }

  const renderEachDividing = (eachMetalDividing) => (
    <div className="flex justify-center ">
      <MetalDivideDisplayTable eachMetalDividing={eachMetalDividing} />
    </div>
  );

  if (bjMetalDividing?.isLoading) return <div></div>;

  if (!bjMetalDividing?.isLoading && bjMetalDividing?.isCompleted) {
    return (
      <div className="my-4">
        <div className="mb-2 font-semibold font-display">
          <div>ตารางผลการตัดลวด</div>
        </div>
        {_.map(bjMetalDividing?.rows, (eachDividing, index) => (
          <div key={index}>{renderEachDividing(eachDividing)}</div>
        ))}
      </div>
    );
  }

  return <div>ไม่สามารถโหลดปลั๊กอินการบันทึกการตัดลวดได้</div>;
};

DisplayMetalDivide.propTypes = {
  manufacturingOrder: PropTypes.object,
  currentProcess: PropTypes.object,
  currentStep: PropTypes.object,
};

export default DisplayMetalDivide;
