import React from 'react';
import { TableRow, TableCell, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';

const TotalSummaryForm = ({
  control,
  getValues,
  setDiscount,
  setReload,
  reload,
  errors,
  order,
}) => (
  <div className="flex justify-end">
    <div>
      <TableRow>
        <TableCell colSpan={3}>
          <div className="text-right">ทั้งหมด</div>
        </TableCell>
        <TableCell>
          <div className="text-center -ml-4">
            {_.size(getValues('material_of_order')) ||
            _.size(getValues('product_of_order')) ? (
              <p>
                {(
                  _.sumBy(getValues('material_of_order'), function (_material) {
                    return parseFloat(_material.price);
                  }) +
                  _.sumBy(getValues('product_of_order'), function (_product) {
                    return parseFloat(_product.price);
                  })
                )?.toFixed(2)}
              </p>
            ) : (
              <p>{0}</p>
            )}
          </div>
        </TableCell>
        <TableCell>
          <div className="text-left">บาท</div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={3}>
          <div className="text-right">ส่วนลด</div>
        </TableCell>
        <TableCell>
          <Controller
            name={'discount'}
            control={control}
            defaultValue={order?.commerce ? order?.commerce?.discount : 0}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  setDiscount(e.target.value);
                  setReload(!reload);
                }}
                label="ส่วนลด"
                required
                inputProps={{
                  min: 0,
                  style: { textAlign: 'center' },
                }}
                size={'small'}
                helperText={errors.discount && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </TableCell>
        <TableCell>
          <div className="text-left">บาท</div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={3}>
          <div className="text-right">รวม</div>
        </TableCell>
        <TableCell>
          <div className="text-center ml-4">
            {_.size(getValues('material_of_order')) ||
            _.size(getValues('product_of_order')) ? (
              <p>
                {(
                  _.sumBy(getValues('material_of_order'), function (_material) {
                    return parseFloat(_material.price);
                  }) +
                  _.sumBy(getValues('product_of_order'), function (_product) {
                    return parseFloat(_product.price);
                  }) -
                  parseFloat(getValues('discount'))
                )?.toFixed(2)}
              </p>
            ) : (
              <p>{0}</p>
            )}
          </div>
        </TableCell>
        <TableCell>
          <div className="text-left">บาท</div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <div></div>
        </TableCell>
      </TableRow>
    </div>
  </div>
);

TotalSummaryForm.propTypes = {
  control: PropTypes.object,
  getValues: PropTypes.func,
  fields: PropTypes.arrayOf(PropTypes.object),
  setDiscount: PropTypes.func,
  setReload: PropTypes.func,
  reload: PropTypes.bool,
  errors: PropTypes.object,
  order: PropTypes.object,
};

export default TotalSummaryForm;
