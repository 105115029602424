import {
  BOM_CHECKING_ALL,
  BOM_CHECKING_DELETE,
  BOM_CHECKING_ERROR,
  BOM_CHECKING_GET,
  BOM_CHECKING_LOADING,
  BOM_CHECKING_POST,
  BOM_CHECKING_PUT,
} from '../../actions/types';

const initialState = {
  bomChecking: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case BOM_CHECKING_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case BOM_CHECKING_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case BOM_CHECKING_POST:
      return { isLoading: false, isCompleted: true };
    case BOM_CHECKING_PUT:
      return { isLoading: false, isCompleted: true };
    case BOM_CHECKING_DELETE:
      return { isLoading: false, isCompleted: true };
    case BOM_CHECKING_LOADING:
      return { isLoading: true, isCompleted: true };
    case BOM_CHECKING_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
