import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { MMS } from '@/utils/functions';

import MultiUnitProdOrMatBox from '../../Box/Rendering/MultiUnitProdOrMatBox';

const MaterialToProductTable = ({ manufacturingMaterial }) => {
  const manufacturingMaterialAnalyzed = MMS.MaterialAnalyzer.manufacturingMaterialStaticAnalyze(
    manufacturingMaterial?.rows,
  );
  console.log('Manufacturing Material Analyzed', manufacturingMaterialAnalyzed);

  return (
    <div className="overflow-x-auto">
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> สินค้าที่ออกมา</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ล็อตสินค้า</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> จำนวน</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(
            manufacturingMaterialAnalyzed.productStockLot,
            (_resultProd, index) => {
              const sumProd = _.sum(
                _.map(_resultProd, (_prod) => _prod?.quantity),
              );
              return (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {_resultProd?.[0]?.product?.type_code}{' '}
                    {_resultProd?.[0]?.product?.name}
                  </TableCell>
                  <TableCell>
                    {_.map(_resultProd, (_prod, prodIndex) => (
                      <div key={prodIndex}>{_prod?.lot_number} </div>
                    ))}
                  </TableCell>
                  <TableCell>
                    <MultiUnitProdOrMatBox
                      foundMaterial={_resultProd?.[0]?.product}
                      quantity={sumProd}
                    />
                  </TableCell>
                </TableRow>
              );
            },
          )}
        </TableBody>
      </Table>
      {_.isEmpty(manufacturingMaterialAnalyzed.productStockLot) && (
        <div className="flex justify-center my-2">
          <div className="font-system p-2 ">ไม่มีสินค้าที่ผลิตได้</div>
        </div>
      )}
    </div>
  );
};

MaterialToProductTable.propTypes = {
  manufacturingMaterial: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default MaterialToProductTable;
