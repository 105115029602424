import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';

import MMSDashboardDragAndDrop from './MMSDashboardDragAndDrop';

const MMSDashboardForm = ({
  control,
  setValue,
  watch,
  errors,
  defaultValue,
  dashboardElement,
  size,
  setSize,
}) => (
  <div>
    <div className="my-2">
      <Card>
        <CardContent>
          <div className="flex flex-wrap">
            <div className="w-full py-2 px-1">
              <Controller
                control={control}
                name="name"
                defaultValue={defaultValue?.name}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    size="small"
                    fullWidth
                    label="ชื่อแดชบอร์ด *"
                    {...field}
                    helperText={errors?.name && 'กรุณาใส่ชื่อแดชบอร์ด'}
                  />
                )}
              />
            </div>
            <div className="w-full py-2 px-1">
              <Controller
                control={control}
                name="is_mo_level"
                defaultValue={defaultValue?.is_mo_level}
                render={({ field }) => (
                  <FormControl>
                    <div className="flex gap-2">
                      <div className="self-center">ระดับไลน์การผลิต</div>
                      <FormControlLabel
                        label="ระดับคำสั่งผลิต"
                        control={
                          <Switch {...field} defaultChecked={field.value} />
                        }
                      />
                    </div>
                  </FormControl>
                )}
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
    <MMSDashboardDragAndDrop
      control={control}
      dashboardElement={dashboardElement}
      setSize={setSize}
      size={size}
      watch={watch}
    />
  </div>
);

MMSDashboardForm.propTypes = {
  control: PropTypes.object,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  errors: PropTypes.object,
  defaultValue: PropTypes.object,
  dashboardElement: PropTypes.shape({
    total: PropTypes.number,
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  size: PropTypes.number,
  setSize: PropTypes.func,
};

export default MMSDashboardForm;
