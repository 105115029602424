import React, { useEffect } from 'react';
import 'dayjs/locale/th';
import { Button } from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import BuddhistCalendar from 'dayjs/plugin/buddhistEra';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { BackButton } from '@iarcpsu/emanufac-components/src/components/Button';
import { ViewTitle } from '@iarcpsu/emanufac-components/src/components/ViewTitle';
import * as actions from '../../../redux/actions';
import { EditManufacturingMaterialForm } from '@iarcpsu/emanufac-components/src/components/Forms';

dayjs.extend(BuddhistCalendar);
dayjs.locale('th');

const EditMaterialUsageTransaction = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const me = useSelector((state) => state.me);
  const manufacturingMaterial = useSelector(
    (state) => state.manufacturingMaterial,
  );
  const process = useSelector((state) => state.process);

  const { control, setValue, watch, handleSubmit } = useForm({
    defaultValues: { manufacturingMaterialArrays: manufacturingMaterial?.rows },
  });

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  useEffect(() => {
    const fetchManufacturingMaterial = async () => {
      try {
        await dispatch(
          actions.manufacMaterialAll({
            processId: params.processid,
            size: 1000,
            page: 1,
            material: params.materialid,
          }),
        );
      } catch (error) {
        console.error(error);
      }
    };

    const getProcess = async () => {
      try {
        await dispatch(actions.processGet(params.processid));
      } catch (error) {
        console.error(error);
      }
    };
    fetchManufacturingMaterial();
    getProcess();
    return () => {};
  }, [params]);

  const onSubmit = async (data) => {
    try {
      await dispatch(
        actions.manufacMaterialPut('0', {
          manufacturingMaterialArrays: data.manufacturingMaterialArrays,
        }),
      );
      window.alert('การแก้ไขสำเร็จแล้ว');
      history.push(
        `/mms/manufacturing-orders/view/${process?.manufacturing_order?._id}`,
      );
    } catch (error) {
      window.alert('การแก้ไขไม่สำเร็จ');
    }
  };

  useEffect(() => {
    if (manufacturingMaterial?.rows) {
      setValue('manufacturingMaterialArrays', manufacturingMaterial?.rows);
    }
    return () => {};
  }, [manufacturingMaterial?.rows]);

  return (
    <div>
      {renderTitle()}

      <div className="flex flex-row justify-between pb-4">
        <div>
          <BackButton />
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <EditManufacturingMaterialForm
          manufacturingMaterials={manufacturingMaterial?.rows}
          control={control}
          me={me}
          setValue={setValue}
          watch={watch}
        />
        <div className="flex justify-end">
          <Button variant="contained" type="submit">
            บันทึกการแก้ไข
          </Button>
        </div>
      </form>
    </div>
  );
};

EditMaterialUsageTransaction.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditMaterialUsageTransaction.defaultProps = {
  title: '',
  subtitle: '',
};

export default EditMaterialUsageTransaction;
