import React, { useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight, ChevronsRight } from 'react-feather';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import dayjs from 'dayjs';
import BuddhistEra from 'dayjs/plugin/buddhistEra';
import dayDuration from 'dayjs/plugin/duration';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import 'dayjs/locale/th';

import { BackButton } from '@iarcpsu/emanufac-components/src/components/Button';
import { ViewTitle } from '@iarcpsu/emanufac-components/src/components/ViewTitle';
import graphqlClient from '../../../configs/graphqlClient';

dayjs.locale('th');
dayjs.extend(BuddhistEra);
dayjs.extend(dayDuration);

const MonthyReport = ({ selectedYear, selectedMonth }) => {
  const [manufacOrderData, setManufacOrderData] = useState();
  const selectedMonthDate = dayjs(selectedYear).month(selectedMonth - 1);
  const query = gql`
    query FindMainDetailMO($input: ManufacturingOrderInput) {
      findManufacturingOrders(input: $input) {
        total
        success
        incumbent
        fail
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        input: {
          startDate: dayjs(selectedMonthDate)
            .startOf('month')
            .format('YYYY-MM-DD'),
          endDate: dayjs(selectedMonthDate).endOf('month').format('YYYY-MM-DD'),
          analyzed: true,
        },
      });
      const tempManufacturingOrderData = queryResult?.findManufacturingOrders;
      setManufacOrderData(tempManufacturingOrderData);
    } catch (err) {
      console.error('Error On Fetch Inside Process', err);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [selectedYear, selectedMonth]);

  return (
    <div>
      <Card>
        <CardContent>
          <div className="flex gap-2">
            <div className="font-display self-center text-lg font-semibold ">
              <Link
                className="hover:underline hover:text-gray-500"
                to={`/mms/manufacturing-order-report/monthy/${dayjs(
                  selectedYear,
                ).format('YYYY')}/${selectedMonth}`}
              >
                {dayjs(selectedMonthDate).format('MMMM')}
              </Link>
            </div>
            <div className="self-center">
              <Link
                to={`/mms/manufacturing-order-report/monthy/${dayjs(
                  selectedYear,
                ).format('YYYY')}/${selectedMonth}`}
              >
                <Button variant="outlined" size="small">
                  รายละเอียด
                </Button>
              </Link>
            </div>
          </div>

          <div className="flex flex-wrap my-2">
            <div className="w-1/4">
              <div className="text-sm">ทั้งหมด</div>
              <div className="flex gap-2">
                <div className="text-3xl font-semibold">
                  {manufacOrderData?.total || 0}
                </div>
                <div className="self-end">รายการ</div>
              </div>
            </div>
            <div className="w-1/4">
              <div className="text-sm">สำเร็จ</div>
              <div className="flex gap-2">
                <div className="text-3xl font-semibold">
                  {manufacOrderData?.success || 0}
                </div>
                <div className="self-end">รายการ</div>
              </div>
            </div>
            <div className="w-1/4">
              <div className="text-sm">ยกเลิก</div>
              <div className="flex gap-2">
                <div className="text-3xl font-semibold">
                  {manufacOrderData?.fail || 0}
                </div>
                <div className="self-end">รายการ</div>
              </div>
            </div>
            <div className="w-1/4">
              <div className="text-sm">อยู่ระหว่างการดำเนินการ</div>
              <div className="flex gap-2">
                <div className="text-3xl font-semibold">
                  {manufacOrderData?.incumbent || 0}
                </div>
                <div className="self-end">รายการ</div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

const ReportManufacturingOrder = ({ title, subtitle }) => {
  const [selectedYear, setSelectedYear] = useState(dayjs());
  const month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const displayDurationTab = () => (
    <div>
      <div className="flex justify-between w-full font-display ">
        <Button
          variant="outlined"
          startIcon={<ChevronLeft size="16" className="self-center" />}
          onClick={() => {
            setSelectedYear(dayjs(selectedYear).subtract(1, 'year'));
          }}
        >
          ก่อนหน้า
        </Button>
        <div className=" text-xl font-semibold text-center">
          พ.ศ. {dayjs(selectedYear)?.format('BBBB')}
        </div>
        <Button
          variant="outlined"
          endIcon={<ChevronsRight size="16" className="self-center" />}
          onClick={() => {
            setSelectedYear(dayjs(selectedYear).add(1, 'year'));
          }}
        >
          ต่อไป
        </Button>
      </div>
    </div>
  );
  return (
    <div>
      {renderTitle()}
      <div className="flex flex-row justify-start pb-4">
        <div>
          <BackButton />
        </div>
      </div>
      <div>{displayDurationTab()}</div>
      <div>
        {_.map(month, (eachMonth, index) => (
          <div key={index} className="my-2">
            <MonthyReport
              selectedMonth={eachMonth}
              selectedYear={selectedYear}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

ReportManufacturingOrder.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MonthyReport.propTypes = {
  selectedYear: PropTypes.number,
  selectedMonth: PropTypes.number,
};

export default ReportManufacturingOrder;
