import api from '../../../configs/api';
import {
  MATERIAL_TRANSACTION_TYPES_ALL,
  MATERIAL_TRANSACTION_TYPES_DEL,
  MATERIAL_TRANSACTION_TYPES_ERROR,
  MATERIAL_TRANSACTION_TYPES_GET,
  MATERIAL_TRANSACTION_TYPES_LOADING,
  MATERIAL_TRANSACTION_TYPES_POST,
  MATERIAL_TRANSACTION_TYPES_PUT,
} from '../types';

export const materialTransactionTypeCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_TRANSACTION_TYPES_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/material-transaction-type`,
      {
        ...payload,
      },
    );
    dispatch({ type: MATERIAL_TRANSACTION_TYPES_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_TRANSACTION_TYPES_ERROR });
    throw new Error(error);
  }
};

export const materialTransactionTypeAll = ({
  name = '',
  size = '',
  page = 1,
  direction = '',
}) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/material-transaction-type?name=${name}&size=${size}&page=${page}&direction=${direction}`,
    );
    if (status === 200) {
      dispatch({ type: MATERIAL_TRANSACTION_TYPES_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_TRANSACTION_TYPES_ERROR });
    throw new Error(error);
  }
};

export const materialTransactionTypeGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/material-transaction-type/${id}`,
    );
    if (status === 200) {
      dispatch({ type: MATERIAL_TRANSACTION_TYPES_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_TRANSACTION_TYPES_ERROR });
    throw new Error(error);
  }
};

export const materialTransactionTypeStateSet = (data) => async (dispatch) => {
  dispatch({ type: MATERIAL_TRANSACTION_TYPES_ALL, payload: data });
};

export const materialTransactionTypePut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_TRANSACTION_TYPES_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/material-transaction-type/${id}`,
      payload,
    );
    dispatch({ type: MATERIAL_TRANSACTION_TYPES_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_TRANSACTION_TYPES_ERROR });
    throw new Error(error);
  }
};
export const materialTransactionTypeDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_TRANSACTION_TYPES_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/material-transaction-type/${id}`,
    );
    dispatch({ type: MATERIAL_TRANSACTION_TYPES_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_TRANSACTION_TYPES_ERROR });
    throw new Error(error);
  }
};
