import {
  PAYROLL_ADJ_ALL,
  PAYROLL_ADJ_DELETE,
  PAYROLL_ADJ_ERROR,
  PAYROLL_ADJ_GET,
  PAYROLL_ADJ_LOADING,
  PAYROLL_ADJ_POST,
  PAYROLL_ADJ_PUT,
} from '../../actions/types';

const initialState = {
  isLoading: true,
  isCompleted: true,
};

// eslint-disable-next-line func-names
export default function PayrollAdjustmentReducer(state = initialState, action) {
  switch (action.type) {
    case PAYROLL_ADJ_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PAYROLL_ADJ_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PAYROLL_ADJ_POST:
      return { isLoading: false, isCompleted: true };
    case PAYROLL_ADJ_PUT:
      return { isLoading: false, isCompleted: true };
    case PAYROLL_ADJ_DELETE:
      return { isLoading: false, isCompleted: true };
    case PAYROLL_ADJ_LOADING:
      return { isLoading: true, isCompleted: true };
    case PAYROLL_ADJ_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
