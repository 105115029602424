import React, { useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import MultiUnitProdOrMatBox from '../../Box/Rendering/MultiUnitProdOrMatBox';
import ProdOrMatQuantityInput from '../../Inputs/ProdOrMatQuantityInput';

export function ApprovalMaterialWithdrawForm({
  material,
  materialWithdraw,
  control,
  getValues,
  setValue,
  watch,
}) {
  const [reload, setReload] = useState(false);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'select_lot',
  });

  const showLot = (materialId) => {
    const findLot = _.find(material, function (_material) {
      return _material._id === materialId;
    });
    return _.size(findLot?.stocklots) ? (
      _.map(findLot?.stocklots, (lot) => (
        <MenuItem key={lot._id} value={lot._id}>
          {lot?.place?.name}{' '}
          {lot?.lot_number ? `หมายเลขล็อต ${lot?.lot_number || ''} ` : 'ล็อต'}
          วันที่ผลิต{' '}
          {dayjs(lot?.production_date).locale('th').format('DD MMM BBBB')}{' '}
          คงเหลือ
          <MultiUnitProdOrMatBox
            foundMaterial={findLot}
            quantity={lot?.amount}
            noWrap
          />
        </MenuItem>
      ))
    ) : (
      <MenuItem disabled value="สินค้าหมด" selected>
        <em>สินค้าหมด</em>
      </MenuItem>
    );
  };

  const calulateAmount = (index) => {
    const stockLots = _.find(material, function (_material) {
      return _material?._id === watch(`select_lot.[${index}].material`);
    }).stocklots;
    fields[index].amount =
      parseFloat(
        _.find(stockLots, function (_stockLots) {
          return _stockLots?._id === watch(`select_lot.[${index}].lotId`);
        })?.amount,
      ) - parseFloat(watch(`select_lot.[${index}].quantity`));
    setValue(`select_lot.[${index}].amount`, fields?.[index]?.amount);
  };

  const showAddButton = (materialId, index) => {
    const findLot = _.find(material, function (_material) {
      return _material._id === materialId;
    });
    return _.isEmpty(findLot?.stocklots) ? (
      <div></div>
    ) : (
      <div>
        <Button
          color={'secondary'}
          variant="contained"
          size={'small'}
          onClick={() =>
            append({
              material: getValues(`select_lot.[${index}].material`),
              lotId: '',
              quantity: 0,
              amount: 0,
            })
          }
        >
          เพิ่ม
        </Button>
      </div>
    );
  };

  const showSelectLot = (materialId, index) => {
    const findLot = _.find(material, function (_material) {
      return _material._id === materialId;
    });
    return _.isEmpty(findLot?.stocklots) ? (
      <TableCell>
        {' '}
        <div className="text-center">
          <b className="text-red-500"> สินค้าหมด</b>
        </div>
      </TableCell>
    ) : (
      <TableCell>
        <div className="w-48">
          <Controller
            name={`select_lot.[${index}].lotId`}
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                <InputLabel id="type" size={'small'}>
                  ล็อตวัตถุดิบ
                </InputLabel>
                <Select
                  {...field}
                  label="ล็อตวัตถุดิบ"
                  size={'small'}
                  // fullWidth
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    calulateAmount(index);
                    setReload(!reload);
                  }}
                >
                  {showLot(getValues(`select_lot.[${index}].material`))}
                </Select>
              </FormControl>
            )}
          />{' '}
        </div>
      </TableCell>
    );
  };

  const showSelectQuantity = (materialId, index) => {
    const findLot = _.find(material, function (_material) {
      return _material._id === materialId;
    });
    return _.isEmpty(findLot?.stocklots) ? (
      <TableCell>
        {' '}
        <div className="text-center">
          <b className="text-red-500"> 0</b>
        </div>
      </TableCell>
    ) : (
      <TableCell>
        <ProdOrMatQuantityInput
          controllerName={`select_lot.[${index}].quantity`}
          artificialControllerName={`select_lot.[${index}].quantity_aritfic`}
          index={index}
          reload={reload}
          setReload={setReload}
          selectedUnitControllerName={`select_lot.[${index}].selected_unit`}
          setValue={setValue}
          watch={watch}
          control={control}
          selectedProduct={findLot}
          onChangeFunction={() => {
            calulateAmount(index);
          }}
        />
        {/* <Controller
          name={`select_lot.[${index}].quantity`}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              onChange={(e) => {
                field.onChange(e.target.value);
                calulateAmount(index);
                setReload(!reload);
              }}
              label="จำนวน"
              fullWidth
              inputProps={{
                min: 0,
                style: { textAlign: 'center' },
              }}
              size={'small'}
              helperText={errors.quantity && 'กรุณาใส่ข้อมูล'}
            />
          )}
        /> */}
      </TableCell>
    );
  };

  const showDeleteButton = (materialId, index) => {
    const findLot = _.find(material, function (_material) {
      return _material._id === materialId;
    });
    return _.isEmpty(findLot?.stocklots) ? (
      <TableCell>
        {' '}
        <div className="flex">
          <div className="ml-2 mt-1">
            {showAddButton(getValues(`select_lot.[${index}].material`), index)}
          </div>
          <div>
            <Button color={'error'} variant="contained" size={'small'} disabled>
              ลบ
            </Button>
          </div>
        </div>
      </TableCell>
    ) : (
      <TableCell>
        <div className="flex">
          <div className="mr-2">
            {showAddButton(getValues(`select_lot.[${index}].material`), index)}
          </div>
          <div>
            <Button
              color={'error'}
              variant="contained"
              size={'small'}
              onClick={() => remove(index)}
              disabled={
                _.filter(getValues('select_lot'), {
                  material: getValues(`select_lot.[${index}].material`),
                }).length === 1
              }
            >
              ลบ
            </Button>
          </div>
        </div>
      </TableCell>
    );
  };

  const showMaterial = (index) => {
    const findFromMaterial = _.find(material, function (_material) {
      return _material?._id === getValues(`select_lot.[${index}].material`);
    });

    const findFromMaterialWithdraw = _.find(
      materialWithdraw?.material,
      function (_material) {
        return (
          _material?.material?._id ===
          getValues(`select_lot.[${index}].material`)
        );
      },
    );
    return (
      <p>
        {findFromMaterial?.name}{' '}
        <MultiUnitProdOrMatBox
          noWrap
          foundMaterial={findFromMaterial}
          quantity={findFromMaterialWithdraw?.quantity}
        />
      </p>
    );
  };

  const displaymaterial = () => (
    <TableContainer>
      <Table size="small" className="bmaterialWithdraw-lg">
        <TableHead className="bg-blue-100">
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> วัตถุดิบ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ล็อตวัตถุดิบ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> จำนวน</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ดำเนินการ</div>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {fields.map((_field, index) => (
            <TableRow key={_field._id}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>
                <div className="flex flex-col">
                  <div className="my-1">{showMaterial(index)}</div>
                </div>
              </TableCell>
              {showSelectLot(
                getValues(`select_lot.[${index}].material`),
                index,
              )}
              {showSelectQuantity(
                getValues(`select_lot.[${index}].material`),
                index,
              )}
              {showDeleteButton(
                getValues(`select_lot.[${index}].material`),
                index,
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <div>
      <div className="my-2">{displaymaterial()}</div>
    </div>
  );
}

ApprovalMaterialWithdrawForm.propTypes = {
  material: PropTypes.object,
  setSelectStockLot: PropTypes.func,
  materialWithdraw: PropTypes.object,
  control: PropTypes.object,
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
};

ApprovalMaterialWithdrawForm.defaultProps = {
  material: null,
  materialWithdraw: null,
};

export default ApprovalMaterialWithdrawForm;
