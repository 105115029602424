import {
  BASE_PROJECT_ALL,
  BASE_PROJECT_DELETE,
  BASE_PROJECT_ERROR,
  BASE_PROJECT_GET,
  BASE_PROJECT_LOADING,
  BASE_PROJECT_POST,
  BASE_PROJECT_PUT,
} from '../../actions/types';

const initialState = {
  baseProject: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case BASE_PROJECT_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case BASE_PROJECT_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case BASE_PROJECT_POST:
      return { isLoading: false, isCompleted: true };
    case BASE_PROJECT_PUT:
      return { isLoading: false, isCompleted: true };
    case BASE_PROJECT_DELETE:
      return { isLoading: false, isCompleted: true };
    case BASE_PROJECT_LOADING:
      return { isLoading: true, isCompleted: true };
    case BASE_PROJECT_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
