import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Card, CardContent, TextField } from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';

import { config } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';

function MaterialManufacturingPlanning() {
  const material = useSelector((state) => state.material);
  const processTemplate = useSelector((state) => state.processTemplate);
  const bomTemplate = useSelector((state) => state.bomTemplate);
  const [selectedMaterial, setSelectedMaterial] = useState();
  const dispatch = useDispatch();
  const query = gql`
    query findProcessTemplates(
      $processTemplateInput: ProcessTemplateInput
      $bomTemplateInput: BomTemplateInput
      $materialInput: MaterialInput
    ) {
      findProcessTemplates(input: $processTemplateInput) {
        rows {
          _id
          name
          steps {
            name
            materials {
              material {
                _id
                name
                type_code
              }
            }
          }
        }
      }

      findBomTemplates(input: $bomTemplateInput) {
        total
        rows {
          name
          bill_of_materials {
            amount
            material {
              _id
              name
              type_code
              use_unit_conversion
              unit
              conversion_from {
                unit_a
                unit_b
                conversion_factor
              }
              conversion_to {
                unit_b
                unit_a
                conversion_factor
              }
              unit_input {
                _id
                short_sign
                name
              }
              unit_output {
                _id
                short_sign
                name
              }
            }
          }
        }
      }

      findMaterials(input: $materialInput) {
        total
        rows {
          _id
          name
          type_code
          use_unit_conversion
          unit
          conversion_from {
            unit_a
            unit_b
            conversion_factor
          }
          conversion_to {
            unit_b
            unit_a
            conversion_factor
          }
          unit_input {
            _id
            short_sign
            name
          }
          unit_output {
            _id
            short_sign
            name
          }
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.materialReset());
      const queryResult = await graphqlClient.request(query, {
        processTemplateInput: { page: 1, size: config.maxFetchSize },

        bomTemplateInput: {
          page: 1,
          size: config.maxFetchSize,
        },
        materialInput: {
          page: 1,
          size: config.maxFetchSize,
          fetchStockLot: false,
          name: '',
        },
      });
      const foundedProcessTemplate = queryResult?.findProcessTemplates;
      const foundedMaterial = queryResult?.findMaterials;
      const foundedBomTemplate = queryResult?.findBomTemplates;
      dispatch(actions.processTemplateStateSet(foundedProcessTemplate));
      dispatch(actions.bomTemplateStateSet(foundedBomTemplate));
      dispatch(actions.materialStateSet(foundedMaterial));
    } catch (error) {
      console.error('Error On Fetching', error);
    }
  };

  useEffect(() => {
    queryDataFromServer();

    return () => {};
  }, []);

  if (
    material?.isLoading ||
    processTemplate?.isLoading ||
    bomTemplate?.isLoading
  ) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Card>
        <CardContent>
          <h3>วัตถุดิบที่ต้องการผลิต</h3>
          {!_.isEmpty(material?.rows) && (
            <Autocomplete
              disablePortal
              options={material?.rows}
              size="small"
              placeholder="เลือกวัตถุดิบ"
              onChange={(e, newValue) => {
                setSelectedMaterial(newValue);
              }}
              className="z-40"
              // prettier-ignore
              getOptionLabel={(option) => `${option?.type_code || ''} ${option?.name || ''}`
          }
              renderInput={(params) => (
                <TextField label="เลือกวัตถุดิบ" {...params} />
              )}
            />
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default MaterialManufacturingPlanning;
