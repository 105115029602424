import {
  FEEDBACK_ALL,
  FEEDBACK_DELETE,
  FEEDBACK_ERROR,
  FEEDBACK_GET,
  FEEDBACK_LOADING,
  FEEDBACK_POST,
  FEEDBACK_PUT,
} from '../../actions/types';

const initialState = {
  feedback: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case FEEDBACK_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case FEEDBACK_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case FEEDBACK_POST:
      return { isLoading: false, isCompleted: true };
    case FEEDBACK_PUT:
      return { isLoading: false, isCompleted: true };
    case FEEDBACK_DELETE:
      return { isLoading: false, isCompleted: true };
    case FEEDBACK_LOADING:
      return { isLoading: true, isCompleted: true };
    case FEEDBACK_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
