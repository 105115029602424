import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Card,
  InputAdornment,
  Backdrop,
  Modal,
  Fade,
} from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { gql } from 'graphql-request';
import { Link } from 'react-router-dom';

import { ViewTitle } from '@iarcpsu/emanufac-components/src/components/ViewTitle';
import * as actions from '../../redux/actions';
import { app as appConfig } from '../../configs';
import { OrganizationConstantForm } from '@iarcpsu/emanufac-components/src/components/Forms';
import Loading from '@iarcpsu/emanufac-components/src/components/Loading';
import { Error } from '@iarcpsu/emanufac-components/src/components/Error';
import { Modal as ModalStyle } from '../../utils/inlineStyle';

export default function OrganizationConstant({ title, subtitle }) {
  const dispatch = useDispatch();
  const organizationConstant = useSelector(
    (state) => state.organizationConstant,
  );
  const unit = useSelector((state) => state.unit);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  const getAllOrganizationConstant = async () => {
    try {
      const query = gql`
      query FindOrganizationConstant {
        findOrganizationConstants(input:{page:${page},size:${size}, ${
        name ? `name: "${name}"` : ''
      }}){
          currPage,
          lastPage,
          total,
          rows {
          _id
          name
          type_code
          value
          unit {
            name
          }
          }
        }
      } 
    `;
      dispatch(actions.organizationConstantAllQuery({ query }));
    } catch (error) {
      console.log('Organization constant All Errors', error);
    }
  };

  const getAllUnit = async () => {
    try {
      const query = gql`
      query FindUnits {
        findUnits(input:{page:1,size:${appConfig.maxFetchSize}, ${
        name ? `name: "${name}"` : ''
      }}){
          currPage,
          lastPage,
          total,
          rows {
          _id
          name
          }
        }
      } 
    `;
      dispatch(actions.unitAllQuery({ query }));
    } catch (error) {
      console.error('Get All Units Error', error);
    }
  };

  useEffect(() => {
    getAllOrganizationConstant();
    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    setTotal(organizationConstant?.total);
    return () => {};
  }, [organizationConstant]);

  useEffect(() => {
    getAllUnit();
    return () => {};
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleDelete = async (id) => {
    try {
      const confirm = window.confirm('ยืนยันการลบหน่วย');
      if (confirm) {
        await dispatch(actions.organizationConstantDelete(id));
      }
      getAllOrganizationConstant();
    } catch (error) {
      alert('ลบหน่วยไม่สำเร็จ');
      console.error('Cannot remove conversion', error);
    }
  };

  const onSubmit = async (data) => {
    try {
      await dispatch(actions.organizationConstantCreate(data));
      getAllOrganizationConstant();
      setIsModalOpen(false);
      reset();
    } catch (error) {
      window.alert('เพิ่มเติมค่าคงที่ไม่สำเร็จ');
      console.error(error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isModalOpen}>
        <Card sx={ModalStyle} className="max-w-4xl">
          <div className="py-2">เพิ่มข้อมูล</div>
          <OrganizationConstantForm
            units={unit}
            control={control}
            errors={errors}
          />
          <div className="py-2">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setIsModalOpen(false)}
                >
                  ยกเลิก
                </Button>
              </div>
            </form>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4">
      <div>
        <Button variant="contained" onClick={() => setIsModalOpen(true)}>
          เพิ่ม
        </Button>
      </div>
    </div>
  );

  const renderSearch = () => (
    <Card className="my-2">
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <Paper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ลำดับที่</TableCell>
              <TableCell>รหัสค่า</TableCell>
              <TableCell>ชื่อ</TableCell>
              <TableCell>ค่าคงที่/ค่าคาดหวัง</TableCell>
              <TableCell>หน่วย</TableCell>
              <TableCell>ดำเนินการ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(organizationConstant?.rows, (_orgConstant, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{_orgConstant?.type_code} </TableCell>
                <TableCell>{_orgConstant?.name}</TableCell>
                <TableCell>{_orgConstant?.value}</TableCell>
                <TableCell>{_orgConstant?.unit?.name}</TableCell>
                <TableCell>
                  <div className="flex gap-2">
                    <Link
                      to={`/km/organization-constant/edit/${_orgConstant?._id}`}
                    >
                      <Button size="small" variant="contained" color="warning">
                        แก้ไข
                      </Button>
                    </Link>
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={() => handleDelete(_orgConstant?._id)}
                    >
                      ลบ
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
            {_.isEmpty(organizationConstant?.rows) && (
              <TableRow>
                <TableCell colSpan={5}>
                  <div className="text-center">ไม่มีข้อมูล</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        page={page - 1}
        //  rowsPerPageOptions={[]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        count={total || 1}
        rowsPerPage={size}
        onPageChange={handleChangePage}
      />
    </Paper>
  );

  if (organizationConstant.isLoading) {
    return <Loading />;
  }
  if (!organizationConstant.isLoading && organizationConstant.isCompleted) {
    return (
      <div>
        <div className="flex flex-wrap justify-between">
          {renderTitle()}
          {renderAddButtom()}
        </div>
        {renderSearch()}
        {renderTable()}
        {renderModal()}
      </div>
    );
  }
  return <Error />;
}

OrganizationConstant.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

OrganizationConstant.defaultProps = {
  title: '',
  subtitle: '',
};
