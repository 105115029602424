const currencyFormatter = new Intl.NumberFormat('th', {
  style: 'decimal',
  currency: 'THB',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const customNumberFormat = (value, floatingPoint = 2) => {
  const formatter = new Intl.NumberFormat('th', {
    style: 'decimal',
    currency: 'THB',
    maximumFractionDigits: floatingPoint,
    minimumFractionDigits: floatingPoint,
  });
  return formatter.format(value);
};

export default currencyFormatter;
