import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { gql } from 'graphql-request';
import { Link, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  Chip,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import {
  List as ListIcon,
  PieChart,
  Check as CheckIcon,
  Trash,
  X as XIcon,
  RefreshCw,
  Edit as EditIcon,
} from 'react-feather';
import _ from 'lodash';

import {
  ViewTitle,
  Loading,
  Error,
  BackButton,
  InfoManufacturingOrder,
  MultiUnitProdOrMatBox,
  MaterialBomCompareBox,
  BomCostBox,
  MOMaterialBomTable,
} from '@iarcpsu/emanufac-components/src/components';
import * as actions from '../../../redux/actions';
import graphqlClient from '../../../configs/graphqlClient';
import { app as appConfig } from '../../../configs';
import { MMS as MMSMiddleWare } from '../../../utils/middleware';

/**
 * @function ManufacturingOrderBom
 * @description Display a collections or a list of ManufacturingOrderBom from database
 */

export default function ManufacturingOrderBom({ title, subtitle }) {
  const dispatch = useDispatch();
  const bomChecking = useSelector((state) => state.bomChecking);
  const me = useSelector((state) => state.me);
  const information = useSelector((state) => state.information);
  const manufacturingOrder = useSelector((state) => state.manufacturingOrder);
  const customer = useSelector((state) => state.customer);
  const paymentType = useSelector((state) => state.paymentType);
  const params = useParams();
  const [selectedDisplayType, setSelectedDisplayType] = useState('chart');

  const query = gql`
    query FindManufacturingOrderBom(
      $bomCheckingInput: BomCheckingInput
      $manufacturingOrderInput: ManufacturingOrderInput
      $paymentTypeInput: PaymentTypeInput
      $customerInput: CustomerInput
    ) {
      findBomCheckings(input: $bomCheckingInput) {
        currPage
        lastPage
        total
        rows {
          _id
          amount
          product {
            _id
            name
            type_code
            use_unit_conversion
            unit
            conversion_from {
              unit_a
              unit_b
              conversion_factor
            }
            conversion_to {
              unit_b
              unit_a
              conversion_factor
            }
            unit_input {
              _id
              short_sign
              name
            }
            unit_output {
              _id
              short_sign
              name
            }
          }
          amount
          process {
            _id
            completed
            deleted
          }
        }
        analyzed {
          material {
            _id
            name
            type_code
            use_unit_conversion
            unit
            conversion_from {
              unit_a
              unit_b
              conversion_factor
            }
            conversion_to {
              unit_b
              unit_a
              conversion_factor
            }
            unit_input {
              _id
              short_sign
              name
            }
            unit_output {
              _id
              short_sign
              name
            }
          }
          totalPrefer
          totalActual
          totalDeposit
          difference
          overDepositToActual
          lessDepositToActual
          formularCost
          depositCost
          actualCost
        }
      }
      findOneManufacturingOrder(input: $manufacturingOrderInput) {
        _id
        running_number
        prefix
        start_date
        expected_date
        completed
        deleted
        remark
        delete_note
        customer {
          type_code
          name
        }
        assigner {
          firstname
          lastname
          image {
            url
          }
        }
      }
      findPaymentTypes(input: $paymentTypeInput) {
        rows {
          _id
          name
        }
      }
      findCustomers(input: $customerInput) {
        rows {
          _id
          type_code
          name
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        bomCheckingInput: {
          page: 1,
          size: appConfig.maxFetchSize,
          manufacturing_order: params.id,
        },
        manufacturingOrderInput: {
          enableFetchInside: false,
          fetchCurrentStep: false,
          fetchCustomer: false,
          fetchMaterial: false,
          fetchProduct: true,
          id: params.id,
          fetchProcess: false,
        },
        paymentTypeInput: {
          page: 1,
          size: appConfig.maxFetchSize,
        },
        customerInput: {
          page: 1,
          size: appConfig.maxFetchSize,
        },
      });
      const bomCheckingData = queryResult?.findBomCheckings;
      const manufacturingOrderData = queryResult?.findOneManufacturingOrder;
      const paymentTypeData = queryResult?.findPaymentTypes;
      const customerData = queryResult?.findCustomers;

      dispatch(actions.manufacturingOrderStateOneSet(manufacturingOrderData));
      dispatch(actions.bomCheckingStateSet(bomCheckingData));
      dispatch(actions.paymentTypeStateSet(paymentTypeData));
      dispatch(actions.customerStateSet(customerData));
    } catch (error) {
      console.error('Fetching Error', error);
      dispatch(actions.bomCheckingError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const handleMOToSuccess = async ({
    manufacturingOrderId,
    handleSuccess = () => {},
  }) => {
    try {
      await dispatch(
        actions.manufacturingOrderPut(manufacturingOrderId, {
          completed: true,
        }),
      );
      handleSuccess();
      queryDataFromServer();
    } catch (error) {
      window.alert(`ไม่สามารถปิดคำสั่งผลิตได้ ${error?.message}`);
      console.error('Handle Manufacturing Order Success Fail', error);
    }
  };

  const handleMOToCancle = async ({
    manufacturingOrderId,
    handleSuccess = () => {},
  }) => {
    try {
      await dispatch(
        actions.manufacturingOrderPut(manufacturingOrderId, { deleted: true }),
      );
      handleSuccess();
      queryDataFromServer();
    } catch (error) {
      window.alert(`ไม่สามารถยกเลิกคำสั่งผลิตได้ ${error?.message}`);
      console.error('Handle Manufacturing Order Cancle Fail', error);
    }
  };

  const handleSuccess = async (processId) => {
    try {
      const confirm = window.confirm('ยีนยันการเปลี่ยนสถานะ');
      if (confirm) {
        await dispatch(actions.processPut(processId, { completed: true }));
        alert('สำเร็จ');
        queryDataFromServer();
      }
    } catch (err) {
      alert(`การเปลี่ยนสถานะไม่สำเร็จ ${err?.message}`);
    }
  };

  const handleDelete = async (processId) => {
    try {
      const confirm = window.confirm('ยีนยันการเปลี่ยนยกเลิกไลน์การผลิต');
      if (confirm) {
        await dispatch(actions.processPut(processId, { deleted: true }));
        alert('สำเร็จ');
        queryDataFromServer();
      }
    } catch (err) {
      alert(`การเปลี่ยนสถานะไม่สำเร็จ ${err?.message}`);
    }
  };

  const handleBackToNormalStatus = async (processId) => {
    try {
      const confirm = window.confirm('ยีนยันการเปลี่ยนสถานะไลน์การผลิต');
      if (confirm) {
        await dispatch(
          actions.processPut(processId, { deleted: false, completed: false }),
        );
        alert('สำเร็จ');
        queryDataFromServer();
      }
    } catch (err) {
      alert(`การเปลี่ยนสถานะไม่สำเร็จ ${err?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderManufacturingOrderInfo = () => (
    <div className="my-2">
      <InfoManufacturingOrder
        information={information}
        manufacturingOrder={manufacturingOrder}
        me={me}
        handleMOToCancle={handleMOToCancle}
        handleMOToSuccess={handleMOToSuccess}
      />
    </div>
  );

  const renderEachBomCheckingElement = (bomCheckingElement, index) => (
    <div key={index} className="w-full lg:w-1/2 p-1">
      <Card>
        <CardContent>
          <div className="font-semibold font-display">
            {bomCheckingElement?.product?.type_code || ''}{' '}
            {bomCheckingElement?.product?.name}{' '}
            {bomCheckingElement?.process?.completed && (
              <Chip
                color="success"
                icon={<CheckIcon size="16" />}
                label="สำเร็จ"
                size="small"
                className="mx-2"
              />
            )}
            {bomCheckingElement?.process?.deleted && (
              <Chip
                color="error"
                icon={<XIcon size="16" />}
                label="ยกเลิก"
                size="small"
                className="mx-2"
              />
            )}
          </div>
          <div className="flex gap-2">
            จำนวน
            <MultiUnitProdOrMatBox
              foundMaterial={bomCheckingElement?.product}
              noWrap
              quantity={bomCheckingElement?.amount}
            />{' '}
          </div>
          <div className="my-2 flex gap-2">
            <Link
              variant="contained"
              size="small"
              to={`/mms/process/bom-checking/${bomCheckingElement?._id}`}
              component={Button}
              startIcon={<EditIcon size="16" />}
              disabled={
                bomCheckingElement?.process?.completed ||
                bomCheckingElement?.process?.deleted
              }
            >
              ใส่รายละเอียดส่วนผสม{' '}
            </Link>
            <Button
              variant="contained"
              size="small"
              color="success"
              startIcon={<CheckIcon size="16" />}
              onClick={() => handleSuccess(bomCheckingElement?.process?._id)}
              disabled={
                bomCheckingElement?.process?.completed ||
                bomCheckingElement?.process?.deleted
              }
            >
              ทำเครื่องหมายว่าสำเร็จ
            </Button>

            <Button
              variant="contained"
              size="small"
              color="error"
              onClick={() => handleDelete(bomCheckingElement?.process?._id)}
              startIcon={<Trash size="16" />}
              disabled={
                bomCheckingElement?.process?.completed ||
                bomCheckingElement?.process?.deleted
              }
            >
              ยกเลิก
            </Button>
            {(bomCheckingElement?.process?.completed ||
              bomCheckingElement?.process?.deleted) && (
              <Button
                variant="contained"
                size="small"
                color="warning"
                onClick={() =>
                  handleBackToNormalStatus(bomCheckingElement?.process?._id)
                }
                startIcon={<RefreshCw size="16" />}
              >
                กลับมาใช้งาน
              </Button>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );

  const renderChartView = () => (
    <div>
      <div className="my-2">
        <div className="my-4 font-semibold font-display">
          การใชวัตถุดิบจากส่วนผสม
        </div>
        <div className="flex flex-wrap">
          {_.map(bomChecking?.analyzed, (eachAnalyze, index) => (
            <div key={index} className="w-full lg:w-1/3 px-1 py-1 ">
              <MaterialBomCompareBox eachAnalyze={eachAnalyze} />
            </div>
          ))}
        </div>
      </div>
      <div className="my-2">
        <div className="my-4 font-semibold font-display">ต้นทุนการผลิต</div>
        <BomCostBox allAnalyzed={bomChecking?.analyzed} />
      </div>
    </div>
  );

  const renderMaterialBomTable = () => (
    <div>
      <MOMaterialBomTable allAnalyzed={bomChecking?.analyzed} />
    </div>
  );

  if (bomChecking.isLoading) {
    return <Loading />;
  }

  if (!bomChecking.isLoading && bomChecking.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <BackButton />
        </div>
        {renderManufacturingOrderInfo()}
        <div className="my-2">
          <div className="my-4 font-semibold font-display">
            รายการสินค้าที่มีวัตถุดิบส่วนผสม
          </div>
          <div className="my-2 flex flex-wrap">
            {_.map(bomChecking?.rows, (eachBomCheckingElement, index) =>
              renderEachBomCheckingElement(eachBomCheckingElement, index),
            )}
          </div>

          {_.isEmpty(bomChecking?.rows) && (
            <div className="w-full text-center">
              ไม่มีรายละเอียดวัตถุดิบส่วนผสม
            </div>
          )}
        </div>{' '}
        <Card>
          <CardContent>
            <div className="flex justify-between">
              <div className="my-1 font-semibold text-lg font-display">
                รายละเอียดการใช้วัตถุดิบ
              </div>
              <div>
                <ToggleButtonGroup
                  size="small"
                  value={selectedDisplayType}
                  onChange={(event, newValue) => {
                    setSelectedDisplayType(newValue);
                  }}
                  exclusive={true}
                >
                  <ToggleButton value="chart" key="chart">
                    <PieChart />
                  </ToggleButton>
                  <ToggleButton value="table" key="table">
                    <ListIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
          </CardContent>
        </Card>
        {selectedDisplayType === 'chart' && renderChartView()}
        {selectedDisplayType === 'table' && renderMaterialBomTable()}
      </div>
    );
  }
  return <Error />;
}

ManufacturingOrderBom.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ManufacturingOrderBom.defaultProps = {
  title: '',
  subtitle: '',
};
