import _ from 'lodash';

import currencyDecimalFormatter from './currencyDecimalFormatter';
import currencyFormatter from './currencyFormatterTH';

const notNan = (value) => {
  if (typeof value === 'number' && _.isNaN(value)) {
    return '-';
  }

  if (
    value === null ||
    value === undefined ||
    value === 'NaN' ||
    value === ''
  ) {
    return '-';
  }
  return value % 1 === 0
    ? currencyDecimalFormatter.format(value)
    : currencyFormatter.format(value);
};

export default notNan;
