import React from 'react';
import Chart from 'react-apexcharts';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';

import { Error } from '../Error';

export default function ProcessYieldPieChart({
  processMaterial,
  latestStepMaterial,
}) {
  console.log('Processed Material', processMaterial);
  console.log('Latest Step Material', latestStepMaterial);
  const series = [
    parseFloat(latestStepMaterial?.end),
    parseFloat(latestStepMaterial?.wastes),
    parseFloat(processMaterial?.begin) -
      -parseFloat(latestStepMaterial?.wastes) -
      parseFloat(latestStepMaterial?.end),
  ];
  const labels = ['ผลผลิตที่ได้', 'ของเสีย', 'คืนของ'];
  const options = {
    labels,
    colors: ['#2541B2', '#FF6347', '#008B8B'],
    yaxis: {
      labels: {
        formatter: (value) => parseFloat(value).toFixed(2),
      },
    },
  };

  const ErrorFallback = ({ error }) => <Error message={error?.message} />;

  return (
    <div className="my-2 justify-center">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Chart type="pie" series={series} options={options} />
      </ErrorBoundary>
    </div>
  );
}

ProcessYieldPieChart.propTypes = {
  latestStepMaterial: PropTypes.shape({
    material: PropTypes.object,
    yield: PropTypes.number,
    wastes: PropTypes.number,
    begin: PropTypes.number,
    end: PropTypes.number,
    return: PropTypes.number,
  }),
  processMaterial: PropTypes.shape({
    material: PropTypes.object,
    yield: PropTypes.number,
    wastes: PropTypes.number,
    begin: PropTypes.number,
    end: PropTypes.number,
    return: PropTypes.number,
  }),
  error: PropTypes.object,
};
