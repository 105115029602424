import {
  MATERIAL_WITHDRAW_ALL,
  MATERIAL_WITHDRAW_GET,
  MATERIAL_WITHDRAW_DEL,
  MATERIAL_WITHDRAW_PUT,
  MATERIAL_WITHDRAW_POST,
  MATERIAL_WITHDRAW_LOADING,
  MATERIAL_WITHDRAW_ERROR,
} from '../types';

import api from '../../../configs/api';

export const materialWithdrawCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_WITHDRAW_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/material-withdraw`,
      {
        ...payload,
      },
    );
    dispatch({ type: MATERIAL_WITHDRAW_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_WITHDRAW_ERROR });
    throw new Error(error);
  }
};

export const materialWithdrawAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1, statusCode = '' } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/material-withdraw?name=${name}&size=${size}&page=${page}&statusCode=${statusCode}`,
    );
    if (status === 200) {
      dispatch({ type: MATERIAL_WITHDRAW_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_WITHDRAW_ERROR });
    throw new Error(error);
  }
};

export const materialWithdrawGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/material-withdraw/${id}`,
    );
    if (status === 200) {
      dispatch({ type: MATERIAL_WITHDRAW_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_WITHDRAW_ERROR });
    throw new Error(error);
  }
};

export const materialWithdrawStateSet = (data) => async (dispatch) => {
  dispatch({ type: MATERIAL_WITHDRAW_ALL, payload: data });
};

export const materialWithdrawPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_WITHDRAW_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/material-withdraw/${id}`,
      payload,
    );
    dispatch({ type: MATERIAL_WITHDRAW_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_WITHDRAW_ERROR });
    throw new Error(error);
  }
};
export const materialWithdrawDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_WITHDRAW_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/material-withdraw/${id}`,
    );
    dispatch({ type: MATERIAL_WITHDRAW_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_WITHDRAW_ERROR });
    throw new Error(error);
  }
};
