import React from 'react';
import PropTypes from 'prop-types';

import { DATA_TYPE } from '@/utils/constants';

import {
  BooleanInput,
  CheckAndDescribeInput,
  DateInput,
  DatetimeInput,
  NumberInput,
  ParagraphInput,
  PersonInput,
  RadioAndDescribeInput,
  StringInput,
  SwitchInput,
  TimeInput,
} from './MeasurementAdding';

export default function MeasurementAddingRenderingCompanent({
  control,
  measurementType,
  measurementControllerName = '',
  watch,
  defaultValue,
}) {
  const props = {
    control,
    measurementType,
    measurementControllerName,
    watch,
    defaultValue,
  };
  switch (measurementType?.data_type) {
    case DATA_TYPE.BOOLEAN.type_code:
      return <BooleanInput {...props} />;
    case DATA_TYPE.CHECK_AND_DESCRIBE.type_code:
      return <CheckAndDescribeInput {...props} />;
    case DATA_TYPE.DATE.type_code:
      return <DateInput {...props} />;
    case DATA_TYPE.DATETIME.type_code:
      return <DatetimeInput {...props} />;
    case DATA_TYPE.NUMBER.type_code:
      return <NumberInput {...props} />;
    case DATA_TYPE.PARAGRAPH.type_code:
      return <ParagraphInput {...props} />;
    case DATA_TYPE.RADIO_AND_DESCRIBE.type_code:
      return <RadioAndDescribeInput {...props} />;
    case DATA_TYPE.STRING.type_code:
      return <StringInput {...props} />;
    case DATA_TYPE.SWITCH.type_code:
      return <SwitchInput {...props} />;
    case DATA_TYPE.TIME.type_code:
      return <TimeInput {...props} />;
    case DATA_TYPE.PERSON.type_code:
      return <PersonInput {...props} />;
    default:
      return <StringInput {...props} />;
  }
}

MeasurementAddingRenderingCompanent.propTypes = {
  control: PropTypes.object,
  measurementType: PropTypes.object,
  measurementControllerName: PropTypes.string,
  watch: PropTypes.func,
  defaultValue: PropTypes.any,
};
