/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
import { MANUFAC_ORDER_STATUS } from '@iarcpsu/emanufac-constant';
import dayjs from 'dayjs';
import _ from 'lodash';

const ManufacturingOrderWithProcessColumn = (information) => {
  const mmsSetting = information?.setting?.mms;
  const columns = [];

  const enableWhenColPrimary = (col, result) => {
    if (col?.isPrimary) {
      return result;
    }
    return '';
  };

  columns.push({
    label: 'ลำดับที่',
    value: 'index',
  });

  columns.push({
    label: 'หมายเลขใบสั่งผลิต',
    value: (col) =>
      enableWhenColPrimary(col, `${col?.prefix}${col?.running_number}`),
  });

  columns.push({
    label: 'สถานะ',
    value: (col) =>
      enableWhenColPrimary(col, MANUFAC_ORDER_STATUS[col?.status]?.description),
  });

  columns.push({
    label: 'วันที่สั่งผลิต',
    value: (col) =>
      enableWhenColPrimary(col, dayjs(col?.start_date).format('D/MM/BBBB')),
  });

  if (mmsSetting?.usingAsDefault?.showExpectedDate) {
    columns.push({
      label: 'วันที่ต้องการสินค้า',
      value: (col) =>
        enableWhenColPrimary(
          col,
          dayjs(col?.expected_date).format('D/MM/BBBB'),
        ),
    });
  }

  columns.push({
    label: 'รหัสสินค้า',
    value: (col) => {
      if (col?.process_produce_material_instead) {
        return col?.process_product_as_material?.type_code || '-';
      }
      if (col?.process_produce_base_project_instead) {
        return '-';
      }
      return col?.process_product?.type_code || '-';
    },
  });

  columns.push({
    label: 'ชื่อสินค้า',
    value: (col) => {
      if (col?.process_produce_material_instead) {
        return col?.process_product_as_material?.name || '-';
      }
      if (col?.process_produce_base_project_instead) {
        return col?.process_base_project?.name || '-';
      }
      return col?.process_product?.name || '-';
    },
  });

  columns.push({
    label: 'จำนวน',
    value: (col) => col?.process_quantity,
  });

  if (mmsSetting?.manufacturingOrder?.showPrice) {
    columns.push({
      label: 'ราคาต่อหน่วย',
      value: (col) => col?.process_price_per_unit || '-',
    });
  }

  if (mmsSetting?.manufacturingOrder?.showPrice) {
    columns.push({
      label: 'มูลค่า',
      value: (col) =>
        (col?.process_price_per_unit || 0) * (col?.process_quantity || 0),
    });
  }

  columns.push({
    label: 'สถานะ',
    value: (col) => {
      if (col?.process_completed) {
        return 'เสร็จสิ้น';
      }
      if (col?.process_deleted) {
        return 'ยกเลิก';
      }
      return 'กำลังดำเนินการ';
    },
  });

  if (mmsSetting?.manufacturingOrder?.showPrice) {
    columns.push({
      label: 'รวม',
      value: (col) => {
        const totalPriceList = _.map(col?.old_process, (eachProcess) => {
          if (eachProcess?.deleted) {
            return 0;
          }

          return (
            (eachProcess?.price_per_unit || 0) * (eachProcess?.quantity || 0)
          );
        });

        const totalPrice = _.sum(totalPriceList);

        return enableWhenColPrimary(col, totalPrice);
      },
    });
  }

  if (mmsSetting?.manufacturingOrder?.showQuotation) {
    columns.push({
      label: 'หมายเลขใบเสนอราคา',
      value: (col) =>
        enableWhenColPrimary(
          col,
          col?.quotation
            ? `${col?.quotation?.prefix || ''}${
                col?.quotation?.running_number || '-'
              }`
            : col?.quotation_number || '-',
        ),
    });
  }

  if (mmsSetting?.manufacturingOrder?.showPaymentMethod) {
    columns.push({
      label: 'ช่องทางการชำระเงิน',
      value: (col) => enableWhenColPrimary(col?.payment_method?.name || '-'),
    });
  }

  if (mmsSetting?.manufacturingOrder?.showPO) {
    columns.push({
      label: 'หมายเลข PO',
      value: (col) => enableWhenColPrimary(col, col?.po_number || '-'),
    });
  }
  columns.push({
    label: 'รหัสลูกค้า',
    value: (col) => enableWhenColPrimary(col, col?.customer?.type_code || '-'),
  });

  columns.push({
    label: 'ลูกค้า',
    value: (col) => enableWhenColPrimary(col, col?.customer?.name || '-'),
  });

  columns.push({
    label: 'ผู้สั่งผลิต',
    value: (col) =>
      enableWhenColPrimary(
        col,
        `${col?.assigner?.firstname || '-'} ${col?.assigner?.lastname || ''}`,
      ),
  });
  columns.push({
    label: 'หมายเหตุ',
    value: (col) => enableWhenColPrimary(col, col?.remark || '-'),
  });

  return columns;
};

export default ManufacturingOrderWithProcessColumn;
