/* eslint-disable no-nested-ternary */
import _ from 'lodash';

const Step = (steps) =>
  _.map(steps, (eachStep, stepIndex) => ({
    ...eachStep,
    index: stepIndex,
    // materials: _.map(eachStep?.materials, (eachMaterial) => ({
    //   ...eachMaterial,
    //   material: eachMaterial?.material?._id || eachMaterial?.material,
    // })),
    measurementTypes: _.map(
      eachStep?.measurementTypes,
      (_eachMeasurementType) => _eachMeasurementType?._id,
    ),
  }));

const EachStepMaterial = (step) => ({
  ...step,
  materials: _.map(step?.materials, (_material) => ({
    ..._material,
    _id: undefined,
    material: _material?.material?._id || _material?.material,
  })),
});

const ManufacturingOrder = (manufacOrder) => ({
  ...manufacOrder,
  order:
    manufacOrder?.order !== ''
      ? manufacOrder.attachOrder === true
        ? manufacOrder.order
        : undefined
      : undefined,
  customer:
    manufacOrder?.customer !== ''
      ? manufacOrder.attachCustomer === true
        ? manufacOrder?.createNewCustomer === true
          ? {
              ...manufacOrder?.customer,
              _id: undefined,
              contact: [],
              note: [],
              email: undefined,
              fax: undefined,
              source: undefined,
              address: undefined,
              payment: undefined,
            }
          : manufacOrder?.customer
        : undefined
      : undefined,
  processes: _.map(manufacOrder?.processes, (eachProcess) => ({
    ...eachProcess,
    materials:
      eachProcess?.noMaterial === true ? undefined : eachProcess.materials,
  })),
});

export default { Step, ManufacturingOrder, EachStepMaterial };
