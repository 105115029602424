import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { MANUFACTURING_MODE } from '@iarcpsu/emanufac-constant';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import EachCostEstimationWarehouse from './EachCostEstimationWarehouse';

const CostEstimationProfileForm = ({
  control,
  watch,
  place,
  warehouse,
  defaultValue,
}) => {
  const {
    fields: warehouseFields,
    append: warehouseAppend,
    remove: warehouseRemove,
    move: warehouseMove,
  } = useFieldArray({
    control,
    name: 'warehouses',
  });

  return (
    <div>
      <Card>
        <CardContent>
          <div className="flex flex-wrap">
            <div className="w-full">
              <Controller
                name="name"
                control={control}
                defaultValue={defaultValue?.name}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="ชื่อโปรไฟล์"
                    fullWidth
                    required
                    size="small"
                  />
                )}
              />
            </div>
            <div className="w-full my-1">
              <Controller
                name="manufacturing_mode"
                control={control}
                defaultValue={defaultValue?.manufacturing_mode}
                render={({ field }) => (
                  <FormControl fullWidth size="small">
                    <InputLabel>โหมดการผลิต</InputLabel>
                    <Select {...field} fullWidth size="small">
                      {_.map(MANUFACTURING_MODE, (value, key) => (
                        <MenuItem key={key} value={value.status_code}>
                          {value.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </div>
          </div>
        </CardContent>
      </Card>
      {watch('manufacturing_mode') === MANUFACTURING_MODE.WBM.status_code && (
        <div className="my-2">
          {_.map(warehouseFields, (eachField, index) => (
            <div key={eachField.id}>
              <EachCostEstimationWarehouse
                {...{
                  control,
                  index,
                  eachField,
                  watch,
                  place,
                  warehouse,
                  remove: warehouseRemove,
                  move: warehouseMove,
                }}
              />
            </div>
          ))}
          <div>
            <Button onClick={() => warehouseAppend({})} variant="contained">
              เพิ่ม
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CostEstimationProfileForm;

CostEstimationProfileForm.propTypes = {
  control: PropTypes.object,
  watch: PropTypes.func,
  place: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  warehouse: {
    rows: PropTypes.arrayOf(PropTypes.object),
  },
  defaultValue: PropTypes.object,
};
