import React from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

const MetalWasteForm = ({ control, watch, index = 1, machine }) => (
  <div>
    <div className="">
      <Controller
        name={`divide_sets[${index}].step`}
        control={control}
        render={({ field }) => (
          <input size="small" {...field} value={index} type="hidden" />
        )}
      />
    </div>
    <div className="flex flex-wrap w-full">
      <div className="w-full px-1 py-2 lg:w-1/2 ">
        <Controller
          name={`divide_sets[${index}].lost`}
          control={control}
          render={({ field }) => (
            <TextField size="small" {...field} label="ความยาว" fullWidth />
          )}
        />
      </div>
      <div className="w-full px-1 py-2 lg:w-1/2 ">
        <Controller
          name={`divide_sets[${index}].failure_index`}
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel>ตำแหน่งที่เสีย</InputLabel>
              <Select {...field} size="small" fullWidth>
                {_.map(
                  Array(
                    machine?.conveyor_reference_point?.enable
                      ? parseInt(
                          machine?.conveyor_reference_point?.reference_every,
                          10,
                        )
                      : 0,
                  ),
                  (each, index) => (
                    <MenuItem key={index} value={index + 1}>
                      {index + 1}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full px-1 py-2 ">
        <Controller
          name={`divide_sets[${index}].remark`}
          control={control}
          render={({ field }) => (
            <TextField
              size="small"
              {...field}
              label="หมายเหตุ"
              rows={2}
              fullWidth
              multiline
            />
          )}
        />
      </div>
    </div>
  </div>
);

MetalWasteForm.propTypes = {
  control: PropTypes.object,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  index: PropTypes.number,
  machine: PropTypes.object,
};

export default MetalWasteForm;
