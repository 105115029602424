import React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  Paper,
  TableContainer,
  Typography,
  Skeleton,
  Chip,
} from '@mui/material';

import _ from 'lodash';
import PropTypes from 'prop-types';

import MultiUnitProdOrMatBox from '../../Box/Rendering/MultiUnitProdOrMatBox';

const ProcessWithMeasurementTable = ({
  processData,
  page,
  total,
  size,
  handleChangePage,
  handleChangeRowPerPage,
}) => {
  const renderEachStepMeasurement = (stepData, index) => (
    <div key={index} className="my-2">
      <Typography variant="overline" color="primary">
        {stepData?.name}
      </Typography>
      <div className="text-xs font-semibold font-display"> </div>
      <div className="grid grid-cols-3 md:grid-cols-4 xl:grid-cols-5">
        {_.map(stepData?.measurements, (measurement, measurementIndex) => (
          <div key={`measurement${measurementIndex}`} className="pr-2">
            <div className="text-sm">{measurement?.measurement_type?.name}</div>
            <div className="font-semibold text-lg">
              {measurement?.value || '-'}
            </div>
          </div>
        ))}
      </div>
      {_.isEmpty(stepData?.measurements) && <div className="text-sm">-</div>}
    </div>
  );

  const renderEachStepResultingFunction = (stepData, index) => (
    <div key={index} className="my-2">
      <Typography variant="overline" color="primary">
        {stepData?.name}
      </Typography>
      <div className="text-xs font-semibold font-display"> </div>
      <div className="grid grid-cols-3 md:grid-cols-4 xl:grid-cols-5">
        {_.map(
          stepData?.resultingFunctionTypes,
          (eachFunction, functionIndex) => (
            <div key={`function${functionIndex}`} className="pr-2">
              <div className="text-sm">{eachFunction?.name}</div>
              <div className="font-semibold text-lg">
                {eachFunction?.value || '-'}
              </div>
            </div>
          ),
        )}
      </div>
      {_.isEmpty(stepData?.resultingFunctionTypes) && (
        <div className="text-sm">-</div>
      )}
    </div>
  );

  return (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">คำสั่งผลิต</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">จำนวน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ค่าที่วัดได้</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ผลจากการวัด</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(processData) ? (
                _.map(processData, (row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      <div>
                        MO{row?.manufacturing_order?.running_number || ''}
                      </div>
                      {_.isEmpty(row?.steps) && <Skeleton variant="text" />}
                      {row?.produce_material_instead ? (
                        <div>
                          {row?.product_as_material?.type_code || ''}{' '}
                          {row?.product_as_material?.name}
                        </div>
                      ) : (
                        <div>
                          {row?.product?.type_code || ''} {row?.product?.name}
                        </div>
                      )}

                      <div>ลูกค้า {row?.customer?.name || ''}</div>
                      {row?.completed && (
                        <Chip label="สำเร็จ" color="success" size="small" />
                      )}
                      {row?.deleted && (
                        <Chip label="ยกเลิก" color="error" size="small" />
                      )}
                    </TableCell>
                    <TableCell>
                      {row?.quantity > 0 ? (
                        <MultiUnitProdOrMatBox
                          foundMaterial={
                            row?.produce_material_instead
                              ? row?.product_as_material
                              : row?.product
                          }
                          quantity={row?.quantity}
                        />
                      ) : (
                        'ไม่ระบุจำนวน'
                      )}
                    </TableCell>
                    <TableCell>
                      {_.isEmpty(row?.steps) && <Skeleton variant="text" />}
                      {_.map(row?.steps, (step, stepIndex) =>
                        renderEachStepMeasurement(step, stepIndex),
                      )}
                    </TableCell>
                    <TableCell>
                      {_.isEmpty(row?.steps) && <Skeleton variant="text" />}
                      {_.map(row?.steps, (step, stepIndex) =>
                        renderEachStepResultingFunction(step, stepIndex),
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>{' '}
            <TablePagination
              page={page - 1}
              count={total || 1}
              rowsPerPage={size}
              onPageChange={handleChangePage}
              onChangeRowsPerPage={handleChangeRowPerPage}
            />
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

ProcessWithMeasurementTable.propTypes = {
  processData: PropTypes.arrayOf(PropTypes.object),
  page: PropTypes.number,
  total: PropTypes.number,
  size: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowPerPage: PropTypes.func,
};

export default ProcessWithMeasurementTable;
