const MATERIAL_COMING_METHOD = {
  WITHDRAW: {
    status_code: "WITHDRAW",
    description: "การเบิกวัตถุดิบ",
  },
  RECIEVE: {
    status_code: "RECIEVE",
    description: "การรับเข้า",
  },
  SPLITTING: {
    status_code: "SPLITTING",
    description: "การแยกออกจากวัตถุดิบ",
  },
  MERGING: {
    status_code: "MERGING",
    description: "การรวมกันของวัตถุดิบ",
  },
};

module.exports = MATERIAL_COMING_METHOD;
