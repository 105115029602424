/* eslint-disable function-paren-newline */
import React, { useEffect, useState } from 'react';
import { ChevronDown, ChevronUp, Trash } from 'react-feather';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { pluginList } from '@/plugins';
import { MMS, WMS } from '@/utils/Proptypes';

import ProcessTemplateMaterialForm from './ProcessTemplateMaterialForm';
import ProcessTemplateMeasurementTypeForm from './ProcessTemplateMeasurementTypeForm';
import ProcessTemplateResultingFunctionTypeForm from './ProcessTemplateResultingFunctionForm';

const ProcessTemplateForm = ({
  control,
  errors,
  fields,
  remove,
  product,
  move,
  measurementType,
  watch,
  setValue,
  append = () => {},
  resultingFunctionType,
  material,
}) => {
  const [rerender, setRerender] = useState(false);
  const departments = useSelector((state) => state.department);
  const information = useSelector((state) => state.information);
  const [showAddLastStepDialog, setShowAddLastStepDialog] = useState(false);

  const {
    name: selectedName = '',
    department: selectedDepartment = '',
    measurementTypes: selectedMeasurementType = [],
    materials: selectedMaterials = [],
  } = information?.setting?.mms?.fixedLastStep;
  const lastStepPayload = {
    name: selectedName,
    detail: '',
    department: selectedDepartment,
    attachMaterial: false,
    is_fixed_step: true,
    measurementTypes: selectedMeasurementType,
    materials: selectedMaterials,
  };

  useEffect(() => {
    if (_.isEmpty(product?.steps)) {
      if (information?.setting?.mms?.fixedLastStep?.enable === true) {
        setValue('steps', [lastStepPayload]);
      }
    } else if (product?.steps) {
      console.log('PRODUCT STEP', product?.steps);
      setValue(
        'steps',
        _.map(product?.steps, (_productStep) => ({
          ..._productStep,
          measurementTypes: _.map(
            _productStep.measurementTypes,
            (_eachMeasurementType) => {
              console.log('EACH MEASURREMENT TYPE', _eachMeasurementType);
              return _.find(measurementType?.rows, {
                _id: _eachMeasurementType?._id,
              });
            },
          ),
          resultingFunctionTypes: _productStep?.resultingFunctionTypes || [],
          materials: _productStep?.materials || [],
        })),
      );
      if (
        information?.setting?.mms?.fixedLastStep?.enable === true &&
        !_.last(product?.steps)?.is_fixed_step
      ) {
        setShowAddLastStepDialog(true);
      }
    }

    return () => {};
  }, [product]);

  const renderDialogAddLastStep = () => (
    <Dialog
      open={showAddLastStepDialog}
      onClose={() => {
        setShowAddLastStepDialog(false);
      }}
    >
      <DialogTitle>เพิ่มงานขั้นสุดท้าย</DialogTitle>
      <DialogContent>
        วิธีการผลิตนี้ถูกสร้างขึ้น ก่อนที่จะมีการตั้งค่าระบบให้มีขั้นตอน{' '}
        <b>{selectedName}</b> เป็นขั้นตอนสุดท้ายในการผลิต
        <br />
        ท่านต้องการเพิ่มขั้นตอนนี้ลงในกาารผลิตหรือไม่
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="success"
          onClick={() => {
            append(lastStepPayload);
            setShowAddLastStepDialog(false);
          }}
        >
          เพิ่ม
        </Button>
        <Button
          variant="contained"
          color="inherit"
          onClick={() => {
            setShowAddLastStepDialog(false);
          }}
        >
          ไม่เพิ่ม
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderStepPlugin = (index) => (
    <div className="flex flex-wrap justify-center">
      <div className="w-full px-2 py-1">
        <Controller
          control={control}
          name={`steps[${index}].plugin`}
          render={({ field }) => (
            <FormControl fullWidth>
              <FormLabel className="my-2 font-semibold">ปลั๊กอิน</FormLabel>
              <RadioGroup {...field}>
                {_.map(pluginList, (eachPlugin, pluginIndex) => (
                  <FormControlLabel
                    value={eachPlugin?.id}
                    control={<Radio />}
                    key={`plug-${pluginIndex}`}
                    label={
                      <div>
                        <div className="font-semibold">{eachPlugin.id} </div>
                        <div>{eachPlugin?.name}</div>
                      </div>
                    }
                  />
                ))}
              </RadioGroup>
              {_.isEmpty(pluginList) && <div>ไม่มีปลั๊กอินในระบบ</div>}
            </FormControl>
          )}
        />
      </div>
    </div>
  );

  const renderStepProcessing = (index) => (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <div>ตัวแปรรับค่า</div>
        <div>
          <ProcessTemplateMeasurementTypeForm
            control={control}
            measurementType={measurementType}
            stepIndex={index}
            product={product}
            watch={watch}
            alternativeName={`steps[${index}].measurementTypes`}
          />
        </div>
      </div>
      {!_.isEmpty(resultingFunctionType?.rows) && (
        <div className="w-full px-1 py-1">
          <div>ค่าแสดงผล</div>
          <div>
            <ProcessTemplateResultingFunctionTypeForm
              control={control}
              resultingFunctionType={resultingFunctionType}
              stepIndex={index}
              product={product}
              watch={watch}
              alternativeName={`steps[${index}].resultingFunctionTypes`}
            />
          </div>
        </div>
      )}
      {!_.isEmpty(material?.rows) && (
        <div className="w-full px-1 py-1">
          <div>วัตถุดิบ</div>
          <div>
            <ProcessTemplateMaterialForm
              control={control}
              material={material}
              stepIndex={index}
              product={product}
              watch={watch}
              // alternativeName={`steps[${index}].materials`}
            />
          </div>
        </div>
      )}
      {/* <div className="w-full px-1 py-2">
        <div>สิ่งที่ต้องทำหลังจากสิ้นสุดขั้นนี้</div>
        <div className="w-full  px-1 ">
          <Controller
            name={`steps[${index}].bookmark.add_warehouse`}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                label="เพิ่มลงคลังสินค้า"
                control={<Checkbox defaultChecked={field.value} {...field} />}
              />
            )}
          />
        </div>
        <div className="w-full  px-1 ">
          <Controller
            name={`steps[${index}].bookmark.add_inventory`}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                label="เพิ่มลงคลังวัตถุดิบ"
                control={<Checkbox defaultChecked={field.value} {...field} />}
              />
            )}
          />
        </div>
        <div className="w-full  px-1 ">
          <Controller
            name={`steps[${index}].bookmark.withdraw_material`}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                label="เบิกวัตถุดิบ"
                control={<Checkbox defaultChecked={field.value} {...field} />}
              />
            )}
          />
        </div>
      </div> */}
    </div>
  );

  const renderEachStepForm = (key, index, fieldInfo) => (
    <div
      className="flex flex-wrap p-4 w-full border-2 m-4 rounded-md shadow-lg border-gray-200 "
      key={key}
    >
      <div className="w-11/12">
        <div className="flex flex-row flex-wrap">
          <div className="w-full lg:w-2/12 px-1 py-2">
            <Controller
              name={`steps[${index}].index`}
              control={control}
              defaultValue={index + 1}
              rules={{
                min: 0,
                max: 99,
                minLength: 1,
                maxLength: 2,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ลำดับ"
                  fullWidth
                  size={'small'}
                  disabled
                  multiline={false}
                  required
                  helperText={
                    errors.steps?.[index]?.index && 'กรุณาใส่ตัวเลขให้ถูกต้อง'
                  }
                />
              )}
            />
          </div>
          <div className="w-full lg:w-4/12 px-1 py-2">
            <Controller
              name={`steps[${index}].name`}
              control={control}
              defaultValue={fieldInfo?.name}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อ"
                  fullWidth
                  size={'small'}
                  multiline={false}
                  required
                  helperText={errors.steps?.[index]?.detail && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-6/12 px-1 py-2">
            <Controller
              name={`steps[${index}].department`}
              control={control}
              defaultValue={
                product?.steps?.[index]?.responsible?.department?._id || ''
              }
              render={({ field }) => (
                <FormControl sx={{ minWidth: 120 }} fullWidth={true} required>
                  <InputLabel id="type" size={'small'}>
                    แผนก
                  </InputLabel>
                  <Select
                    {...field}
                    label="แผนก"
                    size={'small'}
                    fullWidth
                    defaultValue=""
                  >
                    {_.size(departments?.rows) ? (
                      _.map(departments.rows, (row, deptIndex) => (
                        <MenuItem key={deptIndex} value={row._id}>
                          {row.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>
                        <MenuItem disabled value="">
                          <em>ไม่มีข้อมูล</em>
                        </MenuItem>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            />
          </div>
        </div>
        <div className="flex flex-row flex-wrap">
          <div className="w-full  px-1 py-2">
            <Controller
              name={`steps[${index}].detail`}
              control={control}
              defaultValue={product ? product?.steps?.[index]?.detail : ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="รายละเอียด"
                  fullWidth
                  size={'small'}
                  rows={2}
                  multiline={true}
                  helperText={errors.steps?.[index]?.detail && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
        </div>
        <div className="flex flex-wrap justify-center">
          <div className="w-full lg:w-1/2  px-1 py-2">
            <Controller
              name={`steps[${index}].material_associate`}
              control={control}
              defaultValue={
                product ? product?.steps?.[index]?.material_associate : true
              }
              render={({ field }) => (
                <FormControlLabel
                  label="เกี่ยวข้องกับการใช้วัตถุดิบ"
                  control={<Checkbox defaultChecked={field.value} {...field} />}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-1/2  px-1 py-2">
            <Controller
              name={`steps[${index}].use_plugin_instead`}
              control={control}
              defaultValue={
                product ? product?.steps?.[index]?.use_plugin_instead : false
              }
              render={({ field }) => (
                <FormControlLabel
                  label="ใช้ปลั๊กอินแทนการตั้งค่าแบบทั่วไป"
                  control={<Checkbox defaultChecked={field.value} {...field} />}
                />
              )}
            />
          </div>
        </div>
        {watch(`steps[${index}].use_plugin_instead`)
          ? renderStepPlugin(index)
          : renderStepProcessing(index)}
      </div>

      <div className="w-1/12 p-2">
        <div className="flex flex-col gap-2">
          <Button
            className="w-full whitespace-nowrap "
            color="inherit"
            variant="contained"
            disabled={index === 0}
            endIcon={<ChevronUp size={16} />}
            onClick={() => {
              move(index, index - 1);
              setRerender(!rerender);
            }}
          >
            เลือนขึ้น
          </Button>
          <Button
            className="w-full"
            color="error"
            variant="contained"
            endIcon={<Trash size={16} />}
            onClick={() => {
              remove(index);
              setRerender(!rerender);
            }}
          >
            ลบ
          </Button>
          <Button
            className="w-full whitespace-nowrap"
            color="inherit"
            variant="contained"
            disabled={index === _.size(fields) - 1}
            endIcon={<ChevronDown size={16} />}
            onClick={() => {
              move(index, index + 1);
              setRerender(!rerender);
            }}
          >
            เลื่อนลง
          </Button>
        </div>
      </div>
    </div>
  );

  const renderProductInfo = () => (
    <div className="p-2 mt-2">
      {/* <Card className="p-4"> */}
      <div className="flex justify-center text-2xl font-semibold">
        {product?.type_code} {product?.name}
      </div>
      {/* </Card>{' '} */}
    </div>
  );

  return (
    <div className="flex flex-col  gap-3">
      <Card className="">
        {renderDialogAddLastStep()}
        {renderProductInfo()}
        <div className="flex flex-wrap">
          {_.map(fields, (_field, index) =>
            renderEachStepForm(_field?.id, index, _field),
          )}
        </div>
      </Card>
    </div>
  );
};

ProcessTemplateForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    detail: PropTypes.object,
    status: PropTypes.object,
    steps: MMS.processTemplate,
  }),
  control: PropTypes.object,
  product: WMS.Product,
  fields: PropTypes.arrayOf(PropTypes.object),
  remove: PropTypes.func,
  move: PropTypes.func,
  measurementType: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  watch: PropTypes.func,
  setValue: PropTypes.func,
  append: PropTypes.func,
  resultingFunctionType: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  material: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};

ProcessTemplateForm.defaultProps = {
  processTemplate: null,
};

export default ProcessTemplateForm;
