import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Switch,
  Tab,
  Tabs,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';
import { MANUFACTURING_TRANSACTION_TYPE } from '@/utils/constants';

import MaterialSplittingPieChart from '../../Chart/MaterialSplittingPieChart';
import { CancleProcessModal } from '../../Modals';
import { ProcessReport } from '../../Report';
import MaterialSplittingTable from '../../Tables/MMS/MaterialSplittingTable';
import { MaterialSplittingBranchingStep } from '../../Timeline';
import BoxPreferMaterial from '../Material/BoxPreferMaterial';
import BoxRemainingMaterial from '../Material/BoxRemainingMaterial';
import CommentBox from '../MMS/CommentBox';
import ManufacTransactionBox from '../MMS/ManufacTransactionBox';

import MaterialSplitterControlBox from './MaterialSplitterControlBox';
import MaterialSplitterMaterialList from './MaterialSplitterMaterialList';
import ProcessDetailBox from './ProcessDetailBox';
import ProcessNoInfoBox from './ProcessNoInfoBox';

const MaterialSplittingProcessActionContainerBox = ({
  selectedProcess,
  manufacturingOrder,
  getManufacturingOrder = () => {},
  referenceNumber,
  setDialogLoadingOpen,
}) => {
  const DISPLAY_TYPE = { DIAGRAM: 'DIAGRAM', TABLE: 'TABLE', PIE: 'PIE' };
  const [modalCancleProcessOpen, setModalCancleProcessOpen] = useState(false);
  const [height, setHeight] = useState(700);
  const [allowDrag, setAllowDrag] = useState(false);
  const [displayType, setDisplayType] = useState(DISPLAY_TYPE.DIAGRAM);
  const me = useSelector((state) => state.me);
  const information = useSelector((state) => state.information);
  const materialSplitting = useSelector((state) => state?.materialSplitting);

  const dispatch = useDispatch();

  const handleCancleProcess = () => {
    setModalCancleProcessOpen(true);
  };

  useEffect(() => {
    dispatch(actions.materialSplittingGetByProcess(selectedProcess?._id));

    return () => {};
  }, []);

  const handlePrint = () => {
    try {
      ProcessReport(selectedProcess, manufacturingOrder, information);
    } catch (error) {
      window.alert(`Error on Print ${error?.message}`);
      ProcessReport(selectedProcess, manufacturingOrder, information, true);
    }
  };

  const findMainMaterial = () => {
    const mainStep = _.find(
      materialSplitting?.steps,
      (each) => each?.hover_index === 0,
    );

    return mainStep?.main_material?._id;
  };
  const handleDeleteProcess = async (data) => {
    const confirm = window.confirm('ยืนยันการยกเลิกไลน์การผลิต');
    const { delete_note = '' } = data;
    try {
      if (confirm) {
        setModalCancleProcessOpen(false);
        await dispatch(
          actions.processPut(selectedProcess?._id, {
            deleted: true,
            employeeId: me?.userData?._id,
            delete_note,
            referenceNumber,
            manufacturing_transaction_type:
              MANUFACTURING_TRANSACTION_TYPE.PROCESS_CANCLE.status_code,
          }),
        );
        setDialogLoadingOpen(true);
        getManufacturingOrder();
      }
    } catch (error) {
      console.error('Error on Cancle Process', error);
      window.alert(`ยกเลิกไลน์การผลิตไม่สำเร็จ ${error?.message}`);
    }
  };

  return (
    <div className="my-2">
      <CancleProcessModal
        isOpen={modalCancleProcessOpen}
        handleClose={() => setModalCancleProcessOpen(false)}
        selectedProcess={selectedProcess}
        manufacturingOrder={manufacturingOrder}
        successHandle={(data) => {
          handleDeleteProcess(selectedProcess, data);
        }}
      />
      <div className="my-2">
        <ProcessNoInfoBox
          me={me}
          selectedProcess={selectedProcess}
          information={information}
          handleCancle={handleCancleProcess}
          handlePrint={handlePrint}
        />
      </div>{' '}
      <div className="fixed bottom-4 z-20 right-3">
        <MaterialSplitterControlBox
          process={selectedProcess}
          materialSplittingProcess={materialSplitting}
        />
      </div>
      <Card className="my-2">
        <CardContent>
          <div className="flex justify-between flex-wrap">
            <div>
              <Tabs
                value={displayType}
                onChange={(event, value) => setDisplayType(value)}
                aria-label="basic tabs example"
              >
                <Tab label="แผนผัง" value={DISPLAY_TYPE.DIAGRAM} />
                <Tab label="กราฟวงกลม" value={DISPLAY_TYPE.PIE} />
                <Tab label="ตาราง" value={DISPLAY_TYPE.TABLE} />
              </Tabs>
            </div>
            {displayType === DISPLAY_TYPE.DIAGRAM && (
              <div className="flex justify-end w-full lg:w-1/2">
                <ButtonGroup size="small">
                  <Button
                    startIcon={<i className="fas fa-minus-circle"></i>}
                    onClick={() => {
                      setHeight(height - 100);
                    }}
                  >
                    ลด
                  </Button>
                  <Button
                    startIcon={<i className="fas fa-plus-circle"></i>}
                    onClick={() => {
                      setHeight(height + 100);
                    }}
                  >
                    เพิ่ม
                  </Button>
                </ButtonGroup>
                <div className="px-2">
                  <FormControlLabel
                    label="ย่อขยาย/เลื่อน ไดอะแกรม"
                    control={
                      <Switch
                        defaultChecked={allowDrag}
                        onChange={(e) => setAllowDrag(e.target.checked)}
                      />
                    }
                  />
                </div>
                <div>
                  <Link
                    to={{
                      pathname: '/mms/process/splitting/expand',
                      state: {
                        materialSplitting,
                      },
                    }}
                  >
                    <IconButton size="small">
                      <i className="fas fa-external-link-alt"></i>
                    </IconButton>
                  </Link>
                </div>
              </div>
            )}
          </div>

          <div className="w-full h-full">
            {materialSplitting?.isLoading && <LinearProgress />}
            {displayType === DISPLAY_TYPE.DIAGRAM &&
              !materialSplitting?.isLoading &&
              materialSplitting?.isCompleted && (
                <MaterialSplittingBranchingStep
                  allowDrag={allowDrag}
                  currentProcess={selectedProcess}
                  processSteps={materialSplitting?.steps}
                  currentHoverIndex={materialSplitting?.current_hover_index}
                  manufacturingMaterial={
                    selectedProcess?.manufacturing_materials
                  }
                  height={height}
                />
              )}
            {displayType === DISPLAY_TYPE.TABLE &&
              !materialSplitting?.isLoading &&
              materialSplitting?.isCompleted && (
                <MaterialSplittingTable materialSplitting={materialSplitting} />
              )}

            {displayType === DISPLAY_TYPE.PIE &&
              !materialSplitting?.isLoading &&
              materialSplitting?.isCompleted && (
                <MaterialSplittingPieChart
                  materialSplitting={materialSplitting}
                />
              )}
          </div>
          <div className="my-2 pt-4"></div>
        </CardContent>
      </Card>
      <div className="flex flex-wrap mt-4 my-2">
        {selectedProcess?.isRequestMaterial && (
          <div className="w-full px-1 my-2">
            <MaterialSplitterMaterialList
              processSteps={materialSplitting?.steps}
              materialSplittingProcess={materialSplitting}
              currentProcess={selectedProcess}
            />
          </div>
        )}
        <div className="w-full lg:w-1/2 xl:w-1/3 px-1">
          <Card>
            <CardContent>
              <h4 className="font-bold font-display">วัตถุดิบ</h4>
              {selectedProcess?.isRequestMaterial ? (
                <div>
                  <BoxRemainingMaterial
                    manufacturingMaterials={_.map(
                      selectedProcess.manufacturing_materials,
                    )}
                    selectedProcess={selectedProcess}
                    showTotal
                    showPercent
                    displayDetailOnlyMainMaterial
                    mainMaterial={findMainMaterial()}
                  />
                </div>
              ) : (
                <BoxPreferMaterial
                  processMaterialList={selectedProcess?.materials}
                />
              )}
            </CardContent>
          </Card>
        </div>
        <div className="w-full  lg:w-1/2 xl:w-1/3 mt-2 lg:mt-0 px-1">
          <Card>
            <CardContent>
              <ProcessDetailBox selectedProcess={selectedProcess} />
            </CardContent>
          </Card>
        </div>
        <div className="  w-full lg:w-1/2 xl:w-1/3 mt-2 lg:mt-0 px-1">
          <Card>
            <CardContent>
              <h4 className="font-bold font-display">Timeline การผลิต</h4>
              <ManufacTransactionBox
                manufacturingTransaction={{
                  rows: selectedProcess?.manufacturing_transactions,
                }}
                showTitle={false}
                container={false}
              />
            </CardContent>
          </Card>
        </div>
      </div>
      {/* <Card>
          <CardContent>
            <h4 className="font-semibold font-display"> กระบวนการทำงาน</h4>
            <div className="flex flex-wrap justify-center ">
              {!_.isEmpty(selectedProcess?.steps) && (
                <div className="w-full lg:w-1/3">
                  <StepEmployee steps={selectedProcess?.steps} />
                </div>
              )}
            </div>
          </CardContent>
        </Card> */}
      <Card className="my-2">
        <CardContent>
          <h4 className="font-semibold font-display"> ความคิดเห็น</h4>
          <CommentBox
            comment={{ rows: selectedProcess?.comments }}
            processId={selectedProcess?._id}
            showTitle={false}
            container={false}
            handleSuccess={getManufacturingOrder}
          />
        </CardContent>
      </Card>
    </div>
  );
};

export default MaterialSplittingProcessActionContainerBox;

MaterialSplittingProcessActionContainerBox.propTypes = {
  selectedProcess: PropTypes.object,
  manufacturingOrder: PropTypes.object,
  getManufacturingOrder: PropTypes.func,
  referenceNumber: PropTypes.string,
  setDialogLoadingOpen: PropTypes.func,
};
