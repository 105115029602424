import _ from 'lodash';
import findParentAndChildLot from './findParentAndChildLot';
import getMaterialUsageList from './getMaterialUsageList';

const findMaterialOriginalCost = ({
  allowanceMaterial,
  allLotList,
  useCostFromPrevious,
}) => {
  const parentAndChildLot = findParentAndChildLot(allLotList);
  const newMaterialList = _.map(allowanceMaterial, (each) => {
    if (!useCostFromPrevious) {
      return {
        material: each?.material,
        quantity: each?.quantity,
        lot: each,
        unit_cost: (each?.price || 0) / (each?.quantity || 1),
        original_costs: [],
      };
    }

    const thisMaterialUsageList = getMaterialUsageList({
      parentWithChildLots: parentAndChildLot,
      productStockLot: each,
      productStockLotIndex: 0,
      previousLotList: allLotList,
    });

    const usageListGroupByLot = _.groupBy(
      thisMaterialUsageList,
      'running_number',
    );
    // console.log('usageListGroupByLot', usageListGroupByLot);
    const uniquedUsageList = _.map(usageListGroupByLot, (eachUsage) => ({
      material_stock_lot: eachUsage?.[0]?.materialStockLotId,
      unit_cost: eachUsage?.[0]?.unitCost,
      running_number: eachUsage?.[0]?.running_number,
      material: {
        _id: eachUsage?.[0]?.materialId,
        name: eachUsage?.[0]?.materialName,
      },
      lot_quantity: eachUsage?.[0]?.found_lot_quantity,
      total_cost: _.sumBy(eachUsage, (eus) => eus?.cost),
    }));
    return {
      material: each?.material,
      quantity: each?.quantity,
      lot: each,
      original_costs: uniquedUsageList,
    };
  });

  return newMaterialList;
};

export default findMaterialOriginalCost;
