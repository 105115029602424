import {
  CONVERSION_ALL,
  CONVERSION_GET,
  CONVERSION_DELETE,
  CONVERSION_PUT,
  CONVERSION_POST,
  CONVERSION_LOADING,
  CONVERSION_ERROR,
} from '../types';

import api from '../../../configs/api';

export const conversionCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: CONVERSION_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/conversion`,
      {
        ...payload,
      },
    );
    dispatch({ type: CONVERSION_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: CONVERSION_ERROR });
    throw new Error(error);
  }
};

export const conversionAll = ({ name = '', size = 10, page = 1 }) => async (
  dispatch,
) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/conversion?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: CONVERSION_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: CONVERSION_ERROR });
    throw new Error(error);
  }
};

export const conversionGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/conversion/${id}`,
    );
    if (status === 200) {
      dispatch({ type: CONVERSION_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: CONVERSION_ERROR });
    throw new Error(error);
  }
};

export const conversionStateSet = (data) => async (dispatch) => {
  dispatch({ type: CONVERSION_ALL, payload: data });
};

export const conversionStateOneSet = (data) => async (dispatch) => {
  dispatch({ type: CONVERSION_GET, payload: data });
};

export const conversionPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: CONVERSION_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/conversion/${id}`,
      payload,
    );
    dispatch({ type: CONVERSION_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: CONVERSION_ERROR });
    throw new Error(error);
  }
};
export const conversionDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: CONVERSION_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/conversion/${id}`,
    );
    dispatch({ type: CONVERSION_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: CONVERSION_ERROR });
    throw new Error(error);
  }
};
