import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  BackButton,
  CardProcessInfo,
  Error,
  Loading,
  ProcessEmployeeCostDriverBox,
  ProcessMaterialCostDriverBox,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';

/**
 * @function ProcessCostDetail
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function ProcessCostDetail({ title, subtitle }) {
  const dispatch = useDispatch();
  const currentProcess = useSelector((state) => state.process);
  const information = useSelector((state) => state.information);
  const history = useHistory();
  const params = useParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.processGet(params.id, { fetchProduct: true }));
    } catch (error) {
      dispatch(actions.processError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (currentProcess.isLoading) {
    return <Loading />;
  }

  if (!currentProcess.isLoading && currentProcess.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <CardProcessInfo
            information={information}
            manufacturingOrder={currentProcess?.manufacturing_order}
            selectedProcess={currentProcess}
          />
        </div>
        <div className="my-2">
          <Card>
            <CardContent>
              <h3 className="font-bold">ต้นทุนจากวัตถุดิบ</h3>
              <ProcessMaterialCostDriverBox currentProcess={currentProcess} />
            </CardContent>
          </Card>
        </div>{' '}
        <div className="my-2">
          <Card>
            <CardContent>
              <h3 className="font-bold">ต้นทุนจากพนักงาน</h3>
              <ProcessEmployeeCostDriverBox currentProcess={currentProcess} />
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

ProcessCostDetail.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ProcessCostDetail.defaultProps = {
  title: '',
  subtitle: '',
};
