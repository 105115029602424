import React, { useState, useEffect } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from '@mui/material';
import dayjs from 'dayjs';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';

export function DecreaseOrderForm({
  product,
  setSelectStockLot,
  order,
  setSelectMaterialLot,
  material,
}) {
  const [reload, setReload] = useState(false);
  const productOrder = _.map(order?.product_of_order, function (mapProduct) {
    return {
      product: mapProduct.product._id,
      lotId: '',
      quantity: 0,
      price: mapProduct?.price,
      amount: 0,
    };
  });
  const materialOrder = _.map(order?.material_of_order, function (mapMaterial) {
    return {
      material: mapMaterial.material._id,
      lotId: '',
      quantity: 0,
      price: mapMaterial?.price,
      amount: 0,
    };
  });
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      select_lot: productOrder,
      select_material_lot: materialOrder,
    },
  });

  const { fields, insert, remove } = useFieldArray({
    control,
    name: 'select_lot',
  });

  const {
    fields: materialField,
    insert: materialInsert,
    remove: materialRemove,
  } = useFieldArray({
    control,
    name: 'select_material_lot',
  });

  useEffect(() => {
    setSelectStockLot(getValues('select_lot'));
    setSelectMaterialLot(getValues('select_material_lot'));
    return () => {};
  }, [reload, fields]);

  const showAddButton = (productId, index) => {
    const findLot = _.find(product, function (_product) {
      return _product._id === productId;
    });
    return _.isEmpty(findLot?.stocklots) ? (
      <div></div>
    ) : (
      <div>
        <Button
          color={'secondary'}
          variant="contained"
          size={'small'}
          onClick={() =>
            insert(index, {
              product: getValues(`select_lot.[${index}].product`),
              lotId: '',
              quantity: 0,
              price: getValues(`select_lot.[${index}].price`),
              amount: 0,
            })
          }
        >
          เพิ่ม
        </Button>
      </div>
    );
  };

  const materialShowAddButton = (materialId, index) => {
    const findLot = _.find(material, function (_material) {
      return _material._id === materialId;
    });
    return _.isEmpty(findLot?.stocklots) ? (
      <div></div>
    ) : (
      <div>
        <Button
          color={'secondary'}
          variant="contained"
          size={'small'}
          onClick={() =>
            materialInsert(index, {
              material: getValues(`select_material_lot.[${index}].material`),
              lotId: '',
              quantity: 0,
              price: getValues(`select_material_lot.[${index}].price`),
              amount: 0,
            })
          }
        >
          เพิ่ม
        </Button>
      </div>
    );
  };

  const showLot = (productId) => {
    const findLot = _.find(product, function (_product) {
      return _product._id === productId;
    });
    return _.size(findLot?.stocklots) ? (
      _.map(findLot?.stocklots, (lot) => (
        <MenuItem key={lot._id} value={lot._id}>
          ล็อตวันที่ผลิต{' '}
          {dayjs(lot?.production_date).locale('th').format('DD MMM BB')} คงเหลือ{' '}
          {lot?.amount} {findLot?.unit}
        </MenuItem>
      ))
    ) : (
      <MenuItem disabled value="สินค้าหมด" selected>
        <em>สินค้าหมด</em>
      </MenuItem>
    );
  };

  const showMaterialLot = (materialId) => {
    const findLot = _.find(material, function (_material) {
      return _material._id === materialId;
    });
    return _.size(findLot?.stocklots) ? (
      _.map(findLot?.stocklots, (lot) => (
        <MenuItem key={lot._id} value={lot._id}>
          ล็อตวันที่{' '}
          {dayjs(lot?.purchase_date).locale('th').format('DD MMM BB')} คงเหลือ{' '}
          {lot?.amount} {findLot?.unit}
        </MenuItem>
      ))
    ) : (
      <MenuItem disabled value="วัตถุดิบหมด" selected>
        <em>สินค้าหมด</em>
      </MenuItem>
    );
  };

  const calulateAmount = (index) => {
    const stockLots = _.find(product, function (_product) {
      return _product?._id === getValues(`select_lot.[${index}].product`);
    }).stocklots;
    fields[index].amount =
      parseInt(
        _.find(stockLots, function (_stockLots) {
          return _stockLots?._id === getValues(`select_lot.[${index}].lotId`);
        }).amount,
        10,
      ) - parseInt(getValues(`select_lot.[${index}].quantity`), 10);
    setValue(`select_lot.[${index}].amount`, fields[index].amount);
  };

  const calculateMaterialAmount = (index) => {
    const stockLots = _.find(material, function (_material) {
      return (
        _material?._id === getValues(`select_material_lot.[${index}].material`)
      );
    }).stocklots;
    materialField[index].amount =
      parseInt(
        _.find(stockLots, function (_stockLots) {
          return (
            _stockLots?._id ===
            getValues(`select_material_lot.[${index}].lotId`)
          );
        }).amount,
        10,
      ) - parseInt(getValues(`select_material_lot.[${index}].quantity`), 10);
    setValue(
      `select_material_lot.[${index}].amount`,
      materialField[index].amount,
    );
  };

  const showSelectLot = (productId, index) => {
    const findLot = _.find(product, function (_product) {
      return _product._id === productId;
    });
    return _.isEmpty(findLot?.stocklots) ? (
      <TableCell>
        {' '}
        <div className="">
          <b className="text-red-500"> สินค้าหมด</b>
        </div>
      </TableCell>
    ) : (
      <TableCell>
        <Controller
          name={`select_lot.[${index}].lotId`}
          control={control}
          defaultValue={''}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                ล็อตสินค้า
              </InputLabel>
              <Select
                {...field}
                label="สินค้า"
                size={'small'}
                // fullWidth
                onChange={(e) => {
                  field.onChange(e.target.value);
                  calulateAmount(index);
                  setReload(!reload);
                }}
              >
                {showLot(getValues(`select_lot.[${index}].product`))}
              </Select>
            </FormControl>
          )}
        />{' '}
      </TableCell>
    );
  };

  const showSelectMaterialLot = (materialId, index) => {
    const findLot = _.find(material, function (_material) {
      return _material._id === materialId;
    });
    return _.isEmpty(findLot?.stocklots) ? (
      <TableCell>
        {' '}
        <div className="">
          <b className="text-red-500"> สินค้าหมด</b>
        </div>
      </TableCell>
    ) : (
      <TableCell>
        <Controller
          name={`select_material_lot.[${index}].lotId`}
          control={control}
          defaultValue={''}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                ล็อตวัตถุดิบ
              </InputLabel>
              <Select
                {...field}
                label="วัตถุดิบ"
                size={'small'}
                // fullWidth
                onChange={(e) => {
                  field.onChange(e.target.value);
                  calculateMaterialAmount(index);
                  setReload(!reload);
                }}
              >
                {showMaterialLot(
                  getValues(`select_material_lot.[${index}].material`),
                )}
              </Select>
            </FormControl>
          )}
        />{' '}
      </TableCell>
    );
  };

  const showSelectQuantity = (productId, index) => {
    const findLot = _.find(product, function (_product) {
      return _product._id === productId;
    });
    return _.isEmpty(findLot?.stocklots) ? (
      <TableCell>
        {' '}
        <div className="">
          <b className="text-red-500"> 0</b>
        </div>
      </TableCell>
    ) : (
      <TableCell>
        <Controller
          name={`select_lot.[${index}].quantity`}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              onChange={(e) => {
                field.onChange(e.target.value);
                calulateAmount(index);
                setReload(!reload);
              }}
              label="จำนวน"
              fullWidth
              inputProps={{
                min: 0,
                style: { textAlign: 'center' },
              }}
              size={'small'}
              helperText={errors.quantity && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </TableCell>
    );
  };

  const showSelectMaterialQuantity = (materialId, index) => {
    const findLot = _.find(material, function (_material) {
      return _material._id === materialId;
    });
    return _.isEmpty(findLot?.stocklots) ? (
      <TableCell>
        {' '}
        <div className="">
          <b className="text-red-500"> 0</b>
        </div>
      </TableCell>
    ) : (
      <TableCell>
        <Controller
          name={`select_material_lot.[${index}].quantity`}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              onChange={(e) => {
                field.onChange(e.target.value);
                calculateMaterialAmount(index);
                setReload(!reload);
              }}
              label="จำนวน"
              fullWidth
              inputProps={{
                min: 0,
                style: { textAlign: 'center' },
              }}
              size={'small'}
              helperText={errors.quantity && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </TableCell>
    );
  };

  const showDeleteButton = (productId, index) => {
    const findLot = _.find(product, function (_product) {
      return _product._id === productId;
    });
    return _.isEmpty(findLot?.stocklots) ? (
      <TableCell>
        {' '}
        <div className="flex">
          <div className="ml-2 mt-1">
            {showAddButton(getValues(`select_lot.[${index}].product`), index)}
          </div>
          <div>
            <Button color={'error'} variant="contained" size={'small'} disabled>
              ลบ
            </Button>
          </div>
        </div>
      </TableCell>
    ) : (
      <TableCell>
        <div className="flex">
          <div className="mr-2">
            {showAddButton(getValues(`select_lot.[${index}].product`), index)}
          </div>
          <div>
            <Button
              color={'error'}
              variant="contained"
              size={'small'}
              onClick={() => remove(index)}
              disabled={
                _.filter(getValues('select_lot'), {
                  product: getValues(`select_lot.[${index}].product`),
                }).length === 1
              }
            >
              ลบ
            </Button>
          </div>
        </div>
      </TableCell>
    );
  };

  const showMaterialDeleteButton = (materialId, index) => {
    const findLot = _.find(material, function (_material) {
      return _material._id === materialId;
    });
    return _.isEmpty(findLot?.stocklots) ? (
      <TableCell>
        {' '}
        <div className="flex">
          <div className="ml-2 mt-1">
            {materialShowAddButton(
              getValues(`select_material_lot.[${index}].material`),
              index,
            )}
          </div>
          <div>
            <Button color={'error'} variant="contained" size={'small'} disabled>
              ลบ
            </Button>
          </div>
        </div>
      </TableCell>
    ) : (
      <TableCell>
        <div className="flex">
          <div className="mr-2">
            {materialShowAddButton(
              getValues(`select_material_lot.[${index}].material`),
              index,
            )}
          </div>
          <div>
            <Button
              color={'error'}
              variant="contained"
              size={'small'}
              onClick={() => materialRemove(index)}
              disabled={
                _.filter(getValues('select_material_lot'), {
                  material: getValues(
                    `select_material_lot.[${index}].material`,
                  ),
                }).length === 1
              }
            >
              ลบ
            </Button>
          </div>
        </div>
      </TableCell>
    );
  };

  const showProduct = (index) => {
    const findFromProduct = _.find(product, function (_product) {
      return _product?._id === getValues(`select_lot.[${index}].product`);
    });

    const findFromOrder = _.find(order?.product_of_order, function (_product) {
      return (
        _product?.product?._id === getValues(`select_lot.[${index}].product`)
      );
    });
    return (
      <p>
        {findFromProduct?.name} {findFromOrder?.quantity}{' '}
        {findFromProduct?.unit}
      </p>
    );
  };

  const showMaterial = (index) => {
    const findFromMaterial = _.find(material, function (_material) {
      return (
        _material?._id === getValues(`select_material_lot.[${index}].material`)
      );
    });

    const findFromOrder = _.find(
      order?.material_of_order,
      function (_material) {
        return (
          _material?.material?._id ===
          getValues(`select_material_lot.[${index}].material`)
        );
      },
    );
    return (
      <p>
        {findFromMaterial?.name} {findFromOrder?.quantity}{' '}
        {findFromMaterial?.unit}
      </p>
    );
  };

  const showPrice = (index) => {
    const findFromOrder = _.find(order?.product_of_order, function (_product) {
      return (
        _product?.product?._id === getValues(`select_lot.[${index}].product`)
      );
    });
    return <p>{findFromOrder?.price} บาท</p>;
  };

  const showMaterialPrice = (index) => {
    const findFromOrder = _.find(
      order?.material_of_order,
      function (_material) {
        return (
          _material?.material?._id ===
          getValues(`select_material_lot.[${index}].material`)
        );
      },
    );
    return <p>{findFromOrder?.price} บาท</p>;
  };

  const displayProduct = () => (
    <TableContainer>
      <Table size="small" className="border-lg">
        <colgroup>
          <col width="10%" />
          <col width="10%" />
          <col width="10%" />
          <col width="20%" />
          <col width="15%" />
          <col width="15%" />
        </colgroup>
        <TableHead className="bg-blue-100">
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> สินค้า</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ราคาขาย</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ล็อตสินค้า</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> จำนวน</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ดำเนินการ</div>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {fields.map((_field, index) => (
            <TableRow key={_field._id}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>
                <div className="flex flex-col">
                  <div className="my-1">{showProduct(index)}</div>
                </div>
              </TableCell>
              <TableCell>{showPrice(index)}</TableCell>
              {showSelectLot(getValues(`select_lot.[${index}].product`), index)}
              {showSelectQuantity(
                getValues(`select_lot.[${index}].product`),
                index,
              )}
              {showDeleteButton(
                getValues(`select_lot.[${index}].product`),
                index,
              )}
            </TableRow>
          ))}
          {materialField.map((_field, index) => (
            <TableRow key={_field._id}>
              <TableCell>{index + 1 + _.size(fields)}</TableCell>
              <TableCell>
                <div className="flex flex-col">
                  <div className="my-1">{showMaterial(index)}</div>
                </div>
              </TableCell>
              <TableCell>{showMaterialPrice(index)}</TableCell>
              {showSelectMaterialLot(
                getValues(`select_material_lot.[${index}].material`),
                index,
              )}
              {showSelectMaterialQuantity(
                getValues(`select_material_lot.[${index}].material`),
                index,
              )}
              {showMaterialDeleteButton(
                getValues(`select_material_lot.[${index}].material`),
                index,
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <div>
      <div className="my-2">{displayProduct()}</div>
    </div>
  );
}

DecreaseOrderForm.propTypes = {
  product: PropTypes.object,
  setSelectStockLot: PropTypes.func,
  order: PropTypes.object,
};

DecreaseOrderForm.defaultProps = {
  product: null,
  order: null,
};

export default DecreaseOrderForm;
