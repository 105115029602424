// Frontend Plugin Register
// import Your Plugin
import BJMetalDivide from './bj-metal-divide';

// List your enabled plugin
// name,id, module,containers,reducers

const enablePlugin = [
  {
    name: BJMetalDivide.name,
    buttonTitle: BJMetalDivide.buttonTitle,
    id: BJMetalDivide.id,
    module: BJMetalDivide.module,
    containers: BJMetalDivide.containers,
    reducers: BJMetalDivide.reducers,
    displayOnProcessContainer: BJMetalDivide.displayOnProcessContainer,
    editOnStepContainer: BJMetalDivide.editOnStepContainer,
  },
];

const pluginList = enablePlugin?.map((eachPlugin) => ({
  name: eachPlugin?.name,
  id: eachPlugin?.id,
}));

// Export Your Plugin
export { pluginList };

export default enablePlugin;
