import React, { useEffect } from 'react';
import {
  TextField,
  Autocomplete,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Controller, useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';

export function SelectProductForm({
  product,
  discount,
  setSelectProduct,
  order,
  reload,
  setReload,
  control,
  getValues,
  setValue,
  errors,
  watch,
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'product_of_order',
  });

  useEffect(() => {
    setSelectProduct(getValues('product_of_order'));
    return () => {};
  }, [reload, fields, discount]);

  const displayProduct = () => (
    <TableContainer>
      <Table size="small" className="border-lg">
        <colgroup>
          <col width="5%" />
          <col width="20%" />
          <col width="20%" />
          <col width="20%" />
          <col width="20%" />
          <col width="15%" />
        </colgroup>
        <TableHead className="bg-blue-100">
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> สินค้า</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> จำนวน</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ราคาขาย</div>
            </TableCell>{' '}
            <TableCell>
              <div className="font-bold"> หน่วย</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ดำเนินการ</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.size(fields) ? (
            fields.map((_field, index) => (
              <TableRow key={_field.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  <div className="w-72 px-1 py-2">
                    <Controller
                      name={`product_of_order.[${index}].product`}
                      control={control}
                      rules={{ required: true }}
                      defaultValue={
                        order?.product_of_order
                          ? getValues(`product_of_order.[${index}].product`)
                          : ''
                      }
                      render={({ field }) => (
                        <Autocomplete
                          size={'small'}
                          disableClearable
                          {...field}
                          onChange={(e, newValue) => {
                            field.onChange(newValue);
                            setReload(!reload);
                            setValue(
                              `product_of_order.[${index}].additional_unit`,
                              newValue?.unit,
                            );
                          }}
                          options={product}
                          getOptionLabel={(option) =>
                            `${option?.type_code} | ${option?.name}`
                          }
                          required
                          renderInput={(params) => (
                            <TextField {...params} label="สินค้า" required />
                          )}
                        />
                      )}
                    />
                  </div>
                </TableCell>
                <TableCell>
                  <Controller
                    name={`product_of_order.[${index}].quantity`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          setReload(!reload);
                        }}
                        label="จำนวน"
                        fullWidth
                        required
                        inputProps={{
                          min: 0,
                          style: { textAlign: 'center' },
                        }}
                        size={'small'}
                        helperText={errors.quantity && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  {' '}
                  <Controller
                    name={`product_of_order.[${index}].price`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          setReload(!reload);
                        }}
                        label="ราคา"
                        fullWidth
                        required
                        inputProps={{
                          min: 0,
                          style: { textAlign: 'center' },
                        }}
                        size={'small'}
                        helperText={errors.price && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  {' '}
                  <Controller
                    name={`product_of_order.[${index}].additional_unit`}
                    control={control}
                    rules={{ required: true }}
                    defaultValue={watch(
                      `product_of_order.[${index}].product.unit`,
                    )}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          setReload(!reload);
                        }}
                        label="หน่วย"
                        fullWidth
                        required
                        inputProps={{
                          style: { textAlign: 'center' },
                        }}
                        size={'small'}
                        helperText={errors.additional_unit && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    color={'error'}
                    variant="contained"
                    size={'small'}
                    onClick={() => remove(index)}
                  >
                    ลบ
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5}>
                <div className="text-center py-2">ไม่มีรายการ</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <div>
      <div>{displayProduct()}</div>
      <div className="flex flex-wrap justify-between">
        <div className="px-2 py-2">
          <Button
            color={'success'}
            variant="outlined"
            size={'small'}
            onClick={() =>
              append({
                product: product?.[0],
                quantity: 0,
                price: 0,
              })
            }
          >
            เพิ่มรายการ
          </Button>
        </div>
      </div>
    </div>
  );
}

SelectProductForm.propTypes = {
  product: PropTypes.object,
  order: PropTypes.object,
  reload: PropTypes.bool,
  setReload: PropTypes.func,
  control: PropTypes.object,
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  errors: PropTypes.object,
  watch: PropTypes.func,
  discount: PropTypes.number,
  setSelectProduct: PropTypes.func,
};

SelectProductForm.defaultProps = {
  product: null,
  order: null,
};

export default SelectProductForm;
