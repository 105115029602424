/* eslint-disable no-return-assign */
/* eslint-disable no-confusing-arrow */
import React, { useEffect, useState } from 'react';
import {
  Check as CheckIcon,
  ChevronDown as ChevronDownIcon,
  X as XIcon,
} from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
} from '@mui/material';
import dayjs from 'dayjs';
import BuddhistCalendar from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';

import { MANUFACTURING_TRANSACTION_TYPE, MQ_TASK } from '@/utils/constants';

import 'dayjs/locale/th';

import {
  CommentBox,
  InfoManufacturingOrder,
  ManufacturingTransactionBox as ManufacTransactionBox,
  MaterialSourceBox,
  MaterialUsageBox,
  MultiUnitProdOrMatBox,
  PluginDisplayRenderingComponent,
  ProcessControlBox,
  ProcessInfoBox,
  ProductAsMaterialResultBox,
  ProductResultBox,
  StepEmployee,
} from '@iarcpsu/emanufac-components/src/components/Box';
import { BackButton } from '@iarcpsu/emanufac-components/src/components/Button';
import { Error } from '@iarcpsu/emanufac-components/src/components/Error';
import {
  Loading,
  LoadingDialogWithTimeout,
  SocketIOLoading,
} from '@iarcpsu/emanufac-components/src/components/Loading';
import {
  CancleProcessModal,
  DeleteManufacOrderModal,
} from '@iarcpsu/emanufac-components/src/components/Modals';
import {
  ManufacturingOrderReport,
  ProcessReport,
} from '@iarcpsu/emanufac-components/src/components/Report';
import { TimelineStepper } from '@iarcpsu/emanufac-components/src/components/Timeline';
import { ViewTitle } from '@iarcpsu/emanufac-components/src/components/ViewTitle';
import * as actions from '../../../redux/actions';
import { currencyFormatter, MMS } from '../../../utils/functions';
import { MMS as MMSMiddleWare } from '../../../utils/middleware';

dayjs.extend(BuddhistCalendar);
dayjs.locale('th');

const {
  MaterialAnalyzer: {
    manufacMaterialAnalyzedArrayAnalzer,
    unNullManufacMaterialAnalyzedArrayAnalzer,
  },
  findVariableAndExecute,
} = MMS;

/**
 * @function DetailManufacOrderA
 * @memberof MMS/ManufactoringOrder
 * @description หน้าจอแสดงรายละเอียด MO แบบแรก ที่เน้นดู Process / Line การผลิตที่ละไลน์
 * มองเห็นการผลิตของไลน์ผลิตนั้นในภาพรวม
 */

const DetailManufacOrderA = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const manufacturingOrder = useSelector((state) => state.manufacturingOrder);
  const me = useSelector((state) => state.me);
  const information = useSelector((state) => state.information);
  const [modalCancleOpen, setModalCancleOpen] = useState(false);
  const [modalCancleProcessOpen, setModalCancleProcessOpen] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [dialogLoadingOpen, setDialogLoadingOpen] = useState(false);

  const handleCancleProcess = () => {
    setModalCancleProcessOpen(true);
  };

  const handlePrint = (selectedProcess) => {
    try {
      ProcessReport(selectedProcess, manufacturingOrder, information);
    } catch (error) {
      window.alert(`Error on Print ${error?.message}`);
      ProcessReport(selectedProcess, manufacturingOrder, information, true);
    }
  };

  const openReport = () => {
    try {
      ManufacturingOrderReport(manufacturingOrder, information);
    } catch (error) {
      window.alert(`Error on Print ${error?.message}`);
      console.log('Detech as Error on Open MO Report', error);
      ManufacturingOrderReport(manufacturingOrder, information, true);
    }
  };

  const [tab, setTab] = useState(
    params.tab
      ? _.findIndex(
          manufacturingOrder?.process,
          (_process) => _process?._id === params.tab,
        ) || 0
      : 0,
  );

  const getManufacturingOrder = async () => {
    try {
      await dispatch(actions.manufacturingOrderGet(params.id, {}));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getManufacturingOrder();

    return () => {};
  }, [params]);

  useEffect(() => {
    const selectedTab = params.tab
      ? _.findIndex(
          manufacturingOrder?.process,
          (_process) => _process?._id === params.tab,
        ) || 0
      : 0;
    setTab(selectedTab);
    return () => {};
  }, [manufacturingOrder]);

  useEffect(() => {
    const tempHash = hash({
      employee: me?.userData?._id,
      date: new Date(),
    });
    setReferenceNumber(tempHash);

    return () => {};
  }, []);

  useEffect(() => {
    const fetchMeasurements = async () => {
      const tabbedProcess = manufacturingOrder?.process?.[tab];
      try {
        if (tabbedProcess?._id && tabbedProcess?._id !== '') {
          await dispatch(
            actions.measurementAll({ currentProcess: tabbedProcess?._id }),
          );
        } else {
          console.log('Not Found Tabbed Process');
        }
      } catch (error) {
        console.error('Measurement Fetch All Error', error);
      }
    };

    fetchMeasurements();
    return () => {};
  }, [tab]);

  const handleEditManufacturingOrder = async (data) => {
    try {
      await dispatch(
        actions.manufacturingOrderPut(
          params.id,
          MMSMiddleWare.ManufacturingOrder(data),
        ),
      );
      getManufacturingOrder();
    } catch (error) {
      console.error('Edit ManufacturingOrder Fail', error);
      window.alert('ไม่สามารถแก้ไขคำสั่งผลิตได้', error?.message);
    }
  };

  const renderFunctionResult = (
    currentFunction,
    stepMeasurements,
    allFunctions,
  ) => {
    try {
      console.log(
        'Resulting Function Type that use for execute',
        currentFunction,
      );

      // FIXME: Make as a state avoid redundancy
      const anotherFunctions = _.map(allFunctions, (eachFunction) => ({
        _id: eachFunction?._id,
        value: findVariableAndExecute({
          measurementVars: stepMeasurements,
          resultingFunctionType: eachFunction,
          enableLog: false,
        }),
      }));

      const executedResult = findVariableAndExecute({
        measurementVars: stepMeasurements,
        resultingFunctionType: currentFunction,
        enableLog: false,
        anotherMeasurementFT: anotherFunctions,
      });
      return { value: executedResult };
    } catch (error) {
      console.error('Cannot Execute Resulting Function', error);
      return '-';
    }
  };

  const handleDeleteProcess = async (selectedProcess, data) => {
    const confirm = window.confirm('ยืนยันการยกเลิกไลน์การผลิต');
    const { delete_note = '' } = data;
    try {
      if (confirm) {
        setModalCancleProcessOpen(false);
        await dispatch(
          actions.processPut(selectedProcess?._id, {
            deleted: true,
            employeeId: me?.userData?._id,
            delete_note,
            referenceNumber,
            manufacturing_transaction_type:
              MANUFACTURING_TRANSACTION_TYPE.PROCESS_CANCLE.status_code,
          }),
        );
        setDialogLoadingOpen(true);
        getManufacturingOrder();
      }
    } catch (error) {
      console.error('Error on Cancle Process', error);
      window.alert(`ยกเลิกไลน์การผลิตไม่สำเร็จ ${error?.message}`);
    }
  };

  const handleSocketIOFunction = {
    onSuccess: () => {
      setDialogLoadingOpen(false);
      window.alert('ยกเลิกไลน์การผลิตสำเร็จ');
      getManufacturingOrder();
    },
    onFail: (args) => {
      setDialogLoadingOpen(false);
      console.log('Error', args?.error);
      window.alert(args?.message);
    },
  };

  const renderProcessStatus = (process) => {
    // เสร็จแล้ว
    if (process?.completed) {
      return (
        <div className="flex gap-1 text-green-600   ">
          <CheckIcon size={16} className="self-center mr-2" />
          <div className="self-center">การผลิตเสร็จสิ้น</div>
        </div>
      );
    }

    // ยกเลิกแล้ว
    if (process?.deleted) {
      return (
        <div>
          <div className="flex gap-1 text-red-600   ">
            <XIcon size={16} className="self-center mr-2" />
            <div className="self-center">
              ไลน์การผลิตนี้ถูกยกเลิก
              <p className="text-red-500 text-sm"> {process?.delete_note} </p>
            </div>
          </div>
        </div>
      );
    }

    // ยังไม่เริ่ม
    if (process?.current?.index === null || process?.current?.index === -1) {
      return (
        <div className="flex gap-1  text-red-500  ">
          <div className="self-center">ยังไม่เริ่มการผลิต</div>
        </div>
      );
    }

    return (
      <div>
        <div>
          ขั้นตอนที่ {process?.current?.index + 1} / {_.size(process?.steps)}{' '}
          {process?.current?.step?.name}
        </div>
      </div>
    );
  };

  const renderMeasurement = (selectedProcess) =>
    _.map(selectedProcess?.steps, (eachStep, index) => {
      if (!_.isEmpty(eachStep?.measurements)) {
        return (
          <div key={index} className="my-2">
            <div className="text-base font-semibold font-display">
              {eachStep?.name}{' '}
            </div>
            <div className="lg:grid lg:grid-cols-2">
              {_.map(eachStep?.measurements, (eachMeasurement, innerIndex) => (
                <div key={`in-${innerIndex}`} className="flex gap-4 my-1">
                  <div className="text-base self-center">
                    {eachMeasurement?.measurement_type?.name}
                  </div>
                  <div className="text-xl font-semibold self-center">
                    {eachMeasurement?.value && eachMeasurement?.value !== ''
                      ? eachMeasurement?.value
                      : '-'}{' '}
                  </div>
                  <div className="text-base self-center">
                    {eachMeasurement?.measurement_type?.unit?.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      }
      return <div key={index}></div>;
    });

  const renderResultingFunctions = (selectedProcess) => {
    let processMeasurement = [];
    _.map(
      selectedProcess?.steps,
      (eachStep) =>
        (processMeasurement = _.concat(
          processMeasurement,
          eachStep?.measurements,
        )),
    );

    let processResultingFunction = [];
    _.map(
      selectedProcess?.steps,
      (eachStep) =>
        (processResultingFunction = _.concat(
          processResultingFunction,
          eachStep?.resultingFunctionTypes,
        )),
    );

    // console.log('Process Measurement', processMeasurement);
    return _.map(selectedProcess?.steps, (eachStep, index) => {
      if (!_.isEmpty(eachStep?.resultingFunctionTypes)) {
        return (
          <div key={index} className="my-2">
            <div className="text-base font-semibold font-display">
              {eachStep?.name}{' '}
            </div>{' '}
            <div className="lg:grid lg:grid-cols-2">
              {_.map(
                eachStep?.resultingFunctionTypes,
                (eachFunction, innerIndex) => (
                  <div key={`in-${innerIndex}`} className="flex gap-4 my-1">
                    <div className="text-base self-center">
                      {eachFunction?.name}
                    </div>
                    <div className="text-xl font-semibold">
                      {currencyFormatter.format(
                        renderFunctionResult(
                          eachFunction,
                          processMeasurement,
                          processResultingFunction,
                        )?.value,
                      )}
                    </div>
                  </div>
                ),
              )}
            </div>
          </div>
        );
      }
      return <div key={index}></div>;
    });
  };

  const renderRemainingMaterial = (_material, materialIndex) => (
    <li key={materialIndex}>
      <span className="font-semibold font-display">
        {_material?.materialInfo?.type_code}{' '}
      </span>
      {_material?.materialInfo?.name} ใช้ได้
      <MultiUnitProdOrMatBox
        foundMaterial={_material?.materialInfo}
        quantity={_material?.resultAmount}
        noWrap
      />
    </li>
  );

  const RenderInformationBox = ({ selectedProcess }) => (
    <Card>
      <CardContent>
        <CancleProcessModal
          isOpen={modalCancleProcessOpen}
          handleClose={() => setModalCancleProcessOpen(false)}
          selectedProcess={selectedProcess}
          manufacturingOrder={manufacturingOrder}
          successHandle={(data) => {
            handleDeleteProcess(selectedProcess, data);
          }}
        />

        <ProcessInfoBox
          me={me}
          selectedProcess={selectedProcess}
          information={information}
          handleCancle={handleCancleProcess}
          handlePrint={handlePrint}
        />
        <div className="flex flex-wrap mt-4">
          <div className="w-full lg:w-1/2">
            <div className="  mx-2 ml-4 ">
              <h4 className="font-bold "> วัตถุดิบ</h4>
              <ul className="list-decimal list-inside my-2 ml-2">
                {_.map(
                  unNullManufacMaterialAnalyzedArrayAnalzer(
                    selectedProcess.manufacturing_materials,
                  ),
                  (_material, materialIndex) =>
                    renderRemainingMaterial(_material, materialIndex),
                )}
              </ul>
            </div>
          </div>
          <div className="w-full lg:w-1/2 mt-2 lg:mt-0">
            <div className="  mx-2 ml-4 ">
              <h4 className="font-bold">สถานะการผลิต</h4>
              <div className="w-full mt-2">
                {renderProcessStatus(selectedProcess)}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full m-2 mx-6">
          <div className="flex flex-wrap">
            {!_.isEmpty(selectedProcess?.measurement_type_data) && (
              <div className="my-2 w-full lg:w-1/2">
                <h4 className="font-bold">ค่าที่วัดผล</h4>
                <div className="w-full mt-2">
                  {renderMeasurement(selectedProcess)}
                </div>
              </div>
            )}
            {!_.isEmpty(selectedProcess?.resulting_function_types) && (
              <div className="my-2  w-full lg:w-1/2">
                <h4 className="font-bold">ผลจากการวัด</h4>
                <div className="w-full mt-2">
                  {renderResultingFunctions(selectedProcess)}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="w-full my-8">
          <TimelineStepper
            information={information}
            selectedProcess={selectedProcess}
          />
        </div>
        <div className="w-full my-2 mx-2">
          <PluginDisplayRenderingComponent
            currentProcess={selectedProcess}
            currentStep={selectedProcess?.current?.step}
            manufacturingOrder={manufacturingOrder}
          />
        </div>
        <div className="w-full my-8 mx-2 ml-4">
          <h4 className="font-semibold font-display"> ควบคุมการทำงาน</h4>
          <ProcessControlBox
            currentStep={selectedProcess?.current?.step}
            process={selectedProcess}
            justify="center"
            container={false}
          />
        </div>

        <Accordion>
          <AccordionSummary expandIcon={<ChevronDownIcon />}>
            <h4 className="font-semibold font-display"> การใช้วัตถุดิบ</h4>
          </AccordionSummary>
          <AccordionDetails>
            <div className="flex flex-wrap  w-full my-4 ">
              {_.map(
                _.filter(
                  manufacMaterialAnalyzedArrayAnalzer(
                    selectedProcess.manufacturing_materials,
                  ),
                  (_material) => _material?.materialInfo?._id !== undefined,
                ),
                (_material, index) => (
                  <MaterialUsageBox
                    key={index}
                    isRequested={selectedProcess?.isRequestMaterial}
                    selectedMaterial={_material}
                    steps={selectedProcess.steps}
                    process={selectedProcess}
                  />
                ),
              )}
            </div>
            {_.isEmpty(
              _.filter(
                manufacMaterialAnalyzedArrayAnalzer(
                  selectedProcess.manufacturing_materials,
                ),
                (_material) => _material?.materialInfo?._id !== undefined,
              ),
            ) && (
              <div className="flex justify-center">
                <div className="text-center">ไม่มีข้อมูลการเบิกใช้วัตถุดิบ</div>
              </div>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ChevronDownIcon />}>
            <h4 className="font-semibold font-display"> ที่มาวัตถุดิบ</h4>
          </AccordionSummary>
          <AccordionDetails>
            <MaterialSourceBox
              selectedProcess={selectedProcess}
              information={information}
            />
          </AccordionDetails>
        </Accordion>
        {information?.setting?.mms?.productionHandler?.addToWarehouse && (
          <Accordion>
            <AccordionSummary expandIcon={<ChevronDownIcon />}>
              <h4 className="font-semibold font-display"> ผลผลิต</h4>
            </AccordionSummary>
            <AccordionDetails>
              <ProductResultBox
                selectedProcess={selectedProcess}
                information={information}
              />
              <ProductAsMaterialResultBox
                selectedProcess={selectedProcess}
                information={information}
              />
            </AccordionDetails>
          </Accordion>
        )}

        <div className="w-full my-8 mx-2 ml-4">
          <h4 className="font-semibold font-display"> กระบวนการทำงาน</h4>
          <div className="flex flex-wrap justify-center ">
            {!_.isEmpty(selectedProcess?.steps) && (
              <div className="w-full lg:w-1/3">
                <StepEmployee steps={selectedProcess?.steps} />
              </div>
            )}

            <div className="w-full lg:w-2/3">
              {' '}
              <ManufacTransactionBox
                manufacturingTransaction={{
                  rows: selectedProcess?.manufacturing_transactions,
                }}
                showTitle={false}
                container={false}
              />
            </div>
          </div>
        </div>
        <div className="w-full py-8 p-4">
          <h4 className="font-semibold font-display"> ความคิดเห็น</h4>
          <CommentBox
            comment={{ rows: selectedProcess?.comments }}
            processId={selectedProcess?._id}
            showTitle={false}
            container={false}
            handleSuccess={getManufacturingOrder}
          />
        </div>
      </CardContent>
    </Card>
  );

  const renderTitle = () => (
    <ViewTitle
      title={`${title} ${manufacturingOrder?.running_number || ''} `}
      subtitle={subtitle}
    />
  );

  const renderPrintButton = () => (
    <div className="self-center flex gap-2">
      <Button variant="contained" color="teal" onClick={() => openReport()}>
        พิมพ์
      </Button>
      <Link
        to={`/mms/manufacturing-orders/edit/${manufacturingOrder?._id}`}
        hidden={
          me?.userData?.role?.level < 1 ||
          manufacturingOrder?.completed ||
          manufacturingOrder?.deleted
        }
      >
        <Button
          variant="contained"
          color="warning"
          disabled={
            me?.userData?.role?.level < 1 ||
            manufacturingOrder?.completed ||
            manufacturingOrder?.deleted
          }
        >
          แก้ไข
        </Button>
      </Link>
    </div>
  );

  const renderDeleteModal = () => (
    <DeleteManufacOrderModal
      isOpen={modalCancleOpen}
      handleClose={() => setModalCancleOpen(false)}
      manufacturingOrder={manufacturingOrder}
      successHandle={() => setModalCancleOpen(false)}
    />
  );

  const renderProductTabs = () => (
    <ul className="flex cursor-pointer overflow-x-auto">
      {_.map(manufacturingOrder?.process, (_process, index) =>
        index === tab ? (
          <li
            className={`py-2 px-6 bg-white rounded-t-lg ${
              _process?.deleted ? 'line-through' : ''
            }`}
            key={index}
          >
            <b>
              {_process?.produce_material_instead
                ? _process?.product_as_material?.type_code
                : _process?.product?.type_code}{' '}
            </b>
            {_process?.produce_material_instead
              ? _process?.product_as_material?.name
              : _process?.product?.name}
            {_process?.produce_base_project_instead && (
              <span>
                <b>{_process?.base_project?.type_code || ''}</b>{' '}
                {_process?.base_project?.name || ''}
              </span>
            )}
          </li>
        ) : (
          <Link
            key={index}
            className={`py-2 px-6  rounded-t-lg text-gray-500 bg-gray-200 ${
              _process?.deleted ? 'line-through' : ''
            }`}
            to={`/mms/manufacturing-orders/view/${params.id}/${_process?._id}`}
            onClick={() => {
              setTab(index);
            }}
          >
            <b>
              {_process?.produce_material_instead
                ? _process?.product_as_material?.type_code
                : _process?.product?.type_code}{' '}
            </b>
            {_process?.produce_material_instead
              ? _process?.product_as_material?.name
              : _process?.product?.name}
          </Link>
        ),
      )}
      <Link
        className="py-2 px-6  rounded-t-lg text-gray-500 bg-gray-200"
        to={`/mms/manufacturing-orders/create-new-process/${manufacturingOrder?._id}`}
      >
        <b>
          <i className="fas fa-plus" />
        </b>{' '}
        เพิ่ม
      </Link>
    </ul>
  );

  if (manufacturingOrder?.isLoading || manufacturingOrder?.rows) {
    return <Loading />;
  }

  if (manufacturingOrder?.isCompleted && !manufacturingOrder?.isLoading) {
    return (
      <div>
        {renderDeleteModal()}
        <div className="flex flex-row justify-between">
          {renderTitle()}
          <div className="flex gap-2">{renderPrintButton()}</div>
        </div>
        <LoadingDialogWithTimeout
          isLoading={dialogLoadingOpen}
          label="กำลังลบไลน์การผลิต"
        />
        <SocketIOLoading
          taskCode={MQ_TASK.UPDATE_PROCESS.status_code}
          handleSuccess={handleSocketIOFunction.onSuccess}
          referenceNumber={referenceNumber}
          handleFail={handleSocketIOFunction.onFail}
        />
        <div>
          <BackButton />
        </div>
        <div className="my-2">
          {' '}
          <InfoManufacturingOrder
            manufacturingOrder={manufacturingOrder}
            me={me}
            information={information}
            handleMOToSuccess={() =>
              handleEditManufacturingOrder({ completed: true })
            }
            handleMOToCancle={() =>
              handleEditManufacturingOrder({ deleted: true })
            }
          />
          <div>
            <div className="w-full py-2 my-2 font-bold text-2xl">สินค้า</div>
            {renderProductTabs()}
            {/* eslint-disable-next-line no-confusing-arrow */}
            {_.map(manufacturingOrder?.process, (_process, index) =>
              index === tab ? (
                <RenderInformationBox selectedProcess={_process} key={index} />
              ) : (
                <div key={index}></div>
              ),
            )}
          </div>
        </div>
      </div>
    );
  }

  return <Error />;
};

DetailManufacOrderA.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  selectedProcess: PropTypes.object,
};

DetailManufacOrderA.defaultProps = {
  title: '',
  subtitle: '',
};
export default DetailManufacOrderA;
