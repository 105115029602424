import React from 'react';
import PropTypes from 'prop-types';

const BooleanGauge = ({ measurementData }) => (
  <div>
    {measurementData?.boolean_value ? (
      <div>
        <div className="flex gap-2">
          <div>
            <i className="fas fa-check-circle text-2xl text-green-500"></i>
          </div>
          <div className="text-lg">
            {measurementData?.measurement_type?.label_right}
          </div>
        </div>
      </div>
    ) : (
      <div>
        <div className="flex gap-2">
          <div>
            <i className="fas fa-times-circle text-2xl text-red-500"></i>
          </div>
          <div className="text-lg">
            {measurementData?.measurement_type?.label_left}
          </div>
        </div>
      </div>
    )}
  </div>
);

BooleanGauge.propTypes = {
  measurementData: PropTypes.object,
};

export default BooleanGauge;
