import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

function LocalizationForm({ control, information }) {
  return (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">หัวข้อ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ข้อความที่ใช้</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(information?.localization, (row, key) => (
                <TableRow
                  key={key}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    <div className="text-xs">{key}</div>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name={`localization.${key}`}
                      defaultValue={row || ''}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          size="small"
                          label={key}
                        />
                      )}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default LocalizationForm;

LocalizationForm.propTypes = {
  control: PropTypes.object,
  information: PropTypes.object,
};
