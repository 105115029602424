import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { DesktopDateTimepicker } from '../../DatePicker';

const DatetimeInput = ({
  control,
  measurementType,
  measurementControllerName = '',
}) => (
  <div>
    <div>
      <Controller
        control={control}
        name={`${measurementControllerName}.date_value`}
        defaultValue={dayjs().toDate()}
        render={({ field }) => (
          <div>
            {field.value !== null && (
              <DesktopDateTimepicker
                label={measurementType?.label}
                setValue={field.onChange}
                value={field.value}
                fullWidth
              />
            )}
            <div>
              <FormControlLabel
                label="ยังไม่กรอกตอนนี้"
                control={
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        field.onChange(null);
                      } else {
                        field.onChange(dayjs().toDate());
                      }
                    }}
                  />
                }
              />
            </div>
          </div>
        )}
      />
    </div>
  </div>
);

DatetimeInput.propTypes = {
  control: PropTypes.object,
  measurementType: PropTypes.object,
  measurementControllerName: PropTypes.string,
};

export default DatetimeInput;
