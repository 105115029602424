import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Alert,
  AlertTitle,
  Backdrop,
  Button,
  Card,
  Fade,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { CSVUpload } from '../../CSVUpload';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

function ImportWithStockLotModal({
  handleAddFromFile,
  open,
  onClose,
  materialType,
}) {
  const [csvData, setCsvData] = useState(false);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style} className="max-w-4xl">
          <div className="pt-2">อัพโหลดข้อมูลำหรับอัพโหลดพร้อมสต็อก</div>
          <div className="py-2 font-system">
            อัพโหลดไฟล์ .csv โดยมีโครงสร้างตามไฟล์เทมเพลต แถวที่ 1-2
            และแถวสุดท้าย จะไม่ถูกนำมาคิดในระบบ <br />
            <Button>
              <Link to="/filetemplate/MaterialWithStocklot.csv" target="_blank">
                ดาวน์โหลดเทมเพลต
              </Link>
            </Button>
          </div>
          <div className="my-2">
            <Alert severity="info">
              <AlertTitle>
                โปรดแทนที่ข้อมูลใน <strong>ประเภทวัตถุดิบ</strong>{' '}
                ด้วยรหัสดังต่อไปนี้
              </AlertTitle>
              <div className="border rounded-sm h-80 overflow-y-auto">
                <Table
                  sx={{
                    '&:last-child td, &:last-child th': { border: 1 },
                  }}
                >
                  <TableHead>
                    <TableCell>ชื่อประเภทของวัตถุดิบ</TableCell>
                    <TableCell>รหัส</TableCell>
                  </TableHead>
                  <TableBody>
                    {_.map(materialType?.rows, (_materialType, index) => (
                      <TableRow key={index}>
                        <TableCell>{_materialType?.name} </TableCell>
                        <TableCell>{_materialType?._id} </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </Alert>
          </div>
          <div className="py-2">
            <div className="flex justify-center">
              <div>
                <CSVUpload setCsvData={setCsvData} />
                {!_.isEmpty(csvData) && (
                  <div>
                    <p className="font-system my-1">
                      พบข้อมูล {_.size(csvData)} รายการ
                    </p>
                    <Button
                      color="primary"
                      variant="contained"
                      type="button"
                      onClick={() => handleAddFromFile({ csvData })}
                    >
                      บันทึก
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );
}

ImportWithStockLotModal.propTypes = {
  handleAddFromFile: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ImportWithStockLotModal;
