import React, { useEffect, useState } from 'react';
import { SwatchesPicker } from 'react-color';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import * as actions from '@/redux/actions';
import { FRONT_TEMPLATE } from '@/utils/constants';

function Preference({ title, subtitle }) {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  const { setting: { colors: settingColors } = {} } = information;

  const [currentColor, setCurrentColor] = useState({
    primary: settingColors?.primary,
    secondary: settingColors?.secondary,
    teal: settingColors?.teal,
    success: settingColors?.success,
    error: settingColors?.error,
    warning: settingColors?.warning,
  });

  const { handleSubmit, control } = useForm();

  useEffect(() => {
    dispatch(actions.informationAll());
    return () => {};
  }, []);

  const onSubmit = async (data, event) => {
    try {
      event.preventDefault();

      await dispatch(
        actions.informationPut(information.id, {
          setting: {
            ...information?.setting,
            colors: settingColors,
            main_page_type: data?.setting?.main_page_type,
          },
        }),
      );

      alert('สำเร็จ');
      await dispatch(actions.informationAll());
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const resetDefault = async () => {
    try {
      await dispatch(
        actions.informationPut(information.id, {
          setting: {
            ...information?.setting,
            colors: {
              primary: '#CC0000',
              secondary: '#3B82F6',
              teal: '#009688',
              success: '#10B981',
              error: '#FF1744',
              warning: '#F9A825',
            },
          },
        }),
      );

      alert('สำเร็จ');
      await dispatch(actions.informationAll());
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setCurrentColor(information?.setting?.colors);
    return () => {};
  }, [information]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderColorPicker = (colorPlace) => (
    <div className="my-2 w-full md:w-1/3">
      <div className="">{_.upperFirst(colorPlace)}</div>
      <div className="m-2">
        <SwatchesPicker
          color={currentColor?.[colorPlace]}
          onChangeComplete={(color) => {
            currentColor[colorPlace] = color.hex;
            setCurrentColor(currentColor);
          }}
        />
      </div>
    </div>
  );

  if (information.isLoading) {
    return <Loading />;
  }
  if (!information.isLoading && information.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card className="my-1">
            <CardContent>
              <div className="w-full my-1 p-1 font-semibold">
                รูปแบบของหน้าแรก
              </div>
              <div className="w-full my-1 p-2">
                <Controller
                  control={control}
                  name="setting.main_page_type"
                  defaultValue={information?.setting?.main_page_type}
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      {_.map(FRONT_TEMPLATE, (each, index) => (
                        <FormControlLabel
                          key={index}
                          value={each.status_code}
                          label={each?.description}
                          control={<Radio />}
                        />
                      ))}
                    </RadioGroup>
                  )}
                />
              </div>

              <div className="w-full my-1 p-1 font-semibold">สีของระบบ</div>

              <div className="flex flex-wrap p-2">
                {renderColorPicker('primary')}
                {renderColorPicker('secondary')}
                {renderColorPicker('teal')}
                {renderColorPicker('success')}
                {renderColorPicker('error')}
                {renderColorPicker('warning')}
              </div>
            </CardContent>
          </Card>

          <div className="flex flex-row justify-end gap-1 py-4">
            <Button
              variant="contained"
              color="secondary"
              type="button"
              onClick={() => resetDefault()}
            >
              กลับสู่การตั้งค่าเดิม
            </Button>
            <Button variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    );
  }
  return <Error />;
}
Preference.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Preference.defaultProps = {
  title: '',
  subtitle: '',
};

export default Preference;
