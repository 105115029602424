import api from '../../../configs/api';
import graphqlClient from '../../../configs/graphqlClient';
import {
  BASE_PROJECT_ALL,
  BASE_PROJECT_DELETE,
  BASE_PROJECT_ERROR,
  BASE_PROJECT_GET,
  BASE_PROJECT_LOADING,
  BASE_PROJECT_POST,
  BASE_PROJECT_PUT,
} from '../types';

export const baseProjectCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: BASE_PROJECT_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/base-project`,
      {
        ...payload,
      },
    );
    dispatch({ type: BASE_PROJECT_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: BASE_PROJECT_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const baseProjectAll = ({ name = '', size = 10, page = 1 }) => async (
  dispatch,
) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/base-project?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: BASE_PROJECT_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: BASE_PROJECT_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const baseProjectAllQuery = ({ query }) => async (dispatch) => {
  try {
    const queryResult = await graphqlClient.request(query);
    const data = queryResult?.findUnits;
    //  console.log('Query Data', queryResult);
    if (!data) {
      dispatch({ type: BASE_PROJECT_ERROR });
    }

    dispatch({ type: BASE_PROJECT_ALL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: BASE_PROJECT_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const baseProjectGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/base-project/${id}`,
    );
    if (status === 200) {
      dispatch({ type: BASE_PROJECT_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: BASE_PROJECT_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const baseProjectStateSet = (data) => async (dispatch) => {
  dispatch({ type: BASE_PROJECT_ALL, payload: data });
};

export const baseProjectStateOneSet = (data) => async (dispatch) => {
  dispatch({ type: BASE_PROJECT_GET, payload: data });
};

export const baseProjectReset = () => async (dispatch) => {
  dispatch({ type: BASE_PROJECT_LOADING });
};

export const baseProjectError = () => async (dispatch) => {
  dispatch({ type: BASE_PROJECT_ERROR });
};

export const baseProjectPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: BASE_PROJECT_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/base-project/${id}`,
      payload,
    );
    dispatch({ type: BASE_PROJECT_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: BASE_PROJECT_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};
export const baseProjectDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: BASE_PROJECT_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/base-project/${id}`,
    );
    dispatch({ type: BASE_PROJECT_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: BASE_PROJECT_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};
