/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  BackButton,
  Error,
  Loading,
  UserForm,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Button, Card } from '@mui/material';
import PropTypes from 'prop-types';

import * as actions from '../../redux/actions';

function EditPassword({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const me = useSelector((state) => state.me);
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({ defaultValues: me?.userData?.uid });

  useEffect(() => {
    dispatch(actions.meGet());

    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      await dispatch(actions.userPut(me.userData?.uid?._id, data));
      alert('แก้ไขรหัสผ่านสำเร็จ');
      dispatch(actions.meGet());
      history.push('/profile/main');
    } catch (error) {
      alert(error.message);
      console.log(error);
    }
  };

  const renderForm = () => (
    <div className="w-full ">
      <Card className="p-4 ">
        <form onSubmit={handleSubmit(onSubmit)}>
          <UserForm
            Controller={Controller}
            control={control}
            errors={errors}
            user={me?.userData?.uid}
          />
          <Button variant="contained" type="submit">
            บันทึก
          </Button>
        </form>
      </Card>
    </div>
  );

  if (me.isLoading) {
    return <Loading />;
  }
  if (!me.isLoading && me.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        {renderForm()}
      </div>
    );
  }
  return <Error />;
}
EditPassword.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditPassword.defaultProps = {
  title: '',
  subtitle: '',
};

export default EditPassword;
