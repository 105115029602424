import React from 'react';
import PropTypes from 'prop-types';

import ButtonMenuList from '../Button/ButtonMenuList';

function DefaultMenu({ me, availableModule }) {
  return (
    <div className="min-h-screen bg-white">
      <div className="container pt-40 mr-auto ml-auto">
        <ButtonMenuList me={me} availableModule={availableModule} />
      </div>
    </div>
  );
}

export default DefaultMenu;

DefaultMenu.propTypes = {
  me: PropTypes.object,
  availableModule: PropTypes.arrayOf(PropTypes.object),
};
