import api from '../../../configs/api';
import {
  MANUFACTURING_ORDER_LOG_ALL,
  MANUFACTURING_ORDER_LOG_ERROR,
  MANUFACTURING_ORDER_LOG_GET,
  MANUFACTURING_ORDER_LOG_LOADING,
} from '../types';

export const manufacturingOrderLogAll = (params) => async (dispatch) => {
  try {
    const {
      name = '',
      size = '',
      page = 1,
      completed = false,
      history = false,
      all = false,
      startDate = '',
      endDate = '',
      sortCreatedAt = '',
      analyzed = '',
    } = params;
    const { data, status: httpStatus } = await api.get(
      `${
        process.env.REACT_APP_API_URL
      }/mo-log?name=${name}&size=${size}&page=${page}${
        completed ? '' : '&!completed'
      }&history=${history}&all=${all}&startDate=${startDate}&endDate=${endDate}&sort_created_at=${sortCreatedAt}&analyzed=${analyzed}`,
    );
    if (httpStatus === 200) {
      dispatch({ type: MANUFACTURING_ORDER_LOG_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MANUFACTURING_ORDER_LOG_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const manufacturingOrderLogStateSet = (data) => async (dispatch) => {
  dispatch({ type: MANUFACTURING_ORDER_LOG_ALL, payload: data });
};

export const manufacturingOrderLogStateOneSet = (data) => async (dispatch) => {
  dispatch({ type: MANUFACTURING_ORDER_LOG_GET, payload: data });
};

export const manufacturingOrderLogGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/mo-log/${id}?enableFetchInside=true&fetchCurrentStep=true&fetchCustomer=true&fetchMaterial=true&fetchProduct=true&fetchProcess=true&fetchBaseProject=true`,
    );
    if (status === 200) {
      dispatch({ type: MANUFACTURING_ORDER_LOG_GET, payload: data });
    }
    if (status === 404) {
      dispatch({
        type: MANUFACTURING_ORDER_LOG_ERROR,
        payload: { message: '' },
      });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MANUFACTURING_ORDER_LOG_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const manufacturingOrderLogReset = () => async (dispatch) => {
  dispatch({ type: MANUFACTURING_ORDER_LOG_LOADING });
};

export const manufacturingOrderLogError = () => async (dispatch) => {
  dispatch({ type: MANUFACTURING_ORDER_LOG_ERROR });
};
