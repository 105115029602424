import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { Button } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { MMS } from '@/utils/functions';

import MaterialUsageCard from './MaterialUsageCard';

function AllMaterialUsageCard({ selectedProcess }) {
  const {
    MaterialAnalyzer: { manufacMaterialAnalyzedArrayAnalzer },
  } = MMS;

  const filteredMaterial = _.filter(
    manufacMaterialAnalyzedArrayAnalzer(
      selectedProcess.manufacturing_materials,
    ),
    (_material) => _material?.materialInfo?._id !== undefined,
  );

  const materialAnalyzerWithMergedFlag = _.map(filteredMaterial, (each) => ({
    ...each,
    isMerged: each?.resultAmount === 0 && each?.mergedAmount > 0,
  }));

  const orderedFilterMaterial = _.orderBy(
    materialAnalyzerWithMergedFlag,
    ['isMerged', 'materialInfo.materialName'],
    ['asc', 'asc'],
  );

  const mergeOnlyMaterial = _.filter(
    orderedFilterMaterial,
    (each) => each?.isMerged === false,
  );

  const isMergedMaterialContain = _.some(orderedFilterMaterial, [
    'isMerged',
    true,
  ]);

  const [openMergedContain, setOpenMergedContain] = useState(false);

  return (
    <div className="w-full">
      {_.isEmpty(filteredMaterial) && (
        <div className="flex justify-center">
          <div className="text-center">ไม่มีข้อมูลการเบิกใช้วัตถุดิบ</div>
        </div>
      )}

      {isMergedMaterialContain && !openMergedContain ? (
        <div>
          {_.map(mergeOnlyMaterial, (_material, materialIndex) => (
            <MaterialUsageCard
              key={materialIndex}
              isRequested={selectedProcess?.isRequestMaterial}
              selectedMaterial={_material}
              steps={selectedProcess.steps}
              process={selectedProcess}
            />
          ))}
          <div className="mx-1">
            <Button
              variant="contained"
              color="inherit"
              fullWidth
              startIcon={<ChevronDown />}
              onClick={() => setOpenMergedContain(!openMergedContain)}
            >
              แสดงรายการที่ถูกรวมไปแล้ว
            </Button>
          </div>
        </div>
      ) : (
        <div>
          {_.map(orderedFilterMaterial, (_material, materialIndex) => (
            <MaterialUsageCard
              key={materialIndex}
              isRequested={selectedProcess?.isRequestMaterial}
              selectedMaterial={_material}
              steps={selectedProcess.steps}
              process={selectedProcess}
            />
          ))}
        </div>
      )}
      {isMergedMaterialContain && openMergedContain && (
        <div className="mx-1 my-2">
          <Button
            variant="contained"
            color="inherit"
            fullWidth
            startIcon={<ChevronUp />}
            onClick={() => setOpenMergedContain(!openMergedContain)}
          >
            ซ่อนรายการที่ถูกรวมไปแล้ว
          </Button>
        </div>
      )}
    </div>
  );
}

export default AllMaterialUsageCard;

AllMaterialUsageCard.propTypes = {
  selectedProcess: PropTypes.object,
};
