/* eslint-disable arrow-body-style */
import React from 'react';
import { QUOTATION_ACTION } from '@iarcpsu/emanufac-constant';
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Namebox from '../../Box/Common/NameBox';

const QuotationTransactionTable = ({
  quotationTransaction,
  page,
  size,
  setPage,
  setSize,
  total,
  showQuotationNumber,
}) => {
  return (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วันที่</div>
                </TableCell>
                {showQuotationNumber && (
                  <TableCell>
                    <div className="font-bold">
                      เลขที่ใบเสนอราคา <br />
                      เบิกใช้ภายใน
                    </div>
                  </TableCell>
                )}
                <TableCell>
                  <div className="font-bold">กิจกรรม</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">รายละเอียด</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">พนักงาน</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(quotationTransaction?.rows) ? (
                _.map(quotationTransaction?.rows, (row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="text-xs">
                        {(page - 1) * size + index + 1}
                      </div>
                    </TableCell>
                    <TableCell>
                      {dayjs(row?.createdAt).format('D MMM BBBB HH:mm')}
                    </TableCell>
                    {showQuotationNumber && (
                      <TableCell>
                        {row?.is_internal_deposit ? 'ID' : 'QT'}
                        {row?.quotation_number}
                      </TableCell>
                    )}
                    <TableCell>
                      <Chip
                        label={QUOTATION_ACTION[row?.action_type]?.description}
                        color={
                          QUOTATION_ACTION[row?.action_type]?.color || 'primary'
                        }
                      />
                    </TableCell>{' '}
                    <TableCell>{row?.description}</TableCell>{' '}
                    <TableCell>
                      <Namebox user={row?.employee} />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={(event, newPage) => setPage(newPage + 1)}
          onRowsPerPageChange={(event) => setSize(event.target.value)}
        />
      </Paper>
    </div>
  );
};

export default QuotationTransactionTable;

QuotationTransactionTable.propTypes = {
  quotationTransaction: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  page: PropTypes.number,
  size: PropTypes.number,
  setPage: PropTypes.func,
  setSize: PropTypes.func,
  total: PropTypes.number,
};
