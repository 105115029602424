import Proptypes from 'prop-types';

export const Comment = Proptypes.shape({
  title: Proptypes.string,
  uid: Proptypes.oneOfType([Proptypes.string, Proptypes.object]),
  timestamps: Proptypes.string,
});

export const Order = Proptypes.shape({
  customer: Proptypes.object,
  order_status: Proptypes.string,
  date_expected: Proptypes.string,
  remark: Proptypes.string,
  status: Proptypes.string,
  commerce: Proptypes.shape({
    cost_price: Proptypes.number,
    sale_price: Proptypes.number,
    discount: Proptypes.number,
    sale_name: Proptypes.string,
    recipient_name: Proptypes.string,
    payment_method: Proptypes.oneOfType([Proptypes.object, Proptypes.string]),
  }),
  delivery: Proptypes.shape({
    shipping_type: Proptypes.oneOfType([Proptypes.object, Proptypes.string]),
    tracking_number: Proptypes.string,
    price: Proptypes.number,
  }),
  comments: Proptypes.arrayOf(Comment),
});

export const OrderArray = Proptypes.shape({
  rows: Proptypes.arrayOf(Order),
  currPage: Proptypes.number,
  isComplete: Proptypes.bool,
  isLoading: Proptypes.bool,
  lastPage: Proptypes.number,
});

export const ShippingType = Proptypes.shape({
  id: Proptypes.string,
  type_code: Proptypes.string,
  name: Proptypes.string,
  description: Proptypes.string,
});

export const PaymentType = ShippingType;

const ShippingTypeArray = Proptypes.shape({
  rows: Proptypes.arrayOf(ShippingType),
  currPage: Proptypes.number,
  isComplete: Proptypes.bool,
  isLoading: Proptypes.bool,
  lastPage: Proptypes.number,
});

const PaymentTypeArray = Proptypes.shape({
  rows: Proptypes.arrayOf(PaymentType),
  currPage: Proptypes.number,
  isComplete: Proptypes.bool,
  isLoading: Proptypes.bool,
  lastPage: Proptypes.number,
});

export default {
  Comment,
  Order,
  OrderArray,
  ShippingType,
  PaymentType,
  ShippingTypeArray,
  PaymentTypeArray,
};
