/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
import { CRM_TYPE } from '@iarcpsu/emanufac-constant';

const CustomerColumn = [
  {
    label: 'ลำดับที่',
    value: 'index',
  },
  {
    label: 'ลูกค้าหรือคู่ค้า',
    value: (col) => CRM_TYPE[col?.status]?.description,
  },
  {
    label: 'รหัสคู่ค้า/ลูกค้า',
    value: 'type_code',
  },
  {
    label: 'ชื่อคู่ค้า/ลูกค้า',
    value: 'name',
  },
  {
    label: 'ประเภทองค์กร',
    value: 'organization_type',
  },
  {
    label: 'ประเภทคู่ค้า/ลูกค้า',
    value: (col) => col?.type?.name || '',
  },
  {
    label: 'เลขประจำตัวผู้เสียภาษี',
    value: 'taxes',
  },
  {
    label: 'ที่อยู่',
    value: 'address',
  },
  {
    label: 'หมายเลขโทรศัพท์',
    value: 'phone_number',
  },
  {
    label: 'โทรสาร',
    value: 'fax',
  },
  {
    label: 'อีเมล',
    value: 'email',
  },
  {
    label: 'สำนักงานใหญ่หรือสาขา',
    value: (col) =>
      col?.is_headquator
        ? 'สำนักงานใหญ่'
        : `สาขา${col?.branch_code || ''} ${col?.branch_name || '-'}`,
  },
];

export default CustomerColumn;
