import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

const TransactionTypeSelect = ({
  errors,
  control,
  transactionList,
  controllerName,
}) => (
  <div className={`my-2 ${_.size(transactionList) === 1 ? 'hidden' : ''} `}>
    <Card>
      <CardContent>
        <Controller
          control={control}
          name={controllerName}
          rules={{ require: true }}
          defaultValue={transactionList?.[0]?._id}
          render={({ field }) => (
            <FormControl fullWidth size="small" required>
              <InputLabel>ประเภทการขอเบิก</InputLabel>
              <Select
                size="small"
                required
                {...field}
                label="ประเภทการขอเบิก"
                disabled={_.size(transactionList) === 1}
              >
                {_.map(transactionList, (_txntype, index) => (
                  <MenuItem key={index} value={_txntype?._id}>
                    {_txntype?.name}
                  </MenuItem>
                ))}
              </Select>
              {errors?.[controllerName] && (
                <FormHelperText>กรุณาเลือกประเภท</FormHelperText>
              )}
            </FormControl>
          )}
        />
      </CardContent>
    </Card>{' '}
  </div>
);

export default TransactionTypeSelect;

TransactionTypeSelect.propTypes = {
  errors: PropTypes.object,
  control: PropTypes.object,
  transactionList: PropTypes.arrayOf(PropTypes.object),
  controllerName: PropTypes.string,
};
