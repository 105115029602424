import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  ApprovalMaterialWithdrawForm,
  BackButton,
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import {
  MANUFACTURING_TRANSACTION_TYPE,
  MATERIAL_STOCK_STATUS,
  MATERIAL_WITHDRAW_STATUS,
} from '@iarcpsu/emanufac-constant';
import { Button, Card } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { app as appConfig } from '@/configs';
import * as actions from '@/redux/actions';

const ApprovalMaterialWithdrawOrder = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const materialWithdraw = useSelector((state) => state.materialWithdraw);
  const material = useSelector((state) => state.material);
  const me = useSelector((state) => state.me);

  const selectMaterialWithdraw = _.map(
    materialWithdraw?.material,
    function (_material) {
      return {
        material: _material.material._id,
        lotId: '',
        quantity: 0,
        amount: 0,
      };
    },
  );

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
    handleSubmit,
  } = useForm({
    defaultValues: {
      select_lot: selectMaterialWithdraw,
    },
  });

  const history = useHistory();

  const [name] = useState('');
  const [page] = useState(1);
  const [size] = useState(appConfig.maxFetchSize);

  useEffect(() => {
    const getMaterialWithdraw = async () => {
      try {
        await dispatch(actions.materialWithdrawGet(id));
      } catch (error) {
        console.error('Cannot Get MaterialWithdraw');
      }
    };

    const fetchMaterial = async () => {
      try {
        await dispatch(
          actions.materialAll({
            name,
            page,
            size,
            fetchStockLot: true,
            stockStatus: MATERIAL_STOCK_STATUS.IN_STOCK.status_code,
          }),
        );
      } catch (error) {
        console.error('Cannot Fetch Material');
      }
    };

    const fetchMe = async () => {
      try {
        await dispatch(actions.meGet({ name, page, size }));
      } catch (error) {
        console.error('Cannot Fetch Me');
      }
    };

    getMaterialWithdraw();
    fetchMaterial();
    fetchMe();

    return () => {};
  }, [id]);

  useEffect(() => {
    if (materialWithdraw) {
      setValue('select_lot', selectMaterialWithdraw);
    }

    return () => {};
  }, [materialWithdraw]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data, event) => {
    const confirm = window.confirm('ยืนยันการเบิกวัตถุดิบ');
    if (confirm) {
      try {
        const channgStatus = {
          ...materialWithdraw,
          request_name: `${me?.userData?.firstname} ${me?.userData?.lastname}`,
          material_withdraw: materialWithdraw._id,
          status: MATERIAL_WITHDRAW_STATUS.APPROVAL.status_code,
          materialLots: getValues('select_lot'),
          process_number: materialWithdraw?.process?._id,
        };
        const materialArray = _.map(watch('select_lot'), (_stocklot) => {
          console.log('ข้อมูลที่เพิ่มลงในรายการเบิก/ขอ ในการผลิต');
          console.table(
            [
              {
                ชื่อวัตถุดิบ: _stocklot.material,
                จำนวนเริ่มต้น: 0,
                จำนวนที่ขอเพิ่ม: _stocklot.quantity,
                คงเหลือในการผลิต: 0 + parseFloat(_stocklot.quantity),
              },
            ],
            [
              'ชื่อวัตถุดิบ',
              'จำนวนเริ่มต้น',
              'จำนวนที่ขอเพิ่ม',
              'คงเหลือในการผลิต',
            ],
          );

          return {
            material: _stocklot.material,
            begin: 0,
            quantity: _stocklot.quantity,
            amount: 0 + parseFloat(_stocklot.quantity),
          };
        });
        console.log('channgStatus', channgStatus);
        console.log('-----------');
        console.log('Material Array', materialArray);
        event.preventDefault();
        await dispatch(actions.materialWithdrawPut(id, channgStatus));
        // สร้างรายการการเบิก / ใช้วัตถุดิบ ในกระบวนการการผลิต
        await dispatch(
          actions.manufacMaterialCreate({
            process: materialWithdraw?.process?._id,
            step: null,
            transaction_type:
              MANUFACTURING_TRANSACTION_TYPE.MATERIAL_REQUEST.status_code,
            transaction_dir: true,
            employee: me?.userData?._id,
            materialArray,
          }),
        );
        alert('สำเร็จ');
        await dispatch(actions.materialWithdrawGet(id));
        history.goBack();
      } catch (error) {
        console.log(error);
      }
    }
  };

  // prettier-ignore
  const isPageLoading = () => materialWithdraw.rows ||
  material.isLoading;

  // prettier-ignore
  const isPageNotLoading = () => !materialWithdraw.isLoading || !materialWithdraw.rows ||
    !material.isLoadin;

  // prettier-ignore
  const isPageComplete = () => materialWithdraw.isCompleted ||
  material.isCompleted;

  const renderSelectProduct = () => (
    <ApprovalMaterialWithdrawForm
      material={material.rows}
      materialWithdraw={materialWithdraw}
      control={control}
      getValues={getValues}
      setValue={setValue}
      watch={watch}
    />
  );

  if (isPageLoading()) {
    return <Loading />;
  }

  if (isPageNotLoading() && isPageComplete()) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Card className="p-6">
            <div>
              <b>ข้อมูลวัตถุดิบ</b>
              {renderSelectProduct()}
            </div>
            <div className="flex flex-row justify-end gap-1 py-2">
              <Button
                variant="contained"
                type="submit"
                disabled={_.find(watch('select_lot'), { lotId: '' })}
              >
                บันทึก
              </Button>
            </div>
          </Card>
        </form>
        <div className="bg-green-700"></div>
      </div>
    );
  }
  return <Error />;
};

ApprovalMaterialWithdrawOrder.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ApprovalMaterialWithdrawOrder.defaultProps = {
  title: '',
  subtitle: '',
};

export default ApprovalMaterialWithdrawOrder;
