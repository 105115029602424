import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import _ from 'lodash';
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Button,
  TextField,
  MenuItem,
} from '@mui/material';

import MultiUnitProdOrMatBox from '../../Box/Rendering/MultiUnitProdOrMatBox';
import ProdOrMatQuantityInput from '../../Inputs/ProdOrMatQuantityInput';

const MaterialSearchAndWithdrawForm = ({
  materialTransactionType,
  errors,
  control,
  materials,
  watch,
  setValue,
  selectedLot,
  setSelectedLot,
  reload,
  setReload,
  useRequestOfMaterial = false,
}) => {
  const information = useSelector((state) => state.information);
  const {
    setting: { ims: imsSetting },
  } = information;

  const filteredTxnType = _.filter(
    materialTransactionType?.rows,
    (_txnType) => _txnType?.direction === 'desc',
  );

  // Handle Whilte click add button to with lot mode
  const handleAddToSelectedMaterial = (materialInfo, quantity, lotInfo) => {
    const materialStockLotElementPayload = {
      material: materialInfo,
      quantity,
      lotId: lotInfo,
      amount: lotInfo?.amount - parseFloat(quantity),
    };

    if (materialStockLotElementPayload.amount < 0) {
      alert('ไม่สามารถดำเนินการได้ เนื่องจากเบิกไปมากกว่าที่มีอยู่ในคลัง');
    } else {
      selectedLot.push(materialStockLotElementPayload);
      setSelectedLot(selectedLot);
      setReload(!reload);
    }
  };

  // Handle Whilte click add button to without lot mode
  const handleFIFOAddToSelectedMaterial = (materialInfo, quantity) => {
    const materialStockLotElementPayload = {
      material: materialInfo,
      quantity,
      amount: materialInfo?.total - parseFloat(quantity),
    };

    if (materialStockLotElementPayload.amount < 0) {
      alert('ไม่สามารถดำเนินการได้ เนื่องจากเบิกไปมากกว่าที่มีอยู่ในคลัง');
    } else {
      selectedLot.push(materialStockLotElementPayload);
      setSelectedLot(selectedLot);
      setReload(!reload);
    }
  };

  const handleDeleteSelectedLot = (selectedIndex) => {
    try {
      const selectedLotWithIndex = _.map(
        selectedLot,
        (eachSelectedLot, index) => ({ ...eachSelectedLot, index }),
      );
      const newSelectedLot = _.filter(
        selectedLotWithIndex,
        (eachSelectLot) => eachSelectLot?.index !== selectedIndex,
      );

      setSelectedLot(newSelectedLot);
    } catch (error) {
      console.error('Cannot edit selected lot', error);
    }
  };

  const renderGeneralForm = () => (
    <div>
      <Controller
        control={control}
        name="material_transaction_type"
        rules={{ require: true }}
        defaultValue={filteredTxnType?.[0]?._id}
        render={({ field }) => (
          <FormControl fullWidth size="small" required>
            <InputLabel>ประเภทการขอเบิก</InputLabel>
            <Select
              size="small"
              required
              defaultValue=""
              {...field}
              label="ประเภทการขอเบิก"
            >
              {_.map(filteredTxnType, (_txntype, index) => (
                <MenuItem key={index} value={_txntype?._id}>
                  {_txntype?.name}{' '}
                </MenuItem>
              ))}
            </Select>
            {errors?.material_transaction_type && (
              <FormHelperText>กรุณาเลือกประเภทการขอเบิก</FormHelperText>
            )}
          </FormControl>
        )}
      />

      <div className="my-2">
        <Controller
          control={control}
          name="remark"
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              multiline
              rows={2}
              fullWidth
              label="หมายเหตุ"
            />
          )}
        />{' '}
      </div>
    </div>
  );

  // In Withlot mode render Lot of each material
  const renderEachMaterialLot = (selectedMaterial, materialIndex) => (
    <div className="py-2">
      <div className="rounded-md p-2 bg-gray-200 font-display w-full ">
        {selectedMaterial?.type_code} {selectedMaterial?.name}
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 my-2 gap-1">
        {_.map(selectedMaterial?.stocklots, (eachLot, index) => (
          <div key={`ML${index}`} className="bg-gray-100 p-2 rounded-md ">
            <div className="text-sm">หมายเลขล็อต {eachLot?.lot_number}</div>
            <div className="my-1 font-semibold">
              คงเหลือ{' '}
              <MultiUnitProdOrMatBox
                foundMaterial={selectedMaterial}
                quantity={eachLot?.amount}
              />{' '}
            </div>
            <div>
              <ProdOrMatQuantityInput
                controllerName={`tempSelect[${materialIndex}][${index}].quantity`}
                artificialControllerName={`tempSelect[${materialIndex}][${index}].quantity_artificial`}
                selectedUnitControllerName={`tempSelect[${materialIndex}][${index}].selected_unit`}
                setValue={setValue}
                watch={watch}
                control={control}
                selectedProduct={selectedMaterial}
                index={index}
              />
            </div>
            <div>
              <Button
                type="button"
                variant="contained"
                size="small"
                onClick={() =>
                  handleAddToSelectedMaterial(
                    selectedMaterial,
                    watch(`tempSelect[${materialIndex}][${index}].quantity`),
                    eachLot,
                  )
                }
              >
                เพิ่ม
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderMaterialLotSelect = () => (
    <div className="my-4">
      {!_.isEmpty(materials?.rows) &&
        _.map(materials?.rows, (eachMaterial, materialIndex) => (
          <div key={materialIndex}>
            {renderEachMaterialLot(eachMaterial, materialIndex)}
          </div>
        ))}
    </div>
  );

  const renderMaterialWithoutLotSelect = () => (
    <div>
      <div className="w-full grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 my-2 gap-1">
        {_.map(materials?.rows, (eachMaterial, index) => (
          <div
            key={`selected-lot-${index}`}
            className="bg-gray-100 p-2 m-2 rounded-md"
          >
            {eachMaterial?.name}
            <div className="text-sm">คงเหลือ </div>
            <MultiUnitProdOrMatBox
              foundMaterial={eachMaterial}
              noWrap
              quantity={eachMaterial?.total}
            />
            <div className="my-2">
              <ProdOrMatQuantityInput
                controllerName={`tempSelect[${index}].quantity`}
                artificialControllerName={`tempSelect[${index}].quantity_artificial`}
                selectedUnitControllerName={`tempSelect[${index}].selected_unit`}
                setValue={setValue}
                watch={watch}
                control={control}
                selectedProduct={eachMaterial}
                index={index}
              />
            </div>
            <div>
              <Button
                variant="contained"
                size="small"
                onClick={() =>
                  handleFIFOAddToSelectedMaterial(
                    eachMaterial,
                    watch(`tempSelect[${index}].quantity`),
                  )
                }
              >
                เพิ่ม
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderSelectMaterialDisplay = () => (
    <div>
      <div className="my-2 font-semibold">รายการวัตถุดิบที่เลือก</div>
      {_.isEmpty(selectedLot) && (
        <div className="text-center p-4 ">ยังไม่มีรายการวัตถุดิบที่เลือก</div>
      )}
      <div className="w-full grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 my-2 gap-1">
        {!_.isEmpty(selectedLot) &&
          _.map(selectedLot, (eachSelectedLot, index) => (
            <div
              key={`selected-lot-${index}`}
              className="bg-gray-100 p-2 m-2 rounded-md"
            >
              {index + 1}. {eachSelectedLot?.material?.type_code}{' '}
              {eachSelectedLot?.material?.name}
              {eachSelectedLot?.lotId && (
                <div className="text-sm">
                  หมายเลขล็อต {eachSelectedLot?.lotId?.lot_number || '-'}
                </div>
              )}
              <div className="my-1 font-semibold">จำนวน</div>
              <MultiUnitProdOrMatBox
                foundMaterial={eachSelectedLot?.material}
                quantity={eachSelectedLot?.quantity}
                noWrap
              />
              <div className="my-2">
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  type="button"
                  onClick={() => {
                    handleDeleteSelectedLot(index);
                  }}
                >
                  ลบ
                </Button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );

  return (
    <div>
      {renderSelectMaterialDisplay()}
      <hr />
      {imsSetting?.usingFIFO
        ? renderMaterialWithoutLotSelect()
        : renderMaterialLotSelect()}
      {renderGeneralForm()}
    </div>
  );
};

MaterialSearchAndWithdrawForm.propTypes = {
  materialTransactionType: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  errors: PropTypes.object,
  control: PropTypes.object,
  selectedLot: PropTypes.arrayOf(PropTypes.object),
  setSelectedLot: PropTypes.func,
  materials: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  watch: PropTypes.func,
  setValue: PropTypes.func,
  reload: PropTypes.bool,
  setReload: PropTypes.func,
};

export default MaterialSearchAndWithdrawForm;
