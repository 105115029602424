import DetailMaterialReportDashboard from './DetailMaterialReportDashboard';
import MaterialReportDashboard from './MaterialReportDashboard';
import MaterialSummaryReport from './MaterialSummaryReport';
import ReportDisplay from './ReportDisplay';

export {
  DetailMaterialReportDashboard,
  MaterialReportDashboard,
  MaterialSummaryReport,
  ReportDisplay,
};

export default {
  DetailMaterialReportDashboard,
  MaterialReportDashboard,
  MaterialSummaryReport,
  ReportDisplay,
};
