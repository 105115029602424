import {
  PRODUCT_ALL,
  PRODUCT_DEL,
  PRODUCT_ERROR,
  PRODUCT_GET,
  PRODUCT_LOADING,
  PRODUCT_POST,
  PRODUCT_PUT,
} from '../../actions/types';

const initialState = {
  product: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case PRODUCT_LOADING:
      return { isLoading: true, isCompleted: true };
    case PRODUCT_ERROR:
      return { isLoading: false, isCompleted: false, ...action.payload };
    case PRODUCT_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PRODUCT_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PRODUCT_POST:
      return { isLoading: false, isCompleted: true };
    case PRODUCT_PUT:
      return { isLoading: false, isCompleted: true };
    case PRODUCT_DEL:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
