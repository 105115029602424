import React from 'react';
import { Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

const ConveyorSketchGeneraror = ({
  width = 1,
  length = 1,
  numberOfParallel = 1,
  maxWidth = 860,
  basedWidth = 48,
  referencePoint = 0,
  failurePoint,
  children,
}) => {
  const lengthPerWidthRate = length / width;
  const parallelArray = Array(numberOfParallel >= 1 ? numberOfParallel : 1);

  const referenceBoxWidth = (basedWidth * lengthPerWidthRate) / referencePoint;
  console.log('Reference Box Width', referenceBoxWidth);

  return (
    <div>
      <div className="w-full flex justify-center">
        <Typography color="primary">ยาว {length.toFixed(2)} เมตร</Typography>
      </div>
      {_.map(parallelArray, (eachItem, index) => (
        <div className="flex gap-2" key={index}>
          <div className="self-center">
            <Typography color="primary">{width.toFixed(2)} เมตร</Typography>
          </div>
          <div>
            <div
              className="border-2  border-gray-600 max-w-screen-lg "
              style={{
                height: basedWidth,
                width: basedWidth * lengthPerWidthRate,
                maxWidth,
              }}
            >
              {children}
              <div className="flex ">
                {referencePoint !== 0 &&
                  _.map(Array(referencePoint), (each, refPointIndex) => (
                    <div
                      className="border-l border-red-400  hover:border-red-500 hover:border-l-8"
                      style={{
                        height: basedWidth,
                        width:
                          (basedWidth * lengthPerWidthRate) / referencePoint,
                        maxWidth,
                      }}
                    >
                      {index + 1 === numberOfParallel ? (
                        <div className="text-xs relative flex my-8">
                          <div className="bg-red-500 p-2 text-white rounded-md z-2">
                            <b>{refPointIndex + 1}</b>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

ConveyorSketchGeneraror.propTypes = {
  width: PropTypes.number,
  length: PropTypes.number,
  numberOfParallel: PropTypes.number,
  maxWidth: PropTypes.number,
  basedWidth: PropTypes.number,
  children: PropTypes.object,
  referencePoint: PropTypes.number,
};

export default ConveyorSketchGeneraror;
