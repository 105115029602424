import React from 'react';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';

export function PurchaseOrderForm({
  errors,
  Controller,
  control,
  purchaseOrder,
  customer,
  // paymentType,
}) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'bill_number'}
          control={control}
          rules={{ required: false }}
          defaultValue={
            purchaseOrder?.bill_number ? `${purchaseOrder?.bill_number}` : ''
          }
          render={({ field }) => (
            <TextField
              {...field}
              label="เลขที่บิล"
              fullWidth
              size={'small'}
              helperText={errors.bill_number && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      {/* <div className="w-full px-1 py-2 self-end">
        <Controller
          name={'payment'}
          control={control}
          rules={{ required: false }}
          defaultValue={
            purchaseOrder?.payment ? purchaseOrder?.payment?._id : ''
          }
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true} required>
              <InputLabel id="type" size={'small'}>
                ประเภทการชำระเงิน
              </InputLabel>
              <Select
                {...field}
                label="ประเภทการชำระเงิน"
                size={'small'}
                fullWidth
              >
                {_.size(paymentType) ? (
                  _.map(paymentType, (_paymentType, index) => (
                    <MenuItem key={index} value={_paymentType.id}>
                      {_paymentType.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div> */}
      <div className="w-full px-1 py-2">
        <Controller
          name={'supplier'}
          control={control}
          defaultValue={
            purchaseOrder?.supplier ? purchaseOrder?.supplier?._id : ''
          }
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true} required>
              <InputLabel id="type" size={'small'}>
                คู่ค้า
              </InputLabel>
              <Select {...field} label="คู่ค้า" size={'small'} fullWidth>
                {_.size(customer) ? (
                  _.map(customer, (_customer) => (
                    <MenuItem key={_customer.id} value={_customer.id}>
                      {_customer.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'source'}
          control={control}
          defaultValue={purchaseOrder?.source ? purchaseOrder?.source : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="แหล่งวัตถุดิบ"
              fullWidth
              size={'small'}
              helperText={errors.source && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-full px-1 py-2">
        <Controller
          name={'remark'}
          control={control}
          defaultValue={purchaseOrder?.remark ? purchaseOrder?.remark : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="หมายเหตุ"
              fullWidth
              size={'small'}
              multiline={true}
              rows={3}
              helperText={errors.remark && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
    </div>
  );
}

PurchaseOrderForm.propTypes = {
  errors: PropTypes.shape({
    bill_number: PropTypes.object,
    // payment: PropTypes.object,
    source: PropTypes.object,
    supplier: PropTypes.object,
    remark: PropTypes.object,
  }),
  purchaseOrder: PropTypes.object,
  customer: PropTypes.object,
  // paymentType: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
};

PurchaseOrderForm.defaultProps = {
  purchaseOrder: null,
  customer: null,
  // paymentType: null,
};

export default PurchaseOrderForm;
