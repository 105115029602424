import React from 'react';
import { ExternalLink as ExternalLinkIcon } from 'react-feather';
import { QUOTATION_STATUS } from '@iarcpsu/emanufac-constant';
import PropTypes from 'prop-types';

const MiniBoxQuotationStatus = ({ quotationAnalytic }) => (
  <div>
    <div className="w-full my-2">
      <div className="grid grid-cols-5">
        <div>
          <h4 className="">ทั้งหมด</h4>
          <div>
            <span className="text-2xl mr-1">{quotationAnalytic.total}</span>
            <span>รายการ</span>
          </div>
        </div>{' '}
        <div>
          <h4 className=" flex gap-1 items-center">
            {QUOTATION_STATUS.IN_PROGRESS.description}
          </h4>
          <div>
            <span className="text-2xl mr-1">
              {quotationAnalytic[QUOTATION_STATUS.IN_PROGRESS.status_code]}
            </span>
            <span>รายการ</span>
          </div>
        </div>
        <div>
          <h4 className=" flex gap-1 items-center">
            {QUOTATION_STATUS.SUCCESS.description}
          </h4>
          <div>
            <span className="text-2xl mr-1">
              {quotationAnalytic[QUOTATION_STATUS.SUCCESS.status_code]}
            </span>
            <span>รายการ</span>
          </div>
        </div>
        <div>
          <h4 className=" flex gap-1 items-center">
            {QUOTATION_STATUS.CANCLE.description}
          </h4>

          <div>
            <span className="text-2xl mr-1">
              {quotationAnalytic[QUOTATION_STATUS.CANCLE.status_code]}
            </span>
            <span>รายการ</span>
          </div>
        </div>
        <div>
          <h4 className=" flex gap-1 items-center">
            {QUOTATION_STATUS.REVISED.description}
          </h4>

          <div>
            <span className="text-2xl mr-1">
              {quotationAnalytic[QUOTATION_STATUS.REVISED.status_code]}
            </span>
            <span>รายการ</span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default MiniBoxQuotationStatus;

MiniBoxQuotationStatus.propTypes = {
  quotationAnalytic: PropTypes.object,
};
