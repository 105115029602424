import {
  PURCHASE_ORDER_ALL,
  PURCHASE_ORDER_GET,
  PURCHASE_ORDER_DEL,
  PURCHASE_ORDER_PUT,
  PURCHASE_ORDER_POST,
  PURCHASE_ORDER_LOADING,
  PURCHASE_ORDER_ERROR,
} from '../types';

import api from '../../../configs/api';

export const purchaseOrderCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PURCHASE_ORDER_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/purchase-order`,
      {
        ...payload,
      },
    );
    dispatch({ type: PURCHASE_ORDER_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PURCHASE_ORDER_ERROR });
    throw new Error(error);
  }
};

export const purchaseOrderAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1, statusCode = '' } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/purchase-order?name=${name}&size=${size}&page=${page}&statusCode=${statusCode}`,
    );
    if (status === 200) {
      dispatch({ type: PURCHASE_ORDER_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PURCHASE_ORDER_ERROR });
    throw new Error(error);
  }
};

export const purchaseOrderGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/purchase-order/${id}`,
    );
    if (status === 200) {
      dispatch({ type: PURCHASE_ORDER_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PURCHASE_ORDER_ERROR });
    throw new Error(error);
  }
};

export const purchaseOrderStateSet = (data) => async (dispatch) => {
  dispatch({ type: PURCHASE_ORDER_ALL, payload: data });
};

export const purchaseOrderPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: PURCHASE_ORDER_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/purchase-order/${id}`,
      payload,
    );
    dispatch({ type: PURCHASE_ORDER_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PURCHASE_ORDER_ERROR });
    throw new Error(error);
  }
};
export const purchaseOrderDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: PURCHASE_ORDER_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/purchase-order/${id}`,
    );
    dispatch({ type: PURCHASE_ORDER_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PURCHASE_ORDER_ERROR });
    throw new Error(error);
  }
};
