import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { config } from '@/configs';
import * as actions from '@/redux/actions';
import { DATA_TYPE } from '@/utils/constants';

/**
 * @function ArrangeProcessTemplateMeasurement
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function ArrangeProcessTemplateMeasurement({ title, subtitle }) {
  const dispatch = useDispatch();
  const processTemplate = useSelector((state) => state.processTemplate);
  const history = useHistory();
  const params = useParams();
  const [arragedMeasurement, setArragedMeasurement] = useState([]);

  const stepIndex = parseInt(params.index, 10);

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.processTemplateGet(params.id));
    } catch (error) {
      dispatch(actions.processTemplateError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  useEffect(() => {
    const stepPayload = processTemplate?.steps?.[stepIndex];
    const measurementTypes = stepPayload?.measurementTypes;
    const tempArrangementPayload = _.map(
      measurementTypes,
      (measurementType, index) => ({ index, measurementType }),
    );

    setArragedMeasurement(tempArrangementPayload);

    return () => {};
  }, [processTemplate]);

  // Reference https://codesandbox.io/s/k260nyxq9v?file=/index.js:373-567
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onUpdateProcessMeasurement = async () => {
    const stepPayload = processTemplate?.steps?.[stepIndex];
    const measurmentType = _.map(
      arragedMeasurement,
      (each) => each?.measurementType,
    );
    stepPayload.measurementTypes = measurmentType;
    const payload = {
      steps: [
        ...processTemplate?.steps?.slice(0, stepIndex),
        stepPayload,
        ...processTemplate?.steps?.slice(stepIndex + 1),
      ],
    };

    console.log('Payload', payload);
    await dispatch(actions.processTemplatePut(params.id, payload));
    history.push('/mms/process-template/');
  };

  const handleDragEnd = (result) => {
    console.log('Result', result);
    if (!result?.destination) {
      return;
    }

    const newOrdered = reorder(
      arragedMeasurement,
      result?.source?.index,
      result?.destination?.index,
    );
    setArragedMeasurement(newOrdered);
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (processTemplate.isLoading) {
    return <Loading />;
  }

  if (!processTemplate.isLoading && processTemplate.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex justify-between">
          <BackButton />
          <div>
            <Button variant="contained" onClick={onUpdateProcessMeasurement}>
              บันทึก
            </Button>
          </div>
        </div>
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="flex flex-wrap">
                <div className="w-1/5 font-semibold">สูตรการผลิต:</div>
                <div className="w-4/5 font-semibold">
                  {processTemplate?.name}
                </div>
                <div className="w-1/5 font-semibold">ขั้นตอนที่:</div>
                <div className="w-4/5">
                  {stepIndex + 1}. {processTemplate?.steps?.[stepIndex]?.name}
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="my-2">
          <Card>
            <CardContent>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="ptm-dropable">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {_.map(arragedMeasurement, (item, index) => (
                        <Draggable
                          key={item.measurementType._id}
                          draggableId={item.measurementType._id}
                          index={index}
                        >
                          {(dragableProvided) => (
                            <div
                              {...dragableProvided.draggableProps}
                              {...dragableProvided.dragHandleProps}
                              ref={dragableProvided.innerRef}
                            >
                              <div className="border rounded-md p-4 m-2">
                                {item.measurementType.name}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

ArrangeProcessTemplateMeasurement.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ArrangeProcessTemplateMeasurement.defaultProps = {
  title: '',
  subtitle: '',
};
