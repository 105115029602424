import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  BackButton,
  Error,
  Loading,
  LoadingDialogWithTimeout,
  MoveMaterialForm,
  SocketIOLoading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Card, CardContent } from '@mui/material';
import _ from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';

import { api, app as appConfig } from '@/configs';
import * as actions from '@/redux/actions';
import { MATERIAL_STOCK_STATUS, MQ_TASK } from '@/utils/constants';
import { useQuery } from '@/utils/functions';

function MoveMaterial({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const place = useSelector((state) => state.place);
  const material = useSelector((state) => state.material);
  const { id } = useParams();
  const [currentPlace, setCurrentPlace] = useState();
  const [dialogLoadingOn, setDialogLoadingOn] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState();
  const [statusLabel, setStatusLabel] = useState('');
  const query = useQuery();
  const getCurrentPlace = async () => {
    try {
      const res = await api.get(`${process.env.REACT_APP_API_URL}/place/${id}`);
      setCurrentPlace(res.data);
    } catch (error) {
      console.error('Cannot Gete Current Place', error?.message);
    }
  };

  const foundPlace = _.find(
    place?.rows,
    (each) => each?._id === query.get('destination'),
  );

  const { control, watch, setValue, handleSubmit } = useForm({
    defaultValues: {
      place: foundPlace?._id,
    },
  });

  useEffect(() => {
    const tempRef = hash({ id, date: new Date() });
    setReferenceNumber(tempRef);
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(actions.placeAll({ page: 1, size: appConfig.maxFetchSize }));
    getCurrentPlace();
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(actions.materialReset());
    dispatch(
      actions.materialAll({
        name: '',
        size: appConfig.maxFetchSize,
        page: 1,
        place: id,
        fetchStockLot: true,
        stockStatus: MATERIAL_STOCK_STATUS.IN_STOCK.status_code,
        forceWithoutCache: false,
      }),
    );
    return () => {};
  }, []);

  const handleMoveMaterial = async (data) => {
    try {
      setDialogLoadingOn(true);
      dispatch(actions.moveMaterialStockLot(id, { ...data, referenceNumber }));
    } catch (error) {
      console.error('Cannot Move Material', error?.message);
      alert(`ไม่สามารถย้ายวัตถุดิบได้ ${error?.message}`);
    }
  };

  const renderTitle = () => (
    <ViewTitle title={`${title} ${currentPlace?.name}`} subtitle={subtitle} />
  );

  if (
    !material?.isLoading &&
    !material?.isCompleted &&
    !place?.isLoading &&
    !place?.isCompleted
  ) {
    return <Error message={material?.message} />;
  }

  if (place.isLoading || material.isLoading || !place?.rows) {
    return <Loading />;
  }

  if (
    query.get('destination') &&
    query.get('destination') !== 0 &&
    !foundPlace
  ) {
    return <div>ไม่พบคลังเป้าหมาย</div>;
  }

  return (
    <div>
      {renderTitle()}
      <LoadingDialogWithTimeout
        action="REFRESH"
        label={statusLabel}
        isLoading={dialogLoadingOn}
      />
      <SocketIOLoading
        handleFail={() => {
          setDialogLoadingOn(false);
          alert('ดำเนินการไม่สำเร็จ');
        }}
        handleSuccess={() => {
          setDialogLoadingOn(false);
          alert('สำเร็จ');
          history.goBack();
        }}
        referenceNumber={referenceNumber}
        setStatusMessage={setStatusLabel}
        taskCode={MQ_TASK.MOVE_MATERIAL.status_code}
      />
      <div className="flex justify-between">
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
      </div>
      <div>
        <Card>
          <CardContent>
            <MoveMaterialForm
              material={material}
              place={place}
              handleMoveMaterial={handleMoveMaterial}
              currentPlaceId={id}
              lockDestinationPlace={
                query.get('destination') && query.get('lockplace') === 'true'
              }
              defaultPlace={foundPlace}
              control={control}
              handleSubmit={handleSubmit}
              setValue={setValue}
              watch={watch}
            />
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

MoveMaterial.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MoveMaterial.defaultProps = {
  title: '',
  subtitle: '',
};

export default MoveMaterial;
