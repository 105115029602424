const PRODUCT_STOCK_STATUS = {
  IN_STOCK: {
    status_code: "IN_STOCK",
    description: "มีสินค้า",
  },
  OUT_STOCK: {
    status_code: "OUT_STOCK",
    description: "สินค้าหมด",
  },
};

module.exports = PRODUCT_STOCK_STATUS;
