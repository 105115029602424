import PropTypes from 'prop-types';

export const Material = PropTypes.shape({
  id: PropTypes.string,
  type_code: PropTypes.string,
  name: PropTypes.string,
  unit: PropTypes.string,
  material_type: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  minimum_amount: PropTypes.number,
  net: PropTypes.number,
  unit_net: PropTypes.string,
  detail: PropTypes.string,
  stocklots: PropTypes.object,
});

export const MaterialState = PropTypes.shape({
  rows: PropTypes.arrayOf(Material),
  currPage: PropTypes.number,
  isComplete: PropTypes.bool,
  isLoading: PropTypes.bool,
  lastPage: PropTypes.number,
});

export default { Material, MaterialState };
