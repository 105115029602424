import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

import PropTypes from 'prop-types';

const SwitchAdditionalInput = ({ control, defaultValue }) => (
  <div className="flex flex-wrap w-full">
    <div className="w-full lg:w-1/2 py-1 ">
      <Controller
        control={control}
        name="label_left"
        defaultValue={defaultValue?.label_left}
        render={({ field }) => (
          <TextField
            {...field}
            size="small"
            label="ข้อความด้านซ้าย"
            fullWidth
          />
        )}
      />
    </div>
    <div className="w-full lg:w-1/2 py-1 ">
      <Controller
        control={control}
        name="label_right"
        defaultValue={defaultValue?.label_right}
        render={({ field }) => (
          <TextField {...field} size="small" label="ข้อความด้านขวา" fullWidth />
        )}
      />
    </div>
  </div>
);

SwitchAdditionalInput.propTypes = {
  control: PropTypes.object,
  defaultValue: PropTypes.object,
};

export default SwitchAdditionalInput;
