import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  BackButton,
  FactoryCapitalCostForm,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import * as actions from '@/redux/actions';

/**
 * @function CreatePriceLaborCost
 * @description Display a collections or a list of CreatePriceLaborCost from database
 */

export default function CreateFactoryCapitalCost({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleSubmit, watch, control } = useForm({});

  const handleCreatePlaceLaborCost = async (data) => {
    try {
      await dispatch(actions.factoryCapitalCostCreate(data));
      Swal.fire({
        icon: 'success',
        title: 'สร้างรายการใหม่สำเร็จ',
      }).then(() => {
        history.goBack();
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'ไม่สามารถสร้างรายการใหม่ได้',
        text: error?.message,
      });
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  return (
    <div>
      {renderTitle()}
      <BackButton />
      <div className="my-2">
        <form onSubmit={handleSubmit(handleCreatePlaceLaborCost)}>
          <FactoryCapitalCostForm control={control} watch={watch} />
          <div className="flex justify-end my-2">
            <Button type="submit" variant="contained">
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

CreateFactoryCapitalCost.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateFactoryCapitalCost.defaultProps = {
  title: '',
  subtitle: '',
};
