import {
  MATERIAL_REQUEST_ALL,
  MATERIAL_REQUEST_GET,
  MATERIAL_REQUEST_DEL,
  MATERIAL_REQUEST_PUT,
  MATERIAL_REQUEST_POST,
  MATERIAL_REQUEST_LOADING,
  MATERIAL_REQUEST_ERROR,
} from '../types';

import api from '../../../configs/api';

export const materialRequestCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_REQUEST_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/material-request`,
      {
        ...payload,
      },
    );
    dispatch({ type: MATERIAL_REQUEST_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_REQUEST_ERROR });
    throw new Error(error);
  }
};

export const materialRequestAll = (params) => async (dispatch) => {
  try {
    const {
      name = '',
      size = '',
      page = 1,
      statusCode = '',
      startDate = '',
      endDate = '',
    } = params;

    const { data, status } = await api.get(
      // eslint-disable-next-line camelcase
      `${process.env.REACT_APP_API_URL}/material-request?name=${name}&size=${size}&page=${page}&statusCode=${statusCode}&startDate=${startDate}&endDate=${endDate}`,
    );
    if (status === 200) {
      dispatch({ type: MATERIAL_REQUEST_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_REQUEST_ERROR });
    throw new Error(error);
  }
};

export const materialRequestGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/material-request/${id}`,
    );
    if (status === 200) {
      dispatch({ type: MATERIAL_REQUEST_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_REQUEST_ERROR });
    throw new Error(error);
  }
};

export const materialRequestStateSet = (data) => async (dispatch) => {
  dispatch({ type: MATERIAL_REQUEST_ALL, payload: data });
};

export const materialRequestPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_REQUEST_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/material-request/${id}`,
      payload,
    );
    dispatch({ type: MATERIAL_REQUEST_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_REQUEST_ERROR });
    throw new Error(error);
  }
};
export const materialRequestDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_REQUEST_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/material-request/${id}`,
    );
    dispatch({ type: MATERIAL_REQUEST_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_REQUEST_ERROR });
    throw new Error(error);
  }
};
