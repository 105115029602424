import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import ProdOrMatQuantityInput from '../../Inputs/ProdOrMatQuantityInput';

function RecieveMaterialForm({
  control,
  watch,
  setValue,
  selectedMaterial,
  defaultValue,
  customer,
  errors,
  place,
  materials,
}) {
  return (
    <div className="flex flex-wrap">
      <div className="w-full p-1 my-1 font-semibold">คู่ค้าที่นำเข้า</div>
      <div className="w-full py-2 px-1">
        <Controller
          name="input_source"
          rules={{ required: true }}
          defaultValue={defaultValue?.input_source || null}
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel
                label="รับจากคู่ค้า"
                value="FROM_SUPPLIER"
                control={<Radio />}
              />
              <FormControlLabel
                label="ผลิตเอง"
                value="MYSELF"
                control={<Radio />}
              />
            </RadioGroup>
          )}
        />
        {errors?.input_source && (
          <small className="text-gray-500">
            กรุณาเลือกวิธีนำเข้าอย่างใดอย่างหนึ่ง
          </small>
        )}
      </div>
      {watch('input_source') === 'FROM_SUPPLIER' && !_.isEmpty(customer?.rows) && (
        <div className="w-full py-2 px-1">
          <Controller
            name="supplier"
            defaultValue={defaultValue?.supplier || null}
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                disablePortal
                options={customer?.rows}
                size="small"
                placeholder="เลือกคู่ค้า"
                onChange={(e, newValue) => {
                  field.onChange(newValue);
                }}
                className="z-40"
                // prettier-ignore
                getOptionLabel={(option) => `${option?.type_code || ''} ${option?.name || ''}`
               }
                renderInput={(params) => (
                  <TextField label="เลือกคู่ค้า" {...params} />
                )}
              />
            )}
          />
        </div>
      )}
      <div className="w-full p-1 my-1 font-semibold">เก็บมาไว้ที่คลัง</div>
      <div className="w-full lg:w-3/6 p-1 ">
        <Controller
          control={control}
          name="place"
          defaultValue={place?.rows?.[0]?._id}
          render={({ field }) => (
            <FormControl fullWidth size="small">
              <InputLabel>คลัง</InputLabel>
              <Select size="small" {...field} fullWidth label="คลัง">
                {_.map(place?.rows, (each) => (
                  <MenuItem key={each?._id} value={each?._id}>
                    {each?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </div>

      <div className="w-full p-1 my-1 font-semibold">วัตถุดิบที่รับเข้ามา</div>
      <div className="w-full lg:w-3/6 p-1 self-center">
        {selectedMaterial ? (
          <div>
            <Controller
              control={control}
              name="material"
              defaultValue={selectedMaterial?._id}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel>วัตถุดิบ</InputLabel>
                  <Select
                    fullWidth
                    disabled
                    label="วัตถุดิบ"
                    size="small"
                    {...field}
                  >
                    <MenuItem value={selectedMaterial?._id}>
                      {selectedMaterial?.name}
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </div>
        ) : (
          <div>
            <div className="my-2">เลือกวัตถุดิบ</div>
            {!_.isEmpty(materials?.rows) && (
              <Controller
                control={control}
                name="material"
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    options={materials?.rows}
                    size="small"
                    placeholder="เลือกวัตถุดิบ"
                    onChange={(e, newValue) => {
                      field.onChange(newValue);
                    }}
                    className="z-40"
                    // prettier-ignore
                    getOptionLabel={(option) => `${option?.type_code || ''} ${option?.name || ''}`
          }
                    renderInput={(params) => (
                      <TextField label="เลือกวัตถุดิบ" {...params} />
                    )}
                  />
                )}
              />
            )}
          </div>
        )}
      </div>
      <div className="w-full lg:w-3/6 p-1">
        จำนวน
        <div className="my-2">
          <ProdOrMatQuantityInput
            controllerName="quantity"
            artificialControllerName="quantity_artificial"
            selectedUnitControllerName="quantity_unit"
            watch={watch}
            setValue={setValue}
            selectedProduct={selectedMaterial || watch('material')}
            control={control}
            defaultValue={defaultValue?.quantity}
          />
        </div>
      </div>
    </div>
  );
}

export default RecieveMaterialForm;

RecieveMaterialForm.propTypes = {
  control: PropTypes.object,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  selectedMaterial: PropTypes.object,
  defaultValue: PropTypes.object,
  customer: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  place: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  errors: PropTypes.object,
};
