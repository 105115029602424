import React from 'react';
import { Controller } from 'react-hook-form';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { MATERIAL_COMING_METHOD } from '@/utils/constants';

function MaterialComingMethodForm({ control, index, fieldInfo, watch }) {
  return (
    <div className="w-full flex flex-wrap">
      <div className="w-full p-1 py-2 font-semibold">
        การนำวัตถุเข้าสู่กระบวนการการผลิต
      </div>

      <div className="w-full p-2">
        <Controller
          name={`steps[${index}].material_incoming_method`}
          defaultValue={fieldInfo?.material_incoming_method}
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              {_.map(MATERIAL_COMING_METHOD, (each, eachIndex) => (
                <FormControlLabel
                  label={
                    <div className="my-1">
                      <div>{each.description}</div>
                      <div className="text-xs">{each?.info} </div>
                    </div>
                  }
                  value={each.status_code}
                  key={eachIndex}
                  control={<Radio />}
                />
              ))}
            </RadioGroup>
          )}
        />
      </div>

      {watch(`steps[${index}].material_incoming_method`) ===
        MATERIAL_COMING_METHOD.SPLITTING.status_code && (
        <div className="w-full p-2"></div>
      )}
    </div>
  );
}

export default MaterialComingMethodForm;

MaterialComingMethodForm.propTypes = {
  control: PropTypes.object,
  index: PropTypes.number,
  fieldInfo: PropTypes.object,
  watch: PropTypes.func,
};
