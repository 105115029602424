import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import emanufacList from '../../../assets/images/logo-list-diprom.png';

function SidemenuBox({ information, me }) {
  const renderDayPeriod = () => {
    if (dayjs().get('hour') >= 6 && dayjs().get('hour') < 12) {
      return 'ตอนเช้า';
    }
    if (dayjs().get('hour') >= 12 && dayjs().get('hour') < 16) {
      return 'ตอนบ่าย';
    }
    if (dayjs().get('hour') >= 16 && dayjs().get('hour') < 19) {
      return 'ตอนเย็น';
    }
    if (dayjs().get('hour') >= 20) {
      return 'ตอนค่ำ';
    }
    if (dayjs().get('hour') <= 4) {
      return 'ตอนดึก';
    }
    return 'ตอนหัวรุ่ง';
  };

  return (
    <div className=" text-center lg:text-right lg:mt-60">
      {information?.logo?.url ? (
        <div className="flex md:justify-end justify-center">
          <img src={information?.logo?.url} className="my-2 h-24" />
        </div>
      ) : (
        <div className="text-xl font-semibold">{information?.name}</div>
      )}
      <div className="mt-4">
        <div className="my-2">
          สวัสดี
          {renderDayPeriod()}{' '}
          <span className="font-semibold">{me?.userData?.firstname}</span>{' '}
        </div>
        <div>
          สนับสนุนโดย ศูนย์ส่งเสริมอุตสาหกรรมภาคที่ 11 กรมส่งเสริมอุตสาหกรรม
        </div>
        <div className="flex md:justify-end justify-center my-4">
          <img src={emanufacList || null} className="h-10 md:h-14" />
        </div>
      </div>
    </div>
  );
}

export default SidemenuBox;

SidemenuBox.propTypes = {
  information: PropTypes.object,
  me: PropTypes.object,
};
