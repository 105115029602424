import Swal from 'sweetalert2';

import api from '../../../configs/api';
import {
  getStorage,
  removeStorage,
} from '../../../utils/functions/localstorage';
import { ME_GET, ME_RESET } from '../types';

export const meGet = () => async (dispatch) => {
  const rememeber = JSON.parse(getStorage('remember'));
  try {
    const { data } = await api.get(
      `${process.env.REACT_APP_API_URL}/user/${rememeber.uid.id}`,
    );
    dispatch({
      type: ME_GET,
      payload: data,
    });
  } catch (error) {
    console.error(error);
    await removeStorage('token');
    await removeStorage('remember');

    Swal.fire({
      title: 'เกิดข้อผิดพลาด',
      text:
        'ระบบขาดการเชื่อมต่อกับเครื่องเซิฟเวอร์ กรุณารีเฟรชหน้าแล้วลองใหม่อีกครั้ง',
      icon: 'error',
    });
    // window.location.reload();
    dispatch({ type: ME_RESET });
    throw new Error(error);
  }
};

export const meReset = () => async (dispatch) => {
  dispatch({ type: ME_RESET, payload: null });
};
