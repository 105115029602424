import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import dayjs from 'dayjs';

import * as actions from '@/redux/actions';

function SystemSlowModal({ open, setOpen }) {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const me = useSelector((state) => state.me);
  const dispatch = useDispatch();

  const handleSubmitProblem = async () => {
    try {
      await dispatch(
        actions.feedbackCreate({
          employee: me?.userData?._id,
          department: me?.userData?.department?._id,
          feedback_type: 'SYSTEM_SLOW',
          page: window.location.href,
          createdAt: dayjs().toDate(),
        }),
      );
      setSubmitSuccess(true);
    } catch (error) {
      alert(error?.message);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>แจ้งปัญหาระบบช้า</DialogTitle>
        {submitSuccess ? (
          <div>
            <DialogContent>
              <div className="text-center">
                <i className="fas fa-check-circle text-5xl text-green-500"></i>
                <div className="text-xl">
                  เราได้แจ้งปัญหาไปยังผู้ดูแลระบบเรียบร้อยแล้ว
                </div>
                <div className="text-sm my-2">
                  ขอบคุณสำหรับการแจ้งปัญหา พวกเราขออภัยในความไม่สะดวก
                  และจำดำเนินการแก้ให้เร็วที่สุด
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => {
                  setOpen(false);
                  setSubmitSuccess(false);
                }}
              >
                ปิด
              </Button>
            </DialogActions>
          </div>
        ) : (
          <div>
            <DialogContent>
              <p>
                <b>เวลาที่พบปัญหา :</b> {dayjs().format('DD/MM/YYYY HH:mm')}
              </p>
              <p>
                <b>แผนก :</b> {me?.userData?.department?.name}
              </p>
              <p>
                <b>หน้า :</b> {window.location.href}
              </p>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={() => handleSubmitProblem()}>
                แจ้งปัญหา
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    </div>
  );
}

export default SystemSlowModal;
