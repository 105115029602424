export const TRANSACTION_TYPE = {
  add: {
    status_code: 'add',
    description: 'เพิ่มเข้า',
  },
  desc: {
    status_code: 'desc',
    description: 'นำออก',
  },
};

export default TRANSACTION_TYPE;
