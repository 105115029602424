import React from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import MeasurementAddingRenderingCompanent from '../../Measurement/MeasurementAddingRenderingComponent';

/**
 * @function BaseProjectForm
 * @memberof Form/MMS
 * @description ฟอร์มสำหรับเพิ่ม และแก้ไข โปรเจกต์ สินค้าพื้นฐาน
 * @returns {React.Component} React Component
 */

const BaseProjectForm = ({
  control,
  errors,
  baseProject,
  watch,
  bomTemplate,
  processTemplate,
  setValue,
}) => {
  const information = useSelector((state) => state.information);

  return (
    <div className="flex flex-wrap">
      <div className="w-full py-2 px-1">
        <Controller
          name="type_code"
          defaultValue={baseProject?.type_code || ''}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              fullWidth
              label="รหัสโปรเจกต์ / สินค้าพื้นฐาน"
            />
          )}
        />
      </div>
      <div className="w-full py-2 px-1">
        <Controller
          name="name"
          defaultValue={baseProject?.name || ''}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label="ชื่อโปรเจกต์ / สินค้าพื้นฐาน"
              required
              fullWidth
              helperText={errors?.name && 'กรุณาใส่ชื่อสินค้าพื้นฐาน'}
            />
          )}
        />
      </div>
      <div className="w-full py-2 px-1">
        <Controller
          name="unit"
          defaultValue={baseProject?.unit || ''}
          control={control}
          render={({ field }) => (
            <TextField {...field} fullWidth size="small" label="หน่วย" />
          )}
        />
      </div>
      <div className="w-full py-2 px-1">
        <Controller
          name="description"
          defaultValue={baseProject?.description || ''}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label="รายละเอียด"
              fullWidth
              multiline
              rows={3}
            />
          )}
        />
      </div>

      {information?.setting?.wms?.additionalAttribute &&
        _.map(
          information?.setting?.wms?.additionalAttribute,
          (eachAttribute, index) => (
            <div className="w-full lg:w-1/2 px-1 py-2" key={index}>
              <MeasurementAddingRenderingCompanent
                control={control}
                measurementControllerName={`additional.${eachAttribute?.name}`}
                measurementType={{
                  data_type: eachAttribute?.type,
                  label: eachAttribute?.name,
                }}
                watch={watch}
                defaultValue={
                  baseProject?.additional?.[eachAttribute?.name]?.value
                }
              />
            </div>
          ),
        )}

      <div className="w-full py-2 px-1">
        <Controller
          name="attach_process_template"
          defaultValue={baseProject?.attach_process_template}
          control={control}
          render={({ field }) => (
            <FormControlLabel
              label="ผูกกับโฟลว์การผลิต (สูตรการผลิต)"
              control={
                <Checkbox
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    setValue('process_template', null);
                  }}
                  defaultChecked={field.value}
                />
              }
            />
          )}
        />
      </div>

      {watch('attach_process_template') && !_.isEmpty(processTemplate?.rows) && (
        <div className="w-full py-2 px-1">
          <Controller
            name="process_template"
            defaultValue={baseProject?.process_template || null}
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={processTemplate?.rows}
                onChange={(e, newValue) => field.onChange(newValue)}
                getOptionLabel={(option) => option?.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="รูปแบบโฟลว์การผลิต (สูตรการผลิต)"
                    fullWidth
                  />
                )}
              />
            )}
          />
        </div>
      )}

      <div className="w-full py-2 px-1">
        <Controller
          name="attach_bom_template"
          defaultValue={baseProject?.attach_bom_template}
          control={control}
          render={({ field }) => (
            <FormControlLabel
              label="ผูกกับสูตรส่วนผสม (สูตร BOM)"
              control={
                <Checkbox
                  {...field}
                  defaultChecked={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                    setValue('bom_template', null);
                  }}
                />
              }
            />
          )}
        />
      </div>

      {watch('attach_bom_template') && !_.isEmpty(bomTemplate?.rows) && (
        <div className="w-full py-2 px-1">
          <Controller
            name="bom_template"
            defaultValue={baseProject?.bom_template || null}
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={bomTemplate?.rows}
                getOptionLabel={(option) => option?.name}
                onChange={(e, newValue) => field.onChange(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="สูตรส่วนผสม (สูตร BOM)"
                    fullWidth
                  />
                )}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};

BaseProjectForm.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
  baseProject: PropTypes.object,
  watch: PropTypes.func,
  processTemplate: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  bomTemplate: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  setValue: PropTypes.func,
};

export default BaseProjectForm;
