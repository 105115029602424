import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import _ from 'lodash';

import api from '../../../configs/api';

dayjs.extend(durationPlugin);
dayjs.extend(relativeTime);

/**
 * @function getEmployeePayrollUnitCostOnTimeWithAPI
 * @description ยิง API ไปยัง Backend เเพื่อดูว่าค่าแรงในช่วงเวลาที่ทำงานนั้น
 * มีค่าเป็นเท่าไหร่ โดยยิงไปที่ payroll-adjustment จากนั้นดูว่าเป็นรายเดือน หรือ รายวัน
 * หารออกมาเป็นค่าจ้างต่อนาที
 */
const getEmployeePayrollUnitCostOnTimeWithAPI = async ({
  representEmployee,
  timePair,
  information,
}) => {
  try {
    const { data } = await api.get(
      `${process.env.REACT_APP_API_URL}/payroll-adjustment?employee=${
        representEmployee?._id
      }&date_end=${dayjs(timePair?.[1]).format('YYYY-MM-DD')}`,
    );
    const latestAdjustment = _.last(data?.rows);
    const lastAdjustmentSalary = latestAdjustment?.new_salary;
    // console.log('Last Adjustment Salary', lastAdjustmentSalary);
    const isPayMonthly = latestAdjustment?.is_pay_monthy;
    const pamsSetting = information?.setting?.pams;

    let unitCost = 0;
    if (isPayMonthly) {
      unitCost =
        lastAdjustmentSalary /
        (pamsSetting?.period_date *
          pamsSetting?.daily_payroll?.working_hour *
          60);
    } else {
      unitCost =
        lastAdjustmentSalary / pamsSetting?.daily_payroll?.working_hour / 60;
    }
    return unitCost;
  } catch (error) {
    console.error('Cannot Calculate payroll unit cost', error);
    return 0;
  }
};

export default getEmployeePayrollUnitCostOnTimeWithAPI;
