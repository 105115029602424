import React from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import PropTypes from 'prop-types';

export function DepartmentForm({ errors, department, Controller, control }) {
  const availableModule = useSelector((state) => state.availableModule);
  const {
    CRM,
    DASHBOARD,
    HRMS,
    IMS,
    KM,
    MAMS,
    MMS,
    PMS,
    SETTING,
    SPM,
    WMS,
    PAMS,
    DOC,
    CEM,
  } = availableModule;
  const renderAccessList = () => (
    <div className="px-2">
      {DASHBOARD && (
        <div className="w-full px-1 py-1">
          <Controller
            name={'access.DASHBOARD'}
            control={control}
            defaultValue={department ? department.access?.DASHBOARD : false}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="แดชบอร์ด"
              />
            )}
          />
        </div>
      )}
      {SPM && (
        <div className="w-full px-1 py-1">
          <Controller
            name={'access.SPM'}
            control={control}
            defaultValue={department ? department.access?.SPM : false}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="การขาย"
              />
            )}
          />
        </div>
      )}
      {PMS && (
        <div className="w-full px-1 py-1">
          <Controller
            name={'access.PMS'}
            control={control}
            defaultValue={department ? department.access?.PMS : false}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="การจัดซื้อ"
              />
            )}
          />
        </div>
      )}
      {MMS && (
        <div className="w-full px-1 py-1">
          <Controller
            name={'access.MMS'}
            control={control}
            defaultValue={department ? department.access?.MMS : false}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="การผลิต"
              />
            )}
          />
        </div>
      )}
      {IMS && (
        <div className="w-full px-1 py-1">
          <Controller
            name={'access.IMS'}
            control={control}
            defaultValue={department ? department.access?.IMS : false}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="คลังวัตถุดิบ"
              />
            )}
          />
        </div>
      )}
      {WMS && (
        <div className="w-full px-1 py-1">
          <Controller
            name={'access.WMS'}
            control={control}
            defaultValue={department ? department.access?.WMS : false}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="คลังสินค้า"
              />
            )}
          />
        </div>
      )}
      {CRM && (
        <div className="w-full px-1 py-1">
          <Controller
            name={'access.CRM'}
            control={control}
            defaultValue={department ? department.access?.CRM : false}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="ลูกค้าสัมพันธ์"
              />
            )}
          />
        </div>
      )}
      {HRMS && (
        <div className="w-full px-1 py-1">
          <Controller
            name={'access.HRMS'}
            control={control}
            defaultValue={department ? department.access?.HRMS : false}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="ทรัพยากรบุคคล"
              />
            )}
          />
        </div>
      )}
      {KM && (
        <div className="w-full px-1 py-1">
          <Controller
            name={'access.KM'}
            control={control}
            defaultValue={department ? department.access?.KM : false}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="คลังข้อมูลองค์กร"
              />
            )}
          />
        </div>
      )}
      {MAMS && (
        <div className="w-full px-1 py-1">
          <Controller
            name={'access.MAMS'}
            control={control}
            defaultValue={department ? department.access?.MAMS : false}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="เครื่องจักร / รางการผลิต"
              />
            )}
          />
        </div>
      )}
      {PAMS && (
        <div className="w-full px-1 py-1">
          <Controller
            name={'access.PAMS'}
            control={control}
            defaultValue={department ? department.access?.PAMS : false}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="เงินเดือนและการจ่ายค่าตอบแทน"
              />
            )}
          />
        </div>
      )}
      {DOC && (
        <div className="w-full px-1 py-1">
          <Controller
            name={'DOC'}
            control={control}
            defaultValue={availableModule?.DOC || false}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="จัดการเอกสาร (สำหรับใช้กับระบบ E-Warehouse)"
              />
            )}
          />
        </div>
      )}{' '}
      {CEM && (
        <div className="w-full px-1 py-1">
          <Controller
            name={'access.CEM'}
            control={control}
            defaultValue={department.access?.CEM || false}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="ประมาณการต้นทุน"
              />
            )}
          />
        </div>
      )}
      {SETTING && (
        <div className="w-full px-1 py-1">
          <Controller
            name={'access.SETTING'}
            control={control}
            defaultValue={department ? department.access?.SETTING : false}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="ตั้งค่าระบบ"
              />
            )}
          />
        </div>
      )}
    </div>
  );

  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={department ? department.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อแผนก"
              fullWidth
              size={'small'}
              required
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'description'}
          control={control}
          defaultValue={department ? department.description : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รายละเอียด"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors.description && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>

      <div> {renderAccessList()} </div>
    </div>
  );
}

DepartmentForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    description: PropTypes.object,
  }),
  department: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
};

DepartmentForm.defaultProps = {
  department: null,
};

export default DepartmentForm;
