import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as CONSTANT from '@/utils/constants';
import { notNan } from '@/utils/functions';

import 'dayjs/locale/th';

dayjs.locale('th');
dayjs.extend(buddhistEra);

const FullManufacMaterialTable = ({
  manufacturingMaterials,
  size,
  page,
  total,
  handleChangePage,
  pagination = true,
}) => {
  // Your function Go here

  const information = useSelector((state) => state.information);

  const numberFormatting = (row) => {
    try {
      if ((row?.amount / row?.begin) * 100 === Infinity) {
        return '100';
      }
      if ((row?.amount / row?.begin) * 100 === -Infinity) {
        return '-';
      }
      return ((row?.amount / row?.begin) * 100)?.toFixed(2);
    } catch (error) {
      return '-';
    }
  };

  return (
    <div className="my-2">
      <div className="overflow-x-auto">
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead className="bg-blue-100">
            <TableRow>
              <TableCell>
                <div className="font-bold">ลำดับที่</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> วันที่</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">การดำเนินการ</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">คำสั่งผลิต</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">เดิม</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">ดำเนินการ</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">คงเหลือ</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">Yield</div>
              </TableCell>{' '}
              <TableCell>
                <div className="font-bold">หน่วย</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!_.isEmpty(manufacturingMaterials) ? (
              _.map(manufacturingMaterials, (row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    <div className="text-xs">{index + 1}</div>
                  </TableCell>
                  <TableCell>
                    {dayjs(row?.createdAt)?.format('D MMM BBBB')} <br />
                    {dayjs(row?.createdAt)?.format('HH.mm น.')}
                  </TableCell>
                  <TableCell>
                    <div
                      className={`font-semibold ${
                        row?.transaction_dir === true
                          ? 'text-green-500'
                          : 'text-red-500'
                      }`}
                    >
                      {
                        CONSTANT.MANUFACTURING_TRANSACTION_TYPE[
                          row?.transaction_type
                        ]?.description
                      }
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="my-1">
                      <Link
                        to={
                          information?.setting?.mms?.dashboard?.showDashboardC
                            ? `/mms/manufacturing-orders-c/view/${row?.manufacturing_order?._id}`
                            : `/mms/manufacturing-orders/view/${row?.manufacturing_order?._id}`
                        }
                      >
                        {row?.manufacturing_order?.prefix}
                        {row?.manufacturing_order?.running_number}{' '}
                      </Link>
                    </div>
                    <div>
                      <span className="font-semibold">
                        {row?.product?.type_code}
                      </span>{' '}
                      {row?.product?.name}
                    </div>
                  </TableCell>
                  <TableCell>{notNan(row?.begin)}</TableCell>
                  <TableCell>{notNan(row?.quantity)}</TableCell>
                  <TableCell>{notNan(row?.amount)}</TableCell>
                  <TableCell>{numberFormatting(row)} %</TableCell>
                  <TableCell> {row?.material?.unit}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9}>
                  <div className="text-center">ไม่มีข้อมูล</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {pagination && (
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      )}
    </div>
  );
};

FullManufacMaterialTable.propTypes = {
  manufacturingMaterials: PropTypes.arrayOf(PropTypes.object),
  size: PropTypes.number,
  page: PropTypes.number,
  total: PropTypes.number,
  handleChangePage: PropTypes.func,
  pagination: PropTypes.bool,
};

export default FullManufacMaterialTable;
