import api from '../../../configs/api';
import {
  PROCESS_OF_ORDER_ALL,
  PROCESS_OF_ORDER_DEL,
  PROCESS_OF_ORDER_ERROR,
  PROCESS_OF_ORDER_GET,
  PROCESS_OF_ORDER_LOADING,
  PROCESS_OF_ORDER_POST,
  PROCESS_OF_ORDER_PUT,
} from '../types';

export const processCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PROCESS_OF_ORDER_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/process`,
      {
        ...payload,
      },
    );
    dispatch({ type: PROCESS_OF_ORDER_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PROCESS_OF_ORDER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const processAll = (params) => async (dispatch) => {
  try {
    const {
      name = '',
      size = '',
      page = 1,
      startDate = '',
      endDate = '',
      deleted = false,
      completed = false,
    } = params;
    const { data, status } = await api.get(
      `${
        process.env.REACT_APP_API_URL
      }/process?productName=${name}&size=${size}&page=${page}&startDate=${startDate}&endDate=${endDate}&completed=${
        completed ? 'true' : 'false'
      }&deleted=${deleted ? 'true' : 'false'}`,
    );
    if (status === 200) {
      dispatch({ type: PROCESS_OF_ORDER_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PROCESS_OF_ORDER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const processGet = (
  id,
  { fetchInsideProecss = true, fetchProduct = '' } = {},
) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/process/${id}?fetchInsideProcess=${fetchInsideProecss}&fetchProduct=${fetchProduct}`,
    );
    if (status === 200) {
      dispatch({ type: PROCESS_OF_ORDER_GET, payload: data });
    }
    if (status === 404) {
      dispatch({ type: PROCESS_OF_ORDER_ERROR, payload: { message: '' } });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PROCESS_OF_ORDER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const processStateSet = (data) => async (dispatch) => {
  dispatch({ type: PROCESS_OF_ORDER_ALL, payload: data });
};

export const processStateOneSet = (data) => async (dispatch) => {
  dispatch({ type: PROCESS_OF_ORDER_GET, payload: data });
};

export const processReset = () => async (dispatch) => {
  dispatch({ type: PROCESS_OF_ORDER_LOADING });
};

export const processError = () => async (dispatch) => {
  dispatch({ type: PROCESS_OF_ORDER_ERROR });
};

export const processPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: PROCESS_OF_ORDER_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/process/${id}`,
      payload,
    );
    dispatch({ type: PROCESS_OF_ORDER_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PROCESS_OF_ORDER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const processMaterialWithdrawStockLot = (payload) => async (
  dispatch,
) => {
  try {
    dispatch({ type: PROCESS_OF_ORDER_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/process/material-withdraw/stocklot`,
      payload,
    );
    dispatch({ type: PROCESS_OF_ORDER_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PROCESS_OF_ORDER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const processMaterialWithdrawFIFO = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PROCESS_OF_ORDER_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/process/material-withdraw/fifo`,
      payload,
    );
    dispatch({ type: PROCESS_OF_ORDER_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PROCESS_OF_ORDER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const processMaterialRequest = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PROCESS_OF_ORDER_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/process/material-withdraw/request`,
      payload,
    );
    dispatch({ type: PROCESS_OF_ORDER_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PROCESS_OF_ORDER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const processSplitMoreProcess = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PROCESS_OF_ORDER_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/process/more-split`,
      payload,
    );
    dispatch({ type: PROCESS_OF_ORDER_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PROCESS_OF_ORDER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const processRecieveMaterial = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PROCESS_OF_ORDER_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/process/material-withdraw/recieve`,
      payload,
    );
    dispatch({ type: PROCESS_OF_ORDER_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PROCESS_OF_ORDER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const processRecieveMinorMaterial = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PROCESS_OF_ORDER_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/process/material-withdraw/recieve-minor`,
      payload,
    );
    dispatch({ type: PROCESS_OF_ORDER_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PROCESS_OF_ORDER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const processSplittingClose = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PROCESS_OF_ORDER_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/process/close-split`,
      payload,
    );
    dispatch({ type: PROCESS_OF_ORDER_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PROCESS_OF_ORDER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const processMaterialReturn = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PROCESS_OF_ORDER_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/process/material-return`,
      payload,
    );
    dispatch({ type: PROCESS_OF_ORDER_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PROCESS_OF_ORDER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const processDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: PROCESS_OF_ORDER_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/process/${id}`,
    );
    dispatch({ type: PROCESS_OF_ORDER_DEL, payload: data });
  } catch (error) {
    const { status } = error.request;
    console.error(error);

    if (status === 403) {
      // prettier-ignore
      const message = 'ไม่สามารถลบข้อมูลได้';
      dispatch({ type: PROCESS_OF_ORDER_ERROR, payload: { message } });
      throw new Error(message);
    } else {
      const message = 'เกิดข้อผิดพลาด';
      dispatch({ type: PROCESS_OF_ORDER_ERROR, payload: { message } });
      throw new Error(message);
    }
  }
};

export const processAudit = ({ referenceNumber }) => async (dispatch) => {
  try {
    dispatch({ type: PROCESS_OF_ORDER_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/process/audit-log`,
      {
        referenceNumber,
      },
    );
    dispatch({ type: PROCESS_OF_ORDER_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PROCESS_OF_ORDER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};
