/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar';
import { withErrorBoundary } from 'react-error-boundary';
import { Card } from '@mui/material';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import th from 'date-fns/locale/th';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import _ from 'lodash';
import PropTypes from 'prop-types';

import stepToEvent from '@/utils/functions/stepToEvent';

import Error from '../../Error/Error';

import CalendarColorLegend from './CalendarColorLegend';

import '../../../assets/styles/calendar.css';

const ManufacturingOrderCalendarByStep = ({
  step,
  setCurrentDay = () => {},
  stepLog,
  currentDay,
}) => {
  const locales = {
    th,
  };

  const allSteps = [...(step?.rows || []), ...(stepLog?.rows || [])];

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (allSteps) {
      setEvents(stepToEvent(allSteps));
    }

    return () => {};
  }, [step, stepLog]);

  const eventStyleGetter = (event) => {
    let backgroundColor = '#D6EAF8';
    if (event.type === 'pending') {
      backgroundColor = '#2541B2';
    } else if (event.type === 'deleted') {
      backgroundColor = '#ff465d';
    } else if (event.type === 'completed') {
      backgroundColor = '#10B981';
    } else if (event.type === 'late') {
      backgroundColor = '#ffae2a';
    }
    const style = {
      backgroundColor,
      borderRadius: '4px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      display: 'block',
    };
    return {
      style,
    };
  };

  return (
    <div>
      <div className="flex flex-wrap  gap-4">
        <div className="w-full">
          <Card className="p-6">
            <div className="w-full my-4">
              <CalendarColorLegend />
              <div>
                <div style={{ height: '200vh' }}>
                  <Calendar
                    culture={'th'}
                    localizer={localizer}
                    events={events}
                    views={{ month: true }}
                    startAccessor="start"
                    endAccessor="end"
                    eventPropGetter={eventStyleGetter}
                    style={{ height: '200vh' }}
                    dayLayoutAlgorithm="no-overlap"
                    popup={true}
                    defaultView={Views.MONTH}
                    defaultDate={currentDay}
                    onNavigate={(selectedDate) => {
                      console.log('Selected Date', selectedDate);
                      setCurrentDay(selectedDate);
                    }}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

const MMSDashboardWithBoundary = withErrorBoundary(
  ManufacturingOrderCalendarByStep,
  {
    fallback: <Error />,
    onError(error, info) {
      // Do something with the error
      // E.g. log to an error logging client here
      console.error(error);
    },
  },
);

export default MMSDashboardWithBoundary;

ManufacturingOrderCalendarByStep.propTypes = {
  periodText: PropTypes.string,
  step: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
    total: PropTypes.number,
  }),
  employee: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
    total: PropTypes.number,
  }),
  setCurrentDay: PropTypes.func,
};
