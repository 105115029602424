/* eslint-disable no-confusing-arrow */
import appConfig from '../../configs/app';
import { MATERIAL_STOCK_STATUS } from '../constants';
import { converseMaterialOrProductUnit } from '../functions/converseMaterialOrProductUnit';

const columns = [
  {
    label: 'ลำดับที่',
    value: 'index',
  },
  {
    label: 'รหัสสินค้า',
    value: 'type_code',
  },
  {
    label: 'ชื่อสิน้คา',
    value: 'name',
  },
  {
    label: 'คงเหลือ',
    value: 'total',
  },
  {
    label: 'หน่วย',
    value: (col) =>
      col?.use_unit_conversion ? col?.unit_input?.short_sign : col?.unit,
  },
  {
    label: 'คงเหลือ (หน่วยที่สอง)',
    value: (col) =>
      col?.use_unit_conversion
        ? converseMaterialOrProductUnit(col, col?.total)
        : '',
  },
  {
    label: 'หน่วย',
    value: (col) =>
      col?.use_unit_conversion ? col?.unit_output?.short_sign : '-',
  },
];

const columnWithoutStock = [
  {
    label: 'ลำดับที่',
    value: 'index',
  },
  {
    label: 'รหัสสินค้า',
    value: 'type_code',
  },
  {
    label: 'ชื่อสินค้า',
    value: 'name',
  },
  {
    label: 'ประเภทสินค้า',
    value: (col) => col?.product_type?.name,
  },
  {
    label: 'หน่วย',
    value: (col) =>
      col?.use_unit_conversion ? col?.unit_input?.short_sign : col?.unit,
  },
  {
    label: 'หน่วยที่สอง',
    value: (col) =>
      col?.use_unit_conversion ? col?.unit_output?.short_sign : '-',
  },
];

const columnWithMaterialDouble = [
  {
    label: 'ลำดับที่',
    value: 'index',
  },
  {
    label: 'รหัสสินค้า',
    value: (col) => col?.product?.type_code,
  },
  {
    label: 'ขื่อสินค้า',
    value: (col) => col?.product?.name,
  },
  {
    label: 'จำนวน',
    value: 'quantity',
  },
  {
    label: 'หน่วย',
    value: (col) =>
      col?.product?.use_unit_conversion
        ? col?.product?.unit_input?.short_sign
        : col?.product?.unit,
  },
  {
    label: 'คงเหลือ (หน่วยที่สอง)',
    value: (col) =>
      col?.product?.use_unit_conversion
        ? converseMaterialOrProductUnit(col?.product, col?.quantity)
        : '',
  },
  {
    label: 'หน่วย',
    value: (col) =>
      col?.product?.use_unit_conversion
        ? col?.product?.unit_output?.short_sign
        : '',
  },
];

const query = ({ id, selectedProductType }) => ({
  place: id,
  fetchStockLot: true,
  selectProductTypeType: selectedProductType,
  stockStatus: MATERIAL_STOCK_STATUS.IN_STOCK.status_code,
  fetchProcess: false,
  page: 1,
  size: appConfig.maxFetchSize,
});

export default { columns, query, columnWithoutStock, columnWithMaterialDouble };
