import React from 'react';
import { Alert, Button, Card, CardContent } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

const ProcessHandleProductOnlyControlBox = ({
  selectedProcess,
  handleProcessSuccess = () => {},
}) => {
  const isLaststep =
    _.find(
      selectedProcess?.steps,
      (_step) => _step?.index === _.size(selectedProcess?.steps) - 1,
    ) && selectedProcess?.current?.index === _.size(selectedProcess?.steps) - 1;

  if (!isLaststep) {
    return <div></div>;
  }

  if (selectedProcess?.completed) {
    return (
      <div className="my-2">
        <Alert severity="success">
          ไลน์การผลิตของสินค้า <i>{selectedProcess?.product?.type_code}</i>{' '}
          <b>{selectedProcess?.product?.name} </b> เสร็จสิ่นแล้ว
        </Alert>
      </div>
    );
  }

  return (
    <div>
      <Card>
        <CardContent>
          <Alert severity="warning">
            <div className="w-full flex flex-wrap">
              <div className="w-full lg:w-1/2">
                สินค้า <i>{selectedProcess?.product?.type_code}</i>{' '}
                <b>{selectedProcess?.product?.name} </b>{' '}
                เข้าสู่ขั้นตอนสุดท้ายของการผลิตแล้ว
              </div>
              <div className="w-1/3">
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => handleProcessSuccess(selectedProcess?._id)}
                >
                  ทำเครื่องหมายว่าสำเร็จ
                </Button>
              </div>
            </div>
          </Alert>
        </CardContent>
      </Card>
    </div>
  );
};

export default ProcessHandleProductOnlyControlBox;

ProcessHandleProductOnlyControlBox.propTypes = {
  selectedProcess: PropTypes.object,
  handleProcessSuccess: PropTypes.func,
};
