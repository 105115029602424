import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import _ from 'lodash';
import PropTypes from 'prop-types';

import ModuleLevelPlugin from '@/configs/plugins';
import InsideLevelPlugin from '@/plugins';

import { Loading } from '../../Loading';
// const AllPluginInfo = lazy(() => import('../../plugins'));

export default function OnePluginDisplayRenderingComponent({
  pluginInfo,
  manufacturingOrder,
  currentProcess,
  currentStep,
}) {
  const AllPluginInfo = [...ModuleLevelPlugin, ...InsideLevelPlugin];
  const FoundedPlugin = _.find(
    AllPluginInfo,
    (eachPlugin) => eachPlugin?.id === pluginInfo?.plugin,
  );

  console.log('Plugin Info', pluginInfo);

  if (!FoundedPlugin) {
    return <div></div>;
  }

  const fallbackComponent = <div>Plugin Error</div>;
  return (
    <div>
      <ErrorBoundary FallbackComponent={fallbackComponent}>
        <Suspense fallback={<Loading />}>
          <FoundedPlugin.displayOnProcessContainer
            manufacturingOrder={manufacturingOrder}
            currentProcess={currentProcess}
            currentStep={currentStep}
            React={React}
          />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}

OnePluginDisplayRenderingComponent.propTypes = {
  pluginInfo: PropTypes.shape({
    use_plugin_instead: PropTypes.bool,
    plugin: PropTypes.string,
  }),
  manufacturingOrder: PropTypes.object,
  currentProcess: PropTypes.object,
  currentStep: PropTypes.object,
};
