import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Autocomplete,
  Skeleton,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import ProdOrMatQuantityInput from '../../Inputs/ProdOrMatQuantityInput';
import ProductAutocomplete from '../../Inputs/ProductAutocomplete';

const MaterialAmountAddComponent = ({
  material,
  index,
  control,
  watch,
  setValue,
  enableAddPreferProduct,
  enableCustomer,
  customer,
  product,
  rerender,
  setRerender,
  place,
  autoFocus = false,
}) => {
  let productList = [];
  if (place?.place_action?.filter_product_by_peer_material) {
    const peerProductList = _.map(
      material?.peer_product_type,
      (each) => each?._id,
    );

    const filteredProductList = _.filter(product?.rows, (eachProduct) =>
      _.includes(peerProductList, eachProduct?.product_type?._id),
    );
    if (!_.isEmpty(filteredProductList)) {
      productList = filteredProductList;
    }
  } else {
    productList = product?.rows;
  }

  return (
    <TableRow>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        {material ? (
          <div>
            <Controller
              control={control}
              defaultValue={material}
              render={({ field }) => <input {...field} className="hidden" />}
              name={`materialIn[${index}].material`}
            />
            <b>{material?.type_code}</b> {material?.name} (
            {material?.material_type?.name})
          </div>
        ) : (
          <Skeleton variant="text" width={100} height={20} />
        )}
      </TableCell>
      {enableAddPreferProduct && (
        <TableCell>
          {!_.isEmpty(productList) && (
            <Controller
              control={control}
              name={`materialIn[${index}].prefer_product`}
              render={({ field }) => (
                <ProductAutocomplete field={field} productRows={productList} />
              )}
            />
          )}
        </TableCell>
      )}{' '}
      {enableCustomer && (
        <TableCell>
          {!_.isEmpty(customer?.rows) && (
            <Controller
              control={control}
              name={`materialIn[${index}].customer`}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  options={customer?.rows}
                  size="small"
                  placeholder="เลือกลูกค้า"
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                  }}
                  className="z-40"
                  // prettier-ignore
                  getOptionLabel={(option) => `${option?.type_code || ''} ${option?.name || ''}`
}
                  renderInput={(params) => (
                    <TextField label="เลือกลูกค้า" {...params} />
                  )}
                />
              )}
            />
          )}
        </TableCell>
      )}
      <TableCell>
        <ProdOrMatQuantityInput
          controllerName={`materialIn[${index}].quantity`}
          artificialControllerName={`materialIn[${index}].quantity_artificial`}
          selectedUnitControllerName={`materialIn[${index}].selected_unit`}
          setValue={setValue}
          watch={watch}
          control={control}
          selectedProduct={material}
          reload={rerender}
          setReload={setRerender}
          autoFocus={autoFocus}
        />
      </TableCell>
    </TableRow>
  );
};

export default MaterialAmountAddComponent;

MaterialAmountAddComponent.propTypes = {
  material: PropTypes.object,
  index: PropTypes.number,
  control: PropTypes.object,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  enableAddPreferProduct: PropTypes.bool,
  enableCustomer: PropTypes.bool,
  customer: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  product: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  rerender: PropTypes.bool,
  setRerender: PropTypes.func,
  place: PropTypes.object,
  autoFocus: PropTypes.bool,
};
