import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  BackButton,
  Error,
  Loading,
  MaterialLotTable,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import { Button, Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import * as actions from '@/redux/actions';
import { useQuery } from '@/utils/functions';

/**
 * @function SuccessTransfer
 * @description Display a collections or a list of SuccessTransfer from database
 */

export default function SuccessTransfer({ title, subtitle }) {
  const dispatch = useDispatch();
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const information = useSelector((state) => state.information);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const params = useParams();
  const browserQuery = useQuery();

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.materialStockLotAll({ page, size, ancestor: params.id }),
      );
    } catch (error) {
      dispatch(actions.materialStockLotError());
    }
  };

  const handleDelete = async (id) => {
    try {
      Swal.fire({
        title: 'ยืนยันการลบรายการ',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await dispatch(actions.materialStockLotDelete(id));
          queryDataFromServer();
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, params]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (materialStockLot.isLoading) {
    return <Loading />;
  }
  if (!materialStockLot.isLoading && materialStockLot.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div>
          <BackButton />
        </div>
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="text-center">
                <div className="font-semibold text-2xl">
                  ดำเนินการเปลี่ยนชนิดวัตถุดิบสำเร็จ
                </div>
                <div>กรุณาตรวจสอบรายการวัตถุดิบอีกครั้ง</div>
              </div>
              <div className="my-2 flex justify-center">
                <Link
                  to={`/ims/material-stock/place/${browserQuery.get('source')}`}
                >
                  <Button variant="contained" color="primary">
                    กลับไปคลังล่าสุด
                  </Button>
                </Link>
              </div>
            </CardContent>
          </Card>
        </div>
        <div>
          <MaterialLotTable
            imsSetting={information?.setting?.ims}
            handleDelete={handleDelete}
            materialStockLot={materialStockLot}
            multiMaterial
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            showStatus
            total={materialStockLot?.total}
            showActionButton
          />
        </div>
      </div>
    );
  }
  return <Error />;
}

SuccessTransfer.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SuccessTransfer.defaultProps = {
  title: '',
  subtitle: '',
};
