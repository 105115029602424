import dayjs from 'dayjs';
import _ from 'lodash';

const groupEvent = (order) => {
  const { completed, deleted } = order;
  if (completed === true) {
    return 'completed';
  }
  if (deleted === true) {
    return 'deleted';
  }
  return 'pending';
};
export const ManufacturingOrderToEvent = (orders) =>
  // console.log('orders', orders);
  _.map(orders, (order) => {
    const returnedObject = {
      type: groupEvent(order),
      id: order?._id,
      title: `${order.running_number} ${order?.customer?.name || ''}`,
      allDay: true,
      status: order?.order_status,
      start: dayjs(new Date(order?.createdAt)).startOf('day').toString(),
      end: order?.expected_date
        ? dayjs(new Date(order?.expected_date)).endOf('day').toString()
        : dayjs(new Date(order?.createdAt)).startOf('day').toString(),
    };
    return returnedObject;
  });
export default ManufacturingOrderToEvent;
