import {
  COST_ESTIMATION_PROFILE_ALL,
  COST_ESTIMATION_PROFILE_DELETE,
  COST_ESTIMATION_PROFILE_ERROR,
  COST_ESTIMATION_PROFILE_GET,
  COST_ESTIMATION_PROFILE_LOADING,
  COST_ESTIMATION_PROFILE_POST,
  COST_ESTIMATION_PROFILE_PUT,
} from '../../actions/types';
import { BaseReducer } from '../../class';

const basedReducerObject = new BaseReducer({
  allConst: COST_ESTIMATION_PROFILE_ALL,
  getConst: COST_ESTIMATION_PROFILE_GET,
  postConst: COST_ESTIMATION_PROFILE_POST,
  putConst: COST_ESTIMATION_PROFILE_PUT,
  deleteConst: COST_ESTIMATION_PROFILE_DELETE,
  errorConst: COST_ESTIMATION_PROFILE_ERROR,
  loadingConst: COST_ESTIMATION_PROFILE_LOADING,
});

const initialState = {
  isLoading: true,
  isCompleted: true,
};

const CostEstimationProfileReducer = (state = initialState, action) =>
  basedReducerObject.getReducer(state, action);

export default CostEstimationProfileReducer;
