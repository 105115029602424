import React from 'react';
import PropTypes from 'prop-types';

const ParagraphGauge = ({ measurementData }) => (
  <div>
    <div className="">{measurementData?.value}</div>
  </div>
);

ParagraphGauge.propTypes = {
  measurementData: PropTypes.object,
};

export default ParagraphGauge;
