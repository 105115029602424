import React from 'react';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { Avatar } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { previousLotReciptDateSafe } from '@/utils/functions';

export default function MaterialStockLotTimeline({ previousLots }) {
  // Playsafe: Ensure that receipt_date is a valid date and scale only into minutes

  const orderedLot = _.orderBy(
    previousLotReciptDateSafe(previousLots),
    'receipt_date',
  );
  const groupByDate = _.groupBy(orderedLot, 'receipt_date');
  // console.log('Group By Date', groupByDate);

  const generatePrefixBeforeLot = (currentLotList, previousLotList) => {
    if (!previousLotList) return 'รับวัตถุดิบเข้า';
    const currentPlace = currentLotList?.[0]?.place;
    const currentWarehouse = currentLotList?.[0]?.warehouse;
    const previousPlace = previousLotList?.[0]?.place;

    if (currentPlace?._id === previousPlace?._id) return 'แปลงวัตถุดิบเป็น';
    if (!currentPlace?._id && currentWarehouse?._id) return 'นำเข้าคลังสินค้า';
    return 'ย้ายวัตถุดิบ';
  };

  const dateKey = _.keys(groupByDate);
  return (
    <div>
      <Timeline position="alternate">
        {_.map(groupByDate, (stockLotLists, key) => {
          const currentLotKey = _.findIndex(dateKey, (each) => each === key);
          const previousLotList =
            currentLotKey > 0 ? groupByDate[dateKey[currentLotKey - 1]] : null;
          return (
            <TimelineItem key={key}>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                {dayjs(key).format('D MMM  HH.mm น.')}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <div className="p-2">
                  <Avatar
                    src={stockLotLists?.[0]?.employee?.image?.url}
                    className="uppercase"
                    sx={{ width: 32, height: 32 }}
                  >
                    {_.first(stockLotLists?.[0]?.employee?.firstname)}
                    {_.first(stockLotLists?.[0]?.employee?.lastname)}
                  </Avatar>
                </div>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <div className="flex flex-col align-center">
                  <div className="text-xs">พนักงาน</div>
                  <div className="text-sm">
                    {stockLotLists?.[0]?.employee?.firstname || '-'}{' '}
                    {stockLotLists?.[0]?.employee?.lastname || ''}{' '}
                  </div>

                  <div className="font-semibold text-black font-display">
                    {generatePrefixBeforeLot(stockLotLists, previousLotList)}{' '}
                    {stockLotLists?.[0]?.isProduct
                      ? stockLotLists?.[0]?.product?.name
                      : stockLotLists?.[0]?.material?.name}
                    {_.size(stockLotLists) > 1 && (
                      <span> และอีก {_.size(stockLotLists) - 1} ชนิด </span>
                    )}
                  </div>
                  <div className="text-sm">
                    {stockLotLists?.[0]?.isProduct
                      ? stockLotLists?.[0]?.warehouse?.name || '-'
                      : stockLotLists?.[0]?.place?.name || '-'}
                  </div>
                  <div className="text-sm">
                    {stockLotLists?.[0]?.machine?.name || ''}
                  </div>
                </div>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </div>
  );
}

MaterialStockLotTimeline.propTypes = {
  previousLots: PropTypes.arrayOf(PropTypes.object),
};
