import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import dayjs from 'dayjs';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  AddToWarehouseForm,
  BackButton,
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { app as appConfig } from '../../../configs';
import graphqlClient from '../../../configs/graphqlClient';
import * as actions from '../../../redux/actions';
import { PRODUCT_STOCK_STATUS } from '../../../utils/constants';

/**
 * @function HandleAddToWarehouse
 * @description Display a collections or a list of HandleAddToWarehouse from database
 */

export default function HandleAddToWarehouse({ title, subtitle }) {
  const dispatch = useDispatch();
  const selectedProcess = useSelector((state) => state.process);
  const warehouse = useSelector((state) => state.warehouse);
  const product = useSelector((state) => state.product);
  const productTransactionType = useSelector(
    (state) => state.productTransactionType,
  );
  const customer = useSelector((state) => state.customer);
  const me = useSelector((state) => state.me);

  const history = useHistory();
  const params = useParams();

  const query = gql`
    query FindSettingEditProductBom(
      $processInput: ProcessInput
      $productInput: ProductInput
      $customerInput: CustomerInput
    ) {
      findOneProcess(input: $processInput) {
        _id
        product {
          _id
          name
          type_code
          use_unit_conversion
          unit
          conversion_from {
            unit_a
            unit_b
            conversion_factor
          }
          conversion_to {
            unit_b
            unit_a
            conversion_factor
          }
          unit_input {
            _id
            short_sign
            name
          }
          unit_output {
            _id
            short_sign
            name
          }
        }
        quantity
        produce_material_instead
        manufacturing_order {
          _id
        }
        manufacturing_date
        expired_date
        customer {
          _id
          name
          type_code
        }
      }
      findProducts(input: $productInput) {
        rows {
          _id
          name
          type_code
          use_unit_conversion
          unit
          conversion_from {
            unit_a
            unit_b
            conversion_factor
          }
          conversion_to {
            unit_b
            unit_a
            conversion_factor
          }
          unit_input {
            _id
            short_sign
            name
          }
          unit_output {
            _id
            short_sign
            name
          }
        }
      }
      findCustomers(input: $customerInput) {
        rows {
          _id
          type_code
          name
        }
      }
    }
  `;

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      product: [
        {
          product: selectedProcess?.product,
          quantity: selectedProcess?.quantity,
          cost_price: 0,
          production_date: selectedProcess?.manufacturing_date
            ? dayjs(selectedProcess?.manufacturing_date).format('YYYY-MM-DD')
            : dayjs().format('YYYY-MM-DD'),
          expiration_date: selectedProcess?.expired_date
            ? dayjs(selectedProcess?.expired_date).format('YYYY-MM-DD')
            : dayjs().format('YYYY-MM-DD'),
          customer: selectedProcess?.customer,
        },
      ],
      production_number:
        selectedProcess?.manufacturing_order?._id ||
        selectedProcess?.manufacturing_order,
      process_number: selectedProcess?._id,
    },
  });

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        processInput: {
          id: params.id,
          fetchCustomer: true,
          fetchProduct: true,
        },
        productInput: {
          page: 1,
          size: appConfig.maxFetchSize,
          fetchStockLot: false,
          fetchBOM: false,
        },
      });
      const processData = queryResult?.findOneProcess;
      const productData = queryResult?.findProducts;
      const customerData = queryResult?.findCustomers;
      dispatch(actions.processStateOneSet(processData));
      dispatch(actions.productStateSet(productData));
      dispatch(actions.customerStateSet(customerData));
    } catch (err) {
      console.error(err);
      dispatch(actions.processError());
    }
  };

  const fetchWarehouse = async () => {
    try {
      dispatch(actions.warehouseAll({ page: 1, size: appConfig.maxFetchSize }));
    } catch (err) {
      console.error('Error On Fetch Warehouse', err);
    }
  };

  const fetchProductTxnType = async () => {
    try {
      dispatch(
        actions.productTransactionTypeAll({
          page: 1,
          size: appConfig.maxFetchSize,
        }),
      );
    } catch (err) {
      console.error('Error On Fetch Product Txn Type', err);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    fetchWarehouse();
    fetchProductTxnType();
    return () => {};
  }, [params]);

  useEffect(() => {
    setValue('product', [
      {
        product: selectedProcess?.product,
        quantity: selectedProcess?.quantity,
        cost_price: 0,
        production_date: selectedProcess?.manufacturing_date
          ? dayjs(selectedProcess?.manufacturing_date).format('YYYY-MM-DD')
          : dayjs().format('YYYY-MM-DD'),
        expiration_date: selectedProcess?.expired_date
          ? dayjs(selectedProcess?.expired_date).format('YYYY-MM-DD')
          : dayjs().format('YYYY-MM-DD'),
        customer: selectedProcess?.customer,
      },
    ]);

    return () => {};
  }, [selectedProcess]);

  console.log('Errors', errors);

  const handleProductAdd = async (data) => {
    console.log('On Handle Product Add', data);
    const payload = {
      ...data,
      consignee_name: `${me?.userData?.firstname} ${me?.userData?.lastname}`,
      product: _.map(data.product, (_product) => ({
        ..._product,
        product: _product.product?._id,
        amount: _product?.quantity,
      })),
      status: PRODUCT_STOCK_STATUS.IN_STOCK.status_code,
      remark: '',
      production_number:
        selectedProcess?.manufacturing_order?._id ||
        selectedProcess?.manufacturing_order,
      inventory_data: new Date(),
      process_number: selectedProcess?._id,
    };
    console.log('Add Product Payload', payload);
    try {
      await dispatch(actions.productStockLotCreate(payload));
      window.alert('เพิ่มสินค้าลงในคลังเสร็จสิ้น');

      // Go Back
      history.goBack();
    } catch (error) {
      window.alert(`ไม่สามารถเพิ่มสินค้าลงในคลังสินค้าได้ ${error?.message}`);
      console.log('Product Stock Lot Add Errors', error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (selectedProcess.isLoading) {
    return <Loading />;
  }

  if (!selectedProcess.isLoading && selectedProcess.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <BackButton />
        </div>
        <div className="my-2">
          <Card>
            <CardContent>
              <form onSubmit={handleSubmit(handleProductAdd)}>
                <AddToWarehouseForm
                  control={control}
                  customer={customer}
                  errors={errors}
                  product={product}
                  productTransactionType={productTransactionType}
                  warehouse={warehouse}
                  watch={watch}
                  setValue={setValue}
                />
                <div className="flex justify-end">
                  <Button variant="contained" type="submit">
                    บันทึก
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

HandleAddToWarehouse.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

HandleAddToWarehouse.defaultProps = {
  title: '',
  subtitle: '',
};
