import MetalDividingReducer from './redux/reducers/MetalDividingReducer';
import config from './config';
import Container, { DisplayMetalDivide, EditMetalDivide } from './containers';

export default {
  ...config,
  containers: Container,
  displayOnProcessContainer: DisplayMetalDivide,
  editOnStepContainer: EditMetalDivide,
  reducers: [MetalDividingReducer],
};
