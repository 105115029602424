import React from 'react';

import { CampaignModal } from '@iarcpsu/emanufac-components/src/components';

import LogoList from '../assets/images/logo-list.png';
import Santa from '../assets/images/santa.png';
import Slowbotton from '../assets/images/slowbotton.png';

export default function SystemSlowApologize() {
  return (
    <CampaignModal
      campaignCode="SLOW_APG_20240109"
      title="ขออภัยในการทำงานที่ล่าช้าของระบบ"
    >
      <div>
        {/* <div className="flex justify-center">
          <img src={Santa} className="w-32" />
        </div> */}
        {/* <div className="text-xl font-semibold text-center my-2">
          ขออภัยในการทำงานที่ล่าช้าของระบบ
        </div> */}
        <div className="text-center text-sm">
          ตั้งแต่ต้นปีที่ผ่านมา ทางเราได้รับทราบถึงการทำงานที่ล่าช้าของระบบ
          และต้องขออภัยทุกๆ ท่านถึงความไม่สะดวกที่เกิดขึ้น
          <br />
          <br />
          เพื่อป้องกันการเกิดเหตุการณ์เหล่านี้
          ทางเราได้สร้างปุ่มสำหรับรายงานปัญหา โดยท่านสามารถแจ้งเราได้ตลอดเวลา{' '}
          <b>โดยกดปุ่มธง</b>
          ทีอยู่บนด้านล้างขวามือของหน้าจอ จากนั้นจึง <b>กดที่ปุ่มเต่า</b>{' '}
          เพื่อแจ้งว่าระบบล่าช้า
        </div>
        <div className="flex justify-center my-2">
          <img src={Slowbotton} className="w-32" />
        </div>
        <div className="text-center text-sm">
          ท่านสามารถแจ้งเข้ามาได้ตลอดเวลา ทันทีที่ท่านแจ้ง
          เราจะส่งการแจ้งเตือนไปยังผู้ดูแลระบบ และจะเก็บเป็นข้อมูลสถิติ ช่วงเวลา
          และส่วนของระบบที่เกิดปัญหา เพื่อแก้ไขและป้องกันการเกิดปัญหาเหล่านี้
          <br />
          <br />
          ทางเรายังคงมีการพัฒนาระบบอย่างต่อเนื่อง
          เพื่อจะส่งมอบประสบการณ์ที่ดีให้กับทุกๆ ท่าน
        </div>
        <div className="flex justify-center my-4">
          <img src={LogoList} className="h-12" />
        </div>
      </div>
    </CampaignModal>
  );
}
