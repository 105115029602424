import CreateDashboardElement from './CreateDashboardElement';
import CreateMMSDashboard from './CreateMMSDashboard';
import Dashboard from './Dashboard';
import DashboardElements from './DashboardElements';
import EditDashboardElement from './EditDashboardElement';
import EditMMSDashboard from './EditMMSDashboard';
import MMSDashboards from './MMSDashboard';
import ViewDashbaordElement from './ViewDashboardElement';
import ViewMMSDashboard from './ViewMMSDashboard';

export {
  CreateDashboardElement,
  CreateMMSDashboard,
  Dashboard,
  DashboardElements,
  EditDashboardElement,
  EditMMSDashboard,
  MMSDashboards,
  ViewDashbaordElement,
  ViewMMSDashboard,
};

export default {
  Dashboard,
  DashboardElements,
  CreateDashboardElement,
  EditDashboardElement,
  ViewDashbaordElement,
  MMSDashboards,
  CreateMMSDashboard,
  EditMMSDashboard,
  ViewMMSDashboard,
};
