import {
  PROCESS_OF_ORDER_ALL,
  PROCESS_OF_ORDER_GET,
  PROCESS_OF_ORDER_POST,
  PROCESS_OF_ORDER_PUT,
  PROCESS_OF_ORDER_DEL,
  PROCESS_OF_ORDER_ERROR,
  PROCESS_OF_ORDER_LOADING,
} from '../../actions/types';

const initialState = {
  processOfOrder: null,
  isLoading: true,
  isCompleted: true,
};

export default function ProcessOfOrderReducer(state = initialState, action) {
  switch (action.type) {
    case PROCESS_OF_ORDER_LOADING:
      return { isLoading: true, isCompleted: true };
    case PROCESS_OF_ORDER_ERROR:
      return { isLoading: false, isCompleted: false, ...action.payload };
    case PROCESS_OF_ORDER_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PROCESS_OF_ORDER_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PROCESS_OF_ORDER_POST:
      return { ...action.payload, isLoading: false, isCompleted: true };
    case PROCESS_OF_ORDER_PUT:
      return { isLoading: false, isCompleted: true };
    case PROCESS_OF_ORDER_DEL:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
