import React from 'react';
import { ExternalLink as ExternalLinkIcon } from 'react-feather';
import { Link } from 'react-router-dom';
import { QUOTATION_STATUS } from '@iarcpsu/emanufac-constant';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { PieChart } from '../../Chart';

const BoxQuotationStatus = ({ quotationAnalytic, startDate, endDate }) => (
  <div>
    <h3 className="font-semibold text-xl">สถานะของใบเสนอราคา</h3>
    <h5>
      ตั้งแต่ {dayjs(startDate).format('D MMMM BBBB')} ถึง{' '}
      {dayjs(endDate).format('D MMMM BBBB')}{' '}
    </h5>
    <div className="flex flex-wrap">
      <div className="w-full lg:w-1/3">
        <PieChart
          donut
          series={[
            quotationAnalytic[QUOTATION_STATUS.SUCCESS.status_code],
            quotationAnalytic[QUOTATION_STATUS.CANCLE.status_code],
            quotationAnalytic[QUOTATION_STATUS.IN_PROGRESS.status_code],
            quotationAnalytic[QUOTATION_STATUS.REVISED.status_code],
          ]}
          labels={[
            QUOTATION_STATUS.SUCCESS.description,
            QUOTATION_STATUS.CANCLE.description,
            QUOTATION_STATUS.IN_PROGRESS.description,
            QUOTATION_STATUS.REVISED.description,
          ]}
          anotherOptions={{
            colors: ['#00E396', '#FF4560', '#008FFB', '#FEB019'],
          }}
          height={600}
        />
      </div>
      <div className="w-full lg:w-2/3">
        <div className="grid grid-cols-3">
          <div>
            <h4 className="text-lg">ทั้งหมด</h4>
            <div className="my-2">
              <span className="text-4xl mr-1">{quotationAnalytic.total}</span>
              <span>รายการ</span>
            </div>
          </div>{' '}
          <div className="lg:col-span-2">
            <Link
              to={`/spm/quotation/by-status?status=${
                QUOTATION_STATUS.IN_PROGRESS.status_code
              }&startDate=${dayjs(startDate).format(
                'YYYY-MM-DD',
              )}&endDate=${dayjs(endDate).format('YYYY-MM-DD')}`}
            >
              <h4 className="text-lg flex gap-1 items-center">
                {QUOTATION_STATUS.IN_PROGRESS.description}
                <ExternalLinkIcon size={18} />
              </h4>
            </Link>
            <div className="my-2">
              <span className="text-4xl mr-1">
                {quotationAnalytic[QUOTATION_STATUS.IN_PROGRESS.status_code]}
              </span>
              <span>รายการ</span>
            </div>
          </div>
          <div className="my-2">
            <Link
              to={`/spm/quotation/by-status?status=${
                QUOTATION_STATUS.SUCCESS.status_code
              }&startDate=${dayjs(startDate).format(
                'YYYY-MM-DD',
              )}&endDate=${dayjs(endDate).format('YYYY-MM-DD')}`}
            >
              <h4 className="text-lg flex gap-1 items-center">
                {QUOTATION_STATUS.SUCCESS.description}
                <ExternalLinkIcon size={18} />
              </h4>
            </Link>

            <div className="my-2">
              <span className="text-4xl mr-1">
                {quotationAnalytic[QUOTATION_STATUS.SUCCESS.status_code]}
              </span>
              <span>รายการ</span>
            </div>
          </div>{' '}
          <div className="my-2">
            <Link
              to={`/spm/quotation/by-status?status=${
                QUOTATION_STATUS.CANCLE.status_code
              }&startDate=${dayjs(startDate).format(
                'YYYY-MM-DD',
              )}&endDate=${dayjs(endDate).format('YYYY-MM-DD')}`}
            >
              <h4 className="text-lg flex gap-1 items-center">
                {QUOTATION_STATUS.CANCLE.description}
                <ExternalLinkIcon size={18} />
              </h4>
            </Link>
            <div className="my-2">
              <span className="text-4xl mr-1">
                {quotationAnalytic[QUOTATION_STATUS.CANCLE.status_code]}
              </span>
              <span>รายการ</span>
            </div>
          </div>
          <div className="my-2">
            <Link
              to={`/spm/quotation/by-status?status=${
                QUOTATION_STATUS.REVISED.status_code
              }&startDate=${dayjs(startDate).format(
                'YYYY-MM-DD',
              )}&endDate=${dayjs(endDate).format('YYYY-MM-DD')}`}
            >
              <h4 className="text-lg flex gap-1 items-center">
                {QUOTATION_STATUS.REVISED.description}
                <ExternalLinkIcon size={18} />
              </h4>
            </Link>
            <div className="my-2">
              <span className="text-4xl mr-1">
                {quotationAnalytic[QUOTATION_STATUS.REVISED.status_code]}
              </span>
              <span>รายการ</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default BoxQuotationStatus;

BoxQuotationStatus.propTypes = {
  quotationAnalytic: PropTypes.object,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
};
