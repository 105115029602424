import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function Navbar({ information }) {
  if (information) {
    return (
      <nav className="top-0  absolute z-50 w-full flex flex-wrap items-center justify-between  navbar-expand-lg">
        <div className="container px-4 py-2 mx-auto flex flex-wrap items-center justify-between ">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              className="text-gray-600 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase"
              to="/"
            >
              {information.name}
            </Link>
          </div>
        </div>
      </nav>
    );
  }
  return <></>;
}

Navbar.propTypes = {
  information: PropTypes.object,
};
