import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material';
import { gql } from 'graphql-request';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  ProductBomForm,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { app as appConfig } from '../../../configs';
import graphqlClient from '../../../configs/graphqlClient';
import * as actions from '../../../redux/actions';

/**
 * @function EditBomTemplate
 * @description Display a collections or a list of SettingEditBOMTemplate from database
 */

export default function EditBomTemplate({ title, subtitle }) {
  const dispatch = useDispatch();
  const material = useSelector((state) => state.material);
  const bomTemplate = useSelector((state) => state.bomTemplate);
  const history = useHistory();

  const params = useParams();
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const query = gql`
    query FindSettingEditProductBom(
      $materialInput: MaterialInput
      $bomTemplateInput: BomTemplateInput
    ) {
      findOneBomTemplate(input: $bomTemplateInput) {
        name
        as_a_batch
        bill_of_materials {
          amount
          tag
          material {
            _id
            name
            type_code
            use_unit_conversion
            unit
            conversion_from {
              unit_a
              unit_b
              conversion_factor
            }
            conversion_to {
              unit_b
              unit_a
              conversion_factor
            }
            unit_input {
              _id
              short_sign
              name
            }
            unit_output {
              _id
              short_sign
              name
            }
          }
        }
      }
      findMaterials(input: $materialInput) {
        total
        rows {
          _id
          name
          type_code
          use_unit_conversion
          unit
          conversion_from {
            unit_a
            unit_b
            conversion_factor
          }
          conversion_to {
            unit_b
            unit_a
            conversion_factor
          }
          unit_input {
            _id
            short_sign
            name
          }
          unit_output {
            _id
            short_sign
            name
          }
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        materialInput: {
          page: 1,
          size: appConfig.maxFetchSize,
          fetchStockLot: false,
        },
        bomTemplateInput: {
          id: params.id,
        },
      });
      const materialData = queryResult?.findMaterials;
      dispatch(actions.materialStateSet(materialData));
      const bomTemplateData = queryResult?.findOneBomTemplate;
      dispatch(actions.bomTemplateStateOneSet(bomTemplateData));
    } catch (err) {
      console.error('Query Fetch Error', err?.message);
      alert(`การดึงข้อมูลผิดพลาด${err?.message}`);
      dispatch(actions.bomTemplateError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const handleEditBomTemplate = async (data) => {
    console.log(`Error In Form ${errors}`);
    try {
      console.log('Edit Bom Template', data);
      await dispatch(actions.bomTemplatePut(params.id, data));
      alert('สำเร็จ');
      history.goBack();
    } catch (err) {
      alert(`ไม่สามารถแก้ไขสูตรส่วนผสมได้ ${err?.message}`);
    }
  };

  useEffect(() => {
    if (bomTemplate && !bomTemplate?.rows) {
      setValue('bill_of_materials', bomTemplate?.bill_of_materials);
      setValue('as_a_batch', bomTemplate?.as_a_batch);
      setValue('name', bomTemplate?.name);
    }

    return () => {};
  }, [bomTemplate]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (material.isLoading || bomTemplate.isLoading) {
    return <Loading />;
  }

  if (
    !material.isLoading &&
    material.isCompleted &&
    !bomTemplate.isLoading &&
    bomTemplate.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <BackButton />
        </div>
        <div>
          <Card>
            <CardContent>
              <form onSubmit={handleSubmit(handleEditBomTemplate)}>
                <div className="w-full px-1 py-2">
                  <Controller
                    control={control}
                    name="name"
                    defaultValue={bomTemplate?.name}
                    render={({ field }) => (
                      <TextField
                        size="small"
                        {...field}
                        label="ชื่อ"
                        fullWidth
                        required
                        helperText={errors?.name && 'กรุณาใสข้อมูล'}
                      />
                    )}
                  />
                </div>
                <div className="w-full px-1 py-2">
                  <Controller
                    control={control}
                    name="as_a_batch"
                    defaultValue={bomTemplate?.as_a_batch}
                    render={({ field }) => (
                      <FormControlLabel
                        label="เป็นส่วนผสมต่อ 1 Batch"
                        control={
                          <Checkbox
                            size="small"
                            {...field}
                            defaultChecked={field.value}
                          ></Checkbox>
                        }
                      />
                    )}
                  />
                </div>
                <ProductBomForm
                  control={control}
                  errors={errors}
                  material={material}
                  setValue={setValue}
                  watch={watch}
                  showProduct={false}
                />
                <div className="flex justify-end">
                  <Button variant="contained" type="submit">
                    บันทึก
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditBomTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditBomTemplate.defaultProps = {
  title: '',
  subtitle: '',
};
