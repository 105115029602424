import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import * as actions from '@/redux/actions';

/**
 * @function EditManyProduct
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function EditManyProduct({ title, subtitle }) {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const page = location?.state?.page || 1;
  const size = location?.state?.size || 10;
  const name = location?.state?.name || '';
  const { control, setValue, handleSubmit, watch } = useForm();

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.productAll({ page, size, fetchStockLot: false, name }));
    } catch (error) {
      dispatch(actions.productError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  useEffect(() => {
    if (product?.rows) {
      setValue('products', product?.rows);
    }

    return () => {};
  }, [product]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (product.isLoading) {
    return <Loading />;
  }

  if (!product.isLoading && product.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div></div>
      </div>
    );
  }
  return <Error />;
}

EditManyProduct.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditManyProduct.defaultProps = {
  title: '',
  subtitle: '',
};
