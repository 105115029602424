import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Autocomplete,
  Card,
  CardContent,
  LinearProgress,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { config } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';

import MaterialOnBomBox from './MaterialOnBomBox';

function ProduceNewProductCard({ selectedProduct, amount }) {
  const [foundedProduct, setFoundProduct] = useState(null);
  const [loading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState('0');
  const [selectedBomTemplate, setSelectedBomTemplate] = useState(null);
  const bomTemplate = useSelector((state) => state.bomTemplate);
  const dispatch = useDispatch();
  const query = gql`
    query FindProduct(
      $productInput: ProductInput
      $bomTemplateInput: BomTemplateInput
    ) {
      findOneProduct(input: $productInput) {
        _id
        name
        steps {
          index
          name
          materials {
            material {
              _id
              name
              type_code
              use_unit_conversion
              unit
              conversion_from {
                unit_a
                unit_b
                conversion_factor
              }
              conversion_to {
                unit_b
                unit_a
                conversion_factor
              }
              unit_input {
                _id
                short_sign
                name
              }
              unit_output {
                _id
                short_sign
                name
              }
            }
            amount
          }
        }
        bill_of_materials {
          material {
            _id
            name
            type_code
            use_unit_conversion
            unit
            conversion_from {
              unit_a
              unit_b
              conversion_factor
            }
            conversion_to {
              unit_b
              unit_a
              conversion_factor
            }
            unit_input {
              _id
              short_sign
              name
            }
            unit_output {
              _id
              short_sign
              name
            }
          }
          amount
          tag
        }
      }
      findBomTemplates(input: $bomTemplateInput) {
        rows {
          _id
          name
          bill_of_materials {
            material {
              _id
              name
              type_code
              use_unit_conversion
              unit
              conversion_from {
                unit_a
                unit_b
                conversion_factor
              }
              conversion_to {
                unit_b
                unit_a
                conversion_factor
              }
              unit_input {
                _id
                short_sign
                name
              }
              unit_output {
                _id
                short_sign
                name
              }
            }
            amount
          }
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        productInput: {
          id: selectedProduct._id,
          fetchBOM: true,
          fetchMaterial: true,
        },
        bomTemplateInput: {
          page: 1,
          size: config.maxFetchSize,
        },
      });

      const productData = queryResult?.findOneProduct;
      const findBomData = queryResult?.findBomTemplates;
      console.log('Founed Product', productData);
      setFoundProduct(productData);
      dispatch(actions.bomTemplateStateSet(findBomData));
      setIsLoading(false);
    } catch (error) {
      console.error('Product Get Error', error);
    }
  };

  useEffect(() => {
    if (selectedProduct) {
      queryDataFromServer();
    }

    return () => {};
  }, [selectedProduct]);

  return (
    <div className="my-2">
      <Card>
        <CardContent>
          <div className="font-semibold font-display">ผลิตสินค้าเพิ่ม</div>
          {loading && <LinearProgress />}
          <Tabs
            value={selectedTab}
            onChange={(e, newValue) => setSelectedTab(newValue)}
            aria-label="basic tabs example"
          >
            <Tab value="0" label="ใช้ส่วนผสม (BOM) ในสินค้านี้" />
            <Tab value="1" label="ใช้สูตร BOM" />
          </Tabs>
          {selectedTab === '0' && (
            <div className="mt-2">
              {_.map(foundedProduct?.bill_of_materials, (bom, index) => (
                <div className="w-full my-2 border" key={index}>
                  <div className="p-2">
                    <MaterialOnBomBox bomObject={bom} productAmount={amount} />
                  </div>
                </div>
              ))}
            </div>
          )}{' '}
          {selectedTab === '1' && (
            <div className="mt-2">
              {!_.isEmpty(bomTemplate?.rows) && (
                <Autocomplete
                  disablePortal
                  options={bomTemplate?.rows}
                  size="small"
                  placeholder="เลือกระบบ"
                  onChange={(e, newValue) => {
                    setSelectedBomTemplate(newValue);
                  }}
                  className="z-40"
                  // prettier-ignore
                  getOptionLabel={(option) => `${option?.name || ''}`
                }
                  renderInput={(params) => (
                    <TextField label="เลือกสูตร BOM" {...params} />
                  )}
                />
              )}
              {selectedBomTemplate &&
                _.map(selectedBomTemplate?.bill_of_materials, (bom, index) => (
                  <div className="w-full my-2 border" key={index}>
                    <div className="p-2">
                      <MaterialOnBomBox
                        bomObject={bom}
                        productAmount={amount}
                      />
                    </div>
                  </div>
                ))}
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default ProduceNewProductCard;

ProduceNewProductCard.propTypes = {
  selectedProduct: PropTypes.object,
  amount: PropTypes.number,
};
