import api from '../../configs/api';

import { removeStorage, setStorage } from './localstorage';

export const authMethods = {
  signup: async (username, password, firstname = '', lastname = '') => {
    api
      .post(`${process.env.REACT_APP_API_URL}/user/register`, {
        username,
        password,
        firstname,
        lastname,
        lock: true,
      })
      .then(async (res) => {
        alert(
          'ดำเนินการสมัครสมาชิกเสร็จสิ้น ทางเราจะแจ้งไปยังเจ้าหน้าที่ เมื่อท่านได้รับอนุญาตจากผู้ดูแลระบบแล้วจึงจะสามารถลงชื่อเข้าใช้เข้าสู่ระบบได้',
        );
      })
      .catch((error) => {
        window.alert(error?.message);
        throw new Error(error);
      });
  },
  signin: async (username, password) => {
    try {
      console.log('On Signin');
      const res = await api.post(
        `${process.env.REACT_APP_API_URL}/user/login`,
        {
          username,
          password,
        },
      );
      console.log('Res.data', res.data);
      const { userData: data = {} } = res?.data;
      const remember = {
        id: data?.id,
        uid: data?.uid,
        firstname: data?.firstname,
        lastname: data?.lastname,
        department: {
          name: data?.department?.name,
          description: data?.department?.description,
        },
      };
      console.log('Remeber', remember);
      const token = res?.data?.accessToken;
      console.log('token setting');
      await setStorage('token', token);
      await setStorage('remember', JSON.stringify(remember));
      return res.data;
    } catch (e) {
      console.log(e);
      throw new Error(e);
    }
  },
  signout: async () => {
    removeStorage('token');
    removeStorage('remember');
  },
};

export default authMethods;
