import React from 'react';
import { ExternalLink } from 'react-feather';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { MMS } from '@/utils/functions';

import MultiUnitProdOrMatBox from '../Rendering/MultiUnitProdOrMatBox';

/**
 * @function ProcessMaterialCostDriverBox
 * @description ส่วนประกอบต้นทุนจากวัตถุดิบ
 */
const ProcessMaterialCostDriverBox = ({ currentProcess }) => {
  const manufacturingMaterial = currentProcess?.manufacturing_materials;
  const materialTransaction = currentProcess?.material_transactions;

  const { findMaterialCost } = MMS;
  const manufacMaterialWithLot = findMaterialCost(
    manufacturingMaterial,
    materialTransaction,
  );
  console.log('manufacMaterialWithLot', manufacMaterialWithLot);

  return (
    <div className="my-2">
      {_.map(manufacMaterialWithLot, (eachMaterial, index) => (
        <div key={index} className="my-2">
          <h4 className="font-semibold">
            {index + 1}. {eachMaterial.materialInfo?.type_code}{' '}
            {eachMaterial.materialInfo?.name}
          </h4>
          <div>
            <MultiUnitProdOrMatBox
              foundMaterial={eachMaterial?.materialInfo}
              quantity={eachMaterial?.resultAmount}
            />{' '}
          </div>
          <div className="my-2 flex flex-wrap">
            {_.map(
              eachMaterial?.material_stock_lot,
              (eachStocklot, lotIndex) => (
                <div key={lotIndex} className="w-full md:w-1/3 lg:w-1/5">
                  <div className="font-semibold flex items-center">
                    <div>ล็อตที่ {lotIndex + 1}</div>
                    <Link
                      to={`/ims/material-stock-lot/${eachStocklot.lotId?._id}`}
                    >
                      <IconButton>
                        <ExternalLink size={16} />
                      </IconButton>
                    </Link>
                  </div>
                  <div className="text-sm">
                    <div>
                      เลขอ้างอิงล็อต : {eachStocklot.lotId?.running_number}
                    </div>
                    <div>
                      หมายเลขล็อต : {eachStocklot.lotId?.lot_number || '-'}
                    </div>
                    <div className="flex gap-1">
                      <div>จำนวน : </div>
                      <MultiUnitProdOrMatBox
                        foundMaterial={eachMaterial?.materialInfo}
                        quantity={eachStocklot?.quantity}
                      />{' '}
                    </div>
                    <div>
                      ต้นทุนต่อหน่วย :{' '}
                      {(eachStocklot?.unit_cost || 0)?.toFixed(3)} บาท
                    </div>
                  </div>
                </div>
              ),
            )}
          </div>
          <hr />
        </div>
      ))}{' '}
    </div>
  );
};

export default ProcessMaterialCostDriverBox;

ProcessMaterialCostDriverBox.propTypes = {
  currentProcess: PropTypes.object,
};
