import { red, yellow } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  palette: {
    amber: {
      main: '#ffc107',
    },
    primary: {
      main: localStorage.getItem('ERP_MAIN_COLOR') || '#2541B2',
    },
    cyan: {
      main: localStorage.getItem('ERP_MAIN_CYAN') || '#00bcd4',
    },
    teal: {
      main: localStorage.getItem('ERP_MAIN_TEAL') || '#009688',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: localStorage.getItem('ERP_MAIN_SECONDARY') || '#3B82F6',
    },
    indigo: {
      main: '#065F46',
    },
    orange: {
      main: '#FF6F00',
      contrastText: '#FFFFFF',
    },
    purple: {
      main: '#673AB7',
      contrastText: '#FFFFFF',
    },
    magenta: {
      main: '#EE0290',
      contrastText: '#FFFFFF',
    },
    error: {
      main: localStorage.getItem('ERP_MAIN_ERROR') || red.A400,
    },
    warning: {
      main: localStorage.getItem('ERP_MAIN_WARNING') || yellow[800],
    },
    success: {
      main: '#10B981',
      contrastText: '#FFFFFF',
    },
    lightGreen: {
      main: '#c8e6c9',
    },
    gray: {
      main: '#9e9e9e',
    },
    background: {
      default: '#F4F5F7',
    },
    dark: {
      default: '#000000',
    },
  },
  typography: {
    fontFamily: [
      'Noto Sans Thai Looped',
      'Noto Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    // fontSize: 12,
  },
});

export default theme;
