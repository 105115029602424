import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Avatar, TextField } from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { config } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';

const PersonInput = ({
  control,
  measurementType,
  measurementControllerName = '',
  defaultValue,
}) => {
  const dispatch = useDispatch();
  const employee = useSelector((state) => state.employee);
  const [isError, setIsError] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const query = gql`
    query FindEmployee($input: EmployeeInput) {
      findEmployees(input: $input) {
        rows {
          _id
          firstname
          lastname
          image {
            url
          }
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        input: { page: 1, size: config.maxFetchSize },
      });
      const employeeData = queryResult?.findEmployees;
      dispatch(actions.employeeStateSet(employeeData));
      setIsReady(true);
    } catch (error) {
      setIsError(true);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, []);

  if (isError) {
    return <div>ขออภัย ไม่สามารถใส่ข้อมูลตรงนี้ได้</div>;
  }

  if (!isReady) {
    return <div>กำลังโหลดข้อมูล...</div>;
  }

  return (
    <div>
      <div>
        {!_.isEmpty(employee?.rows) && (
          <Controller
            control={control}
            name={`${measurementControllerName}.person_value`}
            defaultValue={defaultValue || null}
            render={({ field }) => (
              <Autocomplete
                {...field}
                disablePortal
                options={employee?.rows}
                placeholder="เลือกพนักงาน"
                onChange={(e, newValue) => {
                  field.onChange(newValue);
                }}
                // prettier-ignore
                defaultValue={field.value}
                getOptionLabel={(option) =>
                  `${option?.firstname || ''} ${option?.lastname || ''}`
                }
                renderInput={(params) => (
                  <TextField label="เลือกพนักงาน" {...params} />
                )}
                renderOption={(props, option) => (
                  <div className="flex" {...props}>
                    <div className="w-1/6">
                      <Avatar src={option?.image?.url}>
                        {option?.firstname} {option?.lastname}
                      </Avatar>
                    </div>
                    <div className="w-5/6">
                      {option.firstname} {option.lastname}
                    </div>
                  </div>
                )}
              />
            )}
          />
        )}
      </div>
    </div>
  );
};

PersonInput.propTypes = {
  control: PropTypes.object,
  measurementType: PropTypes.object,
  measurementControllerName: PropTypes.string,
  defaultValue: PropTypes.string,
};

export default PersonInput;
