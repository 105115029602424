/* eslint-disable no-confusing-arrow */
import dayjs from 'dayjs';

const columns = [
  {
    label: 'ลำดับที่',
    value: 'index',
  },
  {
    label: 'หมายเลขใบเบิกใช้ภายใน',
    value: (col) => `${col?.prefix}${col?.running_number}`,
  },
  {
    label: 'หมายเลขใบเสนอราคา',
    value: (col) =>
      `${col?.quotation?.prefix || ''}${col?.quotation?.running_number || '-'}`,
  },
  {
    label: 'วันที่สร้าง',
    value: (col) => dayjs(col?.start_date).format('D/MM/BBBB'),
  },
  {
    label: 'วันกำหนดจัดส่ง',
    value: (col) => dayjs(col?.expected_date).format('D/MM/BBBB'),
  },
  {
    label: 'ลูกค้า',
    value: (col) =>
      `${col?.customer?.type_code || ''} ${col?.customer?.name || ''}`,
  },

  {
    label: 'ผู้สร้าง',
    value: (col) =>
      `${col?.creator?.firstname || ''} ${col?.creator?.lastname || ''}`,
  },
  {
    label: 'สถานะ',
    value: (col) =>
      col?.completed
        ? 'สร้างคำสั่งผลิตแล้ว'
        : col?.deleted
        ? 'ยกเลิก'
        : 'กำลังดำเนินการ',
  },
];

export default { columns };
