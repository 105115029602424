import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import { gql } from 'graphql-request';
import PropTypes from 'prop-types';

import {
  BackButton,
  BaseProjectForm,
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { config } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';

/**
 * @function CreateBaseProject
 * @description Editing Base Project
 */

export default function CreateBaseProject({ title, subtitle }) {
  const dispatch = useDispatch();
  const processTemplate = useSelector((state) => state.processTemplate);
  const bomTemplate = useSelector((state) => state.bomTemplate);
  const history = useHistory();
  const params = useParams();
  const {
    formState: { errors },
    control,
    handleSubmit,
    watch,
    setValue,
  } = useForm({});

  const query = gql`
    query FindSettingEditProductBom(
      $processTemplateInput: ProcessTemplateInput
      $bomTemplateInput: BomTemplateInput
    ) {
      findProcessTemplates(input: $processTemplateInput) {
        rows {
          _id
          name
        }
      }
      findBomTemplates(input: $bomTemplateInput) {
        rows {
          _id
          name
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    const queryResult = await graphqlClient.request(query, {
      processTemplateInput: { page: 1, size: config.maxFetchSize },
      bomTemplateInput: { page: 1, size: config.maxFetchSize },
    });

    const processTemplateData = queryResult?.findProcessTemplates;
    const bomTemplateData = queryResult?.findBomTemplates;
    dispatch(actions.processTemplateStateSet(processTemplateData));
    dispatch(actions.bomTemplateStateSet(bomTemplateData));
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const onSubmit = async (data) => {
    try {
      await dispatch(actions.baseProjectCreate(data));
      history.goBack();
    } catch (error) {
      alert(`ไม่สามารถแก้ไขได้ ${error?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (bomTemplate?.isLoading || processTemplate?.isLoading) {
    return <Loading />;
  }

  if (processTemplate?.isCompleted && bomTemplate?.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <Card>
            <CardContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <BaseProjectForm
                  control={control}
                  bomTemplate={bomTemplate}
                  processTemplate={processTemplate}
                  errors={errors}
                  watch={watch}
                  setValue={setValue}
                />
                <div className="flex justify-end">
                  <Button variant="contained" type="submit">
                    บันทึก
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

CreateBaseProject.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateBaseProject.defaultProps = {
  title: '',
  subtitle: '',
};
