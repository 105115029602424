import React from 'react';
import { Card, InputAdornment, TextField } from '@mui/material';
import PropTypes from 'prop-types';

const SearchBox = ({ label, setValue }) => (
  <Card>
    <div className="p-4 flex flex-row">
      <div className="w-full md:w-1/2">
        <TextField
          label={label}
          fullWidth
          size={'small'}
          id="outlined-start-adornment"
          onChange={(e) => {
            setValue(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <i className="fas fa-search"></i>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </div>
  </Card>
);

SearchBox.propTypes = {
  label: PropTypes.string,
  setValue: PropTypes.func,
};

export default SearchBox;
