import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { MATERIAL_STOCK_STATUS } from '@iarcpsu/emanufac-constant';
import {
  Autocomplete,
  Button,
  Skeleton,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { currencyFormatter } from '@/utils/functions';

import { config } from '../../../configs';
import graphqlClient from '../../../configs/graphqlClient';
import MaterialQuantityDisplay from '../../Box/Material/MaterialQuantityDisplay';
import { MaterialWithOrWithoutLotQuantityInput } from '../../Inputs';

const EachMaterialRowOnWithdrawForm = ({
  watch,
  index,
  _field,
  control,
  setValue,
  allMaterial,
  reload,
  setReload,
  remove,
  errors,
  requestFromProcess,
  requireConfirmation,
  usingFIFO,
}) => {
  const selectMaterialId = watch(`materialLots[${index}].material`)?._id;
  console.log('Select Material ID', selectMaterialId);
  const [stockLotsData, setStockLotsData] = useState([]);
  const [isReady, setIsReady] = useState(false);

  const fetchData = async () => {
    const query = gql`
      query findMaterialStockLot(
        $materialStockLotInput: MaterialStockLotInput
      ) {
        findMaterialStockLots(input: $materialStockLotInput) {
          rows {
            _id
            material {
              _id
              name
              type_code
            }
            status
            quantity
            amount
            receipt_date
            lot_number
            running_number
          }
        }
      }
    `;
    try {
      const queryResult = await graphqlClient.request(query, {
        materialStockLotInput: {
          status: MATERIAL_STOCK_STATUS.IN_STOCK.status_code,
          page: 1,
          size: config.maxFetchSize,
          material: selectMaterialId,
        },
      });
      const tempStockLotData = queryResult?.findMaterialStockLots;
      setStockLotsData(tempStockLotData?.rows);
    } catch (err) {
      console.error(`Error fetching stock lots: ${err}`);
      setStockLotsData([]);
    } finally {
      setIsReady(true);
    }
  };

  useEffect(() => {
    if (selectMaterialId) {
      setIsReady(false);
      setStockLotsData([]);
      fetchData();
    }
  }, [selectMaterialId]);
  return (
    <TableRow key={_field?.id}>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        <div className="w-64">
          {_field?.fromDatabase === true ? (
            <div>{_field?.allMaterial?.name}</div>
          ) : (
            <div>
              {watch(`materialLots[${index}].tag`) && (
                <div className="my-2">
                  Tag {watch(`materialLots[${index}].tag`)}
                </div>
              )}{' '}
              <Controller
                control={control}
                name={`materialLots[${index}].runOutOff`}
                defaultValue={false}
                render={({ field }) => <input {...field} hidden />}
              />{' '}
              <Controller
                control={control}
                name={`materialLots[${index}].originalMaterial`}
                render={({ field }) => <input {...field} hidden />}
              />
              {watch(`materialLots[${index}].runOutOff`) && (
                <div className="rounded-md bg-yellow-100 p-2 my-2">
                  วัตถุดิบที่เลือก{' '}
                  <b>
                    {watch(`materialLots[${index}].originalMaterial`)?.name}
                  </b>
                  ในคลังหมดแล้ว
                  {watch(`materialLots[${index}].createRequestOfMaterial`) && (
                    <div className="my-2">
                      <Button
                        variant="contained"
                        color="warning"
                        size="small"
                        onClick={() => {
                          setValue(
                            `materialLots[${index}].material`,
                            watch(`materialLots[${index}]`)?.originalMaterial,
                          );
                          setValue(`materialLots[${index}].force`, true);
                        }}
                      >
                        ยืนยันจะเบิกชิ้นนี้
                      </Button>
                    </div>
                  )}
                </div>
              )}
              <Controller
                control={control}
                name={`materialLots[${index}].material`}
                defaultValue={
                  watch(`materialLots[${index}].material`) ||
                  allMaterial?.rows?.[0]
                }
                // eslint-disable-next-line no-confusing-arrow
                render={({ field }) =>
                  !_.isEmpty(allMaterial?.rows) ? (
                    <Autocomplete
                      size={'small'}
                      disableClearable
                      defaultValue={''}
                      {...field}
                      onChange={(e, newValue) => {
                        if (newValue?.autofetching) {
                          setValue(`materialLots[${index}].autofetching`, true);
                        }
                        field.onChange(newValue);
                        setReload(!reload);
                      }}
                      options={allMaterial?.rows}
                      getOptionLabel={(option) => {
                        if (option?.type_code) {
                          return `${option?.type_code} | ${option?.name}`;
                        }
                        if (option?.autofetching) {
                          return `${option?.type_code || ''} | ${
                            option?.name
                          } (WIP)`;
                        }
                        return `${option?.name}`;
                      }}
                      required
                      renderInput={(params) => (
                        <TextField {...params} label="วัตถุดิบ" required />
                      )}
                    />
                  ) : (
                    <div></div>
                  )
                }
              />
              {watch(`materialLots[${index}].begin`) &&
                watch(`materialLots[${index}].begin`) >= 0 && (
                  <div className="my-2">
                    ต้องการ
                    {watch(`materialLots[${index}].reservedMaterialName`)}{' '}
                    {currencyFormatter.format(
                      watch(`materialLots[${index}].begin`),
                    )}
                    {watch(`materialLots[${index}]`)?.material
                      ?.use_unit_conversion
                      ? ` ${
                          watch(`materialLots[${index}]`)?.allMaterial
                            ?.unit_input?.name || ''
                        }`
                      : `${
                          watch(`materialLots[${index}]`)?.allMaterial?.unit ||
                          ''
                        }`}
                  </div>
                )}
            </div>
          )}
        </div>
      </TableCell>
      {/** ถ้ามันเป็นการทำแบบร้องขอ Confirm ไม่ต้องมีการเลือก Stock lots */}
      <TableCell>
        {isReady ? (
          <MaterialQuantityDisplay
            control={control}
            dataField={_field}
            index={index}
            material={allMaterial}
            requestFromProcess={requestFromProcess}
            requireConfirmation={requireConfirmation}
            usingFIFO={usingFIFO}
            watch={watch}
            stocklots={stockLotsData}
          />
        ) : (
          <Skeleton />
        )}
      </TableCell>

      <TableCell>
        {isReady ? (
          <MaterialWithOrWithoutLotQuantityInput
            control={control}
            errors={errors}
            fieldData={_field}
            usingFIFO={usingFIFO}
            index={index}
            material={allMaterial}
            requestFromProcess={requestFromProcess}
            requireConfirmation={requireConfirmation}
            watch={watch}
            setValue={setValue}
            stockLotsData={stockLotsData}
          />
        ) : (
          <Skeleton />
        )}
      </TableCell>
      <TableCell>
        <div className="w-16 flex flex-col gap-2">
          <Button
            size="small"
            variant="contained"
            color="error"
            disabled={_field?.fromDatabase === true}
            onClick={() => remove(index)}
          >
            ลบ
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default EachMaterialRowOnWithdrawForm;

EachMaterialRowOnWithdrawForm.propTypes = {
  watch: PropTypes.func,
  index: PropTypes.number,
  _field: PropTypes.object,
  control: PropTypes.object,
  setValue: PropTypes.func,
  allMaterial: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  reload: PropTypes.bool,
  setReload: PropTypes.func,
  remove: PropTypes.func,
  errors: PropTypes.object,
  requestFromProcess: PropTypes.bool,
  requireConfirmation: PropTypes.bool,
  usingFIFO: PropTypes.bool,
};
