import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DaySpotAndRangePicker,
  Error,
  Loading,
  MaterialLotCostEstimationTable,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import { Autocomplete, Card, CardContent, TextField } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { config } from '@/configs';
import * as actions from '@/redux/actions';

/**
 * @function CostEstimationByLots
 * @description Display a collections or a list of CostEstimationByLots from database
 */

export default function CostEstimationByLots({ title, subtitle }) {
  const dispatch = useDispatch();
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const place = useSelector((state) => state.place);
  const information = useSelector((state) => state.information);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [total, setTotal] = useState(0);

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.materialStockLotAll({
          page,
          size,
          startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
          endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : '',
          place: selectedPlace ? selectedPlace?._id : '',
          placeRestricNotify: selectedPlace ? true : '',
          correctPlaceOnly: selectedPlace ? true : '',
        }),
      );
    } catch (error) {
      dispatch(actions.materialStockLotError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, startDate, endDate, selectedPlace]);

  useEffect(() => {
    dispatch(actions.placeAll({ page, size: config.maxFetchSize }));

    return () => {};
  }, []);

  useEffect(() => {
    if (
      information?.setting?.ims?.cost_estimation
        ?.default_place_in_cost_estimation
    ) {
      const foundPlace = _.find(
        place?.rows,
        (each) =>
          each?._id ===
          information?.setting?.ims?.cost_estimation
            ?.default_place_in_cost_estimation,
      );
      setSelectedPlace(foundPlace);
    }

    return () => {};
  }, [information, place]);

  useEffect(() => {
    if (materialStockLot) {
      setTotal(materialStockLot.total);
    }

    return () => {};
  }, [materialStockLot]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (materialStockLot.isLoading) {
    return <Loading />;
  }
  if (!materialStockLot.isLoading && materialStockLot.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="flex flex-wrap items-center">
                <div className="w-full md:w-1/5 pr-2">
                  <Autocomplete
                    disablePortal
                    value={selectedPlace}
                    options={place?.rows}
                    size="small"
                    placeholder="เลือกคลัง"
                    onChange={(e, newValue) => {
                      setSelectedPlace(newValue);
                    }}
                    className="z-40"
                    // prettier-ignore
                    getOptionLabel={(option) => `${option?.name || ''}`
                    }
                    isOptionEqualToValue={(option, value) =>
                      option?._id === value?._id
                    }
                    renderInput={(params) => (
                      <TextField label="เลือกคลัง" {...params} />
                    )}
                  />
                </div>
                <div className="w-full md:w-4/5">
                  <DaySpotAndRangePicker
                    flex
                    enable
                    endDate={endDate}
                    setEndDate={setEndDate}
                    startDate={startDate}
                    setStartDate={setStartDate}
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="my-2">
          <MaterialLotCostEstimationTable
            imsSetting={information?.setting?.ims}
            materialStockLot={materialStockLot}
            page={page}
            setPage={setPage}
            setSize={setSize}
            size={size}
            total={total}
          />
        </div>
      </div>
    );
  }
  return <Error />;
}

CostEstimationByLots.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CostEstimationByLots.defaultProps = {
  title: '',
  subtitle: '',
};
