import DefaultMenu from './DefaultMenu';
import Diprom1 from './Diprom1';
import Diprom2 from './Diprom2';
import Diprom3 from './Diprom3';
import Diprom4 from './Diprom4';
import LeftMenu from './LeftMenu';
import LowerMenu from './LowerMenu';
import RightMenu from './RightMenu';

export {
  DefaultMenu,
  Diprom1,
  Diprom2,
  Diprom3,
  Diprom4,
  LeftMenu,
  LowerMenu,
  RightMenu,
};
export default {
  DefaultMenu,
  LeftMenu,
  LowerMenu,
  RightMenu,
  Diprom1,
  Diprom2,
  Diprom3,
  Diprom4,
};
