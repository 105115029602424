/* eslint-disable no-plusplus */
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import _ from 'lodash';

import * as CONSTANT from '../../constants';

dayjs.extend(duration);

export default function resultingFunctionExecutor({
  resultingFunctionType,
  input = [],
  enableLog = false,
}) {
  let variableCount = 0;
  let resultValue = 0;

  _.map(resultingFunctionType?.executor, (eachExecutor, index) => {
    let stepInputData = 0;
    if (enableLog) console.log('Each Executor', eachExecutor);
    if (eachExecutor?.using_constant) {
      console.log('Using Constant');
      if (eachExecutor?.using_static_value) {
        console.log('Static Value Constant', eachExecutor?.static_value);
        stepInputData = parseFloat(eachExecutor?.static_value);
      } else {
        console.log('Constant', eachExecutor?.constant);
        stepInputData = parseFloat(eachExecutor?.constant?.value);
      }
    } else {
      stepInputData = input[variableCount];
      variableCount++;
    }
    if (enableLog) {
      console.log('--------');
      console.log('stepInputData', stepInputData);
      console.log('Result value', resultValue);
    }

    // First step of Execution
    if (index === 0) {
      resultValue = stepInputData;
    }
    // Result value is Date and input is Date
    else if (
      typeof stepInputData === 'object' &&
      typeof resultValue === 'object'
    ) {
      switch (resultingFunctionType?.executor?.[index - 1]?.operation) {
        case CONSTANT.MATH_OPERATION.SUBTRACT.type_code:
          resultValue = dayjs(resultValue).diff(stepInputData);
          break;
        case CONSTANT.MATH_OPERATION.END_OFF_CALCULATION.type_code:
          break;
        default:
          resultValue = stepInputData;
          break;
      }
    } else if (
      typeof stepInputData === 'number' &&
      typeof resultValue === 'object'
    ) {
      switch (resultingFunctionType?.executor?.[index - 1]?.operation) {
        case CONSTANT.MATH_OPERATION.ADD.type_code:
          resultValue = dayjs(resultValue).add(stepInputData);
          break;
        case CONSTANT.MATH_OPERATION.SUBTRACT.type_code:
          resultValue = dayjs(resultValue).subtract(stepInputData);
          break;
        case CONSTANT.MATH_OPERATION.END_OFF_CALCULATION.type_code:
          break;
        default:
          resultValue = stepInputData;
          break;
      }
    } else {
      // In Another Step use operation from the before operation index
      switch (resultingFunctionType?.executor?.[index - 1]?.operation) {
        case CONSTANT.MATH_OPERATION.ADD.type_code:
          resultValue += stepInputData;
          break;
        case CONSTANT.MATH_OPERATION.SUBTRACT.type_code:
          resultValue -= stepInputData;
          break;
        case CONSTANT.MATH_OPERATION.MULTIPLY.type_code:
          resultValue *= stepInputData;
          break;
        case CONSTANT.MATH_OPERATION.DIVIDE.type_code:
          resultValue /= stepInputData;
          break;
        case CONSTANT.MATH_OPERATION.END_OFF_CALCULATION.type_code:
          break;
        default:
          resultValue = stepInputData;
          break;
      }
    }

    // last step of Execution do excute again with before operation index
    // In Last step must 2 round of execute
    // if (index === _.size(resultingFunctionType?.executor) - 1) {
    //   switch (resultingFunctionType?.executor?.[index - 1]?.operation) {
    //     case CONSTANT.MATH_OPERATION.ADD.type_code:
    //       resultValue += stepInputData;
    //       break;
    //     case CONSTANT.MATH_OPERATION.SUBTRACT.type_code:
    //       resultValue -= stepInputData;
    //       break;
    //     case CONSTANT.MATH_OPERATION.MULTIPLY.type_code:
    //       resultValue *= stepInputData;
    //       break;
    //     case CONSTANT.MATH_OPERATION.DIVIDE.type_code:
    //       resultValue /= stepInputData;
    //       break;
    //     case CONSTANT.MATH_OPERATION.END_OFF_CALCULATION.type_code:
    //       break;
    //     default:
    //       resultValue = stepInputData;
    //       break;
    //   }
    // }
    if (enableLog) {
      console.log('Resulting Variable of this step', resultValue);
      console.log('--------');
    }
  });

  return resultValue;
}
