import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField, Autocomplete } from '@mui/material';
import PropTypes from 'prop-types';

const OrganizationConstantForm = ({
  control,
  errors,
  units,
  organizationConstant,
}) => (
  <div className="flex flex-row flex-wrap">
    <div className="w-full md:w-1/4 px-1 py-2">
      <Controller
        name={'type_code'}
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            {...field}
            label="รหัสค่า"
            fullWidth
            required
            size={'small'}
            helperText={errors.type_code && 'กรุณาใส่รหัสค่า'}
          />
        )}
      />
    </div>
    <div className="w-full md:w-1/4 px-1 py-2">
      <Controller
        name={'name'}
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            {...field}
            label="ชื่อค่า"
            fullWidth
            required
            size={'small'}
            helperText={errors.name && 'กรุณาใส่ชื่อของค่า'}
          />
        )}
      />
    </div>
    <div className="w-full md:w-1/4 px-1 py-2">
      <Controller
        name={'value'}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="ค่าที่ต้องการ"
            fullWidth
            size={'small'}
            helperText={errors.value && 'กรุณาใส่ค่าที่ต้องการ'}
          />
        )}
      />
    </div>
    <div className="w-full md:w-1/4 px-1 py-2">
      <Controller
        name={'unit'}
        control={control}
        rules={{ required: false }}
        defaultValue={organizationConstant ? organizationConstant?.unit : ''}
        render={({ field }) => (
          <Autocomplete
            {...field}
            size="small"
            disablePortal
            options={units?.rows}
            placeholder="หน่วย"
            onChange={(e, newValue) => {
              field.onChange(newValue);
            }}
            // prettier-ignore
            getOptionLabel={(option) => `${option?.name} `
        }
            renderInput={(params) => <TextField label="หน่วย" {...params} />}
          />
        )}
      />
    </div>
  </div>
);

export default OrganizationConstantForm;

OrganizationConstantForm.propTypes = {
  control: PropTypes.func,
  errors: PropTypes.func,
  units: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  organizationConstant: PropTypes.shape({
    type_code: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    unit: PropTypes.object,
  }),
};
