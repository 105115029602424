import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import * as actions from '@/redux/actions';

/**
 * @function DetailMOTemplate
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function DetailMOTemplate({ title, subtitle }) {
  const dispatch = useDispatch();
  const moTemplate = useSelector((state) => state.moTemplate);
  const params = useParams();

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.moTemplateGet(params.id));
    } catch (error) {
      dispatch(actions.moTemplateError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (moTemplate.isLoading) {
    return <Loading />;
  }

  if (!moTemplate.isLoading && moTemplate.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <Card>
            <CardContent></CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailMOTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailMOTemplate.defaultProps = {
  title: '',
  subtitle: '',
};
