import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import { gql } from 'graphql-request';
import PropTypes from 'prop-types';

import {
  BackButton,
  BaseProjectForm,
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { config } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';

/**
 * @function EditBaseProject
 * @description Editing Base Project
 */

export default function EditBaseProject({ title, subtitle }) {
  const dispatch = useDispatch();
  const baseProject = useSelector((state) => state.baseProject);
  const processTemplate = useSelector((state) => state.processTemplate);
  const bomTemplate = useSelector((state) => state.bomTemplate);
  const history = useHistory();
  const params = useParams();
  const {
    formState: { errors },
    control,
    handleSubmit,
    watch,
    setValue,
  } = useForm({ defaultValues: baseProject });

  const query = gql`
    query FindSettingEditProductBom(
      $baseProjectInput: BaseProjectInput
      $processTemplateInput: ProcessTemplateInput
      $bomTemplateInput: BomTemplateInput
    ) {
      findOneBaseProject(input: $baseProjectInput) {
        _id
        type_code
        name
        description
        bom_template {
          _id
          name
        }
        process_template {
          _id
          name
        }
        attach_process_template
        attach_bom_template
      }
      findProcessTemplates(input: $processTemplateInput) {
        rows {
          _id
          name
        }
      }
      findBomTemplates(input: $bomTemplateInput) {
        rows {
          _id
          name
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    const queryResult = await graphqlClient.request(query, {
      baseProjectInput: { id: params.id },
      processTemplateInput: { page: 1, size: config.maxFetchSize },
      bomTemplateInput: { page: 1, size: config.maxFetchSize },
    });
    const baseProjectData = queryResult?.findOneBaseProject;
    const processTemplateData = queryResult?.findProcessTemplates;
    const bomTemplateData = queryResult?.findBomTemplates;
    dispatch(actions.baseProjectStateOneSet(baseProjectData));
    dispatch(actions.processTemplateStateSet(processTemplateData));
    dispatch(actions.bomTemplateStateSet(bomTemplateData));
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  useEffect(() => {
    if (baseProject) {
      setValue('attach_process_template', baseProject?.attach_process_template);
      setValue('attach_bom_template', baseProject?.attach_bom_template);
    }

    return () => {};
  }, [baseProject]);

  const onSubmit = async (data) => {
    try {
      await dispatch(actions.baseProjectPut(params.id, data));
      history.goBack();
    } catch (error) {
      alert(`ไม่สามารถแก้ไขได้ ${error?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (
    baseProject.isLoading ||
    baseProject?.rows ||
    bomTemplate?.isLoading ||
    processTemplate?.isLoading
  ) {
    return <Loading />;
  }

  if (
    !baseProject.isLoading &&
    baseProject.isCompleted &&
    !baseProject?.rows &&
    processTemplate?.isCompleted &&
    bomTemplate?.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <Card>
            <CardContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <BaseProjectForm
                  control={control}
                  baseProject={baseProject}
                  errors={errors}
                  watch={watch}
                  processTemplate={processTemplate}
                  bomTemplate={bomTemplate}
                  setValue={setValue}
                />
                <div className="flex justify-end">
                  <Button variant="contained" type="submit">
                    บันทึก
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditBaseProject.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditBaseProject.defaultProps = {
  title: '',
  subtitle: '',
};
