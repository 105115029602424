/* eslint-disable import/no-unresolved */
/* eslint-disable import/prefer-default-export */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';

import { converseMaterialOrProductUnit } from '@/utils/functions';
import currencyFormatterTH from '@/utils/functions/currencyFormatterTH';

import 'addthaifont-pdfmake/build/vfs_fonts';

import { ReportHeader } from './common';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};

const genMaterialTransaction = (data, imsSetting) =>
  _.map(data, (_data, index) => [
    {
      text: index + 1,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${dayjs(_data?.createdAt)
        .locale('th')
        .format('DD MMM BBBB')}\n เวลา ${new Date(
        _data?.createdAt,
      ).toLocaleTimeString('th')} น.`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${_data?.material_transaction_type?.name || '-'}`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: imsSetting?.display?.lot_number ? 1 : 2,
    },
    imsSetting?.display?.lot_number
      ? {
          text: _.map(
            _data?.material_stock_lot,
            (lot) => `${lot?.lotId?.lot_number || '-'}\n`,
          ),
          border: [true, false, true, true],
          fontSize: 10,
          colSpan: 1,
        }
      : {},
    {
      text: _.map(
        _data?.material_stock_lot,
        (lot, indexLot) =>
          `${indexLot + 1}. ${lot?.lotId?.material?.type_code || ''} ${
            lot?.lotId?.material?.name || ''
          }\n`,
      ),
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: _.map(
        _data?.material_stock_lot,
        (lot) => `${lot?.lotId?.place?.name || ''}\n`,
      ),
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: _.map(
        _data?.material_stock_lot,
        (lot) =>
          `${currencyFormatterTH.format(lot?.lotId?.quantity) || '0'} ${
            lot?.lotId?.material?.use_unit_conversion
              ? `${
                  lot?.lotId?.material?.unit_input?.short_sign
                } ( ${converseMaterialOrProductUnit(
                  lot?.material,
                  lot?.quantity,
                )}  ${lot?.lotId?.material?.unit_output?.short_sign}) `
              : lot?.lotId?.material?.unit || ''
          }\n`,
      ),
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: _.map(
        _data?.material_stock_lot,
        (lot) =>
          `${currencyFormatterTH.format(lot?.lotId?.amount) || '0'} ${
            lot?.lotId?.material?.use_unit_conversion
              ? `${
                  lot?.lotId?.material?.unit_input?.short_sign
                } ( ${converseMaterialOrProductUnit(
                  lot?.material,
                  lot?.amount,
                )}  ${lot?.lotId?.material?.unit_output?.short_sign}) `
              : lot?.lotId?.material?.unit || ''
          }\n`,
      ),
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${
        _data?.request_name
          ? _data?.request_name || '-'
          : _data?.material_stock_lot[0]?.lotId?.recipient_name || '-'
      }`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${_data?.remark || ''}`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
  ]);

export const MaterialHReport = (
  materialTransaction,
  information,
  monthChang,
  isError,
) => {
  const {
    setting: { ims: imsSetting },
  } = information;
  const materialData = genMaterialTransaction(materialTransaction, imsSetting);
  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [20, 30, 40, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '16',
    },
    info: {
      title: 'ประวิติการจัดการวัตถุดิบ',
    },
    content: [
      isError ? ReportHeader(information, false) : ReportHeader(information),
      {
        margin: [0, 20, 30, 0],
        style: 'tableExample',
        layout: {
          fillColor(rowIndex) {
            return rowIndex === 0 ? '#c5cae9' : null;
          },
        },
        table: {
          widths: [
            '5%',
            '12%',
            '7%',
            imsSetting?.display?.lot_number ? '10%' : '0%',
            '20%',
            '10%',
            '7%',
            '7%',
            '20%',
            '10%',
          ],
          heights: [15],
          headerRows: 1,
          dontBreakRows: true,
          body: [
            [
              {
                text: 'ลำดับ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'วันที่ดำเนินการ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'ประเภท',
                colSpan: imsSetting?.display?.lot_number ? 1 : 2,
                fontSize: 10,
                bold: true,
              },
              imsSetting?.display?.lot_number
                ? {
                    text: 'หมายเลขล็อต',
                    colSpan: 1,
                    fontSize: 10,
                    bold: true,
                  }
                : {},
              {
                text: 'วัตถุดิบ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'ชื่อคลัง',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'จำนวน',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'คงเหลือ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'ผู้ดำเนินการ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'หมายเหตุ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
            ],
            ...materialData,
          ],
        },
      },
    ],
    images: {
      logo: information?.logo?.url,
    },
  };
  pdfMake.createPdf(docDefinition).open();
};
