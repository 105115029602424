import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  BackButton,
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Card } from '@mui/material';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';

export default function DetailMaterialType({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const materialType = useSelector((state) => state.materialType);

  useEffect(() => {
    dispatch(actions.materialTypeGet(id));
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (materialType.isLoading || materialType.rows) {
    return <Loading />;
  }
  if (!materialType.isLoading && materialType.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <div>
          <Card className="p-4 ">
            <div className="flex flex-wrap divide-y">
              <div className="w-full py-4 text-lg font-semibold font-display ">
                {'รายละเอียด'}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold font-display ">
                {'ชื่อ'}
              </div>
              <div className="w-1/2 py-4 ">{`${materialType.name}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold font-display ">
                {'รายละเอียด'}
              </div>
              <div className="w-1/2 py-4 ">{`${
                materialType.description || '-'
              }`}</div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailMaterialType.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailMaterialType.defaultProps = {
  title: '',
  subtitle: '',
};
