import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardContent, List, ListItemButton } from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';

/**
 * @function FindMeasurementPreEdit
 * @description Display a collections or a list of FindMeasurementPreEdit from database
 */

export default function FindMeasurementPreEdit({ title, subtitle }) {
  const dispatch = useDispatch();
  const currentProcess = useSelector((state) => state.process);
  const params = useParams();

  const query = gql`
    query FindFindMeasurementPreEdit($processInput: ProcessInput) {
      findOneProcess(input: $processInput) {
        _id
        steps {
          _id
          name
          index
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        processInput: { id: params.id, fetchInsideProcess: true },
      });
      const processData = queryResult?.findOneProcess;
      dispatch(actions.processStateOneSet(processData));
    } catch (error) {
      dispatch(actions.processError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (currentProcess.isLoading) {
    return <Loading />;
  }

  if (!currentProcess.isLoading && currentProcess.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="font-semibold">เลือกขั้นตอนที่ต้องการแก้ไข</div>
              <List>
                {_.map(
                  _.orderBy(currentProcess?.steps, ['index'], ['asc']),
                  (eachStep, index) => (
                    <div key={index} className="flex justify-between my-2">
                      {eachStep?.name}
                      <div>
                        <Link
                          to={`/mms/process/measurement/edit/${params.id}?step=${eachStep?._id}`}
                        >
                          <Button variant="contained">เลือก</Button>
                        </Link>
                      </div>
                    </div>
                  ),
                )}
              </List>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

FindMeasurementPreEdit.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

FindMeasurementPreEdit.defaultProps = {
  title: '',
  subtitle: '',
};
