import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  Error,
  Loading,
  NameBox,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';

/**
 * @function DashboardEmployeeSelect
 * @description Display a collections or a list of DashboardEmployeeSelect from database
 */

export default function DashboardEmployeeSelect({ title, subtitle }) {
  const dispatch = useDispatch();
  const employee = useSelector((state) => state.employee);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [total, setTotal] = useState();

  const query = gql`
    query FindDashboardEmployeeSelect($employeeinput: EmployeeInput) {
      findEmployees(input: $employeeinput) {
        currPage
        lastPage
        total
        rows {
          _id
          firstname
          lastname
          image {
            url
          }
          department {
            _id
            name
          }
          other_departments {
            _id
            name
          }
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        employeeinput: { page, size, name },
      });
      const employeeData = queryResult?.findEmployees;
      dispatch(actions.employeeStateSet(employeeData));
    } catch (error) {
      dispatch(actions.employeeError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, name]);

  useEffect(() => {
    if (employee?.rows) {
      setTotal(employee?.total);
    }

    return () => {};
  }, [employee]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (employee.isLoading) {
    return <Loading />;
  }
  if (!employee.isLoading && employee.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <Card>
          <div className="p-4 flex flex-row">
            <div className="w-full md:w-1/2">
              <TextField
                label="ค้นหา"
                fullWidth
                size="small"
                id="outlined-start-adornment"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <i className="fas fa-search"></i>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        </Card>
        <div className="my-2">
          <Paper>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="font-bold">ลำดับที่</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold"> พนักงาน</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">แผนก</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">ดำเนินการ</div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!_.isEmpty(employee?.rows) ? (
                    _.map(employee?.rows, (row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <div className="text-xs">
                            {(page - 1) * size + index + 1}
                          </div>
                        </TableCell>
                        <TableCell>
                          <NameBox user={row} showDepartment={false} />
                        </TableCell>
                        <TableCell>
                          {row?.department?.name || 'ไม่มีข้อมูล'}
                          {_.map(
                            row?.other_departments,
                            (department, deptIndex) => (
                              <div key={deptIndex}>{department?.name}</div>
                            ),
                          )}
                        </TableCell>
                        <TableCell>
                          <Link to={`/dashboard/employee/detail/${row?._id}`}>
                            <Button variant="contained">รายละเอียด</Button>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <div className="text-center">ไม่มีข้อมูล</div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              page={page - 1}
              count={total || 1}
              rowsPerPage={size}
              onPageChange={(event, newPage) => {
                console.log('New Page', page);
                setPage(newPage + 1);
              }}
              onRowsPerPageChange={(event) => {
                setSize(parseInt(event.target.value, 10));
                setPage(1);
              }}
            />
          </Paper>
        </div>
      </div>
    );
  }
  return <Error />;
}

DashboardEmployeeSelect.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DashboardEmployeeSelect.defaultProps = {
  title: '',
  subtitle: '',
};
