import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Error,
  ExportExcelContainer,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { ApprovalForm } from '@iarcpsu/emanufac-components/src/components/Forms';
import {
  Backdrop,
  Button,
  Card,
  Fade,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as actions from '../../redux/actions';
import { MATERIAL_REQUEST_STATUS } from '../../utils/constants';
import { MaterialRequestUtil } from '../../utils/excelColumn';
import currencyFormatterTH from '../../utils/functions/currencyFormatterTH';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

const defaultValues = {
  message_memo: '',
};

export default function MaterialRequestManagement({ title, subtitle }) {
  const dispatch = useDispatch();
  const materialRequest = useSelector((state) => state.materialRequest);
  const me = useSelector((state) => state.me);
  const { control, reset, getValues } = useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [statusCode, setStatusCode] = useState('');
  const [total, setTotal] = useState(undefined);
  const history = useHistory();
  const [Id, setId] = useState();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    reset(defaultValues);
  };
  useEffect(() => {
    const fetchMaterterialRequest = async () => {
      try {
        await dispatch(
          actions.materialRequestAll({
            name,
            page,
            size,
            statusCode,
          }),
        );
      } catch (error) {
        console.error(error);
      }
    };

    const fetchMe = async () => {
      try {
        await dispatch(actions.meGet(''));
      } catch (error) {
        console.error(error);
      }
    };

    fetchMaterterialRequest();
    fetchMe();

    return () => {};
  }, [name, page, size, statusCode]);

  useEffect(() => {
    setTotal(materialRequest?.total);
    return () => {};
  }, [materialRequest]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      const confirmApproval = window.confirm('ยืนยันการอนุมัติคำสั่งซื้อ');
      if (confirmApproval) {
        handleClose();
        const changeData = {
          message_memo: data,
          approver: me.userData._id,
          status: MATERIAL_REQUEST_STATUS.APPROVAL.status_code,
        };
        await dispatch(actions.materialRequestPut(Id, changeData));
        alert('สำเร็จ');
        await dispatch(actions.materialRequestAll({ name, page, size }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePushToRejectMaterialRequest = async (id) => {
    const confirmApproval = window.confirm('ยืนยันการไม่อนุมัติคำสั่งซื้อ');
    if (confirmApproval) {
      const data = {
        status: MATERIAL_REQUEST_STATUS.REJECT.status_code,
      };
      await dispatch(actions.materialRequestPut(id, data));
      await dispatch(actions.materialRequestAll({ name, page, size }));
    }
  };

  const handlePushToCancleMaterialRequest = async (id) => {
    const confirmApproval = window.confirm('ยืนยันการยกเลิกคำสั่งซื้อ');
    if (confirmApproval) {
      const data = {
        status: MATERIAL_REQUEST_STATUS.CANCEL.status_code,
      };
      await dispatch(actions.materialRequestPut(id, data));
      await dispatch(actions.materialRequestAll({ name, page, size }));
    }
  };

  const handlePushToEditMaterialRequest = async (id) => {
    history.push(`material-order/edit/${id}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeStatus = (status) => {
    setStatusCode(status);
  };

  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4">
      <Link to="/pms/material-order/create">
        <Button variant="contained">เพิ่ม</Button>
      </Link>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row gap-2">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/4">
          <Controller
            name={'status_code'}
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                <InputLabel id="type" size={'small'}>
                  ค้นหาด้วยสถานะ
                </InputLabel>
                <Select
                  {...field}
                  label="ค้นหาด้วยสถานะ"
                  size={'small'}
                  fullWidth
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    handleChangeStatus(e.target.value);
                  }}
                >
                  <MenuItem value="">ทั้งหมด</MenuItem>
                  {_.map(
                    MATERIAL_REQUEST_STATUS,
                    (_MATERIAL_REQUEST_STATUS) => (
                      <MenuItem
                        value={_MATERIAL_REQUEST_STATUS?.status_code}
                        key={_MATERIAL_REQUEST_STATUS?.status_code}
                      >
                        {_MATERIAL_REQUEST_STATUS?.description}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            )}
          />
        </div>
        <div className="w-full md:w-1/4 flex justify-end">
          <ExportExcelContainer
            columnList={MaterialRequestUtil.columns}
            currentData={materialRequest?.rows}
            dataAPIEndPoint="material-request"
            dataQuery={{ statusCode }}
            sheetName="MaterialRequest"
            spreadedColumn="material"
          />
        </div>
      </div>
    </Card>
  );

  const showStatus = (status) => {
    if (status === MATERIAL_REQUEST_STATUS.PENDING_APPROVAL.status_code) {
      return (
        <b className="text-yellow-500">
          {MATERIAL_REQUEST_STATUS[status].description}
        </b>
      );
    }
    if (status === MATERIAL_REQUEST_STATUS.APPROVAL.status_code) {
      return (
        <b className="text-indigo-700">
          {MATERIAL_REQUEST_STATUS[status].description}
        </b>
      );
    }
    if (status === MATERIAL_REQUEST_STATUS.SUCCESSFUL_PURCHASE.status_code) {
      return (
        <b className="text-green-700">
          {MATERIAL_REQUEST_STATUS[status].description}
        </b>
      );
    }
    if (status === MATERIAL_REQUEST_STATUS.REJECT.status_code) {
      return (
        <b className="text-red-500">
          {MATERIAL_REQUEST_STATUS[status].description}
        </b>
      );
    }
    if (status === MATERIAL_REQUEST_STATUS.CANCEL.status_code) {
      return (
        <b className="text-blue-500">
          {MATERIAL_REQUEST_STATUS[status].description}
        </b>
      );
    }
  };

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style} className="max-w-4xl">
          <div className="py-2">บันทึกข้อความ</div>
          <div className="py-2">
            <ApprovalForm Controller={Controller} control={control} />
            <div className="flex flex-row justify-end gap-1 py-4">
              <Button
                variant="contained"
                type="submit"
                onClick={() => onSubmit(getValues('message_memo'))}
              >
                บันทึก
              </Button>
              <Button variant="outlined" onClick={handleClose}>
                ยกเลิก
              </Button>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <colgroup>
              <col width="5%" />
              <col width="10%" />
              <col width="15%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="15%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> วันที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> วัตถุดิบ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ผู้สั่งซื้อ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ผู้อนุมัติ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> สถานะ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> หมายเหตุ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(materialRequest?.rows) ? (
                materialRequest?.rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="text-xs">
                        {' '}
                        {(page - 1) * size + index + 1}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="text-xs">
                        {dayjs(row?.createdAt)
                          .locale('th')
                          .format('DD MMM BBBB')}
                        <p>
                          เวลา{' '}
                          {new Date(row?.createdAt).toLocaleTimeString('th')} น.
                        </p>
                      </div>
                    </TableCell>
                    <TableCell>
                      {_.size(row?.material) ? (
                        row?.material.map((_material, _index) => (
                          <div key={_index}>
                            <span>
                              {_index + 1}
                              {'. '}
                            </span>
                            <span className="font-semibold font-display">
                              {_material?.material?.type_code}
                            </span>{' '}
                            <span className=" ">
                              {_material?.material?.name}
                            </span>
                            <span>
                              {' '}
                              {currencyFormatterTH.format(
                                _material?.quantity,
                              ) || ' 0 '}{' '}
                              {_material?.material?.unit}
                            </span>
                          </div>
                        ))
                      ) : (
                        <div>{' - '}</div>
                      )}
                    </TableCell>
                    <TableCell>
                      {row?.request_name && row?.request_name ? (
                        <div>
                          <div>{row?.request_name}</div>
                          <div>
                            {row?.request_department?.name &&
                              `แผนก : ${row?.request_department?.name}`}
                          </div>
                        </div>
                      ) : (
                        <div>-</div>
                      )}
                    </TableCell>
                    <TableCell>
                      {' '}
                      {row?.approver ? (
                        <div>
                          {row?.approver?.firstname} {row?.approver?.lastname}
                        </div>
                      ) : (
                        <p>-</p>
                      )}
                    </TableCell>
                    <TableCell>
                      <div className="py-1 bg-gray-100 rounded-md  text-center">
                        {row?.status ? showStatus(row?.status) : ' - '}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="text-xs">
                        <p>{row?.remark ? row?.remark : ' '}</p>
                        {row?.message_memo ? <p>{row?.message_memo}</p> : ''}
                      </div>
                    </TableCell>
                    <TableCell>
                      {' '}
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          color={'success'}
                          size={'small'}
                          disabled={
                            row?.status !==
                            MATERIAL_REQUEST_STATUS.PENDING_APPROVAL.status_code
                          }
                          onClick={() => {
                            setId(row.id);
                            handleOpen();
                          }}
                        >
                          อนุมัติ
                        </Button>
                        <Button
                          variant="contained"
                          color={'warning'}
                          size={'small'}
                          disabled={
                            row?.status !==
                            MATERIAL_REQUEST_STATUS.PENDING_APPROVAL.status_code
                          }
                          onClick={() =>
                            handlePushToEditMaterialRequest(row.id)
                          }
                        >
                          แก้ไข
                        </Button>
                        <Button
                          variant="contained"
                          color={'error'}
                          size={'small'}
                          disabled={
                            row?.status !==
                            MATERIAL_REQUEST_STATUS.PENDING_APPROVAL.status_code
                          }
                          onClick={() =>
                            handlePushToRejectMaterialRequest(row.id)
                          }
                        >
                          ไม่อนุมัติ
                        </Button>
                        {row?.status ===
                          MATERIAL_REQUEST_STATUS.PENDING_APPROVAL
                            .status_code &&
                        row?.request_name ===
                          `${me?.userData?.firstname} ${me?.userData?.lastname}` ? (
                          <Button
                            variant="contained"
                            color={'secondary'}
                            size={'small'}
                            hidden
                            onClick={() =>
                              handlePushToCancleMaterialRequest(row.id)
                            }
                          >
                            ยกเลิกคำสั่งซื้อ
                          </Button>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (materialRequest?.isLoading) {
    return <Loading />;
  }
  if (!materialRequest?.isLoading && materialRequest?.isCompleted) {
    return (
      <div>
        {renderModal()}
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButtom()}</div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error message={materialRequest?.message} />;
}

MaterialRequestManagement.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MaterialRequestManagement.defaultProps = {
  title: '',
  subtitle: '',
};
