const addressToString = (address) => {
  const {
    country = '',
    district = '',
    house_number = ' ',
    province = '',
    postcode = '',
    road = '',
    subdistrict = '',
    village_number = '',
  } = address;
  return `${house_number} ${village_number ? `ม.${village_number}` : ''} ${
    road ? `ถ.${road}` : ''
  } ${subdistrict ? `ต.${subdistrict}` : ''} ${
    district ? `ม.${district}` : ''
  } ${province ? `ม.${province}` : ''} ${postcode} ${country}`;
};

export default addressToString;
