import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel } from '@mui/material';

const BooleanInput = ({
  control,
  measurementType,
  measurementControllerName = '',
}) => (
  <div>
    <div>
      <Controller
        control={control}
        name={`${measurementControllerName}.boolean_value`}
        render={({ field }) => (
          <FormControlLabel
            control={<Checkbox {...field} />}
            label={measurementType?.label}
          />
        )}
      />
    </div>
  </div>
);

BooleanInput.propTypes = {
  control: PropTypes.object,
  measurementType: PropTypes.object,
  measurementControllerName: PropTypes.string,
};

export default BooleanInput;
