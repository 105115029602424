/**
 * Find that selected Material is restrict in this place or not
 * หาว่าวัตถุดิบตัวที่ส่งมาอยู่ในคลังที่ไม่ควรจะอยู่หรือไม่
 */

import _ from 'lodash';

export const findIsMaterialRestrict = (material, place) => {
  // ถ้าคลังนั้นไม่ได้ตั้งค่าให้ใช้ได้เฉพาะหมวดหมู่มดหมวดหมู่หนึ่ง ก็ให้เป็น false ไปเลย
  if (!place?.inventory_restriction) {
    return false;
  }

  const allowCategoriesList = _.map(
    place?.allow_categories,
    (each) => each?._id,
  );

  // ID อยู่ในคลังที่กำหนด
  if (
    _.includes(
      allowCategoriesList,
      material?.material_type?._id || material?.material_type,
    )
  ) {
    return false;
  }

  return true;
};

export default findIsMaterialRestrict;
