import React from 'react';
import {
  Backdrop,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import DonutLove from '../../assets/images/donutLove.png';

import LoadingLinear from './LoadingLinear';

export default function LoadingDialog({ isLoading, label }) {
  const useStyles = makeStyles({
    backdrop: {
      zIndex: 100,
      color: '#fff',
    },
  });

  const classes = useStyles();

  return (
    <div>
      <Backdrop open={isLoading} className={classes.backdrop}>
        <Card>
          <CardContent>
            <div className="flex w-full justify-center my-2">
              <img src={DonutLove} className="w-48 lg:w-80" />
            </div>

            <div className="text-center">{label}</div>
            <div className="px-4 p-2">
              <LoadingLinear isLoading={isLoading} />
            </div>
          </CardContent>
        </Card>
      </Backdrop>
    </div>
  );
}

LoadingDialog.propTypes = {
  isLoading: PropTypes.bool,
  label: PropTypes.string,
};

LoadingDialog.defaultProps = {
  isLoading: true,
};
