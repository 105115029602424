import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { gql } from 'graphql-request';
import PropTypes from 'prop-types';

import {
  BackButton,
  DashboardElementForm,
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import graphqlClient from '../../../configs/graphqlClient';
import * as actions from '../../../redux/actions';

/**
 * @function EditDashboardElement
 * @description Display a collections or a list of EditDashboardElement from database
 */

export default function EditDashboardElement({ title, subtitle }) {
  const dispatch = useDispatch();
  const dashboardElement = useSelector((state) => state.dashboardElement);
  const measurementType = useSelector((state) => state.measurementType);
  const resultingFunctionType = useSelector(
    (state) => state.resultingFunctionType,
  );
  const history = useHistory();
  const params = useParams();
  const [page] = useState(1);
  const [size, setSize] = useState(10);
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...dashboardElement,
      elements: dashboardElement?.elements || [],
    },
  });

  const query = gql`
    query FindSettingEditProductBom(
      $dashboardElementInput: DashboardElementInput
      $measurementTypeInput: MeasurementTypeInput
      $resultingFunctionTypeInput: ResultingFunctionTypeInput
    ) {
      findOneDashboardElement(input: $dashboardElementInput) {
        _id
        name
        dashboard_type
        is_mo_level
        elements {
          index
          name
          source_type
          resulting_function_type_source {
            _id
            name
          }
          measurement_type_source {
            _id
            name
            type_code
          }
        }
      }
      findMeasurementTypes(input: $measurementTypeInput) {
        total
        rows {
          _id
          name
          type_code
        }
      }
      findResultingFunctionTypes(input: $resultingFunctionTypeInput) {
        total
        rows {
          _id
          name
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        dashboardElementInput: { id: params.id },
        measurementTypeInput: { page, size },
        resultingFunctionTypeInput: { page, size },
      });
      const dashboardElementData = queryResult?.findOneDashboardElement;
      const measurementTypeData = queryResult?.findMeasurementTypes;
      const resultingFunctionTypeData = queryResult?.findResultingFunctionTypes;
      dispatch(actions.dashboardElementStateOneSet(dashboardElementData));
      dispatch(
        actions.resultingFunctionTypeStateSet(resultingFunctionTypeData),
      );
      dispatch(actions.measurementTypeStateSet(measurementTypeData));
    } catch (error) {
      dispatch(actions.dashboardElementError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params, page, size]);

  useEffect(() => {
    let timeout;
    if (dashboardElement?.elements) {
      if (dashboardElement?.elements) {
        timeout = setTimeout(() => {
          setValue('elements', dashboardElement?.elements || []);
        }, 200);
      }
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [dashboardElement]);

  const handleEditDashboardElement = async (data) => {
    try {
      await dispatch(actions.dashboardElementPut(params.id, data));
      history.goBack();
    } catch (error) {
      alert(`การสร้างส่วนประกอบของแดชบอร์ดผิดพลาด ${error?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (dashboardElement.isLoading || dashboardElement?.rows) {
    return <Loading />;
  }

  if (!dashboardElement.isLoading && dashboardElement.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <form onSubmit={handleSubmit(handleEditDashboardElement)}>
          <div className="my-2">
            <DashboardElementForm
              control={control}
              errors={errors}
              setValue={setValue}
              watch={watch}
              resultingFuctionType={resultingFunctionType}
              measurementType={measurementType}
              size={size}
              setSize={setSize}
              defaultValue={dashboardElement}
            />
          </div>
          <div className="flex justify-end">
            <Button color="success" variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    );
  }
  return <Error />;
}

EditDashboardElement.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditDashboardElement.defaultProps = {
  title: '',
  subtitle: '',
};
