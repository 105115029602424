import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  BackButton,
  CostEstimationProfileForm,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Button } from '@mui/material';
import { gql } from 'graphql-request';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import { config } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';

/**
 * @function CreateCostEstimationProfile
 * @description Display a collections or a list of CreateCostEstimationProfile from database
 */

export default function CreateCostEstimationProfile({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const place = useSelector((state) => state.place);
  const warehouse = useSelector((state) => state.warehouse);
  const { handleSubmit, watch, control } = useForm();

  const query = gql`
    query FindCreateCostEstimationProfile(
      $placeInput: PlaceInput
      $warehouseInput: WarehouseInput
    ) {
      findPlaces(input: $placeInput) {
        rows {
          _id
          name
        }
      }
      findWarehouses(input: $warehouseInput) {
        rows {
          _id
          name
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        placeInput: { page: 1, size: config.maxFetchSize },
        warehouseInput: { page: 1, size: config.maxFetchSize },
      });
      const placeData = queryResult?.findPlaces;
      const warehouseData = queryResult?.findWarehouses;
      dispatch(actions.placeStateSet(placeData));
      dispatch(actions.warehouseStateSet(warehouseData));
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const handleCreateProfile = async (data) => {
    try {
      dispatch(actions.costEstimationProfileCreate(data));
      Swal.fire({
        icon: 'success',
        title: 'สร้างโปรไฟล์เสร็จสิ้น',
      }).then(() => {
        history.goBack();
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: error?.message,
      });
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  return (
    <div>
      {renderTitle()}
      <BackButton />
      <div className="my-2">
        <form onSubmit={handleSubmit(handleCreateProfile)}>
          <CostEstimationProfileForm
            control={control}
            place={place}
            warehouse={warehouse}
            watch={watch}
          />
          <div className="flex justify-end my-1">
            <Button variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

CreateCostEstimationProfile.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateCostEstimationProfile.defaultProps = {
  title: '',
  subtitle: '',
};
