import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  Autocomplete,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { DISPLAY_TYPE } from '@/utils/constants';
import { MATERIAL_STOCK_STATUS } from '@iarcpsu/emanufac-constant';
import { currencyFormatter } from '@/utils/functions';
import MultiUnitProdOrMatBox from '../../Box/Rendering/MultiUnitProdOrMatBox';
import { ProdOrMatQuantityInput } from '../../Inputs';

const resetValues = {
  material: '',
  addMaterialLots: [],
};

/**
 * ฟอร์มในการเอาวัตถุดิบออกจากคลังวัตถุดิบ
 * โดยในฟอร์มจะมีการอ่าน `params.id` จากด้านบนโดยเป็นค่าคลังที่ต้องการ Filter
 */
export function DecreaseMaterialForm({
  material,
  place,
  type,
  selectMaterialLot,
  setSelectMaterialLot,
  filterMaterial,
  setFilterMaterial,
  filterPlace,
  setFilterPlace,
}) {
  const id = useParams()?.id;
  const [reload, setReload] = useState(false);
  const [displayType, setDisplayType] = useState(
    window.screen.width >= 768
      ? DISPLAY_TYPE.TABLE.status_code
      : DISPLAY_TYPE.CARD.status_code,
  );
  const addMaterialLots = useState([]);
  const {
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
    getValues,
  } = useForm();
  const information = useSelector((state) => state.information);
  const imsSetting = information?.setting?.ims;

  const checkMaterialLot = () => {
    console.log('Generate New CheckMaterial Lot');
    console.log('[BB] Overall Filter Place ', filterPlace);
    if (_.isEmpty(filterPlace)) {
      console.log('[BB] Filter Place in Check Matreial lot', filterPlace);
      console.log('[BB| Place ', place);
      const findPlace = _.find(place, (each) => each?._id === id);
      console.log('[BB] Set Filter Place');
      setFilterPlace(findPlace);
      _.map(material, (_material, index) => {
        const filterLot = _.filter(
          _material?.stocklots,
          function filterLot(lot) {
            return (
              lot.place?._id === filterPlace?._id &&
              lot.status === MATERIAL_STOCK_STATUS.IN_STOCK.status_code
            );
          },
        );

        //   console.log('Filter Lot', filterLot);
        if (!_.isEmpty(filterLot)) {
          const data = {
            ..._material,
            stocklots: filterLot,
          };
          filterMaterial[index] = data;
        } else {
          filterMaterial[index] = null;
        }
      });
      console.log('[AA] Filter Material', filterMaterial);
      const filterNoLot = _.filter(filterMaterial, function filterLot(lot) {
        return lot != null;
      });
      console.log('[AA] Filter No Lot', filterNoLot);

      setFilterMaterial(filterNoLot);
    } else if (filterPlace) {
      _.map(material, (_material, index) => {
        console.log('[BB] Material Stocklot', _material?.stocklots);
        console.log('[BB] Filter Place', filterPlace);
        const filterLot = _.filter(
          _material?.stocklots,
          (lot) =>
            (lot.place?._id === filterPlace?._id ||
              lot.place === filterPlace?._id) &&
            lot.status === MATERIAL_STOCK_STATUS.IN_STOCK.status_code,
        );
        console.log('[BB] Filter Lot', filterLot);
        if (!_.isEmpty(filterLot)) {
          const data = {
            ..._material,
            stocklots: filterLot,
          };
          filterMaterial[index] = data;
        } else {
          filterMaterial[index] = null;
        }
      });
      const filterNoLot = _.filter(filterMaterial, function filterLot(lot) {
        return lot != null;
      });
      setFilterMaterial(filterNoLot);

      console.log('Filter No Lot', filterNoLot);
    }
  };

  useEffect(() => {
    checkMaterialLot();
    return () => {};
  }, [reload, filterPlace, type]);

  const handleAddMaterialLots = () => {
    const getQuantity = [];
    _.map(addMaterialLots[0]?.stocklots, (lot, index) => {
      if (
        getValues(`quantity${index}`) &&
        getValues(`quantity${index}`) !== '0'
      ) {
        const lotData = {
          quantity: getValues(`quantity${index}`),
          amount:
            parseInt(lot?.amount, 10) -
            parseInt(getValues(`quantity${index}`), 10),
          lotId: lot?._id,
          materialId: getValues('material'),
          place: filterPlace?.id,
          stocklot: lot,
          material: addMaterialLots[0],
        };
        getQuantity.push(lotData);
      }
    });
    if (getValues('material') === '') {
      alert('กรุณาใส่ข้อมูลให้ครบถ้วน');
    } else {
      _.map(getQuantity, (_getQuantity) => {
        selectMaterialLot.push(_getQuantity);
        setSelectMaterialLot(selectMaterialLot);
      });
      reset(resetValues);
      setReload(!reload);
    }
  };

  const handleRemoveMaterialLot = (index) => {
    selectMaterialLot.splice(index, 1);
    setReload(!reload);
  };

  const materialLotSelectorTableMode = (findStockLots) => (
    <Card>
      <TableContainer>
        <Table size="small" className="border-lg">
          <colgroup>
            <col width="10%" />
            {imsSetting?.display?.lot_number && <col width="15%" />}{' '}
            {imsSetting?.display?.production_date && <col width="15%" />}
            {imsSetting?.display?.expiration_date && <col width="10%" />}
            <col width="15%" />
            <col width="15%" />
          </colgroup>
          <TableHead className="bg-blue-100">
            <TableRow>
              <TableCell>
                <div className="font-bold">ล็อตที่</div>
              </TableCell>
              {imsSetting?.display?.lot_number && (
                <TableCell>
                  <div className="font-bold"> หมายเลขล็อต</div>
                </TableCell>
              )}
              {imsSetting?.display?.production_date && (
                <TableCell>
                  <div className="font-bold"> วันที่ผลิต</div>
                </TableCell>
              )}
              {imsSetting?.display?.expiration_date && (
                <TableCell>
                  <div className="font-bold"> วันที่หมดอายุ</div>
                </TableCell>
              )}
              <TableCell>
                <div className="font-bold"> คงเหลือ</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> จำนวนที่เบิก</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(findStockLots?.stocklots, (lot, index) => (
              <TableRow key={lot.id}>
                <TableCell>{index + 1}</TableCell>
                {imsSetting?.display?.lot_number && (
                  <TableCell>
                    <div className="w-24">
                      {lot?.lot_number || '-'}
                      <div className="text-xs">{lot?.supplier?.name}</div>
                      <div className="text-xs my-1">
                        รับเข้า {dayjs(lot?.receipt_date).format('D MMM ')}
                      </div>
                    </div>
                  </TableCell>
                )}

                {imsSetting?.display?.production_date && (
                  <TableCell>
                    <div className="w-24">
                      {dayjs(lot?.production_date)
                        .locale('th')
                        .format('DD MMM ')}
                    </div>
                  </TableCell>
                )}
                {imsSetting?.display?.expiration_date && (
                  <TableCell>
                    <div className="w-24">
                      {dayjs(lot?.expiration_date)
                        .locale('th')
                        .format('DD MMM ')}
                    </div>
                  </TableCell>
                )}
                <TableCell>
                  <div className="w-24">
                    {' '}
                    <MultiUnitProdOrMatBox
                      foundMaterial={findStockLots}
                      quantity={lot?.amount}
                    />
                    <div>
                      ต้นทุน{' '}
                      {currencyFormatter.format(
                        parseFloat(lot?.price) / parseFloat(lot?.quantity),
                      )}
                      บาท
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="w-48">
                    <ProdOrMatQuantityInput
                      controllerName={`quantity${index}`}
                      artificialControllerName={`artifac[${index}]`}
                      selectedUnitControllerName={`unit[${index}]`}
                      watch={watch}
                      setValue={setValue}
                      index={index}
                      control={control}
                      selectedProduct={findStockLots}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );

  const materialLotSelectorCardMode = (findStockLots) => (
    <div>
      {console.log('findStockLots?.stocklots', findStockLots?.stocklots)}
      {_.map(findStockLots?.stocklots, (lot, index) => (
        <div key={lot?._id} className="my-1 p-4 rounded-md shadow-md">
          <div className="flex">
            <div className="w-1/2">ล็อตที่ {index + 1}</div>
            <div className="w-1/2">
              <div className="text-xs">รับเข้า</div>
              <div className="text-sm">
                {dayjs(lot?.receipt_date).format('D MMM BBBB')}
              </div>
            </div>
          </div>
          <div className="flex my-1 flex-wrap">
            <div className="w-1/2">
              <div className="text-xs">หมายเลขล็อต</div>
              <div className="text-sm">{lot?.lot_number || '-'}</div>
            </div>
            <div className="w-1/2">
              <div className="text-xs">คู่ค้า</div>
              <div className="text-sm">{lot?.supplier?.name || '-'}</div>
            </div>
            {imsSetting?.display?.production_date && (
              <div className="w-1/2">
                <div className="text-xs">วันผลิต</div>
                <div className="text-sm">
                  {dayjs(lot?.production_date)
                    .locale('th')
                    .format('DD MMM BBBB')}
                </div>
              </div>
            )}
            {imsSetting?.display?.expiration_date && (
              <div className="w-1/2">
                <div className="text-xs">วันหมดอายุ</div>
                <div className="text-sm">
                  {dayjs(lot?.expiration_date)
                    .locale('th')
                    .format('DD MMM BBBB')}
                </div>
              </div>
            )}
          </div>
          <div>
            <div className="text-xs">คงเหลือ</div>
            <div className="text-sm font-semibold">
              <MultiUnitProdOrMatBox
                foundMaterial={findStockLots}
                quantity={lot?.amount}
              />
            </div>
          </div>
          <div>
            <div className="text-xs">ราคาทุน</div>
            <div className="text-sm font-semibold">
              {currencyFormatter.format(
                parseFloat(lot?.price) / parseFloat(lot?.quantity),
              )}
            </div>
          </div>
          <div className="my-1">
            <div className="text-xs">ต้องการใช้</div>
            <div className="my-2">
              <ProdOrMatQuantityInput
                controllerName={`quantity${index}`}
                artificialControllerName={`artifac[${index}]`}
                selectedUnitControllerName={`unit[${index}]`}
                watch={watch}
                setValue={setValue}
                index={index}
                control={control}
                selectedProduct={findStockLots}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const showMaterialLots = () => {
    console.log('Watch Material', watch('material'));
    if (watch('material')) {
      console.log('Filter Material', filterMaterial);
      const findStockLots = _.find(filterMaterial, {
        _id: watch('material')._id,
      });
      addMaterialLots[0] = findStockLots;
      return (
        <div className="px-1 ">
          {displayType === DISPLAY_TYPE.TABLE.status_code
            ? materialLotSelectorTableMode(findStockLots)
            : materialLotSelectorCardMode(findStockLots)}
        </div>
      );
    }

    return <div></div>;
  };

  const materialOptions = () => (
    <Card>
      <div className="w-full flex flex-row flex-wrap p-4">
        <div className="w-full px-1 py-2">
          <Controller
            name={'material'}
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <Autocomplete
                size={'small'}
                disableClearable
                {...field}
                onChange={(e, newValue) => {
                  field.onChange(newValue);
                  setReload(!reload);
                }}
                options={material}
                // prettier-ignore
                getOptionLabel={(option) =>
                  (option ? `${option?.type_code ? `${option?.type_code} - ` : ''}  ${option?.name || ''} (${option?.material_type?.name})` : '')
                }
                renderInput={(params) => (
                  <TextField {...params} label="วัตถุดิบ" />
                )}
              />
            )}
          />
        </div>
        <div className="w-full ">{showMaterialLots()}</div>
        <div className="px-1 py-2">
          <Button
            variant="outlined"
            size={'small'}
            color={'success'}
            onClick={() => handleAddMaterialLots()}
          >
            เพิ่ม
          </Button>
        </div>
      </div>
    </Card>
  );

  const displayMaterial = () => (
    <Card className="my-4 lg:my-0">
      <TableContainer>
        <Table size="small" className="border-lg">
          <TableHead className="bg-blue-100">
            <TableRow>
              <TableCell>
                <div className="font-bold">ลำดับที่</div>
              </TableCell>
              {imsSetting?.display?.lot_number && (
                <TableCell>
                  <div className="font-bold"> หมายเลขล็อต</div>
                </TableCell>
              )}
              <TableCell>
                <div className="font-bold"> ชื่อวัตถุดิบ</div>
              </TableCell>
              {imsSetting?.display?.production_date && (
                <TableCell>
                  <div className="font-bold"> วันที่ผลิต</div>
                </TableCell>
              )}{' '}
              {imsSetting?.display?.expiration_date && (
                <TableCell>
                  <div className="font-bold"> วันที่หมดอายุ</div>
                </TableCell>
              )}
              <TableCell>
                <div className="font-bold"> จำนวน</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> ดำเนินการ</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!_.isEmpty(selectMaterialLot) ? (
              selectMaterialLot.map((_material, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {imsSetting?.display?.lot_number && (
                    <TableCell>
                      <div className="w-24">
                        {' '}
                        {_material?.stocklot?.lot_number || '-'}
                      </div>
                    </TableCell>
                  )}
                  <TableCell>
                    <div className="w-24">
                      <span className="font-bold mr-2">
                        {_material?.material?.type_code}
                      </span>
                      <span>{_material?.material?.name}</span>
                      <span className="italic">
                        หมวดหมู่ {_material?.material?.material_type?.name}
                      </span>
                    </div>
                  </TableCell>
                  {imsSetting?.display?.production_date && (
                    <TableCell>
                      <div className="w-24">
                        {' '}
                        {dayjs(_material?.stocklot?.production_date)
                          .locale('th')
                          .format('DD MMM BBBB')}
                      </div>
                    </TableCell>
                  )}
                  {imsSetting?.display?.expiration_date && (
                    <TableCell>
                      <div className="w-24">
                        {' '}
                        {dayjs(_material?.stocklot?.expiration_date)
                          .locale('th')
                          .format('DD MMM BBBB')}
                      </div>
                    </TableCell>
                  )}
                  <TableCell>
                    <div className="w-24">
                      {' '}
                      <MultiUnitProdOrMatBox
                        foundMaterial={_material?.material}
                        quantity={_material?.quantity}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <Button
                      color={'error'}
                      variant="contained"
                      size={'small'}
                      onClick={() => handleRemoveMaterialLot(index)}
                    >
                      ลบ
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow key={''}>
                <TableCell colSpan={6}>
                  <div className="text-center">ไม่มีข้อมูล</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );

  const showSelectMaterialLot = () => displayMaterial();

  return (
    <div>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-1/2">{materialOptions()}</div>
        <div className="w-full lg:w-1/2 lg:pl-2">{showSelectMaterialLot()}</div>
      </div>
    </div>
  );
}

DecreaseMaterialForm.propTypes = {
  material: PropTypes.arrayOf(PropTypes.object),
  place: PropTypes.object,
  type: PropTypes.object,
  selectMaterialLot: PropTypes.object,
  setSelectMaterialLot: PropTypes.func,
  filterMaterial: PropTypes.object,
  setFilterMaterial: PropTypes.func,
  filterPlace: PropTypes.object,
  setFilterPlace: PropTypes.func,
};

DecreaseMaterialForm.defaultProps = {
  material: null,
  place: null,
  type: null,
  selectMaterialLot: null,
  filterMaterial: null,
  filterPlace: null,
};

export default DecreaseMaterialForm;
