import {
  WAREHOUSE_ALL,
  WAREHOUSE_GET,
  WAREHOUSE_DEL,
  WAREHOUSE_PUT,
  WAREHOUSE_POST,
  WAREHOUSE_LOADING,
  WAREHOUSE_ERROR,
} from '../types';

import api from '../../../configs/api';

export const warehouseCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: WAREHOUSE_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/warehouse`,
      {
        ...payload,
      },
    );
    dispatch({ type: WAREHOUSE_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: WAREHOUSE_ERROR });
    throw new Error(error);
  }
};

export const warehouseAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/warehouse?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: WAREHOUSE_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: WAREHOUSE_ERROR });
    throw new Error(error);
  }
};

export const warehouseGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/warehouse/${id}`,
    );
    if (status === 200) {
      dispatch({ type: WAREHOUSE_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: WAREHOUSE_ERROR });
    throw new Error(error);
  }
};

export const warehouseStateSet = (data) => async (dispatch) => {
  dispatch({ type: WAREHOUSE_ALL, payload: data });
};

export const warehousePut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: WAREHOUSE_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/warehouse/${id}`,
      payload,
    );
    dispatch({ type: WAREHOUSE_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: WAREHOUSE_ERROR });
    throw new Error(error);
  }
};
export const warehouseDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: WAREHOUSE_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/warehouse/${id}`,
    );
    dispatch({ type: WAREHOUSE_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: WAREHOUSE_ERROR });
    throw new Error(error);
  }
};
