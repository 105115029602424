import {
  PRODUCTTYPES_ALL,
  PRODUCTTYPES_GET,
  PRODUCTTYPES_DEL,
  PRODUCTTYPES_PUT,
  PRODUCTTYPES_POST,
  PRODUCTTYPES_LOADING,
  PRODUCTTYPES_ERROR,
} from '../types';

import api from '../../../configs/api';

export const productTypeCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCTTYPES_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/product-type`,
      {
        ...payload,
      },
    );
    dispatch({ type: PRODUCTTYPES_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCTTYPES_ERROR });
    throw new Error(error);
  }
};

export const productTypeAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/product-type?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: PRODUCTTYPES_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCTTYPES_ERROR });
    throw new Error(error);
  }
};

export const productTypeGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/product-type/${id}`,
    );
    if (status === 200) {
      dispatch({ type: PRODUCTTYPES_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCTTYPES_ERROR });
    throw new Error(error);
  }
};

export const productTypeStateSet = (data) => async (dispatch) => {
  dispatch({ type: PRODUCTTYPES_ALL, payload: data });
};

export const productTypePut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCTTYPES_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/product-type/${id}`,
      payload,
    );
    dispatch({ type: PRODUCTTYPES_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCTTYPES_ERROR });
    throw new Error(error);
  }
};
export const productTypeDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCTTYPES_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/product-type/${id}`,
    );
    dispatch({ type: PRODUCTTYPES_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCTTYPES_ERROR });
    throw new Error(error);
  }
};
