import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as CONFIG from '@/utils/constants';

import {
  BooleanAdditionalInput,
  CheckboxAddditionalInput,
  RadioAndDescribeAdditionalInput,
  SwitchAdditionalInput,
} from '../../Measurement/MeasurementType';

const MeasurementTypeForm = ({
  control,
  measurementType,
  units,
  watch = () => {},
  errors,
}) => (
  <div>
    <div className="flex flex-wrap">
      <div className="w-full py-2 ">
        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          // defaultValue={measurementType?.name}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              fullWidth
              label="ชื่อตัวแปร/ชื่อที่จะให้ใช้แสดงในการรับค่า"
              helperText={errors?.name && 'ชื่อตัวแปรไม่สามารถเว้นว่างได้'}
            />
          )}
        />
      </div>
      <div className="w-full py-2 ">
        <Controller
          name="type_code"
          control={control}
          rules={{ required: false, pattern: /^[A-Za-z0-9_.]+$/i }}
          // defaultValue={measurementType?.name}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              fullWidth
              label="รหัสตัวแปร (ถ้ามี)"
              helperText="สามารถใช้ตัวอักษร A-Z, a-z,0-9 หรือ _ เท่านั้น"
            />
          )}
        />
      </div>
      <div className="w-full py-2 ">
        <Controller
          name="description"
          control={control}
          // defaultValue={measurementType?.description}
          render={({ field }) => (
            <TextField
              {...field}
              label="รายละเอียด / คำอธิบายที่จะให้กับผู้ใช้"
              multiline
              size="small"
              fullWidth
              rows={2}
            />
          )}
        />
      </div>
      <div className="w-full py-2 ">
        <Controller
          name="data_type"
          control={control}
          defaultValue={measurementType?.data_type}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel>ชนิดข้อมูล</InputLabel>
              <Select {...field} label="ชนิดข้อมูล" fullWidth>
                {_.map(CONFIG.DATA_TYPE, (eachConfig, index) => (
                  <MenuItem key={index} value={eachConfig.type_code}>
                    <i className={`${eachConfig.icon} mr-2 text-lg`} />
                    {eachConfig.description}{' '}
                  </MenuItem>
                ))}
              </Select>
              {errors?.data_type && (
                <FormHelperText>กรุณาเลือกชนิดของตัวแปร</FormHelperText>
              )}
            </FormControl>
          )}
        />
      </div>
      {!_.isEmpty(units) &&
        watch('data_type') === CONFIG.DATA_TYPE.NUMBER.type_code && (
          <div className="w-full py-2 ">
            <Controller
              name={'unit'}
              control={control}
              rules={{ required: false }}
              defaultValue={
                measurementType ? measurementType?.unit : units?.rows?.[0]
              }
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  size="small"
                  disablePortal
                  options={units?.rows}
                  placeholder="เลือกหน่วย"
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                  }}
                  // prettier-ignore
                  getOptionLabel={(option) => `${option?.name}`
        }
                  renderInput={(params) => (
                    <TextField label="เลือกหน่วย" {...params} />
                  )}
                />
              )}
            />
          </div>
        )}

      {(watch('data_type') === CONFIG.DATA_TYPE.NUMBER.type_code ||
        watch('data_type') === CONFIG.DATA_TYPE.STRING.type_code) && (
        <div className="w-full py-2 ">
          <Controller
            name="default_value"
            control={control}
            rules={{ required: false }}
            defaultValue={measurementType?.default_value}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                label="ค่าเริ่มต้น"
                fullWidth
              />
            )}
          />
        </div>
      )}
      {CONFIG.DATA_TYPE?.[watch('data_type')]?.requirable && (
        <div className="w-full py-2 ">
          <Controller
            name={'unit'}
            control={control}
            rules={{ required: false }}
            defaultValue={
              measurementType ? measurementType?.unit : units?.rows?.[0]
            }
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} />}
                label="จำเป็นต้องกรอก"
              />
            )}
          />
        </div>
      )}
      {watch('data_type') === CONFIG.DATA_TYPE.CHECK_AND_DESCRIBE.type_code && (
        <CheckboxAddditionalInput
          control={control}
          defaultValue={measurementType}
        />
      )}
      {watch('data_type') === CONFIG.DATA_TYPE.BOOLEAN.type_code && (
        <BooleanAdditionalInput control={control} />
      )}
      {watch('data_type') === CONFIG.DATA_TYPE.RADIO_AND_DESCRIBE.type_code && (
        <RadioAndDescribeAdditionalInput
          control={control}
          defaultValue={measurementType}
        />
      )}
      {watch('data_type') === CONFIG.DATA_TYPE.SWITCH.type_code && (
        <SwitchAdditionalInput
          control={control}
          defaultValue={measurementType}
        />
      )}
    </div>
  </div>
);

MeasurementTypeForm.propTypes = {
  control: PropTypes.func,
  measurementType: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    data_type: PropTypes.string,
    unit: PropTypes.object,
    default_value: PropTypes.string,
  }),
  units: {
    rows: PropTypes.arrayOf(PropTypes.object),
  },
  watch: PropTypes.func,
  errors: PropTypes.object,
};

export default MeasurementTypeForm;
