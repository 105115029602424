import _ from 'lodash';
import { INVENTORY_WORKFLOW } from '@iarcpsu/emanufac-constant';

const findWorkType = ({ representStartLot, representEndLot }) => {
  if (
    representStartLot?.place?._id?.toString() ===
    representEndLot?.place?._id?.toString()
  ) {
    // รับวัตถุดิบเข้า
    if (_.isEmpty(representStartLot?.previous_lot)) {
      // ยังได้วัตถุดิบชิ้นเดียวกัน
      if (
        representStartLot?.material?._id?.toString() ===
        representEndLot?.material?._id?.toString()
      ) {
        return INVENTORY_WORKFLOW.INITIAL_WAITING.status_code;
      }

      return INVENTORY_WORKFLOW.INITIAL_IN_PROGRESS.status_code;
    }
    // ยังได้วัตถุดิบชิ้นเดียวกัน
    if (
      representStartLot?.material?._id?.toString() ===
      representEndLot?.material?._id?.toString()
    ) {
      return INVENTORY_WORKFLOW.WAITING.status_code;
    }

    return INVENTORY_WORKFLOW.IN_PROGRESS.status_code;

    // วัตถุดิบ มาจากขั้นตอนอื่น
  }
  return INVENTORY_WORKFLOW.IN_PLACE_WAITING_TO_MOVE.status_code;
};

export default findWorkType;
