import {
  WAREHOUSE_STOCK_REQUEST_ALL,
  WAREHOUSE_STOCK_REQUEST_GET,
  WAREHOUSE_STOCK_REQUEST_DELETE,
  WAREHOUSE_STOCK_REQUEST_PUT,
  WAREHOUSE_STOCK_REQUEST_POST,
  WAREHOUSE_STOCK_REQUEST_LOADING,
  WAREHOUSE_STOCK_REQUEST_ERROR,
} from '../types';

import api from '../../../configs/api';
import graphqlClient from '../../../configs/graphqlClient';

export const warehouseStockRequestCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: WAREHOUSE_STOCK_REQUEST_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/warehouse-stock-request`,
      {
        ...payload,
      },
    );
    dispatch({ type: WAREHOUSE_STOCK_REQUEST_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: WAREHOUSE_STOCK_REQUEST_ERROR });
    throw new Error(error);
  }
};

export const warehouseStockRequestAll = ({
  name = '',
  size = 10,
  page = 1,
}) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/warehouse-stock-request?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: WAREHOUSE_STOCK_REQUEST_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: WAREHOUSE_STOCK_REQUEST_ERROR });
    throw new Error(error);
  }
};

export const warehouseStockRequestAllQuery = ({ query }) => async (
  dispatch,
) => {
  try {
    const queryResult = await graphqlClient.request(query);
    const data = queryResult?.findUnits;
    //  console.log('Query Data', queryResult);
    if (!data) {
      dispatch({ type: WAREHOUSE_STOCK_REQUEST_ERROR });
    }

    dispatch({ type: WAREHOUSE_STOCK_REQUEST_ALL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: WAREHOUSE_STOCK_REQUEST_ERROR });
    throw new Error(error);
  }
};

export const warehouseStockRequestGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/warehouse-stock-request/${id}`,
    );
    if (status === 200) {
      dispatch({ type: WAREHOUSE_STOCK_REQUEST_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: WAREHOUSE_STOCK_REQUEST_ERROR });
    throw new Error(error);
  }
};

export const warehouseStockRequestStateOneSet = (data) => async (dispatch) => {
  dispatch({ type: WAREHOUSE_STOCK_REQUEST_GET, payload: data });
};

export const warehouseStockRequestStateSet = (data) => async (dispatch) => {
  dispatch({ type: WAREHOUSE_STOCK_REQUEST_ALL, payload: data });
};

export const warehouseStockRequestLoading = (data) => async (dispatch) => {
  dispatch({ type: WAREHOUSE_STOCK_REQUEST_LOADING, payload: data });
};

export const warehouseStockRequestError = (data) => async (dispatch) => {
  dispatch({ type: WAREHOUSE_STOCK_REQUEST_ERROR, payload: data });
};

export const warehouseStockRequestPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: WAREHOUSE_STOCK_REQUEST_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/warehouse-stock-request/${id}`,
      payload,
    );
    dispatch({ type: WAREHOUSE_STOCK_REQUEST_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: WAREHOUSE_STOCK_REQUEST_ERROR });
    throw new Error(error);
  }
};
export const warehouseStockRequestDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: WAREHOUSE_STOCK_REQUEST_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/warehouse-stock-request/${id}`,
    );
    dispatch({ type: WAREHOUSE_STOCK_REQUEST_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: WAREHOUSE_STOCK_REQUEST_ERROR });
    throw new Error(error);
  }
};
