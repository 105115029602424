import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardContent } from '@mui/material';
import { gql } from 'graphql-request';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  ProductBomBox,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import graphqlClient from '../../../configs/graphqlClient';
import * as actions from '../../../redux/actions';

/**
 * @function SettingViewBOMTemplate
 * @description Display a collections or a list of SettingViewBOMTemplate from database
 */

export default function DetailBomTemplate({ title, subtitle }) {
  const dispatch = useDispatch();
  const bomTemplate = useSelector((state) => state.bomTemplate);
  const params = useParams();

  const query = gql`
    query FindSettingViewBOMTemplate($bomTemplateInput: BomTemplateInput) {
      findOneBomTemplate(input: $bomTemplateInput) {
        _id
        name
        as_a_batch
        bill_of_materials {
          amount
          tag
          material {
            _id
            name
            type_code
            use_unit_conversion
            unit
            conversion_from {
              unit_a
              unit_b
              conversion_factor
            }
            conversion_to {
              unit_b
              unit_a
              conversion_factor
            }
            unit_input {
              _id
              short_sign
              name
            }
            unit_output {
              _id
              short_sign
              name
            }
          }
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        bomTemplateInput: { id: params.id },
      });
      const bomTemplateData = queryResult?.findOneBomTemplate;
      console.log('Bom Template Data', bomTemplateData);
      dispatch(actions.bomTemplateStateOneSet(bomTemplateData));
    } catch (err) {
      console.error('Query Error', err);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (bomTemplate.isLoading) {
    return <Loading />;
  }

  if (!bomTemplate.isLoading && bomTemplate.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <BackButton />
        </div>
        <div>
          <Card>
            <CardContent>
              <div className="text-lg font-semibold font-display m-2">
                {bomTemplate?.name} {bomTemplate?.as_a_batch ? '1 Batch' : ''}
              </div>
              <div className="flex flex-wrap my-2">
                <div className="w-full p-2">
                  <ProductBomBox product={bomTemplate} selectedUnit="" />
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailBomTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailBomTemplate.defaultProps = {
  title: '',
  subtitle: '',
};
