// Create Redux action for PDF Report API Calling

import api from '../../../configs/api';
import {
  PDF_REPORT_ALL,
  PDF_REPORT_DELETE,
  PDF_REPORT_ERROR,
  PDF_REPORT_GET,
  PDF_REPORT_LOADING,
  PDF_REPORT_POST,
  PDF_REPORT_PUT,
} from '../types';

/**
 *  @typedef {Object} PDFReportQuery
 * @property {string} name Name for searching
 * @property {number} size
 * @property {number} page
 * @property {string} warehouse Can be either Inventory place id or product warehouse id
 * @param {PDFReportQuery} params
 * @returns {Promise} dispatch
 */
export const pdfReportAll = ({
  name = '',
  size = 10,
  page = 1,
  warehouse = '',
}) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/pdf-report?name=${name}&size=${size}&page=${page}&warehouse=${warehouse}`,
    );
    if (status === 200) {
      dispatch({ type: PDF_REPORT_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PDF_REPORT_ERROR });
    throw new Error(error);
  }
};

export const pdfReportCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PDF_REPORT_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/pdf-report`,
      {
        ...payload,
      },
    );
    dispatch({ type: PDF_REPORT_POST, payload: data });
  } catch (error) {
    dispatch({ type: PDF_REPORT_ERROR });
  }
};

export const pdfReportUpdate = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: PDF_REPORT_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/pdf-report/${id}`,
      payload,
    );
    dispatch({ type: PDF_REPORT_PUT, payload: data });
  } catch (error) {
    dispatch({ type: PDF_REPORT_ERROR });
  }
};

export const pdfReportDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: PDF_REPORT_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/pdf-report/${id}`,
    );
    dispatch({ type: PDF_REPORT_DELETE, payload: data });
  } catch (error) {
    dispatch({ type: PDF_REPORT_ERROR });
    throw new Error(error);
  }
};

export const pdfReportGet = (id) => async (dispatch) => {
  try {
    dispatch({ type: PDF_REPORT_LOADING });
    const { data } = await api.get(
      `${process.env.REACT_APP_API_URL}/pdf-report/${id}`,
    );
    dispatch({ type: PDF_REPORT_GET, payload: data });
  } catch (error) {
    dispatch({ type: PDF_REPORT_ERROR });
  }
};

export const pdfReportStateSet = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PDF_REPORT_ALL, payload });
  } catch (error) {
    dispatch({ type: PDF_REPORT_ERROR });
  }
};

export const pdfReportStateOneSet = (payload) => async (dispatch) => {
  dispatch({ type: PDF_REPORT_GET, payload });
};

export const pdfReportReset = () => async (dispatch) => {
  dispatch({ type: PDF_REPORT_LOADING, payload: [] });
};

export const pdfReportError = () => async (dispatch) => {
  dispatch({ type: PDF_REPORT_ERROR });
};
