import React from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import PropTypes from 'prop-types';

const MaterialSourceForm = ({ control, materialSource, customer, watch }) => (
  <div className="flex flex-row flex-wrap w-full">
    <div className="px-1 py-2 self-center">
      {watch('supplier')?.type_code || '-'} /
    </div>{' '}
    <div className="px-1 py-2 w-1/2">
      <Controller
        name={'type_code'}
        control={control}
        defaultValue={materialSource ? materialSource.type_code : ''}
        render={({ field }) => (
          <TextField
            {...field}
            label="รหัสแหล่งวัตถุดิบ เรือ / รถบรรทุก"
            fullWidth
            size={'small'}
          />
        )}
      />
    </div>{' '}
    <div className="w-full px-1 py-2">
      <Controller
        name={'name'}
        control={control}
        defaultValue={materialSource ? materialSource.name : ''}
        render={({ field }) => (
          <TextField
            {...field}
            label="ชื่อแหล่งวัตถุดิบ เรือ / รถบรรทุก"
            fullWidth
            required
            size={'small'}
          />
        )}
      />
    </div>
    <div className="w-full px-1 py-2">
      <Controller
        control={control}
        name="supplier"
        defaultValue={materialSource?.supplier}
        render={({ field }) => (
          <Autocomplete
            {...field}
            disablePortal
            options={customer?.rows}
            size="small"
            placeholder="เลือกคู่ค้า"
            onChange={(e, newValue) => {
              field.onChange(newValue);
            }}
            className="z-40"
            // prettier-ignore
            getOptionLabel={(option) => `${option?.type_code || ''} ${option?.name || ''}`
     }
            renderInput={(params) => (
              <TextField label="เลือกคู่ค้า" {...params} />
            )}
          />
        )}
      />
    </div>{' '}
    <div className="w-full px-1 py-2">
      <Controller
        name={'owner'}
        control={control}
        defaultValue={materialSource ? materialSource.owner : ''}
        render={({ field }) => (
          <TextField {...field} label="เจ้าของ" fullWidth size={'small'} />
        )}
      />
    </div>
    <div className="w-full px-1 py-2">
      <Controller
        name={'licence_number'}
        control={control}
        defaultValue={materialSource ? materialSource.licence_number : ''}
        render={({ field }) => (
          <TextField {...field} label="เลขทะเบียน" fullWidth size={'small'} />
        )}
      />
    </div>{' '}
    <div className="w-full px-1 py-2">
      <Controller
        name={'desctiption'}
        control={control}
        defaultValue={materialSource ? materialSource.desctiption : ''}
        render={({ field }) => (
          <TextField
            multiline
            rows={3}
            {...field}
            label="รายละเอียดเพิ่มเติม"
            fullWidth
            size={'small'}
          />
        )}
      />
    </div>
  </div>
);

export default MaterialSourceForm;

MaterialSourceForm.propTypes = {
  control: PropTypes.object,
  materialSource: PropTypes.object,
  customer: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  watch: PropTypes.func,
};
