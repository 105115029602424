/* eslint-disable import/prefer-default-export */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import { he } from 'date-fns/locale';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';
import THBText from 'thai-baht-text';

import { converseMaterialOrProductUnit } from '@/utils/functions/converseMaterialOrProductUnit';
import currencyFormatterTH from '@/utils/functions/currencyFormatterTH';

import 'addthaifont-pdfmake/build/vfs_fonts';

import { ReportHeader } from './common';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};

function splitter(inputString, l) {
  let str = inputString;
  const strs = [];
  while (str.length > l) {
    let pos = str.substring(0, l).lastIndexOf(' ');
    pos = pos <= 0 ? l : pos;
    strs.push(str.substring(0, pos));
    let i = str.indexOf(' ', pos) + 1;
    if (i < pos || i > pos + l) {
      i = pos;
    }
    str = str.substring(i);
  }
  strs.push(str);
  return strs;
}

const generateProductRemark = (remark) => {
  const splitWithLodash = _.split(remark, ' ');
  const newWordResult = _.map(splitWithLodash, (word) => {
    if (_.size(word) > 40) {
      const splitedRemark = splitter(word, 40);
      return _.join(splitedRemark, '\n');
    }

    return word;
  });

  return _.join(newWordResult, ' ');
};

const genOrder = (data) =>
  _.map(data, (_data, index) => {
    if (_data?.produce_material_instead) {
      return [
        {
          text: index + 1,
          border: [true, false, true, true],
          fontSize: 10,
          colSpan: 1,
        },
        {
          text: `${_data?.product_as_material?.type_code || ''} ${
            _data?.product_as_material?.name || ''
          }${
            _data?.remark ? `\n ${generateProductRemark(_data?.remark)}` : ''
          }`,
          border: [true, false, true, true],
          fontSize: 10,
          colSpan: 1,
        },
        {
          text: `${currencyFormatterTH.format(_data?.quantity)} ${
            _data?.additional_unit ||
            `${
              _data?.product_as_material?.use_unit_conversion
                ? `${
                    _data?.product_as_material?.unit_input?.short_sign
                  } (${converseMaterialOrProductUnit(
                    _data?.product_as_material,
                    _data?.quantity,
                  )} ${_data?.product_as_material?.unit_output?.short_sign})`
                : `${_data?.product_as_material?.unit}`
            }`
          }`,
          border: [true, false, true, true],
          fontSize: 10,
          alignment: 'center',
          colSpan: 1,
        },
        {
          text: `${currencyFormatterTH.format(_data?.price_per_unit)}`,
          border: [true, false, true, true],
          fontSize: 10,
          alignment: 'center',
          colSpan: 1,
        },
        {
          text: `${currencyFormatterTH.format(
            _data?.price_per_unit * _data?.quantity,
          )}`,
          border: [true, false, true, true],
          fontSize: 10,
          alignment: 'right',
          colSpan: 1,
        },
      ];
    }
    return [
      {
        text: index + 1,
        border: [true, false, true, true],
        fontSize: 10,
        colSpan: 1,
      },
      {
        text: `${_data?.product?.type_code || ''} ${
          _data?.product?.name || ''
        }${_data?.remark ? `\n ${generateProductRemark(_data?.remark)}` : ''}`,
        border: [true, false, true, true],
        fontSize: 10,
        colSpan: 1,
      },
      {
        text: `${currencyFormatterTH.format(_data?.quantity)}  ${
          _data?.additional_unit ||
          `${
            _data?.product?.use_unit_conversion
              ? `${
                  _data?.product?.unit_input?.short_sign
                } (${converseMaterialOrProductUnit(
                  _data?.product,
                  _data?.quantity,
                )} ${_data?.product?.unit_output?.short_sign})`
              : `${_data?.product?.unit}`
          }`
        }`,
        border: [true, false, true, true],
        fontSize: 10,
        alignment: 'center',
        colSpan: 1,
      },
      {
        text: `${currencyFormatterTH.format(_data?.price_per_unit)}`,
        border: [true, false, true, true],
        fontSize: 10,
        alignment: 'center',
        colSpan: 1,
      },
      {
        text: `${currencyFormatterTH.format(
          _data?.price_per_unit * _data?.quantity,
        )}`,
        border: [true, false, true, true],
        fontSize: 10,
        alignment: 'right',
        colSpan: 1,
      },
    ];
  });

const calculateProductCost = (processes) => {
  const listOfProductCost = _.map(
    processes,
    (eachProcess) =>
      (eachProcess.price_per_unit || 0) * (eachProcess.quantity || 0),
  );

  console.log('listOfProductCost', listOfProductCost);

  return _.sum(listOfProductCost);
};

const calculatePrice = (processes, discount, expressCost) => {
  const productionCost = calculateProductCost(processes);
  const totalCost = productionCost + expressCost || 0 - discount || 0;
  return totalCost;
};

export const QuotationReport = (order, information, showImage) => {
  console.log('order', order);
  console.log('information', information);

  const orderData = genOrder(order?.processes);
  const orderPrice = calculatePrice(
    order?.processes,
    order?.discount,
    order?.express_fee,
  );

  const orderWithoutExpress = calculateProductCost(order?.processes);
  const orderWithOutDiscount = orderPrice - order?.discount;

  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 30, 40, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },
    info: {
      title: 'ใบเสนอราคา',
    },
    content: [
      showImage
        ? ReportHeader(
            information,
            true,
            information?.setting?.information?.enable_right_logo,
          )
        : ReportHeader(information, false, false),
      {
        // หัวข้อ
        alignment: 'center',
        margin: [0, 5, 0, 10],
        text: 'ใบเสนอราคา',
        fontSize: '16',
        bold: true,
      },
      {
        style: 'tableExample',
        table: {
          widths: ['7%', '45%', '17%', '35%'],
          heights: [15],
          body: [
            [
              {
                text: 'ถึง',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, false, false],
              },
              {
                text: `${order?.customer?.name || '-'}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, true, true, false],
              },
              {
                text: 'เลขที่เอกสาร',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, false, false],
              },
              {
                text: `${order?.prefix}${order?.running_number}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, true, true, false],
              },
            ],
            [
              {
                text: 'ที่อยู่',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, false],
              },
              {
                text: `${order?.customer?.address || '-'}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, true, false],
              },
              {
                text: 'วันสร้างใบเสนอราคา',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, false],
              },
              {
                text: `${dayjs(order?.start_date)
                  .locale('th')
                  .format('DD MMM BBBB')}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: '',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, false],
              },
              {
                text: `เลขประจำตัวผู้เสียภาษี ${order?.customer?.taxes || '-'}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: 'กำหนดยืนราคา',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, false],
              },
              {
                text: `${order?.valid_price_day || 0} วัน ถึงวันที่ ${dayjs(
                  order?.start_date,
                )
                  .add(order?.valid_price_day || 0, 'day')
                  .locale('th')
                  .format('DD MMM BBBB')}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: '',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, true],
              },
              {
                text: `โทร ${order?.customer?.phone_number || '-'}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, true, true],
              },
              {
                text: 'เงื่อนไขการชำระเงิน',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, true],
              },
              {
                text: `${order?.payment_method?.name || '-'}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, true, true],
              },
            ],
          ],
        },
      },
      {
        margin: [0, 20, 0, 5],
        text: 'บริษัท มีความยินดีที่จะเสนอราคาสินค้า ดังต่อไปนี้',
        fontSize: 10,
      },
      {
        margin: [0, 5, 0, 0],
        style: 'tableExample',
        table: {
          widths: ['10%', '49%', '15%', '15%', '15%'],
          heights: [15],
          body: [
            [
              {
                text: 'ลำดับ',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
                alignment: 'center',
              },
              {
                text: 'สินค้า / รายละเอียด',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
                alignment: 'center',
              },
              {
                text: 'จำนวน',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
                alignment: 'center',
              },
              {
                text: 'หน่วยละ',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
                alignment: 'center',
              },
              {
                text: 'ราคารวมภาษี',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
                alignment: 'center',
              },
            ],
            ...orderData,
            [
              {
                text: 'หมายเหตุ :',
                colSpan: 3,
                fontSize: '10',
                border: [true, false, false, false],
              },
              {},
              {},
              {
                text: 'รวมเป็นเงิน',
                colSpan: 1,
                fontSize: 10,
                border: [true, false, false, false],
              },
              {
                text: `${
                  currencyFormatterTH.format(orderWithoutExpress) || '0'
                } บาท`,
                alignment: 'right',
                colSpan: 1,
                fontSize: 10,
                border: [true, false, true, false],
              },
            ],
            order?.express
              ? [
                  {
                    text: order?.remark || '',
                    colSpan: 3,
                    fontSize: '10',
                    rowSpan: 4,
                    border: [true, false, false, false],
                  },
                  {},
                  {},
                  {
                    text: 'ค่าผลิตด่วน',
                    colSpan: 1,
                    fontSize: 10,
                    border: [true, true, false, true],
                  },
                  {
                    text: `${
                      currencyFormatterTH.format(order?.express_fee) || '0'
                    } บาท`,
                    alignment: 'right',
                    colSpan: 1,
                    fontSize: 10,
                    border: [true, true, true, true],
                  },
                ]
              : [
                  {
                    text: order?.remark || '',
                    colSpan: 3,
                    fontSize: '10',
                    rowSpan: 4,
                    border: [true, false, false, false],
                  },
                  {},
                  {},
                  {
                    text: '',
                    colSpan: 1,
                    fontSize: 10,
                    border: [true, false, false, true],
                  },
                  {
                    text: '',
                    alignment: 'right',
                    colSpan: 1,
                    fontSize: 10,
                    border: [true, false, true, true],
                  },
                ],
            [
              {},
              {},
              {},
              {
                text: 'หัก ส่วนลด',
                colSpan: 1,
                fontSize: 10,
                border: [true, false, false, true],
              },
              {
                text: `${
                  currencyFormatterTH.format(order?.discount) || '0'
                } บาท`,
                alignment: 'right',
                colSpan: 1,
                fontSize: 10,
                border: [true, false, true, true],
              },
            ],
            [
              {},
              {},
              {},
              {
                text: 'ราคาสินค้า',
                colSpan: 1,
                fontSize: 10,
                border: [true, true, false, true],
              },
              {
                text: `${
                  currencyFormatterTH.format((orderPrice * 93) / 100) || '0'
                } บาท`,
                alignment: 'right',
                colSpan: 1,
                fontSize: 10,
                border: [true, false, true, true],
              },
            ],
            [
              {},
              {},
              {},
              {
                text: 'ภาษีมูลค่าเพิ่ม 7%',
                colSpan: 1,
                fontSize: 10,
                border: [true, false, false, true],
              },
              {
                text: `${
                  currencyFormatterTH.format((orderPrice * 7) / 100) || '0'
                } บาท`,
                alignment: 'right',
                colSpan: 1,
                fontSize: 10,
                border: [true, false, true, true],
              },
            ],
            [
              {
                text: `( ${THBText(orderPrice)} )`,
                alignment: 'center',
                colSpan: 3,
                fontSize: 10,
                border: [true, true, false, true],
              },
              {},
              {},
              {
                text: 'รวมทั้งสิ้น',
                colSpan: 1,
                fontSize: 10,
                border: [true, false, false, true],
              },
              {
                text: `${currencyFormatterTH.format(orderPrice) || '0'} บาท`,
                alignment: 'right',
                colSpan: 1,
                fontSize: 10,
                border: [true, false, true, true],
              },
            ],
          ],
        },
      },
      {
        style: 'tableExample',
        table: {
          widths: ['25%', '25%', '25%', '25%'],
          margin: [0, 30, 0, 10],
          heights: [30, 15, 15],
          body: [
            [
              { border: [false, false, false, false], text: '', colSpan: 4 },
              {},
              {},
              {},
            ],
            [
              {
                text: 'กรุณายืนยันการสั่งซื้อด้านล่างนี้',
                colSpan: 2,
                fontSize: 10,
                alignment: 'center',
                border: [false, false, false, false],
              },
              {},
              {
                text: 'ขอแสดงความนับถือ',
                colSpan: 2,
                fontSize: 10,
                alignment: 'center',
                border: [false, false, false, false],
              },
              {},
            ],
            [
              { border: [false, false, false, false], text: '', colSpan: 2 },
              {},
              {
                text: `ในนาม ${information?.owner?.name || ''}`,
                bold: true,
                alignment: 'center',
                colSpan: 2,
                border: [false, false, false, false],
              },
              {},
            ],
            [
              {
                border: [false, false, false, false],
                text: '',
                colSpan: 4,
                height: 20,
              },
              {},
              {},
              {},
            ],
            [
              {
                border: [false, false, false, false],
                text: '',
                colSpan: 4,
                height: 20,
              },
              {},
              {},
              {},
            ],
            [
              {
                text: '........................................',
                colSpan: 2,
                bold: true,
                fontSize: 10,
                alignment: 'center',
                border: [false, false, false, false],
              },
              {},
              {
                text: '........................................',
                colSpan: 2,
                bold: true,
                fontSize: 10,
                alignment: 'center',
                border: [false, false, false, false],
              },
              {},
            ],
            [
              {
                text: '(........................................)',
                colSpan: 2,
                bold: true,
                fontSize: 10,
                alignment: 'center',
                border: [false, false, false, false],
              },
              {},
              {
                text: '(........................................)',
                colSpan: 2,
                bold: true,
                fontSize: 10,
                alignment: 'center',
                border: [false, false, false, false],
              },
              {},
            ],
            [
              {
                text: 'ผู้สั่งซื้อสินค้า',
                colSpan: 2,
                fontSize: 10,
                alignment: 'center',
                border: [false, false, false, false],
              },
              {},
              {
                text: 'ฝ่ายขาย',
                colSpan: 2,
                fontSize: 10,
                alignment: 'center',
                border: [false, false, false, false],
              },
              {},
            ],
            [
              {
                text: '....../......../......',
                colSpan: 2,
                fontSize: 10,
                alignment: 'center',
                border: [false, false, false, false],
              },
              {},
              {
                text: '',
                colSpan: 2,
                fontSize: 10,
                alignment: 'center',
                border: [false, false, false, false],
              },
              {},
            ],
          ],
        },
      },
    ],
    // images: {
    //   logo: information?.logo?.url,
    // },
  };
  try {
    const newDocDefinition = docDefinition;
    if (showImage) {
      newDocDefinition.images = {
        logo: information?.logo?.url,
      };
      if (information?.setting?.information?.enable_right_logo) {
        newDocDefinition.images.right_logo = information?.right_logo?.url;
      }
    }
    pdfMake.createPdf(newDocDefinition).open();
  } catch (e) {
    pdfMake.createPdf(docDefinition).open();
  }
};
