/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Error,
  ExportExcelContainer,
  LoadingDialogWithTimeout,
  LoadingLinear,
  MaterialTransactionTable,
  SocketIOLoading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { MaterialHReport } from '@iarcpsu/emanufac-components/src/components/Report';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Button,
  Card,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import thLocale from 'date-fns/locale/th';
import _ from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';

import api from '@/configs/api';
import * as actions from '@/redux/actions';
import { MQ_TASK } from '@/utils/constants';
import { MaterialTransasctionUtil } from '@/utils/excelColumn';

function MaterialHistory({ title, subtitle }) {
  const dispatch = useDispatch();
  const materialTransaction = useSelector((state) => state.materialTransaction);
  const materialTransactionType = useSelector(
    (state) => state.materialTransactionType,
  );
  const information = useSelector((state) => state.information);
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [month, setMonth] = useState(new Date());
  const [monthChang, setMonthChang] = useState();
  const { control } = useForm();
  const [selectType, setSelectType] = useState('');
  const [referenceNumber, setReferenceNumber] = useState('');
  const [isLoadingDialogOpen, setIsLoadingDialogOpen] = useState(false);
  const getMaterialTransaction = () => {
    dispatch(
      actions.materialTransactionAll({
        materialName: name,
        page,
        size,
        selectType,
      }),
    );
  };
  useEffect(() => {
    if (monthChang) {
      dispatch(
        actions.materialTransactionSearch({
          materialName: name,
          page,
          size,
          month: new Date(month).toISOString().split('T')[0],
          selectType,
        }),
      );
    } else {
      dispatch(actions.materialTransactionLoading());
      getMaterialTransaction();
    }
    dispatch(actions.materialTransactionTypeAll(''));
    dispatch(actions.informationAll());
    return () => {};
  }, [name, page, size, selectType, monthChang]);

  useEffect(() => {
    setTotal(materialTransaction?.total);
    return () => {};
  }, [materialTransaction]);

  useEffect(() => {
    const tempRefNumber = hash({ date: new Date() });
    setReferenceNumber(tempRefNumber);
    return () => {};
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  // const handleSeachMaterialTransaction = () => {
  //   setMonthChang(month);
  //   dispatch(
  //     actions.materialTransactionSearch({
  //       materialName: name,
  //       page,
  //       size,
  //       month: new Date(month).toISOString().split('T')[0],
  //       selectType,
  //     }),
  //   );
  // };

  const handleMaterialTransactionAll = async () => {
    try {
      if (monthChang) {
        const { data: materialTransactionNew } = await api.get(
          `${process.env.REACT_APP_API_URL}/material-transaction?month=${
            new Date(monthChang).toISOString().split('T')[0]
          }&selectType=${selectType}&materialName=${name}`,
        );
        return materialTransactionNew;
      }
      const { data: materialTransactionNew } = await api.get(
        `${process.env.REACT_APP_API_URL}/material-transaction?selectType=${selectType}&materialName=${name}`,
      );
      return materialTransactionNew;
    } catch (error) {
      console.error(error);
    }
  };

  const handlePrintPdf = async () => {
    const materialTransactionNew = await handleMaterialTransactionAll();
    try {
      await MaterialHReport(
        materialTransactionNew?.rows,
        information,
        monthChang,
      );
    } catch (error) {
      await MaterialHReport(
        materialTransactionNew?.rows,
        information,
        monthChang,
        true,
      );
    }
  };

  const handleDelete = async (row) => {
    const confirm = window.confirm('ยืนยันการลบ');
    if (confirm) {
      try {
        dispatch(actions.materialTransactionDelete(row.id, referenceNumber));
        setIsLoadingDialogOpen(true);
      } catch (error) {
        alert(`ไม่สามารถลบได้ ${error?.message}`);
      }
    }
  };

  const handleSelectType = (type) => {
    setSelectType(type);
  };

  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4 gap-2">
      <Button variant="contained" color="teal" onClick={() => handlePrintPdf()}>
        พิมพ์
      </Button>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row gap-2">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/2">
          <Controller
            name={'status_code'}
            control={control}
            defaultValue={''}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                <InputLabel id="type" size={'small'}>
                  ค้นหาด้วยประเภท
                </InputLabel>
                <Select
                  {...field}
                  label="ค้นหาด้วยประเภท"
                  size={'small'}
                  fullWidth
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    handleSelectType(e.target.value);
                  }}
                >
                  <MenuItem value="">ทั้งหมด</MenuItem>
                  {_.map(materialTransactionType.rows, (_type) => (
                    <MenuItem value={_type?._id} key={_type?._id}>
                      {_type?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </div>
        <div className="w-full  md:w-4/12 md:pl-1 my-2 md:my-0">
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={thLocale}>
            <DatePicker
              views={['year', 'month']}
              label="เดือน"
              minDate={new Date('2012-03-01')}
              maxDate={new Date('2030-06-01')}
              value={month}
              onChange={(newValue) => {
                setMonth(newValue);
                setMonthChang(newValue);
              }}
              renderInput={(params) => (
                <TextField fullWidth {...params} required size={'small'} />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="w-full md:w-2/12 ml-1 flex justify-items-center my-2 md:my-0">
          <ExportExcelContainer
            columnList={MaterialTransasctionUtil.columns}
            currentData={materialTransaction?.rows}
            dataAPIEndPoint="material-transaction"
            dataQuery={{}}
            sheetName="MaterialTransaction"
            spreadedColumn="material_stock_lot"
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <MaterialTransactionTable
        page={page}
        setPage={setPage}
        size={size}
        setSize={setSize}
        total={total}
        transaction={materialTransaction}
        disableDelete={false}
        handleDelete={(row) => handleDelete(row)}
      />
    </div>
  );

  const renderMainTransactionInfo = () => {
    if (materialTransaction.isLoading) {
      return <LoadingLinear />;
    }
    if (!materialTransaction.isLoading && materialTransaction.isCompleted) {
      return <div> {renderTable()}</div>;
    }
    return <Error message={materialTransaction?.message} />;
  };

  return (
    <div>
      <div className="flex justify-between">
        <div>{renderTitle()}</div>
        <div className="mt-6">{renderAddButtom()}</div>
      </div>{' '}
      <LoadingDialogWithTimeout
        action="REFRESH"
        isLoading={isLoadingDialogOpen}
      />
      <SocketIOLoading
        handleFail={() => {
          alert('การดำเนินการไม่สำเร็จ');
        }}
        handleSuccess={() => {
          setIsLoadingDialogOpen(false);
          getMaterialTransaction();
        }}
        referenceNumber={referenceNumber}
        setStatusMessage={() => {}}
        taskCode={MQ_TASK.REMOVE_TRANSACTION.status_code}
      />
      {renderSearch()}
      {renderMainTransactionInfo()}
    </div>
  );
}

MaterialHistory.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MaterialHistory.defaultProps = {
  title: '',
  subtitle: '',
};

export default MaterialHistory;
