import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Autocomplete,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { DesktopDatepicker } from '../../DatePicker';
import EmployeeAutoComplete from '../../Inputs/EmployeeAutoComplete';

function InternalDepositForm({
  employee,
  control,
  defaultValue,
  watch,
  customer,
  setReload = () => {},
  reload,
  customerType,
  errors,
}) {
  const renderAttachCustomer = () => (
    <div>
      <div className="flex flex-row flex-wrap ">
        <div className="w-full py-1 ">
          {!_.isEmpty(customer?.rows) && (
            <Controller
              name="customer"
              control={control}
              defaultValue={defaultValue?.customer}
              render={({ field }) => (
                <Autocomplete
                  size={'small'}
                  disableClearable
                  {...field}
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                    setReload(!reload);
                  }}
                  options={_.map(
                    customer?.rows,
                    (eachCustomer, customerIndex) => ({
                      ...eachCustomer,
                      index: customerIndex,
                    }),
                  )}
                  getOptionLabel={(option) =>
                    `${option?.index || ''} ${option?.type_code || ''} | ${
                      option?.name
                    }`
                  }
                  isOptionEqualToValue={(option, value) =>
                    option?._id === value?._id
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="เลือกลูกค้า" />
                  )}
                />
              )}
            />
          )}
        </div>
      </div>
    </div>
  );

  const renderCreateNewCustomer = () => (
    <div className="flex flex-wrap">
      <div className="w-full p-1">
        <Controller
          control={control}
          name="customer.name"
          render={({ field }) => (
            <TextField {...field} size="small" label="ชื่อ" fullWidth />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'customer.type'}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true} required>
              <InputLabel id="type" size={'small'}>
                หมวดหมู่
              </InputLabel>
              <Select {...field} label="หมวดหมู่" size={'small'} fullWidth>
                {_.size(customerType?.rows) ? (
                  _.map(customerType.rows, (row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'customer.organization_type'}
          control={control}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                ประเภทธุรกิจ
              </InputLabel>
              <Select {...field} label="ประเภทธุรกิจ" size={'small'} fullWidth>
                <MenuItem value="บุคคลธรรมดา">บุคคลธรรมดา</MenuItem>
                <MenuItem value="นิติบุคคล">นิติบุคคล</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full  px-1 py-2">
        <Controller
          name={'customer.type_code'}
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รหัสลูกค้า / คู่ค้า"
              fullWidth
              size={'small'}
              helperText={errors.type_code && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'customer.taxes'}
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เลขที่ผู้เสียภาษี"
              fullWidth
              size={'small'}
              helperText={errors.taxes && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'customer.phone_number'}
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เบอร์โทรศัพท์"
              fullWidth
              size={'small'}
              helperText={errors.phone_number && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>{' '}
      <div className="w-full px-1 py-1">
        <Controller
          name={'customer.address'}
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ที่อยู่"
              rows={3}
              multiline={true}
              fullWidth
              size={'small'}
              helperText={errors.address && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
    </div>
  );

  return (
    <div>
      <Card className="my-1">
        <CardContent>
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 py-1 px-1">
              <Controller
                control={control}
                name="start_date"
                defaultValue={defaultValue?.start_date}
                render={({ field }) => (
                  <DesktopDatepicker
                    {...field}
                    fullWidth
                    label="วันที่สร้างใบเบิกใช้"
                    size="small"
                  />
                )}
              />
            </div>
            <div className="w-full md:w-1/2 py-1 px-1">
              <Controller
                control={control}
                name="expected_date"
                defaultValue={defaultValue?.expected_date}
                render={({ field }) => (
                  <DesktopDatepicker
                    {...field}
                    fullWidth
                    label="วันเกำหนดส่งงาน"
                    size="small"
                  />
                )}
              />
            </div>{' '}
            <div className="w-full md:w-1/2 py-1 px-1">
              {!_.isEmpty(employee?.rows) && (
                <Controller
                  control={control}
                  name="creator"
                  defaultValue={defaultValue?.creator}
                  render={({ field }) => (
                    <EmployeeAutoComplete
                      employeeRows={employee?.rows}
                      label="ผู้สร้างใบเบิกใช้"
                      field={field}
                      defaultValue={defaultValue?.sales}
                    />
                  )}
                />
              )}
            </div>
            <div className="w-full md:w-1/2 py-1 px-1">
              {watch('createNewCustomer')
                ? renderCreateNewCustomer()
                : renderAttachCustomer()}
              <Controller
                name="createNewCustomer"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    label="สร้างลูกค้าใหม่"
                    control={<Checkbox {...field} checked={field?.value} />}
                  />
                )}
              />
            </div>
            <div className="w-full md:w-1/2 py-1 px-1">
              <div className="my-1">
                <Controller
                  control={control}
                  name="remark"
                  defaultValue={defaultValue?.remark}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size="small"
                      label="หมายเหตุ"
                      multiline
                      rows={3}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default InternalDepositForm;

InternalDepositForm.propTypes = {
  employee: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  control: PropTypes.object,
  defaultValue: PropTypes.object,
  watch: PropTypes.func,
  customer: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  setReload: PropTypes.func,
  reload: PropTypes.bool,
  customerType: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  errors: PropTypes.object,
};
