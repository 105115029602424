import { gql } from 'graphql-request';

export const findMainDetailProcess = gql`
  query FindMainDetailProcess($processInput: ProcessInput) {
    findOneProcess(input: $processInput) {
      _id
    }
  }
`;

export default { findMainDetailProcess };
