/* eslint-disable arrow-body-style */
import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Autocomplete,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';

import { DesktopDatepicker } from '../../DatePicker';

const PlaceLaborCostForm = ({ place, watch, control, defaultValue }) => {
  return (
    <div>
      <Card>
        <CardContent>
          <div className="flex flex-wrap">
            <div className="w-full">
              <Controller
                control={control}
                name="places"
                defaultValue={defaultValue?.places || [place[0]]}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    options={place?.rows}
                    size="small"
                    multiple
                    placeholder="คลังวัตถุดิบที่ค่าแรงนี้มีผล"
                    onChange={(e, newValue) => {
                      field.onChange(newValue);
                    }}
                    className="z-40"
                    // prettier-ignore
                    getOptionLabel={(option) => `${option?.name || ''}`
                    }
                    isOptionEqualToValue={(option, value) =>
                      option?._id === value?._id
                    }
                    renderInput={(params) => (
                      <TextField
                        label="คลังวัตถุดิบที่ค่าแรงนี้มีผล"
                        {...params}
                      />
                    )}
                  />
                )}
              />
            </div>
            <div className="w-full my-1">
              <Controller
                control={control}
                name="is_daily"
                defaultValue={defaultValue ? defaultValue?.is_daily : true}
                render={({ field }) => (
                  <div className="flex items-center gap-2">
                    <div>รายช่วงเวลา</div>
                    <FormControlLabel
                      control={
                        <Switch {...field} defaultChecked={field.value} />
                      }
                      label="รายวัน"
                    />
                  </div>
                )}
              />
            </div>
            {watch('is_daily') && (
              <div className="w-full my-1">
                <Controller
                  control={control}
                  name="start_date"
                  defaultValue={defaultValue?.start_date}
                  render={({ field }) => (
                    <DesktopDatepicker
                      fullWidth
                      {...field}
                      label="วันที่"
                      size="small"
                    />
                  )}
                />
              </div>
            )}
            {!watch('is_daily') && (
              <div className="w-full my-1 md:w-1/2">
                <Controller
                  control={control}
                  name="start_date"
                  defaultValue={defaultValue?.start_date}
                  render={({ field }) => (
                    <DesktopDatepicker
                      fullWidth
                      {...field}
                      label="วันที่เริ่มต้น"
                      size="small"
                    />
                  )}
                />
              </div>
            )}
            {!watch('is_daily') && (
              <div className="w-full my-1 md:w-1/2">
                <Controller
                  control={control}
                  name="end_date"
                  defaultValue={defaultValue?.end_date}
                  render={({ field }) => (
                    <DesktopDatepicker
                      fullWidth
                      {...field}
                      label="วันที่สิ้นสุด"
                      size="small"
                    />
                  )}
                />
              </div>
            )}
            <div className="w-full my-1">
              <Controller
                control={control}
                name="cost"
                defaultValue={defaultValue?.cost}
                render={({ field }) => (
                  <TextField fullWidth {...field} label="ค่าแรง" size="small" />
                )}
              />
            </div>
            {!watch('not_specify_amount_of_labor') && (
              <div className="w-full my-1">
                <Controller
                  control={control}
                  name="amount_of_labor"
                  defaultValue={defaultValue?.amount_of_labor}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      {...field}
                      label="จำนวนคน"
                      size="small"
                    />
                  )}
                />
              </div>
            )}
            {/** This must setting default value in edit page */}
            <div className="w-full my-1">
              <Controller
                control={control}
                name="not_specify_amount_of_labor"
                defaultValue={defaultValue?.not_specify_amount_of_labor}
                render={({ field }) => (
                  <div className="flex items-center gap-2">
                    <FormControlLabel
                      control={
                        <Checkbox {...field} defaultChecked={field.value} />
                      }
                      label="ไม่ระบุจำนวนคน"
                    />
                  </div>
                )}
              />
            </div>

            <div className="w-full my-1">
              <Controller
                control={control}
                name="remark"
                defaultValue={defaultValue?.remark}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    {...field}
                    multiline
                    rows={3}
                    label="หมายเหตุ"
                    size="small"
                  />
                )}
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default PlaceLaborCostForm;

PlaceLaborCostForm.propTypes = {
  place: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  watch: PropTypes.func,
  control: PropTypes.object,
  defaultValue: PropTypes.object,
};
