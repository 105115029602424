import React, { useEffect, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Card,
  CardContent,
  LinearProgress,
  TextField,
} from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';

import { config } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';
import { PRODUCT_STOCK_STATUS } from '@/utils/constants';

import Error from '../../Error/Error';
import { ProdOrMatQuantityInput } from '../../Inputs';
import Loading from '../../Loading/Loading';
import ProductStockLotWithOutProductNameTable from '../../Tables/IMS-WMS/ProductStockLotWithOutProductNameTable';
import MultiUnitProdOrMatBox from '../Rendering/MultiUnitProdOrMatBox';

import ProduceNewProductCard from './ProduceNewProductCard';

/**
 * @function ProductManuafcturingPlaning
 * @description Display a collections or a list of ProductManuafcturingPlaning from database
 */

export default function ProductManuafcturingPlaning() {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  const params = useParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [selectedSearchProduct, setSelectedSearchProduct] = useState(null);
  const [foundedProductStockLot, setFoundProductStockLot] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const [selectToProduce, setSelectToProduce] = useState(null);

  const { control, setValue, watch } = useForm();
  const query = gql`
    query FindProductManuafcturingPlaning($productInput: ProductInput) {
      findProducts(input: $productInput) {
        rows {
          _id
          name
          type_code
          use_unit_conversion
          unit
          conversion_from {
            unit_a
            unit_b
            conversion_factor
          }
          conversion_to {
            unit_b
            unit_a
            conversion_factor
          }
          unit_input {
            _id
            short_sign
            name
          }
          unit_output {
            _id
            short_sign
            name
          }
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        productInput: {
          page: 1,
          size: config.maxFetchSize,
          fetchStockLot: false,
        },
      });
      const productData = queryResult?.findProducts;
      dispatch(actions.productStateSet(productData));
    } catch (error) {
      dispatch(actions.productError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const findProductStockLot = async () => {
    try {
      const prodStockLotQuery = gql`
        query FindProductStockLot($productStockLotInput: ProductStockLotInput) {
          findProductStockLots(input: $productStockLotInput) {
            total
            rows {
              _id
              running_number
              lot_number
              warehouse {
                _id
                name
              }
              quantity
              amount
              createdAt
              inventory_date
              production_date
              expiration_date
            }
            quantity
            amount
          }
        }
      `;
      const queryResult = await graphqlClient.request(prodStockLotQuery, {
        productStockLotInput: {
          product: selectedSearchProduct._id,
          page,
          size,
          status: PRODUCT_STOCK_STATUS.IN_STOCK.status_code,
        },
      });
      const productData = queryResult?.findProductStockLots;
      setFoundProductStockLot(productData);
      setIsLoading(false);
      console.log('Founed Product Stock  Lot', productData);
    } catch (error) {
      alert(`ไม่สามารถหาล็อตของสินค้าชิ้นนี้ในคลังได้ ${error.message}`);
    }
  };

  useEffect(() => {
    if (selectedSearchProduct) {
      setIsLoading(true);
      findProductStockLot();
    } else {
      setIsLoading(false);
    }

    return () => {};
  }, [selectedSearchProduct]);

  const renderProductSummary = () => (
    <div>
      <Card>
        <CardContent>
          <div className="font-semibold font-display">รายละเอียดสินค้า</div>
          <div className="my-2">
            <span className="font-semibold">สินค้า :</span>{' '}
            {selectedSearchProduct?.type_code || ''}{' '}
            {selectedSearchProduct?.name || '-'}
          </div>
          <div className="flex gap-4 my-2">
            <div className="">
              <div className="font-semibold text-sm">คงเหลือทั้งหมด </div>
              <MultiUnitProdOrMatBox
                foundMaterial={selectedSearchProduct}
                quantity={foundedProductStockLot?.amount || 0}
              />
            </div>
            <div>
              <div className="text-sm font-semibold">ปริมาณที่ต้องการ</div>
              <MultiUnitProdOrMatBox
                foundMaterial={selectedSearchProduct}
                quantity={watch('quantity') || 0}
              />
            </div>
          </div>

          {parseFloat(watch('quantity')) < foundedProductStockLot?.amount ? (
            <div>
              <div className="font-semibold text-xl text-green-500">
                เพียงพอ
              </div>
            </div>
          ) : (
            <div>
              <div className="font-semibold text-xl text-red-500">
                ไม่เพียงพอ
              </div>
            </div>
          )}

          <div className="my-2">
            <span className="font-semibold">สินค้าในคลังสินค้า</span>
          </div>
          <div className="my-2">
            <Accordion>
              <AccordionSummary expandIcon={<ChevronDown />}>
                ล็อตสินค้าในคลัง
              </AccordionSummary>
              <AccordionDetails>
                <ProductStockLotWithOutProductNameTable
                  foundedProductStockLot={foundedProductStockLot}
                  page={page}
                  selectedProduct={selectedSearchProduct}
                  setPage={setPage}
                  setSize={setSize}
                  size={size}
                />
              </AccordionDetails>
            </Accordion>
          </div>
        </CardContent>
      </Card>
    </div>
  );

  if (product.isLoading) {
    return <Loading />;
  }

  if (!product.isLoading && product.isCompleted) {
    return (
      <div>
        <div>
          <Card>
            <CardContent>
              <div>เลือกสินค้า</div>
              {!_.isEmpty(product?.rows) && (
                <Autocomplete
                  disablePortal
                  options={product?.rows}
                  size="small"
                  placeholder="เลือกสินค้า"
                  onChange={(e, newValue) => {
                    setSelectedSearchProduct(newValue);
                  }}
                  className="z-40 my-2"
                  // prettier-ignore
                  getOptionLabel={(option) => `${option?.type_code || ''} ${option?.name || ''}`
          }
                  renderInput={(inputParams) => (
                    <TextField label="เลือกสินค้า" {...inputParams} />
                  )}
                />
              )}
              <div>
                <div className="my-2">ปริมาณที่ต้องการ</div>
                <ProdOrMatQuantityInput
                  control={control}
                  controllerName="quantity"
                  label="จำนวนที่ต้องการผลิต"
                  defaultValue={0}
                  artificialControllerName="quantity_artificial"
                  selectedUnitControllerName="selected_unit"
                  setValue={setValue}
                  watch={watch}
                  selectedProduct={selectedSearchProduct}
                />
              </div>
            </CardContent>
          </Card>
        </div>
        {loading && <LinearProgress />}
        {foundedProductStockLot && (
          <div className="my-2">
            {renderProductSummary()}
            <Card className="my-2">
              <CardContent>
                <div className="my-2 text-center">ต้องการที่จะ</div>
                <div className="flex justify-center gap-2">
                  <Button
                    variant="contained"
                    onClick={() => {
                      setSelectToProduce(!selectToProduce);
                    }}
                  >
                    ผลิตเพิ่ม
                  </Button>
                  <Link to={'/wms/product-stock/create/undefined'}>
                    <Button variant="contained" color="success">
                      เบิกสินค้าไปขาย
                    </Button>
                  </Link>
                </div>
              </CardContent>
            </Card>
            {selectToProduce && (
              <ProduceNewProductCard
                selectedProduct={selectedSearchProduct}
                amount={watch('quantity')}
              />
            )}
          </div>
        )}
      </div>
    );
  }
  return <Error />;
}
