import React from 'react';
import { Card, CardContent, Alert } from '@mui/material';
import PropTypes from 'prop-types';

import { currencyFormatter } from '../../../utils/functions';

const HeadTailLostAndMachineDetail = ({ lostHead, lostTail, machine }) => (
  <div>
    <Card>
      <CardContent>
        {(!lostHead || !lostTail) && (
          <div className="my-2">
            <Alert severity="warning">
              <div>
                ไม่พบการวัดค่าศูนย์เสียที่หัวและที่ท้าย
                กรุณาสร้างตัวแปรที่มีชื่อ CONCRETE_HEAD_LOST
                สำหรับเก็บส่วนศูนย์เสียที่หัว (หน่วยเป็นเมตร),
                CONCRETE_TAIL_LOST สำหรับเก็บส่วนศูนย์เสียที่หาง (หน่วยเป็นเมตร)
                และโปรดตรวจสอบว่าสองตัวแปรนี้ได้ถูกเรียกมาเก็บค่าในไลน์การผลิตนี้แล้ว
              </div>
            </Alert>
          </div>
        )}
        <div className="w-full flex flex-wrap">
          <div className="w-full my-1 lg:w-1/6 px-1 font-semibold">
            ศูนย์เสียที่หัว
          </div>
          <div className="w-full my-1 lg:w-2/6 px-1">
            {lostHead?.value
              ? currencyFormatter.format(parseFloat(lostHead?.value))
              : '-'}{' '}
            เมตร
          </div>
          <div className="w-full my-1 lg:w-1/6 px-1 font-semibold">
            ศูนย์เสียที่ท้าย
          </div>
          <div className="w-full my-1 lg:w-2/6 px-1">
            {' '}
            {lostTail?.value
              ? currencyFormatter.format(parseFloat(lostTail?.value))
              : '-'}{' '}
            เมตร
          </div>
          <div className="w-full my-1 lg:w-1/6 px-1 font-semibold">
            รางการผลิต
          </div>
          <div className="w-full my-1 lg:w-2/6 px-1">{machine?.name}</div>
          <div className="w-full my-1 lg:w-1/6 px-1 font-semibold">
            จำนวนรางคู่ขนาน
          </div>
          <div className="w-full my-1 lg:w-2/6 px-1">
            {machine?.conveyor_parallel_number} ราง
          </div>
          <div className="w-full my-1 lg:w-1/6 px-1 font-semibold">กว้าง</div>
          <div className="w-full my-1 lg:w-2/6 px-1">
            {currencyFormatter.format(machine?.conveyor_width)} เมตร
          </div>
          <div className="w-full my-1 lg:w-1/6 px-1 font-semibold">ยาว</div>
          <div className="w-full my-1 lg:w-2/6 px-1">
            {currencyFormatter.format(machine?.conveyor_length)} เมตร
          </div>
        </div>
      </CardContent>
    </Card>
  </div>
);

HeadTailLostAndMachineDetail.propTypes = {
  lostHead: PropTypes.object,
  lostTail: PropTypes.object,
  machine: PropTypes.object,
};

export default HeadTailLostAndMachineDetail;
