import React from 'react';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import PropTypes from 'prop-types';

import 'dayjs/locale/th';

dayjs.extend(buddhistEra);
dayjs.locale('th');

const ProductAsMaterialResultBox = ({ selectedProcess, information }) => {
  const { product_as_materials: productAsMaterials = [{}] } = selectedProcess;
  const { setting: { ims: imsSetting } = {} } = information;
  return (
    <div className="px-2">
      <div className="flex flex-wrap">
        {_.map(productAsMaterials, (_materialLot, index) => (
          <div className="w-full  my-2 p-1" key={index}>
            <div className="rounded-md border p-4 shadow-md">
              <h6 className="font-semibold font-display">
                {_materialLot?.material?.type_code}{' '}
                {_materialLot?.material?.name}{' '}
              </h6>
              <h6 className="">
                จำนวน {_materialLot?.quantity} {_materialLot?.material?.unit}
              </h6>

              {imsSetting?.display?.lot_number && (
                <div className="my-2">
                  <div className="text-sm font-semibold font-display">
                    หมายเลขล็อต
                  </div>
                  <div>{_materialLot?.lot_number || '-'}</div>
                </div>
              )}

              <div className="my-2">
                <div className="text-sm font-semibold font-display">
                  คลังวัตถุดิบ
                </div>
                <div>{_materialLot?.place?.name}</div>
              </div>

              <div className="flex ">
                {imsSetting?.display?.production_date && (
                  <div className="my-2 w-1/2">
                    <div className="text-sm font-semibold font-display">
                      วันผลิต
                    </div>
                    <div>
                      {dayjs(_materialLot?.production_date).format(
                        'D MMM BBBB',
                      )}
                    </div>
                  </div>
                )}
                {imsSetting?.display?.expiration_date && (
                  <div className="my-2 w-1/2">
                    <div className="text-sm font-semibold font-display">
                      วันหมดอายุ
                    </div>
                    <div>
                      {dayjs(_materialLot?.expiration_date).format(
                        'D MMM BBBB',
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

ProductAsMaterialResultBox.propTypes = {
  selectedProcess: PropTypes.object,
  information: PropTypes.object,
};

export default ProductAsMaterialResultBox;
