export const ORDER_STATUS = {
  ORDER_CREATE: {
    status_code: 'ORDER_CREATE',
    description: 'รายการสั่งซื้อใหม่',
  },
  ORDER_PENDING: {
    status_code: 'ORDER_PENDING',
    description: 'กำลังจัดเตรียมสินค้า',
  },
  ORDER_PACK: {
    status_code: 'ORDER_PACK',
    description: 'เตรียมสินค้าเรียบร้อย รอการจัดส่ง',
  },
  ORDER_SUCCESS: {
    status_code: 'ORDER_SUCCESS',
    description: 'สำเร็จ',
  },
  ORDER_CANCEL: {
    status_code: 'ORDER_CANCEL',
    description: 'ยกเลิก',
  },
};
export default ORDER_STATUS;
