import React from 'react';
import { Controller } from 'react-hook-form';
import { Switch, TextField } from '@mui/material';
import PropTypes from 'prop-types';

import { DesktopDatepicker } from '../../DatePicker';

export default function PayrollForm({ control, employee }) {
  return (
    <div className="flex flex-wrap">
      <div className="w-full font-semibold">ประเภทการรับเงินรายได้</div>
      <div className="w-full py-1">
        <Controller
          name="earnings.is_pay_monthy"
          defaultValue={employee?.earnings?.is_pay_monthy}
          control={control}
          render={({ field }) => (
            <div className="flex gap-2 items-center">
              <div>รายวัน</div>
              <Switch {...field} defaultChecked={field.value} />
              <div>รายเดือน</div>
            </div>
          )}
        />
      </div>
      <div className="w-full my-2 font-semibold">
        รายได้ต่อวัน หรือ รายได้ต่อเดือน
      </div>
      <div className="w-full py-1">
        <Controller
          name="earnings.amount"
          defaultValue={employee?.earnings?.amount}
          control={control}
          render={({ field }) => (
            <TextField {...field} label="รายได้" fullWidth size="small" />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 py-2 pr-1">
        <Controller
          name="earnings.hour_per_day"
          defaultValue={employee?.earnings?.hour_per_day}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="จำนวนชั่วโมงการทำงาน / วัน"
              fullWidth
              size="small"
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 py-2 pl-1">
        <Controller
          name="earnings.day_per_month"
          defaultValue={employee?.earnings?.day_per_month}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="จำนวนวันการทำงานเฉลี่ย / เดือน"
              fullWidth
              size="small"
            />
          )}
        />
      </div>

      <div className="w-full my-2 font-semibold">วันที่มีการปรับเงินรายได้</div>
      <div className="w-full py-1">
        <Controller
          name="earnings.date"
          defaultValue={new Date()}
          control={control}
          render={({ field }) => (
            <DesktopDatepicker
              {...field}
              size="small"
              label="วันที่ปรับเงินรายได้"
            />
          )}
        />
      </div>
    </div>
  );
}

PayrollForm.propTypes = {
  control: PropTypes.object,
  employee: PropTypes.object,
};
