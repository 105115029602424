import {
  MANUFACTURING_ORDER_LOG_ALL,
  MANUFACTURING_ORDER_LOG_ERROR,
  MANUFACTURING_ORDER_LOG_GET,
  MANUFACTURING_ORDER_LOG_LOADING,
} from '../../actions/types';

const initialState = {
  manufacturingOrder: null,
  isLoading: true,
  isCompleted: true,
};

export default function ManufacturingOrderLogReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case MANUFACTURING_ORDER_LOG_LOADING:
      return { isLoading: true, isCompleted: true };
    case MANUFACTURING_ORDER_LOG_ERROR:
      return { isLoading: false, isCompleted: false, ...action.payload };
    case MANUFACTURING_ORDER_LOG_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MANUFACTURING_ORDER_LOG_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    default:
      return state;
  }
}
