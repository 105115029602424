import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { plugins } from '@/configs';
import { menuList } from '@/utils/constants';
import { accessRight } from '@/utils/functions';

const ButtonMenuList = ({ me, availableModule }) => {
  const information = useSelector((state) => state.information);
  const activatedPlugins = _.filter(
    plugins,
    (plugin) => information?.plugins?.[plugin.id] === true,
  );

  const newMenuList = [
    ...menuList,
    ..._.map(activatedPlugins, (plugin) => ({
      title: plugin.information.title,
      extendedStyle: plugin.information.color,
      icon: <i className={`${plugin.information.icon} text-4xl text-white`} />,
      link: `/plugin/${plugin.id}`,
      moduleName: 'PLUGIN',
    })),
  ];

  console.log('New Menu List', newMenuList);
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 print-disable ">
      {_.map(newMenuList, (_menu, index) => {
        if (accessRight(me, _menu.moduleName, 0, availableModule)) {
          return (
            <div className="my-4  px-4" key={index}>
              <Link to={_menu.link}>
                <div
                  className={`  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105 ${_menu.extendedStyle}`}
                >
                  <div className="flex justify-center">{_menu.icon}</div>
                  <p className=" text-white mt-4 font-display">{_menu.title}</p>
                </div>
              </Link>
            </div>
          );
        }
        return <></>;
      })}
    </div>
  );
};

export default ButtonMenuList;

ButtonMenuList.propTypes = {
  me: PropTypes.object,
  availableModule: PropTypes.object,
};
