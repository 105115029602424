import React from 'react';

function CapitalCostTDABC({
  enableElement,
  previousLotList,
  currentLot,
  isCurrentAsProductLot,
  useTDABCArtifact,
}) {
  return <div>CapitalCostTDABC</div>;
}

export default CapitalCostTDABC;
