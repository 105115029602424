const DAYS = {
  MONDAY: {
    status_code: "MONDAY",
    description: "วันจันทร์",
    lowercase: "monday",
    dayOfWeek: 1,
  },
  TUESDAY: {
    status_code: "TUESDAY",
    description: "วันอังคาร",
    lowercase: "tuesday",
    dayOfWeek: 2,
  },
  WEDNESDAY: {
    status_code: "WEDNESDAY",
    description: "วันพุธ",
    lowercase: "wednesday",
    dayOfWeek: 3,
  },
  THURSDAY: {
    status_code: "THURSDAY",
    description: "วันพฤหัสบดี",
    lowercase: "thursday",
    dayOfWeek: 4,
  },
  FRIDAY: {
    status_code: "FRIDAY",
    description: "วันศุกร์",
    lowercase: "friday",
    dayOfWeek: 5,
  },
  SATURDAY: {
    status_code: "SATURDAY",
    description: "วันเสาร์",
    lowercase: "saturday",
    dayOfWeek: 6,
  },
  SUNDAY: {
    status_code: "SUNDAY",
    description: "วันอาทิตย์",
    lowercase: "sunday",
    dayOfWeek: 7,
  },
};

module.exports = DAYS;
