import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';
import * as CONSTANT from '@/utils/constants';

import { Loading } from '../../Loading';

const HandleProductForm = ({ process, handleSuccess = () => {} }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      product: [
        {
          product: process?.product?._id,
          quantity: process?.quantity,
          cost_price: 0,
          production_date: dayjs().format('YYYY-MM-DD'),
          expiration_date: dayjs().format('YYYY-MM-DD'),
        },
      ],
      production_number: process?.manufacturing_order?._id,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'product',
  });

  const [isAddToWarehouseDialogOpen, setIsAddToWarehouseDialogOpen] = useState(
    false,
  );

  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const warehouse = useSelector((state) => state.warehouse);
  const product = useSelector((state) => state.product);
  const productTransactionType = useSelector(
    (state) => state.productTransactionType,
  );

  const handleProductAdd = async (data) => {
    const payload = {
      ...data,
      consignee_name: `${me?.userData?.firstname} ${me?.userData?.lastname}`,
      product: _.map(data.product, (_product) => ({
        ..._product,
        amount: _product?.quantity,
      })),
      status: CONSTANT.PRODUCT_STOCK_STATUS.IN_STOCK.status_code,
      remark: '',
      inventory_data: new Date(),
    };
    console.log('Add Product Payload', payload);
    try {
      await dispatch(actions.productStockLotCreate(payload));
      await dispatch(actions.processPut(process?._id, { completed: true }));
      window.alert('เพิ่มสินค้าลงในคลังเสร็จสิ้น');

      await dispatch(actions.processGet(process?._id));
      handleSuccess();
    } catch (error) {
      window.alert('ไม่สามารถเพิ่มสินค้าลงในคลังสินค้าได้');
      console.log('Product Stock Lot Add Errors', error);
    }
  };

  const handleToPurchase = async () => {
    try {
      await dispatch(actions.processPut(process?._id, { completed: true }));

      window.alert('สินค้าอยู่ในสถานะพร้อมจำหน่ายแล้ว การผลิตเสร็จสิ้น');
      await dispatch(actions.processGet(process?._id));
      handleSuccess();
    } catch (error) {
      window.alert('ไม่สามารถเปลี่ยนสถานะสินค้าเป็นพร้อมจำหน่ายได้');
      console.log(
        'Process change to complete and handle purchase error',
        error,
      );
    }
  };

  useEffect(() => {
    const fetchWarehouse = async () => {
      try {
        await dispatch(actions.warehouseAll({}));
      } catch (error) {
        console.error('Warehouse Fetch Error', error);
      }
    };

    const fetchProductTxnType = async () => {
      try {
        await dispatch(actions.productTransactionTypeAll({}));
      } catch (error) {
        console.error('Fetch Product TxnType Error', error);
      }
    };

    const fetchProduct = async () => {
      try {
        await dispatch(actions.productAll({}));
      } catch (error) {
        console.error('Fetch All Product Error', error);
      }
    };

    fetchWarehouse();
    fetchProductTxnType();
    fetchProduct();
    return () => {};
  }, []);

  const renderAddToWarehouseDialog = () => (
    <Dialog
      open={isAddToWarehouseDialogOpen}
      onClose={() => setIsAddToWarehouseDialogOpen(false)}
    >
      <form>
        <DialogTitle>เพิ่มสินค้าลงในคลังสินค้า</DialogTitle>
        <DialogContent>
          <div className="w-full py-2 my-1">
            <Controller
              name="product_transaction_type"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <FormControl fullWidth required size="small">
                  <InputLabel>ประเภทการดำเนินการ</InputLabel>
                  <Select {...field} label="ประเภทการดำเนินการ">
                    {_.map(productTransactionType?.rows, (_txnType, index) => {
                      if (
                        _txnType?.direction ===
                        CONSTANT.PRODUCT_TRANSACTION_JSON_TYPE.add.direction
                      ) {
                        return (
                          <MenuItem key={index} value={_txnType?._id}>
                            {_txnType?.name}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                  <FormHelperText>
                    {errors?.product_transaction_type &&
                      'กรุณาเลือกประเภทการดำเนินการ'}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </div>
          <div className="w-full py-2 my-1">
            <Controller
              name="warehouse"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <FormControl fullWidth required size="small">
                  <InputLabel>คลังสินค้า</InputLabel>
                  <Select {...field} label="คลังสินค้า">
                    {_.map(warehouse?.rows, (_warehouse, index) => (
                      <MenuItem key={index} value={_warehouse?._id}>
                        {_warehouse?.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors?.warehouse && 'กรุณาเลือกคลังสินค้า'}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </div>
          <div className="overflow-x-auto">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ลำดับที่</TableCell>
                  <TableCell>สินค้า</TableCell>
                  <TableCell>
                    <div className="whitespace-nowrap">ราคาทุน</div>{' '}
                  </TableCell>
                  <TableCell>วันที่ผลิต</TableCell>
                  <TableCell>วันหมดอายุ </TableCell>
                  <TableCell>จำนวน </TableCell>
                  <TableCell>ดำเนินการ </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_.map(fields, (_field, index) => (
                  <TableRow key={_field?.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <Controller
                        name={`product[${index}].product`}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <FormControl fullWidth>
                            <InputLabel>สินค้า</InputLabel>
                            <Select
                              {...field}
                              defaultValue=""
                              label="สินค้า"
                              size="small"
                            >
                              {_.map(product?.rows, (_product, prodIndex) => (
                                <MenuItem key={prodIndex} value={_product?._id}>
                                  {_product?.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>
                              {' '}
                              {errors?.product?.[index]?.product &&
                                'กรุณาเลือกสินค้า'}{' '}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <div className="w-24">
                        <Controller
                          name={`product[${index}].cost_price`}
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="number"
                              fullWidth
                              size={'small'}
                              helperText={
                                errors?.product?.[index]?.price &&
                                'กรุณาใส่ข้อมูล '
                              }
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name={`product[${index}].production_date`}
                        control={control}
                        defaultValue={new Date()}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            type="date"
                            fullWidth
                            size={'small'}
                            helperText={
                              errors?.product?.[index]?.production_date &&
                              'กรุณาใส่ข้อมูล'
                            }
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <Controller
                        name={`product[${index}].expiration_date`}
                        control={control}
                        defaultValue={new Date()}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            type="date"
                            fullWidth
                            size={'small'}
                            helperText={
                              errors?.product?.[index]?.expiration_date &&
                              'กรุณาใส่ข้อมูล'
                            }
                          />
                        )}
                      />{' '}
                    </TableCell>
                    <TableCell>
                      <div className="w-24">
                        <Controller
                          name={`product[${index}].quantity`}
                          contprol={control}
                          rules={{ required: true, min: 1 }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="number"
                              fullWidth
                              size={'small'}
                              helperText={
                                errors?.product?.[index]?.quantity &&
                                'กรุณาใส่ข้อมูล และข้อมูลขั้นต่ำที่ได้คือ 1 ชิ้น'
                              }
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        variant="contained"
                        color="error"
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        ลบ{' '}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}{' '}
                <TableRow>
                  <TableCell>
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        append({
                          product: null,
                          quantity: 0,
                          cost_price: 0,
                          production_date: dayjs().format('YYYY-MM-DD'),
                          expiration_date: dayjs().format('YYYY-MM-DD'),
                        });
                      }}
                    >
                      เพิ่ม{' '}
                    </Button>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell> </TableCell>
                  <TableCell></TableCell>
                  <TableCell> </TableCell>
                  <TableCell> </TableCell>
                  <TableCell> </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              type="button"
              onClick={handleSubmit(handleProductAdd)}
            >
              ยืนยัน
            </Button>
            <Button
              color="inherit"
              variant="contained"
              type="button"
              onClick={() => setIsAddToWarehouseDialogOpen(false)}
            >
              ยกเลิก
            </Button>
          </DialogActions>{' '}
        </DialogContent>
      </form>
    </Dialog>
  );

  if (product?.isLoading) {
    return <Loading />;
  }
  if (!product?.isLoading && product?.isCompleted) {
    return (
      <div>
        {renderAddToWarehouseDialog()}
        <div className="flex gap-2 w-full justify-center">
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={() => {
              setIsAddToWarehouseDialogOpen(true);
            }}
          >
            เพิ่มลงในคลังสินค้า
          </Button>
          <Button
            variant="contained"
            color="secondary"
            type="button"
            onClick={() => handleToPurchase()}
          >
            รอจำหน่าย
          </Button>
        </div>
      </div>
    );
  }

  return <div></div>;
};

export default HandleProductForm;

HandleProductForm.propTypes = {
  process: PropTypes.object,
  handleSuccess: PropTypes.func,
};
