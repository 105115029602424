import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { gql } from 'graphql-request';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import dayjs from 'dayjs';

import {
  ViewTitle,
  Loading,
  Error,
  BackButton,
  MultiPayrollForm,
} from '@iarcpsu/emanufac-components/src/components';
import * as actions from '../../redux/actions';
import graphqlClient from '../../configs/graphqlClient';

/**
 * @function EditPayroll
 * @description Display a collections or a list of EditPayroll from database
 */

export default function EditPayroll({ title, subtitle }) {
  const dispatch = useDispatch();
  const payroll = useSelector((state) => state.payroll);
  const information = useSelector((state) => state.information);
  const history = useHistory();
  const params = useParams();

  const {
    setting: { pams: pamsSetting },
  } = information;

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: payroll });

  const query = gql`
    query FindSettingEditProductBom($payrollInput: PayrollInput) {
      findOnePayroll(input: $payrollInput) {
        _id
        payroll_type
        employee {
          _id
          firstname
          lastname
          earnings {
            is_pay_monthy
            amount
          }
        }
        date
        overtimes {
          start_time
          end_time
          cost
        }
        total_overtime
        daily_attribute {
          start_time
          end_time
        }
        main_cost
        total_cost
        remark
      }
    }
  `;

  const queryDataFromServer = async () => {
    const queryResult = await graphqlClient.request(query, {
      payrollInput: { id: params.id },
    });
    const payrollData = queryResult?.findOnePayroll;
    dispatch(actions.payrollStateOneSet(payrollData));
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const calculateManHour = (
    start,
    finish,
    { brushOfThreshold, lateThreshold },
  ) => {
    const manMin = dayjs(finish).diff(dayjs(start), 'minute');
    const manHour = dayjs(finish).diff(dayjs(start), 'hour');

    // ลงตัวเป็นชั่วโมง  หรือ ทำงานไม่ถึง 20 นาที (สายเกิน 40 นาที) ถือว่าไม่ได้ทำงาน ปัดทิ้ง
    if (manMin % 60 < 60 - brushOfThreshold) {
      return manHour;
    }

    if (manMin % 60 < 60 - lateThreshold) {
      return manHour + 0.5; // ทำงานสาย / คิดเรทครั่งชั่วโมง
    }

    return manHour + 1; // น้อยกว่าเรทสาย ถือว่าไม่สาย
  };

  const changeMainCost = (start, finish, costPerHour, basedControllerName) => {
    const manHour = calculateManHour(start, finish, {
      brushOfThreshold: pamsSetting?.daily_payroll?.brushoff_threshold,
      lateThreshold: pamsSetting?.daily_payroll?.late_threshold,
    });
    const cost = costPerHour * manHour; // Main Cost
    setValue(`${basedControllerName}.main_cost`, cost);

    const totalCost =
      cost + parseFloat(watch(`${basedControllerName}.overtime.cost`)) || 0; // Cost with OT
    setValue(`${basedControllerName}.total_cost`, totalCost);
  };

  const calculateAllOTCost = () => {
    const otList = _.map(payroll?.overtimes, (otItem) => otItem?.cost);

    return _.sum(otList);
  };

  const changeOTCost = (basedControllerName) => {
    const totalCost =
      (parseFloat(watch(`${basedControllerName}.main_cost`)) || 0) +
      calculateAllOTCost(); // Cost with OT

    // console.log('Total Cost', totalCost);
    setValue(`${basedControllerName}.total_cost`, totalCost);
  };

  useEffect(() => {
    if (payroll && !payroll.rows) {
      setValue('arr', [
        {
          ...payroll,
          enable: true,
        },
      ]);
      const costPerHour =
        payroll?.employee?.earnings?.amount /
        pamsSetting?.daily_payroll?.working_hour;
      changeMainCost(
        payroll?.daily_attribute?.start_time,
        payroll?.daily_attribute?.end_time,
        costPerHour,
        'arr[0]',
      );
      changeOTCost('arr[0]');
    }

    return () => {};
  }, [payroll]);

  const handleEditPayroll = async (data) => {
    try {
      const selectedPayroll = data?.arr?.[0];
      console.log('data', data);
      await dispatch(actions.payrollPut(selectedPayroll?._id, selectedPayroll));
      alert('สำเร็จ');
      history.goBack();
    } catch (err) {
      console.error(err);
      alert(`ไม่สามารถแก้ไขเงินเดือนได้ ${err?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (payroll.isLoading) {
    return <Loading />;
  }

  if (!payroll.isLoading && payroll.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div>
          <BackButton />
        </div>
        <div className="my-2">
          <Card>
            <CardContent>
              <form onSubmit={handleSubmit(handleEditPayroll)}>
                <MultiPayrollForm
                  control={control}
                  errors={errors}
                  isDaily
                  watch={watch}
                  setValue={setValue}
                  selectedDate={payroll?.date}
                  hourPerDay={pamsSetting?.daily_payroll?.working_hour}
                  breakPerDay={pamsSetting?.daily_payroll?.break_hour}
                  brushOfThreshold={
                    pamsSetting?.daily_payroll?.brushoff_threshold
                  }
                  lateThreshold={pamsSetting?.daily_payroll?.late_threshold}
                />
                <div className="flex justify-end my-2">
                  <Button type="submit" variant="contained">
                    บันทึก
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditPayroll.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditPayroll.defaultProps = {
  title: '',
  subtitle: '',
};
