import DashboardEmployeeSelect from './DashboardEmployeeSelect';
import DetailReportMaterialUsage from './DetailReportMaterialUsage';
import EmployeeDashboard from './EmployeeDashboard';
import ManufacturingDashboard from './ManufacturingDashboard';
import ManufacturingOrderCalendar from './ManufacturingOrderCalendar';
import OnMonthReportManufacturingOrder from './OnMonthReportManufacturingOrder';
import ReportAllBom from './ReportAllBom';
import ReportManufacturingOrder from './ReportManufacturingOrder';
import ReportMaterialUsage from './ReportMaterialUsage';

export {
  DashboardEmployeeSelect,
  DetailReportMaterialUsage,
  EmployeeDashboard,
  ManufacturingDashboard,
  ManufacturingOrderCalendar,
  OnMonthReportManufacturingOrder,
  ReportAllBom,
  ReportManufacturingOrder,
  ReportMaterialUsage,
};

export default {
  DetailReportMaterialUsage,
  ReportManufacturingOrder,
  ReportMaterialUsage,
  OnMonthReportManufacturingOrder,
  ReportAllBom,
  ManufacturingDashboard,
  DashboardEmployeeSelect,
  EmployeeDashboard,
  ManufacturingOrderCalendar,
};
