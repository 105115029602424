import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  BackButton,
  DaySpotAndRangePicker,
  Error,
  ExportExcelContainer,
  Loading,
  MeasurementDataTable,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { MeasurementColumns } from '@iarcpsu/emanufac-utils/columns';
import { Card, CardContent, TextField } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { config } from '@/configs';

import * as actions from '../../../redux/actions';

/**
 * @function DetailMeasuredData
 * @description Display a collections or a list of Measurement Data
 * getting Measurement Type from Params
 *  ดึง Measurement Type (variable type) จากนห้าที่แล้วมาใส่ไว้ใน Params
 */

export default function DetailMeasuredData({ title, subtitle }) {
  const dispatch = useDispatch();
  const measurement = useSelector((state) => state.measurement);
  const measurementType = useSelector((state) => state.measurementType);
  const { id: measurementTypeId } = useParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [name, setName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.measurementTypeGet(measurementTypeId));
      dispatch(
        actions.measurementAll({
          page,
          size,
          measurementType: measurementTypeId,
          startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
          endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : '',
          name,
        }),
      );
    } catch (error) {
      console.error('Query Data Error', error);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, measurementTypeId, startDate, endDate, name]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    if (measurement?.rows) {
      setTotal(measurement?.total);
    }

    return () => {};
  }, [measurement]);

  const measurementDataTableFuncton = {
    measurement,
    page,
    size,
    total,
    handleChangePage: (event, currPage) => {
      setPage(currPage + 1);
    },
    handleChangeRowPerPage: (event) => {
      setSize(event.target.value);
      setPage(1);
    },
  };

  const apiQuery = {
    page: 1,
    size: config.maxFetchSize,
    measurementType: measurementTypeId,
    startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
    endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : '',
    name: '',
  };

  const renderTitle = () => (
    <ViewTitle title={`${title}${measurementType?.name}`} subtitle={subtitle} />
  );

  if (measurement.isLoading) {
    return <Loading />;
  }

  if (!measurement.isLoading && measurement.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className=" flex">
          <BackButton />
        </div>
        <Card className="my-2">
          <CardContent>
            <div className="flex flex-wrap justify-between items-center">
              <div className="w-full md:w-1/2">
                <TextField
                  value={searchTerm}
                  size="small"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  label="ค้นหาตามคำสั่งผลิต"
                  fullWidth
                />
              </div>
              <div className="w-full md:w-1/5">
                <ExportExcelContainer
                  columnList={MeasurementColumns(measurementType)}
                  currentData={measurement?.rows}
                  dataAPIEndPoint="measurement"
                  dataQuery={apiQuery}
                  sheetName={`Measurement-${measurementType?.name}`}
                />
              </div>
            </div>
          </CardContent>
        </Card>
        <Card className="my-2">
          <CardContent>
            <div className="flex flex-wrap">
              <div className="w-full ">
                <DaySpotAndRangePicker
                  enable
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  flex
                />
              </div>
            </div>
          </CardContent>
        </Card>

        <div className="my-2">
          <MeasurementDataTable {...measurementDataTableFuncton} />
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailMeasuredData.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailMeasuredData.defaultProps = {
  title: '',
  subtitle: '',
};
