export const WORK_SHIFT = {
  SHIFT_1: {
    status_code: 'SHIFT_1',
    description: 'กะ 1',
  },
  SHIFT_2: {
    status_code: 'SHIFT_2',
    description: 'กะ 2',
  },
  SHIFT_3: {
    status_code: 'SHIFT_3',
    description: 'กะ 3',
  },
};

export default WORK_SHIFT;
