import {
  COMMENT_ALL,
  COMMENT_POST,
  COMMENT_GET,
  COMMENT_PUT,
  COMMENT_DELETE,
  COMMENT_ERROR,
  COMMENT_LOADING,
  COMMENT_RESET,
} from '../types';

import api from '../../../configs/api';

export const commentCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: COMMENT_LOADING });
    if (payload?.file) {
      // Upload File First
      const formData = new FormData();
      formData.append('file', payload.file);
      const { data: uploadData } = await api.post(
        `${process.env.REACT_APP_API_URL}/upload/file`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );
      console.log('Upload Data', uploadData);
      // Then Create Comment
      const { data } = await api.post(
        `${process.env.REACT_APP_API_URL}/comment`,
        {
          ...payload,
          file: uploadData?.id,
        },
      );

      dispatch({ type: COMMENT_POST, payload: data });
    } else {
      const { data } = await api.post(
        `${process.env.REACT_APP_API_URL}/comment`,
        payload,
      );

      dispatch({ type: COMMENT_POST, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: COMMENT_ERROR });
    throw new Error(error);
  }
};

export const commentAll = (params) => async (dispatch) => {
  try {
    const { processId = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/comment?processId=${processId}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: COMMENT_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: COMMENT_ERROR });
    throw new Error(error);
  }
};

export const commentGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/comment/${id}`,
    );
    if (status === 200) {
      dispatch({ type: COMMENT_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: COMMENT_ERROR });
    throw new Error(error);
  }
};

export const commentStateSet = (data) => async (dispatch) => {
  dispatch({ type: COMMENT_ALL, payload: data });
};

export const commentPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: COMMENT_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/comment/${id}`,
      payload,
    );
    dispatch({ type: COMMENT_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: COMMENT_ERROR });
    throw new Error(error);
  }
};

export const commentDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: COMMENT_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/comment/${id}`,
    );
    dispatch({ type: COMMENT_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: COMMENT_ERROR });
    throw new Error(error);
  }
};

export const commentReset = () => async (dispatch) => {
  try {
    dispatch({ type: COMMENT_RESET });
  } catch (error) {
    console.error(error);
    dispatch({ type: COMMENT_RESET });
    throw new Error(error);
  }
};
