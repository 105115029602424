import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { FormControlLabel, Switch } from '@mui/material';

const SwitchInput = ({
  control,
  measurementType,
  measurementControllerName = '',
}) => (
  <div>
    <div>
      <div>{measurementType?.label}</div>
      <div className="flex gap-2">
        <div className="self-center">
          <div>{measurementType?.label_right}</div>
        </div>
        <div className="self-center">
          <Controller
            control={control}
            name={`${measurementControllerName}.boolean_value`}
            render={({ field }) => (
              <FormControlLabel
                control={<Switch {...field} />}
                label={measurementType?.label_right}
              />
            )}
          />
        </div>
      </div>
    </div>
  </div>
);

SwitchInput.propTypes = {
  control: PropTypes.object,
  measurementType: PropTypes.object,
  measurementControllerName: PropTypes.string,
};

export default SwitchInput;
