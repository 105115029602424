const columns = [
  {
    label: 'ลำดับที่',
    value: 'index',
  },
  {
    label: 'รหัสวัตถุดิบ',
    value: 'material_typecode',
  },
  {
    label: 'ขื่อวัตถุดิบ',
    value: 'material_name',
  },
  {
    label: 'เบิกวัตถุดิบ',
    value: 'request',
  },
  {
    label: 'คืนวัตถุดิบ',
    value: 'return',
  },
  {
    label: 'สูญเสีย',
    value: 'waste',
  },
  {
    label: 'หน่วย',
    value: 'unit',
  },
  {
    label: 'เบิกวัตถุดิบ',
    value: 'request_second_unit',
  },
  {
    label: 'คืนวัตถุดิบ',
    value: 'return_second_unit',
  },
  {
    label: 'สูญเสีย',
    value: 'waste_second_unit',
  },
  {
    label: 'หน่วยที่สอง',
    value: 'unit_second',
  },
];

export default { columns };
