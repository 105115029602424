import Dashboard from './CustomDashboard/Dashboard';
import DetailMeasuredData from './MeasurementVariable/DetailMeasuredData';
import MeasuredData from './MeasurementVariable/MeasuredData';
import DetailMaterialOnProcess from './Process/DetailMaterialOnProcess';
import EditMaterialUsageTransaction from './Process/EditMaterialUsageTransaction';
import DetailProductProcess from './Setting/SettingDetailProductProcess';
import EditProductProcess from './Setting/SettingEditProductProcess';
import CustomView from './custom';
import CustomDashboardView from './CustomDashboard';
import ManufacturingOrderViews from './ManufacturingOrder';
import ProcessView from './Process';
import ReportView from './Report';
import SettingView from './Setting';
import WorkView from './Work';

/**
 * @namespace MMS
 * @description Manufacturing Management System - การผลิต การเบิกวัตถุดิบ สินค้า การจัดการ BOM
 */

export {
  CustomDashboardView,
  CustomView,
  Dashboard,
  DetailMaterialOnProcess,
  DetailMeasuredData,
  DetailProductProcess,
  EditMaterialUsageTransaction,
  EditProductProcess,
  ManufacturingOrderViews,
  MeasuredData,
  ProcessView,
  ReportView,
  SettingView,
  WorkView,
};

export default {
  Dashboard,
  DetailMaterialOnProcess,
  DetailMeasuredData,
  DetailProductProcess,
  EditMaterialUsageTransaction,
  EditProductProcess,
  MeasuredData,
  ...WorkView,
  ...ManufacturingOrderViews,
  ...ReportView,
  ...ProcessView,
  ...SettingView,
  ...CustomDashboardView,
};
