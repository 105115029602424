import React from 'react';
import { Check as CheckIcon, X as XIcon } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';
import { unZeroManufacMaterialAnalyzedArrayAnalyzer } from '@/utils/functions/MMS/materialAnalyzer';

const DeleteManufacOrderModal = ({
  isOpen,
  handleClose,
  manufacturingOrder,
  successHandle,
}) => {
  const { control, handleSubmit } = useForm();
  const me = useSelector((state) => state.me);
  const dispatch = useDispatch();
  const referenceNumber = hash({ me: me?.userData?._id, date: new Date() });

  const onSubmit = async (data) => {
    try {
      // Long Queue Process, must use Reference ID To Tracking

      await dispatch(
        actions.manufacturingOrderPut(manufacturingOrder?._id, {
          deleted: true,
          delete_note: data?.delete_note,
          employeeId: me?.userData?._id,
          referenceNumber,
        }),
      );
      successHandle(referenceNumber);
    } catch (error) {
      console.log('Error on Delete Manufacturing Order', error);
      window.alert('ยกเลิกคำสั่งผลิตไม่สำเร็จ');
    }
  };

  const checkProcessDeleteable = (selectedProcess) => {
    const isProcessMaterialEmpty = _.isEmpty(selectedProcess?.materials);
    const isProcessStart =
      selectedProcess?.current?.index && selectedProcess?.current?.index !== -1;

    const processAllowMODelete =
      selectedProcess?.deleted ||
      selectedProcess?.completed ||
      (!isProcessMaterialEmpty && !selectedProcess?.isRequestMaterial) ||
      (isProcessMaterialEmpty && !isProcessStart);

    return processAllowMODelete;
  };

  const isAllProcessesDeleteable = _.map(
    manufacturingOrder?.process,
    (_process) => checkProcessDeleteable(_process),
  );

  const renderProcess = (selectedProcess, index) => {
    const analyzedMaterial = unZeroManufacMaterialAnalyzedArrayAnalyzer(
      selectedProcess?.manufacturing_materials,
    );
    const renderRemainingMaterial = () => (
      <div>
        {!_.isEmpty(analyzedMaterial) && (
          <div className="font-system text-sm font-semibold text-yellow-600">
            กรุณาตรวจสอบวัตถุดิบที่ได้เบิกไว้อีกครั้ง
            <br />
            หากยกเลิกการผลิตไปแล้ว วัตถุดิบที่เหลือจะถือเป็นของเสียทั้งหมด
          </div>
        )}

        {_.map(analyzedMaterial, (_material, ind) => (
          <li key={ind} className="font-system text-sm ">
            <span className="font-semibold ">
              {_material?.materialInfo?.type_code}{' '}
            </span>
            {_material?.materialInfo?.name} ใช้ได้ {_material?.resultAmount}{' '}
            {_material?.materialInfo?.unit}
          </li>
        ))}
      </div>
    );

    if (selectedProcess?.completed) {
      return (
        <ListItem key={index}>
          <ListItemAvatar>
            <Avatar>
              <CheckIcon />{' '}
            </Avatar>
          </ListItemAvatar>
          <div className="flex flex-col">
            <ListItemText
              primary={`การผลิต${selectedProcess?.product?.name}`}
              secondary="การผลิตสินค้าชิ้นนี้เสร็จสิ้นแล้ว ไม่มีปัญหาในการยกเลิกคำสั่งผลิต"
            ></ListItemText>{' '}
            {renderRemainingMaterial()}
          </div>
        </ListItem>
      );
    }
    if (checkProcessDeleteable(selectedProcess)) {
      return (
        <ListItem key={index}>
          <ListItemAvatar>
            <Avatar>
              <CheckIcon />{' '}
            </Avatar>
          </ListItemAvatar>
          <div className="flex flex-col">
            <ListItemText
              className="max-w-sm"
              primary={`การผลิต${selectedProcess?.product?.name}`}
              secondary="ยังไม่เริ่มการผลิตสำหรับสินค้าชิ้นนี้ ไลน์การผลิตนี้จะถูกลบทันทีหลังจากคำสั่งผลิตถูกยกเลิก"
            ></ListItemText>{' '}
            {renderRemainingMaterial()}
          </div>
        </ListItem>
      );
    }
    return (
      <ListItem key={index}>
        <ListItemAvatar>
          <Avatar>
            <XIcon />{' '}
          </Avatar>
        </ListItemAvatar>
        <div className="flex flex-col">
          <ListItemText
            className="max-w-sm"
            primary={`การผลิต${selectedProcess?.product?.name}`}
            secondary={`สินค้าชิ้นนี้มีการเบิกวัตถุดิบ หรือ เริ่มการผลิตไปแล้ว
              กรุญาดำเนินการผลิตในไลน์การผลิตนั้นให้เสร็จสิ้น หรือ ยกเลิกไลน์การผลิตนั้นเสียก่อน จึงสามารถยกเลิกคำสั่งผลิตได้  `}
          ></ListItemText>
          {renderRemainingMaterial()}
        </div>
      </ListItem>
    );
  };

  return (
    <Dialog open={isOpen} onClose={() => handleClose()}>
      <DialogTitle>ยืนยันการยกเลิกคำสั่งผลิต</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}></form>
        <List>
          {_.map(manufacturingOrder?.process, (_process, index) =>
            renderProcess(_process, index),
          )}
        </List>
        <div></div>
        {!_.includes(isAllProcessesDeleteable, false) && (
          <div className="w-full mx-2">
            <Controller
              control={control}
              name="delete_note"
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  label="เหตุผลที่ยกเลิกคำสั่งผลิต"
                  {...field}
                  multiline={true}
                  rows={3}
                  fullWidth
                  required
                />
              )}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          onClick={handleSubmit(onSubmit)}
          color="primary"
          variant="contained"
          disabled={_.includes(isAllProcessesDeleteable, false)}
        >
          ยืนยัน
        </Button>
        <Button
          type="button"
          onClick={() => handleClose()}
          color="inherit"
          variant="contained"
        >
          ยกเลิก
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteManufacOrderModal;

DeleteManufacOrderModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  successHandle: PropTypes.func,
  manufacturingOrder: PropTypes.object,
};
