import {
  PDF_REPORT_ALL,
  PDF_REPORT_DELETE,
  PDF_REPORT_ERROR,
  PDF_REPORT_GET,
  PDF_REPORT_LOADING,
  PDF_REPORT_POST,
  PDF_REPORT_PUT,
} from '../../actions/types';

export default function PDFReportReducer(state = {}, { type, payload }) {
  switch (type) {
    case PDF_REPORT_ALL:
      return { ...payload, isLoading: false, isCompleted: true };
    case PDF_REPORT_GET:
      return { ...payload, isLoading: false, isCompleted: true };
    case PDF_REPORT_DELETE:
      return { isLoading: true, isCompleted: false };
    case PDF_REPORT_ERROR:
      return { isLoading: false, isCompleted: false };
    case PDF_REPORT_LOADING:
      return { isLoading: true, isCompleted: false };
    case PDF_REPORT_POST:
      return { isLoading: true, isCompleted: false };
    case PDF_REPORT_PUT:
      return { isLoading: true, isCompleted: false };
    default:
      return state;
  }
}
