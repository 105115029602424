const RATING = {
  VERY_GOOD: {
    status_code: "VERY_GOOD",
    description: "ดีมาก",
    level: 5,
    emoji: "😍",
    rangeStart: 4.5,
    rangeEnd: 5.1,
  },
  GOOD: {
    status_code: "GOOD",
    description: "ดี",
    level: 4,
    emoji: "😊",
    rangeStart: 3.5,
    rangeEnd: 4.5,
  },
  NORMAL: {
    status_code: "NORMAL",
    description: "ปานกลาง",
    level: 3,
    emoji: "😐",
    rangeStart: 2.5,
    rangeEnd: 3.5,
  },
  BAD: {
    status_code: "BAD",
    description: "แย่",
    level: 2,
    emoji: "😞",
    rangeStart: 1.5,
    rangeEnd: 2.5,
  },
  VERY_BAD: {
    status_code: "VERY_BAD",
    description: "แย่มาก",
    level: 1,
    emoji: "😡",
    rangeStart: -1,
    rangeEnd: 1.5,
  },
};

module.exports = RATING;
