import {
  MACHINE_ALL,
  MACHINE_GET,
  MACHINE_DELETE,
  MACHINE_PUT,
  MACHINE_POST,
  MACHINE_LOADING,
  MACHINE_ERROR,
} from '../../actions/types';

const initialState = {
  machine: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case MACHINE_LOADING:
      return { isLoading: true, isCompleted: true };
    case MACHINE_ERROR:
      return { isLoading: false, isCompleted: false, ...action.payload };
    case MACHINE_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MACHINE_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MACHINE_POST:
      return { isLoading: false, isCompleted: true };
    case MACHINE_PUT:
      return { isLoading: false, isCompleted: true };
    case MACHINE_DELETE:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
