/* eslint-disable import/prefer-default-export */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';
import THBText from 'thai-baht-text';

import { PAYROLL_TYPE } from '@/utils/constants';
import currencyFormatterTH from '@/utils/functions/currencyFormatterTH';

import 'addthaifont-pdfmake/build/vfs_fonts';

import { ReportHeader } from './common';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};

export const OneEmployeePayrollReport = (
  payrolls,
  employee,
  information,
  option = {
    startDate: '',
    endDate: '',
    payroll_type: '',
    filterUsingDate: false,
    selectedDate: '',
  },
  isError,
) => {
  const genPayrolls = (data) =>
    _.map(data, (_data, index) => [
      {
        text: index + 1,
        border: [true, false, true, true],
        fontSize: 10,
        colSpan: 1,
        alignment: 'center',
      },
      {
        text: `${dayjs(_data?.date).format('D MMM BBBB') || ''}`,
        border: [true, false, true, true],
        fontSize: 10,
        alignment: 'center',
        colSpan: 1,
      },
      {
        text: `${dayjs(_data?.daily_attribute?.start_time).format(
          'HH:mm',
        )} - ${dayjs(_data?.daily_attribute?.end_time).format('HH:mm')}`,
        border: [true, false, true, true],
        fontSize: 10,
        alignment: 'center',
        colSpan: 1,
      },
      {
        text: currencyFormatterTH.format(_data?.main_cost) || '',
        border: [true, false, true, true],
        fontSize: 10,
        alignment: 'center',
        colSpan: 1,
      },
      {
        text: _.map(
          _data?.overtimes,
          (eachOvertime) =>
            // prettier-ignore
            `${dayjs(eachOvertime?.start_time).format('HH:mm')} - ${dayjs(eachOvertime?.end_time).format('HH:mm')} \n`,
        ),
        border: [true, false, true, true],
        fontSize: 10,
        alignment: 'center',
        colSpan: 1,
      },
      {
        text: `${currencyFormatterTH.format(_data?.total_overtime)}`,
        border: [true, false, true, true],
        fontSize: 10,
        alignment: 'center',
        colSpan: 1,
      },
      {
        text: `${currencyFormatterTH.format(_data?.total_cost)}`,
        border: [true, false, true, true],
        fontSize: 10,
        alignment: 'center',
        colSpan: 1,
      },
    ]);

  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 30, 40, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '16',
    },
    info: {
      title: 'รายการค่าตอบแทนของพนักงาน',
    },
    content: [
      isError ? ReportHeader(information, false) : ReportHeader(information),
      {
        // หัวข้อ
        alignment: 'center',
        margin: [0, 15, 0, 10],
        text: 'รายการค่าตอบแทนของพนักงาน',
        fontSize: '16',
        bold: true,
      },
      {
        style: 'tableExample',
        table: {
          widths: ['15%', '35%', '15%', '35%'],
          heights: [15],
          body: [
            [
              {
                text: 'ชื่อพนักงาน',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${employee?.firstname} ${employee?.lastname}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: 'แผนก',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${employee?.department?.name} ${_.map(
                  employee?.other_departments,
                  (eachDepartment) => `/ ${eachDepartment?.name}`,
                )}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'ตั้งแต่วันที่',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${dayjs(option?.startDate).format('D MMMM BBBB')}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: 'จนถึง',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${dayjs(option?.endDate).format('D MMMM BBBB')}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        margin: [0, 5, 0, 0],
        style: 'tableExample',
        table: {
          widths: ['10%', '*', '18%', '12%', '18%', '13%', '13%'],
          heights: [15],
          body: [
            [
              {
                text: 'ลำดับ',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
                alignment: 'center',
              },
              {
                text:
                  option?.payroll_type === PAYROLL_TYPE.DAILY.status_code
                    ? 'วัน/เดือน/ปี'
                    : 'เดือน',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
                alignment: 'center',
              },
              {
                text: 'ช่วงเวลาที่ทำงาน',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
                alignment: 'center',
              },
              {
                text: 'ค่าตอบแทน',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
                alignment: 'center',
              },
              {
                text: 'การทำงานล่วงเวลา',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
                alignment: 'center',
              },
              {
                text: 'ค่าล่วงเวลา',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
                alignment: 'center',
              },
              {
                text: 'รวม\nค่าตอบแทน',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
                alignment: 'center',
              },
            ],
            ...genPayrolls(payrolls),
            [
              {
                text: 'รวม',
                colSpan: 3,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
                alignment: 'center',
              },
              {},
              {},
              {
                text: currencyFormatterTH.format(
                  _.sum(
                    _.map(payrolls, (eachPayroll) => eachPayroll?.main_cost),
                  ),
                ),
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
                alignment: 'center',
              },
              {
                text: '',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
                alignment: 'center',
              },
              {
                text: currencyFormatterTH.format(
                  _.sum(
                    _.map(
                      payrolls,
                      (eachPayroll) => eachPayroll?.total_overtime,
                    ),
                  ),
                ),
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
                alignment: 'center',
              },
              {
                text: currencyFormatterTH.format(
                  _.sum(
                    _.map(payrolls, (eachPayroll) => eachPayroll?.total_cost),
                  ),
                ),
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
                alignment: 'center',
              },
            ],
          ],
        },
      },
    ],
    // images: {
    //   logo: information?.logo?.url,
    // },
  };
  pdfMake.createPdf(docDefinition).open();
};
