import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  BackButton,
  Error,
  Loading,
  PlaceLaborCostForm,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { gql } from 'graphql-request';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import { config } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';

/**
 * @function CreatePriceLaborCost
 * @description Display a collections or a list of CreatePriceLaborCost from database
 */

export default function EditPlaceLaborCost({ title, subtitle }) {
  const dispatch = useDispatch();
  const place = useSelector((state) => state.place);
  const history = useHistory();
  const params = useParams();
  const placeLaborCost = useSelector((state) => state.placeLaborCost);
  const { handleSubmit, watch, control, setValue } = useForm({
    defaultValues: {},
  });

  const query = gql`
    query FindForPriceLaborCost($placeInput: PlaceInput) {
      findPlaces(input: $placeInput) {
        rows {
          _id
          name
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        placeInput: { page: 1, size: config.maxFetchSize },
      });
      const placeResult = queryResult?.findPlaces;
      dispatch(actions.placeStateSet(placeResult));
      // dispatch(
      //   actions.placeAll({
      //     page: 1,
      //     size: config.maxFetchSize,
      //   }),
      // );
      dispatch(actions.placeLaborCostGet(params.id));
    } catch (error) {
      console.error('Cannot Get Place', error);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  useEffect(() => {
    if (placeLaborCost && !placeLaborCost?.rows) {
      setValue('is_daily', placeLaborCost?.is_daily);
      setValue(
        'not_specify_amount_of_labor',
        placeLaborCost?.not_specify_amount_of_labor,
      );
      setValue('places', placeLaborCost?.places);
    }

    return () => {};
  }, [placeLaborCost]);

  const handleEditPlaceLaborCost = async (data) => {
    try {
      const payload = data;
      payload.start_date = dayjs(data.start_date).startOf('date').toDate();
      if (data?.is_daily) {
        payload.end_date = dayjs(data.start_date).endOf('date').toDate();
      }

      await dispatch(actions.placeLaborCostPut(params.id, payload));
      Swal.fire({
        icon: 'success',
        title: 'แก้ไขสำเร็จ',
      }).then(() => {
        history.goBack();
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'ไม่สามารถแก้ไขได้',
        text: error?.message,
      });
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (placeLaborCost?.isLoading || placeLaborCost?.rows) {
    return <Loading />;
  }

  if (placeLaborCost?.isCompleted && !placeLaborCost?.rows) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <form onSubmit={handleSubmit(handleEditPlaceLaborCost)}>
            <PlaceLaborCostForm
              control={control}
              place={place}
              watch={watch}
              defaultValue={placeLaborCost}
            />
            <div className="flex justify-end my-2">
              <Button type="submit" variant="contained">
                บันทึก
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return <Error />;
}

EditPlaceLaborCost.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditPlaceLaborCost.defaultProps = {
  title: '',
  subtitle: '',
};
