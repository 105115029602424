const initialState = {
  isLoading: true,
  isCompleted: true,
};

export default class BaseReducer {
  constructor({
    allConst,
    getConst,
    postConst,
    putConst,
    deleteConst,
    errorConst,
    loadingConst,
  }) {
    this.allConst = allConst;
    this.getConst = getConst;
    this.postConst = postConst;
    this.putConst = putConst;
    this.deleteConst = deleteConst;
    this.errorConst = errorConst;
    this.loadingConst = loadingConst;
  }

  getReducer(state = initialState, action) {
    switch (action.type) {
      case this.allConst:
        return {
          ...action.payload,
          isLoading: false,
          isCompleted: true,
        };
      case this.getConst:
        return {
          ...action.payload,
          isLoading: false,
          isCompleted: true,
        };
      case this.postConst:
        return { isLoading: false, isCompleted: true };
      case this.putConst:
        return { isLoading: false, isCompleted: true };
      case this.deleteConst:
        return { isLoading: false, isCompleted: true };
      case this.loadingConst:
        return { isLoading: true, isCompleted: true };
      case this.errorConst:
        return { isLoading: false, isCompleted: false };
      default:
        return state;
    }
  }
}
