import React from 'react';
import _ from 'lodash';
import {
  FormControlLabel,
  Card,
  FormControl,
  Switch,
  CardContent,
  Select,
  MenuItem,
  Button,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import { Controller, useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';

const moduleList = [
  {
    code: 'IMS',
    description: 'คลังวัตถุดิบ',
  },
  {
    code: 'MMS',
    description: 'การผลิต',
  },
  {
    code: 'SPM',
    description: 'ฝ่ายขาย',
  },
  {
    code: 'WMS',
    description: 'คลังสินค้า',
  },
  {
    code: 'PMS',
    description: 'ฝ่ายจัดซื้อ',
  },
];

export function NotifySettingForm({
  information,
  control,
  errors,
  department,
}) {
  const renderSetting = (module, index) => {
    const { fields, append, remove } = useFieldArray({
      control,
      name: `setting.notify.[${module.code}].departments`,
    });

    return (
      <div className="my-2 w-full lg:w-1/2" key={index}>
        <div className="px-2">
          <Card>
            <CardContent>
              <div className="flex flex-wrap w-full ">
                <div className="w-4/6 py-2 px-2  self-center font-semibold font-display">
                  {module.description}
                </div>
                <div className="w-2/6 py-2 px-2">
                  <Controller
                    name={`setting.notify[${module.code}].enable`}
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Switch
                              {...field}
                              defaultChecked={
                                information?.setting?.notify?.[module.code]
                                  ?.enable
                              }
                            />
                          }
                          label="เปิดใช้งาน"
                        />
                      </FormControl>
                    )}
                  />
                </div>
              </div>
              <div className="px-2 py-2">แผนกที่รับแจ้งเตือน</div>
              {_.map(fields, (_field, fieldIndex) => (
                <div key={_field?.id} className="my-2">
                  <div className="flex gap-2 w-full lg:w-full ml-2">
                    <div className="w-full">
                      <Controller
                        name={`setting.notify[${module.code}].departments[${fieldIndex}]`}
                        defaultValue={
                          information?.setting?.notify?.[module.code]
                            ?.departments?.[fieldIndex]
                        }
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <FormControl fullWidth size="small" required>
                            <InputLabel>แผนกที่รับแจ้งเตือน</InputLabel>
                            <Select {...field} label="แผนกที่รับแจ้งเตือน">
                              {_.map(
                                department?.rows,
                                (_department, deptIndex) => (
                                  <MenuItem
                                    key={deptIndex}
                                    value={_department?._id}
                                  >
                                    {_department?.name}
                                  </MenuItem>
                                ),
                              )}
                            </Select>
                            <FormHelperText>
                              {' '}
                              {errors?.setting?.notify?.[module.code]
                                ?.departments?.[fieldIndex] &&
                                'กรุณาเลือกแผนก'}{' '}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </div>
                    <div className="w-1/6">
                      <Button
                        color="error"
                        variant="contained"
                        type="button"
                        onClick={() => {
                          remove(fieldIndex);
                        }}
                      >
                        ลบ
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
              <div className="flex px-2">
                <Button
                  variant="outlined"
                  color="secondary"
                  type="button"
                  onClick={() => {
                    append('');
                  }}
                >
                  เพิ่ม
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-wrap">
      {_.map(moduleList, (_module, index) => renderSetting(_module, index))}
    </div>
  );
}

NotifySettingForm.propTypes = {
  information: PropTypes.object,
  control: PropTypes.object,
  errors: PropTypes.object,
  department: PropTypes.arrayOf(PropTypes.object),
};

NotifySettingForm.defaultProps = {
  information: null,
};

export default NotifySettingForm;
