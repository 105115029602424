import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

const ConversionForm = ({ control, errors, units, conversion }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'chain_conversion',
  });

  return (
    <div>
      <div className="flex flex-col flex-wrap">
        <div className="w-full  px-1 py-2">
          <div className="my-2 font-semibold">หน่วยแรก</div>
          {!_.isEmpty(units?.rows) && (
            <Controller
              name={'unit_a'}
              control={control}
              rules={{ required: true }}
              defaultValue={conversion ? conversion?.unit_a : null}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  options={units?.rows}
                  size="small"
                  placeholder="เลือกหน่วย"
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                  }}
                  className="z-40"
                  // prettier-ignore
                  getOptionLabel={(option) => `${option?.name || ''} (${option?.short_sign || ''})`
          }
                  renderInput={(params) => (
                    <TextField label="เลือกหน่วย" {...params} />
                  )}
                />
              )}
            />
          )}
        </div>
        <div className="w-full md:w-1/2 px-1 py-2">
          <div className="flex w-full ">
            <div className="self-center mr-3">คิดเป็น</div>
            <div className="w-1/2">
              <Controller
                name={'conversion_factor'}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="จำนวนเท่า"
                    fullWidth
                    required
                    size={'large'}
                    helperText={errors.conversion_factor && 'ชื่อหน่วย'}
                  />
                )}
              />
            </div>
            <div className="w-1/4 px-3 self-center">เท่า ของ</div>
          </div>
        </div>
        <div className="w-full  px-1 py-2 ">
          <div className="my-2 font-semibold">หน่วยหลัง</div>
          <Controller
            name={'unit_b'}
            control={control}
            rules={{ required: false }}
            defaultValue={conversion ? conversion?.unit_b : null}
            render={({ field }) => (
              <Autocomplete
                {...field}
                size="small"
                disablePortal
                options={units?.rows}
                placeholder="เลือกหน่วย"
                onChange={(e, newValue) => {
                  field.onChange(newValue);
                }}
                // prettier-ignore
                getOptionLabel={(option) => `${option?.name || ''} (${option?.short_sign})`
        }
                renderInput={(params) => (
                  <TextField label="เลือกหน่วย" {...params} />
                )}
              />
            )}
          />
        </div>
      </div>{' '}
      <div>
        {!conversion && (
          <div className="w-full  px-1 py-2 ">
            <Controller
              name={'create_reverse_conversion'}
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <FormControl>
                  <FormControlLabel
                    label="สร้างการแปลงหน่วยกลับโดยอัตโนมัติ"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                </FormControl>
              )}
            />
          </div>
        )}
      </div>
      <div>
        <hr className="my-4" />
      </div>
      <div>
        <div className="my-2 font-semibold">ห่วงโซ่ของการแปลงหน่วย</div>
        {_?.map(fields, (eachField, index) => (
          <div key={eachField.id} className="flex flex-wrap">
            <div className="w-full md:w-1/2 px-1 py-2">
              <div className="flex w-full ">
                <div className="self-center mr-3 w-1/6">คิดเป็น</div>
                <div className="w-full">
                  <Controller
                    name={`chain_conversion[${index}].conversion_factor`}
                    control={control}
                    defaultValue={eachField.conversion_factor}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="จำนวนเท่า"
                        fullWidth
                        size="small"
                      />
                    )}
                  />
                </div>
                <div className="w-1/4 px-3 self-center">เท่า ของ</div>
              </div>
            </div>
            <div className="w-full md:w-1/4 px-1 py-2">
              <Controller
                name={`chain_conversion[${index}].unit`}
                control={control}
                defaultValue={eachField.unit}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    size="small"
                    disablePortal
                    options={units?.rows}
                    placeholder="เลือกหน่วย"
                    onChange={(e, newValue) => {
                      field.onChange(newValue);
                    }}
                    // prettier-ignore
                    getOptionLabel={(option) => `${option?.name || ''} (${option?.short_sign})`
                      }
                    renderInput={(params) => (
                      <TextField label="เลือกหน่วย" {...params} />
                    )}
                  />
                )}
              />
            </div>
            <div className="w-full md:w-1/4 px-1 py-2">
              <Button
                onClick={() => remove(index)}
                variant="contained"
                color="error"
              >
                ลบ
              </Button>
            </div>
          </div>
        ))}
        <div>
          <Button onClick={() => append({})} variant="contained">
            เพิ่มหน่วย
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConversionForm;

ConversionForm.propTypes = {
  control: PropTypes.func,
  errors: PropTypes.func,
  units: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  conversion: PropTypes.shape({
    unit_a: PropTypes.object,
    unit_b: PropTypes.object,
    conversion_factor: PropTypes.number,
  }),
};
