const QUICK_BUTTON_TYPE = {
  CREATE_PROCESS: {
    status_code: "CREATE_PROCESS",
    description: "สร้างไลน์การผลิต",
  },

  // TODO: Add Another
};

module.exports = QUICK_BUTTON_TYPE;
