import dayjs from 'dayjs';
import _ from 'lodash';

import {
  DASHBOARD_ELEMENT_VALUE_TYPE,
  DATA_TYPE,
  WORK_SHIFT,
} from '../../constants';
import currencyFormatter from '../currencyFormatterTH';

import findVariableAndExecute from './findVariableAndExecute';

export const moLevelDashboardDataPrepare = (
  manufacturingOrder,
  dashboardElement,
) => {
  const getProcessName = (processData) => {
    if (processData?.produce_base_project_instead) {
      return processData?.base_project?.name || '';
    }

    if (processData?.produce_material_instead) {
      return `${processData?.product_as_material?.type_code || ''}${
        processData?.product_as_material?.name || '-'
      }`;
    }

    return `${processData?.product?.type_code || ''} ${
      processData?.product?.name || '-'
    }`;
  };

  const generateStatus = (processData) => {
    if (processData?.completed) {
      return 'เสร็จสิ้นแล้ว';
    }
    if (processData?.deleted) {
      return 'ยกเลิก';
    }
    return 'กำลังดำเนินการ';
  };

  const findMeasurementData = (processData, measurementType) => {
    const allStepMeasurement = [];
    _.map(processData?.steps, (eachStep) => {
      _.map(eachStep.measurements, (eachMeasurement) => {
        allStepMeasurement.push(eachMeasurement);
      });
    });

    const founedMeasurement = _.find(
      allStepMeasurement,
      (eachMeasurement) =>
        eachMeasurement?.measurement_type?._id === measurementType?._id,
    );

    switch (measurementType?.data_type) {
      case DATA_TYPE.BOOLEAN.type_code:
        return founedMeasurement?.boolean_value ? '✅' : '❎';
      case DATA_TYPE.CHECK_AND_DESCRIBE.type_code:
        return founedMeasurement?.boolean_value
          ? measurementType?.label_right
          : measurementType?.label_left;
      case DATA_TYPE.DATE.type_code:
        return dayjs(founedMeasurement?.date_value).format('D MMM BBBB');
      case DATA_TYPE.DATETIME.type_code:
        return dayjs(founedMeasurement?.date_value).format('D MMM BBBB HH:mm');
      case DATA_TYPE.NUMBER.type_code:
        return founedMeasurement?.value;
      case DATA_TYPE.PARAGRAPH.type_code:
        return founedMeasurement?.value;
      case DATA_TYPE.RADIO_AND_DESCRIBE.type_code:
        return founedMeasurement?.boolean_value
          ? measurementType?.label_right
          : measurementType?.label_left;
      case DATA_TYPE.STRING.type_code:
        return founedMeasurement?.value;
      case DATA_TYPE.SWITCH.type_code:
        return founedMeasurement?.boolean_value
          ? measurementType?.label_right
          : measurementType?.label_left;
      case DATA_TYPE.TIME.type_code:
        return dayjs(founedMeasurement?.date_value).format('HH:mm');
      default:
        return founedMeasurement?.value;
    }
  };

  const findResultingFunction = (processData, resultingFunctionType) => {
    const allStepMeasurement = [];
    _.map(processData?.steps, (eachStep) => {
      _.map(eachStep.measurements, (eachMeasurement) => {
        allStepMeasurement.push(eachMeasurement);
      });
    });

    let processResultingFunction = [];
    _.map(processData?.steps, (eachStep) => {
      processResultingFunction = _.concat(
        processResultingFunction,
        eachStep?.resultingFunctionTypes,
      );
    });

    console.log('processResultingFunction', processResultingFunction);

    const founedResultingFunction = _.find(
      processResultingFunction,
      (eachFunction) => eachFunction?._id === resultingFunctionType?._id,
    );

    console.log('Founeded Resulting Function', founedResultingFunction);

    const anotherFunctions = _.map(
      processResultingFunction,
      (eachAnotherFunction) => ({
        _id: eachAnotherFunction?._id,
        value: findVariableAndExecute({
          measurementVars: allStepMeasurement,
          resultingFunctionType: eachAnotherFunction,
          enableLog: false,
        }),
      }),
    );

    const executedResult = findVariableAndExecute({
      measurementVars: allStepMeasurement,
      resultingFunctionType: founedResultingFunction,
      enableLog: false,
      anotherMeasurementFT: anotherFunctions,
    });

    return _.isNumber(executedResult)
      ? currencyFormatter.format(executedResult)
      : executedResult;
  };

  const dashboardRows = _.map(
    manufacturingOrder?.process,
    (eachProcess, index) => {
      const returnElement = {};
      _.map(dashboardElement?.elements, (eachElement) => {
        switch (eachElement?.source_type) {
          case DASHBOARD_ELEMENT_VALUE_TYPE.CONSTANT.status_code:
            returnElement[eachElement?.name] = ''; // TODO: Create Input from Static
            break;
          case DASHBOARD_ELEMENT_VALUE_TYPE.CUSTOMER.status_code:
            returnElement[eachElement?.name] = `${
              eachProcess?.customer?.type_code || ' '
            } ${eachProcess?.customer?.name || '-'}`;
            break;
          case DASHBOARD_ELEMENT_VALUE_TYPE.INDEX.status_code:
            returnElement[eachElement?.name] = parseInt(
              index + 1,
              10,
            ).toString();
            break;
          case DASHBOARD_ELEMENT_VALUE_TYPE.MACHINE.status_code:
            returnElement[eachElement?.name] = eachProcess?.machine?.name;
            break;
          case DASHBOARD_ELEMENT_VALUE_TYPE.MEASUREMENT.status_code:
            returnElement[eachElement?.name] = findMeasurementData(
              eachProcess,
              eachElement?.measurement_type_source,
            );
            break;
          case DASHBOARD_ELEMENT_VALUE_TYPE.PROCESS_NAME.status_code:
            returnElement[eachElement?.name] = getProcessName(eachProcess);
            break;
          case DASHBOARD_ELEMENT_VALUE_TYPE.RESULTING_FUNCTION.status_code:
            returnElement[eachElement?.name] =
              findResultingFunction(
                eachProcess,
                eachElement?.resulting_function_type_source,
              ) || '-';
            break;
          case DASHBOARD_ELEMENT_VALUE_TYPE.STATUS.status_code:
            returnElement[eachElement?.name] = generateStatus(eachProcess);
            break;
          case DASHBOARD_ELEMENT_VALUE_TYPE.TIME_SHIFT.status_code:
            returnElement[eachElement?.name] =
              WORK_SHIFT[eachProcess?.work_shift]?.description || '';
            break;
          default:
            break;
        }
      });

      return returnElement;
    },
  );

  return dashboardRows;
};

export default moLevelDashboardDataPrepare;
