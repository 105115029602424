/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  BackButton,
  DesktopDatepicker,
  Error,
  Loading,
  LoadingDialog,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { PROCESS_TEMPLATE_TYPE } from '@iarcpsu/emanufac-constant';
import { Button, Card, CardContent, TextField } from '@mui/material';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';
import { useQuery } from '@/utils/functions';

/**
 * @function CreateProcessInMO
 * @description Display a collections or a list of MainDetailMO from database
 */

export default function CreateRetrod({ title, subtitle }) {
  const dispatch = useDispatch();
  const params = useParams();
  const browserQuery = useQuery();
  const baseProjectId = browserQuery.get('baseProjectId');
  const processTemplateId = browserQuery.get('processTemplateId');
  const basedProject = useSelector((state) => state.baseProject);
  const processTemplate = useSelector((state) => state.processTemplate);
  const manufacturingOrder = useSelector((state) => state.manufacturingOrder);
  const me = useSelector((state) => state.me);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, control } = useForm({});

  useEffect(() => {
    dispatch(actions.baseProjectGet(baseProjectId));
    dispatch(actions.processTemplateGet(processTemplateId));

    return () => {};
  }, [baseProjectId, processTemplateId]);

  const handleAddProcess = async (data) => {
    try {
      const processPayload = {
        ...data,
        manufacturing_order: params.id,
        process_template: processTemplateId,
        employee: me?.userData?._id,
        createFromTemplate: true,
        process_type: PROCESS_TEMPLATE_TYPE.SEQUENCIAL.status_code,
        produce_base_project_instead: true,
        base_project: baseProjectId,
      };
      console.log('Add Process Payload', processPayload);
      setIsLoading(true);
      await dispatch(actions.processCreate(processPayload));

      alert('สร้างการผลิตสำเร็จ');
      setIsLoading(false);
      history.goBack();
    } catch (error) {
      console.error('Error On Create New Process', error);
      alert(`เพิ่มการผลิตไม่สำเร็จ ${error?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (manufacturingOrder.isLoading) {
    return <Loading />;
  }
  if (!manufacturingOrder.isLoading && manufacturingOrder.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <BackButton />
        </div>
        <div className="my-2">
          <LoadingDialog isLoading={isLoading} />
          <Card>
            <CardContent>
              <div>สร้างไลน์การผลิตสำหรับ {basedProject?.name}</div>
              <div>
                <b>สูตรการผลิต</b> {processTemplate?.name}
              </div>
              <form onSubmit={handleSubmit(handleAddProcess)}>
                <div className="flex flex-wrap">
                  <div className="w-full my-1">
                    <Controller
                      control={control}
                      name="batch_no"
                      render={({ field }) => (
                        <TextField
                          label="หมายเลข Batch"
                          {...field}
                          size="small"
                          fullWidth
                          helperText={
                            'ถ้ามีหลายหมายเลขสามารถใช้ , คั่นเช่น 1,2'
                          }
                        />
                      )}
                    />
                  </div>{' '}
                  <div className="w-full my-1">
                    <Controller
                      control={control}
                      name="round_no"
                      render={({ field }) => (
                        <TextField
                          label="รอบที่ของการ Retrot"
                          {...field}
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  <div className="w-full lg:w-1/2 py-1 my-1 ">
                    <Controller
                      name={'manufacturing_date'}
                      control={control}
                      defaultValue={new Date()}
                      render={({ field }) => (
                        <DesktopDatepicker
                          {...field}
                          fullWidth
                          inputFormat="D/MM/YYYY"
                          label="วันที่ผลิต"
                          size="small"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="flex justify-end">
                  <Button variant="contained" type="submit">
                    บันทึก
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

CreateRetrod.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateRetrod.defaultProps = {
  title: '',
  subtitle: '',
};
