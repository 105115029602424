import {
  STEP_ALL,
  STEP_DELETE,
  STEP_ERROR,
  STEP_GET,
  STEP_LOADING,
  STEP_POST,
  STEP_PUT,
} from '../../actions/types';

const initialState = {
  step: null,
  isLoading: true,
  isCompleted: false,
};

export default function StepReducer(state = initialState, action) {
  switch (action.type) {
    case STEP_LOADING:
      return { isLoading: true, isCompleted: false };
    case STEP_ERROR:
      return { isLoading: false, isCompleted: false, ...action.payload };
    case STEP_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case STEP_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case STEP_POST:
      return { isLoading: false, isCompleted: true };
    case STEP_PUT:
      return { isLoading: true, isCompleted: false };
    case STEP_DELETE:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
