const add = {
  name: 'เข้า',
  direction: 'add',
};
const desc = {
  name: 'ออก',
  direction: 'desc',
};

export const PRODUCT_TRANSACTION_TYPE = [add, desc];

export const PRODUCT_TRANSACTION_JSON_TYPE = {
  add,
  desc,
};

export default PRODUCT_TRANSACTION_TYPE;
