import dayjs from 'dayjs';
import 'dayjs/locale/th';

dayjs.locale('th');

export const timeDurationWord = (expectedDate, calculatedDate) => {
  const startOfCalculateDate = dayjs(calculatedDate).startOf('days');
  const startOfExceptedDate = dayjs(expectedDate).startOf('days');

  if (dayjs(startOfExceptedDate).isBefore(startOfCalculateDate, 'date')) {
    return {
      word: `ล่าช้า ${startOfCalculateDate.diff(
        dayjs(startOfExceptedDate),
        'day',
      )} วัน`,
      cssClass: 'text-red-500 font-semibold',
    };
  }
  if (dayjs(startOfExceptedDate).isSame(startOfCalculateDate, 'date')) {
    return { word: 'ภายในวันนี้', cssClass: 'text-yellow-600 font-semibold' };
  }
  if (dayjs(startOfExceptedDate).isAfter(startOfCalculateDate, 'date')) {
    return {
      word: ` อีก ${dayjs(startOfExceptedDate).diff(
        startOfCalculateDate,
        'day',
      )} วัน`,
      cssClass: '',
    };
  }
  return { word: '', cssClass: '' };
};

export default { timeDurationWord };
