import React from 'react';
import PropTypes from 'prop-types';

const StringGauge = ({ measurementData }) => (
  <div>
    <div className="text-xl font-semibold">{measurementData?.value || '-'}</div>
  </div>
);

StringGauge.propTypes = {
  measurementData: PropTypes.object,
};

export default StringGauge;
