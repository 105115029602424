import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardContent,
  List,
  ListItemButton,
  ListSubheader,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import MultiUnitProdOrMatBox from '../Box/Rendering/MultiUnitProdOrMatBox';

const CardMaterialStockLotListInPlace = ({ previousLots, currentLot }) => {
  const groupByPlace = _.groupBy(previousLots, 'place.name');
  console.log('Group By Place', groupByPlace);
  return (
    <div>
      <Card>
        <CardContent>
          {_.map(groupByPlace, (values, key) => (
            <List
              subheader={
                <ListSubheader>
                  {key === 'undefined' ? values?.[0]?.warehouse?.name : key}
                </ListSubheader>
              }
            >
              {_.map(values, (eachLot, index) => (
                <Link to={`/ims/material-stock-lot/${eachLot?._id}`}>
                  <ListItemButton
                    key={index}
                    selected={eachLot?._id === currentLot?._id}
                  >
                    <div className="flex w-full justify-between">
                      <div>
                        <div>
                          {eachLot?.isProduct
                            ? eachLot?.product?.name
                            : eachLot?.material?.name}
                        </div>
                        <div className="text-xs">
                          เลขอ้างอิง {eachLot?.running_number}
                        </div>{' '}
                        <div className="text-xs">
                          {dayjs(
                            eachLot?.isProduct
                              ? eachLot?.inventory_date
                              : eachLot?.receipt_date,
                          ).format('D MMM BBBB HH:mm')}
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <div>
                          <div className="text-xs">ทั้งหมด</div>
                          <div className="text-sm">
                            <MultiUnitProdOrMatBox
                              foundMaterial={
                                eachLot?.isProduct
                                  ? eachLot?.product
                                  : eachLot?.material
                              }
                              quantity={eachLot?.quantity}
                            />
                          </div>
                        </div>
                        <div>
                          <div className="text-xs">คงเหลือ</div>
                          <div className="text-sm">
                            <MultiUnitProdOrMatBox
                              foundMaterial={
                                eachLot?.isProduct
                                  ? eachLot?.product
                                  : eachLot?.material
                              }
                              quantity={eachLot?.amount}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </ListItemButton>
                </Link>
              ))}
            </List>
          ))}
        </CardContent>
      </Card>
    </div>
  );
};

export default CardMaterialStockLotListInPlace;

CardMaterialStockLotListInPlace.propTypes = {
  previousLots: PropTypes.arrayOf(PropTypes.object),
  currentLot: PropTypes.object,
};
