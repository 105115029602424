import React from 'react';
import { Avatar, Card, CardContent } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

const CardEmployeeInfo = ({ employee }) => (
  <Card>
    <CardContent>
      <div className="flex flex-wrap justify-between">
        <div className="w-full md:w-1/2">
          <div className="flex gap-4">
            <Avatar
              src={employee?.image?.url}
              sx={{ width: 100, height: 100 }}
              alt={`${employee?.firstname} ${employee?.lastname || ''}`}
            />
            <div>
              <div className="font-semibold">พนักงาน</div>
              <div>
                {employee?.firstname} {employee?.lastname}
              </div>
              <div className="font-semibold mt-2">เริ่มมีชื่อในระบบ</div>
              <div className="mb-2">
                {dayjs(employee?.createdAt).format('D MMMM BBBB')}
              </div>
            </div>
          </div>
        </div>
        <div className="md:w-1/2">
          <div className="font-semibold">แผนก</div>{' '}
          <div className="">{employee?.department?.name}</div>
          {_.map(employee?.other_departments, (item) => (
            <div key={item.id} className="my-2">
              {item?.name}
            </div>
          ))}
        </div>
      </div>
    </CardContent>
  </Card>
);

export default CardEmployeeInfo;

CardEmployeeInfo.propTypes = {
  employee: PropTypes.object,
};
