import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Alert } from '@mui/material';
import dayjs from 'dayjs';

import * as actions from '@/redux/actions';

import { LoadingLinear } from '../../Loading';
import MaterialTransactionTable from '../../Tables/IMS-WMS/MaterialTransactionTable';

function MaterialTransactionTab() {
  const materialTransaction = useSelector((state) => state.materialTransaction);
  const params = useParams();
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [enableDateSearch, setIsEnableDateSearch] = useState(false);
  const dispatch = useDispatch();

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.materialTransactionLoading());
      dispatch(
        actions.materialTransactionSearch({
          page,
          size,
          date: enableDateSearch
            ? dayjs(selectedDate).format('YYYY-MM-DD')
            : '',
          month: '',
          place: params.placeId || '',
          material: params.id,
        }),
      );
    } catch (error) {
      dispatch(actions.materialTransactionError());
    }
  };

  useEffect(() => {
    setTotal(materialTransaction?.total);

    return () => {};
  }, [materialTransaction]);

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  if (materialTransaction?.isLoading) {
    return <LoadingLinear />;
  }

  if (!materialTransaction?.isLoading && materialTransaction?.isCompleted) {
    return (
      <div>
        <MaterialTransactionTable
          page={page}
          setPage={setPage}
          size={size}
          setSize={setSize}
          total={total}
          transaction={materialTransaction}
        />
      </div>
    );
  }

  return (
    <Alert severity="error" title="การดึงข้อมูลผิดพลาด">
      ไม่สามารถดคงข้อมูลประวัติการจัดการวัตถุดิบชิ้นนี้ได้
    </Alert>
  );
}

export default MaterialTransactionTab;
