import api from '../../../../configs/api';
import graphqlClient from '../../../../configs/graphqlClient';
import {
  METAL_DIVIDE_ALL,
  METAL_DIVIDE_DELETE,
  METAL_DIVIDE_ERROR,
  METAL_DIVIDE_GET,
  METAL_DIVIDE_LOADING,
  METAL_DIVIDE_POST,
  METAL_DIVIDE_PUT,
} from '../types';

export const metalDivideCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: METAL_DIVIDE_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/plugin/bj/metal-divide`,
      {
        ...payload,
      },
    );
    dispatch({ type: METAL_DIVIDE_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: METAL_DIVIDE_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const metalDivideAll = ({
  name = '',
  size = 10,
  page = 1,
  selectProcess = '',
  manufacturingOrder = '',
}) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/plugin/bj/metal-divide?name=${name}&page=${page}&size=${size}&process=${selectProcess}&manufacturing_order=${manufacturingOrder}`,
    );
    if (status === 200) {
      dispatch({ type: METAL_DIVIDE_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: METAL_DIVIDE_ERROR });
    // throw new Error(error?.response?.data?.error?.message);
  }
};

export const metalDivideAllQuery = ({ query }) => async (dispatch) => {
  try {
    const queryResult = await graphqlClient.request(query);
    const data = queryResult?.findUnits;
    //  console.log('Query Data', queryResult);
    if (!data) {
      dispatch({ type: METAL_DIVIDE_ERROR });
    }

    dispatch({ type: METAL_DIVIDE_ALL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: METAL_DIVIDE_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const metalDivideGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/plugin/bj/metal-divide/${id}`,
    );
    if (status === 200) {
      dispatch({ type: METAL_DIVIDE_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: METAL_DIVIDE_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const metalDivideStateSet = (data) => async (dispatch) => {
  dispatch({ type: METAL_DIVIDE_ALL, payload: data });
};

export const metalDivideStateOneSet = (data) => async (dispatch) => {
  dispatch({ type: METAL_DIVIDE_GET, payload: data });
};

export const metalDivideReset = () => async (dispatch) => {
  dispatch({ type: METAL_DIVIDE_LOADING });
};

export const metalDivideError = () => async (dispatch) => {
  dispatch({ type: METAL_DIVIDE_ERROR });
};

export const metalDividePut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: METAL_DIVIDE_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/plugin/bj/metal-divide/${id}`,
      payload,
    );
    dispatch({ type: METAL_DIVIDE_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: METAL_DIVIDE_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};
export const metalDivideDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: METAL_DIVIDE_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/plugin/bj/metal-divide/${id}`,
    );
    dispatch({ type: METAL_DIVIDE_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: METAL_DIVIDE_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};
