export const MATERIAL_COMING_METHOD = {
  WITHDRAW: {
    status_code: 'WITHDRAW',
    description: 'การเบิกวัตถุดิบ',
    info: 'เบิกวัตถุดิบจากในคลังวัตถุดิบ',
  },
  RECIEVE: {
    status_code: 'RECIEVE',
    description: 'การรับเข้า',
    info:
      'การรับเข้ามาจาก Supplier โดยตรงโดยไม่ต้องผ่านคลังวัตถุดิบ แต่มีการบันทึกไว้',
  },
  SPLITTING: {
    status_code: 'SPLITTING',
    description: 'การแยกออกจากวัตถุดิบ',
    info: 'แยกจากวัตถุดิบในขั้นตอนก่อนหน้า',
  },
  MERGING: {
    status_code: 'MERGING',
    description: 'การรวมกันของวัตถุดิบ',
    info: '',
  },
};

export default MATERIAL_COMING_METHOD;
