import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  BackButton,
  Error,
  Loading,
  MultiUnitProdOrMatBox,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { getTotalMaterialUsageOnPlace } from '@iarcpsu/emanufac-utils/functions';
import {
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { api, config } from '@/configs';
import { WordLocalizeContext } from '@/contexts/WordLocalizeContext';
import * as actions from '@/redux/actions';
import { currencyFormatter, useQuery } from '@/utils/functions';
/**
 * @function WeightAllowInPlace
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function WeightAllowInPlace({ title, subtitle }) {
  const dispatch = useDispatch();
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const params = useParams();
  const information = useSelector((state) => state.information);
  const [totalAllLotWeight, setTotalAllLotWeight] = useState(0);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [lotList, setLotList] = useState([]);
  const [total, setTotal] = useState(0);
  const browserQuery = useQuery();
  const { findWord } = useContext(WordLocalizeContext);

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.materialStockLotGet(params.id));
    } catch (error) {
      dispatch(actions.materialStockLotError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const getTotalWeightAndUnit = async (place) => {
    try {
      const {
        total: weightTotal,
        unit,
        lotList: fetchedLotList,
      } = await getTotalMaterialUsageOnPlace({
        place,
        currentDate: browserQuery.get('date'),
        axios: api,
        maxFetchSize: config.maxFetchSize,
        isFrontend: true,
      });
      setTotalAllLotWeight(weightTotal);
      setTotal(_.size(fetchedLotList));
      setSelectedUnit(unit);
      setLotList(fetchedLotList);
    } catch (error) {
      console.error('Cannot Get Total Weight And Unit', error);
    }
  };

  useEffect(() => {
    if (materialStockLot) {
      getTotalWeightAndUnit(materialStockLot?.place);
    }

    return () => {};
  }, [materialStockLot]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (materialStockLot.isLoading) {
    return <Loading />;
  }

  if (!materialStockLot.isLoading && materialStockLot.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />

        <div className="my-2">
          <Card>
            <CardContent>
              <div>ล็อตวัตถุดิบที่รับเข้าคลังวันเดียวกัน</div>
              <div className="flex flex-wrap">
                <div className="w-full md:w-1/2">
                  <div className="my-1">
                    <span className="font-semibold">ล็อตวัตถุดิบ:</span>{' '}
                    {materialStockLot?.running_number}{' '}
                  </div>
                  <div className="my-1">
                    <b>วัตถุดิบ:</b>{' '}
                    {materialStockLot?.material?.type_code || ''}{' '}
                    {materialStockLot?.material?.name}
                  </div>
                  <div className="flex gap-1 my-1">
                    <span className="font-semibold">จำนวน: </span>{' '}
                    <MultiUnitProdOrMatBox
                      foundMaterial={materialStockLot?.material}
                      noWrap
                      quantity={materialStockLot?.quantity}
                    />
                  </div>
                </div>
                <div className="w-full md:w-1/2">
                  <div className="my-1">
                    <span className="font-semibold">วันที่:</span>{' '}
                    {dayjs(browserQuery.get('date')).format('D MMM BBBB')}
                  </div>

                  <div className="my-1">
                    <span className="font-semibold">คลัง:</span>{' '}
                    {materialStockLot?.place?.name}
                  </div>
                  <div className="my-1">
                    <span className="font-semibold">ปริมาณรวม:</span>{' '}
                    {currencyFormatter.format(totalAllLotWeight)} {selectedUnit}
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="my-2">
          <Paper>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="font-bold">ลำดับที่</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">หมายเลขอ้างอิง</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">วันที่รับเข้าคลัง</div>
                    </TableCell>
                    {information?.setting?.ims?.purchase_date && (
                      <TableCell>
                        <div className="font-bold">
                          {findWord('purchase_date') || 'วันที่ซื้อวัตถุดิบ'}
                        </div>
                      </TableCell>
                    )}
                    <TableCell>
                      <div className="font-bold">วัตถุดิบ</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">ปริมาณ</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">คลัง</div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!_.isEmpty(lotList) ? (
                    _.map(lotList, (row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <div className="text-xs">{index + 1}</div>
                        </TableCell>
                        <TableCell>{row?.running_number}</TableCell>
                        <TableCell>
                          {dayjs(row?.receipt_date).format('D MMM BBBB')}
                        </TableCell>
                        {information?.setting?.ims?.purchase_date && (
                          <TableCell>
                            {dayjs(row?.purchase_date).format('D MMM BBBB')}
                          </TableCell>
                        )}
                        <TableCell>{row?.material?.name}</TableCell>
                        <TableCell>
                          <MultiUnitProdOrMatBox
                            foundMaterial={row?.material}
                            noWrap
                            quantity={row?.quantity}
                          />
                        </TableCell>
                        <TableCell>{row?.place?.name}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7}>
                        <div className="text-center">ไม่มีข้อมูล</div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>
    );
  }
  return <Error />;
}

WeightAllowInPlace.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

WeightAllowInPlace.defaultProps = {
  title: '',
  subtitle: '',
};
