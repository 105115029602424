import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { setLang } from '@antv/s2';
import { SheetComponent } from '@antv/s2-react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { WordLocalizeContext } from '@/contexts/WordLocalizeContext';
import { MATERIAL_STOCK_STATUS } from '@/utils/constants';

const MaterialPivotTable = ({
  generatedData,
  isTotalBySource,
  showRemainAmount,
  showTotalPrevious,
  showProcess,
  showRunOutOff,
  showYield,
  showRemainder,
}) => {
  const history = useHistory();
  const payload = generatedData;
  const extraMeta = [];

  setLang('en_US');
  const { findWordInPlace, findWord } = useContext(WordLocalizeContext);
  const rowList = isTotalBySource
    ? ['date', 'source']
    : ['date', 'source', 'runningNumber'];

  if (showRunOutOff) {
    rowList.push('status');
  }

  if (showProcess) {
    rowList.push('process');
  }
  if (showTotalPrevious) {
    rowList.push('previous_amount');
  }

  rowList.push('action');
  rowList.push('detail');

  if (showYield) {
    // กรองเพื่อไม่เอาตัวที่ไม่มี Value มา ตัว total จะ Bug
    const generatedDataWithHaveValue = _.filter(
      generatedData,
      (each) => each?.quantity,
    );
    const materialTypeList = _.map(
      generatedDataWithHaveValue,
      (each) => each?.material_type,
    );
    // Find Unique and Filter out undefined value
    const uniqMaterialType = _.filter(_.uniq(materialTypeList), (each) => each);
    // Made it Alphabetically Order
    const sortedMaterialType = uniqMaterialType?.sort();
    _.map(sortedMaterialType, (each) => {
      rowList.push(`${each}_total`);
      rowList.push(`${each}_yield`);

      // Generate Extra Meta Data for Row of Yield and Total
      extraMeta.push({
        field: `${each}_total`,
        name: `รวม${each}`,
        formatter: (data) => {
          if (data === null) {
            return 0;
          }
          if (_.isNaN(data)) {
            return 0;
          }

          if (data % 0 === 0) {
            return data;
          }

          return parseFloat(data).toFixed(2);
        },
      });
      extraMeta.push({
        field: `${each}_yield`,
        name: `Yield${each}`,
        formatter: (data) => {
          if (data === null) {
            return '0 %';
          }
          if (_.isNaN(data)) {
            return '0 %';
          }

          if (data % 0 === 0) {
            return `${data} %`;
          }

          return `${parseFloat(data).toFixed(2)} %`;
        },
      });
    });
  }

  if (showRemainder) {
    rowList.push('remainder');
  }

  const columnList = ['material_type', 'material'];

  /** @type {import('@antv/s2').S2DataConfig} */
  const config = {
    data: payload,
    fields: {
      rows: rowList,
      columns: columnList,
      values: showRemainAmount ? ['quantity', 'amount'] : ['quantity'],
      valueInCols: true,
    },
    meta: [
      {
        field: 'date',
        name: findWord('purchase_date') || 'วันที่ล็อต',
      },
      {
        field: 'source',
        name: findWord('material_source') || 'แหล่งวัตถุดิบ',
      },

      {
        field: 'material',
        name: 'วัตถุดิบ',
      },
      {
        field: 'quantity',
        name: 'ปริมาณ',
        formatter: (data) => {
          if (data === null) {
            return 0;
          }
          if (_.isNaN(data)) {
            return 0;
          }

          if (data % 0 === 0) {
            return data;
          }

          return parseFloat(data).toFixed(2);
        },
      },
      {
        field: 'amount',
        name: 'คงเหลือ',
      },
      {
        field: 'material_type',
        name: 'หมวดหมู่',
      },
      {
        field: 'action',
        name: 'ดำเนินการ',
        formatter: (value) => {
          if (value === 'null') {
            return null;
          }
          if (value !== '$$empty_field_value$$') {
            const splitBySlashURL = _.split(value, '/');
            const splitByQuestion = _.split(splitBySlashURL?.[2], '?');

            if (splitByQuestion?.[0] === 'fixed-move') {
              return findWordInPlace('go_to_next') || 'เบิกไปขั้นถัดไป';
            }
            if (splitByQuestion?.[0] === 'fixed-transform') {
              return findWordInPlace('transfrom_to_another') || 'แปลงวัตถุดิบ';
            }
            return 'เบิกไปขั้นถัดไป';
          }
          return value;
        },
      },
      {
        field: 'runningNumber',
        name: 'เลขอ้างอิงล็อต',
      },
      {
        field: 'detail',
        name: 'รายละเอียด',
        formatter: (value) => {
          if (value === 'null') {
            return null;
          }
          if (value !== '$$empty_field_value$$') {
            return 'รายละเอียด';
          }
          return null;
        },
      },
      {
        field: 'status',
        name: 'สถานะ',
        formatter: (value) => {
          if (value === '$$empty_field_value$$') {
            return '-';
          }
          return MATERIAL_STOCK_STATUS[value]?.description || '';
        },
      },
      {
        field: 'process',
        name: 'คำสั่งผลิต',
      },
      {
        field: 'previous_amount',
        name: findWordInPlace('previous_amount') || 'ผลรวมก่อนหน้า',
      },
      {
        field: 'remainder',
        name: 'เศษเหลือ',
      },

      ...extraMeta,
    ],
    width: 800,
    sortParams: [
      {
        sortFieldId: 'material',
        sortMethod: 'ASC',
      },
    ],
  };

  const calcFunc = (query, data) => {
    const quantityList = _.map(data, (each) => each?.quantity);
    return _.sum(quantityList);
  };

  return (
    <div>
      {generatedData && (
        <SheetComponent
          dataCfg={config}
          sheetType="pivot"
          themeCfg={{
            name: 'gray',
            theme: {
              dataCell: {
                bolderText: {
                  fontSize: 14,
                },
                text: {
                  fontSize: 14,
                },
              },
              colCell: {
                text: {
                  fontSize: 14,
                },
                bolderText: {
                  fontSize: 14,
                },
              },
              headerCell: {
                text: {
                  fontSize: 14,
                },
                bolderText: {
                  fontSize: 14,
                },
              },
              rowCell: {
                text: {
                  fontSize: 14,
                },
                bolderText: {
                  fontSize: 14,
                },
              },
              mergedCell: {
                text: {
                  fontSize: 14,
                },
                bolderText: {
                  fontSize: 14,
                },
              },
              cornerCell: {
                text: {
                  fontSize: 14,
                },
                bolderText: {
                  fontSize: 14,
                },
              },
            },
          }}
          options={{
            tooltip: {
              enable: true,
            },
            interaction: {
              selectedCellsSpotlight: true,
              hoverHighlight: true,
              copy: { enable: true },
              linkFields: ['action', 'detail'],
            },
            totals: {
              col: {
                showGrandTotals: true,
                showSubTotals: true,
                subTotalsDimensions: ['material_type'],
                calcSubTotals: {
                  calcFunc,
                },
                subLabel: 'รวม',
                calcTotals: {
                  calcFunc,
                },
                label: 'รวม',
              },
              row: {
                showSubTotals: !isTotalBySource,
                subTotalsDimensions: ['source'],
                calcSubTotals: {
                  calcFunc,
                },
                calcTotals: {
                  calcFunc,
                },
                subLabel: 'รวม',
                showGrandTotals: true,
                label: 'รวม',
              },
            },
          }}
          onLinkFieldJump={(jump) => {
            if (jump?.key === 'detail') {
              const actionLink = jump?.record?.detail;
              console.log('Action Link', actionLink);
              history.push(actionLink);
            } else if (jump?.key === 'action') {
              const actionLink = jump?.record?.action;
              history.push(actionLink);
            }
          }}
          adaptive
          header={{
            className: 'flex justify-end text-xs',
            title: 'Export',
            exportCfg: {
              open: true,
              className: 'flex',
            },
          }}
        />
      )}
    </div>
  );
};

export default MaterialPivotTable;

MaterialPivotTable.propTypes = {
  /** ข้อมูลที่จะส่งมาแสดงผล */
  generatedData: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      source: PropTypes.string,
      material: PropTypes.string,
      quantity: PropTypes.number,
      amount: PropTypes.number,
      lot: PropTypes.string,
      action: PropTypes.string,
    }),
  ),
  isTotalBySource: PropTypes.bool,
  /** แสดงยอดคงเหลือ */
  showRemainAmount: PropTypes.bool,
  /** แสดงจำนวนรวมของขั้นตอนก่อนหน้า */
  showTotalPrevious: PropTypes.bool,
  /** แสดง ID ของ Process */
  showProcess: PropTypes.bool,
  /** แสดงวัตถุดิบที่หมดแล้ว */
  showRunOutOff: PropTypes.bool,
  showYield: PropTypes.bool,
  showRemainder: PropTypes.bool,
};
