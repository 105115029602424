import {
  MEASUREMENT_ALL,
  MEASUREMENT_GET,
  MEASUREMENT_POST,
  MEASUREMENT_PUT,
  MEASUREMENT_DELETE,
  MEASUREMENT_ERROR,
  MEASUREMENT_LOADING,
} from '../../actions/types';

const initialState = {
  measurement: null,
  isLoading: true,
  isCompleted: true,
};

export default function MeasurementReducer(state = initialState, action) {
  switch (action.type) {
    case MEASUREMENT_LOADING:
      return { isLoading: true, isCompleted: true };
    case MEASUREMENT_ERROR:
      return { isLoading: false, isCompleted: false, ...action.payload };
    case MEASUREMENT_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MEASUREMENT_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MEASUREMENT_POST:
      return { isLoading: false, isCompleted: true };
    case MEASUREMENT_PUT:
      return { isLoading: false, isCompleted: true };
    case MEASUREMENT_DELETE:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
