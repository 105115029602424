/* eslint-disable arrow-body-style */
import React from 'react';
import { calculateRating } from '@iarcpsu/emanufac-utils/functions';
import PropTypes from 'prop-types';

const RatingTag = ({
  customer,
  allowUpdate,
  asBox,
  handleUpdateRating = () => {},
  showEvenIsEmpty,
}) => {
  if (!customer?.rating && showEvenIsEmpty) {
    return (
      <span
        className={` ${asBox ? 'bg-gray-100 px-1 rounded-md' : 'text-lg'}${
          allowUpdate
            ? 'cursor-pointer hover:bg-gray-200 hover:translate-y-1.5'
            : ''
        }`}
        onClick={() => {
          if (allowUpdate) {
            handleUpdateRating(customer);
          }
        }}
      >
        <span className="text-sm">ให้คะแนน</span>
      </span>
    );
  }

  if (!customer?.rating) {
    return <span></span>;
  }
  return (
    <span
      className={` ${asBox ? 'bg-gray-100 px-1 rounded-md' : 'text-lg'}${
        allowUpdate
          ? 'cursor-pointer hover:bg-gray-200 hover:translate-y-1.5'
          : ''
      }`}
      onClick={() => {
        if (allowUpdate) {
          handleUpdateRating(customer);
        }
      }}
    >
      {calculateRating(customer?.rating)?.emoji}
      {asBox && (
        <span
          className={`ml-1 text-gray-800 ${
            allowUpdate ? 'cursor-pointer' : ''
          }`}
        >
          {customer?.rating?.toFixed(1)}
        </span>
      )}
    </span>
  );
};

export default RatingTag;

RatingTag.propTypes = {
  customer: PropTypes.object,
  allowUpdate: PropTypes.bool,
  asBox: PropTypes.bool,
  handleUpdateRating: PropTypes.func,
  showEvenIsEmpty: PropTypes.bool,
};
