import {
  SHIPPING_TYPE_ALL,
  SHIPPING_TYPE_GET,
  SHIPPING_TYPE_DEL,
  SHIPPING_TYPE_PUT,
  SHIPPING_TYPE_POST,
  SHIPPING_TYPE_LOADING,
  SHIPPING_TYPE_ERROR,
} from '../types';

import api from '../../../configs/api';

export const shippingTypeCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SHIPPING_TYPE_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/shipping-type`,
      {
        ...payload,
      },
    );
    dispatch({ type: SHIPPING_TYPE_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: SHIPPING_TYPE_ERROR });
    throw new Error(error);
  }
};

export const shippingTypeAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/shipping-type?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: SHIPPING_TYPE_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: SHIPPING_TYPE_ERROR });
    throw new Error(error);
  }
};

export const shippingTypeGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/shipping-type/${id}`,
    );
    if (status === 200) {
      dispatch({ type: SHIPPING_TYPE_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: SHIPPING_TYPE_ERROR });
    throw new Error(error);
  }
};

export const shippingTypeStateSet = (data) => async (dispatch) => {
  dispatch({ type: SHIPPING_TYPE_ALL, payload: data });
};

export const shippingTypePut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: SHIPPING_TYPE_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/shipping-type/${id}`,
      payload,
    );
    dispatch({ type: SHIPPING_TYPE_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: SHIPPING_TYPE_ERROR });
    throw new Error(error);
  }
};
export const shippingTypeDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: SHIPPING_TYPE_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/shipping-type/${id}`,
    );
    dispatch({ type: SHIPPING_TYPE_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: SHIPPING_TYPE_ERROR });
    throw new Error(error);
  }
};
