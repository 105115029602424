const MANUFACTURING_MODE = {
  MMS: {
    status_code: "MMS",
    name: "Manufacturing Managemen System",
    description: "ระบบจัดการการผลิตแบบเป็นขั้นตอน",
  },
  WBM: {
    status_code: "WBM",
    name: "Warehouse Based Manufacturing",
    description: "ระบบจัดการการผลิตผ่านคลังวัตถุดิบและสินค้า",
  },
};

module.exports = MANUFACTURING_MODE;
