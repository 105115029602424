export const DASHBOARD_ELEMENT_VALUE_TYPE = {
  MEASUREMENT: {
    status_code: 'MEASUREMENT',
    description: 'ค่าจากตัวแปรวัดผล',
    special: false,
  },
  RESULTING_FUNCTION: {
    status_code: 'RESULTING_FUNCTION',
    description: 'ค่าที่ได้จากการคำนวณตัวแปร',
    special: false,
  },
  CONSTANT: {
    status_code: 'CONSTANT',
    description: 'ค่าคงที่',
    special: true,
  },
  PROCESS_NAME: {
    status_code: 'PROCESS_NAME',
    description: 'ชื่อสินค้า / ผลิตภัณฑ์',
    special: true,
  },
  QUANTITY: {
    status_code: 'QUANTITY',
    description: 'จำนวน',
    special: true,
  },
  CUSTOMER: {
    status_code: 'CUSTOMER',
    description: 'ลูกค้า',
    special: true,
  },
  MACHINE: {
    status_code: 'MACHINE',
    description: 'เครื่องจักร',
    special: true,
  },
  TIME_SHIFT: {
    status_code: 'TIME_SHIFT',
    description: 'กะ',
    special: true,
  },
  STATUS: {
    status_code: 'STATUS',
    description: 'สถานะ',
    special: true,
  },
  INDEX: {
    status_code: 'INDEX',
    description: 'Index',
    special: true,
  },
};

export default DASHBOARD_ELEMENT_VALUE_TYPE;
