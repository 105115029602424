import {
  UNIT_ALL,
  UNIT_GET,
  UNIT_DELETE,
  UNIT_PUT,
  UNIT_POST,
  UNIT_LOADING,
  UNIT_ERROR,
} from '../types';

import api from '../../../configs/api';
import graphqlClient from '../../../configs/graphqlClient';

export const unitCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: UNIT_LOADING });
    const { data } = await api.post(`${process.env.REACT_APP_API_URL}/unit`, {
      ...payload,
    });
    dispatch({ type: UNIT_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: UNIT_ERROR });
    throw new Error(error);
  }
};

export const unitAll = ({ name = '', size = 10, page = 1 }) => async (
  dispatch,
) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/unit?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: UNIT_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: UNIT_ERROR });
    throw new Error(error);
  }
};

export const unitAllQuery = ({ query }) => async (dispatch) => {
  try {
    const queryResult = await graphqlClient.request(query);
    const data = queryResult?.findUnits;
    //  console.log('Query Data', queryResult);
    if (!data) {
      dispatch({ type: UNIT_ERROR });
    }

    dispatch({ type: UNIT_ALL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: UNIT_ERROR });
    throw new Error(error);
  }
};

export const unitGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/unit/${id}`,
    );
    if (status === 200) {
      dispatch({ type: UNIT_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: UNIT_ERROR });
    throw new Error(error);
  }
};

export const unitStateSet = (data) => async (dispatch) => {
  dispatch({ type: UNIT_ALL, payload: data });
};

export const unitPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: UNIT_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/unit/${id}`,
      payload,
    );
    dispatch({ type: UNIT_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: UNIT_ERROR });
    throw new Error(error);
  }
};
export const unitDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: UNIT_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/unit/${id}`,
    );
    dispatch({ type: UNIT_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: UNIT_ERROR });
    throw new Error(error);
  }
};
