import React, { useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import MeasurementAddingRenderingCompanent from '../../Measurement/MeasurementAddingRenderingComponent';

export function MaterialForm({
  errors,
  material,
  control,
  materialType,
  customerType,
  selectCustomerType,
  setSelectCustomerType,
  customer,
  selectCustomer,
  setSelectCustomer,
  unit,
  watch,
  otherMaterialList,
  productType,
}) {
  const [editCustomer, setEditCustomer] = useState(false);

  // Use Selector get information from redux
  const information = useSelector((state) => state.information);
  const { fields: inputMaterialField, append, remove } = useFieldArray({
    control,
    name: 'input_materials',
  });

  const {
    setting: { ims: imsSetting },
  } = information;

  const handlePushtoSelectCustomer = (select) => {
    const removeIndex = _.indexOf(selectCustomer, select);
    console.log('removeIndex', removeIndex);
    if (removeIndex > -1) {
      const newArr = _.filter(
        selectCustomer,
        (_customer, index) => index !== removeIndex,
      );
      setSelectCustomer(newArr);
    } else {
      setSelectCustomer([...selectCustomer, select]);
    }
  };
  return (
    <div className="flex flex-row flex-wrap w-full">
      <div className="font-semibold">ข้อมูลวัตถุดิบ</div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'type_code'}
          control={control}
          defaultValue={material ? material.type_code : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รหัสวัตถุดิบ"
              fullWidth
              size={'small'}
              helperText={errors.type_code && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={material ? material.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อวัตถุดิบ"
              fullWidth
              size={'small'}
              required
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>

      {watch('use_unit_conversion') === true ? (
        <div className="w-full lg:w-2/4 px-1 py-2 flex">
          <div className="w-1/2 px-1">
            {!_.isEmpty(unit?.rows) && (
              <Controller
                name={'unit_input'}
                control={control}
                defaultValue={material ? material.unit_input : ''}
                rules={{ required: false }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={unit?.rows}
                    size={'small'}
                    disableClearable
                    onChange={(e, newValue) => {
                      field.onChange(newValue);
                    }}
                    getOptionLabel={(option) => `${option?.name} `}
                    required
                    renderInput={(params) => (
                      <TextField {...params} label="หน่วยหลัก" />
                    )}
                  />
                )}
              />
            )}
          </div>
          <div className="w-1/2 px-1">
            {!_.isEmpty(unit?.rows) && (
              <Controller
                name={'unit_output'}
                control={control}
                defaultValue={material ? material.unit_output : ''}
                rules={{ required: false }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={unit?.rows}
                    size={'small'}
                    disableClearable
                    onChange={(e, newValue) => {
                      field.onChange(newValue);
                    }}
                    getOptionLabel={(option) => `${option?.name} `}
                    required
                    renderInput={(params) => (
                      <TextField {...params} label="หน่วยรอง" />
                    )}
                  />
                )}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="w-full lg:w-1/4 px-1 py-2">
          <Controller
            name={'unit'}
            control={control}
            defaultValue={material ? material.unit : ''}
            render={({ field }) => (
              <TextField
                {...field}
                label="หน่วยวัตถุดิบ"
                fullWidth
                size={'small'}
                helperText={errors.unit && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
      )}

      <div className="w-full lg:w-1/4 px-1 py-2">
        <Controller
          name={'use_unit_conversion'}
          control={control}
          defaultValue={material ? material.use_unit_conversion : false}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox checked={field.value} {...field} />}
              label="ระบุมากกว่า 1 หน่วย"
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        {!_.isEmpty(materialType?.rows) && (
          <Controller
            name={'material_type'}
            control={control}
            defaultValue={material ? material?.material_type : null}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={materialType?.rows}
                size={'small'}
                disableClearable
                onChange={(e, newValue) => {
                  field.onChange(newValue);
                }}
                getOptionLabel={(option) => `${option?.name || ''} `}
                renderInput={(params) => (
                  <TextField {...params} label="ประเภทวัตถุดิบ" required />
                )}
              />
            )}
          />
        )}
      </div>
      {imsSetting?.materialAttribute?.net && (
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'net'}
            control={control}
            defaultValue={material ? material.net : ''}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ปริมาตรสุทธิ"
                fullWidth
                type="number"
                size={'small'}
                helperText={errors.net && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
      )}
      {imsSetting?.materialAttribute?.net && (
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'unit_net'}
            control={control}
            defaultValue={material ? material.unit_net : ''}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                label="หน่วยของปริมาตรสุทธิ"
                fullWidth
                size={'small'}
                helperText={errors.unit_net && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
      )}
      {imsSetting?.materialAttribute?.register_number && (
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'register_number'}
            control={control}
            defaultValue={material ? material.register_number : ''}
            render={({ field }) => (
              <TextField
                {...field}
                label="เลขทะเบียน"
                fullWidth
                size={'small'}
                helperText={errors.register_number && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
      )}
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'minimum_amount'}
          control={control}
          defaultValue={material ? material.minimum_amount : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="จำนวนขั้นต่ำของวัตถุดิบ"
              fullWidth
              type="number"
              size={'small'}
              helperText={errors.minimum_amount && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>

      {imsSetting?.materialAttribute?.common_price && (
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'common_price'}
            control={control}
            defaultValue={material ? material.common_price : 0}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ราคาโดยทั่วไป"
                fullWidth
                size={'small'}
                helperText={errors.common_price && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
      )}

      {imsSetting?.peerMaterialWithProductType && (
        <div className="w-full lg:w-1/2 px-1 py-2">
          {!_.isEmpty(productType?.rows) && (
            <Controller
              name={'peer_product_type'}
              control={control}
              defaultValue={material ? material.peer_product_type : []}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  options={productType?.rows}
                  size="small"
                  placeholder="หมวดหมู่สินค้าที่ผูกกับวัตถุดิบชนิดนี้"
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                  }}
                  multiple
                  className="z-40"
                  // prettier-ignore
                  getOptionLabel={(option) => `${option?.name || ''}`
              }
                  renderInput={(params) => (
                    <TextField
                      label="หมวดหมู่สินค้าที่ผูกกับวัตถุดิบชนิดนี้"
                      {...params}
                    />
                  )}
                />
              )}
            />
          )}
        </div>
      )}
      <div className="w-full px-1 py-2">
        <Controller
          name={'detail'}
          control={control}
          defaultValue={material ? material.detail : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รายละเอียด"
              fullWidth
              rows={2}
              multiline={true}
              size={'small'}
              helperText={errors.detail && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>

      <div className="w-full  px-1 py-2">
        <Controller
          name={'is_workin_process'}
          control={control}
          defaultValue={material ? material.is_workin_process : false}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox checked={field.value} {...field} />}
              label="เป็นวัตถุดิบแบบ Work In Process"
            />
          )}
        />
      </div>

      {watch('is_workin_process') && (
        <div className="w-full mb-4 border rounded-md p-2">
          <div className="my-2 font-semibold">
            ส่วนผสมที่ทำให้เกิดวัตถุดิบชิ้นนี้ 1 หน่วย
          </div>
          <div className="grid lg:grid-cols-2 ">
            {_.map(inputMaterialField, (eachField, fieldIndex) => (
              <div className="flex my-1" key={`imf-${fieldIndex}`}>
                <div className="w-3/5 px-2">
                  {!_.isEmpty(otherMaterialList) && (
                    <Controller
                      name={`input_materials[${fieldIndex}].material`}
                      control={control}
                      defaultValue={eachField?.material}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          options={otherMaterialList}
                          size={'small'}
                          disableClearable
                          onChange={(e, newValue) => {
                            field.onChange(newValue);
                          }}
                          getOptionLabel={(option) => `${option?.name || ''} `}
                          required
                          renderInput={(params) => (
                            <TextField {...params} label="วัตถุดิบ" />
                          )}
                        />
                      )}
                    />
                  )}
                </div>
                <div className="w-2/5 flex gap-2 px-2">
                  {information?.setting?.ims?.wip_show_fraction_add && (
                    <Controller
                      name={`input_materials[${fieldIndex}].fraction`}
                      control={control}
                      defaultValue={eachField?.fraction}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="ปริมาณ (หน่วย)"
                          fullWidth
                          size="small"
                        />
                      )}
                    />
                  )}
                  <Button
                    size="small"
                    variant="contained"
                    color="error"
                    onClick={() => remove(fieldIndex)}
                  >
                    ลบ
                  </Button>
                </div>
              </div>
            ))}
          </div>
          <Button variant="contained" size="small" onClick={() => append({})}>
            เพิ่ม
          </Button>
          <div className="w-full  px-1 py-2">
            <Controller
              name={'autofetching'}
              control={control}
              defaultValue={material ? material.autofetching : false}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox checked={field.value} {...field} />}
                  label="ดึงปริมาณวัตถุดิบจากส่วนผสมอัตโนมัติ"
                />
              )}
            />
          </div>
        </div>
      )}
      {information?.setting?.ims?.additionalAttribute &&
        _.map(
          information?.setting?.ims?.additionalAttribute,
          (eachAttribute, index) => (
            <div className="w-full lg:w-1/2 px-1 py-2" key={index}>
              <MeasurementAddingRenderingCompanent
                control={control}
                measurementControllerName={`additional.${eachAttribute?.name}`}
                measurementType={{
                  data_type: eachAttribute?.type,
                  label: eachAttribute?.name,
                }}
                watch={watch}
                defaultValue={
                  material?.additional?.[eachAttribute?.name]?.value
                }
              />
            </div>
          ),
        )}
      {editCustomer ? (
        <>
          <div className="flex">
            <div className="font-semibold">ข้อมูลคู่ค้า</div>
            <div className="ml-2">
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => {
                  setEditCustomer(!editCustomer);
                  setSelectCustomerType('');
                }}
              >
                กลับ
              </Button>
            </div>
          </div>
          <div className="w-full px-1 py-2">
            <Controller
              name={'customer_type'}
              control={control}
              //   defaultValue={material ? material?.supplier?.[0]?.type?._id : ''}
              render={({ field }) => (
                <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                  <InputLabel id="type" size={'small'}>
                    ประเภทคู่ค้า
                  </InputLabel>
                  <Select
                    {...field}
                    label="ประเภทคู่ค้า"
                    size={'small'}
                    fullWidth
                    onChange={(e) => {
                      setSelectCustomerType(e.target.value);
                    }}
                  >
                    {_.size(customerType?.rows) ? (
                      _.map(customerType.rows, (row) => (
                        <MenuItem key={row.id} value={row.name}>
                          {row.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>
                        <MenuItem disabled value="">
                          <em>ไม่มีข้อมูล</em>
                        </MenuItem>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            />
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            {selectCustomerType ? (
              <>
                <div className="">
                  <div className="text-sm font-semibold mb-1">
                    {selectCustomerType}
                  </div>
                  <Paper>
                    <TableContainer
                      component={Paper}
                      style={{ maxHeight: 400 }}
                    >
                      <Table sx={{ minWidth: 650 }}>
                        <colgroup>
                          <col width="5%" />
                          <col width="10%" />
                          <col width="20%" />
                          <col width="5%" />
                          <col width="10%" />
                        </colgroup>
                        <TableHead className="bg-blue-100">
                          <TableRow>
                            <TableCell>
                              <div className="font-bold">ลำดับที่</div>
                            </TableCell>
                            <TableCell>
                              <div className="font-bold">ชื่อ</div>
                            </TableCell>
                            <TableCell>
                              <div className="font-bold">ที่อยู่</div>
                            </TableCell>
                            <TableCell>
                              <div className="font-bold">เบอร์โทร</div>
                            </TableCell>
                            <TableCell>
                              <div className="font-bold"> ดำเนินการ</div>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!_.isEmpty(customer?.rows) ? (
                            customer?.rows.map((row, index) => (
                              <TableRow
                                key={row.id}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {index + 1}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {row?.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {row?.address}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {row?.phone_number}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Button
                                    variant="contained"
                                    color={'secondary'}
                                    onClick={() => {
                                      handlePushtoSelectCustomer(row);
                                    }}
                                    disabled={_.includes(selectCustomer, row)}
                                  >
                                    เพิ่ม
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={6}>
                                <div className="text-center">ไม่มีข้อมูล</div>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            {selectCustomerType ? (
              <>
                <div className="">
                  <div className="text-sm font-semibold mb-1">
                    คู่ค้าที่เลือก
                  </div>
                  <Paper>
                    <TableContainer
                      component={Paper}
                      style={{ maxHeight: 400 }}
                    >
                      <Table sx={{ minWidth: 650 }}>
                        <colgroup>
                          <col width="5%" />
                          <col width="10%" />
                          <col width="20%" />
                          <col width="5%" />
                          <col width="10%" />
                        </colgroup>
                        <TableHead className="bg-blue-100">
                          <TableRow>
                            <TableCell>
                              <div className="font-bold">ลำดับที่</div>
                            </TableCell>
                            <TableCell>
                              <div className="font-bold">ชื่อ</div>
                            </TableCell>
                            <TableCell>
                              <div className="font-bold">ที่อยู่</div>
                            </TableCell>
                            <TableCell>
                              <div className="font-bold">เบอร์โทร</div>
                            </TableCell>
                            <TableCell>
                              <div className="font-bold"> ดำเนินการ</div>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!_.isEmpty(selectCustomer) ? (
                            selectCustomer.map((select, index) => (
                              <TableRow
                                key={select.id}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {index + 1}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {select?.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {select?.address}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {select?.phone_number}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Button
                                    variant="contained"
                                    color={'error'}
                                    onClick={() => {
                                      handlePushtoSelectCustomer(select);
                                    }}
                                  >
                                    ลบ
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={6}>
                                <div className="text-center">ไม่มีข้อมูล</div>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <>
          {!_.isEmpty(material?.supplier) ? (
            <>
              <div className="flex">
                <div className="font-semibold">ข้อมูลคู่ค้า</div>
                <div className="ml-2">
                  <Button
                    size="small"
                    variant="outlined"
                    color="warning"
                    onClick={() => {
                      setEditCustomer(!editCustomer);
                    }}
                  >
                    แก้ไข
                  </Button>
                </div>
              </div>

              <div className="w-full px-1 py-2">
                <Paper>
                  <TableContainer component={Paper} style={{ maxHeight: 400 }}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead className="bg-blue-100">
                        <TableRow>
                          <TableCell>
                            <div className="font-bold">ลำดับที่</div>
                          </TableCell>
                          <TableCell>
                            <div className="font-bold">ชื่อ</div>
                          </TableCell>
                          <TableCell>
                            <div className="font-bold">ที่อยู่</div>
                          </TableCell>
                          <TableCell>
                            <div className="font-bold">เบอร์โทร</div>
                          </TableCell>
                          <TableCell>
                            <div className="font-bold">อีเมล</div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {material?.supplier.map((_supplier, index) => (
                          <TableRow
                            key={_supplier.id}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {index + 1}
                            </TableCell>
                            <TableCell>{`${_supplier?.name}`}</TableCell>
                            <TableCell>{`${_supplier?.address}`}</TableCell>
                            <TableCell>
                              {`${_supplier?.phone_number}`}
                            </TableCell>
                            <TableCell>{`${_supplier?.email}`}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-wrap w-full">
                <div className="flex">
                  <div className="font-semibold">ข้อมูลคู่ค้า</div>
                  <div className="ml-2">
                    <Button
                      size="small"
                      variant="outlined"
                      color="success"
                      onClick={() => {
                        setEditCustomer(!editCustomer);
                      }}
                    >
                      เพิ่มข้อมูลคู่ค้า
                    </Button>
                  </div>
                </div>
                <div className="bg-gray-50 w-full text-center mt-2">
                  ไม่มีข้อมูล
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

MaterialForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    unit: PropTypes.object,
    net: PropTypes.object,
    unit_net: PropTypes.object,
    detail: PropTypes.object,
    type_code: PropTypes.object,
    minimum_amount: PropTypes.object,
    register_number: PropTypes.object,
    common_price: PropTypes.object,
  }),
  material: PropTypes.object,
  materialType: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
  customerType: PropTypes.object,
  selectCustomerType: PropTypes.object,
  setSelectCustomerType: PropTypes.object,
  customer: PropTypes.object,
  selectCustomer: PropTypes.object,
  setSelectCustomer: PropTypes.object,
  unit: PropTypes.object,
  watch: PropTypes.func,
  otherMaterialList: PropTypes.arrayOf(PropTypes.object),
  productType: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};

MaterialForm.defaultProps = {
  material: null,
  materialType: null,
  customerType: null,
};

export default MaterialForm;
