import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import dayjs from 'dayjs';
import BuddhistEra from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as CONSTANT from '@/utils/constants';
import MultiUnitProdOrMatBox from '../../Box/Rendering/MultiUnitProdOrMatBox';

dayjs.extend(BuddhistEra);

export default function MaterialWithdrawInfoTable({ currentMaterialRequest }) {
  return (
    <Table size="small">
      <TableHead className="bg-white ">
        <TableRow
          sx={{
            '&:last-child td, &:last-child th': { border: 1 },
          }}
        >
          <TableCell>ลำดับที่</TableCell>
          <TableCell>วันที่</TableCell>
          <TableCell>วัตถุดิบ</TableCell>
          <TableCell>สถานะ</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {_.map(currentMaterialRequest, (request, index) => (
          <TableRow
            key={index}
            className="border border-gray-500"
            sx={{
              '&:last-child td, &:last-child th': { border: 1 },
            }}
          >
            <TableCell className="border border-gray-500">
              {index + 1}
            </TableCell>
            <TableCell className="border border-gray-500">
              {dayjs(request?.createdAt).locale('th').format('D MMM BBBB')}
            </TableCell>
            <TableCell className="border border-gray-500">
              {_.map(request?.material, (_material, matIndex) => (
                <li key={matIndex}>
                  {_material?.material?.name} จำนวน{' '}
                  <MultiUnitProdOrMatBox
                    foundMaterial={_material?.material}
                    noWrap
                    quantity={_material?.quantity}
                  />
                </li>
              ))}
            </TableCell>
            <TableCell className="border border-gray-500">
              {
                CONSTANT.MATERIAL_WITHDRAW_STATUS?.[request?.status]
                  ?.description
              }
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

MaterialWithdrawInfoTable.propTypes = {
  currentMaterialRequest: PropTypes.arrayOf(PropTypes.object),
};
