const INVENTORY_WORKFLOW = {
  INITIAL_WAITING: {
    status_code: "INITIAL_WAITING",
    description: "วัตถุดิบรับเข้ามาถูกตั้งไว้รอการผลิต / รอการเบิกไปคลังต่อไป",
  },
  WAITING: {
    status_code: "WAITING",
    description: "รอการผลิต / รอการเบิกไปคลังต่อไป",
  },
  INITIAL_IN_PROGRESS: {
    status_code: "INITIAL_IN_PROGRESS",
    description: "วัตถุดิบรับเข้ามาแล้วกำลังแปรสภาพ",
  },
  IN_PROGRESS: {
    status_code: "IN_PROGRESS",
    description: "ดำเนินการแปรสภาพวัตถุดิบ",
  },
  IN_PLACE_WAITING_TO_MOVE: {
    status_code: "IN_PLACE_WAITING_TO_MOVE",
    description: "วัตถุดิบอยู่ในที่เก็บรอการเคลื่อนย้าย",
  },
};

module.exports = INVENTORY_WORKFLOW;
