module.exports = {
  ERROR_NOT_FOUND: {
    status_code: "ERROR_NOT_FOUND",
    description: "Not Found",
  },
  CREATE_ERROR: {
    status_code: "CREATE_ERROR",
    description: "Create Something Error",
  },
  UPDATE_ERROR: {
    status_code: "UPDATE_ERROR",
    description: "Update Something Error",
  },
  INSIDE_LOOP_ERROR: {
    status_code: "INSIDE_LOOP_ERROR",
    description: "Member of loop running error",
  },
  INVALID_INPUT_ERROR: {
    status_code: "INVALID_INPUT_ERROR",
    description: "User Invalid Input",
  },
  QUEUE_ERROR: {
    status_code: "QUEUE_ERROR",
    description: "Error when process is in queue",
  },
  SERVICE_CONNECT_ERROR: {
    status_code: "SERVICE_CONNECT_ERROR",
    description: "Error on Connect to another service",
  },
  CREATER_ERROR: {
    status_code: "CREATOR_ERROR",
    description: "Not Found Task Creator",
  },
  DEPENDENCIES_CREATE_ERROR: {
    status_code: "DEPENDENCIES_CREATE_ERROR",
    description: "Create Error on Related Dependencies",
  },
};
