import React from 'react';
import { useHistory } from 'react-router';
import { Button, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import Sleeping from '../../assets/images/sleeping-svg.svg';

export default function Error({ message }) {
  const history = useHistory();
  const theme = useTheme();
  return (
    <div className="p-4 my-8">
      <div className="flex justify-center">
        <div className="w-full lg:w-1/3 my-2">
          <img src={Sleeping} className="w-64" />
          <div
            className="font-semibold text-4xl"
            style={{ color: theme.palette.primary.main }}
          >
            ขออภัย
          </div>
          <div className="text-lg my-2">
            เราไม่สามารถดำเนินการตามคำขอของท่านได้ <br />
            อาจเป็นความผิดพลาดของระบบหรือเครือข่าย
          </div>
          <div className="flex  my-4 gap-2">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                window.location.reload();
              }}
            >
              โหลดใหม่
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push('/');
              }}
            >
              กลับสู่หน้าหลัก
            </Button>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => {
                history.goBack();
              }}
            >
              กลับ
            </Button>
          </div>
        </div>
      </div>

      <div className="text-red-600">{message}</div>

      <div></div>
    </div>
  );
}

Error.propTypes = {
  message: PropTypes.string,
};

Error.defaultProps = {
  message: '',
};
