/* eslint-disable no-param-reassign */
import React from 'react';
import { Card } from '@mui/material';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';

const IMSDashboard = ({ material }) => (
  <div>
    <div className="my-4 mx-2 text-3xl font-display">ฝ่ายคลังวัตถุดิบ</div>
    <div className="flex flex-wrap  gap-4">
      <div className="w-full ">
        <Card className="p-6">
          <div className="font-display">วัตถุดิบที่คงเหลือน้อยกว่าขั้นต่ำ</div>
          <div className="text-5xl p-4 text-right">
            {_.reduce(
              material.rows,
              (result, row) => {
                if (row.warning) {
                  result += 1;
                }
                return result;
              },
              0,
            )}
            <span className="text-xl px-4">รายการ</span>
          </div>
          <hr />
          <div className="px-4">
            <div className="flex flex-wrap justify-between my-4 font-bold">
              <div className="w-3/5">ชื่อ</div>
              <div className="w-2/5 flex flex-wrap justify-between text-center">
                <div className="w-1/2">ขั้นต่ำ</div>
                <div className="w-1/2">คงเหลือ</div>
              </div>
            </div>
            <div className="max-h-64 overflow-y-scroll">
              {_.map(material.rows, (row) => {
                if (row.warning) {
                  return (
                    <div
                      key={row._id}
                      className="flex flex-wrap justify-between my-2 text-sm"
                    >
                      <div className="w-3/5 text-grey-">
                        <span className="mx-1 font-bold">{row.type_code}</span>
                        {/* {`${index + 1} ) . `} */}
                        {row.name}
                      </div>
                      <div className="w-2/5 flex flex-wrap  justify-between text-center">
                        <div className="w-1/2">{row.minimum_amount}</div>
                        <div className="w-1/2 text-red-500 ">{row.total}</div>
                      </div>
                    </div>
                  );
                }
                return <div></div>;
              })}
            </div>
          </div>

          <hr />
          <div className="mt-2">
            <Link
              to="/pms/material-order/create"
              className="text-sm text-blue-500 hover:text-blue-700"
            >
              เพิ่มคำสั่งซื้อวัตถุดิบ -{'>'}
            </Link>
          </div>
        </Card>
      </div>
    </div>
  </div>
);

IMSDashboard.propTypes = {
  material: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default IMSDashboard;
