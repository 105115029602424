import {
  QUOTATION_LOG_ALL,
  QUOTATION_LOG_DELETE,
  QUOTATION_LOG_ERROR,
  QUOTATION_LOG_GET,
  QUOTATION_LOG_LOADING,
  QUOTATION_LOG_POST,
  QUOTATION_LOG_PUT,
} from '../../actions/types';
import { BaseReducer } from '../../class';

const basedReducerObject = new BaseReducer({
  allConst: QUOTATION_LOG_ALL,
  getConst: QUOTATION_LOG_GET,
  postConst: QUOTATION_LOG_POST,
  putConst: QUOTATION_LOG_PUT,
  deleteConst: QUOTATION_LOG_DELETE,
  errorConst: QUOTATION_LOG_ERROR,
  loadingConst: QUOTATION_LOG_LOADING,
});

const initialState = {
  isLoading: true,
  isCompleted: true,
};

const QuotationLogReducer = (state = initialState, action) =>
  basedReducerObject.getReducer(state, action);

export default QuotationLogReducer;
