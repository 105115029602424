import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import { NotAuthorized } from '@iarcpsu/emanufac-components/src/components/Error';
import accessRight from '../../utils/functions/accessRight';
import {
  DetailProductType,
  EditProductTransactionType,
  EditProductType,
  EditWarehouse,
  ProductTransactionType,
  ProductType,
  SettingWMS,
  Warehouse,
} from '../../views/WMS';

export function SettingSPM() {
  const module = 'WMS';
  const prefix = '/setting/wms';
  const name = 'ตั้งค่าระบบ';
  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);

  if (accessRight(me, module, 1, availableModule)) {
    return (
      <div>
        <Route exact path={`${prefix}/product-types`}>
          <ProductType title="จัดการประเภทสินค้า" subtitle={name} />
        </Route>
        <Route exact path={`${prefix}/product-types/edit/:id`}>
          <EditProductType title="แก้ไขประเภทสินค้า" subtitle={name} />
        </Route>
        <Route exact path={`${prefix}/product-type/:id`}>
          <DetailProductType title="รายละเอียดประเภทสินค้า" subtitle={name} />
        </Route>
        <Route exact path={`${prefix}/warehouses`}>
          <Warehouse title="จัดการคลังสินค้า" subtitle={name} />
        </Route>
        <Route exact path={`${prefix}/warehouse/edit/:id`}>
          <EditWarehouse title="แก้ไขคลังสินค้า" subtitle={name} />
        </Route>
        <Route exact path={`${prefix}/transaction-types`}>
          <ProductTransactionType
            title="จัดการประเภทการดำเนินการ"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/transaction-types/edit/:id`}>
          <EditProductTransactionType
            title="แก้ไขประเภทการดำเนินการ"
            subtitle={name}
          />
        </Route>

        <Route exact path={`${prefix}/module-setting`}>
          <SettingWMS title="ตั้งค่าระบบคลังสินค้า" subtitle={name} />
        </Route>
      </div>
    );
  }
  return (
    <div>
      <Route path="*">
        <NotAuthorized />
      </Route>
    </div>
  );
}

export default SettingSPM;
