import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

import PropTypes from 'prop-types';

const RadioAndDescribeAdditionalInput = ({ control, defaultValue }) => (
  <div className="flex flex-wrap w-full">
    <div className="w-full lg:w-1/2 py-1 px-1">
      <Controller
        control={control}
        name="label_left"
        defaultValue={defaultValue?.label_left}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            size="small"
            label="ค่าที่เลือกแล้วไม่ต้องกรอกข้อความใน Textbox"
          />
        )}
      />
    </div>
    <div className="w-full lg:w-1/2 py-1 px-1">
      <Controller
        control={control}
        name="label_right"
        defaultValue={defaultValue?.label_right}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            size="small"
            label="คำที่เลือกแล้วต้องกรอกข้อความใน Textbox"
          />
        )}
      />
    </div>
    <div className="w-full  py-1 px-1">
      <Controller
        control={control}
        defaultValue={defaultValue?.label_on_additional_text}
        name="label_on_additional_text"
        render={({ field }) => (
          <TextField
            {...field}
            size="small"
            fullWidth
            label="Label ที่จะโชว์ในช่องกรอกข้อความเพิ่มเติม"
          />
        )}
      />
    </div>
  </div>
);

RadioAndDescribeAdditionalInput.propTypes = {
  control: PropTypes.object,
  defaultValue: PropTypes.object,
};

export default RadioAndDescribeAdditionalInput;
