import React, { useCallback, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Plus as PlusIcon, Trash } from 'react-feather';
import { useFieldArray } from 'react-hook-form';
import {
  Button,
  Card,
  CardContent,
  Chip,
  FormControlLabel,
  Switch,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { DASHBOARD_ELEMENT_VALUE_TYPE } from '@/utils/constants';

import { LoadingLinear } from '../../Loading';

const DashboardElementDragAndDrop = ({
  measurementType,
  control,
  resultingFuctionType,
  size,
  setSize,
}) => {
  const { fields, append, remove, update, insert } = useFieldArray({
    control,
    name: 'elements',
  });
  const [useWarp, setIsUseWarp] = useState(false);

  const onBeforeCapture = useCallback(() => {
    /* ... */
  }, []);
  const onBeforeDragStart = useCallback(() => {
    /* ... */
  }, []);
  const onDragStart = useCallback(() => {
    /* ... */
  }, []);
  const onDragUpdate = useCallback(() => {
    /* ... */
  }, []);
  const onDragEnd = useCallback((result) => {
    // the only one that is required
    console.log('Result', result);
    const destinationDropable = result?.destination?.droppableId;
    const destinationIndex =
      _.parseInt(_.last(_.split(result?.destination?.droppableId, '-'))) || 0;
    const sourceDropable = result?.source?.droppableId;
    const sourceIndex = result?.source?.index;

    if (_.startsWith(destinationDropable, 'display-dropable')) {
      // Handle For Measurement Variable
      if (_.startsWith(sourceDropable, 'measurement')) {
        const selectedMeasurement = measurementType?.rows?.[sourceIndex];
        const payload = {
          index: destinationIndex,
          name: selectedMeasurement?.name,
          source_type: DASHBOARD_ELEMENT_VALUE_TYPE.MEASUREMENT.status_code,
          measurement_type_source: selectedMeasurement,
        };
        if (_.endsWith(destinationDropable, 'start')) {
          insert(0, payload);
        } else if (_.endsWith(destinationDropable, 'end')) {
          append(payload);
        } else {
          update(destinationIndex, payload);
        }
      }

      // Handle For Resulting function Type
      else if (_.startsWith(sourceDropable, 'resulting-function-type')) {
        const selectedResultingFunction =
          resultingFuctionType?.rows?.[sourceIndex];
        const payload = {
          index: destinationIndex,
          name: selectedResultingFunction?.name,
          source_type:
            DASHBOARD_ELEMENT_VALUE_TYPE.RESULTING_FUNCTION.status_code,
          resulting_function_type_source: selectedResultingFunction,
        };
        if (_.endsWith(destinationDropable, 'start')) {
          insert(0, payload);
        } else if (_.endsWith(destinationDropable, 'end')) {
          append(payload);
        } else {
          update(destinationIndex, payload);
        }
      }

      // Handle Special Value
      else if (_.startsWith(sourceDropable, 'special-value')) {
        const specialValueIndex = _.last(_.split(result?.draggableId, '-'));
        console.log('specialValueIndex', specialValueIndex);
        const selectedSpecialValue =
          DASHBOARD_ELEMENT_VALUE_TYPE?.[specialValueIndex];
        const payload = {
          index: destinationIndex,
          name: selectedSpecialValue?.description,
          source_type: selectedSpecialValue?.status_code,
        };
        if (_.endsWith(destinationDropable, 'start')) {
          insert(0, payload);
        } else if (_.endsWith(destinationDropable, 'end')) {
          append(payload);
        } else {
          update(destinationIndex, payload);
        }
      }
    }
  }, []);
  return (
    <div>
      <DragDropContext
        onBeforeCapture={onBeforeCapture}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onBeforeDragStart={onBeforeDragStart}
        onDragUpdate={onDragUpdate}
      >
        <div className="my-2 flex flex-wrap">
          <div className="w-2/3 px-2">
            <div className="flex justify-between w-full">
              <div className="my-2 text-lg font-semibold font-display  ">
                การแสดงผล
              </div>
              <div className="self-center">
                <FormControlLabel
                  label="เลื่อนลงด้านล่าง"
                  control={
                    <Switch
                      checked={useWarp}
                      onChange={(event) => {
                        setIsUseWarp(event.target.checked);
                      }}
                    />
                  }
                />
                <Button
                  size="small"
                  variant="contained"
                  startIcon={<PlusIcon size={16} />}
                  onClick={() => append({})}
                >
                  เพิ่ม
                </Button>
              </div>
            </div>
            <div
              className={`flex ${
                useWarp ? 'flex-wrap' : ''
              }  gap-2 w-full overflow-x-auto`}
            >
              <Droppable droppableId="display-dropable-start" type="PERSON">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    className="bg-gray-200 rounded-md"
                    style={{
                      backgroundColor: snapshot.isDraggingOver
                        ? 'lightGray'
                        : '',
                    }}
                    {...provided.droppableProps}
                  >
                    <div className="py-2   w-12 h-full  flex flex-col ">
                      <div className="my-auto text-gray-400  ">
                        <PlusIcon className="mx-auto  " />
                      </div>
                    </div>
                  </div>
                )}
              </Droppable>
              {_.map(fields, (eachField, index) => (
                <div
                  key={eachField.id}
                  className="border bg-gray-300 rounded-md h-full w-48"
                >
                  <div className="flex justify-end">
                    <Button
                      size="small"
                      color="primary"
                      startIcon={<Trash size={16} />}
                      onClick={() => remove(index)}
                    >
                      ลบ
                    </Button>
                  </div>
                  <Droppable
                    droppableId={`display-dropable-${index}`}
                    type="PERSON"
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={{
                          backgroundColor: snapshot.isDraggingOver
                            ? 'lightGray'
                            : '',
                        }}
                        {...provided.droppableProps}
                      >
                        <div className="py-2  h-48 rounded-md">
                          {eachField?.name ? (
                            <div className="p-4 h-full bg-white shadow-md rounded-md">
                              <div className="my-2">
                                <Chip
                                  size="small"
                                  label={
                                    DASHBOARD_ELEMENT_VALUE_TYPE?.[
                                      eachField?.source_type
                                    ]?.description || ''
                                  }
                                />
                              </div>
                              <div>
                                <span className="font-semibold text-sm">
                                  {
                                    eachField?.measurement_type_source
                                      ?.type_code
                                  }{' '}
                                </span>
                                <div>{eachField?.name}</div>
                              </div>
                            </div>
                          ) : (
                            <div className="p-4 w-full text-center text-gray-400 font-semibold text-xl">
                              ว่าง
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </Droppable>
                </div>
              ))}
              <Droppable droppableId="display-dropable-end" type="PERSON">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    className="bg-gray-200 rounded-md"
                    style={{
                      backgroundColor: snapshot.isDraggingOver
                        ? 'lightGray'
                        : '',
                    }}
                    {...provided.droppableProps}
                  >
                    <div className="py-2   w-12 h-full  flex flex-col ">
                      <div className="my-auto text-gray-400  ">
                        <PlusIcon className="mx-auto  " />
                      </div>
                    </div>
                  </div>
                )}
              </Droppable>
            </div>
          </div>
          <div className="w-1/3 px-2">
            <div className="my-1">
              <Card>
                <CardContent>
                  <div className="text-lg font-semibold font-display  ">
                    ค่าพิเศษ
                  </div>
                  <Droppable droppableId="special-value-dropable" type="PERSON">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {provided.placeholder}
                        <div className="grid lg:grid-cols-3">
                          {_.map(
                            _.filter(
                              DASHBOARD_ELEMENT_VALUE_TYPE,
                              (eachType) => eachType.special === true,
                            ),
                            (eachRow, index) => (
                              <Draggable
                                draggableId={`draggable-special-${eachRow.status_code}`}
                                index={index}
                              >
                                {(dragableProvider) => (
                                  <div
                                    ref={dragableProvider.innerRef}
                                    {...dragableProvider.draggableProps}
                                    {...dragableProvider.dragHandleProps}
                                  >
                                    <div
                                      key={index}
                                      className="p-2 rounded-sm border m-1 shadow-md"
                                    >
                                      {eachRow?.description}
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ),
                          )}
                        </div>
                      </div>
                    )}
                  </Droppable>{' '}
                </CardContent>
              </Card>
            </div>
            <div className="my-1">
              <Card>
                <CardContent>
                  <div className="text-lg font-semibold font-display  ">
                    ตัวแปรวัดค่า
                  </div>
                  {measurementType?.isLoading && <LoadingLinear />}
                  <Droppable droppableId="measurement-dropable" type="PERSON">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {provided.placeholder}
                        <div className="grid lg:grid-cols-3">
                          {_.map(measurementType?.rows, (eachRow, index) => (
                            <Draggable
                              draggableId={`draggable-${index}`}
                              index={index}
                            >
                              {(dragableProvider) => (
                                <div
                                  ref={dragableProvider.innerRef}
                                  {...dragableProvider.draggableProps}
                                  {...dragableProvider.dragHandleProps}
                                >
                                  <div
                                    key={index}
                                    className="p-2 rounded-sm border m-1 shadow-md"
                                  >
                                    <span className="font-semibold text-sm">
                                      {eachRow?.type_code}{' '}
                                    </span>
                                    {eachRow?.name}
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        </div>
                      </div>
                    )}
                  </Droppable>{' '}
                  {size < measurementType?.total && (
                    <div className="my-2 flex justify-center">
                      <Button
                        size="small"
                        variant="contained"
                        color="inherit"
                        onClick={() => setSize(size + 10)}
                      >
                        เพิ่ม{' '}
                        {measurementType?.total - size > 10
                          ? '10 รายการ'
                          : `${measurementType?.total - size} รายการ`}
                      </Button>
                    </div>
                  )}
                </CardContent>
              </Card>
            </div>
            <div className="my-1">
              <Card>
                <CardContent>
                  <div className="text-lg font-semibold font-display  ">
                    ค่าวัดผล{' '}
                  </div>
                  {resultingFuctionType?.isLoading && <LoadingLinear />}
                  <Droppable
                    droppableId="resulting-function-type-dropable"
                    type="PERSON"
                  >
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {provided.placeholder}
                        <div className="grid lg:grid-cols-3">
                          {_.map(
                            resultingFuctionType?.rows,
                            (eachRow, index) => (
                              <Draggable
                                draggableId={`draggable-resultingfunctiontype-${index}`}
                                index={index}
                              >
                                {(dragableProvider) => (
                                  <div
                                    ref={dragableProvider.innerRef}
                                    {...dragableProvider.draggableProps}
                                    {...dragableProvider.dragHandleProps}
                                  >
                                    <div
                                      key={index}
                                      className="p-2 rounded-sm border m-1 shadow-md min-h-full"
                                    >
                                      {eachRow?.name}{' '}
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ),
                          )}
                        </div>
                      </div>
                    )}
                  </Droppable>
                  {size < resultingFuctionType?.total && (
                    <div className="flex justify-center my-2 ">
                      <Button
                        size="small"
                        variant="contained"
                        color="inherit"
                        onClick={() => setSize(size + 10)}
                      >
                        เพิ่มอีก{' '}
                        {resultingFuctionType?.total - size > 10
                          ? '10 รายการ'
                          : `${resultingFuctionType?.total - size} รายการ`}
                      </Button>
                    </div>
                  )}
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </DragDropContext>
    </div>
  );
};

DashboardElementDragAndDrop.propTypes = {
  measurementType: PropTypes.shape({
    total: PropTypes.number,
    rows: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
  }),
  control: PropTypes.object,
  resultingFuctionType: PropTypes.shape({
    total: PropTypes.number,
    rows: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
  }),
  size: PropTypes.number,
  setSize: PropTypes.func,
};

export default DashboardElementDragAndDrop;
