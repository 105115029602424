import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

const ParagraphInput = ({
  control,
  measurementType,
  measurementControllerName = '',
}) => (
  <div>
    <div>
      <Controller
        control={control}
        name={`${measurementControllerName}.value`}
        render={({ field }) => (
          <TextField
            {...field}
            label={measurementType?.label}
            required={measurementType?.required}
            fullWidth
            multiline
            rows={3}
          />
        )}
      />
    </div>
  </div>
);

ParagraphInput.propTypes = {
  control: PropTypes.object,
  measurementType: PropTypes.object,
  measurementControllerName: PropTypes.string,
};

export default ParagraphInput;
