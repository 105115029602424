import React from 'react';
import Chart from 'react-apexcharts';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';

import { Error } from '../Error';

export default function PieChart({
  labels,
  series,
  height = 400,
  donut = false,
  anotherOptions = {},
}) {
  const options = {
    labels,
    yaxis: {
      labels: {
        formatter: (value) => parseFloat(value).toFixed(2),
      },
    },
    ...anotherOptions,
  };

  const ErrorFallback = ({ error }) => <Error message={error?.message} />;

  return (
    <div className="my-2 justify-center">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Chart
          type={donut ? 'donut' : 'pie'}
          series={series}
          options={options}
          height={height}
        />
      </ErrorBoundary>
    </div>
  );
}

PieChart.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
  series: PropTypes.arrayOf(PropTypes.number),
  height: PropTypes.number,
  donut: PropTypes.bool,
  anotherOptions: PropTypes.object,
};
