import { BaseAction } from '../../class';
import {
  CUSTOMER_COMMENT_ALL,
  CUSTOMER_COMMENT_DELETE,
  CUSTOMER_COMMENT_ERROR,
  CUSTOMER_COMMENT_GET,
  CUSTOMER_COMMENT_LOADING,
  CUSTOMER_COMMENT_POST,
  CUSTOMER_COMMENT_PUT,
} from '../types';

const customerCommentAction = new BaseAction('customer-comment', {
  allConst: CUSTOMER_COMMENT_ALL,
  getConst: CUSTOMER_COMMENT_GET,
  postConst: CUSTOMER_COMMENT_POST,
  putConst: CUSTOMER_COMMENT_PUT,
  deleteConst: CUSTOMER_COMMENT_DELETE,
  errorConst: CUSTOMER_COMMENT_ERROR,
  loadingConst: CUSTOMER_COMMENT_LOADING,
});

export const customerCommentCreate = (data) =>
  customerCommentAction.create(data);
export const customerCommentAll = ({ name, page, size, ...query }) =>
  customerCommentAction.getAllData({ name, page, size, ...query });
export const customerCommentGet = (id) => customerCommentAction.getOneData(id);
export const customerCommentPut = (id, data) =>
  customerCommentAction.editOne(id, data);
export const customerCommentDelete = (id) =>
  customerCommentAction.deleteOne(id);
export const customerCommentStateSet = (data) =>
  customerCommentAction.stateSet(data);
export const customerCommentStateOneSet = (data) =>
  customerCommentAction.stateOneSet(data);
export const customerCommentReset = () => customerCommentAction.reset();
export const customerCommentError = () => customerCommentAction.onError();
