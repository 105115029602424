import React from 'react';
import { useSelector } from 'react-redux';
import { INVENTORY_WORKFLOW } from '@iarcpsu/emanufac-constant';
import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import _ from 'lodash';
import PropTypes from 'prop-types';

import EmployeeOnTimePairDisplay from './EmployeeOnTimePairDisplay';

dayjs.extend(durationPlugin);
dayjs.extend(relativeTime);

const EachEmployeeTimePair = ({ timePair, materialStockLotGroupByDated }) => {
  const startLots = materialStockLotGroupByDated[timePair?.[0]];
  const endLots = materialStockLotGroupByDated[timePair?.[1]];

  const information = useSelector((state) => state.information);
  const pamsSetting = information?.setting?.pams;

  const representStartLot = _.first(startLots);
  const representEndLot = _.last(endLots);

  const findWorkType = () => {
    if (representStartLot?.place?._id === representEndLot?.place?._id) {
      // รับวัตถุดิบเข้า
      if (_.isEmpty(representStartLot?.previous_lot)) {
        // ยังได้วัตถุดิบชิ้นเดียวกัน
        if (
          representStartLot?.material?._id === representEndLot?.material?._id
        ) {
          return INVENTORY_WORKFLOW.INITIAL_WAITING.status_code;
        }

        return INVENTORY_WORKFLOW.INITIAL_IN_PROGRESS.status_code;
      }
      // ยังได้วัตถุดิบชิ้นเดียวกัน
      if (representStartLot?.material?._id === representEndLot?.material?._id) {
        return INVENTORY_WORKFLOW.WAITING.status_code;
      }

      return INVENTORY_WORKFLOW.IN_PROGRESS.status_code;

      // วัตถุดิบ มาจากขั้นตอนอื่น
    }
    return INVENTORY_WORKFLOW.IN_PLACE_WAITING_TO_MOVE.status_code;
  };

  if (pamsSetting?.wbm?.costed_procedure?.[findWorkType()] === false) {
    return <div></div>;
  }

  return (
    <div className="py-2 px-1">
      <EmployeeOnTimePairDisplay
        foundWorkType={findWorkType()}
        representStartLot={representStartLot}
        representEndLot={representEndLot}
        selectedEmployee={representEndLot?.employee}
        timePair={timePair}
        timeUsage={representEndLot?.duration}
        information={information}
      />
      {!_.isEmpty(representEndLot?.another_employees) && (
        <div className="ml-4">
          <div className="my-4 font-semibold">พนักงานอื่นๆ ที่มีส่วนร่วม</div>
          {_.map(representEndLot?.another_employees, (each, index) => (
            <EmployeeOnTimePairDisplay
              foundWorkType={findWorkType()}
              representStartLot={representStartLot}
              representEndLot={representEndLot}
              selectedEmployee={each.employee}
              timePair={timePair}
              timeUsage={representEndLot?.duration}
              key={index}
              isAnotherEmployee={true}
              amountOfEmployee={each.amount}
              information={information}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default EachEmployeeTimePair;

EachEmployeeTimePair.propTypes = {
  timePair: PropTypes.arrayOf(PropTypes.string),
  materialStockLotGroupByDated: PropTypes.object,
};
