import React from 'react';
import {
  Archive as ArchiveIcon,
  BarChart as BarChartIcon,
  DollarSign,
  FilePlus as FilePlusIcon,
  FileText as FileTextIcon,
  Folder as FolderIcon,
  HardDrive as HardDriveIcon,
  Package as PackageIcon,
  Repeat as RepeatIcon,
  Sliders as SlidersIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  // BarChart as BarChartIcon,
  Users as UsersIcon,
} from 'react-feather';

export const menuList = [
  {
    moduleName: 'DASHBOARD',
    link: '/dashboard',
    icon: <BarChartIcon className="text-white" size={48} />,
    title: 'แดชบอร์ด',
    extendedStyle: 'bg-gray-600',
  },
  {
    moduleName: 'PMS',
    link: '/pms/material-order',
    icon: <FilePlusIcon className="text-white" size={48} />,
    title: 'ฝ่ายจัดซื้อ',
    extendedStyle: 'bg-blue-500 ',
  },
  {
    moduleName: 'SPM',
    link: '/spm',
    icon: <FileTextIcon className="text-white" size={48} />,
    title: 'ฝ่ายขาย',
    extendedStyle: 'bg-red-600 ',
  },
  {
    moduleName: 'MMS',
    link: '/mms/manufacturing-orders',
    icon: <RepeatIcon className="text-white" size={48} />,
    title: 'การผลิต',
    extendedStyle: 'bg-green-600 ',
  },
  {
    moduleName: 'IMS',
    link: '/ims/material-stock',
    icon: <PackageIcon className="text-white" size={48} />,
    title: 'คลังวัตถุดิบ',
    extendedStyle: 'bg-indigo-600',
  },
  {
    moduleName: 'WMS',
    link: '/wms/product-stock',
    icon: <ArchiveIcon className="text-white" size={48} />,
    title: 'คลังสินค้า',
    extendedStyle: 'bg-green-400',
  },
  {
    moduleName: 'CRM',
    link: '/crm/customers',
    icon: <UsersIcon className="text-white " size={48} />,
    title: 'ลูกค้าสัมพันธ์',
    extendedStyle: 'bg-yellow-500 ',
  },
  {
    moduleName: 'KM',
    link: '/km',
    icon: <FolderIcon className="text-white " size={48} />,
    title: 'คลังข้อมูลองค์กร',
    extendedStyle: 'bg-purple-500',
  },
  {
    moduleName: 'MAMS',
    link: '/mams',
    icon: <HardDriveIcon className="text-white" size={48} />,
    title: 'เครื่องจักร / รางการผลิต',
    extendedStyle: 'bg-pink-800',
  },
  // {
  //   moduleName: 'PAMS',
  //   link: '/pams',
  //   icon: <DollarSign className="text-white" size={48} />,
  //   title: 'เงินเดือนและการจ่ายค่าตอบแทน',
  //   extendedStyle: 'bg-blue-800',
  // },
  {
    moduleName: 'CEM',
    link: '/cem',
    icon: <DollarSign className="text-white" size={48} />,
    title: 'ประมาณการต้นทุน',
    extendedStyle: 'bg-green-800',
  },
  {
    moduleName: 'HRMS',
    link: '/hrms/employee',
    icon: <UserPlusIcon className="text-white" size={48} />,
    title: 'ทรัพยากรบุคคล',
    extendedStyle: 'bg-pink-600 ',
  },
  {
    moduleName: 'SETTING',
    link: '/setting',
    icon: <SlidersIcon className="text-white" size={48} />,
    title: 'การตั้งค่า',
    extendedStyle: 'bg-black',
  },
  {
    moduleName: 'PROFILE',
    link: '/profile',
    icon: <UserIcon className="text-white" size={48} />,
    title: 'โปรไฟล์',
    extendedStyle: 'bg-theme-800',
  },
];

export default menuList;
