export const PROCESS_TEMPLATE_TYPE = {
  SEQUENCIAL: {
    status_code: 'SEQUENCIAL',
    description: 'แบบเป็นลำดับขั้นตอน',
  },
  MATERIAL_SPLITTING: {
    status_code: 'MATERIAL_SPLITTING',
    description: 'แบบการกระจายของวัตถุดิบ',
  },
};

export default PROCESS_TEMPLATE_TYPE;
