import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Card } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { BackButton } from '@iarcpsu/emanufac-components/src/components/Button';
import { Error } from '@iarcpsu/emanufac-components/src/components/Error';
import { Loading } from '@iarcpsu/emanufac-components/src/components/Loading';
import { ViewTitle } from '@iarcpsu/emanufac-components/src/components/ViewTitle';
import * as actions from '../../redux/actions';

export default function DetailMaterialSource({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const materialSource = useSelector((state) => state.materialSource);

  useEffect(() => {
    dispatch(actions.materialSourceGet(id));
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (materialSource.isLoading || materialSource.rows) {
    return <Loading />;
  }
  if (!materialSource.isLoading && materialSource.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <div>
          <Card className="p-4 ">
            <div className="flex flex-wrap divide-y">
              <div className="w-full py-4 text-lg font-semibold   font-display">
                {'รายละเอียด'}
              </div>

              <div className="w-1/2 py-4 px-2 font-semibold  font-display ">
                ชื่อ
              </div>
              <div className="w-1/2 py-4 ">{materialSource?.name}</div>
              <div className="w-1/2 py-4 px-2 font-semibold  font-display ">
                รหัสเฉพาะแหล่งวัตถุดิบ / เรือ / รถบรรทุก
              </div>
              <div className="w-1/2 py-4 ">{materialSource?.type_code}</div>
              <div className="w-1/2 py-4 px-2 font-semibold  font-display ">
                รหัสรวม
              </div>
              <div className="w-1/2 py-4 ">{materialSource?.overall_code}</div>
              <div className="w-1/2 py-4 px-2 font-semibold  font-display ">
                คู่ค้า
              </div>
              <div className="w-1/2 py-4 ">
                {' '}
                {materialSource?.supplier?.type_code}{' '}
                {materialSource?.supplier?.name}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold  font-display ">
                สถานที่
              </div>
              <div className="w-1/2 py-4 ">{materialSource?.source_place}</div>
              <div className="w-1/2 py-4 px-2 font-semibold  font-display ">
                ทะเบียน
              </div>
              <div className="w-1/2 py-4 ">
                {materialSource?.licence_number}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold  font-display ">
                รายละเอียดเพิ่มเติม
              </div>
              <div className="w-1/2 py-4 ">{materialSource?.desctiption}</div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailMaterialSource.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailMaterialSource.defaultProps = {
  title: '',
  subtitle: '',
};
