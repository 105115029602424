import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  BackButton,
  Loading,
  PlaceLaborCostForm,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import Error from '@iarcpsu/emanufac-components/src/components/Error';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import { config } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';
import { useQuery } from '@/utils/functions';

/**
 * @function CreatePriceLaborCost
 * @description Display a collections or a list of CreatePriceLaborCost from database
 */

export default function CreatePlaceLaborCost({ title, subtitle }) {
  const dispatch = useDispatch();
  const place = useSelector((state) => state.place);
  const history = useHistory();
  const params = useParams();
  const { handleSubmit, watch, control, setValue } = useForm({
    defaultValues: {
      is_daily: true,
    },
  });

  const browserQuery = useQuery();

  const query = gql`
    query FindForPlaceLaborCost($placeInput: PlaceInput) {
      findPlaces(input: $placeInput) {
        rows {
          _id
          name
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        placeInput: { page: 1, size: config.maxFetchSize },
      });
      const placeResult = queryResult?.findPlaces;
      dispatch(actions.placeStateSet(placeResult));
    } catch (error) {
      console.error('Cannot Get Place', error);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const handleCreatePlaceLaborCost = async (data) => {
    try {
      const payload = data;
      payload.start_date = dayjs(data.start_date).startOf('date').toDate();
      if (data?.is_daily) {
        payload.end_date = dayjs(data.start_date).endOf('date').toDate();
      }
      await dispatch(actions.placeLaborCostCreate(payload));
      Swal.fire({
        icon: 'success',
        title: 'สร้างรายการใหม่สำเร็จ',
      }).then(() => {
        history.goBack();
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'ไม่สามารถสร้างรายการใหม่ได้',
        text: error?.message,
      });
    }
  };

  useEffect(() => {
    if (
      browserQuery.get('addFromSearchQuery') === true ||
      browserQuery.get('addFromSearchQuery') === 'true'
    ) {
      const placeId = browserQuery.get('place');
      const isDaily = browserQuery.get('is_daily') === 'true';
      const startDate = dayjs(browserQuery.get('start_date')).toDate();
      const foundPlace = _.find(
        place?.rows,
        (eachPlace) => eachPlace._id === placeId,
      );
      setValue('places', [foundPlace]);
      setValue('is_daily', isDaily);
      setValue('start_date', startDate);
    }

    return () => {};
  }, [place]);

  console.log('Watch Places', watch('places'));

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (place?.isLoading) {
    return <Loading />;
  }

  if (place?.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <form onSubmit={handleSubmit(handleCreatePlaceLaborCost)}>
            <PlaceLaborCostForm control={control} place={place} watch={watch} />
            <div className="flex justify-end my-2">
              <Button type="submit" variant="contained">
                บันทึก
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return <Error />;
}

CreatePlaceLaborCost.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreatePlaceLaborCost.defaultProps = {
  title: '',
  subtitle: '',
};
