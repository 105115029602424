import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { INVENTORY_WORKFLOW } from '@iarcpsu/emanufac-constant';
import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { getEmployeePayrollUnitCostOnTimeWithAPI } from '@/utils/functions';

dayjs.extend(durationPlugin);
dayjs.extend(relativeTime);

const EmployeeTimePairInEquation = ({
  timePair,
  materialStockLotGroupByDated,
  employee,
}) => {
  const timeUsage = dayjs(timePair?.[1]).diff(dayjs(timePair?.[0]), 'minute');
  const startLots = materialStockLotGroupByDated[timePair?.[0]];
  const endLots = materialStockLotGroupByDated[timePair?.[1]];

  const information = useSelector((state) => state.information);
  const pamsSetting = information?.setting?.pams;
  const [unitCost, setUnitCost] = useState(0);

  const representStartLot = _.first(startLots);
  const representEndLot = _.last(endLots);

  const representEmployee = representStartLot?.employee;

  const getEmployeePayrollAdjustment = async () => {
    try {
      const foundedUnitCost = await getEmployeePayrollUnitCostOnTimeWithAPI({
        representEmployee,
        timePair,
        information,
      });
      // console.log('Founded Unit Cost', foundedUnitCost);
      setUnitCost(foundedUnitCost);
    } catch (error) {
      console.error('Cannot Get Employee Payroll Adjustment', error);
    }
  };

  useEffect(() => {
    getEmployeePayrollAdjustment();

    return () => {};
  }, [representEmployee]);

  const foundedEmployeeIndex = _.findIndex(
    employee?.rows,
    (each) => each?._id === representEmployee?._id,
  );

  const findWorkType = () => {
    if (representStartLot?.place?._id === representEndLot?.place?._id) {
      // รับวัตถุดิบเข้า
      if (_.isEmpty(representStartLot?.previous_lot)) {
        // ยังได้วัตถุดิบชิ้นเดียวกัน
        if (
          representStartLot?.material?._id === representEndLot?.material?._id
        ) {
          return INVENTORY_WORKFLOW.INITIAL_WAITING.status_code;
        }

        return INVENTORY_WORKFLOW.INITIAL_IN_PROGRESS.status_code;
      }
      // ยังได้วัตถุดิบชิ้นเดียวกัน
      if (representStartLot?.material?._id === representEndLot?.material?._id) {
        return INVENTORY_WORKFLOW.WAITING.status_code;
      }

      return INVENTORY_WORKFLOW.IN_PROGRESS.status_code;

      // วัตถุดิบ มาจากขั้นตอนอื่น
    }
    return INVENTORY_WORKFLOW.IN_PLACE_WAITING_TO_MOVE.status_code;
  };

  if (pamsSetting?.wbm?.costed_procedure?.[findWorkType()] === false) {
    return <div></div>;
  }
  return (
    <div className="py-2 px-1">
      {parseFloat(unitCost).toFixed(2)} *{timeUsage}{' '}
      <span className="font-semibold text-blue-700">
        e<sub>{foundedEmployeeIndex}</sub>
      </span>{' '}
      +
    </div>
  );
};

export default EmployeeTimePairInEquation;

EmployeeTimePairInEquation.propTypes = {
  timePair: PropTypes.arrayOf(PropTypes.string),
  materialStockLotGroupByDated: PropTypes.object,
};
