import React from 'react';
import PropTypes from 'prop-types';

import dipromLogo from '../../assets/images/diprom.png';
import dipromGo from '../../assets/images/dipromgo.jpg';
import ButtonMenuList from '../Button/ButtonMenuList';

function Diprom4({ me, availableModule, information }) {
  return (
    <div className="min-h-screen bg-gradient-to-t from-green-400 to-white">
      <div className="lg:px-32 my-24 text-center lg:text-left">
        <div className=" flex-wrap items-center my-10 hidden md:flex">
          <div className="w-full lg:w-1/2 pr-10">
            <div className="flex flex-wrap w-full justify-end items-center">
              <div className="w-full lg:w-1/2 px-2">
                <div className="text-xl">{information?.description}</div>
                <div className="text-xl font-semibold">
                  {information?.owner?.name}
                </div>
              </div>
              <div className="w-full lg:w-1/3 items-center flex justify-center lg:justify-start">
                <img
                  src={information?.logo?.url}
                  alt="company-logo"
                  className="h-32"
                />
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <div className="flex flex-wrap items-center">
              <div className="w-full lg:w-1/4  flex justify-center lg:justify-start">
                <div>
                  <img src={dipromLogo} alt="diprom" className="h-32" />
                </div>
              </div>
              <div className="w-full lg:w-3/4">
                <div className="text-lg my-2">
                  <b>สนับสนุนการพัฒนาระบบโดย</b> <br />{' '}
                  {information?.sponsor?.name}
                </div>{' '}
                <div className="">ดีพร้อมโต โต้ได้โตไว โตไกล โตให้ยั่งยืน</div>
                <div>
                  <a
                    href="https://www.diprom.go.th"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.diprom.go.th
                  </a>
                </div>
                <div className="my-2 text-xs">
                  พัฒนาระบบโดย ศูนย์วิจัยระบบอัตโนมัติอัจฉริยะ คณะวิศวกรรมศาสตร์
                  มหาวิทยาลัยสงขลานครินทร์
                </div>
              </div>
            </div>{' '}
          </div>
        </div>

        <div className="container mr-auto ml-auto">
          <ButtonMenuList me={me} availableModule={availableModule} />
        </div>
      </div>
    </div>
  );
}

export default Diprom4;

Diprom4.propTypes = {
  me: PropTypes.object,
  availableModule: PropTypes.arrayOf(PropTypes.object),
  information: PropTypes.object,
};
