import React from 'react';
import { TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

function DesktopDateTimepicker({
  label,
  value,
  setValue = () => {},
  onChange = () => {},
  inputFormat = 'DD MMM BBBB HH:mm',
  size = 'normal',
  fullWidth = true,
}) {
  const handleChange = (inputValue) => {
    setValue(inputValue);
    onChange(inputValue);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label={label}
        value={dayjs(value)}
        onChange={handleChange}
        inputFormat={inputFormat}
        slotProps={{
          textField: {
            fullWidth,
            size,
          },
        }}
      />
    </LocalizationProvider>
  );
}

DesktopDateTimepicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.object,
  setValue: PropTypes.func,
  onChange: PropTypes.func,
  inputFormat: PropTypes.string,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default DesktopDateTimepicker;
