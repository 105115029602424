/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import { gql } from 'graphql-request';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  ProductAndMaterialSelectForm,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { app as appConfig } from '../../../configs';
import graphqlClient from '../../../configs/graphqlClient';
import * as actions from '../../../redux/actions';

/**
 * @function MainDetailMO
 * @description Display a collections or a list of MainDetailMO from database
 */

export default function CreateProcessInMO({ title, subtitle }) {
  const dispatch = useDispatch();
  const params = useParams();
  const manufacturingOrder = useSelector((state) => state.manufacturingOrder);
  const information = useSelector((state) => state.information);
  const material = useSelector((state) => state.material);
  const product = useSelector((state) => state.product);
  const processTemplate = useSelector((state) => state.processTemplate);
  const machine = useSelector((state) => state.machine);
  const me = useSelector((state) => state.me);
  const customer = useSelector((state) => state.customer);
  const history = useHistory();

  const {
    control,
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
  } = useForm({ defaultValues: { processes: [{}] } });

  const query = gql`
    query FindMainDetailMO(
      $input: ManufacturingOrderInput
      $materialInput: MaterialInput
      $productInput: ProductInput
      $processTemplateInput: ProcessTemplateInput
      $machineInput: MachineInput
      $customerInput: CustomerInput
    ) {
      findOneManufacturingOrder(input: $input) {
        _id
        running_number
        prefix
        start_date
        expected_date
        completed
        deleted
        remark
        delete_note
        customer {
          type_code
          name
        }
      }
      findMaterials(input: $materialInput) {
        rows {
          _id
          type_code
          name
          unit
          use_unit_conversion
          unit_input {
            _id
            short_sign
          }
          unit_output {
            _id
            short_sign
          }
          conversion_from {
            unit_a
            unit_b
            conversion_factor
          }
          conversion_to {
            unit_a
            unit_b
            conversion_factor
          }
        }
      }
      findProducts(input: $productInput) {
        rows {
          _id
          type_code
          name
          unit
          use_unit_conversion
          unit_input {
            _id
            short_sign
          }
          unit_output {
            _id
            short_sign
          }
          conversion_from {
            unit_a
            unit_b
            conversion_factor
          }
          conversion_to {
            unit_a
            unit_b
            conversion_factor
          }
          steps {
            name
            materials {
              material {
                _id
                name
                type_code
              }
            }
          }
        }
      }
      findProcessTemplates(input: $processTemplateInput) {
        rows {
          _id
          name
          steps {
            name
            materials {
              material {
                _id
                name
                type_code
              }
            }
          }
        }
      }
      findMachines(input: $machineInput) {
        rows {
          _id
          name
        }
      }
      findCustomers(input: $customerInput) {
        rows {
          _id
          type_code
          name
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    dispatch(actions.manufacturingOrderReset());
    try {
      const queryResult = await graphqlClient.request(query, {
        input: {
          enableFetchInside: false,
          fetchCurrentStep: false,
          fetchCustomer: false,
          fetchMaterial: false,
          fetchProduct: true,
          id: params.id,
          fetchProcess: false,
        },
        materialInput: {
          page: 1,
          size: appConfig.maxFetchSize,
          fetchStockLot: false,
          status: '',
        },
        productInput: {
          page: 1,
          size: appConfig.maxFetchSize,
          fetchStockLot: false,
          status: '',
        },
        processTemplateInput: {
          page: 1,
          size: appConfig.maxFetchSize,
        },
        machineInput: {
          page: 1,
          size: appConfig.maxFetchSize,
        },
        customerInput: {
          page: 1,
          size: appConfig.maxFetchSize,
        },
      });
      const manufacturingOrderData = queryResult?.findOneManufacturingOrder;
      const materialData = queryResult?.findMaterials;
      const productData = queryResult?.findProducts;
      const processTemplateData = queryResult?.findProcessTemplates;
      const machineData = queryResult?.findMachines;
      const customerData = queryResult?.findCustomers;

      dispatch(actions.manufacturingOrderStateOneSet(manufacturingOrderData));
      dispatch(actions.materialStateSet(materialData));
      dispatch(actions.productStateSet(productData));
      dispatch(actions.processTemplateStateSet(processTemplateData));
      dispatch(actions.machineStateSet(machineData));
      dispatch(actions.customerStateSet(customerData));
    } catch (err) {
      console.error('Error On Fetch Inside Process', err);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const handleAddProcess = async (data) => {
    try {
      const processPayload = {
        ...data?.processes?.[0],
        manufacturing_order: params.id,
      };
      console.log('Add Process Payload', processPayload);
      await dispatch(actions.processCreate(processPayload));
      alert('สร้างการผลิตสำเร็จ');
      history.goBack();
    } catch (error) {
      console.error('Error On Create New Process', error);
      alert(`เพิ่มการผลิตไม่สำเร็จ ${error?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (manufacturingOrder.isLoading) {
    return <Loading />;
  }
  if (!manufacturingOrder.isLoading && manufacturingOrder.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <BackButton />
        </div>
        <div className="my-2">
          <Card>
            <CardContent>
              <form onSubmit={handleSubmit(handleAddProcess)}>
                <ProductAndMaterialSelectForm
                  control={control}
                  customer={customer}
                  information={information}
                  errors={errors}
                  isReady={true}
                  machine={machine}
                  material={material}
                  processTemplate={processTemplate}
                  product={product}
                  setValue={setValue}
                  watch={watch}
                  disableAddButton
                />
                <div className="flex justify-end">
                  <Button variant="contained" type="submit">
                    บันทึก
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

CreateProcessInMO.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateProcessInMO.defaultProps = {
  title: '',
  subtitle: '',
};
