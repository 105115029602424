import { RATING } from '@iarcpsu/emanufac-constant';
import _ from 'lodash';

const calculateRating = (rating) => {
  const foundRating = _.find(
    RATING,
    (eachRating) =>
      rating >= eachRating.rangeStart && rating < eachRating.rangeEnd,
  );

  return foundRating;
};

export default calculateRating;
