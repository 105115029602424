import React from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import MultiUnitProdOrMatBox from '../../Box/Rendering/MultiUnitProdOrMatBox';

import SplittingProcessLineCreation from './SplittingProcessLineCreation';

function SplittingProcessForm({
  nextStep,
  allSteps,
  control,
  watch,
  setValue,
}) {
  const watchedMaterial = watch('materials');
  const startIndex = nextStep?.index;
  const endStep = _.find(allSteps, (step) => step?.is_process_merging_point);
  const endIndex = endStep?.index;
  const filterStep = _.filter(
    allSteps,
    (step) => step?.index >= startIndex && step?.index <= endIndex,
  );

  return (
    <div className="m-2 p-2">
      <div>
        <b>ขั้นตอนต่อไป:</b> {nextStep?.name}
        <div>มีการแยกขั้นตอนต่อไปนี้มาเป็นไลน์ผลิตที่คู่ขนานกัน</div>
        <div className="p-4 border rounded-md my-2">
          <Stepper alternativeLabel>
            {_.map(filterStep, (_productStep, index) => (
              <Step key={index}>
                <StepLabel>
                  <h5 className="font-semibold font-display ">
                    {_productStep?.name}
                  </h5>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>{' '}
        <div className="my-2">
          <div>วัตถุดิบที่แต่ละไลน์จะได้รับ</div>
          <Controller
            control={control}
            name="material_after_splitting"
            render={({ field }) => (
              <RadioGroup {...field}>
                <FormControlLabel
                  label="แบ่งเท่าๆ กัน"
                  value="EQUAL"
                  control={<Radio />}
                />
                <FormControlLabel
                  label="การแยกตามความต้องการ"
                  value="CUSTOM"
                  control={<Radio />}
                />
              </RadioGroup>
            )}
          />
        </div>
        <div className="my-2">
          <div>แต่ละไลน์จะได้รับ</div>
          {watch('material_after_splitting') === 'EQUAL' && (
            <div>
              <div className="my-2">
                <div className="font-semibold">ผลจากการแยกไลน์</div>
                <Controller
                  control={control}
                  name="amount_of_splitting"
                  render={({ field }) => (
                    <div className="my-2 flex gap-2">
                      <label className="block">แยกออกมาเป็น</label>
                      <TextField {...field} type="number" size="small" />
                      <label className="block">ไลน์</label>
                    </div>
                  )}
                />
              </div>
              <ul className="list-disc list-inside">
                {_.map(watchedMaterial, (each, index) => (
                  <li key={index} className="flex gap-2">
                    <b>{each?.material?.name}</b> จำนวน{' '}
                    <MultiUnitProdOrMatBox
                      foundMaterial={each?.material}
                      quantity={
                        each?.end /
                        parseFloat(watch('amount_of_splitting') || 1)
                      }
                      noWrap
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}

          {watch('material_after_splitting') === 'CUSTOM' && (
            <div>
              <SplittingProcessLineCreation
                control={control}
                watchedMaterial={watchedMaterial}
                watch={watch}
                setValue={setValue}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SplittingProcessForm;

SplittingProcessForm.propTypes = {
  nextStep: PropTypes.object,
  allSteps: PropTypes.arrayOf(PropTypes.object),
  control: PropTypes.object,
  watch: PropTypes.func,
  setValue: PropTypes.func,
};
