import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Backdrop,
  Button,
  Card,
  Fade,
  InputAdornment,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  ConversionForm,
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { app as appConfig } from '@/configs';
import * as actions from '@/redux/actions';
import { currencyFormatter } from '@/utils/functions';
import { small as modalSmallStyle } from '@/utils/inlineStyle/Modal';

export default function Conversions({ title, subtitle }) {
  const dispatch = useDispatch();
  const conversion = useSelector((state) => state.conversion);
  const unit = useSelector((state) => state.unit);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      create_reverse_conversion: true,
    },
  });

  const getAllConversions = async () => {
    try {
      dispatch(actions.conversionAll({ page, size, name }));
    } catch (error) {
      console.log('Conversions All Errors', error);
    }
  };

  const getAllUnit = async () => {
    try {
      dispatch(actions.unitAll({ page: 1, size: appConfig.maxFetchSize }));
    } catch (error) {
      console.error('Get All Units Error', error);
    }
  };

  useEffect(() => {
    getAllConversions();
    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    setTotal(conversion?.total);
    return () => {};
  }, [conversion]);

  useEffect(() => {
    getAllUnit();
    return () => {};
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleDelete = async (id) => {
    try {
      const confirm = window.confirm('ยืนยันการลบหน่วย');
      if (confirm) {
        await dispatch(actions.conversionDelete(id));
      }
      getAllConversions();
    } catch (error) {
      alert('ลบหน่วยไม่สำเร็จ');
      console.error('Cannot remove conversion', error);
    }
  };

  const onSubmit = async (data) => {
    try {
      await dispatch(actions.conversionCreate(data));
      getAllConversions();
      setIsModalOpen(false);
      reset();
    } catch (error) {
      window.alert('เพิ่มเติมหน่วยไม่สำเร็จ');
      console.error(error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isModalOpen}>
        <Card sx={modalSmallStyle} className="z-10">
          <div className="py-2 font-display font-semibold">
            เพิ่มข้อมูลการแปลงหน่วย
          </div>
          <div className="z-10">
            <ConversionForm units={unit} control={control} errors={errors} />
          </div>
          <div className="py-2">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setIsModalOpen(false)}
                >
                  ยกเลิก
                </Button>
              </div>
            </form>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4">
      <div>
        <Button variant="contained" onClick={() => setIsModalOpen(true)}>
          เพิ่ม
        </Button>
      </div>
    </div>
  );

  const renderSearch = () => (
    <Card className="my-2">
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <Paper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <div className="font-bold">ลำดับที่</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">หน่วยแรก</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">จำนวนเท่า</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">หน่วยหลัง</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">ดำเนินการ</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(conversion?.rows, (_conversion, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  {_conversion?.unit_a?.name} ({_conversion?.unit_a?.short_sign}
                  ){' '}
                </TableCell>
                <TableCell>
                  {parseFloat(_conversion?.conversion_factor).toFixed(4)}
                  {_.map(_conversion?.chain_conversion, (each, idx) => (
                    <div key={idx}>
                      {parseFloat(each?.conversion_factor).toFixed(4)}
                    </div>
                  ))}
                </TableCell>
                <TableCell>
                  {_conversion?.unit_b?.name} ({_conversion?.unit_b?.short_sign}
                  ){' '}
                  {_.map(_conversion?.chain_conversion, (each, idx) => (
                    <div key={idx}>{each?.unit?.name}</div>
                  ))}
                </TableCell>
                <TableCell>
                  <div className="flex gap-2">
                    <Link to={`/km/conversion/edit/${_conversion?._id}`}>
                      <Button size="small" variant="contained" color="warning">
                        แก้ไข
                      </Button>
                    </Link>
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={() => handleDelete(_conversion?._id)}
                    >
                      ลบ
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
            {_.isEmpty(conversion?.rows) && (
              <TableRow>
                <TableCell colSpan={5}>
                  <div className="text-center">ไม่มีข้อมูล</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        page={page - 1}
        //  rowsPerPageOptions={[]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        count={total || 1}
        rowsPerPage={size}
        onPageChange={handleChangePage}
      />
    </Paper>
  );

  if (conversion.isLoading) {
    return <Loading />;
  }
  if (!conversion.isLoading && conversion.isCompleted) {
    return (
      <div>
        <div className="flex flex-wrap justify-between">
          {renderTitle()}
          {renderAddButtom()}
        </div>
        {renderSearch()}
        {renderTable()}
        {renderModal()}
      </div>
    );
  }
  return <Error />;
}

Conversions.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Conversions.defaultProps = {
  title: '',
  subtitle: '',
};
