import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Error,
  Loading,
  MultiUnitProdOrMatBox,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { MATERIAL_WITHDRAW_STATUS } from '@iarcpsu/emanufac-constant';
import {
  Button,
  Card,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';

const ApprovalMaterialWithdrawOrder = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const materialWithdraw = useSelector((state) => state.materialWithdraw);
  const { control } = useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [statusCode, setStatusCode] = useState('');
  const [total, setTotal] = useState(undefined);
  const history = useHistory();

  useEffect(() => {
    const fetchMaterterialWithdraw = async () => {
      try {
        await dispatch(
          actions.materialWithdrawAll({
            name,
            page,
            size,
            statusCode,
          }),
        );
      } catch (error) {
        console.error(error);
      }
    };

    fetchMaterterialWithdraw();

    return () => {};
  }, [name, page, size, statusCode]);

  useEffect(() => {
    setTotal(materialWithdraw?.total);
    return () => {};
  }, [materialWithdraw]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handlePushToApprovalMaterialWithdraw = async (id) => {
    history.push(`withdraw-order/approval/${id}`);
  };

  const handlePushToRejectMaterialWithdraw = async (id) => {
    const confirmApproval = window.confirm('ยืนยันการยกเลิกคำขอเบิกวัตถุดิบ');
    if (confirmApproval) {
      const data = {
        status: MATERIAL_WITHDRAW_STATUS.REJECT.status_code,
      };
      await dispatch(actions.materialWithdrawPut(id, data));
      await dispatch(actions.materialWithdrawAll({ name, page, size }));
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeStatus = (status) => {
    setStatusCode(status);
  };

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row gap-2">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/2">
          <Controller
            name={'status_code'}
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                <InputLabel id="type" size={'small'}>
                  ค้นหาด้วยสถานะ
                </InputLabel>
                <Select
                  {...field}
                  label="ค้นหาด้วยสถานะ"
                  size={'small'}
                  fullWidth
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    handleChangeStatus(e.target.value);
                  }}
                >
                  <MenuItem value="">ทั้งหมด</MenuItem>
                  {_.map(
                    MATERIAL_WITHDRAW_STATUS,
                    (_MATERIAL_WITHDRAW_STATUS) => (
                      <MenuItem
                        value={_MATERIAL_WITHDRAW_STATUS?.status_code}
                        key={_MATERIAL_WITHDRAW_STATUS?.status_code}
                      >
                        {_MATERIAL_WITHDRAW_STATUS?.description}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            )}
          />
        </div>
      </div>
    </Card>
  );

  const showStatus = (status) => {
    if (status === MATERIAL_WITHDRAW_STATUS.PENDING_APPROVAL.status_code) {
      return (
        <div className="text-yellow-500">
          {MATERIAL_WITHDRAW_STATUS[status].description}
        </div>
      );
    }
    if (status === MATERIAL_WITHDRAW_STATUS.APPROVAL.status_code) {
      return (
        <div className="text-green-700">
          {MATERIAL_WITHDRAW_STATUS[status].description}
        </div>
      );
    }
    if (status === MATERIAL_WITHDRAW_STATUS.REJECT.status_code) {
      return (
        <div className="text-red-500">
          {MATERIAL_WITHDRAW_STATUS[status].description}
        </div>
      );
    }
  };
  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> วันที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> วัตถุดิบ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ผู้ขอเบิก</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ผู้รับ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> สถานะ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> หมายเหตุ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(materialWithdraw?.rows) ? (
                materialWithdraw?.rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="text-xs">
                        {' '}
                        {(page - 1) * size + index + 1}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="text-xs">
                        {dayjs(row?.createdAt)
                          .locale('th')
                          .format('DD MMM BBBB')}
                        <p>
                          เวลา{' '}
                          {new Date(row?.createdAt).toLocaleTimeString('th')} น.
                        </p>
                      </div>
                    </TableCell>
                    <TableCell>
                      {_.size(row.material) ? (
                        row?.material.map((_material, _index) => (
                          <div key={_index}>
                            <span>
                              {_index + 1}
                              {'. '}
                            </span>
                            <span className=" font-semibold font-display">
                              {_material?.material?.type_code}
                            </span>{' '}
                            <span>{_material?.material?.name}</span>{' '}
                            <span>
                              <MultiUnitProdOrMatBox
                                foundMaterial={_material?.material}
                                noWrap
                                quantity={_material?.quantity}
                              />
                            </span>
                          </div>
                        ))
                      ) : (
                        <div>{' - '}</div>
                      )}
                    </TableCell>
                    <TableCell>
                      {row?.employee ? (
                        <div>
                          {row?.employee?.firstname} {row?.employee?.lastname}
                        </div>
                      ) : (
                        <div>-</div>
                      )}
                    </TableCell>
                    <TableCell>
                      {row?.receive_department ? (
                        <div>
                          {row?.receive_department?.name &&
                            `แผนก${row?.receive_department?.name}`}
                        </div>
                      ) : (
                        <div>-</div>
                      )}
                    </TableCell>
                    <TableCell>
                      <div className="py-1 bg-gray-100 rounded-md  text-center">
                        {row?.status ? showStatus(row?.status) : ' - '}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="text-xs">
                        {row?.remark ? row?.remark : ' - '}
                      </div>
                    </TableCell>
                    <TableCell>
                      {' '}
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          size={'small'}
                          color={'success'}
                          disabled={
                            row?.status !==
                            MATERIAL_WITHDRAW_STATUS.PENDING_APPROVAL
                              .status_code
                          }
                          onClick={() =>
                            handlePushToApprovalMaterialWithdraw(row.id)
                          }
                        >
                          อนุมัติ
                        </Button>
                        <Button
                          variant="contained"
                          size={'small'}
                          color={'error'}
                          disabled={
                            row?.status !==
                            MATERIAL_WITHDRAW_STATUS.PENDING_APPROVAL
                              .status_code
                          }
                          onClick={() =>
                            handlePushToRejectMaterialWithdraw(row.id)
                          }
                        >
                          ไม่อนุมัติ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          //    rowsPerPageOptions={[]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (materialWithdraw.isLoading) {
    return <Loading />;
  }
  if (!materialWithdraw.isLoading && materialWithdraw.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error message={materialWithdraw?.message} />;
};

ApprovalMaterialWithdrawOrder.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ApprovalMaterialWithdrawOrder.defaultProps = {
  title: '',
  subtitle: '',
};

export default ApprovalMaterialWithdrawOrder;
