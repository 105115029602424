import api from '../../../configs/api';
import {
  MANUFAC_MATERIAL_ALL,
  MANUFAC_MATERIAL_DELETE,
  MANUFAC_MATERIAL_ERROR,
  MANUFAC_MATERIAL_GET,
  MANUFAC_MATERIAL_LOADING,
  MANUFAC_MATERIAL_POST,
  MANUFAC_MATERIAL_PUT,
} from '../types';

export const manufacMaterialCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MANUFAC_MATERIAL_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/manufacturing-material`,
      {
        ...payload,
      },
    );
    dispatch({ type: MANUFAC_MATERIAL_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MANUFAC_MATERIAL_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const manufacMaterialAll = ({
  processId = '',
  step = '',
  material = '',
  size = '',
  page = 1,
  selectType = '',
  startDate = '',
  endDate = '',
  productLookup = '',
}) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/manufacturing-material?size=${size}&page=${page}&selectType=${selectType}&process=${processId}&step=${step}&material=${material}&startDate=${startDate}&endDate=${endDate}&productLookup=${productLookup}`,
    );
    if (status === 200) {
      dispatch({ type: MANUFAC_MATERIAL_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MANUFAC_MATERIAL_ERROR });
    throw new Error(error);
  }
};

export const manufacMaterialGet = (id) => async (dispatch) => {
  try {
    dispatch({ type: MANUFAC_MATERIAL_LOADING });
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/manufacturing-material/${id}`,
    );
    if (status === 200) {
      dispatch({ type: MANUFAC_MATERIAL_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MANUFAC_MATERIAL_ERROR });
    throw new Error(error);
  }
};

export const manufacturingMaterialStateSet = (data) => async (dispatch) => {
  dispatch({ type: MANUFAC_MATERIAL_ALL, payload: data });
};

export const manufacMaterialPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: MANUFAC_MATERIAL_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/manufacturing-material/${id}`,
      payload,
    );
    dispatch({ type: MANUFAC_MATERIAL_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MANUFAC_MATERIAL_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};
export const manufacMaterialDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: MANUFAC_MATERIAL_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/manufacturing-material/${id}`,
    );
    dispatch({ type: MANUFAC_MATERIAL_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MANUFAC_MATERIAL_ERROR });
    throw new Error(error);
  }
};

export const manufacMaterialError = (id) => async (dispatch) => {
  dispatch({ type: MANUFAC_MATERIAL_ERROR });
};

export const forceMovingManufacturingMaterial = ({
  referenceNumber,
  selectedTime,
}) => async (dispatch) => {
  try {
    dispatch({ type: MANUFAC_MATERIAL_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/manufacturing-material/force-moving`,
      {
        referenceNumber,
        selectedTime,
      },
    );
    dispatch({ type: MANUFAC_MATERIAL_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MANUFAC_MATERIAL_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};
