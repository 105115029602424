// db 1 represent 1 db any
// can be 1-db2  or 1-db12 , etc.

import db1 from "./3-1DB.png";
import db2 from "./3-2DB.png";
import db3 from "./3-3DB.png";
import db4 from "./3-4DB.png";
import db5 from "./3-5DB.png";
import db6 from "./3-6DB.png";
import db7 from "./3-7DB.png";
import db8 from "./3-8DB.png";
import db9 from "./3-9DB.png";
import db10 from "./3-10DB.png";
import db11 from "./3-11DB.png";
import db12 from "./3-12DB.png";
import db13 from "./3-13DB.png";
import db14 from "./3-14DB.png";
import db15 from "./3-15DB.png";
import db16 from "./3-16DB.png";
import db17 from "./3-17DB.png";
import db18 from "./3-18DB.png";

const threeJacket = {
  db1,
  db2,
  db3,
  db4,
  db5,
  db6,
  db7,
  db8,
  db9,
  db10,
  db11,
  db12,
  db13,
  db14,
  db15,
  db16,
  db17,
  db18,
};

export default threeJacket;
