import React from 'react';
import Chart from 'react-apexcharts';
import { ErrorBoundary } from 'react-error-boundary';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Error } from '../Error';

export default function MaterialSplittingPieChart({ materialSplitting }) {
  const filterStep = _.orderBy(
    _.filter(
      materialSplitting?.steps,
      (eachProcessStep) =>
        eachProcessStep?.material_associate &&
        !eachProcessStep?.is_waste &&
        eachProcessStep?.splited_step,
    ),
    ['amount'],
    ['desc'],
  );

  const totalWaste = _.sumBy(
    _.filter(materialSplitting?.steps, (each) => each?.is_waste === true),
    'amount',
  );

  const totalAmount = _.sumBy(filterStep, 'amount') + totalWaste;
  const firstStep = _.find(
    materialSplitting?.steps,
    (each) => each?.index === 0,
  );

  const series = [
    totalWaste,
    ..._.map(filterStep, (each) => each?.amount),
    totalAmount - firstStep?.amount,
  ];
  const labels = [
    'ของเสีย',
    ..._.map(filterStep, (each) => each?.name),
    'ไม่ทราบสาเหตุ',
  ];
  const options = {
    labels,
    colors: [
      '#FF4560',
      '#008FFB',
      '#00E396',
      '#FEB019',
      '#775DD0',
      '#3F51B5',
      '#03A9F4',
      '#4CAF50',
      '#F9CE1D',
      '#FF9800',
    ],
    yaxis: {
      labels: {
        formatter: (value) => parseFloat(value).toFixed(2),
      },
    },
    legend: {
      show: true,
    },
  };

  const ErrorFallback = ({ error }) => <Error message={error?.message} />;

  return (
    <div className="my-2">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Chart type="pie" series={series} options={options} height={500} />
      </ErrorBoundary>
    </div>
  );
}

MaterialSplittingPieChart.propTypes = {
  materialSplitting: PropTypes.shape({
    steps: PropTypes.arrayOf(PropTypes.object),
  }),
  error: PropTypes.object,
};
