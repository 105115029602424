import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  DaySpotAndRangePicker,
  Error,
  Loading,
  PlaceLaborCostByDayTableRow,
  PlaceLaborCostTable,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import { config } from '@/configs';
import * as actions from '@/redux/actions';
import { useQuery } from '@/utils/functions';

/**
 * @function PlaceLaborCosts
 * @description Display a collections or a list of PlaceLaborCosts from database
 */

export default function PlaceLaborCostByDay({ title, subtitle }) {
  const dispatch = useDispatch();
  const browserQuery = useQuery();
  const place = useSelector((state) => state.place);
  const [startDate, setStartDate] = useState(
    browserQuery.get('start')
      ? dayjs(browserQuery.get('start')).startOf('month').toDate()
      : dayjs().startOf('month').toDate(),
  );
  const [endDate, setEndDate] = useState(null);

  const amountOfDayInMonth = dayjs(startDate).daysInMonth();
  const dayArray = Array.from({ length: amountOfDayInMonth });

  useEffect(() => {
    dispatch(actions.placeAll({ page: 1, size: config.maxFetchSize }));

    return () => {};
  }, []);

  const orderedPlace = _.orderBy(place?.rows, ['name'], ['asc']);

  const handleDelete = async (id) => {
    try {
      Swal.fire({
        icon: 'warning',
        text: 'ยืนยันการลบข้อมูล',
        showConfirmButton: true,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await dispatch(actions.placeLaborCostDelete(id));
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'ลบข้อมูลไม่สำเร็จ',
        text: error?.message,
      });
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  return (
    <div>
      <div className="flex justify-between items-center">
        {renderTitle()}
        <div className="flex flex-wrap gap-1">
          <Link to="/cem/place-labor-cost/create">
            <Button variant="contained">เพิ่ม</Button>
          </Link>

          <Link to="/cem/place-labor-cost">
            <Button variant="contained" color="secondary">
              มุมมองรายการ
            </Button>
          </Link>
        </div>
      </div>
      <div className="my-2">
        <Card>
          <CardContent>
            <div className="flex justify-between items-center">
              <div className="w-full lg:w-4/5">
                <DaySpotAndRangePicker
                  enable
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  size="small"
                  state="month"
                  flex
                  label="เลือกเดือนที่ต้องการ"
                  addSearchQuery
                  anotherQuery="?"
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      <div className="my-2">
        <Paper>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className="font-bold">ลำดับที่</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold">วันที่</div>
                  </TableCell>
                  {_.map(orderedPlace, (eachPlace, index) => (
                    <TableCell key={index}>
                      <div className="font-bold">{eachPlace.name}</div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {_.map(dayArray, (row, index) => (
                  <PlaceLaborCostByDayTableRow
                    key={index}
                    index={index}
                    selectedDay={dayjs(startDate).add(index, 'day').toDate()}
                    orderedPlace={orderedPlace}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
}

PlaceLaborCostByDay.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

PlaceLaborCostByDay.defaultProps = {
  title: '',
  subtitle: '',
};
