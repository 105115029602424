import {
  BOM_CHECKING_ALL,
  BOM_CHECKING_GET,
  BOM_CHECKING_DELETE,
  BOM_CHECKING_PUT,
  BOM_CHECKING_POST,
  BOM_CHECKING_LOADING,
  BOM_CHECKING_ERROR,
} from '../types';

import api from '../../../configs/api';
import graphqlClient from '../../../configs/graphqlClient';

export const bomCheckingCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: BOM_CHECKING_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/bom-checking`,
      {
        ...payload,
      },
    );
    dispatch({ type: BOM_CHECKING_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: BOM_CHECKING_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const bomCheckingAll = ({
  name = '',
  size = 10,
  page = 1,
  completed = '',
  deleted = '',
  manufacturingOrder = '',
  currentProcess = '',
  product = '',
}) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/bom-checking?name=${name}&size=${size}&page=${page}&completed=${completed}&deleted=${deleted}&manufacturing_order=${manufacturingOrder}&process=${currentProcess}&product=${product}`,
    );
    if (status === 200) {
      dispatch({ type: BOM_CHECKING_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: BOM_CHECKING_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const bomCheckingAllQuery = ({ query }) => async (dispatch) => {
  try {
    const queryResult = await graphqlClient.request(query);
    const data = queryResult?.findUnits;
    //  console.log('Query Data', queryResult);
    if (!data) {
      dispatch({ type: BOM_CHECKING_ERROR });
    }

    dispatch({ type: BOM_CHECKING_ALL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: BOM_CHECKING_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const bomCheckingGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/bom-checking/${id}`,
    );
    if (status === 200) {
      dispatch({ type: BOM_CHECKING_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: BOM_CHECKING_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const bomCheckingStateSet = (data) => async (dispatch) => {
  dispatch({ type: BOM_CHECKING_ALL, payload: data });
};

export const bomCheckingStateOneSet = (data) => async (dispatch) => {
  dispatch({ type: BOM_CHECKING_GET, payload: data });
};

export const bomCheckingReset = () => async (dispatch) => {
  dispatch({ type: BOM_CHECKING_LOADING });
};

export const bomCheckingError = () => async (dispatch) => {
  dispatch({ type: BOM_CHECKING_ERROR });
};

export const bomCheckingPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: BOM_CHECKING_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/bom-checking/${id}`,
      payload,
    );
    dispatch({ type: BOM_CHECKING_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: BOM_CHECKING_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};
export const bomCheckingDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: BOM_CHECKING_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/bom-checking/${id}`,
    );
    dispatch({ type: BOM_CHECKING_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: BOM_CHECKING_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};
