import Proptypes from 'prop-types';

export const Step = Proptypes.shape({
  index: Proptypes.number,
  name: Proptypes.string,
  detail: Proptypes.string,
  status: Proptypes.string,
  department: Proptypes.object,
  measurements: Proptypes.arrayOf(Proptypes.object),
  notes: Proptypes.arrayOf(Proptypes.object),
  process: Proptypes.object,
});

export const processTemplate = Proptypes.shape({
  id: Proptypes.string,
  name: Proptypes.string,
  detail: Proptypes.string,
  product: Proptypes.object,
  step: Proptypes.arrayOf(Step),
});

export const StepStage = Proptypes.shape({
  rows: Proptypes.arrayOf(Step),
  currPage: Proptypes.number,
  isComplete: Proptypes.bool,
  isLoading: Proptypes.bool,
  lastPage: Proptypes.number,
});

export const processTemplateArray = Proptypes.shape({
  rows: Proptypes.arrayOf(processTemplate),
  currPage: Proptypes.number,
  isComplete: Proptypes.bool,
  isLoading: Proptypes.bool,
  lastPage: Proptypes.number,
});

export default {
  processTemplate,
  processTemplateArray,
  Step,
  StepStage,
};
