import React from 'react';
import { Chip } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import MultiUnitProdOrMatBox from '../Rendering/MultiUnitProdOrMatBox';

const BoxQuotationByProductOrMaterial = ({
  quotationByProducts,
  typeKey,
  typeKeyTH,
}) => {
  const orderByQuantity = _.orderBy(quotationByProducts, ['total'], ['desc']);

  const mostQuantity = orderByQuantity[0];
  const anotherQuantity = _.tail(orderByQuantity);

  return (
    <div>
      <div className="text-lg font-semibold mb-2">{typeKeyTH}ที่เสนอราคา</div>
      <div className="w-full">
        <h4 className="text-xl flex items-center">
          <Chip label="#1" size="small" className="mr-1" />{' '}
          {mostQuantity?.[typeKey]?.name}
        </h4>
        <div className="flex justify-end">
          {mostQuantity?.additional_unit ? (
            <div>
              {mostQuantity?.total} {mostQuantity?.additional_unit}
            </div>
          ) : (
            <MultiUnitProdOrMatBox
              foundMaterial={mostQuantity?.[typeKey]}
              quantity={mostQuantity?.total}
              noWrap
            />
          )}
        </div>
      </div>
      <hr className="my-2" />
      {_.map(anotherQuantity, (eachProduct) => (
        <div className="flex justify-between">
          <div>{eachProduct?.[typeKey]?.name}</div>
          {eachProduct?.additional_unit ? (
            <div>
              {eachProduct?.total} {eachProduct?.additional_unit}
            </div>
          ) : (
            <MultiUnitProdOrMatBox
              foundMaterial={eachProduct?.[typeKey]}
              quantity={eachProduct?.total}
              noWrap
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default BoxQuotationByProductOrMaterial;

BoxQuotationByProductOrMaterial.propTypes = {
  quotationByProducts: PropTypes.arrayOf(PropTypes.object),
  typeKey: PropTypes.string,
  typeKeyTH: PropTypes.string,
};
