/* eslint-disable import/prefer-default-export */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';
import THBText from 'thai-baht-text';

import currencyFormatterTH from '@/utils/functions/currencyFormatterTH';

import 'addthaifont-pdfmake/build/vfs_fonts';

import { ReportHeader } from './common';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};

const genOrder = (data) =>
  _.map(data, (_data, index) => [
    {
      text: index + 1,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${_data?.product?.type_code || ''} ${_data?.product?.name || ''}`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${currencyFormatterTH.format(_data?.quantity)}`,
      border: [true, false, true, true],
      fontSize: 10,
      alignment: 'center',
      colSpan: 1,
    },
    {
      text: `${_data?.additional_unit || _data?.product?.unit}`,
      border: [true, false, true, true],
      fontSize: 10,
      alignment: 'center',
      colSpan: 1,
    },
    {
      text: `${currencyFormatterTH.format(_data?.price)}`,
      border: [true, false, true, true],
      fontSize: 10,
      alignment: 'right',
      colSpan: 1,
    },
  ]);

const genMaterialOrder = (data, productOrderSize) =>
  _.map(data, (_data, index) => [
    {
      text: index + 1 + productOrderSize,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${_data?.material?.type_code || ''} ${
        _data?.material?.name || ''
      }`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${currencyFormatterTH.format(_data?.quantity)}`,
      border: [true, false, true, true],
      fontSize: 10,
      alignment: 'center',
      colSpan: 1,
    },
    {
      text: `${_data?.additional_unit || _data?.material?.unit}`,
      border: [true, false, true, true],
      fontSize: 10,
      alignment: 'center',
      colSpan: 1,
    },
    {
      text: `${currencyFormatterTH.format(_data?.price)}`,
      border: [true, false, true, true],
      fontSize: 10,
      alignment: 'right',
      colSpan: 1,
    },
  ]);

export const OrderReport = (order, information, isError) => {
  console.log('order', order);
  console.log('information', information);

  const orderData = genOrder(order?.product_of_order);
  const materialOrderData = genMaterialOrder(
    order?.material_of_order,
    _.size(order?.product_of_order),
  );

  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 30, 40, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '16',
    },
    info: {
      title: 'ใบเสนอราคา',
    },
    content: [
      isError ? ReportHeader(information, false) : ReportHeader(information),
      {
        // หัวข้อ
        alignment: 'center',
        margin: [0, 5, 0, 10],
        text: 'ใบเสนอราคา',
        fontSize: '16',
        bold: true,
      },
      {
        style: 'tableExample',
        table: {
          widths: ['7%', '45%', '17%', '35%'],
          heights: [15],
          body: [
            [
              {
                text: 'ลูกค้า',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, false, false],
              },
              {
                text: `${order?.customer?.name || '-'}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, true, true, false],
              },
              {
                text: 'เลขที่เอกสาร',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, false, false],
              },
              {
                text: `${order?.running_number}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, true, true, false],
              },
            ],
            [
              {
                text: 'ที่อยู่',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, false],
              },
              {
                text: `${order?.customer?.address || '-'}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, true, false],
              },
              {
                text: 'วันที่กำหนดจัดส่ง',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, false],
              },
              {
                text: `${dayjs(order?.expected_date)
                  .locale('th')
                  .format('DD MMM BBBB')}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: '',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, true],
              },
              {
                text: `โทร ${order?.customer?.phone_number || '-'}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, true, true],
              },
              {
                text: 'ประเภทการชำระเงิน',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, true],
              },
              {
                text: `${order?.commerce?.payment?.name}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, true, true],
              },
            ],
          ],
        },
      },
      {
        margin: [0, 5, 0, 0],
        style: 'tableExample',
        table: {
          widths: ['10%', '49%', '15%', '15%', '15%'],
          heights: [15],
          body: [
            [
              {
                text: 'ลำดับ',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
                alignment: 'center',
              },
              {
                text: 'สินค้า',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
                alignment: 'center',
              },
              {
                text: 'จำนวน',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
                alignment: 'center',
              },
              {
                text: 'หน่วย',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
                alignment: 'center',
              },
              {
                text: 'ราคาขาย',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
                alignment: 'center',
              },
            ],
            ...orderData,
            ...materialOrderData,
            [
              {
                text: `หมายเหตุ : ${order?.remark}`,
                colSpan: 3,
                fontSize: '10',
                border: [true, false, false, false],
              },
              {},
              {},
              {
                text: 'ทั้งหมด',
                colSpan: 1,
                fontSize: 10,
                border: [true, false, false, true],
              },
              {
                text: `${
                  currencyFormatterTH.format(order?.commerce?.price) || '0'
                } บาท`,
                alignment: 'right',
                colSpan: 1,
                fontSize: 10,
                border: [true, false, true, true],
              },
            ],
            [
              {
                text: '',
                colSpan: 3,
                border: [true, false, false, false],
              },
              {},
              {},
              {
                text: 'ส่วนลด',
                colSpan: 1,
                fontSize: 10,
                border: [true, false, false, true],
              },
              {
                text: `${
                  currencyFormatterTH.format(order?.commerce?.discount) || '0'
                } บาท`,
                alignment: 'right',
                colSpan: 1,
                fontSize: 10,
                border: [true, false, true, true],
              },
            ],
            [
              {
                text: '',
                colSpan: 3,
                border: [true, false, false, false],
              },
              {},
              {},
              {
                text: 'ราคาสินค้า',
                colSpan: 1,
                fontSize: 10,
                border: [true, false, false, true],
              },
              {
                text: `${
                  currencyFormatterTH.format(
                    (order?.commerce?.price * 93) / 100,
                  ) || '0'
                } บาท`,
                alignment: 'right',
                colSpan: 1,
                fontSize: 10,
                border: [true, false, true, true],
              },
            ],
            [
              {
                text: '',
                colSpan: 3,
                border: [true, false, false, false],
              },
              {},
              {},
              {
                text: 'ภาษีมูลค่าเพิ่ม 7%',
                colSpan: 1,
                fontSize: 10,
                border: [true, false, false, true],
              },
              {
                text: `${
                  currencyFormatterTH.format(
                    (order?.commerce?.price * 7) / 100,
                  ) || '0'
                } บาท`,
                alignment: 'right',
                colSpan: 1,
                fontSize: 10,
                border: [true, false, true, true],
              },
            ],
            [
              {
                text: `( ${THBText(order?.commerce?.total_price)} )`,
                alignment: 'center',
                colSpan: 3,
                fontSize: 10,
                border: [true, true, false, true],
              },
              {},
              {},
              {
                text: 'รวมราคาสุทธิ',
                colSpan: 1,
                fontSize: 10,
                border: [true, false, false, true],
              },
              {
                text: `${
                  currencyFormatterTH.format(order?.commerce?.total_price) ||
                  '0'
                } บาท`,
                alignment: 'right',
                colSpan: 1,
                fontSize: 10,
                border: [true, false, true, true],
              },
            ],
          ],
        },
      },
      {
        style: 'tableExample',
        table: {
          widths: ['25%', '25%', '25%', '25%'],
          margin: [0, 30, 0, 10],
          heights: [30, 15, 15],
          body: [
            [
              { border: [false, false, false, false], text: '', colSpan: 4 },
              {},
              {},
              {},
            ],
            [
              { border: [false, false, false, false], text: '', colSpan: 3 },
              {},
              {},
              {
                text: 'ฝ่ายขาย',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
            [
              { border: [false, false, false, false], text: '', colSpan: 3 },
              {},
              {},
              {
                text: '........................................',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
            [
              { border: [false, false, false, false], text: '', colSpan: 3 },
              {},
              {},
              {
                text: '(........................................)',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ],
    // images: {
    //   logo: information?.logo?.url,
    // },
  };
  pdfMake.createPdf(docDefinition).open();
};
