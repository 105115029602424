import React from 'react';
import { TextField } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

/**
 * @function DesktopTimePicker
 * @description Time Picker MUI For Hybridge Use between React-hook-form and React UI-state/setState
 * @link   https://react-hook-form.com/api/usecontroller
 */

function DesktopTimePicker({
  label,
  value,
  setValue = () => {},
  onChange = () => {},
  disabled = false,
}) {
  const handleChange = (inputValue) => {
    setValue(inputValue);
    onChange(inputValue);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label={label}
        value={dayjs(value)}
        onChange={handleChange}
        showToolbar
        // inputFormat="DD MMM BBBB"
        renderInput={(params) => <TextField fullWidth {...params} />}
        disabled={disabled}
        ampm={false}
        slotProps={{
          textField: {
            fullWidth: true,
          },
        }}
      />
    </LocalizationProvider>
  );
}

DesktopTimePicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.object,
  setValue: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default DesktopTimePicker;
