import React, { useEffect, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import BuddhistCalendar from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  DesktopDatepicker,
  DetailReportMaterialReport,
  FullManufacMaterialTable,
  ReportMaterialProductAsMaterial,
  ReportMaterialProductResultTable,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { useQuery } from '@/configs';
import * as actions from '@/redux/actions';
import * as CONSTANT from '@/utils/constants';
import { TIMING } from '@/utils/constants';
import {
  backConverseMaterialOrProductUnit,
  MMS,
  notNan,
} from '@/utils/functions';

import 'dayjs/locale/th';

dayjs.extend(BuddhistCalendar);
dayjs.locale('th');

const DetailReportMaterialUsage = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const material = useSelector((state) => state.material);
  const information = useSelector((state) => state.information);
  const manufacturingMaterial = useSelector(
    (state) => state.manufacturingMaterial,
  );

  const {
    manufacturingMaterialAnalyzed,
    manufacturingMaterialAllStepByStepAnalyzer,
  } = MMS.MaterialAnalyzer;
  const materialResult = manufacturingMaterialAnalyzed(
    manufacturingMaterial?.analyzed,
    true,
  );

  const query = useQuery();
  const [startDate, setStartDate] = useState(
    dayjs(query.get('start') || new Date()).toDate(),
  );
  const [endDate, setEndDate] = useState(
    dayjs(query.get('end') || new Date()).toDate(),
  );
  const [page, setPage] = useState(1);
  const [size] = useState('');
  const [total, setTotal] = useState(undefined);
  const [isShowHandover, setIsShowHandover] = useState(false);
  const [useTimePeriod, setUseTimePeriod] = useState();

  const [selectedTimingMode, setSelectedTimingMode] = useState(
    TIMING.DAILY.status_code,
  );
  const [selectedPointDate, setSelectedPointDate] = useState();
  const manufacMaterialByStep = _.values(
    _.groupBy(
      manufacturingMaterial?.rows,
      (_manufacMaterial) => _manufacMaterial?.step?.name,
    ),
  );

  const filteredManufacByStep = _.orderBy(
    _.filter(
      manufacMaterialByStep,
      (_manufacMat) => _manufacMat?.[0]?.step?.index !== undefined,
    ),
    ['0.step.index'],
  );

  const allStepByStepAnalyzedResult = manufacturingMaterialAllStepByStepAnalyzer(
    manufacMaterialByStep,
  );

  console.log('All Step By Step Analyze', allStepByStepAnalyzedResult);

  const numberFormatting = (row) => {
    try {
      if (row === Infinity) {
        return '100';
      }
      if (row === -Infinity) {
        return '-';
      }
      return row?.toFixed(2);
    } catch (error) {
      return '-';
    }
  };

  const showReport = () => {
    try {
      DetailReportMaterialReport(
        material,
        manufacturingMaterial,
        startDate,
        information,
      );
    } catch (error) {
      DetailReportMaterialReport(
        material,
        manufacturingMaterial,
        startDate,
        information,
        true,
      );
    }
  };

  const adjustTimeOnPeriod = () => {
    if (selectedTimingMode === TIMING.DAILY.status_code) {
      const startPoint = dayjs(selectedPointDate)
        .startOf('date')
        .format('YYYY-MM-DDTHH:mm');
      const endPoint = dayjs(selectedPointDate)
        .endOf('date')
        .format('YYYY-MM-DDTHH:mm');
      setStartDate(startPoint);
      setEndDate(endPoint);
    }
    if (selectedTimingMode === TIMING.MONTHY.status_code) {
      const startPoint = dayjs(selectedPointDate)
        .startOf('month')
        .format('YYYY-MM-DDTHH:mm');
      const endPoint = dayjs(selectedPointDate)
        .endOf('month')
        .format('YYYY-MM-DDTHH:mm');
      setStartDate(startPoint);
      setEndDate(endPoint);
    }
    if (selectedTimingMode === TIMING.YEARLY.status_code) {
      const startPoint = dayjs(selectedPointDate)
        .startOf('year')
        .format('YYYY-MM-DDTHH:mm');
      const endPoint = dayjs(selectedPointDate)
        .endOf('year')
        .format('YYYY-MM-DDTHH:mm');
      setStartDate(startPoint);
      setEndDate(endPoint);
    }
  };

  useEffect(() => {
    if (useTimePeriod) {
      adjustTimeOnPeriod();
    }

    return () => {};
  }, [useTimePeriod, selectedTimingMode, selectedPointDate]);

  useEffect(() => {
    const getMaterial = async () => {
      try {
        console.log('Get Material');
        await dispatch(
          actions.materialGet(params.id, {
            placeId: '',
            startDate: dayjs(startDate).format('YYYY-MM-DD'),
            endDate: dayjs(endDate).format('YYYY-MM-DD'),
            stockStatus: '',
            fetchProcess: true,
          }),
        );
      } catch (error) {
        console.error(error);
      }
    };

    const getManufacturingMaterial = async () => {
      try {
        dispatch(
          actions.manufacMaterialAll({
            startDate: dayjs(startDate).toISOString(),
            endDate: dayjs(endDate).toISOString(),
            material: params.id,
            page,
            size,
            productLookup: true,
          }),
        );
      } catch (error) {
        console.error('Error on Fetching Manufacturing Material');
        console.error(error);
      }
    };

    getMaterial();
    getManufacturingMaterial();
    return () => {};
  }, [params, startDate, endDate, size, page]);

  useEffect(() => {
    setTotal(manufacturingMaterial?.total);
    return () => {};
  }, [manufacturingMaterial]);
  const beforeStepResult = _.find(
    manufacMaterialByStep,
    (_manufacMat) => _manufacMat?.[0]?.step?.index === undefined,
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderMainUnit = (selectedMaterial) => {
    if (selectedMaterial?.use_unit_conversion) {
      return selectedMaterial?.unit_input?.short_sign || '';
    }

    return selectedMaterial?.unit || '';
  };

  const renderSubUnit = (selectedMaterial, value) => {
    if (selectedMaterial?.use_unit_conversion) {
      return `${notNan(
        backConverseMaterialOrProductUnit(selectedMaterial, value),
      )} ${selectedMaterial?.unit_output?.short_sign || ''}`;
    }

    return <div></div>;
  };

  const renderMaterialInfoPanel = () => (
    <div>
      <Card>
        <CardContent>
          <div className="flex flex-wrap justify-between items-center">
            <div className="w-full lg:w-3/12">
              <h2 className="text-lg">
                <span className="font-semibold font-display">
                  {material?.type_code}
                </span>{' '}
                {material?.name}{' '}
              </h2>
            </div>
            {!useTimePeriod && (
              <div className="w-full md:w-1/6 px-1">
                <DesktopDatepicker
                  fullWidth
                  label="จากวันที่"
                  onChange={setStartDate}
                  value={startDate}
                  size="small"
                />
              </div>
            )}
            {!useTimePeriod && (
              <div className="w-full md:w-1/6 px-1">
                <DesktopDatepicker
                  fullWidth
                  label="ถึงวันที่"
                  onChange={setEndDate}
                  value={endDate}
                  size="small"
                />
              </div>
            )}
            {useTimePeriod && (
              <div className="w-full md:w-1/4 px-1">
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    เลือกช่วงเวลา
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={selectedTimingMode}
                    onChange={(e) => setSelectedTimingMode(e.target.value)}
                  >
                    {_.map(TIMING, (eachTime, index) => (
                      <FormControlLabel
                        value={eachTime.status_code}
                        control={<Radio />}
                        label={eachTime.description}
                        key={index}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </div>
            )}
            {useTimePeriod && selectedTimingMode === TIMING.DAILY.status_code && (
              <div className="w-full md:w-1/6 px-1">
                <DesktopDatepicker
                  fullWidth
                  views={['day', 'month']}
                  label="เลือกวันที่"
                  size="small"
                  onChange={setSelectedPointDate}
                  value={selectedPointDate}
                  inputFormat="DD MMM BBBB"
                />
              </div>
            )}
            {useTimePeriod && selectedTimingMode === TIMING.MONTHY.status_code && (
              <div className="w-full md:w-1/6 px-1">
                <DesktopDatepicker
                  fullWidth
                  views={['year', 'month']}
                  label="เลือกเดือน"
                  size="small"
                  onChange={setSelectedPointDate}
                  value={selectedPointDate}
                  inputFormat="MMM BBBB"
                />
              </div>
            )}
            {useTimePeriod &&
              selectedTimingMode === TIMING.YEARLY.status_code && (
                <div className="w-full md:w-1/6 px-1">
                  <DesktopDatepicker
                    fullWidth
                    views={['year']}
                    label="เลือกปี"
                    size="small"
                    onChange={setSelectedPointDate}
                    value={selectedPointDate}
                    inputFormat="BBBB"
                  />
                </div>
              )}{' '}
            <div className="w-full md:w-1/4 flex px-1 gap-2 border py-2 rounded-md">
              <div className="self-center">เลือกวันเริ่มต้น/สิ้นสุด</div>
              <FormControlLabel
                label="เลือกเป็นช่วงเวลา"
                control={
                  <Switch
                    checked={useTimePeriod}
                    onChange={(e) => setUseTimePeriod(e.target.checked)}
                  />
                }
              />
            </div>
            <div className="lg:w-1/12 self-center px-2">
              <Switch
                value={isShowHandover}
                onChange={() => {
                  setIsShowHandover(!isShowHandover);
                }}
              />
              <div className="text-sm">แสดงการส่งต่อวัตถุดิบ</div>
            </div>
            <div className="lg:w-1/12 self-center px-2">
              <Button
                color="teal"
                variant="contained"
                onClick={() => showReport()}
              >
                พิมพ์
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );

  const renderProductFromMaterial = () => (
    <div className="my-4">
      <h4 className="text-lg my-2 font-semibold font-display">
        สินค้าที่ผลิตได้
      </h4>
      <Card>
        <CardContent>
          <ReportMaterialProductResultTable
            manufacturingMaterial={manufacturingMaterial}
          />
          <ReportMaterialProductAsMaterial materialData={material} />
        </CardContent>
      </Card>
    </div>
  );

  const renderMaterialSummary = () => (
    <div className="my-2">
      <Card>
        <CardContent>
          <h4 className="text-lg my-2 font-semibold font-display">
            สรุปการใช้วัตถุดิบ
          </h4>
          <div className="grid lg:grid-cols-6 md:grid-cols-4  sm:grid-cols-2 p-2">
            <div className="my-2">
              <h4>
                {' '}
                <i className="fas fa-caret-right" /> เบิกมา
              </h4>
              <div className="flex gap-2">
                <div className="font-bold text-2xl">
                  {notNan(materialResult.requestAmount)}
                </div>
                <div className="text-sm self-end">
                  {renderMainUnit(material)}{' '}
                </div>
              </div>{' '}
              <div className="text-xs">
                {renderSubUnit(material, materialResult.requestAmount)}
              </div>
            </div>{' '}
            <div className="my-2">
              <h4>
                <i className="fas fa-mortar-pestle"></i> มาจากการรวบวัตถุดิบ
              </h4>
              <div className="flex gap-2">
                <div className="font-bold text-2xl">
                  {notNan(materialResult.mergingAmount)}
                </div>
                <div className="text-sm self-end">
                  {renderMainUnit(material)}{' '}
                </div>
              </div>{' '}
              <div className="text-xs">
                {renderSubUnit(material, materialResult.mergingAmount)}
              </div>
            </div>
            <div className="my-2">
              <h4>
                <i className="fas fa-caret-left" /> คืน
              </h4>
              <div className="flex gap-2">
                <div className="font-bold text-2xl">
                  {notNan(materialResult.returnAmount)}
                </div>
                <div className="text-sm self-end">
                  {renderMainUnit(material)}{' '}
                </div>
              </div>{' '}
              <div className="text-xs">
                {renderSubUnit(material, materialResult.returnAmount)}
              </div>
            </div>
            <div className="my-2">
              <h4>
                <i className="fas fa-box-open"></i> ใช้จริง
              </h4>
              <div className="flex gap-2">
                <div className="font-bold text-2xl">
                  {notNan(materialResult.usedAmount)}
                </div>
                <div className="text-sm self-end">
                  {renderMainUnit(material)}{' '}
                </div>
              </div>{' '}
              <div className="text-xs">
                {renderSubUnit(material, materialResult.usedAmount)}
              </div>
            </div>
            <div className="my-2">
              <h4>
                <i className="fas fa-exchange-alt"></i> ได้เป็นผลผลิต
              </h4>
              <div className="flex gap-2">
                <div className="font-bold text-2xl">
                  {notNan(materialResult.resultAmount)}
                </div>
                <div className="text-sm self-end">
                  {renderMainUnit(material)}{' '}
                </div>
              </div>
              <div className="text-xs">
                {renderSubUnit(material, materialResult.resultAmount)}
              </div>
            </div>
            <div className="my-2">
              <h4>
                <i className="fas fa-object-ungroup"></i> รวมเป็นวัตถุดิบอื่น
              </h4>
              <div className="flex gap-2">
                <div className="font-bold text-2xl">
                  {notNan(materialResult.mergedAmount)}
                </div>
                <div className="text-sm self-end">
                  {renderMainUnit(material)}{' '}
                </div>
              </div>
              <div className="text-xs">
                {renderSubUnit(material, materialResult.mergedAmount)}
              </div>
            </div>
            <div className="my-2">
              <h4>
                <i className="fas fa-trash"></i> ของเสีย
              </h4>
              <div className="flex gap-2">
                <div className="font-bold text-2xl">
                  {notNan(materialResult.wastesAmount)}
                </div>
                <div className="text-sm self-end">
                  {renderMainUnit(material)}{' '}
                </div>
              </div>{' '}
              <div className="text-xs">
                {renderSubUnit(material, materialResult.wastesAmount)}
              </div>
            </div>
            <div className="my-2">
              <h4>
                <i className="fas fa-percentage"></i> Yield ของการผลิต
              </h4>
              <div className="flex gap-2">
                <div className="font-bold text-2xl">
                  {numberFormatting(materialResult.yield)} %
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );

  const renderEachStepYieldSummary = (analysisResult) => (
    <Table size="small">
      <TableHead>
        <TableCell>
          <div className="font-bold"> ยกมา </div>
        </TableCell>
        <TableCell>
          <div className="font-bold"> เบิกเพิ่ม </div>
        </TableCell>
        <TableCell>
          <div className="font-bold"> คืน </div>
        </TableCell>
        <TableCell>
          <div className="font-bold"> ใช้จริง </div>
        </TableCell>
        <TableCell>
          <div className="font-bold"> ได้เป็นผลผลิต </div>
        </TableCell>
        <TableCell>
          <div className="font-bold"> ของเสีย </div>
        </TableCell>
        <TableCell>
          <div className="font-bold"> Yield </div>
        </TableCell>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            {notNan(analysisResult.incomingAmount)} {material?.unit}
          </TableCell>
          <TableCell>
            {notNan(analysisResult.requestAmount)} {material?.unit}
          </TableCell>
          <TableCell>
            {notNan(analysisResult.returnAmount)} {material?.unit}
          </TableCell>
          <TableCell>
            {notNan(analysisResult.usedAmount)} {material?.unit}
          </TableCell>
          <TableCell>
            {notNan(analysisResult.resultAmount)} {material?.unit}
          </TableCell>
          <TableCell>
            {notNan(analysisResult.wasteAmount)} {material?.unit}
          </TableCell>
          <TableCell>{numberFormatting(analysisResult.yield)} % </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );

  const renderManufacMaterialTable = (
    manufacMaterialStepSet,
    currentStep = -1,
  ) => {
    const eachMaterialAnalyze = allStepByStepAnalyzedResult[currentStep + 1];
    const eachTxnWithoutHandover = _.filter(
      manufacMaterialStepSet,
      (_manufacMaterial) =>
        _manufacMaterial?.transaction_type !==
        CONSTANT.MANUFACTURING_TRANSACTION_TYPE.MATERIAL_HANDOVER.status_code,
    );

    return (
      <Card>
        <CardContent>
          <h5 className="my-2 text-lg font-semibold font-display">
            {manufacMaterialStepSet?.[0]?.step?.name ||
              'ก่อนเริ่มกระบวนการผลิต'}
          </h5>

          {renderEachStepYieldSummary(eachMaterialAnalyze)}
          <div className="my-4">
            <Accordion>
              <AccordionSummary expandIcon={<ChevronDown size={16} />}>
                <h5 className="font-semibold font-display">รายละเอียดการใช้</h5>
              </AccordionSummary>

              <AccordionDetails>
                <FullManufacMaterialTable
                  manufacturingMaterials={
                    isShowHandover
                      ? manufacMaterialStepSet
                      : eachTxnWithoutHandover
                  }
                  size={size}
                  page={page}
                  total={total}
                  pagination={false}
                  handleChangePage={(pageNo) => {
                    setPage(pageNo);
                  }}
                />
              </AccordionDetails>
            </Accordion>
          </div>
        </CardContent>
      </Card>
    );
  };

  const renderManufacturingMaterialOrder = () => (
    <div className="my-2">
      <h4 className="text-lg my-4 font-semibold font-display">
        การใช้วัตถุดิบในแต่ละขั้นตอน
      </h4>
      {renderManufacMaterialTable(beforeStepResult, -1)}
      {_.map(filteredManufacByStep, (_manufacMaterialStepSets, index) => (
        <div key={index} className="my-2">
          {renderManufacMaterialTable(_manufacMaterialStepSets, index)}
        </div>
      ))}
    </div>
  );

  return (
    <div>
      {renderTitle()}
      <div className="flex flex-row justify-between pb-4">
        <div>
          <BackButton />
        </div>
      </div>
      {renderMaterialInfoPanel()}
      {renderMaterialSummary()}
      {renderProductFromMaterial()}
      {renderManufacturingMaterialOrder()}
    </div>
  );
};

DetailReportMaterialUsage.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailReportMaterialUsage.defaultProps = {
  title: '',
  subtitle: '',
};

export default DetailReportMaterialUsage;
