import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Button,
  Card,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import PropTypes from 'prop-types';
import { gql } from 'graphql-request';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import * as actions from '../../redux/actions';
import { app as appConfig } from '../../configs';
import {
  DesktopDatepicker,
  ViewTitle,
  Error,
  Loading,
  PayrollTable,
  BackButton,
  DailyOrMonthyPayrollReport,
  MonthyUpAndDownButton,
} from '@iarcpsu/emanufac-components/src/components';
import * as CONSTANT from '../../utils/constants';
import { currencyFormatter } from '../../utils/functions';
import graphqlClient from '../../configs/graphqlClient';
import { PAYROLL_TYPE } from '../../utils/constants';

function DailyPayrolls({ title, subtitle }) {
  const dispatch = useDispatch();
  const payroll = useSelector((state) => state.payroll);
  const information = useSelector((state) => state.information);
  const history = useHistory();

  const {
    setting: { pams: pamsSetting },
  } = information;

  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(appConfig.normalFetchSize);
  const [total, setTotal] = useState(undefined);
  const [findAllCheck, setFindAllCheck] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs().toDate());
  const [selectedStartDate, setSelectedStartDate] = useState(
    dayjs().date(pamsSetting?.period_date).subtract(1, 'month').toDate(),
  );

  const [selectedEndDate, setSelectedEndDate] = useState(
    dayjs().date(pamsSetting?.period_date).toDate(),
  );
  const [useDurationInstead, setIsUseDurationInstead] = useState(true);

  const query = gql`
    query FindPayrolls($input: PayrollInput) {
      findPayrolls(input: $input) {
        currPage
        lastPage
        total
        totalAmount
        rows {
          _id
          id
          employee {
            firstname
            lastname
          }
          total_overtime
          date
          total_cost
          main_cost
          daily_attribute {
            start_time
            end_time
          }
          createdAt
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        input: {
          page,
          size,
          payroll_type: CONSTANT.PAYROLL_TYPE.DAILY.status_code,
          date:
            !findAllCheck && !useDurationInstead
              ? dayjs(selectedDate).format('YYYY-MM-DD')
              : null,
          date_start:
            !findAllCheck && useDurationInstead
              ? dayjs(selectedStartDate).format('YYYY-MM-DD')
              : null,
          date_end:
            !findAllCheck && useDurationInstead
              ? dayjs(selectedEndDate).format('YYYY-MM-DD')
              : null,
          name,
        },
      });
      const payrollData = queryResult?.findPayrolls;
      dispatch(actions.payrollStateSet(payrollData));
    } catch (err) {
      dispatch(actions.payrollError());
    }
  };

  const handlePrint = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        input: {
          page: 1,
          size: appConfig.maxFetchSize,
          payroll_type: CONSTANT.PAYROLL_TYPE.DAILY.status_code,
          date:
            !findAllCheck && !useDurationInstead
              ? dayjs(selectedDate).format('YYYY-MM-DD')
              : null,
          date_start:
            !findAllCheck && useDurationInstead
              ? dayjs(selectedStartDate).format('YYYY-MM-DD')
              : null,
          date_end:
            !findAllCheck && useDurationInstead
              ? dayjs(selectedEndDate).format('YYYY-MM-DD')
              : null,
        },
      });
      const payrollData = queryResult?.findPayrolls;
      DailyOrMonthyPayrollReport(payrollData?.rows, information, {
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        filterUsingDate: !useDurationInstead,
        selectedDate,
        payroll_type: PAYROLL_TYPE.DAILY.status_code,
      });
    } catch (err) {
      alert(`พิมพ์ PDF ไม่สำเร็จ ${err?.message}`);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [
    name,
    page,
    size,
    selectedDate,
    findAllCheck,
    selectedStartDate,
    selectedEndDate,
    useDurationInstead,
  ]);

  useEffect(() => {
    setTotal(payroll?.total);
    return () => {};
  }, [payroll]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const dailyPayrollTableProps = {
    payroll,
    page,
    size,
    total,
    handleDeletePayroll: async (id) => {
      const confirm = window.confirm('ยืนยันการลบข้อมูล');
      if (confirm) {
        try {
          await dispatch(actions.payrollDelete(id));
          await dispatch(actions.payrollAllQuery({ query }));
        } catch (error) {
          console.error(error);
        }
      }
    },
    handlePushToEditPayroll: async (id) => {
      history.push(`payroll/edit/${id}`);
    },
    handleChangeRowsPerPage: (event) => {
      setSize(event.target.value);
      setPage(1);
    },
    handleChangePage: (event, newPage) => {
      setPage(newPage + 1);
    },
    isDailyPayroll: true,
  };

  const renderAddButtom = () => (
    <div className="flex flex-row gap-2 justify-end pb-4">
      <Link to="daily/create/">
        <Button variant="contained">เพิ่ม</Button>
      </Link>
      <div>
        <Button variant="contained" color="teal" onClick={handlePrint}>
          พิมพ์
        </Button>
      </div>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row justify-between">
        <div className="w-full md:w-1/2">
          <div>
            <TextField
              label="ค้นหา"
              fullWidth
              size={'small'}
              id="outlined-start-adornment"
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <i className="fas fa-search"></i>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="my-4">
            ยอดเงินรวม{' '}
            <span className="text-xl">
              {currencyFormatter.format(payroll?.totalAmount)}{' '}
            </span>
            บาท
          </div>
        </div>
        <div className="w-full md:w-1/4">
          {' '}
          {useDurationInstead ? (
            <div>
              <div className="flex gap-2 flex-wrap">
                <div>
                  <DesktopDatepicker
                    value={selectedStartDate}
                    setValue={setSelectedStartDate}
                    label="ตั้งแต่"
                    inputFormat="DD MMM BBBB"
                  />
                </div>
                <div>
                  <DesktopDatepicker
                    value={selectedEndDate}
                    setValue={setSelectedEndDate}
                    label="จนถึง"
                    inputFormat="DD MMM BBBB"
                  />
                </div>
              </div>
              <div>
                <MonthyUpAndDownButton
                  cursorDate={pamsSetting?.period_date}
                  selectedStartDate={selectedStartDate}
                  setSelectedEndDate={setSelectedEndDate}
                  setSelectedStartDate={setSelectedStartDate}
                />
              </div>
            </div>
          ) : (
            <div>
              <DesktopDatepicker
                value={selectedDate}
                setValue={setSelectedDate}
                label="เดือนที่ต้องการค้นหา"
                inputFormat="DD MMM BBBB"
              />
            </div>
          )}
          <div>
            <FormControlLabel
              label="เลือกแบบช่วงเวลา"
              control={
                <Checkbox
                  checked={useDurationInstead}
                  onChange={() => {
                    setIsUseDurationInstead(!useDurationInstead);
                  }}
                />
              }
            />
          </div>
          <div>
            <FormControlLabel
              label="แสดงทั้งหมด"
              control={
                <Checkbox
                  checked={findAllCheck}
                  onChange={() => {
                    setFindAllCheck(!findAllCheck);
                  }}
                />
              }
            />
          </div>
        </div>
      </div>
    </Card>
  );

  if (payroll.isLoading) {
    return <Loading />;
  }
  if (!payroll.isLoading && payroll.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButtom()}</div>
        </div>
        <div className="my-2">
          <BackButton />
        </div>
        {renderSearch()}
        <div className="my-2">
          <PayrollTable {...dailyPayrollTableProps} />
        </div>
      </div>
    );
  }
  return <Error message={payroll?.message} />;
}

DailyPayrolls.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DailyPayrolls.defaultProps = {
  title: '',
  subtitle: '',
};

export default DailyPayrolls;
