const PAYROLL_TYPE = {
  MONTHY: {
    status_code: "MONTHY",
    description: "รายเดือน",
  },
  DAILY: {
    status_code: "DAILY",
    description: "รายวัน",
  },
};

module.exports = PAYROLL_TYPE;
