import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Card, CardContent } from '@mui/material';
import { gql } from 'graphql-request';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  ProductForm,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';

import { app as appConfig, config } from '../../configs';
import * as actions from '../../redux/actions';

const defaultValue = {
  name: '',
  unit: '',
  packing_size: '',
  packing_unit: '',
  net: '',
  unit_net: '',
  detail: '',
  type_code: '',
};

function CreateProduct({ title, subtitle }) {
  const dispatch = useDispatch();
  const productType = useSelector((state) => state.productType);
  const unit = useSelector((state) => state.unit);

  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm();

  useEffect(() => {
    const getAllUnit = async () => {
      try {
        const query = gql`
        query FindUnits {
          findUnits(input:{page:1,size:${appConfig.maxFetchSize},}){
            rows {
            _id
            name
            }
          }
        } 
      `;
        dispatch(actions.unitAllQuery({ query }));
      } catch (error) {
        console.error('Get All Units Error', error);
      }
    };

    dispatch(actions.productTypeAll(''));
    // TODO: Change to graphql
    dispatch(actions.customerAll({ page: 1, size: config.maxFetchSize }));
    getAllUnit();
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      console.log(data);
      await dispatch(actions.productCreate(data));
      reset(defaultValue);
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      console.error(error);
    }
  };

  if (productType.isLoading) {
    return <Loading />;
  }
  if (!productType.isLoading && productType.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        <BackButton />
        <div className="my-2">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card>
              <CardContent>
                <ProductForm
                  control={control}
                  Controller={Controller}
                  errors={errors}
                  productType={productType}
                  unit={unit}
                  watch={watch}
                />
              </CardContent>
            </Card>
            <div className="flex flex-row justify-end gap-1 py-4">
              <Button variant="contained" type="submit">
                บันทึก
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
  return <Error />;
}

CreateProduct.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateProduct.defaultProps = {
  title: '',
  subtitle: '',
};

export default CreateProduct;
