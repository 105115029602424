import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const ProductTab = ({
  processlist,
  currentProcessId,
  manufacturingOrderId,
  linkPrefix = '/mms/mo',
}) => {
  const renderCurrentTab = (index, _process) => (
    <li className="py-2 px-6 bg-white rounded-t-lg" key={index}>
      <b>
        {_process?.produce_material_instead
          ? _process?.product_as_material?.type_code
          : _process?.product?.type_code}{' '}
      </b>
      {_process?.produce_material_instead
        ? _process?.product_as_material?.name
        : _process?.product?.name}
    </li>
  );

  const renderNonCurrentTab = (index, _process) => (
    <Link
      key={index}
      className="py-2 px-6  rounded-t-lg text-gray-500 bg-gray-200"
      to={`${linkPrefix}/view/${manufacturingOrderId}/${_process?._id}`}
    >
      {' '}
      <b>
        {_process?.produce_material_instead
          ? _process?.product_as_material?.type_code
          : _process?.product?.type_code}{' '}
      </b>
      {_process?.produce_material_instead
        ? _process?.product_as_material?.name
        : _process?.product?.name}
    </Link>
  );

  return (
    <ul className="flex cursor-pointer">
      {_.map(processlist, (_process, index) => {
        if (_process?._id === currentProcessId) {
          console.log('Process Id', _process?._id);
          return renderCurrentTab(index, _process);
        }
        return renderNonCurrentTab(index, _process);
      })}
    </ul>
  );
};

ProductTab.propTypes = {
  processlist: PropTypes.arrayOf(PropTypes.object),
  currentProcessId: PropTypes.string,
  manufacturingOrderId: PropTypes.string,
  linkPrefix: PropTypes.string,
};

export default ProductTab;
