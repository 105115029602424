import { DATA_TYPE } from '@iarcpsu/emanufac-constant';
import dayjs from 'dayjs';
import currencyFormatter from './currrencyFormatter';

const measurementStringRender = (measurement) => {
  switch (measurement?.measurement_type?.data_type) {
    case DATA_TYPE.BOOLEAN.type_code:
      return measurement?.boolean_value
        ? measurement?.measurement_type?.label_right
        : measurement?.measurement_type?.label_left;
    case DATA_TYPE.CHECK_AND_DESCRIBE.type_code:
      return measurement?.boolean_value
        ? `${measurement?.measurement_type?.label_right}${measurement?.additional_value}`
        : measurement?.measurement_type?.label_left;
    case DATA_TYPE.DATE.type_code:
      return measurement?.date_value
        ? dayjs(measurement?.date_value).format('D MMM BBBB')
        : '-';
    case DATA_TYPE.DATETIME.type_code:
      return measurement?.date_value
        ? dayjs(measurement?.date_value).format('D MMM BBBB HH:mm')
        : '-';
    case DATA_TYPE.NUMBER.type_code:
      return measurement?.value
        ? currencyFormatter.format(measurement?.value)
        : '-';
    case DATA_TYPE.PARAGRAPH.type_code:
      return measurement?.value;
    case DATA_TYPE.RADIO_AND_DESCRIBE.type_code:
      return measurement?.boolean_value
        ? measurement?.measurement_type?.label_right
        : measurement?.measurement_type?.label_left;
    case DATA_TYPE.STRING.type_code:
      return measurement?.value;
    case DATA_TYPE.SWITCH.type_code:
      return measurement?.boolean_value
        ? measurement?.measurement_type?.label_right
        : measurement?.measurement_type?.label_left;
    case DATA_TYPE.TIME.type_code:
      return dayjs(measurement?.date_value).format('HH:mm');
    case DATA_TYPE.PERSON.type_code:
      // TODO: Implement person gauge
      return '-';
    default:
      return measurement?.value;
  }
};

export default measurementStringRender;
