import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Avatar,
  CardContent,
} from '@mui/material';
import { useParams, useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import dayjs from 'dayjs';
import * as actions from '../../redux/actions';
import { BackButton } from '@iarcpsu/emanufac-components/src/components/Button';
import { Loading } from '@iarcpsu/emanufac-components/src/components/Loading';
import { Error } from '@iarcpsu/emanufac-components/src/components/Error';
import { ViewTitle } from '@iarcpsu/emanufac-components/src/components/ViewTitle';
import { MATERIAL_PURCHASE_STATUS } from '../../utils/constants';
import { AddInventoryModal } from '@iarcpsu/emanufac-components/src/components/Modals';

export default function DetailPurchaseOrder({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { control, handleSubmit, reset } = useForm();
  const purchaseOrder = useSelector((state) => state.purchaseOrder);
  const me = useSelector((state) => state.me);
  const [name] = useState('');
  const [page] = useState(1);
  const [size] = useState('');
  const [showAddInventoryModal, setIsShowAddInventoryModal] = useState(false);

  useEffect(() => {
    dispatch(actions.purchaseOrderGet(id));
    dispatch(actions.meGet(''));
    return () => {};
  }, [name, page, size]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderEditButton = () => (
    <div className="flex flex-row justify-end pb-4">
      <Button
        variant="contained"
        color="warning"
        onClick={() => {
          history.push(`/pms/purchase-order/edit/${id}`);
        }}
        disabled={
          purchaseOrder?.status !==
          MATERIAL_PURCHASE_STATUS.PURCHASE.status_code
        }
      >
        แก้ไข
      </Button>
    </div>
  );

  const handlePushToCancelPurchaseOrder = async () => {
    const confirmCancel = window.confirm('ยืนยันการยกเลิกคำสั่งซื้อ');
    if (confirmCancel) {
      const data = {
        status: MATERIAL_PURCHASE_STATUS.CANCEL.status_code,
      };
      await dispatch(actions.purchaseOrderPut(id, data));
      dispatch(actions.purchaseOrderGet(id));
    }
  };

  const handlePushToSucessPurchaseOrder = async () => {
    const confirmCancel = window.confirm('ยืนยันการปิดรายการคำสั่งซื้อ');
    if (confirmCancel) {
      const data = {
        status: MATERIAL_PURCHASE_STATUS.SUCCESSFUL.status_code,
      };
      await dispatch(actions.purchaseOrderPut(id, data));
      dispatch(actions.purchaseOrderGet(id));
    }
  };

  const renderButton = () => {
    if (
      purchaseOrder?.status === MATERIAL_PURCHASE_STATUS.PURCHASE.status_code
    ) {
      return (
        <div className="flex flex-row py-2 gap-2">
          <Button
            variant="contained"
            color={'secondary'}
            onClick={() => setIsShowAddInventoryModal(true)}
          >
            เพิ่มวัตถุดิบเข้าคลัง
          </Button>
          <Button
            variant="contained"
            color={'error'}
            onClick={() => {
              handlePushToCancelPurchaseOrder();
            }}
          >
            ยกเลิกคำสั่งซื้อ
          </Button>
        </div>
      );
    }

    if (
      purchaseOrder?.status === MATERIAL_PURCHASE_STATUS.RECEIVED.status_code
    ) {
      return (
        <div className="flex flex-row py-2 gap-2">
          <Button
            variant="contained"
            color={'secondary'}
            onClick={() => setIsShowAddInventoryModal(true)}
          >
            เพิ่มวัตถุดิบเข้าคลัง
          </Button>
          <Button
            variant="contained"
            color={'success'}
            onClick={() => {
              handlePushToSucessPurchaseOrder();
            }}
          >
            ปิดรายการ
          </Button>
        </div>
      );
    }
  };

  const displayMaterial = () => (
    <TableContainer>
      <Table size="medium" className="border-lg mt-2">
        <TableHead className="bg-blue-100">
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ชื่อวัตถุดิบ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> จำนวนที่สั่ง</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> จำนวนทีได้รับ</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!_.isEmpty(purchaseOrder?.material) ? (
            purchaseOrder?.material.map((_material, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  <div className="flex flex-wrap">
                    <div className="font-semibold font-display">
                      {_material?.material?.type_code}
                    </div>
                    <div className="ml-2">{_material?.material?.name}</div>
                  </div>
                </TableCell>
                <TableCell>
                  {_material?.quantity} {_material?.material?.unit}
                </TableCell>
                <TableCell>
                  {_material?.quantity - _material?.amount}{' '}
                  {_material?.material?.unit}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow key={''}>
              <TableCell colSpan={6}>
                <div className="text-center">ไม่มีข้อมูล</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderDetail = () => (
    <Card className="p-4">
      <div className="flex flex-wrap">
        <div className="w-full p-2 text-lg font-semibold ">{'รายละเอียด'}</div>
        <div className="w-1/2 py-2 px-2 font-semibold ">{'เลขที่บิล'}</div>
        <div className="w-1/2 py-2 ">{`${
          purchaseOrder?.bill_number || '-'
        }`}</div>
        <div className="w-1/2 py-2 px-2 font-semibold ">{'คู่ค้า'}</div>
        <div className="w-1/2 py-2  bg-gray-100 mb-2 p-3 rounded-md text-sm">
          <div>
            <b>ชื่อ :</b> {purchaseOrder?.supplier?.name || ' - '}
          </div>
          <div>
            <b>อีเมล : </b>
            {purchaseOrder?.supplier?.email || ' - '}
          </div>
          <div>
            <b>โทร : </b>
            {purchaseOrder?.supplier?.phone_number || ' - '}
          </div>
          <div>
            <b>ที่อยู่ : </b>
            {purchaseOrder?.supplier?.address || ' - '}
          </div>
        </div>
        <div className="w-1/2 py-2 px-2 font-semibold ">{'แหล่งวัตถุดิบ'}</div>
        <div className="w-1/2 py-2 ">{`${purchaseOrder?.source || '-'}`}</div>
        <div className="w-1/2 py-2 px-2 font-semibold ">{'ผู้สั่งซื้อ'}</div>
        <div className="w-1/2 py-2 ">
          {`${purchaseOrder?.purchaser?.firstname || '-'}`}{' '}
          {`${purchaseOrder?.purchaser?.lastname || ''}`}
        </div>
        <div className="w-1/2 py-2 px-2 font-semibold ">
          {'วัตถุดิบที่สั่งซื้อ'}
        </div>
      </div>
      <div className="p-2 w-full">{displayMaterial()}</div>
    </Card>
  );

  const renderStatus = () => (
    <div className="my-2">
      <Card className="p-4">
        <div className="flex flex-wrap">
          <div className="w-1/6 py-2 px-2 font-semibold ">{'สถานะ'}</div>
          <div className="w-4/6 py-2 text-center">
            {MATERIAL_PURCHASE_STATUS[purchaseOrder?.status]?.description}{' '}
          </div>
        </div>
        <div className="flex justify-center">{renderButton()}</div>
      </Card>
    </div>
  );

  const onSubmit = async (data) => {
    try {
      const commentData = {
        comments: [
          ..._.map(purchaseOrder?.comments, (_comment) => ({
            ..._comment,
            _id: undefined,
          })),
          {
            comment: data.comment,
            comment_date: new Date(),
            commentator: me?.userData?._id,
          },
        ],
      };

      await dispatch(actions.purchaseOrderPut(id, commentData));
      dispatch(actions.purchaseOrderGet(id));
      reset({ comment: '' });
    } catch (error) {
      window.alert('ความคิดเห็นไม่สำเร็จ');
      console.error('Comment Unsuccessful', error);
    }
  };

  const renderComment = () => (
    <div className="my-2">
      <Card className="p-4">
        <div className="w-full py-2 text-lg font-semibold ">
          {'ความคิดเห็น'}
        </div>
        {purchaseOrder && !_.isEmpty(purchaseOrder?.comments) ? (
          <div className="my-2">
            {purchaseOrder?.comments.map((_comment) => (
              <div key={_comment?._id} className="flex flex-row gap-2">
                <Avatar
                  src={_comment?.commentator?.image?.url}
                  className="uppercase mt-6"
                >
                  {_.first(_comment?.commentator?.firstname)}
                  {_.first(_comment?.commentator?.lastname)}
                </Avatar>
                <Card className="my-2">
                  <CardContent>
                    <div className="text-gray-500 flex">
                      <div className="self-center">
                        {' '}
                        <span className="font-bold text-black">
                          {_comment?.commentator?.firstname}{' '}
                          {_comment?.commentator?.lastname}
                        </span>{' '}
                        <br />
                        เมื่อวันที่{' '}
                        {dayjs(_comment?.comment_date).format(
                          'D MMM BBBB เวลา HH.mm น.',
                        )}
                      </div>
                    </div>
                    <div className="mt-1 font-system">{_comment?.comment}</div>
                  </CardContent>
                </Card>
              </div>
            ))}
          </div>
        ) : (
          // <div className="text-center text-red-600 p-4">ไม่มีความคิดเห็น</div>
          <div></div>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="comment"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                label="ความคิดเห็น"
                fullWidth
                required
                multiline
                rows={2}
              />
            )}
          />
          <div className="flex justify-end mt-2">
            <Button type="submit" color="primary" variant="contained">
              แสดงความคิดเห็น
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );

  const renderModal = () => (
    <AddInventoryModal
      isOpen={showAddInventoryModal}
      handleClose={() => setIsShowAddInventoryModal(false)}
      purchaseOrder={purchaseOrder}
    />
  );

  if (purchaseOrder.isLoading || purchaseOrder.rows) {
    return <Loading />;
  }
  if (!purchaseOrder.isLoading && purchaseOrder.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-between pb-4">
          <div>
            <BackButton />
          </div>
          <div>{renderEditButton()}</div>
        </div>
        {renderModal()}
        {renderDetail()}
        {renderStatus()}
        {renderComment()}
      </div>
    );
  }
  return <Error />;
}

DetailPurchaseOrder.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailPurchaseOrder.defaultProps = {
  title: '',
  subtitle: '',
};
