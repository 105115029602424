import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button, Chip } from '@mui/material';
import PropTypes from 'prop-types';

import { PassportAuth } from '@/contexts/AuthContext';

export function ContentNavbar({ onMobileNavOpen, isShowMobileNav = true }) {
  const information = useSelector((state) => state.information);
  const { handleSignout } = useContext(PassportAuth);
  const history = useHistory();
  const onSignOut = async () => {
    await handleSignout();
    history.push('/login');
  };
  const [navbarOpen] = React.useState(false);

  return (
    <>
      <nav className="top-0 fixed z-10 w-full flex flex-wrap items-center justify-between px-2 py-3  navbar-expand-lg bg-white shadow-xl print-disable">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start font-sans">
            <div className="flex gap-2">
              <Link
                to="/"
                className="text-gray-600 text-base font-bold leading-relaxed mr-4 py-2 whitespace-no-wrap flex"
              >
                <img src={information?.logo?.url} className="h-10 rounded-md" />
                <div className="my-auto pl-4">
                  {information?.name || 'E-Manufac'}{' '}
                  {process.env.NODE_ENV === 'development' && (
                    <Chip label="Dev" color="warning" />
                  )}
                </div>
              </Link>
            </div>
            <div className="lg:hidden block py-2 px-3 text-gray-600">
              <Button
                size="xs"
                variant="outlined"
                onClick={() => {
                  onSignOut();
                }}
              >
                <i className="fas fa-sign-out-alt"></i>
              </Button>
            </div>
          </div>
          <div
            className={`lg:flex flex-grow items-center bg-white text-white lg:bg-transparent lg:shadow-none${
              navbarOpen ? ' block' : ' hidden'
            }`}
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="flex items-center">
                <Link to="/auth/login">
                  <Button
                    size="xs"
                    variant="outlined"
                    startIcon={<i className="fas fa-sign-out-alt"></i>}
                    onClick={() => {
                      onSignOut();
                    }}
                  >
                    ออกจากระบบ
                  </Button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
ContentNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
  isShowMobileNav: PropTypes.bool,
};

ContentNavbar.defaultProps = {
  isShowMobileNav: true,
};

export default ContentNavbar;
