import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

import PropTypes from 'prop-types';

const BooleanAdditionalInput = ({ control }) => (
  <div className="flex flex-wrap w-full">
    <div className="w-full lg:w-1/2 py-1 ">
      <Controller
        control={control}
        name="label_left"
        render={({ field }) => (
          <TextField
            {...field}
            size="small"
            label="ข้อความที่ต้องการแสดงเมื่อเป็น False"
            fullWidth
          />
        )}
      />
    </div>
    <div className="w-full lg:w-1/2 py-1 ">
      <Controller
        control={control}
        name="label_right"
        render={({ field }) => (
          <TextField
            {...field}
            size="small"
            label="ข้อความที่ต้องการแสดงเมื่อเป็น True"
            fullWidth
          />
        )}
      />
    </div>
  </div>
);

BooleanAdditionalInput.propTypes = { control: PropTypes.object };

export default BooleanAdditionalInput;
