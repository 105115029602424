import React from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, Controller } from 'react-hook-form';
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Button,
} from '@mui/material';
import _ from 'lodash';

import MultiUnitProdOrMatBox from '../../Box/Rendering/MultiUnitProdOrMatBox';
import ProdOrMatQuantityInput from '../../Inputs/ProdOrMatQuantityInput';

const EachProductWarehouseDistributeForm = ({
  control,
  watch,
  setValue,
  warehouse,
  controllerName,
  selectedProduct,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: controllerName,
  });
  return (
    <div>
      {_.map(fields, (eachField, index) => (
        <div key={eachField?.id}>
          <div className="w-full py-2 px-1">
            <Controller
              name={`${controllerName}[${index}].warehouse`}
              control={control}
              render={({ field }) => (
                <FormControl fullWidth size="small">
                  <InputLabel>คลังสินค้า</InputLabel>
                  <Select {...field} fullWidth size="small" label="คลังสินค้า">
                    {_.map(warehouse?.rows, (eachWarehouse, warehouseIndex) => (
                      <MenuItem key={warehouseIndex} value={eachWarehouse?._id}>
                        {eachWarehouse?.name}{' '}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </div>

          <div className="flex gap-2">
            <div className="w-3/4 self-center">
              <div className="w-full py-2 px-1">
                <ProdOrMatQuantityInput
                  controllerName={`${controllerName}[${index}].quantity`}
                  index={index}
                  artificialControllerName={`${controllerName}[${index}].aritifical_quantity`}
                  selectedUnitControllerName={`${controllerName}[${index}].selected_unit`}
                  setValue={setValue}
                  watch={watch}
                  control={control}
                  selectedProduct={selectedProduct}
                />
              </div>
            </div>

            <div className="w-1/4 self-center">
              <Button
                size="small"
                variant="contained"
                onClick={() => remove(index)}
                color="error"
              >
                ลบ
              </Button>
            </div>
          </div>
        </div>
      ))}
      <div>
        <Button size="small" variant="outlined" onClick={() => append({})}>
          เพิ่ม
        </Button>
      </div>
    </div>
  );
};

/**
 * @function WarehouseApproveRequestForm
 * @memberof Form/WMS
 * @description ฟอร์มสำหรับการอนุมิตการเพิ่มเข้าคลังสินค้า กรณีที่เปิดใช้งานการขออนุมัติเข้าคลัง
 * @returns {React.Component} React Component
 * @author theethawat
 */

const WarehouseApproveRequestForm = ({
  control,
  watch,
  setValue,
  warehouse,
  productTransactionType,
}) => {
  const { fields } = useFieldArray({ control, name: 'products' });
  const addedDirProdTxnType = _.filter(productTransactionType?.rows, {
    direction: 'add',
  });
  return (
    <div>
      <div className="my-2">
        <div className="w-full py-2 px-1">
          <Controller
            name="product_transaction_type"
            defaultValue={addedDirProdTxnType?.[0]?._id}
            control={control}
            render={({ field }) => (
              <FormControl fullWidth size="small">
                <InputLabel>ประเภทการดำเนินการ</InputLabel>
                <Select
                  size="small"
                  fullWidth
                  required
                  label="ประเภทการดำเนินการ"
                  {...field}
                >
                  {_.map(addedDirProdTxnType, (eachProdTxnType, index) => (
                    <MenuItem key={index} value={eachProdTxnType?._id}>
                      {eachProdTxnType?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </div>
      </div>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> สินค้า</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">คลังและล็อตสินค้า</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(fields, (eachField, index) => (
            <TableRow
              key={eachField?.id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              <TableCell component="th" scope="row">
                <div className="text-xs">{index + 1}</div>
              </TableCell>
              <TableCell>
                {eachField?.product?.type_code} {eachField?.product?.name}
                <div>
                  <MultiUnitProdOrMatBox
                    foundMaterial={eachField?.product}
                    quantity={eachField?.quantity}
                    noWrap
                  />
                </div>
              </TableCell>
              <TableCell>
                <EachProductWarehouseDistributeForm
                  control={control}
                  controllerName={`products[${index}].stocklots`}
                  setValue={setValue}
                  warehouse={warehouse}
                  watch={watch}
                  selectedProduct={eachField?.product}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className="my-2">
        <div className="w-full py-2 px-1">
          <Controller
            name="approver_remak"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                multiline
                rows={3}
                size="small"
                label="หมายเหตุ"
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

WarehouseApproveRequestForm.propTypes = {
  control: PropTypes.object,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  warehouse: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  productTransactionType: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};

EachProductWarehouseDistributeForm.propTypes = {
  control: PropTypes.object,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  warehouse: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  controllerName: PropTypes.string,
  selectedProduct: PropTypes.object,
};

export default WarehouseApproveRequestForm;
