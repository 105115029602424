/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
import { MANUFAC_ORDER_STATUS } from '@iarcpsu/emanufac-constant';
import dayjs from 'dayjs';

const ManufacturingOrderColumn = (information) => {
  const mmsSetting = information?.setting?.mms;
  const columns = [];

  columns.push({
    label: 'ลำดับที่',
    value: 'index',
  });

  columns.push({
    label: 'หมายเลขใบสั่งผลิต',
    value: (col) => `${col?.prefix}${col?.running_number}`,
  });

  columns.push({
    label: 'สถานะ',
    value: (col) => MANUFAC_ORDER_STATUS[col?.status]?.description,
  });

  if (mmsSetting?.manufacturingOrder?.showQuotation) {
    columns.push({
      label: 'หมายเลขใบเสนอราคา',
      value: (col) =>
        col?.quotation
          ? `${col?.quotation?.prefix || ''}${
              col?.quotation?.running_number || '-'
            }`
          : col?.quotation_number || '-',
    });
  }

  if (mmsSetting?.manufacturingOrder?.showPaymentMethod) {
    columns.push({
      label: 'ช่องทางการชำระเงิน',
      value: (col) => col?.payment_method?.name || '-',
    });
  }

  if (mmsSetting?.manufacturingOrder?.showPO) {
    columns.push({
      label: 'หมายเลข PO',
      value: (col) => col?.po_number || '-',
    });
  }

  columns.push({
    label: 'วันที่สั่งผลิต',
    value: (col) => dayjs(col?.start_date).format('D/MM/BBBB'),
  });

  if (mmsSetting?.usingAsDefault?.showExpectedDate) {
    columns.push({
      label: 'วันที่ต้องการสินค้า',
      value: (col) => dayjs(col?.expected_date).format('D/MM/BBBB'),
    });
  }

  columns.push({
    label: 'รหัสลูกค้า',
    value: (col) => col?.customer?.type_code || '-',
  });

  columns.push({
    label: 'ลูกค้า',
    value: (col) => col?.customer?.name || '-',
  });

  columns.push({
    label: 'ผู้สั่งผลิต',
    value: (col) =>
      `${col?.assigner?.firstname || '-'} ${col?.assigner?.lastname || ''}`,
  });

  columns.push({
    label: 'หมายเหตุ',
    value: (col) => col?.remark || '-',
  });

  return columns;
};

export default ManufacturingOrderColumn;
