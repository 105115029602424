import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import {
  BackButton,
  CardCustomer,
  CustomerCommentBox,
  CustomerRatingBox,
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import * as actions from '../../redux/actions';

export default function DetailCustomer({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const customer = useSelector((state) => state.customer);
  const customerComment = useSelector((state) => state.customerComment);
  const customerRating = useSelector((state) => state.customerRating);
  const me = useSelector((state) => state.me);
  const [size, setSize] = useState(10);

  useEffect(() => {
    dispatch(actions.customerGet(id));
    return () => {};
  }, []);

  const customerCommentAll = async () => {
    try {
      dispatch(
        actions.customerCommentAll({
          page: 1,
          size,
          customer: id,
        }),
      );
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'ไม่สามารถดึงคอมเมนต์ถึงคู่ค้า/ลูกค้าค้นนี้ได้',
        text: error?.message,
      });
    }
  };

  const customerRatingAll = async () => {
    try {
      dispatch(actions.customerRatingAll({ customer: id, page: 1, size: 10 }));
    } catch (error) {
      console.error('Customer Rating All Error', error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleSubmitComment = async (data) => {
    try {
      await dispatch(
        actions.customerCommentCreate({
          ...data,
          customer: id,
        }),
      );
      customerCommentAll();
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: error?.message,
      });
    }
  };

  useEffect(() => {
    customerCommentAll();
    customerRatingAll();
    return () => {};
  }, [size]);

  const handleDeleteComment = async (commentId) => {
    try {
      Swal.fire({
        icon: 'warning',
        title: 'ยืนยันการลบคอมเมนต์',
        showCancelButton: true,
        showConfirmButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await dispatch(actions.customerCommentDelete(commentId));
          customerCommentAll();
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'ไม่สามารถลบได้',
        text: error?.message,
      });
    }
  };

  const handleUpdateRating = async (nextRating) => {
    try {
      Swal.fire({
        icon: 'warning',
        title: 'ยืนยันการอัพเดท Rating คู่ค้า/ลูกค้า',
        showCancelButton: true,
        showConfirmButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await dispatch(
            actions.customerPut(id, {
              rating: nextRating,
              employee: me?.userData?._id,
            }),
          );
          dispatch(actions.customerGet(id));
          customerRatingAll();
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'ไม่สามารถลบได้',
        text: error?.message,
      });
    }
  };

  if (customer.isLoading || customer.rows) {
    return <Loading />;
  }
  if (!customer.isLoading && customer.isCompleted) {
    return (
      <div>
        <div className="flex flex-row justify-between items-center">
          {renderTitle()}
          <Link to={`/crm/customer/edit/${id}`}>
            <Button variant="contained" color="warning">
              แก้ไข
            </Button>
          </Link>
        </div>
        <div>
          <BackButton />
        </div>
        <div className="my-2">
          <CardCustomer customer={customer} />
        </div>
        <div className="my-2">
          <CustomerRatingBox
            customer={customer}
            handleUpdateRating={handleUpdateRating}
            customerRating={customerRating}
          />
        </div>
        <div className="my-2">
          <CustomerCommentBox
            allowDelete
            comment={customerComment}
            container
            handleDeleteComment={handleDeleteComment}
            handleSubmitComment={handleSubmitComment}
            size={size}
            setSize={setSize}
            showTitle
          />
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailCustomer.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailCustomer.defaultProps = {
  title: '',
  subtitle: '',
};
