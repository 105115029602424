import React, { useEffect, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import graphqlClient from '@/configs/graphqlClient';

import MaterialStockLotTable from '../../Tables/IMS-WMS/MaterialStockLotWithOutMaterialNameTable';
import MultiUnitProdOrMatBox from '../Rendering/MultiUnitProdOrMatBox';

function MaterialOnBomBox({ bomObject, productAmount }) {
  const [stocklotData, setStocklotData] = useState(null);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const query = gql`
    query FindMaterial($materialStockLotInput: MaterialStockLotInput) {
      findMaterialStockLots(input: $materialStockLotInput) {
        rows {
          _id
          amount
          quantity
          running_number
          lot_number
          receipt_date
          production_date
          expiration_date
          receipt_date
        }
        quantity
        amount
        total
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        materialStockLotInput: {
          material: bomObject?.material._id,
        },
      });
      const tempStockLot = queryResult?.findMaterialStockLots;
      setStocklotData(tempStockLot);
      console.log('Founed Product', tempStockLot);
    } catch (error) {
      console.error('Product Get Error', error);
    }
  };

  useEffect(() => {
    if (bomObject) {
      queryDataFromServer();
    }

    return () => {};
  }, [bomObject]);

  return (
    <div>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-1/3">
          <div className="font-semibold">
            {bomObject?.material?.type_code} {bomObject?.material?.name}{' '}
          </div>
          <div className="text-sm">สัดส่วน {bomObject?.amount}</div>
          <div className="my-2">
            <div className="text-sm">ปริมาณที่มี</div>
            <MultiUnitProdOrMatBox
              foundMaterial={bomObject?.material}
              quantity={stocklotData?.amount}
            />
          </div>
          <div className="my-2">
            <div className="text-sm">ต้องการใช้</div>
            <MultiUnitProdOrMatBox
              foundMaterial={bomObject?.material}
              quantity={productAmount * bomObject?.amount}
            />
          </div>
          {parseFloat(productAmount * bomObject?.amount) <
          stocklotData?.amount ? (
            <div>
              <div className="font-semibold text-xl text-green-500">
                เพียงพอ
              </div>
            </div>
          ) : (
            <div>
              <div className="font-semibold text-xl text-red-500">
                ไม่เพียงพอ
              </div>
            </div>
          )}
        </div>
        <div className="w-full lg:w-2/3">
          <Accordion>
            <AccordionSummary expandIcon={<ChevronDown />}>
              รายละเอียดล็อตวัตถุดิบ
            </AccordionSummary>
            <AccordionDetails>
              <MaterialStockLotTable
                foundedMaterialStockLot={stocklotData}
                page={page}
                setPage={setPage}
                size={size}
                setSize={setSize}
                selectedMaterial={bomObject?.material}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default MaterialOnBomBox;

MaterialOnBomBox.propTypes = {
  bomObject: PropTypes.object,
  productAmount: PropTypes.number,
};
