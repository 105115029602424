import React from 'react';
import { CRM_TYPE } from '@iarcpsu/emanufac-constant';
import { Card, Chip } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

const CardCustomer = ({ customer }) => (
  <div>
    <Card className="p-4 ">
      <div className="flex flex-wrap divide-y">
        <div className="w-full py-4 text-lg font-semibold   font-display">
          รายละเอียด
        </div>
        <div className="w-1/2 py-2 px-2 font-semibold  font-display ">ชื่อ</div>
        <div className="w-1/2 py-2 ">
          {customer?.type_code} {customer.name || '-'}{' '}
          {customer?.is_headquator && (
            <Chip label="สำนักงานใหญ่" color="primary" size="small" />
          )}
          {!customer?.is_headquator && (
            <div>
              {customer?.branch_code} สาขา {customer?.branch_name || '-'}{' '}
            </div>
          )}
        </div>
        <div className="w-1/2 py-2 px-2 font-semibold  font-display ">
          {'ประเภท'}
        </div>
        <div className="w-1/2 py-2 flex items-center ">
          {`${customer.type?.name || ''}`}
          <Chip label={CRM_TYPE[customer?.status]?.description} size="small" />
        </div>

        <div className="w-1/2 py-2 px-2 font-semibold  font-display ">
          {'ประเภทธุรกิจ'}
        </div>
        <div className="w-1/2 py-2 ">{`${
          customer.organization_type || ''
        }`}</div>
        <div className="w-1/2 py-2 px-2 font-semibold  font-display ">
          {'ที่อยู่'}
        </div>
        <div className="w-1/2 py-2 ">
          {customer.address}
          <div>โทร {customer.phone_number || '-'}</div>
          <div>แฟกซ์ {customer.fax || '-'}</div>
          <div>อีเมล {customer.email || '-'}</div>
        </div>
        <div className="w-1/2 py-2 px-2 font-semibold  font-display ">
          {'เลขที่ผู้เสียภาษี'}
        </div>
        <div className="w-1/2 py-2 ">{`${customer.taxes || '-'}`}</div>

        <div className="w-1/2 py-2 px-2 font-semibold  font-display ">
          รูปแบบการชำระเงิน <span className="font-normal">(โดยปกติ)</span>
        </div>
        <div className="w-1/2 py-2 ">{customer?.payment?.name || ''}</div>
        <div className="w-1/2 py-2 px-2 font-semibold  font-display ">
          เริ่มมีข้อมูลในระบบ
        </div>
        <div className="w-1/2 py-2 ">
          {dayjs(customer?.createdAt).format('D MMMM BBBB')}
        </div>

        {!_.isEmpty(customer?.contact) ? (
          <>
            <div className="w-1/2 py-4 px-2 font-semibold  font-display ">
              {'ผู้ติดต่อ'}
            </div>
            <div className="w-1/2 py-4 ">
              <div className="flex flex-wrap">
                {_.map(customer?.contact, (_contact) => (
                  <div
                    key={_contact?._id}
                    className="bg-gray-300 mb-2 p-3 rounded-md text-sm mr-2"
                  >
                    <div>
                      <b>ชื่อ :</b> {`${_contact?.name}`}
                    </div>
                    <div>
                      <b>โทร :</b>
                      {` ${_contact?.phone_number}`}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </Card>
  </div>
);

export default CardCustomer;

CardCustomer.propTypes = {
  customer: PropTypes.object,
};
