import {
  FACTORY_CAPITAL_COST_ALL,
  FACTORY_CAPITAL_COST_DELETE,
  FACTORY_CAPITAL_COST_ERROR,
  FACTORY_CAPITAL_COST_GET,
  FACTORY_CAPITAL_COST_LOADING,
  FACTORY_CAPITAL_COST_POST,
  FACTORY_CAPITAL_COST_PUT,
} from '../../actions/types';
import { BaseReducer } from '../../class';

const basedReducerObject = new BaseReducer({
  allConst: FACTORY_CAPITAL_COST_ALL,
  getConst: FACTORY_CAPITAL_COST_GET,
  postConst: FACTORY_CAPITAL_COST_POST,
  putConst: FACTORY_CAPITAL_COST_PUT,
  deleteConst: FACTORY_CAPITAL_COST_DELETE,
  errorConst: FACTORY_CAPITAL_COST_ERROR,
  loadingConst: FACTORY_CAPITAL_COST_LOADING,
});

const initialState = {
  isLoading: true,
  isCompleted: true,
};

const FactoryCapitalCostReducer = (state = initialState, action) =>
  basedReducerObject.getReducer(state, action);

export default FactoryCapitalCostReducer;
