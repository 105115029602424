import React, { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardContent } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { config } from '../../../configs';
import * as parentActions from '../../../redux/actions';
import {
  HeadTailLostAndMachineDetail,
  MetalDivideEditTable,
  MetalDivideForm,
  MetalDivideIllustrator,
  MetalWasteForm,
} from '../components';
import * as actions from '../redux/actions';

const EditMetalDivide = ({
  manufacturingOrder,
  currentProcess,
  currentStep,
}) => {
  const bjMetalDividing = useSelector((state) => state.bjMetalDividing);
  const customer = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: bjMetalDividing?.rows?.[0],
  });

  const { fields, remove } = useFieldArray({
    control,
    name: 'divide_sets',
  });

  useEffect(() => {
    if (bjMetalDividing?.rows && bjMetalDividing?.rows?.[0]) {
      setValue('divide_sets', bjMetalDividing?.rows?.[0]?.divide_sets);
    }
    return () => {};
  }, [bjMetalDividing]);

  const fetchMetalDivide = () => {
    dispatch(
      actions.metalDivideAll({
        selectProcess: currentProcess?._id,
        size: config.maxFetchSize,
      }),
    );
  };

  useEffect(() => {
    fetchMetalDivide();
    dispatch(
      parentActions.customerAll({
        page: 1,
        size: config.maxFetchSize,
      }),
    );

    return () => {};
  }, []);

  const allMeasurement = [];
  _.map(currentProcess?.steps, (eachStep) => {
    _.map(eachStep?.measurements, (eachMeasurement) => {
      allMeasurement.push(eachMeasurement);
    });
  });

  const lostHead = _.find(
    allMeasurement,
    (eachMeasurement) =>
      eachMeasurement?.measurement_type?.type_code === 'CONCRETE_HEAD_LOST',
  );

  const lostTail = _.find(
    allMeasurement,
    (eachMeasurement) =>
      eachMeasurement?.measurement_type?.type_code === 'CONCRETE_TAIL_LOST',
  );

  const handleCreateNewRail = async () => {
    try {
      await dispatch(
        actions.metalDivideCreate({
          process: currentProcess?._id,
          manufacturing_order: manufacturingOrder?._id,
        }),
      );
      alert('สำเร็จ');
      fetchMetalDivide();
    } catch (error) {
      alert(`ไม่สามารถสร้างรางลวดได้ ${error?.message}`);
    }
  };

  const handleEditMetalDivide = async (data) => {
    try {
      await dispatch(
        actions.metalDividePut(bjMetalDividing?.rows?.[0]?._id, {
          ...data,
          is_waste: false,
          lost: 0,
        }),
      );
      fetchMetalDivide();
    } catch (error) {
      alert(`แก้ไขไม่สำเร็จ ${error?.message}`);
    }
  };

  const handleToDeleteRecord = async (deleteIndex) => {
    try {
      await remove(deleteIndex);
      const divigingField = fields;
      delete divigingField[deleteIndex];
      const filterDividingField = divigingField?.filter((n) => n);
      await dispatch(
        actions.metalDividePut(bjMetalDividing?.rows?.[0]?._id, {
          divide_sets: filterDividingField,
        }),
      );
      fetchMetalDivide();
    } catch (error) {
      alert(`แก้ไขไม่สำเร็จ ${error?.message}`);
    }
  };

  const handleCreateWasteRecord = async (data) => {
    try {
      await dispatch(
        actions.metalDividePut(bjMetalDividing?.rows?.[0]?._id, {
          ...data,
          divide_sets: _.map(data?.divide_sets, (each, index) => {
            if (index === _.size(data?.divide_sets) - 1) {
              return {
                ...each,
                is_waste: true,
                length: 0,
                amount: 1,
                customer: null,
              };
            }
            return each;
          }),
        }),
      );
      fetchMetalDivide();
    } catch (error) {
      alert(`แก้ไขไม่สำเร็จ ${error?.message}`);
    }
  };

  const renderCreateNew = () => (
    <div className="w-full flex  justify-center my-4">
      <div>
        <div>ยังไม่มีรายการรางลวดในสินค้านี้</div>
        <div className="flex justify-center my-2">
          <Button
            variant="contained"
            size="small"
            className="justify-center"
            onClick={handleCreateNewRail}
          >
            สร้างใหม่
          </Button>
        </div>
      </div>
    </div>
  );

  const renderMachineAndLost = () => (
    <div className="my-2">
      <HeadTailLostAndMachineDetail
        lostHead={lostHead}
        lostTail={lostTail}
        machine={currentProcess?.machine}
      />
    </div>
  );

  const renderMetalDevideIllustrator = () => (
    <div>
      <MetalDivideIllustrator
        machine={currentProcess?.machine}
        metalDivides={bjMetalDividing?.rows?.[0]?.divide_sets}
        metalDivideId={bjMetalDividing?.rows?.[0]?._id}
        lostHead={lostHead?.value}
        lostTail={lostTail?.value}
        manufacturingOrder={manufacturingOrder}
        currentProcess={currentProcess}
      />
    </div>
  );

  if (bjMetalDividing?.isLoading) return <div>Loading...</div>;

  if (!bjMetalDividing?.isLoading && _.isEmpty(bjMetalDividing?.rows)) {
    return renderCreateNew();
  }

  if (!bjMetalDividing?.isLoading && bjMetalDividing?.isCompleted) {
    return (
      <div className="my-2 ">
        <form>
          {renderMetalDevideIllustrator()}
          <div className="font-semibold font-display my-2">
            ข้อมูลรางการผลิต
          </div>
          {renderMachineAndLost()}
          <div className="font-semibold font-display my-4">ผลการตัดลวด</div>
          <MetalDivideEditTable
            eachMetalDividing={bjMetalDividing?.rows?.[0]}
            handleDelete={handleToDeleteRecord}
            handleEdit={() => {}}
            machine={currentProcess?.machine}
          />
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/2 px-2">
              <div className="font-semibold font-display my-4">
                บันทึกการตัดแผ่นคอนกรีต
              </div>
              <Card>
                <CardContent>
                  <div className="w-full">
                    <MetalDivideForm
                      control={control}
                      customer={customer}
                      setValue={setValue}
                      watch={watch}
                      index={_.size(fields)}
                    />
                  </div>
                  <div className="w-full flex justify-end">
                    <Button
                      size="small"
                      variant="contained"
                      type="button"
                      onClick={handleSubmit(handleEditMetalDivide)}
                    >
                      เพิ่ม
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </div>
            <div className="w-full lg:w-1/2 px-2">
              <div className="font-semibold font-display my-4">
                บันทึกของเสีย
              </div>
              <Card>
                <CardContent>
                  <div className="w-full">
                    <MetalWasteForm
                      control={control}
                      customer={customer}
                      setValue={setValue}
                      watch={watch}
                      index={_.size(fields)}
                      machine={currentProcess?.machine}
                    />
                  </div>
                  <div className="w-full flex justify-end">
                    <Button
                      size="small"
                      variant="contained"
                      type="button"
                      onClick={(data) =>
                        handleSubmit(handleCreateWasteRecord)(data)
                      }
                    >
                      เพิ่ม
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </form>
      </div>
    );
  }

  return <div>ไม่สามารถโหลดปลั๊กอินการบันทึกการตัดลวดได้</div>;
};

EditMetalDivide.propTypes = {
  manufacturingOrder: PropTypes.object,
  currentProcess: PropTypes.object,
  currentStep: PropTypes.object,
};

export default EditMetalDivide;
