/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import PropTypes from 'prop-types';

dayjs.extend(durationPlugin);
dayjs.extend(relativeTime);

const LotDurationInput = ({
  control,
  previousLotDate,
  watch,
  controllerKey,
  setValue,
  defaultValue,
}) => {
  const estimateDuration = dayjs().diff(dayjs(previousLotDate), 'minute');
  const hourUsage = defaultValue
    ? Math.floor(defaultValue / 60)
    : Math.floor(estimateDuration / 60);
  const minuteUsage = defaultValue ? defaultValue % 60 : estimateDuration % 60;

  useEffect(() => {
    if (previousLotDate) {
      setValue(controllerKey, estimateDuration);
    }
    if (defaultValue) {
      setValue(controllerKey, defaultValue);
      const tempHourUsage = Math.floor(defaultValue / 60);
      const tempMinUsage = defaultValue % 60;
      setValue(`${controllerKey}_hour`, tempHourUsage);
      setValue(`${controllerKey}_minute`, tempMinUsage);
    }
    return () => {};
  }, [previousLotDate]);

  return (
    <div>
      {previousLotDate && (
        <div className="text-xs">
          ล็อตเดิมเกิดขึ้นเวลา{' '}
          {dayjs(previousLotDate).format('D MMM BBBB HH:mm')}{' '}
          คาดการณ์ว่ากระบวนการนี้ใช้เวลา {estimateDuration} นาที
        </div>
      )}
      <div className="flex py-2">
        <div className="w-1/2 mr-1">
          <Controller
            name={`${controllerKey}_hour`}
            control={control}
            defaultValue={hourUsage}
            render={({ field }) => (
              <TextField
                {...field}
                onChange={(e) => {
                  field.onChange(e);

                  const tempDuration =
                    parseFloat(e.target.value) * 60 +
                    parseFloat(watch(`${controllerKey}_minute`));
                  setValue(controllerKey, tempDuration);
                }}
                size="small"
                label="ชั่วโมง"
                type="number"
                fullWidth
              />
            )}
          />
        </div>
        <div className="w-1/2 ml-1">
          <Controller
            name={`${controllerKey}_minute`}
            control={control}
            defaultValue={minuteUsage}
            render={({ field }) => (
              <TextField
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  const tempDuration =
                    parseFloat(watch(`${controllerKey}_hour`)) * 60 +
                    parseFloat(e.target.value);
                  setValue(controllerKey, tempDuration);
                }}
                size="small"
                label="นาที"
                type="number"
                fullWidth
              />
            )}
          />
        </div>
      </div>
      <div className="text-xs">หรือ {watch(controllerKey)} นาที</div>
    </div>
  );
};

export default LotDurationInput;

LotDurationInput.propTypes = {
  control: PropTypes.object,
  previousLotDate: PropTypes.object,
  watch: PropTypes.func,
  controllerKey: PropTypes.string,
  setValue: PropTypes.func,
  defaultValue: PropTypes.number,
};
