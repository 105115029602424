import React from 'react';
import PropTypes from 'prop-types';

import dipromLogo from '../../assets/images/diprom.png';
import ButtonMenuList from '../Button/ButtonMenuList';

function Diprom1({ me, availableModule, information }) {
  return (
    <div className="min-h-screen bg-gradient-to-t from-purple-400 to-white">
      <div className="hidden md:block h-96 pt-28">
        <div className="hidden md:flex justify-center items-center px-10">
          <div>
            <img src={dipromLogo} alt="diprom" className="h-32" />
          </div>
          <div className="text-4xl px-8">x</div>
          <div>
            <img
              src={information?.logo?.url}
              alt="company-logo"
              className="h-32"
            />
          </div>
        </div>
        <div className=" text-center my-2 text-xl mt-10">
          <div className="font-semibold">{information?.description}</div>
          <div className="my-1">{information?.owner?.name}</div>
          <div className="my-1 text-base">
            สนับสนุนโดย {information?.sponsor?.name}
          </div>
        </div>
      </div>
      <div className="container mr-auto ml-auto pt-28 md:pt-0">
        <ButtonMenuList me={me} availableModule={availableModule} />
      </div>
    </div>
  );
}

export default Diprom1;

Diprom1.propTypes = {
  me: PropTypes.object,
  availableModule: PropTypes.arrayOf(PropTypes.object),
  information: PropTypes.object,
};
