import { BaseAction } from '../../class';
import {
  CUSTOMER_RATING_ALL,
  CUSTOMER_RATING_DELETE,
  CUSTOMER_RATING_ERROR,
  CUSTOMER_RATING_GET,
  CUSTOMER_RATING_LOADING,
  CUSTOMER_RATING_POST,
  CUSTOMER_RATING_PUT,
} from '../types';

const customerRatingAction = new BaseAction('customer-rating', {
  allConst: CUSTOMER_RATING_ALL,
  getConst: CUSTOMER_RATING_GET,
  postConst: CUSTOMER_RATING_POST,
  putConst: CUSTOMER_RATING_PUT,
  deleteConst: CUSTOMER_RATING_DELETE,
  errorConst: CUSTOMER_RATING_ERROR,
  loadingConst: CUSTOMER_RATING_LOADING,
});

export const customerRatingCreate = (data) => customerRatingAction.create(data);
export const customerRatingAll = ({ name, page, size, ...query }) =>
  customerRatingAction.getAllData({ name, page, size, ...query });
export const customerRatingGet = (id) => customerRatingAction.getOneData(id);
export const customerRatingPut = (id, data) =>
  customerRatingAction.editOne(id, data);
export const customerRatingDelete = (id) => customerRatingAction.deleteOne(id);
export const customerRatingStateSet = (data) =>
  customerRatingAction.stateSet(data);
export const customerRatingStateOneSet = (data) =>
  customerRatingAction.stateOneSet(data);
export const customerRatingReset = () => customerRatingAction.reset();
export const customerRatingError = () => customerRatingAction.onError();
