import React, { useState, useEffect } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';

export function EditMaterialInPurhaseOrderForm({
  material,
  setSelectMaterial,
  purchaseOrder,
}) {
  const [reload, setReload] = useState(false);
  const materialOrder = purchaseOrder?.material
    ? _.map(purchaseOrder?.material, function (mapMaterial) {
        return {
          material: mapMaterial.material._id,
          amount: mapMaterial.amount,
          quantity: mapMaterial.quantity,
        };
      })
    : [
        {
          material: '',
          amount: 0,
          quantity: 0,
        },
      ];
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      select_material: materialOrder || [
        {
          material: '',
          amount: 0,
          quantity: 0,
        },
      ],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'select_material',
  });

  useEffect(() => {
    setSelectMaterial(getValues('select_material'));
    return () => {};
  }, [reload, fields]);

  const displayMaterial = () => (
    <TableContainer>
      <Table size="small" className="border-lg">
        <TableHead className="bg-blue-100">
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> วัตถุดิบ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> จำนวน</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ดำเนินการ</div>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {_.size(fields) ? (
            fields.map((_field, index) => {
              return (
                <TableRow key={_field.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <div className="w-full px-1 py-2">
                      <Controller
                        name={`select_material.[${index}].material`}
                        control={control}
                        defaultValue={''}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <FormControl
                            sx={{ minWidth: 120 }}
                            fullWidth={true}
                            required
                          >
                            <InputLabel id="type" size={'small'}>
                              วัตถุดิบ
                            </InputLabel>
                            <Select
                              {...field}
                              label="วัตถุดิบ"
                              size={'small'}
                              fullWidth
                              onChange={(e) => {
                                field.onChange(e.target.value);
                                setReload(!reload);
                              }}
                            >
                              {_.size(material) ? (
                                _.map(material, (_material) => (
                                  <MenuItem
                                    key={_material._id}
                                    value={_material._id}
                                  >
                                    {_material.name}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem>
                                  <MenuItem disabled value="ไม่มีข้อมูล">
                                    <em>ไม่มีข้อมูล</em>
                                  </MenuItem>
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        )}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name={`select_material.[${index}].quantity`}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setValue(
                              `select_material.[${index}].amount`,
                              parseInt(
                                getValues(
                                  `select_material.[${index}].quantity`,
                                ),
                                10,
                              ),
                            );
                            setReload(!reload);
                          }}
                          label="จำนวน"
                          fullWidth
                          type={'Number'}
                          required
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'center' },
                          }}
                          size={'small'}
                          helperText={errors.quantity && 'กรุณาใส่ข้อมูล'}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      color={'error'}
                      variant="contained"
                      size={'small'}
                      onClick={() => remove(index)}
                    >
                      ลบ
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={4}>
                <div className="text-center py-2">ไม่มีรายการ</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <div>
      {displayMaterial()}{' '}
      <div className="px-2 py-2">
        <Button
          color={'success'}
          variant="outlined"
          size={'small'}
          onClick={() =>
            append({
              material: '',
              amount: 0,
              quantity: 0,
            })
          }
        >
          เพิ่มรายการ
        </Button>
      </div>
    </div>
  );
}

EditMaterialInPurhaseOrderForm.propTypes = {
  material: PropTypes.object,
  setSelectMaterial: PropTypes.func,
  purchaseOrder: PropTypes.object,
};

EditMaterialInPurhaseOrderForm.defaultProps = {
  material: null,
  setSelectMaterial: null,
  purchaseOrder: null,
};

export default EditMaterialInPurhaseOrderForm;
