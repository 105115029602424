import api from '../../../configs/api';
import graphqlClient from '../../../configs/graphqlClient';
import {
  MMS_DASHBOARD_ALL,
  MMS_DASHBOARD_DELETE,
  MMS_DASHBOARD_ERROR,
  MMS_DASHBOARD_GET,
  MMS_DASHBOARD_LOADING,
  MMS_DASHBOARD_POST,
  MMS_DASHBOARD_PUT,
} from '../types';

export const mmsDashboardCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MMS_DASHBOARD_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/mms-dashboard`,
      {
        ...payload,
      },
    );
    dispatch({ type: MMS_DASHBOARD_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MMS_DASHBOARD_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const mmsDashboardAll = ({ name = '', size = 10, page = 1 }) => async (
  dispatch,
) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/mms-dashboard?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: MMS_DASHBOARD_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MMS_DASHBOARD_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const mmsDashboardAllQuery = ({ query }) => async (dispatch) => {
  try {
    const queryResult = await graphqlClient.request(query);
    const data = queryResult?.findUnits;
    //  console.log('Query Data', queryResult);
    if (!data) {
      dispatch({ type: MMS_DASHBOARD_ERROR });
    }

    dispatch({ type: MMS_DASHBOARD_ALL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MMS_DASHBOARD_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const mmsDashboardGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/mms-dashboard/${id}`,
    );
    if (status === 200) {
      dispatch({ type: MMS_DASHBOARD_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MMS_DASHBOARD_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const mmsDashboardStateSet = (data) => async (dispatch) => {
  dispatch({ type: MMS_DASHBOARD_ALL, payload: data });
};

export const mmsDashboardStateOneSet = (data) => async (dispatch) => {
  dispatch({ type: MMS_DASHBOARD_GET, payload: data });
};

export const mmsDashboardReset = () => async (dispatch) => {
  dispatch({ type: MMS_DASHBOARD_LOADING });
};

export const mmsDashboardError = () => async (dispatch) => {
  dispatch({ type: MMS_DASHBOARD_ERROR });
};

export const mmsDashboardPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: MMS_DASHBOARD_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/mms-dashboard/${id}`,
      payload,
    );
    dispatch({ type: MMS_DASHBOARD_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MMS_DASHBOARD_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};
export const mmsDashboardDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: MMS_DASHBOARD_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/mms-dashboard/${id}`,
    );
    dispatch({ type: MMS_DASHBOARD_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MMS_DASHBOARD_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};
