import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import MultiUnitProdOrMatBox from '../../Box/Rendering/MultiUnitProdOrMatBox';
import DesktopDatepicker from '../../DatePicker/DesktopDatepicker';
import ProductAmountInput from '../../Inputs/ProdOrMatQuantityInput';
import ProductAutocomplete from '../../Inputs/ProductAutocomplete';

function OnlyAddProductForm({
  product,
  information,
  control,
  controlName,
  watch,
  setValue,
}) {
  const wmsDisplaySetting = information?.setting?.wms?.display;
  const { fields, append, remove } = useFieldArray({
    control,
    name: controlName,
  });
  return (
    <div>
      {product?.rows && (
        <div className="overflow-x-auto">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ลำดับที่</TableCell>
                <TableCell>สินค้า</TableCell>
                {wmsDisplaySetting?.cost_price && (
                  <TableCell>
                    <div className="whitespace-nowrap">ราคาทุน</div>{' '}
                  </TableCell>
                )}
                {wmsDisplaySetting?.lot_number && (
                  <TableCell>หมายเลขล็อต </TableCell>
                )}
                {wmsDisplaySetting?.expiration_date ||
                  (wmsDisplaySetting?.production_date && (
                    <TableCell>วันที่ผลิต / วันหมดอายุ </TableCell>
                  ))}{' '}
                <TableCell>จำนวน </TableCell>
                <TableCell>ดำเนินการ </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(fields, (_field, index) => (
                <TableRow key={_field?.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <div className="w-48">
                      <Controller
                        name={`${controlName}[${index}].product`}
                        control={control}
                        render={({ field }) => (
                          <ProductAutocomplete
                            information={information}
                            productRows={product?.rows}
                            field={field}
                          />
                        )}
                      />
                    </div>
                  </TableCell>
                  {wmsDisplaySetting?.cost_price && (
                    <TableCell>
                      <div className="w-24">
                        <Controller
                          name={`product[${index}].cost_price`}
                          control={control}
                          rules={{ required: false }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="number"
                              label="ราคาทุน"
                              fullWidth
                              size={'small'}
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                  )}
                  {wmsDisplaySetting?.lot_number && (
                    <TableCell>
                      <div className="w-48">
                        <Controller
                          name={`${controlName}[${index}].lot_number`}
                          control={control}
                          rules={{ required: false }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="หมายเลขล็อต"
                              fullWidth
                              size={'small'}
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                  )}
                  {(wmsDisplaySetting?.production_date ||
                    wmsDisplaySetting?.expiration_date) && (
                    <TableCell>
                      <div className="w-64">
                        {wmsDisplaySetting?.production_date && (
                          <div className="py-2">
                            <Controller
                              name={`${controlName}[${index}].production_date`}
                              control={control}
                              defaultValue={new Date()}
                              render={({ field }) => (
                                <DesktopDatepicker
                                  {...field}
                                  label="วันผลิต"
                                  fullWidth
                                  size="small"
                                  inputFormat="DD/MM/YYYY"
                                />
                              )}
                            />
                          </div>
                        )}
                        {wmsDisplaySetting?.expiration_date && (
                          <div className="py-2">
                            <Controller
                              name={`${controlName}[${index}].expiration_date`}
                              control={control}
                              render={({ field }) => (
                                <DesktopDatepicker
                                  {...field}
                                  label="วันหมดอายุ"
                                  fullWidth
                                  size="small"
                                  inputFormat="DD/MM/YYYY"
                                />
                              )}
                            />{' '}
                          </div>
                        )}
                      </div>
                    </TableCell>
                  )}

                  <TableCell>
                    <div className="w-52">
                      <ProductAmountInput
                        control={control}
                        controllerName={`${controlName}[${index}].quantity`}
                        artificialControllerName={`${controlName}[${index}].quantity_artificial`}
                        index={index}
                        selectedProduct={watch(
                          `${controlName}[${index}].product`,
                        )}
                        selectedUnitControllerName={`${controlName}[${index}].selected_unit`}
                        setValue={setValue}
                        watch={watch}
                      />
                      <MultiUnitProdOrMatBox
                        foundMaterial={watch(
                          `${controlName}[${index}].product`,
                        )}
                        quantity={watch(`${controlName}[${index}].quantity`)}
                        noWrap
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={() => {
                        remove(index);
                      }}
                    >
                      ลบ{' '}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}{' '}
              <TableRow>
                <TableCell>
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      append({
                        product: null,
                        quantity: 0,
                        cost_price: 0,
                      });
                    }}
                  >
                    เพิ่ม{' '}
                  </Button>
                </TableCell>
                <TableCell></TableCell>
                <TableCell> </TableCell>
                <TableCell></TableCell>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
}

export default OnlyAddProductForm;

OnlyAddProductForm.propTypes = {
  product: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  information: PropTypes.object,
  control: PropTypes.object,
  controlName: PropTypes.string,
  watch: PropTypes.func,
  setValue: PropTypes.func,
};
