import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { currencyFormatter } from '@/utils/functions';

const PlaceLaborCostTable = ({
  placeLaborCost,
  page,
  size,
  setPage,
  setSize,
  total,
  handleDelete,
}) => (
  <Paper>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">รูปแบบ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">วันที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">คลัง</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">ค่าแรง</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">ดำเนินการ</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!_.isEmpty(placeLaborCost?.rows) ? (
            _.map(placeLaborCost?.rows, (row, index) => (
              <TableRow
                key={index}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  <div className="text-xs">{(page - 1) * size + index + 1}</div>
                </TableCell>
                <TableCell>{row?.is_daily ? 'รายวัน' : 'ช่วงเวลา'}</TableCell>
                <TableCell>
                  {row?.is_daily ? (
                    dayjs(row?.start_date).format('D MMM BBBB')
                  ) : (
                    <span>
                      {dayjs(row?.start_date).format('D MMM BBBB')} -{' '}
                      {dayjs(row?.end_date).format('D MMM BBBB')}
                    </span>
                  )}
                </TableCell>
                <TableCell>
                  {_.map(row?.places, (eachPlace, placeIndex) => (
                    <div key={placeIndex}>{eachPlace?.name}</div>
                  ))}
                </TableCell>
                <TableCell>{currencyFormatter.format(row?.cost)} บาท</TableCell>

                <TableCell>
                  <div className="flex gap-1">
                    <Link to={`/cem/place-labor-cost/detail/${row?._id}`}>
                      <Button size="small" variant="contained" color="info">
                        รายละเอียด
                      </Button>
                    </Link>
                    <Link to={`/cem/place-labor-cost/edit/${row?._id}`}>
                      <Button size="small" variant="contained" color="warning">
                        แก้ไข
                      </Button>
                    </Link>
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={() => handleDelete(row?._id)}
                    >
                      ลบ
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6}>
                <div className="text-center">ไม่มีข้อมูล</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      component="div"
      page={page - 1}
      count={total || 1}
      rowsPerPage={size}
      onPageChange={(e, newPage) => setPage(newPage + 1)}
      onRowsPerPageChange={(e) => setSize(e.target.value)}
    />
  </Paper>
);

export default PlaceLaborCostTable;

PlaceLaborCostTable.propTypes = {
  placeLaborCost: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  page: PropTypes.number,
  size: PropTypes.number,
  setPage: PropTypes.func,
  setSize: PropTypes.func,
  total: PropTypes.number,
  handleDelete: PropTypes.func,
};
