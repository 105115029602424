import React from 'react';
import { Card, CardContent } from '@mui/material';
import _ from 'lodash';

import { MATERIAL_GROUP_OPTION } from '@/utils/constants';

import BoxMaterialFindFromLot from '../Box/Material/BoxMaterialFindFromLot';

const CardMaterialList = ({
  groupedResult,
  groupedBy,
  tag,
  materials,
  materialStockLot,
  showAmount,
}) => {
  const normalList = () => {
    const orderFieldList = _.sortBy(materials?.rows, 'name');
    return (
      <Card>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-2">
            {_.map(orderFieldList, (eachField, index) => (
              <div key={index}>
                <BoxMaterialFindFromLot
                  material={eachField}
                  materialStockLotRows={materialStockLot?.rows}
                  showAmount={showAmount}
                />
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    );
  };

  const groupByTagList = () => {
    const materialByTag = _.groupBy(materials?.rows, `additional.${tag}.value`);
    const tagKeys = _.keys(materialByTag);
    const orderedKey = tagKeys.sort();
    let count = 0;

    return (
      <Card>
        <CardContent>
          {_.map(orderedKey, (each, index) => (
            <div key={index} className="my-2">
              <div className="font-semibold text-lg my-2">{each}</div>
              <div className="grid grid-cols-1 md:grid-cols-2">
                {_.map(materialByTag?.[each], (eachField) => {
                  count += 1;
                  return (
                    <div key={count}>
                      <BoxMaterialFindFromLot
                        material={eachField}
                        materialStockLotRows={materialStockLot?.rows}
                        showAmount={showAmount}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ))}{' '}
        </CardContent>
      </Card>
    );
  };

  const groupByMaterialType = () => {
    const materialByTag = _.groupBy(materials?.rows, 'material_type');
    const tagKeys = _.keys(materialByTag);
    const orderedKey = tagKeys.sort();
    let count = 0;

    return (
      <Card>
        <CardContent>
          {_.map(orderedKey, (each, index) => (
            <div key={index}>
              <div className="font-semibold text-lg my-2">{each}</div>
              <div className="grid grid-cols-1 md:grid-cols-2">
                {_.map(materialByTag?.[each], (eachField) => {
                  count += 1;
                  return (
                    <div key={count}>
                      <BoxMaterialFindFromLot
                        material={eachField}
                        materialStockLotRows={materialStockLot?.rows}
                        showAmount={showAmount}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </CardContent>
      </Card>
    );
  };

  // Render Components

  if (groupedResult) {
    if (groupedBy === MATERIAL_GROUP_OPTION.TAG.status_code) {
      return groupByTagList();
    }
    if (groupedBy === MATERIAL_GROUP_OPTION.MATERIAL_TYPE.status_code) {
      return groupByMaterialType();
    }

    return normalList();
  }

  return normalList();
};

export default CardMaterialList;
