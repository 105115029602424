const MATERIAL_REQUEST_STATUS = {
  PENDING_APPROVAL: {
    status_code: "PENDING_APPROVAL",
    description: "รออนุมัติ",
  },
  APPROVAL: {
    status_code: "APPROVAL",
    description: "อนุมัติแล้ว รอการจัดซื้อ",
  },
  SUCCESSFUL_PURCHASE: {
    status_code: "SUCCESSFUL_PURCHASE",
    description: "จัดซื้อสำเร็จ",
  },
  REJECT: {
    status_code: "REJECT",
    description: "ไม่อนุมัติ",
  },
};

module.exports = MATERIAL_REQUEST_STATUS;
