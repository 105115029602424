import { TextField } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

export function ApprovalForm({ Controller, control }) {
  return (
    <div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'message_memo'}
          defaultValue={''}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="บันทึกข้อความ"
              fullWidth
              rows={3}
              multiline={true}
              size={'small'}
            />
          )}
        />
      </div>
    </div>
  );
}

ApprovalForm.propTypes = {
  Controller: PropTypes.func,
  control: PropTypes.object,
  getValues: PropTypes.func,
};

ApprovalForm.defaultProps = {};

export default ApprovalForm;
