import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  BackButton,
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import { Card, CardContent } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';
import { currencyFormatter } from '@/utils/functions';

/**
 * @function DetailPlaceLaborCost
 * @description Display information for one Place Labor Cost
 */

export default function DetailPlaceLaborCost({ title, subtitle }) {
  const dispatch = useDispatch();
  const placeLaborCost = useSelector((state) => state.placeLaborCost);
  const params = useParams();

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.placeLaborCostGet(params.id));
    } catch (error) {
      dispatch(actions.placeLaborCostError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (placeLaborCost.isLoading) {
    return <Loading />;
  }

  if (!placeLaborCost.isLoading && placeLaborCost.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="flex flex-wrap divide-y">
                <div className="w-full font-semibold text-lg py-4">
                  รายละเอียด
                </div>
                <div className="w-full md:w-1/2 py-4 font-semibold">
                  คลังที่มีผล
                </div>
                <div className="w-full md:w-1/2 py-4">
                  {_.map(placeLaborCost?.places, (place, index) => (
                    <li key={index}>{place?.name}</li>
                  ))}
                </div>
                <div className="w-full md:w-1/2 py-4 font-semibold">วันที่</div>
                <div className="w-full md:w-1/2 py-4">
                  {placeLaborCost?.is_daily ? (
                    <div>
                      {dayjs(placeLaborCost?.start_date).format('D MMM BBBB')}
                    </div>
                  ) : (
                    <div>
                      {dayjs(placeLaborCost?.start_date).format('D MMM BBBB')} -{' '}
                      {dayjs(placeLaborCost?.end_date).format('D MMM BBBB')}
                    </div>
                  )}
                </div>{' '}
                <div className="w-full md:w-1/2 py-4 font-semibold">ต้นทุน</div>
                <div className="w-full md:w-1/2 py-4">
                  {currencyFormatter.format(placeLaborCost?.cost)} บาท
                </div>
                {!placeLaborCost?.not_specify_amount_of_labor && (
                  <div className="w-full md:w-1/2 py-4 font-semibold">
                    จำนวนพนักงาน
                  </div>
                )}
                {!placeLaborCost?.not_specify_amount_of_labor && (
                  <div className="w-full md:w-1/2 py-4">
                    {placeLaborCost?.amount_of_labor} คน
                  </div>
                )}
                <div className="w-full md:w-1/2 py-4 font-semibold">
                  หมายเหตุ
                </div>
                <div className="w-full md:w-1/2 py-4">
                  {placeLaborCost?.remark}
                </div>
                <div className="w-full md:w-1/2 py-4 font-semibold">
                  อัพเดทข้อมูลล่าสุด
                </div>
                <div className="w-full md:w-1/2 py-4">
                  {dayjs(placeLaborCost?.updatedAt).format('D MMM BBBB HH:mm')}
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailPlaceLaborCost.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailPlaceLaborCost.defaultProps = {
  title: '',
  subtitle: '',
};
