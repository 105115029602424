import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  FormControlLabel,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import api from '@/configs/api';
import * as actions from '@/redux/actions';

/**
 * @function PDFReports
 * @description Display a collections or a list of PDFReport from database
 */

export default function PDFReport({ title, subtitle }) {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  const [pluginList, setPluginList] = useState([]);
  const { control, handleSubmit } = useForm({
    defaultValues: { plugins: information?.plugins },
  });

  const queryDataFromServer = async () => {
    try {
      const { data } = await api.get(
        `${process.env.REACT_APP_API_URL}/plugin-list`,
      );
      setPluginList(data);
    } catch (error) {
      console.error('ไม่สามารถดึงรายการปลั๊กอินได้', error?.message);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, []);

  const onEditUsedPlugin = async (data) => {
    try {
      await dispatch(actions.informationPut(information._id, data));
      dispatch(actions.informationAll());
      alert('แก้ไขสำเร็จ');
    } catch (error) {
      alert(`แก้ไขการใช้ปลั๊กอินไม่สำเร็จ ${error.message}}`);
    }
  };

  const renderTitle = () => (
    <div className="flex flex-row justify-between items-center">
      <ViewTitle title={title} subtitle={subtitle} />
    </div>
  );

  if (information.isLoading) {
    return <Loading />;
  }

  if (!information.isLoading && information.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          {' '}
          <form onSubmit={handleSubmit(onEditUsedPlugin)}>
            <Card>
              <CardContent>
                {_?.map(pluginList, (plugin, index) => (
                  <div key={index} className="my-4">
                    <Controller
                      name={`plugins.${plugin.id}`}
                      control={control}
                      render={({ field }) => (
                        <div>
                          <FormControlLabel
                            label={
                              <div>
                                <div className="font-semibold">
                                  {plugin.name}
                                </div>
                                <div>{plugin?.information?.title}</div>
                                <div className="my-2 text-sm">
                                  {plugin?.information?.description}
                                </div>
                                <div>
                                  {plugin?.moduleLevel && (
                                    <Chip size="small" label="ระดับโมดูล" />
                                  )}
                                </div>
                              </div>
                            }
                            control={
                              <Checkbox
                                {...field}
                                defaultChecked={field.value}
                              />
                            }
                          />
                        </div>
                      )}
                    />
                  </div>
                ))}
              </CardContent>
            </Card>
            <div className="flex justify-end mt-4">
              <Button variant="contained" color="primary" type="submit">
                บันทึก
              </Button>
            </div>{' '}
          </form>
        </div>
      </div>
    );
  }
  return <Error />;
}

PDFReport.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

PDFReport.defaultProps = {
  title: '',
  subtitle: '',
};
