import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { setLang } from '@antv/s2';
import { SheetComponent } from '@antv/s2-react';
import {
  MATERIAL_STOCK_STATUS,
  PRODUCT_QUANTITY_DIVIDE_BY,
} from '@iarcpsu/emanufac-constant';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { WordLocalizeContext } from '@/contexts/WordLocalizeContext';

const MaterialToProductPivotTable = ({
  generatedData,
  showRemainAmount,
  useDivider,
  showWarehouseAmount = true,
  dividedBy = PRODUCT_QUANTITY_DIVIDE_BY.NONE.status_code,
  showCustomer,
}) => {
  const history = useHistory();
  const payload = generatedData;
  const extraMeta = [];
  const valueList = [];
  const rowList = ['product_type', 'product'];
  const columnList = ['date', 'lot'];

  setLang('en_US');
  const { findWordInPlace, findWord } = useContext(WordLocalizeContext);

  valueList.push(
    useDivider
      ? PRODUCT_QUANTITY_DIVIDE_BY[dividedBy]?.divided_by_quantity_key
      : 'quantity',
  );

  if (showRemainAmount) {
    valueList.push('amount');
  }

  if (showCustomer) {
    rowList.push('customer');
  }

  if (showWarehouseAmount) {
    rowList.push('real_amount');
    rowList.push('predicted_amount');
    rowList.push('confirm');
    rowList.push('edit');
    rowList.push('detail');
  }

  /** @type {import('@antv/s2').S2DataConfig} */
  const config = {
    data: payload,
    fields: {
      rows: rowList,
      columns: columnList,
      values: valueList,
      valueInCols: true,
    },
    meta: [
      {
        field: 'material',
        name: 'วัตถุดิบ',
      },
      {
        field: 'product',
        name: 'สินค้า',
      },
      {
        field: 'net',
        name: 'บรรจุภัณฑ์',
        formatter: (data) => {
          if (data === null) {
            return 0;
          }
          if (_.isNaN(data)) {
            return 0;
          }

          if (data % 0 === 0) {
            return data;
          }

          return parseFloat(data).toFixed(2);
        },
      },
      {
        field: 'quantity',
        name: 'ปริมาณ (กิโลกรัม)',
        formatter: (data) => {
          if (data === null) {
            return 0;
          }
          if (_.isNaN(data)) {
            return 0;
          }

          if (data % 0 === 0) {
            return data;
          }

          return parseFloat(data).toFixed(2);
        },
      },
      {
        field: 'amount',
        name: 'คงเหลือ',
      },
      {
        field: 'material_type',
        name: 'หมวดหมู่',
      },
      {
        field: 'edit',
        name: 'แก้ไขยอด',
        formatter: (value) => {
          if (value === 'null') {
            return null;
          }
          if (value !== '$$empty_field_value$$') {
            return 'แก้ไขยอด';
          }
          return null;
        },
      },
      {
        field: 'confirm',
        name: 'ยืนยันยอด',
        formatter: (value) => {
          if (value === 'null') {
            return null;
          }
          if (value !== '$$empty_field_value$$') {
            return 'ยืนยันยอด';
          }
          return null;
        },
      },
      {
        field: 'runningNumber',
        name: 'เลขอ้างอิงล็อต',
      },
      {
        field: 'detail',
        name: 'รายละเอียด',
        formatter: (value) => {
          if (value === 'null') {
            return null;
          }
          if (value !== '$$empty_field_value$$') {
            return 'รายละเอียด';
          }
          return null;
        },
      },
      {
        field: 'status',
        name: 'สถานะ',
        formatter: (value) => {
          if (value === '$$empty_field_value$$') {
            return '-';
          }
          return MATERIAL_STOCK_STATUS[value]?.description || '';
        },
      },
      {
        field: 'predicted_amount',
        name: 'คาดการณ์',
        formatter: (data) => {
          if (data === null) {
            return 0;
          }
          if (_.isNaN(data)) {
            return 0;
          }

          if (data % 0 === 0) {
            return data;
          }

          return parseFloat(data).toFixed(2);
        },
      },
      {
        field: 'real_amount',
        name: 'ปริมาณจริง',
        formatter: (data) => {
          if (data === null) {
            return 0;
          }
          if (_.isNaN(data)) {
            return 0;
          }

          if (data % 0 === 0) {
            return data;
          }

          return parseFloat(data).toFixed(2);
        },
      },
      {
        field: 'product_type',
        name: 'ประเภทสินค้า',
      },
      {
        field: 'date',
        name: 'ประเภทสินค้า',
      },

      {
        field: 'product_type',
        name: 'ประเภทสินค้า',
      },
      {
        field: 'lot',
        name: findWord('material_source'),
      },
      {
        field: 'customer',
        name: 'ลูกค้า',
      },
    ],
    width: 800,
    sortParams: [
      {
        sortFieldId: 'material',
        sortMethod: 'ASC',
      },
    ],
  };

  const calQuantityFunction = (query, data) => {
    const quantityList = _.map(data, (each) => each?.quantity);
    return _.sum(quantityList);
  };

  const calNetFunction = (query, data) => {
    const quantityList = _.map(data, (each) => each?.net);
    return _.sum(quantityList);
  };

  const groupByProductPayload = _.groupBy(payload, 'product');
  console.log('Group By Product Payload', groupByProductPayload);

  return (
    <div>
      {generatedData && (
        <SheetComponent
          dataCfg={config}
          sheetType="pivot"
          themeCfg={{
            name: 'gray',
            theme: {
              dataCell: {
                bolderText: {
                  fontSize: 14,
                },
                text: {
                  fontSize: 14,
                },
              },
              colCell: {
                text: {
                  fontSize: 14,
                },
                bolderText: {
                  fontSize: 14,
                },
              },
              headerCell: {
                text: {
                  fontSize: 14,
                },
                bolderText: {
                  fontSize: 14,
                },
              },
              rowCell: {
                text: {
                  fontSize: 14,
                },
                bolderText: {
                  fontSize: 14,
                },
              },
              mergedCell: {
                text: {
                  fontSize: 14,
                },
                bolderText: {
                  fontSize: 14,
                },
              },
              cornerCell: {
                text: {
                  fontSize: 14,
                },
                bolderText: {
                  fontSize: 14,
                },
              },
            },
          }}
          options={{
            tooltip: {
              enable: true,
            },
            interaction: {
              selectedCellsSpotlight: true,
              hoverHighlight: true,
              copy: { enable: true },
              linkFields: ['edit', 'confirm', 'detail'],
            },
            totals: {
              col: {
                showGrandTotals: true,
                showSubTotals: true,
                subTotalsDimensions: ['material_type'],
                calcSubTotals: {
                  calcFunc: useDivider ? calNetFunction : calQuantityFunction,
                },
                subLabel: 'รวม',
                calcTotals: {
                  calcFunc: useDivider ? calNetFunction : calQuantityFunction,
                },
                label: 'รวม',
              },
              row: {
                showGrandTotals: false,
              },
            },
          }}
          onLinkFieldJump={(jump) => {
            if (jump?.key === 'confirm') {
              const actionLink = jump?.record?.confirm;
              console.log('Action Link', actionLink);
              history.push(actionLink);
            } else if (jump?.key === 'edit') {
              const actionLink = jump?.record?.edit;
              history.push(actionLink);
            } else if (jump?.key === 'detail') {
              const actionLink = jump?.record?.detail;
              history.push(actionLink);
            }
          }}
          header={{
            className: 'flex justify-end text-xs',
            title: 'Export',
            exportCfg: {
              open: true,
              className: 'flex',
            },
          }}
          adaptive
        />
      )}
    </div>
  );
};

export default MaterialToProductPivotTable;

MaterialToProductPivotTable.propTypes = {
  /** ข้อมูลที่จะส่งมาแสดงผล */
  generatedData: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      source: PropTypes.string,
      material: PropTypes.string,
      quantity: PropTypes.number,
      amount: PropTypes.number,
      lot: PropTypes.string,
      action: PropTypes.string,
    }),
  ),
  /** แสดงยอดคงเหลือ */
  showRemainAmount: PropTypes.bool,
  useDivider: PropTypes.bool,
  showWarehouseAmount: PropTypes.bool,
  dividedBy: PropTypes.string,
  showCustomer: PropTypes.bool,
};
