/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-named-as-default-member */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  BackButton,
  InternalDepositForm,
  Loading,
  LoadingDialog,
  QuotationProductsForm,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Button, Card, CardContent, Skeleton } from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import { api, app as appConfig } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';
import { useQuery } from '@/utils/functions';

/**
 * @function CreateInternalDeposit

 */
function CreateInternalDeposit({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const browserQuery = useQuery();
  const products = useSelector((state) => state.product);
  const materials = useSelector((state) => state.material);
  const customer = useSelector((state) => state.customer);
  const customerType = useSelector((state) => state.customerType);
  const employee = useSelector((state) => state.employee);
  const me = useSelector((state) => state.me);
  const quotation = useSelector((state) => state.quotation);
  const information = useSelector((state) => state.information);
  const baseProject = useSelector((state) => state.baseProject);
  const [dialogLoading, setIsDialogLoadingOn] = useState(false);

  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      processes: [
        {
          product: products.rows && products.rows[0],
          quantity: 1,
          materials: [
            { materials: materials.rows && materials.rows[0], begin: 1 },
          ],
          noQuantity: false,
        },
      ],
    },
  });
  const query = gql`
    query findProcessTemplates(
      $baseProjectInput: BaseProjectInput
      $productInput: ProductInput
      $materialInput: MaterialInput
      $customerInput: CustomerInput
    ) {
      findBaseProjects(input: $baseProjectInput) {
        rows {
          _id
          type_code
          name
          unit
          attach_process_template
          attach_bom_template
          process_template {
            _id
            name
          }
          bom_template {
            _id
            name
          }
        }
      }
      findMaterials(input: $materialInput) {
        rows {
          _id
          name
          type_code
          use_unit_conversion
          unit
          conversion_from {
            unit_a
            unit_b
            conversion_factor
          }
          conversion_to {
            unit_b
            unit_a
            conversion_factor
          }
          unit_input {
            _id
            short_sign
            name
          }
          unit_output {
            _id
            short_sign
            name
          }
        }
      }
      findProducts(input: $productInput) {
        rows {
          _id
          name
          type_code
          use_unit_conversion
          unit
          conversion_from {
            unit_a
            unit_b
            conversion_factor
          }
          conversion_to {
            unit_b
            unit_a
            conversion_factor
          }
          unit_input {
            _id
            short_sign
            name
          }
          unit_output {
            _id
            short_sign
            name
          }
        }
      }
      findCustomers(input: $customerInput) {
        rows {
          _id
          name
          type_code
        }
      }
    }
  `;
  const onSubmit = async (data) => {
    try {
      if (!_.size(data.processes)) {
        alert('กรุณาเพิ่มรายการอย่างน้อย 1 รายการ');
        return;
      }
      //  setIsDialogLoadingOn(true);
      const newProcesses = _.map(data.processes, (_process) => ({
        ..._process,
        quantity: _process?.noQuantity === true ? 0 : _process?.quantity,
        produce_base_project_instead:
          information?.setting?.mms?.allowUseBaseProject,
        additional_unit: _process.changeUnit
          ? _process.additional_unit
          : _process?.product?._unit,
        product: information?.setting?.mms?.allowUseBaseProject
          ? null
          : _process?.product,
      }));
      const payload = {
        ...data,

        processes: newProcesses,
      };

      console.log('Payload', payload);
      await dispatch(actions.internalDepositCreate(payload));
      setIsDialogLoadingOn(false);
      Swal.fire({
        title: 'สร้างใบเบิกใช้ภายในสำเร็จ',
        icon: 'success',
        confirmButtonText: 'ปิด',
      }).then(() => {
        history.goBack();
      });
    } catch (error) {
      setIsDialogLoadingOn(false);
      Swal.fire({
        title: 'สร้างใบเบิกใช้ภายในไม่สำเร็จ',
        icon: 'error',
        confirmButtonText: 'ปิด',
        text: error?.message,
      });
      console.error('Cannot Create Internal Deposit Order', error);
    }
  };

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        baseProjectInput: {
          page: 1,
          size: appConfig.maxFetchSize,
        },
        productInput: {
          page: 1,
          size: appConfig.maxFetchSize,
        },
        materialInput: {
          page: 1,
          size: appConfig.maxFetchSize,
        },
        customerInput: {
          page: 1,
          size: appConfig.maxFetchSize,
        },
      });

      const foundedBaseProject = queryResult?.findBaseProjects;
      const foundedMaterials = queryResult?.findMaterials;
      const foundedProducts = queryResult?.findProducts;
      const foundedCustomers = queryResult?.findCustomers;

      dispatch(actions.baseProjectStateSet(foundedBaseProject));
      dispatch(actions.materialStateSet(foundedMaterials));
      dispatch(actions.productStateSet(foundedProducts));
      dispatch(actions.customerStateSet(foundedCustomers));
    } catch (error) {
      console.error('Error On Fetching', error);
    }
  };

  const fetchPaymentTransactionType = async () => {
    try {
      dispatch(
        actions.paymentTypeAll({ page: 1, size: appConfig.maxFetchSize }),
      );
    } catch (error) {
      console.error('Fetch Payment Transaction Type Fail', error);
    }
  };

  const fetchCustomerType = async () => {
    try {
      dispatch(
        actions.customerTypeAll({ page: 1, size: appConfig.maxFetchSize }),
      );
    } catch (error) {
      console.error('Fetch Customer Type Fail', error);
    }
  };

  const fetchEmployee = async () => {
    try {
      dispatch(actions.employeeAll({ page: 1, size: appConfig.maxFetchSize }));
    } catch (error) {
      console.error('Fetch Employee Fail', error);
    }
  };

  const fetchQuotation = async () => {
    try {
      if (browserQuery.get('quotation')) {
        dispatch(actions.quotationGet(browserQuery.get('quotation')));
      } else {
        dispatch(actions.quotationLogReset());
      }
    } catch (error) {
      console.error('Fetch Quotation Fail', error);
    }
  };

  useEffect(() => {
    fetchPaymentTransactionType();
    queryDataFromServer();
    fetchCustomerType();
    fetchEmployee();
    fetchQuotation();
    return () => {};
  }, []);

  const settingQuotation = async () => {
    try {
      const newProcesses = _.map(quotation?.processes, (_process) => {
        const foundProduct = _.find(products?.rows, {
          _id: _process?.product?._id,
        });
        return {
          ..._process,
          product: foundProduct,
          noQuantity: false,
        };
      });

      // setValue('processes', []);
      console.log('data.processes', newProcesses);
      setValue('processes', newProcesses);
      const foundCustomer = _.find(
        customer?.rows,
        (each) => each?._id === quotation?.customer?._id,
      );
      setValue('customer', foundCustomer);
      setValue('quotation', quotation);
    } catch (error) {
      console.error('Cannot Fetch Quotation', error);
    }
  };

  useEffect(() => {
    if (
      products?.rows &&
      !products?.isLoading &&
      materials?.rows &&
      !materials?.isLoading &&
      customer?.rows &&
      !customer?.isLoading &&
      browserQuery.get('quotation') &&
      quotation?.isCompleted
    ) {
      settingQuotation();
    }

    return () => {};
  }, [browserQuery, products, materials, customer, quotation]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (
    browserQuery.get('quotation') &&
    (!quotation?.isCompleted || quotation?.isLoading || quotation?.rows)
  ) {
    return <Loading />;
  }

  if (materials.isLoading || products.isLoading || customer.isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="flex justify-between">
        <div>{renderTitle()}</div>
      </div>
      <div className="flex flex-row justify-start pb-4">
        <div>
          <BackButton />
        </div>
      </div>
      <LoadingDialog isLoading={dialogLoading} />
      {browserQuery.get('quotation') && (
        <div className="my-2">
          <Card>
            <CardContent>
              <div>
                สร้างจากใบเสนอราคา {watch('quotation')?.prefix}
                {watch('quotation')?.running_number}{' '}
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      <div className="py-2">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <InternalDepositForm
              control={control}
              customer={customer}
              customerType={customerType}
              employee={employee}
              watch={watch}
              defaultValue={{
                ...(quotation || {}),
                creator: me?.userData,
              }}
            />
          </div>
          <QuotationProductsForm
            errors={errors}
            control={control}
            product={products}
            material={materials?.rows}
            information={information}
            watch={watch}
            customer={customer}
            setValue={setValue}
            isReady={true}
            baseProject={baseProject}
          />

          <div className="flex flex-row justify-end">
            <Button variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

CreateInternalDeposit.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateInternalDeposit.defaultProps = {
  title: '',
  subtitle: '',
};

export default CreateInternalDeposit;
