import CreateMaterial from './CreateMaterial';
import CreateMultipleMaterial from './CreateMultipleMaterial';
import DetailMaterial from './DetailMaterial';
import DetailMaterialType from './DetailMaterialType';
import DetailWithdrawDestination from './DetailWithdrawDestination';
import EditMaterial from './EditMaterial';
import EditMaterialIngredient from './EditMaterialIngredient';
import EditMaterialType from './EditMaterialType';
import ImportExcelMaterial from './ImportExcelMaterial';
import Material from './Material';
import MaterialType from './MaterialType';
import WithdrawDestinations from './WithdrawDestinations';

export {
  CreateMaterial,
  CreateMultipleMaterial,
  DetailMaterial,
  DetailMaterialType,
  DetailWithdrawDestination,
  EditMaterial,
  EditMaterialIngredient,
  EditMaterialType,
  ImportExcelMaterial,
  Material,
  MaterialType,
  WithdrawDestinations,
};

export default {
  CreateMaterial,
  CreateMultipleMaterial,
  DetailMaterial,
  DetailMaterialType,
  DetailWithdrawDestination,
  EditMaterial,
  EditMaterialIngredient,
  EditMaterialType,
  ImportExcelMaterial,
  Material,
  MaterialType,
  WithdrawDestinations,
};
