import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import {
  Button,
  Card,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import graphqlClient from '../../../configs/graphqlClient';
import * as actions from '../../../redux/actions';
import { DASHBOARD_TYPE } from '../../../utils/constants';

/**
 * @function DashboardElements
 * @description Display a collections or a list of DashboardElements from database
 */

export default function DashboardElements({ title, subtitle }) {
  const dispatch = useDispatch();
  const dashboardElement = useSelector((state) => state.dashboardElement);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [total, setTotal] = useState();

  const query = gql`
    query FindDashboardElements($dashboardElementinput: DashboardElementInput) {
      findDashboardElements(input: $dashboardElementinput) {
        currPage
        lastPage
        total
        rows {
          _id
          name
          is_mo_level
          dashboard_type
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    const queryResult = await graphqlClient.request(query, {
      dashboardElementinput: { page, size, name },
    });
    const dashboardElementData = queryResult?.findDashboardElements;
    dispatch(actions.dashboardElementStateSet(dashboardElementData));
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, name]);

  useEffect(() => {
    if (dashboardElement?.rows) {
      setTotal(dashboardElement?.total);
    }

    return () => {};
  }, [dashboardElement, name]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowPerPage = (event) => {
    setSize(event?.target?.value);
    setPage(0);
  };

  const handleDeleteDashboardElement = async (id) => {
    try {
      const confirm = window.confirm('ยืนยันการลบส่วนประกอบของแดชบอร์ด');
      if (confirm) {
        await dispatch(actions.dashboardElementDelete(id));
        queryDataFromServer();
      }
    } catch (error) {
      alert(`ไม่สามารถลบส่วนประกอบของแดชบอร์ดนี้ได้ ${error?.message}`);
    }
  };

  const renderTitle = () => (
    <div className="flex justify-between">
      <ViewTitle title={title} subtitle={subtitle} />
      <div className="self-center">
        <Link to="dashboard-element/create">
          <Button variant="contained">เพิ่ม</Button>
        </Link>
      </div>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size="small"
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ชื่อส่วนประกอบ</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">ระดับ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ชนิดส่วนประกอบ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(dashboardElement?.rows) ? (
                _.map(dashboardElement?.rows, (row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="text-xs">
                        {(page - 1) * size + index + 1}
                      </div>
                    </TableCell>
                    <TableCell>{row?.name}</TableCell>
                    <TableCell>
                      {row?.is_mo_level ? 'คำสั่งผลิต' : 'ไลน์การผลิต'}
                    </TableCell>
                    <TableCell>
                      {DASHBOARD_TYPE?.[row?.dashboard_type]?.description}
                    </TableCell>
                    <TableCell>
                      <div className="flex gap-2">
                        <Link to={`dashboard-element/view/${row?._id}`}>
                          <Button variant="contained" color="info" size="small">
                            รายละเอียด
                          </Button>
                        </Link>
                        <Link to={`dashboard-element/edit/${row?._id}`}>
                          <Button
                            variant="contained"
                            color="warning"
                            size="small"
                          >
                            แก้ไข
                          </Button>
                        </Link>
                        <Button
                          variant="contained"
                          color="error"
                          size="small"
                          onClick={() => handleDeleteDashboardElement(row?._id)}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>{' '}
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowPerPage}
        />
      </Paper>
    </div>
  );

  if (dashboardElement.isLoading) {
    return <Loading />;
  }

  if (!dashboardElement.isLoading && dashboardElement.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="py-2">
          {renderSearch()}
          {renderTable()}
        </div>
      </div>
    );
  }
  return <Error />;
}

DashboardElements.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DashboardElements.defaultProps = {
  title: '',
  subtitle: '',
};
