/* eslint-disable react/prop-types */
import React from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import _ from 'lodash';

import MaterialAmountAddComponent from './MaterialAmountAddComponent';

const MaterialAmountGroupByTagList = ({
  place,
  materialRows,
  tableCellWidth,
  control,
  customer,
  enableAddPreferProduct,
  enableCustomer,
  product,
  rerender,
  setRerender,
  setValue,
  watch,
}) => {
  const materialByTag = _.groupBy(
    materialRows,
    `additional.${place?.place_action?.for_stranger?.group_transfrom_result?.tag_to_group}.value`,
  );
  const tagKeys = _.keys(materialByTag);
  const orderedKey = tagKeys.sort();

  let count = -1;

  return (
    <>
      {_.map(orderedKey, (each, index) => {
        const materialList = materialByTag?.[each];
        const sortedMaterial = _.sortBy(materialList, 'name');
        return (
          <TableBody key={index}>
            <TableRow>
              <TableCell
                colSpan={tableCellWidth}
                className="bg-gray-100 shadow-md"
              >
                <div className=" font-semibold">{each}</div>
              </TableCell>
            </TableRow>
            {_.map(sortedMaterial, (eachField) => {
              count += 1;
              return (
                <MaterialAmountAddComponent
                  control={control}
                  customer={customer}
                  material={eachField}
                  enableAddPreferProduct={enableAddPreferProduct}
                  enableCustomer={enableCustomer}
                  index={count}
                  product={product}
                  rerender={rerender}
                  setRerender={setRerender}
                  setValue={setValue}
                  watch={watch}
                  key={eachField.id}
                  place={place}
                />
              );
            })}
          </TableBody>
        );
      })}
    </>
  );
};

export default MaterialAmountGroupByTagList;
