import {
  MO_TEMPLATE_ALL,
  MO_TEMPLATE_GET,
  MO_TEMPLATE_DELETE,
  MO_TEMPLATE_PUT,
  MO_TEMPLATE_POST,
  MO_TEMPLATE_LOADING,
  MO_TEMPLATE_ERROR,
} from '../types';

import api from '../../../configs/api';
import graphqlClient from '../../../configs/graphqlClient';

export const moTemplateCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MO_TEMPLATE_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/mo-template`,
      {
        ...payload,
      },
    );
    dispatch({ type: MO_TEMPLATE_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MO_TEMPLATE_ERROR });
    throw new Error(error);
  }
};

export const moTemplateAll = ({ name = '', size = 10, page = 1 }) => async (
  dispatch,
) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/mo-template?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: MO_TEMPLATE_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MO_TEMPLATE_ERROR });
    throw new Error(error);
  }
};

export const moTemplateAllQuery = ({ query }) => async (dispatch) => {
  try {
    const queryResult = await graphqlClient.request(query);
    const data = queryResult?.findUnits;
    //  console.log('Query Data', queryResult);
    if (!data) {
      dispatch({ type: MO_TEMPLATE_ERROR });
    }

    dispatch({ type: MO_TEMPLATE_ALL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MO_TEMPLATE_ERROR });
    throw new Error(error);
  }
};

export const moTemplateGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/mo-template/${id}`,
    );
    if (status === 200) {
      dispatch({ type: MO_TEMPLATE_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MO_TEMPLATE_ERROR });
    throw new Error(error);
  }
};

export const moTemplateStateSet = (data) => async (dispatch) => {
  dispatch({ type: MO_TEMPLATE_ALL, payload: data });
};

export const moTemplateStateOneSet = (data) => async (dispatch) => {
  dispatch({ type: MO_TEMPLATE_GET, payload: data });
};

export const moTemplateReset = () => async (dispatch) => {
  dispatch({ type: MO_TEMPLATE_LOADING });
};

export const moTemplateError = () => async (dispatch) => {
  dispatch({ type: MO_TEMPLATE_ERROR });
};

export const moTemplatePut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: MO_TEMPLATE_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/mo-template/${id}`,
      payload,
    );
    dispatch({ type: MO_TEMPLATE_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MO_TEMPLATE_ERROR });
    throw new Error(error);
  }
};
export const moTemplateDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: MO_TEMPLATE_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/mo-template/${id}`,
    );
    dispatch({ type: MO_TEMPLATE_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MO_TEMPLATE_ERROR });
    throw new Error(error);
  }
};
