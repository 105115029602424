import api from '../../../configs/api';
import {
  FEEDBACK_ALL,
  FEEDBACK_DELETE,
  FEEDBACK_ERROR,
  FEEDBACK_GET,
  FEEDBACK_LOADING,
  FEEDBACK_POST,
  FEEDBACK_PUT,
} from '../types';

export const feedbackCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FEEDBACK_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/feedback`,
      {
        ...payload,
      },
    );
    dispatch({ type: FEEDBACK_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: FEEDBACK_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const feedbackAll = ({
  name = '',
  size = 10,
  page = 1,
  date = '',
  startDate = '',
  endDate = '',
}) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/feedback?name=${name}&size=${size}&page=${page}&date=${date}&startDate=${startDate}&endDate=${endDate}`,
    );
    if (status === 200) {
      dispatch({ type: FEEDBACK_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: FEEDBACK_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const feedbackGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/feedback/${id}`,
    );
    if (status === 200) {
      dispatch({ type: FEEDBACK_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: FEEDBACK_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const feedbackStateSet = (data) => async (dispatch) => {
  dispatch({ type: FEEDBACK_ALL, payload: data });
};

export const feedbackStateOneSet = (data) => async (dispatch) => {
  dispatch({ type: FEEDBACK_GET, payload: data });
};

export const feedbackReset = () => async (dispatch) => {
  dispatch({ type: FEEDBACK_LOADING });
};

export const feedbackError = () => async (dispatch) => {
  dispatch({ type: FEEDBACK_ERROR });
};

export const feedbackPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: FEEDBACK_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/feedback/${id}`,
      payload,
    );
    dispatch({ type: FEEDBACK_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: FEEDBACK_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};
export const feedbackDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: FEEDBACK_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/feedback/${id}`,
    );
    dispatch({ type: FEEDBACK_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: FEEDBACK_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};
