/* eslint-disable node/no-unsupported-features/es-syntax */
const AVAILABLE_MODULE = require("./availablemodule.constant");
const information = require("./information.constant");
const MANUFAC_ORDER_STATUS = require("./manufacOrderStatus.constant");
const MANUFACTURING_TRANSACTION_TYPE = require("./manufacturingTxnType.constant");
const MATERIAL_REQUEST_STATUS = require("./materialRequestStatus.constant");
const MATERIAL_STOCK_STATUS = require("./materialStockLotStatus.constant");
const MATERIAL_TRANSACTION_TYPE = require("./materialTransactionType.constant");
const MATH_OPERATION = require("./mathematicOperation.constant");
const messages = require("./messages.constant");
const MQ_TASK = require("./mqTask.constant");
const ORDER_STATUS = require("./orderStatus.constant");
const PAYROLL_TYPE = require("./payrollType.constant");
const PROCESS_STATUS = require("./processStatus.constant");
const PRODUCT_STOCK_STATUS = require("./productStockLotStatus.constant");
const PRODUCT_TRANSACTION_TYPE = require("./productTransactionType.constant");
const ROLE_LEVEL = require("./roleLevel.constant");
const statuses = require("./statuses.constant");
const STEP_STATUS = require("./stepStatus.constant");
const WAREHOUSE_STOCK_REQ_STATUS = require("./warehouseStockReqStatus.constant");
const ERROR_CODE = require("./errorCode");
const MATERIAL_WITHDRAW_STATUS = require("./materialWithdrawStatus.constant");
const MATERIAL_COMING_METHOD = require("./materialOutcomingMethod.constant");
const FRONT_TEMPLATE = require("./frontTemplate.constant");
const PROCESS_TEMPLATE_TYPE = require("./processTemplateType.constant");
const PRODUCTION_TYPE = require("./productionType.constant");
const MO_TEMPLATE_AMOUNT_METHOD = require("./moTemplateAmountMethod");
const PRODUCT_QUANTITY_DIVIDE_BY = require("./productQuantityDivideBy.constant");
const MATERIAL_GROUP_OPTION = require("./materialGroupByOption.constant");
const INVENTORY_WORKFLOW = require("./inventoryWorkflowAction.constant");
const REPORT_SUB_PLACE_IN_WAREHOUSE = require("./reportSubPlaceInWarehouse");
const QUOTATION_STATUS = require("./quotationStatus.constant");
const QUOTATION_ACTION = require("./quotationAction.constant");
const MANUFACTURING_MODE = require("./manufacturingMode");
const {
  CAPITAL_COST_ESTIMATION_METHOD,
  LABOR_COST_ESTIMATION_METHOD,
  MATERIAL_COST_ESTIMATION_METHOD,
} = require("./costEstimationMethod.constant");
const CAPITAL_COST_TYPE = require("./capitalCostType.constant");
const DAYS = require("./day.constant");
const CRM_TYPE = require("./crmType.constant");
const RATING = require("./rating.constant");
const QUICK_BUTTON_TYPE = require("./quickButtonType.constant");
const DATA_TYPE = require("./dataType.constant");

module.exports = {
  ...messages,
  ...statuses,
  information,
  MANUFAC_ORDER_STATUS,
  MATERIAL_REQUEST_STATUS,
  MATERIAL_STOCK_STATUS,
  MATERIAL_TRANSACTION_TYPE,
  ORDER_STATUS,
  PROCESS_STATUS,
  PRODUCT_STOCK_STATUS,
  PRODUCT_TRANSACTION_TYPE,
  ROLE_LEVEL,
  STEP_STATUS,
  AVAILABLE_MODULE,
  MANUFACTURING_TRANSACTION_TYPE,
  MATH_OPERATION,
  PAYROLL_TYPE,
  WAREHOUSE_STOCK_REQ_STATUS,
  MQ_TASK,
  ERROR_CODE,
  MATERIAL_WITHDRAW_STATUS,
  MATERIAL_COMING_METHOD,
  FRONT_TEMPLATE,
  PROCESS_TEMPLATE_TYPE,
  PRODUCTION_TYPE,
  MO_TEMPLATE_AMOUNT_METHOD,
  PRODUCT_QUANTITY_DIVIDE_BY,
  MATERIAL_GROUP_OPTION,
  INVENTORY_WORKFLOW,
  REPORT_SUB_PLACE_IN_WAREHOUSE,
  QUOTATION_STATUS,
  QUOTATION_ACTION,
  MANUFACTURING_MODE,
  MATERIAL_COST_ESTIMATION_METHOD,
  CAPITAL_COST_ESTIMATION_METHOD,
  LABOR_COST_ESTIMATION_METHOD,
  CAPITAL_COST_TYPE,
  DAYS,
  CRM_TYPE,
  RATING,
  QUICK_BUTTON_TYPE,
  DATA_TYPE,
};
