import _ from 'lodash';

import * as CONFIG from '../constants';

const accessRight = (me, moduleName, levelPage = 0, availableModule) => {
  const access = me?.userData?.department?.access;
  const meLevel = me?.userData?.role?.level;
  const otherDepartments = me?.userData?.other_departments;

  if (_.includes(CONFIG.FREE_ACCESS_MODULE, moduleName)) {
    return true;
  }

  if (availableModule?.[moduleName]) {
    if (access[`${_.toUpper(moduleName)}`] && meLevel >= levelPage) {
      return true;
    }
    const otherDeparmentAccessRights = _.map(otherDepartments, (_otherDept) => {
      if (
        _otherDept?.access?.[`${_.toUpper(moduleName)}`] &&
        meLevel >= levelPage
      ) {
        return true;
      }
      return false;
    });

    if (_.includes(otherDeparmentAccessRights, true)) {
      return true;
    }
    return false;
  }
  return false;
};

export default accessRight;

/*
การใช้งาน
me คือข้อมูล user ผู้ใช้งานระบบที่ได้จาก server
module คือชื่อ module จาก layout เช่น TMS CRM เป็นต้น
levelPage สำหรับกำหนดระดับการเข้าถึงของ Page นั้น ๆ
ทั้งนี้หากต้องการตรวจสอบการเข้าถึงโมดูลไม่จำเป็นต้องส่งข้อมูลมาแต่ใช้ค่าเริ่มต้นในฟังก์ชันแทน
*/
