import React, { useEffect, useState } from 'react';
import { Link as LinkIcon } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  Chip,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import graphqlClient from '../../../configs/graphqlClient';
import * as actions from '../../../redux/actions';

/**
 * @function BaseProjects
 * @memberof MMS/Setting
 * @description Display a collections or a list of BaseProjects from database
 * รายการโปรเจกต์หรือสินค้าพื้นฐาน สินค้าพื้นฐานเช่น ของเบญจพร
 * เขาจะมีสินค้าที่ไม่ระบุขนาดในเวลาสั่งผลิต แต่เวลาเก็บในคลังเขาจะเก็บเป็นระบุขนาดเลย
 * ดังนั้น BaseProject จะเป็นโปรเจกต์หรือสินค้าทั่วๆ ไป ใช้ในการสั่งผลิต ไม่ได้เก็บในสินค้าจริง
 */

export default function BaseProjects({ title, subtitle }) {
  const dispatch = useDispatch();
  const baseProject = useSelector((state) => state.baseProject);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState();

  const [name, setName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const query = gql`
    query FindBaseProjects($baseProjectinput: BaseProjectInput) {
      findBaseProjects(input: $baseProjectinput) {
        currPage
        lastPage
        total
        rows {
          _id
          type_code
          name
          description
          unit
          attach_bom_template
          attach_process_template
          bom_template {
            _id
            name
          }
          process_template {
            _id
            name
          }
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        baseProjectInput: { page, size, name },
      });
      const baseProjectData = queryResult?.findBaseProjects;
      dispatch(actions.baseProjectStateSet(baseProjectData));
    } catch (error) {
      dispatch(actions.baseProjectError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size]);

  useEffect(() => {
    if (baseProject?.rows) {
      setTotal(baseProject?.total);
    }

    return () => {};
  }, [baseProject]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleDelete = async (id) => {
    try {
      const confirm = window.confirm('ยืนยันการลบ');
      if (confirm) {
        await dispatch(actions.baseProjectDelete(id));
        queryDataFromServer();
      }
    } catch (error) {
      alert(`ลบโปรเจกต์ / สินค้าพื้นฐานไม่สำเร็จ ${error?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (baseProject.isLoading) {
    return <Loading />;
  }

  if (!baseProject.isLoading && baseProject.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          {renderTitle()}
          <div className="self-center">
            <Link to="/mms/base-project/create">
              <Button variant="contained">เพิ่ม</Button>
            </Link>
          </div>
        </div>
        <div className="my-2">
          <Card>
            <div className="p-4 flex flex-row">
              <div className="w-full md:w-1/2">
                <TextField
                  label="ค้นหา"
                  fullWidth
                  size="small"
                  id="outlined-start-adornment"
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <i className="fas fa-search"></i>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </Card>
        </div>
        <div className="my-2">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className="font-bold">ลำดับที่</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold">ชื่อโปรเจกต์ สินค้าพื้นฐาน</div>
                  </TableCell>{' '}
                  <TableCell>
                    <div className="font-bold">หน่วย</div>
                  </TableCell>{' '}
                  <TableCell>
                    <div className="font-bold">รายละเอียด</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold">ดำเนินการ</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!_.isEmpty(baseProject?.rows) ? (
                  _.map(baseProject?.rows, (row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="text-xs">
                          {(page - 1) * size + index + 1}
                        </div>
                      </TableCell>

                      <TableCell>
                        <div className="font-semibold">
                          {row?.type_code || ''}
                        </div>
                        {row?.name || '-'}{' '}
                        {row?.attach_process_template && (
                          <div>
                            <Chip
                              label={`${row?.process_template?.name}`}
                              icon={<LinkIcon size={'12'} />}
                              size="small"
                            />
                          </div>
                        )}
                        {row?.attach_bom_template && (
                          <div>
                            <Chip
                              label={`${row?.bom_template?.name}`}
                              icon={
                                <i className="fas fa-clipboard-list text-xs" />
                              }
                              size="small"
                            />
                          </div>
                        )}
                      </TableCell>
                      <TableCell> {row?.unit || '-'} </TableCell>
                      <TableCell>{row?.description || '-'} </TableCell>
                      <TableCell>
                        <div className="flex gap-2">
                          <Link
                            to={`/setting/mms/base-project/edit/${row?._id}`}
                          >
                            <Button
                              variant="contained"
                              size="small"
                              color="warning"
                            >
                              แก้ไข
                            </Button>
                          </Link>
                          <Button
                            size="small"
                            variant="contained"
                            color="error"
                            onClick={() => handleDelete(row?._id)}
                          >
                            ลบ
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <div className="text-center">ไม่มีข้อมูล</div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>{' '}
              <TablePagination
                page={page - 1}
                count={total || 1}
                rowsPerPage={size}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Table>
          </TableContainer>
        </div>
      </div>
    );
  }
  return <Error />;
}

BaseProjects.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

BaseProjects.defaultProps = {
  title: '',
  subtitle: '',
};
