import React from 'react';
import { Card } from '@mui/material';
import PropTypes from 'prop-types';

const PMSDashboard = ({ periodText, materialRequest }) => (
  <div>
    <div className="my-4 mx-2 text-3xl font-display">ฝ่ายจัดซื้อ</div>
    <div className="flex flex-wrap  gap-4">
      <div className="w-full lg:w-1/5">
        <Card className="p-6">
          <div>คำขอสั่งซื้อรออนุมัติ{periodText}</div>
          <div className="text-5xl p-4 text-right">
            {materialRequest.total || 0}
            <span className="text-xl pl-4">รายการ</span>
          </div>
        </Card>
      </div>
    </div>
  </div>
);

PMSDashboard.propTypes = {
  periodText: PropTypes.string,
  materialRequest: PropTypes.shape({
    total: PropTypes.number,
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default PMSDashboard;
