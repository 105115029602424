import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';

import { Error } from '@iarcpsu/emanufac-components/src/components/Error';
import { ExternalSoftwareSettingForm } from '@iarcpsu/emanufac-components/src/components/Forms';
import Loading from '@iarcpsu/emanufac-components/src/components/Loading';
import { ViewTitle } from '@iarcpsu/emanufac-components/src/components/ViewTitle';
import * as actions from '@/redux/actions';

function ExternalSoftwareSetting({ title, subtitle }) {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);

  const { handleSubmit, control } = useForm();

  useEffect(() => {
    dispatch(actions.informationAll());
    return () => {};
  }, []);

  const onSubmit = async (data, event) => {
    try {
      event.preventDefault();

      await dispatch(
        actions.informationPut(information._id, {
          external_software: data,
        }),
      );

      console.log('Data', data);

      alert('สำเร็จ');
      await dispatch(actions.informationAll());
    } catch (error) {
      console.error(error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (information.isLoading) {
    return <Loading />;
  }

  if (!information.isLoading && information.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardContent>
              <ExternalSoftwareSettingForm
                control={control}
                information={information}
              />
            </CardContent>
          </Card>

          <div className="flex justify-end my-2">
            <Button variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    );
  }
  return <Error />;
}

ExternalSoftwareSetting.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ExternalSoftwareSetting.defaultProps = {
  title: '',
  subtitle: '',
};

export default ExternalSoftwareSetting;
