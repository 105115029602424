import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import {
  NotAuthorized,
  NotFound,
} from '@iarcpsu/emanufac-components/src/components/Error';
import { MainFooter } from '@iarcpsu/emanufac-components/src/components/Footers';
import { Loading } from '@iarcpsu/emanufac-components/src/components/Loading';
import { HomeNavbar } from '@iarcpsu/emanufac-components/src/components/Navbars';
import { MainSidebar } from '@iarcpsu/emanufac-components/src/components/Sidebar';
import * as actions from '../redux/actions';
import accessRight from '../utils/functions/accessRight';
import {
  CreateDailyPayroll,
  CreateMonthyPayroll,
  CreatePersonalPayrollRecord,
  DailyPayrolls,
  DetailEmployeePayroll,
  DetailPayroll,
  EditPayroll,
  EmployeePayroll,
  MonthyPayrolls,
} from '../views/PAMS';

export function PAMS() {
  const module = 'PAMS';
  const prefix = '/pams';
  const name = 'เงินเดือนและการจ่ายค่าตอบแทน';
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);
  const information = useSelector((state) => state.information);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout : PAMS');
    dispatch(actions.meGet());
    dispatch(actions.informationAll());
    dispatch(actions.availableModuleAll());
    return () => {};
  }, []);

  if (!me?.userData) {
    return <Loading />;
  }

  if (accessRight(me, module, 0, availableModule)) {
    return (
      <div className="min-h-screen">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            เงินเดือนและการจ่ายค่าตอบแทน - {information?.name || 'E-Manufac'}
          </title>
        </Helmet>
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="relative lg:ml-64  min-h-screen  pt-16 px-8 ">
          <div className="py-4">
            <Switch>
              <Redirect exact from={`${prefix}`} to={`${prefix}/employee`} />
              <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />

              <Route exact path={`${prefix}/employee/payroll/:id`}>
                <DetailEmployeePayroll
                  title="การจ่ายค่าตอบแทนให้พนักงาน"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/employee/create-payroll/:id`}>
                <CreatePersonalPayrollRecord
                  title="จ่ายค่าตอบแทน"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/daily`}>
                <DailyPayrolls title="ค่าตอบแทนรายวัน" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/employee`}>
                <EmployeePayroll title="ค่าตอบแทนรายพนักงาน" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/detail/:id`}>
                <DetailPayroll title="รายละเอียดค่าตอบแทน" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/daily/create`}>
                <CreateDailyPayroll
                  title="จ่ายค่าตอบแทนรายวัน"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/monthy`}>
                <MonthyPayrolls title="ค่าตอบแทนรายเดือน" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/monthy/create`}>
                <CreateMonthyPayroll
                  title="จ่ายค่าตอบแทนรายเดือน"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/payroll/edit/:id`}>
                <EditPayroll title="แก้ไขค่าตอบแทน" subtitle={name} />
              </Route>

              {accessRight(me, module, 1, availableModule) && (
                <Switch>
                  <Route path="*">
                    <NotFound />
                  </Route>
                </Switch>
              )}
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <NotAuthorized />
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    </div>
  );
}

export default PAMS;
