import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import OneMeasurementVariableBox from './OneMeasurementVariableBox';
/**
 *
 * @function MeasurementVariableListBox
 * @memberof Component/Box
 * @description แสดงลิสต์ของ Measurement Variable
 * @returns {React.Component} React Component
 */

export default function MeasurementVariableListBox({
  selectedProcess,
  selectedStep = null,
}) {
  return (
    <div>
      {_.map(
        selectedStep
          ? _.filter(
              _.orderBy(selectedProcess?.steps, ['index'], ['asc']),
              (each) => each?._id === selectedStep?._id,
            )
          : _.orderBy(selectedProcess?.steps, ['index'], ['asc']),
        (eachStep, index) => {
          if (!_.isEmpty(eachStep?.measurements)) {
            return (
              <div key={index} className="my-2">
                <div className="text-base font-semibold font-display bg-gray-200 p-2 rounded-md">
                  {eachStep?.name}{' '}
                </div>
                <div className="flex flex-wrap my-2">
                  {_.map(
                    _.orderBy(eachStep?.measurements, ['order'], ['asc']),
                    (eachMeasurement, innerIndex) => (
                      <OneMeasurementVariableBox
                        key={`in-${innerIndex}`}
                        measurement={eachMeasurement}
                      />
                    ),
                  )}
                </div>
              </div>
            );
          }
          return <div key={index}></div>;
        },
      )}
    </div>
  );
}

MeasurementVariableListBox.propTypes = {
  selectedProcess: PropTypes.object,
  selectedStep: PropTypes.object,
};
