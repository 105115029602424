import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { gql } from 'graphql-request';

import * as actions from '../../../redux/actions';
import Loading from '@iarcpsu/emanufac-components/src/components/Loading';
import { Error } from '@iarcpsu/emanufac-components/src/components/Error';
import { BackButton } from '@iarcpsu/emanufac-components/src/components/Button';
import { ViewTitle } from '@iarcpsu/emanufac-components/src/components/ViewTitle';
import { MeasurementTypeForm } from '@iarcpsu/emanufac-components/src/components/Forms';
import { app as appConfig } from '../../../configs';
// ในระบบเราจะเรียกว่า Measurement Type เพราะในการเก็บโครงสร้างข้อมูลมันมีทั้ง
// ที่เป็นตัวนี้คือ Template และที่เป็นค่า
// แต่ในทาง UI เราจะใช้มันว่า ตัวแปรวัดค่า Measurement Variable เพราะว่ามันจะทำให้เข้าใจง่ายกว่า
// สำหรับ User
export default function SettingEditMeasurementType({ title, subtitle }) {
  const dispatch = useDispatch();
  const measurementType = useSelector((state) => state.measurementType);
  const units = useSelector((state) => state.unit);
  const history = useHistory();
  const params = useParams();

  // Setting React hook form
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: measurementType,
  });

  useEffect(() => {
    const getMeasurementType = async () => {
      try {
        dispatch(actions.measurementTypeGet(params.id));
      } catch (error) {
        console.error(error);
      }
    };

    getMeasurementType();

    return () => {};
  }, [params]);

  useEffect(() => {
    if (measurementType && !measurementType?.rows) {
      setValue('name', measurementType?.name);
      setValue('description', measurementType?.description);
      setValue('data_type', measurementType?.data_type);
      setValue('type_code', measurementType?.type_code);
    }
    return () => {};
  }, [measurementType]);

  useEffect(() => {
    const getAllUnit = async () => {
      try {
        const query = gql`
        query FindUnits {
          findUnits(input:{page:1,size:${appConfig.maxFetchSize}}){
            rows {
            _id
            name
            }
          }
        } 
      `;
        dispatch(actions.unitAllQuery({ query }));
      } catch (error) {
        console.error('Get All Units Error', error);
      }
    };

    getAllUnit();

    return () => {};
  }, []);

  const onSubmit = async (data) => {
    try {
      await dispatch(actions.measurementTypePut(params.id, data));
      history.goBack();
    } catch (error) {
      window.alert('สร้างตัวแปรไม่สำเร็จ', error?.message);
    }
  };

  // UI Rendering
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderBackButton = () => <BackButton />;
  const renderForm = () => (
    <div className="my-2">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardContent>
            <MeasurementTypeForm
              measurementType={measurementType}
              control={control}
              units={units}
              watch={watch}
              errors={errors}
            />
          </CardContent>
        </Card>
        <div className="my-2 flex justify-end">
          <Button variant="contained" type="submit">
            บันทึก
          </Button>{' '}
        </div>
      </form>
    </div>
  );

  if (measurementType.isLoading) {
    return <Loading />;
  }
  if (!measurementType.isLoading && measurementType.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>{' '}
        {renderBackButton()}
        {renderForm()}
      </div>
    );
  }
  return <Error message={measurementType?.message} />;
}

SettingEditMeasurementType.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SettingEditMeasurementType.defaultProps = {
  title: '',
  subtitle: '',
};
