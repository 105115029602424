import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { currencyFormatter } from '@/utils/functions';

const GeneralTableRendering = ({
  columns,
  rows = [],
  pagination,
  page = 1,
  size = null,
  total = null,
  setPage = () => {},
  roundOff = true, // ปัดเศษ
}) => {
  const handleChangePage = (e, newPage) => {
    setPage(newPage + 1);
  };

  const renderValue = (rowData, columnData) => {
    if (!rowData?.[columnData?.ref]) {
      return '-';
    }
    if (_.isNumber(rowData?.[columnData?.ref])) {
      if (roundOff) {
        return currencyFormatter.format(rowData?.[columnData?.ref]);
      }

      return rowData?.[columnData?.ref];
    }
    return rowData?.[columnData?.ref];
  };

  return (
    <div>
      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {_.map(columns, (eachColumn, index) => (
                <TableCell>
                  <div className="font-bold" key={index}>
                    {eachColumn?.name}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!_.isEmpty(rows) ? (
              _.map(rows, (eachRow, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  {_.map(columns, (eachColumn, columnIndex) => (
                    <TableCell key={`row-${index}-col-${columnIndex}`}>
                      {renderValue(eachRow, eachColumn)}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <div className="text-center">ไม่มีข้อมูล</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {pagination && (
            <TablePagination
              page={page - 1}
              count={total || 1}
              rowsPerPage={size}
              onPageChange={handleChangePage}
            />
          )}
        </Table>
      </TableContainer>
    </div>
  );
};

GeneralTableRendering.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  pagination: PropTypes.bool,
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  setPage: PropTypes.func,
  roundOff: PropTypes.bool,
};

export default GeneralTableRendering;
