import React from 'react';
import { Card } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

const CardInternalDeposit = ({ internalDeposit }) => (
  <div>
    <Card className="p-4 ">
      <div className="flex flex-wrap divide-y">
        <div className="w-full py-4 text-lg font-semibold font-display ">
          รายละเอียด
        </div>
        <div className="w-1/2 py-4 px-2 font-semibold font-display ">
          วันที่สร้าง
        </div>
        <div className="w-1/2 py-4 ">
          {dayjs(internalDeposit?.start_date)
            .locale('th')
            .format('DD MMM BBBB')}
        </div>
        <div className="w-1/2 py-4 px-2 font-semibold font-display ">
          วันกำหนดส่งสินค้า
        </div>
        <div className="w-1/2 py-4 ">
          {dayjs(internalDeposit?.expected_date)
            .locale('th')
            .format('DD MMM BBBB')}
        </div>
        <div className="w-1/2 py-4 px-2 font-semibold font-display ">
          เลขที่ใบเบิกใช้ภายใน
        </div>
        <div className="w-1/2 py-4 ">
          {internalDeposit?.prefix}
          {internalDeposit?.running_number}
        </div>{' '}
        <div className="w-1/2 py-4 px-2 font-semibold font-display ">
          เลขที่ใบเสนอราคา
        </div>
        <div className="w-1/2 py-4 ">
          {internalDeposit?.quotation?.prefix}
          {internalDeposit?.quotation?.running_number}
        </div>
        <div className="w-1/2 py-4 px-2 font-semibold font-display ">สถานะ</div>
        <div className="w-1/2 py-4 ">
          {internalDeposit?.completed && 'สั่งผลิตแล้ว'}
          {internalDeposit?.deleted && 'ยกเลิก'}
          {!internalDeposit?.completed &&
            !internalDeposit?.deleted &&
            'กำลังดำเนินการ'}
        </div>
        <div className="w-1/2 py-4 px-2 font-semibold font-display ">
          ลูกค้า
        </div>
        <div className="w-1/2 py-4">
          <div className="bg-gray-100 p-3 rounded-md text-sm">
            <div>
              <b>ชื่อ : </b> {internalDeposit?.customer?.name || ' - '}
            </div>
            <div>
              <b>โทร : </b>
              {internalDeposit?.customer?.phone_number || ' - '}
            </div>
            <div>
              <b>ที่อยู่ : </b>
              {internalDeposit?.customer?.address || ' - '}
            </div>
          </div>
        </div>
        <div className="w-1/2 py-4 px-2 font-semibold font-display ">
          หมายเหตุ
        </div>
        <div className="w-1/2 py-4 ">{internalDeposit?.remark}</div>
      </div>
    </Card>
  </div>
);

export default CardInternalDeposit;

CardInternalDeposit.propTypes = {
  internalDeposit: PropTypes.object,
};
