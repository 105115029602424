import { BaseAction } from '../../class';
import {
  FACTORY_CAPITAL_COST_ALL,
  FACTORY_CAPITAL_COST_DELETE,
  FACTORY_CAPITAL_COST_ERROR,
  FACTORY_CAPITAL_COST_GET,
  FACTORY_CAPITAL_COST_LOADING,
  FACTORY_CAPITAL_COST_POST,
  FACTORY_CAPITAL_COST_PUT,
} from '../types';

const factoryCapitalCostAction = new BaseAction('factory-capital-cost', {
  allConst: FACTORY_CAPITAL_COST_ALL,
  getConst: FACTORY_CAPITAL_COST_GET,
  postConst: FACTORY_CAPITAL_COST_POST,
  putConst: FACTORY_CAPITAL_COST_PUT,
  deleteConst: FACTORY_CAPITAL_COST_DELETE,
  errorConst: FACTORY_CAPITAL_COST_ERROR,
  loadingConst: FACTORY_CAPITAL_COST_LOADING,
});

export const factoryCapitalCostCreate = (data) =>
  factoryCapitalCostAction.create(data);
export const factoryCapitalCostAll = ({ name, page, size, ...query }) =>
  factoryCapitalCostAction.getAllData({ name, page, size, ...query });
export const factoryCapitalCostGet = (id) =>
  factoryCapitalCostAction.getOneData(id);
export const factoryCapitalCostPut = (id, data) =>
  factoryCapitalCostAction.editOne(id, data);
export const factoryCapitalCostDelete = (id) =>
  factoryCapitalCostAction.deleteOne(id);
export const factoryCapitalCostStateSet = (data) =>
  factoryCapitalCostAction.stateSet(data);
export const factoryCapitalCostStateOneSet = (data) =>
  factoryCapitalCostAction.stateOneSet(data);
export const factoryCapitalCostReset = () => factoryCapitalCostAction.reset();
export const factoryCapitalCostError = () => factoryCapitalCostAction.onError();
