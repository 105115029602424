import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';

/**
 * @function MMSDashboards
 * @description Display a collections or a list of MMSDashboards from database
 */

export default function MMSDashboards({ title, subtitle }) {
  const dispatch = useDispatch();
  const mmsDashboard = useSelector((state) => state.mmsDashboard);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [name, setName] = useState('');
  const [total, setTotal] = useState();
  const [searchTerm, setSearchTerm] = useState('');

  const query = gql`
    query FindMMSDashboards($mmsDashboardInput: MMSDashboardInput) {
      findMMSDashboards(input: $mmsDashboardInput) {
        currPage
        lastPage
        total
        rows {
          _id
          name
          is_mo_level
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        mmsDashboardInput: { page, size, name },
      });
      const mmsDashboardData = queryResult?.findMMSDashboards;
      dispatch(actions.mmsDashboardStateSet(mmsDashboardData));
    } catch (error) {
      dispatch(actions.mmsDashboardError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, name]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    if (mmsDashboard?.rows) {
      setTotal(mmsDashboard?.total);
    }

    return () => {};
  }, [mmsDashboard]);

  const handleChangePage = (event, value) => {
    setPage(value + 1);
  };

  const handleChangeRowPerPage = (event) => {
    setSize(event?.target?.value);
    setPage(1);
  };

  const handleDeleteMMSDashboard = async (id) => {
    try {
      const confirm = window.confirm('ยืนยันการลบแดชบอร์ด');
      if (confirm) {
        await dispatch(actions.mmsDashboardDelete(id));
        queryDataFromServer();
      }
    } catch (error) {
      alert(`ลบแดชบอร์ดไม่สำเร็จ ${error?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size="small"
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  if (mmsDashboard.isLoading) {
    return <Loading />;
  }
  if (!mmsDashboard.isLoading && mmsDashboard.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          {renderTitle()}
          <div className="self-center">
            <Link to="mms-dashboard/create">
              <Button variant="contained">เพิ่ม</Button>
            </Link>
          </div>
        </div>
        {renderSearch()}
        <div className="my-2">
          <Paper>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="font-bold">ลำดับที่</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">ชื่อแดชบอร์ด</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">ระดับ</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">ดำเนินการ</div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!_.isEmpty(mmsDashboard?.rows) ? (
                    _.map(mmsDashboard?.rows, (row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <div className="text-xs">{index + 1}</div>
                        </TableCell>
                        <TableCell>{row?.name}</TableCell>
                        <TableCell>
                          {row?.is_mo_level ? 'คำสั่งผลิต' : 'ไลน์การผลิต'}
                        </TableCell>
                        <TableCell>
                          <div className="flex gap-2">
                            <Link to={`mms-dashboard/view/${row?._id}`}>
                              <Button
                                variant="contained"
                                color="info"
                                size="small"
                              >
                                รายละเอียด
                              </Button>
                            </Link>
                            <Link to={`mms-dashboard/edit/${row?._id}`}>
                              <Button
                                variant="contained"
                                color="warning"
                                size="small"
                              >
                                แก้ไข
                              </Button>
                            </Link>
                            <Button
                              variant="contained"
                              color="error"
                              size="small"
                              onClick={() => handleDeleteMMSDashboard(row?._id)}
                            >
                              ลบ
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <div className="text-center">ไม่มีข้อมูล</div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>{' '}
              </Table>
            </TableContainer>{' '}
            <TablePagination
              component="div"
              page={page - 1}
              count={total || 1}
              rowsPerPage={size}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowPerPage}
            />
          </Paper>
        </div>
      </div>
    );
  }
  return <Error />;
}

MMSDashboards.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MMSDashboards.defaultProps = {
  title: '',
  subtitle: '',
};
