import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  BackButton,
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import { CAPITAL_COST_TYPE } from '@iarcpsu/emanufac-constant';
import { findAmountOfWorkDayFromInformation } from '@iarcpsu/emanufac-utils/functions';
import { Card, CardContent } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';
import { currencyFormatter } from '@/utils/functions';

/**
 * @function DetailFactoryCapitalCost
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function DetailFactoryCapitalCost({ title, subtitle }) {
  const dispatch = useDispatch();
  const factoryCapitalCost = useSelector((state) => state.factoryCapitalCost);
  const information = useSelector((state) => state.information);
  const params = useParams();

  const workingDay = findAmountOfWorkDayFromInformation({
    startPeriod: factoryCapitalCost?.start_date,
    endPeriod: factoryCapitalCost?.end_date,
    information,
  });
  const amountOfDay =
    dayjs(factoryCapitalCost?.end_date).diff(
      dayjs(factoryCapitalCost?.start_date),
      'day',
    ) + 1;

  const costPerHour =
    factoryCapitalCost?.cost /
    workingDay /
    (information?.setting?.pams?.cc_working_hour_per_day || 8);

  const costPerMin = costPerHour / 60;

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.factoryCapitalCostGet(params.id));
    } catch (error) {
      dispatch(actions.factoryCapitalCostError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (factoryCapitalCost.isLoading) {
    return <Loading />;
  }

  if (!factoryCapitalCost.isLoading && factoryCapitalCost.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="flex flex-wrap divide-y">
                <div className="w-full font-semibold text-lg py-4">
                  รายละเอียด
                </div>
                <div className="w-full md:w-1/2 py-4 font-semibold">
                  ชนิดของต้นทุน
                </div>
                <div className="w-full md:w-1/2 py-4">
                  {
                    CAPITAL_COST_TYPE[factoryCapitalCost?.cost_type]
                      ?.description
                  }
                </div>
                <div className="w-full md:w-1/2 py-4 font-semibold">
                  มูลค่าราคา
                </div>
                <div className="w-full md:w-1/2 py-4">
                  {currencyFormatter.format(factoryCapitalCost?.cost)} บาท
                </div>
                <div className="w-full md:w-1/2 py-4 font-semibold">
                  วันที่มีผล
                </div>
                <div className="w-full md:w-1/2 py-4">
                  {dayjs(factoryCapitalCost?.start_date).format('D MMM BBBB')} -{' '}
                  {dayjs(factoryCapitalCost?.end_date).format('D MMM BBBB')}
                </div>
                <div className="w-full md:w-1/2 py-4 font-semibold">
                  จำนวนวัน
                </div>
                <div className="w-full md:w-1/2 py-4">{amountOfDay} วัน</div>
                <div className="w-full md:w-1/2 py-4 font-semibold">
                  จำนวนวันทำงาน
                </div>
                <div className="w-full md:w-1/2 py-4">{workingDay} วัน</div>
                <div className="w-full md:w-1/2 py-4 font-semibold">
                  ต้นทุนต่อวันทำงาน
                </div>
                <div className="w-full md:w-1/2 py-4">
                  {currencyFormatter.format(
                    factoryCapitalCost?.cost / workingDay,
                  )}{' '}
                  บาท
                  <div>
                    ( {currencyFormatter.format(costPerHour)} บาท/ชั่วโมง หรือ{' '}
                    {currencyFormatter.format(costPerMin)} บาท/นาที )
                  </div>
                </div>{' '}
                <div className="w-full md:w-1/2 py-4 font-semibold">
                  หมายเหตุ
                </div>
                <div className="w-full md:w-1/2 py-4">
                  {factoryCapitalCost?.remark || '-'}
                </div>
                <div className="w-full md:w-1/2 py-4 font-semibold">
                  อัพเดทข้อมูลล่าสุด
                </div>
                <div className="w-full md:w-1/2 py-4">
                  {dayjs(factoryCapitalCost?.updatedAt).format(
                    'D MMM BBBB HH:mm',
                  )}
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailFactoryCapitalCost.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailFactoryCapitalCost.defaultProps = {
  title: '',
  subtitle: '',
};
