// db 1 represent 1 db any
// can be 1-db2  or 1-db12 , etc.

import db1 from "./4-1DB.png";
import db2 from "./4-2DB.png";
import db3 from "./4-3DB.png";
import db4 from "./4-4DB.png";
import db5 from "./4-5DB.png";
import db6 from "./4-6DB.png";
import db7 from "./4-7DB.png";
import db8 from "./4-8DB.png";
import db9 from "./4-9DB.png";
import db10 from "./4-10DB.png";
import db11 from "./4-11DB.png";
import db12 from "./4-12DB.png";
import db13 from "./4-13DB.png";
import db14 from "./4-14DB.png";
import db15 from "./4-15DB.png";
import db16 from "./4-16DB.png";
import db17 from "./4-17DB.png";
import db18 from "./4-18DB.png";

const fourJacket = {
  db1,
  db2,
  db3,
  db4,
  db5,
  db6,
  db7,
  db8,
  db9,
  db10,
  db11,
  db12,
  db13,
  db14,
  db15,
  db16,
  db17,
  db18,
};

export default fourJacket;
