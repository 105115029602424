import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  BackButton,
  DesktopDatepicker,
  Error,
  ExportExcelContainer,
  Loading,
  MaterialTransactionTable,
  MultiUnitProdOrMatBox,
  PieChart,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { config } from '@/configs';
import * as actions from '@/redux/actions';
import { MaterialUtil } from '@/utils/excelColumn';
import { useQuery } from '@/utils/functions';

/**
 * @function DetailMaterialReportDashboard
 * @description Display a collections or a list of DetailMaterialReportDashboard from database
 */

export default function DetailMaterialReportDashboard({ title, subtitle }) {
  const dispatch = useDispatch();
  const materialTransaction = useSelector((state) => state.materialTransaction);
  const materialType = useSelector((state) => state.materialType);
  const params = useParams();
  const query = useQuery();

  const type = query.get('type');
  const date = query.get('date');
  const isInterval = query.get('interval');
  const selectedMaterial = query.get('material');
  const place = query.get('place');
  const [selectedTab, setSelectedTab] = useState(0);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState();
  const [selectedDate, setSelectedDate] = useState(dayjs(date).toDate());
  const [selectedMaterialType, setSelectedMaterialType] = useState();

  useEffect(() => {
    dispatch(actions.materialTypeAll({ page: 1, size: config.maxFetchSize }));

    return () => {};
  }, []);

  useEffect(() => {
    setTotal(materialTransaction?.total);

    return () => {};
  }, [materialTransaction]);

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.materialTransactionSearch({
          page,
          size,
          date:
            type === 'daily' ? dayjs(selectedDate).format('YYYY-MM-DD') : '',
          month:
            type === 'monthy' ? dayjs(selectedDate).format('YYYY-MM-DD') : '',
          place: place || '',
          materialType: selectedMaterialType?._id || '',
        }),
      );
    } catch (error) {
      dispatch(actions.materialTransactionError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params, page, size, selectedDate, selectedMaterialType]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderSummary = () => (
    <div className="my-2">
      <Card>
        <CardContent>
          <div className="font-display">เข้าคลัง</div>
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/2">
              {_.map(
                materialTransaction?.inAnalysis,
                (eachTransactionType, index) => (
                  <div key={index}>
                    <PieChart
                      labels={_.map(
                        eachTransactionType?.summary,
                        (each) => each?.material?.name,
                      )}
                      series={_.map(
                        eachTransactionType?.summary,
                        (each) => each?.quantity,
                      )}
                    />
                  </div>
                ),
              )}
            </div>
            <div className="w-full lg:w-1/2">
              {_.map(
                materialTransaction?.inAnalysis,
                (eachTransactionType, index) => (
                  <div key={index}>
                    <div className="text-lg font-semibold">
                      {eachTransactionType?._id?.name}
                    </div>
                    {_.map(
                      _.take(eachTransactionType?.summary, 5),
                      (eachOut, index) => (
                        <div key={index} className="my-2 flex">
                          <div className="w-2/3">
                            <div className="text-sm text-gray-500">
                              หมวดหมู่ {eachOut?.material?.material_type?.name}
                            </div>
                            <div className="text-xl font-semibold font-display my-2">
                              <span className="bg-gray-200 p-1 rounded-md my-1 mr-2">
                                {eachOut?.material?.type_code}
                              </span>
                              {eachOut?.material?.name}
                            </div>
                          </div>
                          <div className="w-1/3 self-center text-lg">
                            <MultiUnitProdOrMatBox
                              foundMaterial={eachOut?.material}
                              quantity={eachOut?.quantity}
                            />
                          </div>
                        </div>
                      ),
                    )}
                    {_.size(eachTransactionType?.summary) > 5 && (
                      <div>
                        และอีก {_.size(eachTransactionType?.summary) - 5} รายการ
                        <div className="my-2">
                          <Button
                            size="small"
                            onClick={() => {
                              setSelectedTab(2);
                            }}
                            variant="outlined"
                          >
                            ดูทั้งหมด
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                ),
              )}
            </div>
          </div>
        </CardContent>
      </Card>
      <Card className="my-2">
        <CardContent>
          <div className="font-display">ออกจากคลัง</div>
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/2">
              {_.map(
                materialTransaction?.outAnalysis,
                (eachTransactionType, index) => (
                  <div key={index}>
                    <PieChart
                      labels={_.map(
                        eachTransactionType?.summary,
                        (each) => each?.material?.name,
                      )}
                      series={_.map(
                        eachTransactionType?.summary,
                        (each) => each?.quantity,
                      )}
                    />
                  </div>
                ),
              )}
            </div>
            <div className="w-full lg:w-1/2">
              {_.map(
                materialTransaction?.outAnalysis,
                (eachTransactionType, index) => (
                  <div key={index}>
                    <div className="text-lg font-semibold">
                      {eachTransactionType?._id?.name}
                    </div>
                    {_.map(
                      _.take(eachTransactionType?.summary, 5),
                      (eachOut, index) => (
                        <div key={index} className="my-2 flex">
                          <div className="w-2/3">
                            <div className="text-sm text-gray-500">
                              หมวดหมู่ {eachOut?.material?.material_type?.name}
                            </div>
                            <div className="text-xl font-semibold font-display my-2">
                              <span className="bg-gray-200 p-1 rounded-md my-1 mr-2">
                                {eachOut?.material?.type_code}
                              </span>
                              {eachOut?.material?.name}
                            </div>
                          </div>
                          <div className="w-1/3 self-center text-lg">
                            <MultiUnitProdOrMatBox
                              foundMaterial={eachOut?.material}
                              quantity={eachOut?.quantity}
                            />
                          </div>
                        </div>
                      ),
                    )}
                    {_.size(eachTransactionType?.summary) > 5 && (
                      <div>
                        และอีก {_.size(eachTransactionType?.summary) - 5} รายการ
                        <div className="my-2">
                          <Button
                            size="small"
                            onClick={() => {
                              setSelectedTab(2);
                            }}
                            variant="outlined"
                          >
                            ดูทั้งหมด
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                ),
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );

  const renderTable = () => (
    <div>
      <MaterialTransactionTable
        page={page}
        setPage={setPage}
        size={size}
        setSize={setSize}
        total={total}
        transaction={materialTransaction}
      />
    </div>
  );

  const renderAllMaterialList = (tranasactionTypeName, materialAnalysis) => (
    <div className="px-2">
      <div className="flex justify-between my-2">
        <div className="text-lg font-semibold self-center">
          {tranasactionTypeName}
        </div>
        <div className="self-center my-2">
          <ExportExcelContainer
            columnList={MaterialUtil.columnWithMaterialDouble}
            currentData={materialAnalysis}
            sheetName="MaterialTransactionOnType"
            disableAllExport
            buttonSize="small"
          />
        </div>
      </div>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableCell>
                <div className="font-semibold"> ลำดับที่</div>
              </TableCell>
              <TableCell>
                <div className="font-semibold"> รหัสวัตถุดิบ</div>
              </TableCell>
              <TableCell>
                <div className="font-semibold"> ชื่อ</div>
              </TableCell>
              <TableCell>
                <div className="font-semibold"> ปริมาณ</div>
              </TableCell>
            </TableHead>
            <TableBody>
              {_.map(materialAnalysis, (each, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{each?.material?.type_code}</TableCell>
                  <TableCell>
                    {each?.material?.name}
                    <div className="text-sm">
                      หมวดหมู่ {each?.material?.material_type?.name}
                    </div>
                  </TableCell>
                  <TableCell>
                    <MultiUnitProdOrMatBox
                      foundMaterial={each?.material}
                      quantity={each?.quantity}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  const materialTypeSelector = () => (
    <div className="my-2">
      <Autocomplete
        disablePortal
        options={materialType?.rows}
        size="small"
        placeholder="เลือกประเภทวัตถุดิบ"
        onChange={(e, newValue) => {
          setSelectedMaterialType(newValue);
        }}
        className="z-40"
        // prettier-ignore
        getOptionLabel={(option) => `${option?.name || ''}`
      }
        renderInput={(inputParam) => (
          <TextField label="เลือกประเภทวัตถุดิบ" {...inputParam} />
        )}
      />
    </div>
  );
  if (materialTransaction.isLoading) {
    return <Loading />;
  }

  if (!materialTransaction.isLoading && materialTransaction.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <Card>
            <CardContent>
              {selectedMaterialType && (
                <div>ประเภทวัตถุดิบ : {selectedMaterialType?.name} </div>
              )}
              {type === 'daily' && (
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-1/2">
                    <div className="">สรุปการใช้วัตถุดิบรายวัน</div>
                    <div className="text-xl font-semibold">
                      {dayjs(selectedDate).format('D MMMM BBBB')}
                    </div>
                    <div className="flex gap-2 my-2">
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          const purposeDate = dayjs(selectedDate)
                            .subtract(1, 'day')
                            .toDate();
                          setSelectedDate(purposeDate);
                        }}
                      >
                        วันก่อนหน้า
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          const purposeDate = dayjs(selectedDate)
                            .add(1, 'day')
                            .toDate();
                          setSelectedDate(purposeDate);
                        }}
                      >
                        วันถัดไป
                      </Button>
                    </div>{' '}
                  </div>
                  <div className="w-full lg:w-1/2 flex justify-center self-center">
                    <div className="lg:w-1/3">
                      <DesktopDatepicker
                        label="เลือกวัน"
                        value={selectedDate}
                        setValue={setSelectedDate}
                        fullWidth
                      />
                      {materialTypeSelector()}
                    </div>
                  </div>
                </div>
              )}
              {type === 'monthy' && (
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-1/2">
                    <div className="">สรุปการใช้วัตถุดิบรายเดือน</div>
                    <div className="text-xl font-semibold">
                      {dayjs(selectedDate).format(' MMMM BBBB')}
                    </div>
                    <div className="flex gap-2 my-2">
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          const purposeDate = dayjs(selectedDate)
                            .subtract(1, 'month')
                            .toDate();
                          setSelectedDate(purposeDate);
                        }}
                      >
                        เดือนก่อนหน้า
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          const purposeDate = dayjs(selectedDate)
                            .add(1, 'month')
                            .toDate();
                          setSelectedDate(purposeDate);
                        }}
                      >
                        เดือนถัดไป
                      </Button>
                    </div>{' '}
                  </div>
                  <div className="w-full lg:w-1/2 flex justify-center self-center">
                    <div className="lg:w-1/3">{materialTypeSelector()}</div>
                  </div>
                </div>
              )}
            </CardContent>
          </Card>
        </div>
        <div>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              bgcolor: 'background.paper',
            }}
          >
            <Tabs
              value={selectedTab}
              onChange={(e, newValue) => setSelectedTab(newValue)}
            >
              <Tab label="สรุป" value={0} />
              <Tab label="ประวัติการดำเนินการ" value={1} />
              <Tab label="สรุปวัตถุดิบเข้า" value={2} />
              <Tab label="สรุปวัตถุดิบออก" value={3} />
            </Tabs>
          </Box>
          {selectedTab === 0 && renderSummary()}
          {selectedTab === 1 && renderTable()}
          {selectedTab === 2 && (
            <div>
              {_.map(
                materialTransaction?.inAnalysis,
                (eachTransactionType, index) => (
                  <div key={index}>
                    {renderAllMaterialList(
                      eachTransactionType?._id?.name,
                      eachTransactionType?.summary,
                    )}
                  </div>
                ),
              )}
            </div>
          )}
          {selectedTab === 3 && (
            <div>
              {_.map(
                materialTransaction?.outAnalysis,
                (eachTransactionType, index) => (
                  <div key={index}>
                    {renderAllMaterialList(
                      eachTransactionType?._id?.name,
                      eachTransactionType?.summary,
                    )}
                  </div>
                ),
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailMaterialReportDashboard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailMaterialReportDashboard.defaultProps = {
  title: '',
  subtitle: '',
};
