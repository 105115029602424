import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ImageUpload from '../../ImageUpload/ImageUpload';

const ModalUploadImage = ({ images, setImages, isOpen, handleClose }) => {
  return (
    <Dialog open={isOpen} onClose={() => handleClose()}>
      <DialogTitle>อัพโหลดรูปภาพ</DialogTitle>
      <DialogContent>
        <div className=" w-72 flex justify-center">
          <ImageUpload images={images} maxNumber={1} setImages={setImages} />
        </div>
        {_.size(images) > 0 && (
          <div className="mt-4 flex w-full justify-center">
            <Button
              color="success"
              variant="contained"
              onClick={() => handleClose()}
            >
              ยืนยัน
            </Button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalUploadImage;

ModalUploadImage.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
  setImages: PropTypes.func,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};
