import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  MMSDashboardForm,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';

/**
 * @function EditMMSDashboard
 * @description Display a collections or a list of EditMMSDashboard from database
 */

export default function EditMMSDashboard({ title, subtitle }) {
  const dispatch = useDispatch();
  const mmsDashboard = useSelector((state) => state.mmsDashboard);
  const dashboardElement = useSelector((state) => state.dashboardElement);
  const history = useHistory();
  const params = useParams();
  const [page] = useState(1);
  const [size, setSize] = useState(10);
  const {
    control,
    setValue,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const query = gql`
    query FindEditMMSDashboard(
      $mmsDashboardInput: MMSDashboardInput
      $dashboardElementInput: DashboardElementInput
    ) {
      findOneMMSDashboard(input: $mmsDashboardInput) {
        _id
        name
        is_mo_level
        elements {
          index
          dashboard_element {
            _id
            name
          }
          height
          width
        }
      }
      findDashboardElements(input: $dashboardElementInput) {
        total
        rows {
          _id
          name
          dashboard_type
          is_mo_level
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        mmsDashboardInput: { id: params.id },
        dashboardElementInput: { page, size },
      });
      const mmsDashboardData = queryResult?.findOneMMSDashboard;
      dispatch(actions.mmsDashboardStateOneSet(mmsDashboardData));
    } catch (error) {
      dispatch(actions.mmsDashboardError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params, page, size]);

  useEffect(() => {
    if (mmsDashboard && !_.isEmpty(mmsDashboard?.elements)) {
      setValue('elements', mmsDashboard?.elements);
    }

    return () => {};
  }, [mmsDashboard]);

  const handleEditMMSDashboard = async (data) => {
    try {
      await dispatch(actions.mmsDashboardPut(params.id, data));
      history.goBack();
    } catch (error) {
      alert(`แก้ไขแดชบอร์ดไม่สำเร็จ ${error?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (mmsDashboard.isLoading || mmsDashboard?.rows) {
    return <Loading />;
  }

  if (!mmsDashboard.isLoading && mmsDashboard.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div>
          <form onSubmit={handleSubmit(handleEditMMSDashboard)}>
            <MMSDashboardForm
              control={control}
              dashboardElement={dashboardElement}
              defaultValue={mmsDashboard}
              errors={errors}
              setSize={setSize}
              setValue={setValue}
              size={size}
              watch={watch}
            />
            <div className="flex justify-end">
              <Button variant="contained" color="success" type="submit">
                บันทึก
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditMMSDashboard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditMMSDashboard.defaultProps = {
  title: '',
  subtitle: '',
};
