import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  Chip,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { PRODUCTION_TYPE } from '@/utils/constants';

import {
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import graphqlClient from '../../../configs/graphqlClient';
import * as actions from '../../../redux/actions';

/**
 * @function SettingMOTemplate
 * @memberof MMS/Setting
 * @description เป็น Template สำหรับสร้างคำสั่งผลิตใน One Click MO Template
 * จะใส่ไว้เลยว่า 1 วันต้องผลิตอะไรบ้าง ใช้สํตรการผลิตอะไร จะทำให้เร็วขึ้นในการผลิต
 * แต่ยังไม่มีที่ไหนใช้
 */

export default function SettingMOTemplate({ title, subtitle }) {
  const dispatch = useDispatch();
  const moTemplate = useSelector((state) => state.moTemplate);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState();
  const [name, setName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const query = gql`
    query FindSettingMOTemplate($moTemplateInput: MOTemplateInput) {
      findMOTemplates(input: $moTemplateInput) {
        currPage
        lastPage
        total
        rows {
          _id
          name
          sequential_process
          processes {
            production_type
            no_product
            product {
              type_code
              name
            }
            product_as_material {
              type_code
              name
            }
            process_template {
              name
            }
            based_project {
              name
            }
          }
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        moTemplateInput: { page, size, name },
      });
      const moTemplateData = queryResult?.findMOTemplates;
      dispatch(actions.moTemplateStateSet(moTemplateData));
    } catch (error) {
      console.error('ERROR on Get MO Template', error);
      dispatch(actions.moTemplateError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, name]);

  useEffect(() => {
    dispatch(actions.moTemplateReset());
    return () => {};
  }, []);

  useEffect(() => {
    if (moTemplate?.rows) {
      setTotal(moTemplate?.total);
    }

    return () => {};
  }, [moTemplate]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleDelete = async (currentId) => {
    try {
      const confirm = window.confirm('ยืนยันการลบสูตรคำสั่งผลิตนี้');
      if (confirm) {
        await dispatch(actions.moTemplateDelete(currentId));
        queryDataFromServer();
      }
    } catch (error) {
      alert('ลบสูตรคำสั่งผลิตไม่สำเร็จ');
      console.error('Cannot Delete MO Template', error);
    }
  };

  const renderTitle = () => (
    <div>
      <div className="flex justify-between">
        <ViewTitle title={title} subtitle={subtitle} />
        <div className="self-center">
          <Link to="mo-template/create">
            <Button variant="contained">เพิ่ม</Button>
          </Link>
        </div>
      </div>
    </div>
  );

  const renderSearch = () => (
    <Card className="my-2">
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ชื่อสูตรคำสั่งผลิต</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ผลิตภัณฑ์ที่ผลิต</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(moTemplate?.rows) ? (
                _.map(moTemplate?.rows, (row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="text-xs">{index + 1}</div>
                    </TableCell>
                    <TableCell>
                      {row?.name}
                      {row?.sequential_process && (
                        <div>
                          <Chip
                            label="งานแบบเป็นขั้นตอน"
                            color="primary"
                            size="small"
                          />
                        </div>
                      )}{' '}
                    </TableCell>
                    <TableCell>
                      {_.map(row?.processes, (eachProcess, procIndex) => (
                        <div key={procIndex}>
                          {eachProcess?.production_type ===
                            PRODUCTION_TYPE.MATERIAL.type_code && (
                            <div>
                              -{' '}
                              {eachProcess?.product_as_material?.type_code ||
                                ''}{' '}
                              {eachProcess?.product_as_material?.name || ''}{' '}
                              {(eachProcess?.no_product &&
                                eachProcess?.process_template?.name) ||
                                ''}
                              <Chip
                                label={PRODUCTION_TYPE.MATERIAL.description}
                                size="small"
                              />
                            </div>
                          )}
                          {eachProcess?.production_type ===
                            PRODUCTION_TYPE.PRODUCT.type_code && (
                            <div>
                              - {eachProcess?.product?.type_code || ''}{' '}
                              {eachProcess?.product?.name || ''}{' '}
                              {(eachProcess?.no_product &&
                                eachProcess?.process_template?.name) ||
                                ''}
                              <Chip
                                label={PRODUCTION_TYPE.PRODUCT.description}
                                size="small"
                              />
                            </div>
                          )}{' '}
                          {eachProcess?.production_type ===
                            PRODUCTION_TYPE.BASED_PROJECT.type_code && (
                            <div>
                              -{eachProcess?.based_project?.name || ''}{' '}
                              {(eachProcess?.no_product &&
                                eachProcess?.process_template?.name) ||
                                ''}
                              <Chip
                                label={
                                  PRODUCTION_TYPE.BASED_PROJECT.description
                                }
                                size="small"
                              />
                            </div>
                          )}
                        </div>
                      ))}
                    </TableCell>
                    <TableCell>
                      <div className="flex flex-wrap gap-1">
                        <Link to={`mo-template/view/${row?._id}`}>
                          <Button size="small" variant="contained" color="info">
                            รายละเอียด
                          </Button>
                        </Link>{' '}
                        <Link to={`mo-template/edit/${row?._id}`}>
                          <Button
                            size="small"
                            variant="contained"
                            color="warning"
                          >
                            แก้ไข
                          </Button>
                        </Link>
                        <Button
                          size="small"
                          variant="contained"
                          color="error"
                          onClick={() => handleDelete(row?._id)}
                        >
                          ลบ
                        </Button>
                      </div>{' '}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TablePagination
              page={page - 1}
              count={total || 1}
              rowsPerPage={size}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  if (moTemplate.isLoading) {
    return <Loading />;
  }
  if (!moTemplate.isLoading && moTemplate.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          {renderSearch()}
          {renderTable()}
        </div>
      </div>
    );
  }
  return <Error />;
}

SettingMOTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SettingMOTemplate.defaultProps = {
  title: '',
  subtitle: '',
};
