/* eslint-disable arrow-body-style */
import React from 'react';
import { RATING } from '@iarcpsu/emanufac-constant';
import { calculateRating } from '@iarcpsu/emanufac-utils/functions';
import { Avatar, Card, CardContent } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

const CustomerRatingBox = ({
  customer,
  handleUpdateRating = () => {},
  customerRating,
}) => {
  return (
    <div>
      <div className="flex flex-wrap">
        <div className="w-1/2 md:w-1/4 pr-1">
          <Card>
            <CardContent>
              <div>Rating ปัจจุบัน</div>
              <div className="flex items-center">
                <div className="text-5xl">
                  {calculateRating(customer?.rating)?.emoji}
                </div>
                <div className="text-2xl">
                  {_.isNumber(customer?.rating)
                    ? customer?.rating?.toFixed(1)
                    : 0}
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="w-1/2 md:w-3/4 pl-1">
          <Card>
            <CardContent>
              <div className="flex flex-wrap">
                <div className="w-full  lg:w-1/3">
                  <div>ต้องการให้ Rating ใหม่เป็น</div>
                  <div className="flex">
                    {_.map(RATING, (rating, key) => (
                      <div key={key}>
                        <div
                          className="text-2xl hover:scale-125 hover:text-4xl cursor-pointer p-2 hover:bg-gray-100 rounded-md"
                          onClick={() => handleUpdateRating(key)}
                        >
                          {rating.emoji}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="w-full lg:w-2/3">
                  <div>คะแนนที่ได้รับ</div>
                  <div className=" flex gap-1 my-2 w-full overflow-x-auto">
                    {_.map(customerRating?.rows, (each, rateIndex) => (
                      <div key={rateIndex}>
                        <div className="relative h-10 ">
                          <div className="absolute flexjustify-center">
                            <Avatar
                              sizes="large"
                              src={each?.employee?.image?.url}
                            >
                              {_.first(each?.employee?.firstname)}
                              {_.first(each?.employee?.lastname)}
                            </Avatar>
                          </div>
                          <div className="absolute text-center text-lg inset-x-1 inset-y-5">
                            {RATING[each?.rating]?.emoji}{' '}
                          </div>
                        </div>
                        <span className="text-xs text-center">
                          {dayjs(each?.updatedAt).format('MMM BB')}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CustomerRatingBox;

CustomerRatingBox.propTypes = {
  customer: PropTypes.object,
  handleUpdateRating: PropTypes.func,
  customerRating: PropTypes.shape({
    rows: PropTypes.array,
  }),
};
