import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  LinearProgress,
} from '@mui/material';
import PropTypes from 'prop-types';

export default function LoadingLinearDialog({ isLoading, label }) {
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(10);

  const progressRef = useRef(() => {});
  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div>
      <Dialog open={isLoading}>
        <DialogContent>
          <div className="flex w-full justify-center my-2">
            <Box sx={{ width: '100%' }}>
              <LinearProgress
                variant="buffer"
                value={progress}
                valueBuffer={buffer}
                style={{ height: 15 }}
              />
            </Box>
          </div>
          <div>{label}</div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

LoadingLinearDialog.propTypes = {
  isLoading: PropTypes.bool,
  label: PropTypes.string,
};

LoadingLinearDialog.defaultProps = {
  isLoading: true,
};
