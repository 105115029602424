import React, { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { BackButton } from '@iarcpsu/emanufac-components/src/components/Button';
import { Loading } from '@iarcpsu/emanufac-components/src/components/Loading';
import { ViewTitle } from '@iarcpsu/emanufac-components/src/components/ViewTitle';
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import BuddhistCalendar from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import PropTypes from 'prop-types';

import 'dayjs/locale/th';

import * as actions from '../../../redux/actions';
import { DATA_TYPE } from '../../../utils/constants';

dayjs.extend(BuddhistCalendar);

/**
 * @function SettingManufacturingOrder
 * @memberof MMS/Setting
 * @desription การตั้งค่าเกี่ยวกับคำสั่งผลิต ให้แสดงอะไรในรายงานบ้าง ใช้อะไรเป็นการเริ่มต้นบ้าง
 */

const SettingManufacturingOrder = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  const measurementType = useSelector((state) => state.measurementType);
  const { control, handleSubmit } = useForm({
    defaultValues: { setting: information?.setting },
  });

  const { append, fields, remove } = useFieldArray({
    control,
    name: 'setting.mms.processAdditionalAttribute',
  });

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        await dispatch(actions.departmentAll({}));
      } catch (error) {
        console.error('Fetch Department Error', error);
      }
    };
    const fetchMeasurementType = async () => {
      try {
        await dispatch(actions.measurementTypeAll({}));
      } catch (error) {
        console.error('Fetch Department Error', error);
      }
    };
    fetchMeasurementType();
    fetchDepartment();
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleChangeSetting = async (data) => {
    console.log('Data', data);
    const confirm = window.confirm('ยืนยันการปรับการตั้งค่า');
    if (confirm) {
      try {
        await dispatch(
          actions.informationPut(information?._id, {
            ...data,
            setting: {
              ...data.setting,
              mms: {
                ...data.setting.mms,
                fixedLastStep: {
                  ...data.setting.mms.fixedLastStep,
                  measurementTypes: _.map(
                    data?.setting?.mms?.fixedLastStep?.measurementTypes,
                    (_eachMMType) => _eachMMType?._id,
                  ),
                },
              },
            },
          }),
        );
        await dispatch(actions.informationAll());
        window.alert('แก้ไขการตั้งค่าสำเร็จ');
      } catch (error) {
        window.alert('แก้ไขการตั้งค่าไม่สำเร็จ');
        console.error('แก้ไขการตั้งค่าไม่สำเร็จ', error);
      }
    }
  };

  const renderGeneralSetting = () => (
    <Card className="p-4">
      <div className="flex flex-wrap w-full ">
        <div className="w-full py-2 px-2 font-semibold font-display ">
          แสดง Timeline การผลิต
        </div>
        <div className="w-1/2 py-2 px-2">แสดงขั้นตอนเบิกวัตถุดิบ</div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.showPrepareMaterial"
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      defaultChecked={
                        information?.setting?.mms?.showPrepareMaterial
                      }
                    />
                  }
                  label="เปิดใช้งาน"
                />
              </FormControl>
            )}
          />
        </div>
        <div className="w-1/2 py-2 px-2">แสดงขั้นตอนเตรียมการผลิต</div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.showPrepareStep"
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      defaultChecked={
                        information?.setting?.mms?.showPrepareStep
                      }
                    />
                  }
                  label="เปิดใช้งาน"
                />
              </FormControl>
            )}
          />
        </div>
        <div className="w-1/2 py-2 px-2">แสดงขั้นตอนดำเนินการกับผลผลิต</div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.showProductHandler"
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      defaultChecked={
                        information?.setting?.mms?.showProductHandler
                      }
                    />
                  }
                  label="เปิดใช้งาน"
                />
              </FormControl>
            )}
          />
        </div>
        <div className="w-full py-2 px-2 font-semibold font-display ">
          การดำเนินการกับผลผลิต
        </div>
        <div className="w-1/2 py-2 px-2">ชื่อปุ่มดำเนินการกับผลผลิต</div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.productionHandler.productionHandlerName"
            render={({ field }) => (
              <TextField
                {...field}
                label="ชื่อปุ่มดำเนินการกับผลผลิต"
                size="small"
              />
            )}
          />
        </div>
        <div className="w-1/2 py-2 px-2">
          แสดงปุ่มสำหรับดำเนินการกับผลผลิตในทุกขั้นตอน
        </div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.showHandleProductInEveryStep"
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      defaultChecked={
                        information?.setting?.mms?.showHandleProductInEveryStep
                      }
                    />
                  }
                  label="เปิดใช้งาน"
                />
              </FormControl>
            )}
          />
        </div>
        <div className="w-full py-2 px-2 font-semibold font-display ">
          คำสั่งผลิต
        </div>
        <div className="w-1/2 py-2 px-2">ส่วนประกอบเพิ่มเติมของคำสั่งผลิต</div>
        <div className="w-1/2 py-2 px-2">
          <div className="flex flex-col w-full">
            <Controller
              control={control}
              name="setting.mms.manufacturingOrder.showQuotation"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="เลขที่ใบเสนอราคา"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.mms.manufacturingOrder.showPaymentMethod"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ช่องทางชำระเงิน"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.mms.manufacturingOrder.showPrice"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="แสดงราคา"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.mms.manufacturingOrder.showDesignerSignature"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="แสดงแบบฟอร์มลงลายมือชื่อผู้ออกแบบในใบสั่งผลิต"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.mms.manufacturingOrder.showAssignerSignature"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="แสดงแบบฟอร์มลงลายมือชื่อผู้สั่งงานในใบสั่งผลิต"
                />
              )}
            />{' '}
            <Controller
              control={control}
              name="setting.mms.showManufacturingDate"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="แสดงวันผลิต"
                />
              )}
            />{' '}
            <Controller
              control={control}
              name="setting.mms.showExpireDate"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="แสดงวันหมดอายุ"
                />
              )}
            />{' '}
            <Controller
              control={control}
              name="setting.mms.showBatchNumber"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="แสดงเลข Batch"
                />
              )}
            />{' '}
            <Controller
              control={control}
              name="setting.mms.manufacturingOrder.showPO"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="แสดงและให้กรอกเลขที่ PO"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.mms.enableClaimedMO"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="อนุญาตให้มีการสั่งงานเคลม"
                />
              )}
            />{' '}
            <Controller
              control={control}
              name="setting.mms.allow_remove_comment"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="อนุญาตให้ลบคอมเมนต์"
                />
              )}
            />{' '}
            <Controller
              control={control}
              name="setting.mms.manufacturingOrder.showFilterProductAndBaseProjectInDashboardC"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="แสดงกล่องให้ระหว่างกรองสินค้า และ Base Project ใน Dashboard C"
                />
              )}
            />{' '}
          </div>
        </div>
        <div className="w-1/2 py-2 px-2">วิธีการดำเนินการกับผลผลิต</div>
        <div className="w-1/2 py-2 px-2">
          <div className="flex flex-col w-full">
            <Controller
              control={control}
              name="setting.mms.productionHandler.addToWarehouse"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="เพิ่มลงคลังสินค้า"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.mms.productionHandler.openBill"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="เปิดบิล"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.mms.productionHandler.addAsMaterial"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="เพิ่มเป็นวัตถุดิบ"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.mms.productionHandler.markAsFinished"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ทำสัญลักษณ์ว่าสำเร็จ"
                />
              )}
            />{' '}
            <Controller
              control={control}
              name="setting.mms.productionHandler.addToRightTypeOnly"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ให้เข้าคลังวัตถุดิบ หรือ คลังสินค้าอย่างใดอย่างหนึ่ง อยู่ที่ว่าสั่งผลิตอย่างใด"
                />
              )}
            />{' '}
          </div>
        </div>
        <div className="w-1/2 py-2 px-2">ค่าเริ่มต้นในการสั่งผลิต</div>
        <div className="w-1/2 py-2 px-2">
          <div className="flex flex-col w-full">
            <Controller
              control={control}
              name="setting.mms.usingAsDefault.usingFormular"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ใช้สูตรการผลิต"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.mms.usingAsDefault.addMaterial"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ใส่วัตถุดิบส่วนประกอบ"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.mms.usingAsDefault.attachAmount"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ใส่จำนวนที่ผลิต"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.mms.usingAsDefault.attachCustomer"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ใส่ลูกค้าลงในไลน์การผลิตแต่ละไลน์"
                />
              )}
            />{' '}
            <Controller
              control={control}
              name="setting.mms.usingAsDefault.showExpectedDate"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ใส่วันที่ลูกค้าต้องการสินค้า"
                />
              )}
            />{' '}
            <Controller
              control={control}
              name="setting.mms.usingAsDefault.usingBomTemplate"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ใช้ Bom Template ในการสั่งผลิต"
                />
              )}
            />{' '}
          </div>
        </div>
        <div className="w-full py-2 px-2 font-semibold font-display ">
          การใช้สูตรคำสั้งผลิต
        </div>
        <div className="w-1/2 py-2 px-2">เปิดการใช้งานสูตรคำสั่งผลิต</div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.mms.allowUseMOTemplate"
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      defaultChecked={
                        information?.setting?.mms?.allowUseMOTemplate
                      }
                    />
                  }
                  label="เปิดใช้งาน"
                />
              </FormControl>
            )}
          />
        </div>{' '}
        <div className="w-full py-2 px-2 font-semibold font-display ">
          ส่วนประกอบเพิ่มเติม
        </div>
        <div className="w-1/2 py-2 px-2">
          <div className="flex flex-col w-full">
            {_.map(fields, (eachField, index) => (
              <div key={eachField.id} className="flex my-2">
                <div className="w-1/2 px-1">
                  <Controller
                    control={control}
                    name={`setting.mms.processAdditionalAttribute.[${index}].name`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="ชื่อ"
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </div>
                <div className="w-1/4 px-1">
                  <Controller
                    control={control}
                    name={`setting.mms.processAdditionalAttribute.[${index}].type`}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel>รูปแบบข้อมูล</InputLabel>
                        <Select {...field} size="small" fullWidth>
                          {_.map(
                            _.filter(DATA_TYPE, { is_basic: true }),
                            (eachDataType, idx) => (
                              <MenuItem
                                key={idx}
                                value={eachDataType?.type_code}
                              >
                                {eachDataType?.description}
                              </MenuItem>
                            ),
                          )}
                        </Select>
                      </FormControl>
                    )}
                  />
                </div>
                <div className="w-1/4 px-1">
                  <Button
                    color="error"
                    variant="contained"
                    size="small"
                    onClick={() => remove(index)}
                  >
                    ลบ
                  </Button>
                </div>
              </div>
            ))}
            <div>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => append({})}
              >
                เพิ่ม
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );

  if (measurementType.isLoading) {
    return <Loading />;
  }

  return (
    <div>
      {renderTitle()}
      <div className="flex flex-row justify-start pb-4">
        <div>
          <BackButton />
        </div>
      </div>
      <form onSubmit={handleSubmit(handleChangeSetting)}>
        <div>{renderGeneralSetting()}</div>
        <div className="flex justify-end mt-2">
          <Button color="primary" variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  );
};

SettingManufacturingOrder.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SettingManufacturingOrder.defaultProps = {
  title: '',
  subtitle: '',
};

export default SettingManufacturingOrder;
