import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import {
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { config } from '@/configs';
import * as actions from '@/redux/actions';

/**
 * @function MaterialTypeListForLaborCost
 * @description Display a collections or a list of MaterialLaborCost from database
 */

export default function MaterialTypeListForLaborCost({ title, subtitle }) {
  const dispatch = useDispatch();
  const materialType = useSelector((state) => state.materialType);

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.materialTypeAll({
          page: 1,
          size: config.maxFetchSize,
        }),
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (materialType.isLoading) {
    return <Loading />;
  }
  if (!materialType.isLoading && materialType.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-wrap">
          <Link className="w-full lg:4/12 xl:w-2/12 p-2 cursor-pointer">
            <div>
              <div className="items-center text-center  p-4 bg-white rounded-lg  shadow-xl">
                <i className="fas fa-boxes fa-3x text-gray-500" />
                <div className="text-base my-1 font-display">รวมทุกคลัง</div>
              </div>
            </div>
          </Link>
          {!_.isEmpty(materialType?.rows) &&
            materialType?.rows.map((row, index) => (
              <Link
                key={index}
                className="w-full lg:4/12 xl:w-2/12 p-2 cursor-pointer"
                to={`materials/labor-cost?material-type=${row._id}`}
              >
                <div>
                  <div className="items-center text-center  p-4 bg-white rounded-lg  shadow-xl">
                    <i className="fas fa-boxes fa-3x text-gray-500" />
                    <div className="text-base my-1 font-display">
                      {row.name}{' '}
                    </div>
                  </div>
                </div>
              </Link>
            ))}
        </div>
      </div>
    );
  }
  return <Error />;
}

MaterialTypeListForLaborCost.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MaterialTypeListForLaborCost.defaultProps = {
  title: '',
  subtitle: '',
};
