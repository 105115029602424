export const MATERIAL_REQUEST_STATUS = {
  PENDING_APPROVAL: {
    status_code: 'PENDING_APPROVAL',
    description: 'รออนุมัติ',
  },
  APPROVAL: {
    status_code: 'APPROVAL',
    description: 'อนุมัติแล้ว รอการจัดซื้อ',
  },
  SUCCESSFUL_PURCHASE: {
    status_code: 'SUCCESSFUL_PURCHASE',
    description: 'จัดซื้อสำเร็จ',
  },
  REJECT: {
    status_code: 'REJECT',
    description: 'ไม่อนุมัติ',
  },
  CANCEL: {
    status_code: 'CANCEL',
    description: 'ยกเลิกคำสั่งซื้อ',
  },
};

export const MATERIAL_STATUS = {
  PENDING_PURCHASE: {
    status_code: 'PENDING_PURCHASE',
    description: 'รออนุมัติ',
  },
  PURCHASE: {
    status_code: 'PURCHASE',
    description: 'จัดซื้อแล้ว รอการจัดส่ง',
  },
};

export const MATERIAL_PURCHASE_STATUS = {
  PURCHASE: {
    status_code: 'PURCHASE',
    description: 'จัดซื้อแล้ว รอการจัดส่ง',
  },
  SUCCESSFUL: {
    status_code: 'SUCCESSFUL',
    description: 'สำเร็จ',
  },
  RECEIVED: {
    status_code: 'RECEIVED',
    description: 'ได้รับวัตถุดิบแล้ว',
  },
  CANCEL: {
    status_code: 'CANCEL',
    description: 'ยกเลิกคำสั่งซื้อ',
  },
};

export default { MATERIAL_REQUEST_STATUS, MATERIAL_PURCHASE_STATUS };
