import React, { Suspense } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Loading } from '../../Loading';

import OnePluginDisplayRenderingComponent from './OnePluginDisplayRenderingComponent';

export default function PluginDisplayRenderingComponents({
  manufacturingOrder,
  currentProcess,
  currentStep,
}) {
  const allDisplayPlugin = _.map(currentProcess?.steps, (eachStep) => ({
    plugin: eachStep?.plugin,
    use_plugin_instead: eachStep?.use_plugin_instead,
  }));

  const usedPlugin = _.filter(allDisplayPlugin, { use_plugin_instead: true });
  console.log('allDisplayPlugin', allDisplayPlugin);
  return (
    <div>
      {' '}
      <Suspense fallback={<Loading />}>
        {_.map(usedPlugin, (eachPlugin, index) => (
          <OnePluginDisplayRenderingComponent
            key={index}
            manufacturingOrder={manufacturingOrder}
            currentProcess={currentProcess}
            currentStep={currentStep}
            pluginInfo={eachPlugin}
          />
        ))}
      </Suspense>
    </div>
  );
}

PluginDisplayRenderingComponents.propTypes = {
  manufacturingOrder: PropTypes.object,
  currentProcess: PropTypes.object,
  currentStep: PropTypes.object,
};
