import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { ViewTitle } from '@iarcpsu/emanufac-components/src/components/ViewTitle';
import * as actions from '../../redux/actions';
import { NotifySettingForm } from '@iarcpsu/emanufac-components/src/components/Forms';
import Loading from '@iarcpsu/emanufac-components/src/components/Loading';
import { Error } from '@iarcpsu/emanufac-components/src/components/Error';

function NotifySetting({ title, subtitle }) {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  const department = useSelector((state) => state.department);

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({ defaultValues: information });

  useEffect(() => {
    const fetchAllDepartments = async () => {
      try {
        await dispatch(actions.departmentAll({}));
      } catch (error) {
        console.error('Fetch Departments Error', error);
      }
    };

    fetchAllDepartments();
    dispatch(actions.informationAll());
    return () => {};
  }, []);

  const onSubmit = async (data, event) => {
    try {
      event.preventDefault();
      await dispatch(actions.informationPut(information.id, data));
      alert('สำเร็จ');
      await dispatch(actions.informationAll());
    } catch (error) {
      console.error(error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderForm = () => (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="">
        <NotifySettingForm
          control={control}
          information={information}
          errors={errors}
          department={department}
        />{' '}
      </div>

      <div className="flex flex-row justify-end gap-1 py-4">
        <Button variant="contained" type="submit">
          บันทึก
        </Button>
      </div>
    </form>
  );

  if (information.isLoading) {
    return <Loading />;
  }
  if (!information.isLoading && information.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderForm()}
      </div>
    );
  }
  return <Error />;
}
NotifySetting.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

NotifySetting.defaultProps = {
  title: '',
  subtitle: '',
};

export default NotifySetting;
