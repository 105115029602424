import React, { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Card } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { BackButton } from '@iarcpsu/emanufac-components/src/components/Button';
import { Error } from '@iarcpsu/emanufac-components/src/components/Error';
import { CustomerForm } from '@iarcpsu/emanufac-components/src/components/Forms';
import { Loading } from '@iarcpsu/emanufac-components/src/components/Loading';
import { ViewTitle } from '@iarcpsu/emanufac-components/src/components/ViewTitle';
import * as actions from '../../redux/actions';

const defaultValues = {
  type: '',
  organization_type: '',
  type_code: '',
  name: '',
  address: '',
  taxes: '',
  phone_number: '',
  fax: '',
  email: '',
  payment: '',
};

export default function CreateCustomer({ title, subtitle }) {
  const dispatch = useDispatch();
  const paymentType = useSelector((state) => state.paymentType);
  const customerType = useSelector((state) => state.customerType);
  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    watch,
  } = useForm({
    defaultValues: {
      contact: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contact',
  });

  useEffect(() => {
    dispatch(actions.paymentTypeAll(''));
    dispatch(actions.customerTypeAll(''));
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      if (_.isEmpty(getValues('contact'))) {
        await dispatch(actions.customerCreate(data));
      } else {
        data.contact = getValues('contact');
        await dispatch(actions.customerCreate(data));
      }
      alert('สำเร็จ');
      reset(defaultValues);
      history.goBack();
    } catch (error) {
      console.log(error);
      alert(`ดำเนินการไม่สำเร็จ${error?.message}`);
    }
  };

  if (customerType.isLoading || paymentType.isLoading) {
    return <Loading />;
  }

  if (
    !customerType.isLoading &&
    customerType.isCompleted &&
    !paymentType.isLoading &&
    paymentType.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <div>
          <Card className="p-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <CustomerForm
                Controller={Controller}
                control={control}
                errors={errors}
                customerType={customerType}
                paymentType={paymentType}
                fields={fields}
                append={append}
                remove={remove}
                watch={watch}
              />
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </div>
    );
  }

  return <Error message={customerType?.message} />;
}

CreateCustomer.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateCustomer.defaultProps = {
  title: '',
  subtitle: '',
};
