import React from 'react';
import PropTypes from 'prop-types';

import LaborTDABCArtifact from './LaborTDABCArtifact';
import LaborTDABCReal from './LaborTDABCReal';

function LaborCostTDABC({
  previousLotList,
  currentLot,
  isCurrentAsProductLot,
  place,
  useTDABCArtifact,
}) {
  return (
    <div>
      {useTDABCArtifact ? (
        <LaborTDABCArtifact
          place={place}
          previousLotList={previousLotList}
          currentLot={currentLot}
        />
      ) : (
        <LaborTDABCReal
          previousLotList={previousLotList}
          currentLot={currentLot}
          isCurrentAsProductLot={isCurrentAsProductLot}
          place={place}
        />
      )}
    </div>
  );
}

export default LaborCostTDABC;
