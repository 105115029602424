import React from 'react';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';

export function MaterialRequestForm({
  errors,
  Controller,
  control,
  department,
  me,
  materialRequest,
}) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'request_name'}
          control={control}
          rules={{ required: true }}
          defaultValue={
            materialRequest?.request_name
              ? `${materialRequest?.request_name}`
              : `${me?.firstname} ${me?.lastname}`
          }
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อผู้สั่งซื้อวัตถุดิบ"
              fullWidth
              size={'small'}
              required
              helperText={errors.request_name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'request_department'}
          control={control}
          defaultValue={
            materialRequest?.request_department?.id
              ? `${materialRequest?.request_department?.id}`
              : `${me?.department?.id}`
          }
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true} required>
              <InputLabel id="type" size={'small'}>
                แผนกผู้สั่งซื้อ
              </InputLabel>
              <Select
                {...field}
                label="แผนกผู้สั่งซื้อ"
                size={'small'}
                fullWidth
                defaultValue=""
              >
                {_.size(department) ? (
                  _.map(department, (_department) => (
                    <MenuItem key={_department.id} value={_department.id}>
                      {_department.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full lg:w-full px-1 py-2">
        <Controller
          name={'remark'}
          control={control}
          defaultValue={materialRequest?.remark ? materialRequest?.remark : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="หมายเหตุ"
              fullWidth
              size={'small'}
              multiline={true}
              rows={3}
              helperText={errors.remark && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
    </div>
  );
}

MaterialRequestForm.propTypes = {
  errors: PropTypes.shape({
    request_name: PropTypes.object,
    request_department: PropTypes.object,
    remark: PropTypes.object,
  }),
  materialTransactionType: PropTypes.object,
  place: PropTypes.object,
  department: PropTypes.object,
  me: PropTypes.object,
  materialRequest: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
};

MaterialRequestForm.defaultProps = {
  department: null,
  me: null,
  materialRequest: null,
};

export default MaterialRequestForm;
