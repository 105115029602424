import dayjs from 'dayjs';
import _ from 'lodash';
import { findAmountOfWorkDayFromInformation } from './findAmountOfWorkDay';

const getDataFrontend = async ({ currentDate, placeId, api, maxFetchSize }) => {
  const searchParams = new URLSearchParams({
    page: 1,
    size: maxFetchSize,
    startDate: dayjs(currentDate).format('YYYY-MM-DD'),
    endDate: dayjs(currentDate).format('YYYY-MM-DD'),
    place: placeId?.toString(),
  });

  const { data } = await api.get(
    `${
      process.env.REACT_APP_API_URL
    }/place-labor-cost?${searchParams.toString()}`,
  );
  return data;
};

const getPlaceLaborCostInDay = async ({
  placeId,
  currentDate,
  isFrontend = true,
  axios: api,
  maxFetchSize = 10000,
  information,
}) => {
  try {
    let data = [];

    if (isFrontend) {
      data = await getDataFrontend({
        currentDate,
        placeId,
        api,
        maxFetchSize,
      });
    }

    // TODO: Create get data from backend

    // console.log('Data', data);
    // Looping inside data to get total cost for our day
    const costList = _.map(data.rows, (each) => {
      let eachCost = 0;
      // is same day return cost
      if (
        dayjs(currentDate).isSame(dayjs(each?.start_date), 'date') &&
        dayjs(currentDate).isSame(dayjs(each?.end_date), 'date')
      ) {
        eachCost = each?.cost;
      } else {
        // Else divide cost by amount of day
        const startDay = dayjs(each?.start_date);
        const endDay = dayjs(each?.end_date);
        const totalAmountOfDay = findAmountOfWorkDayFromInformation({
          information,
          startPeriod: startDay,
          endPeriod: endDay,
        });

        // console.log(
        //   `Total Cost ${each?.cost} valid from ${dayjs(startDay).format(
        //     'D MMM',
        //   )} to ${dayjs(endDay).format('D MMM')} Amount ${totalAmountOfDay}`,
        // );
        eachCost = (each?.cost || 0) / totalAmountOfDay;
      }

      // console.log(
      //   `Cost is ${eachCost} divided by ${_.size(each?.places)} Places`,
      // );
      // Cost Must Be divide to the place
      // TODO: Add More related to the real experiment
      const numberOfPlace = _.size(each?.places) || 1;
      eachCost /= numberOfPlace;
      return eachCost;
    });

    return _.sum(costList);
  } catch (error) {
    console.error('Cannot Get Place Labor Cost In Day', error);
    return 0;
  }
};

export default getPlaceLaborCostInDay;
