/* eslint-disable no-nested-ternary */
import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { Autocomplete, Button, TextField } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

const ProcessTemplateMeasurementTypeForm = ({
  control,
  stepIndex,
  measurementType,
  product,
  alternativeName = null,
  watch,
}) => {
  const { append, remove, fields } = useFieldArray({
    control,
    name: alternativeName || `steps[${stepIndex}].measurementTypes`,
  });

  if (!measurementType?.rows) {
    return <div></div>;
  }

  return (
    <div>
      <div className="py-2 flex flex-wrap">
        {_.map(fields, (_field, index) => (
          <div className="w-full lg:w-1/2 flex  my-2" key={_field.id}>
            <div className="w-4/5 px-2">
              <Controller
                name={
                  alternativeName
                    ? `${alternativeName}[${index}]`
                    : `steps[${stepIndex}].measurementTypes[${index}]`
                }
                defaultValue={
                  alternativeName
                    ? watch(`${alternativeName}[${index}]`)
                    : product?.steps?.[stepIndex]?.measurementTypes?.[index]
                }
                control={control}
                render={({ field }) => (
                  <div>
                    <Autocomplete
                      {...field}
                      size={'small'}
                      disableClearable
                      onChange={(e, newValue) => {
                        field.onChange(newValue);
                      }}
                      options={measurementType?.rows}
                      getOptionLabel={(option) =>
                        `${option?.name}  ${option?.description || ''}`
                      }
                      required
                      renderInput={(params) => (
                        <TextField {...params} label="เลือกตัวแปร" />
                      )}
                    />{' '}
                  </div>
                )}
              />
            </div>
            <div className="w-1/5 px-2">
              <Button
                size="small"
                variant="contained"
                color="error"
                onClick={() => remove(index)}
              >
                ลบ
              </Button>
            </div>
          </div>
        ))}
      </div>
      <div className="flex">
        <Button size="small" variant="contained" onClick={() => append({})}>
          เพิ่ม
        </Button>
      </div>
    </div>
  );
};

export default ProcessTemplateMeasurementTypeForm;

ProcessTemplateMeasurementTypeForm.propTypes = {
  control: PropTypes.func,
  stepIndex: PropTypes.number,
  measurementType: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  product: PropTypes.shape({
    steps: PropTypes.arrayOf(PropTypes.object),
  }),
  alternativeName: PropTypes.string,
  watch: PropTypes.func,
};
