import {
  RESULTING_FN_TYPE_ALL,
  RESULTING_FN_TYPE_GET,
  RESULTING_FN_TYPE_DELETE,
  RESULTING_FN_TYPE_PUT,
  RESULTING_FN_TYPE_POST,
  RESULTING_FN_TYPE_LOADING,
  RESULTING_FN_TYPE_ERROR,
} from '../types';

import graphqlClient from '../../../configs/graphqlClient';
import api from '../../../configs/api';

export const resultingFunctionTypeCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: RESULTING_FN_TYPE_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/resulting-function-type`,
      {
        ...payload,
      },
    );
    dispatch({ type: RESULTING_FN_TYPE_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: RESULTING_FN_TYPE_ERROR });
    throw new Error(error);
  }
};

export const resultingFunctionTypeAll = ({
  name = '',
  size = 10,
  page = 1,
}) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/resulting-function-type?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: RESULTING_FN_TYPE_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: RESULTING_FN_TYPE_ERROR });
    throw new Error(error);
  }
};

export const resultingFunctionTypeAllQuery = ({ query }) => async (
  dispatch,
) => {
  try {
    const queryResult = await graphqlClient.request(query);
    const data = queryResult?.findResultingFunctionTypes;
    //  console.log('Query Data', queryResult);
    if (!data) {
      dispatch({ type: RESULTING_FN_TYPE_ALL });
    }

    dispatch({ type: RESULTING_FN_TYPE_ALL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: RESULTING_FN_TYPE_ERROR });
    throw new Error(error);
  }
};

export const resultingFunctionTypeGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/resulting-function-type/${id}`,
    );
    if (status === 200) {
      dispatch({ type: RESULTING_FN_TYPE_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: RESULTING_FN_TYPE_ERROR });
    throw new Error(error);
  }
};

export const resultingFunctionTypeStateSet = (data) => async (dispatch) => {
  dispatch({ type: RESULTING_FN_TYPE_ALL, payload: data });
};

export const resultingFunctionTypePut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: RESULTING_FN_TYPE_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/resulting-function-type/${id}`,
      payload,
    );
    dispatch({ type: RESULTING_FN_TYPE_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: RESULTING_FN_TYPE_ERROR });
    throw new Error(error);
  }
};
export const resultingFunctionTypeDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: RESULTING_FN_TYPE_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/resulting-function-type/${id}`,
    );
    dispatch({ type: RESULTING_FN_TYPE_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: RESULTING_FN_TYPE_ERROR });
    throw new Error(error);
  }
};
