import dayjs from 'dayjs';
import _ from 'lodash';

const groupEvent = (order) => {
  const { completed, deleted } = order;
  if (completed === true) {
    return 'completed';
  }
  if (deleted === true) {
    return 'deleted';
  }

  if (dayjs().isAfter(order?.expected_date)) {
    return 'late';
  }

  return 'pending';
};
export const stepToEvent = (steps) =>
  // console.log('orders', orders);
  _.map(steps, (eachStep) => {
    const returnedObject = {
      type: groupEvent(eachStep),
      id: eachStep?._id,
      title: `${eachStep.mo_number} ${eachStep?.customer?.name || ''} ${
        eachStep?.product?.name || ''
      } ${eachStep?.name || ''}  `,
      allDay: true,
      status: eachStep?.status,
      start: dayjs(new Date(eachStep?.createdAt)).startOf('day').toString(),
      end: eachStep?.expected_date
        ? dayjs(new Date(eachStep?.expected_date)).endOf('day').toString()
        : dayjs(new Date(eachStep?.createdAt)).startOf('day').toString(),
    };
    return returnedObject;
  });
export default stepToEvent;
