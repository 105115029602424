import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Controller } from 'react-hook-form';
import { DesktopDatepicker } from '../../DatePicker';

const DateInput = ({
  control,
  measurementType,
  measurementControllerName = '',
}) => (
  <div>
    <div>
      <Controller
        control={control}
        name={`${measurementControllerName}.date_value`}
        defaultValue={dayjs().format('HH:mm')}
        render={({ field }) => (
          <DesktopDatepicker
            label={measurementType?.label}
            setValue={field.onChange}
            value={field.value}
          />
        )}
      />
    </div>
  </div>
);

DateInput.propTypes = {
  control: PropTypes.object,
  measurementType: PropTypes.object,
  measurementControllerName: PropTypes.string,
};

export default DateInput;
