import _ from 'lodash';

export const stepStatusCheck = (step, me = {}, currentProcess = {}) => {
  // งานที่ว่างหรือไม่ว่าง ดูจากมีคนรับงาน หรือ responsible employee มีแล้วยัง
  const isVacant = _.isEmpty(step?.responsible?.employee) && !step?.completed;
  const isNonVacant =
    step?.responsible?.employee &&
    !_.isEmpty(step?.responsible?.employee) &&
    !step?.completed;

  const isMywork = step?.responsible?.employee?._id === me?.userData?._id;
  const isMyDepartment =
    step?.responsible?.department?._id === me?.userData?.department?._id ||
    _.includes(
      _.map(me?.userData?.other_departments, (_otherDept) => _otherDept?._id),
      step?.responsible?.department?._id,
    );

  // เราจะทำเหมือนว่า Admin เป็นพนักงานในทุกแผนกโดยทันที
  const isAdmin = me?.userData?.role?.level >= 1;
  const isCurrentStep = step?.process?.current?.index === step?.index;

  return {
    isNonVacant,
    isStarted: isNonVacant && step?.started === true,
    isVacant,
    isAdmin,
    isCurrentStep,
    isAdminDoneWork: step?.completed && _.isEmpty(step?.responsible?.employee),
    isSuccess: step?.completed,
    isDelete: step?.deleted,
    isMyPendingWork: isMywork && !step?.completed,
    isMywork,
    isMyDepartment,
    isAnotherWork: isNonVacant && !isMywork,
    isMyDepartmentAndSeeking:
      (isMyDepartment || isAdmin) && !isNonVacant && !step?.completed,
    isLastStep: step?.index + 1 === _.size(currentProcess?.steps),
    buttonAllow:
      step?.started &&
      isCurrentStep &&
      !step?.deleted &&
      !step?.completed &&
      ((isAdmin && !_.isEmpty(step?.responsible?.employee)) || isMywork),
    buttonStartAllow:
      !step?.started &&
      isCurrentStep &&
      !step?.deleted &&
      !step?.completed &&
      ((isAdmin && !_.isEmpty(step?.responsible?.employee)) || isMywork),
    materialAssociateButtonAllow:
      step?.started &&
      step?.material_associate &&
      isCurrentStep &&
      !step?.deleted &&
      !step?.completed &&
      ((isAdmin && !_.isEmpty(step?.responsible?.employee)) || isMywork),
    measurementAssociateButtonAllow:
      step?.started &&
      !_.isEmpty(step?.measurements) &&
      isCurrentStep &&
      !step?.deleted &&
      !step?.completed &&
      ((isAdmin && !_.isEmpty(step?.responsible?.employee)) || isMywork),
  };
};

export default stepStatusCheck;
