import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DAYS } from '@iarcpsu/emanufac-constant';
import { TableCell, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { api } from '@/configs';

import PlaceLaborCostOnPlaceTableCell from './PlaceLaborCostOnPlaceTableCell';

const PlaceLaborCostByDayTableRow = ({ index, selectedDay, orderedPlace }) => {
  const information = useSelector((state) => state.information);
  const [allPlaceLaborCost, setAllPlaceLaborCost] = useState([]);

  const getPlaceLaborCost = async () => {
    try {
      const { data } = await api.get(
        `${process.env.REACT_APP_API_URL}/place-labor-cost?startDate=${dayjs(
          selectedDay,
        ).format('YYYY-MM-DD')}&endDate=${dayjs(selectedDay).format(
          'YYYY-MM-DD',
        )}`,
      );
      const tempPlaceLaborCost = data.rows;
      setAllPlaceLaborCost(tempPlaceLaborCost);
    } catch (error) {
      console.error(
        `Cannot Get Place Labor Cost: for ${dayjs(selectedDay).format(
          'D MMM BBBB',
        )}  ${error}`,
      );
    }
  };

  useEffect(() => {
    getPlaceLaborCost();

    return () => {};
  }, [selectedDay]);

  const allowWorkOnThisDay = () => {
    const dayOfWeek = dayjs(selectedDay).day();
    const foundDayWord = _.find(
      DAYS,
      (eachDay) => eachDay.dayOfWeek === dayOfWeek,
    );
    const workingDayObj = information?.setting?.pams?.working_day;
    if (workingDayObj[foundDayWord?.lowercase] === true) return true;
    return false;
  };

  return (
    <TableRow
      key={index}
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <TableCell component="th" scope="row">
        <div className="text-xs">{index + 1}</div>
      </TableCell>
      <TableCell>{dayjs(selectedDay).format('dd D MMM BBBB')}</TableCell>
      {_.map(orderedPlace, (eachPlace, placeIndex) => (
        <TableCell key={placeIndex}>
          {allowWorkOnThisDay() ? (
            <PlaceLaborCostOnPlaceTableCell
              allPlaceLaborCost={allPlaceLaborCost}
              placeId={eachPlace._id}
              information={information}
              startDate={selectedDay}
            />
          ) : (
            <div>วันหยุด</div>
          )}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default PlaceLaborCostByDayTableRow;

PlaceLaborCostByDayTableRow.propTypes = {
  index: PropTypes.number,
  selectedDay: PropTypes.object,
  orderedPlace: PropTypes.arrayOf(PropTypes.object),
};
