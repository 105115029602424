import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import accessRight from '../../utils/functions/accessRight';
import { NotAuthorized } from '@iarcpsu/emanufac-components/src/components/Error';
import {
  PaymentType,
  ShippingType,
  EditPaymentType,
  EditShippingType,
} from '../../views/SPM';

export function SettingSPM() {
  const module = 'SPM';
  const prefix = '/setting/spm';
  const name = 'ตั้งค่าระบบ';
  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);

  if (accessRight(me, module, 1, availableModule)) {
    return (
      <div>
        <Route exact path={`${prefix}/shipping-types`}>
          <ShippingType title="รูปแบบการจัดส่ง" subtitle={name} />
        </Route>
        <Route exact path={`${prefix}/payment-types`}>
          <PaymentType title="รูปแบบการชำระเงิน" subtitle={name} />
        </Route>
        <Route exact path={`${prefix}/shipping-types/edit/:id`}>
          <EditShippingType title="แก้ไขรูปแบบการจัดส่ง" subtitle={name} />
        </Route>
        <Route exact path={`${prefix}/payment-types/edit/:id`}>
          <EditPaymentType title="แก้ไขรูปแบบการชำระเงิน" subtitle={name} />
        </Route>
      </div>
    );
  } else {
    return (
      <div>
        <Route path="*">
          <NotAuthorized />
        </Route>
      </div>
    );
  }
}

export default SettingSPM;
