import React from 'react';
import { Button, Chip } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  converseMaterialOrProductUnit,
  currencyFormatter,
} from '@/utils/functions';

import MultiUnitProdOrMatBox from '../Box/Rendering/MultiUnitProdOrMatBox';
/**
 *
 *
 * @function CardListProductOrMaterial
 * @memberof Component/Card
 * @description การ์ดรวมรายการวัตถุดิบ/สินค้า และจำนวนคงเหลือ
 * @returns {React.Component} React Component
 */

const CardListProductOrMaterial = ({
  payload,
  handlePushToStock = () => {},
  setSize = () => {},
  size,
  page,
  total,
}) => (
  <div>
    <div className="flex flex-wrap py-4">
      {_.map(payload?.rows, (row, index) => (
        <div
          key={index}
          className="w-full lg:4/12 xl:w-3/12 p-2 cursor-pointer"
          onClick={() => {
            handlePushToStock(row?._id);
          }}
        >
          <div className="items-center  p-4 bg-white rounded-lg  shadow-lg hover:shadow-xl">
            <div className="text-sm  font-display">
              รหัสสินค้า {row?.type_code}{' '}
              {row?.virtual && (
                <Chip label="Virtual" size="small" color="success" />
              )}
              {row?.stranger && (
                <Chip label="ไม่ใช่ของคลัง" size="small" color="warning" />
              )}
            </div>
            <div className="text-base font-display">{row?.name}</div>
            <div className="text-sm font-display text-gray-500">
              หมวดหมู่ {row?.material_type?.name}
            </div>
            <div className="flex justify-between">
              <div className="text-lg font-semibold my-2 font-display">
                <MultiUnitProdOrMatBox
                  foundMaterial={row}
                  quantity={row?.total}
                />
              </div>
              {row?.warning && (
                <div className="text-red-500 text-right">
                  <i className="fas fa-caret-down text-2xl"></i>
                  <div className="text-sm">
                    {parseFloat(row?.minimum_amount - row?.total).toFixed(2)}{' '}
                    {row?.use_unit_conversion
                      ? row?.unit_input?.short_sign
                      : row?.unit}
                    {row?.use_unit_conversion && (
                      <div>
                        ({' '}
                        {currencyFormatter.format(
                          converseMaterialOrProductUnit(
                            row?.minimum_amount - row?.total,
                          ),
                        )}{' '}
                        {row?.unit_output?.short_sign} )
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
    {size * page < total && (
      <div className="flex justify-center">
        <Button
          variant="contained"
          color="inherit"
          startIcon={<i className="fas fa-chevron-down" />}
          onClick={() => setSize(size + 20)}
        >
          โหลดเพิ่มอีก{' '}
          {total - size * page > 20 ? '20 ชิ้น' : `${total - size * page} ชิ้น`}
        </Button>
      </div>
    )}
  </div>
);

CardListProductOrMaterial.propTypes = {
  payload: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  handlePushToStock: PropTypes.func,
  setSize: PropTypes.func,
  size: PropTypes.number,
  page: PropTypes.number,
  total: PropTypes.number,
};

export default CardListProductOrMaterial;
