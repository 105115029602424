import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  BackButton,
  CardInternalDeposit,
  Error,
  InternalDepositReport,
  Loading,
  QuotationProductTable,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';

/**
 * @function DetailQuotation
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function DetailInternalDeposit({ title, subtitle }) {
  const dispatch = useDispatch();
  const internalDeposit = useSelector((state) => state.internalDeposit);
  const information = useSelector((state) => state.information);
  const params = useParams();

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.internalDepositGet(params.id));
    } catch (error) {
      dispatch(actions.internalDepositError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handlePrintPdf = () => {
    InternalDepositReport(internalDeposit, information);
  };

  if (internalDeposit.isLoading) {
    return <Loading />;
  }

  if (!internalDeposit.isLoading && internalDeposit.isCompleted) {
    return (
      <div>
        <div className="flex items-center justify-between">
          {renderTitle()}
          <div className="flex items-center gap-1">
            <Button
              variant="contained"
              color={'teal'}
              onClick={() => {
                handlePrintPdf();
              }}
            >
              พิมพ์
            </Button>
            <Link
              to={
                internalDeposit?.completed || internalDeposit?.deleted
                  ? ''
                  : `/spm/internal-deposit/edit/${internalDeposit?._id}`
              }
            >
              <Button
                variant="contained"
                color={'warning'}
                disabled={
                  internalDeposit?.completed || internalDeposit?.deleted
                }
              >
                แก้ไข
              </Button>
            </Link>
          </div>
        </div>
        <BackButton />
        <div className="my-2">
          <CardInternalDeposit internalDeposit={internalDeposit} />
        </div>
        <div className="my-2">
          <QuotationProductTable
            quotation={internalDeposit}
            showAdditonalPrice={false}
            showPrice={false}
          />
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailInternalDeposit.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  log: PropTypes.bool,
};

DetailInternalDeposit.defaultProps = {
  title: '',
  subtitle: '',
};
