const MO_TEMPLATE_AMOUNT_METHOD = {
  SPECIFY: {
    type_code: "SPECIFY",
    description: "ระบุประมาณที่ต้องการ",
  },
  REQUIRE_ON_MO_CREATE: {
    type_code: "REQUIRE_ON_MO_CREATE",
    description: "ระบุในตอนสร้างคำสั่งผลิต",
  },
  NOT_REQUIRE: {
    type_code: "NOT_REQUIRE",
    description: "ไม่ต้องให้มีการระบุ",
  },
};

module.exports = MO_TEMPLATE_AMOUNT_METHOD;
