import React, { useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import _ from 'lodash';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Button,
  Autocomplete,
} from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { ProdOrMatQuantityInput } from '../../Inputs';

/**
 * @namespace Form/MMS
 * @description ฟอร์มของระบบบริหารจัดการการผลิต
 */

/**
 * @function AddAsMaterialForm
 * @description การเพิ่มผลผลิตที่ได้เป็นวัตถุดิบ กลับไปยังการคลังวัตถุดิบ
 * @memberof Form/MMS
 */
const AddAsMaterialForm = ({
  control,
  materialTransactionType,
  errors,
  place,
  material,
  imsSetting,
  watch,
  setValue,
}) => {
  const [reload, setReload] = useState(false);
  const [showDesktopMode, setIsShowDesktopMode] = useState(
    window.screen.width >= 768,
  );
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'product',
  });

  const renderDesktopMode = () => (
    <div className="overflow-x-auto">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ลำดับที่</TableCell>
            <TableCell>วัตถุดิบ</TableCell>
            {imsSetting?.display?.lot_number && (
              <TableCell>หมายเลขล็อต </TableCell>
            )}
            {(imsSetting?.display?.production_date ||
              imsSetting?.display?.expiration_date) && (
              <TableCell>วันที่ผลิต / วันหมดอายุ </TableCell>
            )}
            <TableCell>จำนวน </TableCell>
            <TableCell>ดำเนินการ </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="overflow-y-auto">
          {_.map(fields, (_field, index) => (
            <TableRow key={_field?.id}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>
                <div className="w-48">
                  {material?.rows && (
                    <Controller
                      name={`material[${index}].material`}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          size={'small'}
                          disableClearable
                          {...field}
                          onChange={(e, newValue) => {
                            field.onChange(newValue);
                            setReload(!reload);
                          }}
                          options={material?.rows}
                          getOptionLabel={(option) =>
                            `${option?.type_code} | ${option?.name}`
                          }
                          required
                          renderInput={(params) => (
                            <TextField {...params} label="วัตถุดิบ" required />
                          )}
                        />
                      )}
                    />
                  )}
                </div>
              </TableCell>

              {imsSetting?.display?.lot_number && (
                <TableCell>
                  <div className="w-48">
                    <Controller
                      name={`material[${index}].lot_number`}
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="หมายเลขล็อต"
                          fullWidth
                          size={'small'}
                          helperText={
                            errors?.material?.[index]?.lot_number &&
                            'กรุณาใส่ข้อมูล '
                          }
                        />
                      )}
                    />
                  </div>
                </TableCell>
              )}
              {(imsSetting?.display?.production_date ||
                imsSetting?.display?.expiration_date) && (
                <TableCell>
                  <div className="w-64">
                    {imsSetting?.display?.production_date && (
                      <div className="py-2">
                        <Controller
                          name={`material[${index}].production_date`}
                          control={control}
                          defaultValue={new Date()}
                          rules={{ required: false }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="date"
                              fullWidth
                              label="วันผลิต"
                              size={'small'}
                              helperText={
                                errors?.material?.[index]?.production_date &&
                                'กรุณาใส่ข้อมูล'
                              }
                            />
                          )}
                        />
                      </div>
                    )}{' '}
                    {imsSetting?.display?.expiration_date && (
                      <div className="py-2">
                        <Controller
                          name={`material[${index}].expiration_date`}
                          control={control}
                          defaultValue={new Date()}
                          rules={{ required: false }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="date"
                              fullWidth
                              label="วันหมดอายุ"
                              size={'small'}
                              helperText={
                                errors?.material?.[index]?.expiration_date &&
                                'กรุณาใส่ข้อมูล'
                              }
                            />
                          )}
                        />{' '}
                      </div>
                    )}
                  </div>
                </TableCell>
              )}
              <TableCell>
                <div className="w-48">
                  <ProdOrMatQuantityInput
                    artificialControllerName={`material[${index}].quantity_artificial`}
                    controllerName={`material[${index}].quantity`}
                    selectedUnitControllerName={`material[${index}].unit_select`}
                    setValue={setValue}
                    control={control}
                    watch={watch}
                    selectedProduct={watch(`material[${index}].material`)}
                    index={index}
                  />
                </div>
              </TableCell>
              <TableCell>
                <Button
                  size="small"
                  variant="contained"
                  color="error"
                  onClick={() => {
                    remove(index);
                  }}
                >
                  ลบ{' '}
                </Button>
              </TableCell>
            </TableRow>
          ))}{' '}
          <TableRow>
            <TableCell>
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => {
                  append({
                    material: null,
                    quantity: 0,
                    cost_price: 0,
                    production_date: dayjs().format('YYYY-MM-DD'),
                    expiration_date: dayjs().format('YYYY-MM-DD'),
                  });
                }}
              >
                เพิ่ม{' '}
              </Button>
            </TableCell>
            <TableCell></TableCell>
            <TableCell> </TableCell>
            <TableCell></TableCell>
            <TableCell> </TableCell>
            <TableCell> </TableCell>
            <TableCell> </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );

  const renderMobileMode = () => (
    <div>
      {_.map(fields, (_field, index) => (
        <div key={_field?.id}>
          <div className="flex gap-2">
            <div>รายการที่ {index + 1}</div>
            <Button
              size="small"
              variant="contained"
              color="error"
              onClick={() => {
                remove(index);
              }}
            >
              ลบ{' '}
            </Button>
          </div>
          <div className="my-2">
            {material?.rows && (
              <Controller
                name={`material[${index}].material`}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    size={'small'}
                    disableClearable
                    {...field}
                    onChange={(e, newValue) => {
                      field.onChange(newValue);
                      setReload(!reload);
                    }}
                    options={material?.rows}
                    getOptionLabel={(option) =>
                      `${option?.type_code} | ${option?.name}`
                    }
                    required
                    renderInput={(params) => (
                      <TextField {...params} label="วัตถุดิบ" required />
                    )}
                  />
                )}
              />
            )}
          </div>

          {imsSetting?.display?.lot_number && (
            <div className="my-2">
              <Controller
                name={`material[${index}].lot_number`}
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="หมายเลขล็อต"
                    fullWidth
                    size={'small'}
                    helperText={
                      errors?.material?.[index]?.lot_number && 'กรุณาใส่ข้อมูล '
                    }
                  />
                )}
              />
            </div>
          )}

          {(imsSetting?.display?.production_date ||
            imsSetting?.display?.expiration_date) && (
            <div className="my-2">
              {imsSetting?.display?.production_date && (
                <div className="py-2">
                  <Controller
                    name={`material[${index}].production_date`}
                    control={control}
                    defaultValue={new Date()}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="date"
                        fullWidth
                        label="วันผลิต"
                        size={'small'}
                        helperText={
                          errors?.material?.[index]?.production_date &&
                          'กรุณาใส่ข้อมูล'
                        }
                      />
                    )}
                  />
                </div>
              )}{' '}
              {imsSetting?.display?.expiration_date && (
                <div className="py-2">
                  <Controller
                    name={`material[${index}].expiration_date`}
                    control={control}
                    defaultValue={new Date()}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="date"
                        fullWidth
                        label="วันหมดอายุ"
                        size={'small'}
                        helperText={
                          errors?.material?.[index]?.expiration_date &&
                          'กรุณาใส่ข้อมูล'
                        }
                      />
                    )}
                  />{' '}
                </div>
              )}
            </div>
          )}

          <div className="my-2">
            <ProdOrMatQuantityInput
              artificialControllerName={`material[${index}].quantity_artificial`}
              controllerName={`material[${index}].quantity`}
              selectedUnitControllerName={`material[${index}].unit_select`}
              setValue={setValue}
              control={control}
              watch={watch}
              selectedProduct={watch(`material[${index}].material`)}
              index={index}
            />
          </div>
        </div>
      ))}
      <Button
        size="small"
        variant="contained"
        color="secondary"
        onClick={() => {
          append({
            material: null,
            quantity: 0,
            cost_price: 0,
            production_date: dayjs().format('YYYY-MM-DD'),
            expiration_date: dayjs().format('YYYY-MM-DD'),
          });
        }}
      >
        เพิ่ม{' '}
      </Button>
    </div>
  );

  return (
    <div>
      <div className="w-full py-2 my-1 ">
        <Controller
          name="material_transaction_type"
          control={control}
          rules={{ required: true }}
          defaultValue={
            _.filter(
              materialTransactionType?.rows,
              (_eachTxnType) => _eachTxnType?.direction === 'add',
            )?.[0]?._id
          }
          render={({ field }) => (
            <FormControl fullWidth required size="small">
              <InputLabel>ประเภทการดำเนินการ</InputLabel>
              <Select {...field} label="ประเภทการดำเนินการ">
                {_.map(materialTransactionType?.rows, (_txnType, index) => {
                  if (_txnType?.direction === 'add') {
                    return (
                      <MenuItem key={index} value={_txnType?._id}>
                        {_txnType?.name}
                      </MenuItem>
                    );
                  }
                  return <div></div>;
                })}
              </Select>
              <FormHelperText>
                {errors?.material_transaction_type &&
                  'กรุณาเลือกประเภทการดำเนินการ'}
              </FormHelperText>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full py-2 my-1">
        <Controller
          name="place"
          control={control}
          rules={{ required: true }}
          defaultValue={place?.rows?.[0]?._id}
          render={({ field }) => (
            <FormControl fullWidth required size="small">
              <InputLabel>คลังวัตถุดิบ</InputLabel>
              <Select {...field} label="คลังสินค้า">
                {_.map(place?.rows, (_warehouse, index) => (
                  <MenuItem key={index} value={_warehouse?._id}>
                    {_warehouse?.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {errors?.place && 'กรุณาเลือกคลังวัตถุดิบ'}
              </FormHelperText>
            </FormControl>
          )}
        />
      </div>

      {showDesktopMode ? renderDesktopMode() : renderMobileMode()}
    </div>
  );
};

AddAsMaterialForm.propTypes = {
  control: PropTypes.object,
  materialTransactionType: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  errors: PropTypes.object,
  place: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  material: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  imsSetting: PropTypes.string,
  watch: PropTypes.func,
  setValue: PropTypes.func,
};

export default AddAsMaterialForm;
