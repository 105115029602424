import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import {
  ViewTitle,
  Loading,
  Error,
} from '@iarcpsu/emanufac-components/src/components';
import * as actions from '../../../redux/actions';
import graphqlClient from '../../../configs/graphqlClient';
import { findMainDetailProcess } from './MainDetailProcess.query';
/**
 * @function MainDetailProcess
 * @description Display a collections or a list of MainDetailProcess from database
 */

export default function MainDetailProcess({
  title,
  subtitle,
  showAsSubPage = false,
  defaultProcessId = '',
}) {
  const dispatch = useDispatch();

  // process is JavaScript Reserved Word, So use currentProcess is more save
  const currentProcess = useSelector((state) => state.process);
  const params = useParams();

  // Use processId instead of id because it can use this view as sub page of MO
  const queryDataFromServer = async () => {
    const queryResult = await graphqlClient.request(findMainDetailProcess, {
      processInput: { id: params.processId || defaultProcessId },
    });
    const processData = queryResult?.findOneProcess;
    dispatch(actions.processStateOneSet(processData));
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (currentProcess.isLoading) {
    return <Loading />;
  }
  if (!currentProcess.isLoading && currentProcess.isCompleted) {
    return (
      <div>
        {!showAsSubPage && renderTitle()}
        <div></div>
      </div>
    );
  }
  return <Error />;
}

MainDetailProcess.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  showAsSubPage: PropTypes.bool,
  defaultProcessId: PropTypes.string,
};

MainDetailProcess.defaultProps = {
  title: '',
  subtitle: '',
  showAsSubPage: false,
  defaultProcessId: '',
};
