import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PRODUCT_STOCK_STATUS } from '@iarcpsu/emanufac-constant';
import { Card, CardContent, Switch } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';

import ProductLotQuantityChart from '../../Chart/ProductLotQuantityChart';
import ProductStockLotTable from '../../Tables/IMS-WMS/ProductStockLotTable';

function ProductStockTab({ selectedProduct, place: warehouse = null }) {
  const information = useSelector((state) => state.information);
  const productStockLot = useSelector((state) => state.productStockLot);
  const me = useSelector((state) => state.me);
  const {
    setting: { wms: wmsSetting },
  } = information;
  const params = useParams();
  const [size, setSize] = useState(25);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState();
  const [showRunOutOff, setIsShowRunOutOff] = useState(false);

  const dispatch = useDispatch();
  const fetchData = () => {
    dispatch(
      actions.productStockLotAll({
        product: params.id,
        warehouse: params.warehouseId,
        size,
        page,
        stockStatus: showRunOutOff
          ? ''
          : PRODUCT_STOCK_STATUS.IN_STOCK.status_code,
      }),
    );
  };

  useEffect(() => {
    fetchData();

    return () => {};
  }, [params, page, size, showRunOutOff]);

  useEffect(() => {
    setTotal(productStockLot?.total);

    return () => {};
  }, [productStockLot]);

  const chartData = _.sortBy(
    productStockLot?.rows,
    ['inventory_date'],
    ['asc'],
  );

  const handleDeleteMaterialLot = async (id) => {
    const confirm = window.confirm('ยืนยันการลบล็อตนี้');
    if (confirm) {
      await dispatch(
        actions.productStockLotDelete(id, {
          employee: me?.userData?._id,
        }),
      );
      fetchData();
    }
  };

  return (
    <div className="my-2">
      <div className="my-2">
        <Card>
          <CardContent>
            <div className="">
              <div className="w-full">
                <ProductLotQuantityChart productStockLotRows={chartData} />
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      <div className="my-2 flex justify-end">
        <div className="flex items-center p-2 border rounded-md">
          <div>
            <Switch
              checked={showRunOutOff}
              onChange={(e) => setIsShowRunOutOff(e.target.checked)}
            />
          </div>
          <div>แสดงล็อตที่หมดแล้ว</div>
        </div>
      </div>
      <ProductStockLotTable
        productStockLot={productStockLot?.rows}
        wmsSetting={wmsSetting}
        page={page}
        selectedProduct={selectedProduct}
        setPage={setPage}
        setSize={setSize}
        showStatus={showRunOutOff}
        size={size}
        total={total}
        warehouse={warehouse}
        showActionButton
        handleDelete={handleDeleteMaterialLot}
      />
    </div>
  );
}

export default ProductStockTab;

ProductStockTab.propTypes = {
  selectedProduct: PropTypes.object,
  /** คลังปัจจุบัน (ถ้ามี) */
  place: PropTypes.object,
};
