import React from 'react';
import { ArrowDown, ArrowRight, Check } from 'react-feather';
import { Link } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import BoxResultingFunction from './BoxResultingFunction';
import OneMeasurementVariableBox from './OneMeasurementVariableBox';

const BoxAllMOMeasurementVariableByStep = ({
  processArray,
  stepIndex,
  stepInfo,
  manufacturingOrderId,
  handleSuccess = () => {},
}) => {
  const renderEachProcessMeasurement = (selectedProcess, index) => {
    const foundedStep = _.find(
      selectedProcess?.steps,
      (selectedStep) => selectedStep?.index === stepIndex,
    );

    if (!foundedStep) {
      return <div key={index}></div>;
    }

    return (
      <div key={index}>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-3/12 px-1 py-2">
            <div className="flex">
              <div className="font-semibold font-display">
                {selectedProcess?.produce_material_instead
                  ? selectedProcess?.product_as_material?.name
                  : selectedProcess?.product?.name}
              </div>
            </div>
            <div className="my-2">
              <div className="font-semibold">ลูกค้า</div>
              <div>
                {selectedProcess?.customer?.type_code || ''}{' '}
                {selectedProcess?.customer?.name || '-'}
              </div>
            </div>

            <div className="my-4">
              <div className="font-semibold">ผู้รับผิดชอบ</div>
              <div>
                {foundedStep?.responsible?.employee?.firstname}{' '}
                {foundedStep?.responsible?.employee?.lastname}
              </div>
            </div>
            {!foundedStep?.completed && !foundedStep?.deleted && (
              <div className="flex gap-2 my-2">
                <Link
                  to={`/mms/process/measurement/edit/${selectedProcess?._id}?step=${foundedStep?._id}`}
                >
                  <Button size="small" variant="contained" color="warning">
                    บันทึกค่า
                  </Button>
                </Link>
                <Button
                  size="small"
                  variant="contained"
                  color="success"
                  onClick={() =>
                    handleSuccess(foundedStep?._id, selectedProcess?._id)
                  }
                >
                  ทำเครื่องหมายว่าสำเร็จ
                </Button>
              </div>
            )}
            {foundedStep?.completed && (
              <div>
                <div className="flex">
                  <div className="rounded-full bg-green-500 text-white p-2">
                    <Check />
                  </div>
                  <div className="px-2 self-center">สำเร็จ</div>
                </div>
              </div>
            )}
          </div>
          <div className="w-full lg:w-1/6 px-1 py-2">
            <div className="flex flex-wrap">
              {_.map(
                foundedStep?.measurements,
                (measurement, measurementIndex) => (
                  <div key={`measurement${measurementIndex}`} className="pr-2">
                    <OneMeasurementVariableBox measurement={measurement} />
                  </div>
                ),
              )}
            </div>
          </div>
          <div className="w-full lg:w-1/12 px-1 self-center md:flex">
            <div className="bg-green-500 text-white p-2 rounded-full justify-center hidden md:flex">
              <ArrowRight />
            </div>
            <div className="bg-gray-300 text-white p-2 mx-2  justify-center flex md:hidden">
              <ArrowDown />
            </div>
          </div>
          <div className="w-full lg:w-3/6 px-1 ">
            {!_.isEmpty(selectedProcess?.resulting_function_types) && (
              <BoxResultingFunction
                currentProcess={selectedProcess}
                currentStepIndex={stepIndex}
                displayStepName={false}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="my-2">
      <Card>
        <CardContent>
          <div className="flex justify-between">
            <div className="text-xl font-semibold font-display">
              {stepInfo?.name}{' '}
            </div>
            <div></div>
          </div>
          {_.map(processArray, (eachProcess, index) =>
            renderEachProcessMeasurement(eachProcess, index),
          )}
        </CardContent>
      </Card>
    </div>
  );
};

BoxAllMOMeasurementVariableByStep.propTypes = {
  processArray: PropTypes.arrayOf(PropTypes.object),
  stepIndex: PropTypes.number,
  stepInfo: PropTypes.object,
  manufacturingOrderId: PropTypes.string,
  handleSuccess: PropTypes.func,
};

export default BoxAllMOMeasurementVariableByStep;
