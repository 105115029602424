import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  INVENTORY_WORKFLOW,
  MATERIAL_STOCK_STATUS,
} from '@iarcpsu/emanufac-constant';
import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { WordLocalizeContext } from '@/contexts/WordLocalizeContext';

function MaterialLotDurationTable({
  imsSetting,
  materialStockLot,
  page,
  total,
  size,
  setPage,
  setSize,
  showStatus = false,
  enableEdit = false,
}) {
  const { findWord } = useContext(WordLocalizeContext);
  return (
    <div>
      <Card>
        <TableContainer>
          <Table className="border-lg mb-4">
            <colgroup>
              <col width="5%" />
              <col width="8%" />
              <col width="10%" />
              <col width="10%" />
              <col width="8%" />
              {imsSetting?.display?.purchase_date && <col width="8%" />}
              <col width="10%" />
              <col width="8%" />
              <col width="7%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">เลขอ้างอิง</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">วัตถุดิบ</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">คลังวัตถุดิบ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">
                    {findWord('recipt_date') || 'วันที่รับวัตถุดิบ'}
                  </div>
                </TableCell>{' '}
                {imsSetting?.display?.purchase_date && (
                  <TableCell>
                    <div className="font-bold">
                      {findWord('purchase_date') || 'วันที่ซื้อ'}
                    </div>
                  </TableCell>
                )}{' '}
                <TableCell>
                  <div className="font-bold"> เวลาในล็อตนี้</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> กิจกรรมที่ทำ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(materialStockLot?.rows) ? (
                materialStockLot?.rows.map((selectedLot, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {selectedLot?.running_number}
                      {showStatus && (
                        <div>
                          {selectedLot?.status ===
                          MATERIAL_STOCK_STATUS.IN_STOCK.status_code ? (
                            <div className="w-32">
                              <div className="bg-gray-100 p-1 text-center rounded-md">
                                <p className="text-green-500 ">
                                  {MATERIAL_STOCK_STATUS.IN_STOCK.description}
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div className="w-32">
                              <div className="bg-gray-100 p-1 text-center rounded-md">
                                <p className="text-red-500 ">
                                  {' '}
                                  {MATERIAL_STOCK_STATUS.OUT_STOCK.description}
                                </p>
                              </div>
                            </div>
                          )}{' '}
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      <b>{selectedLot?.material?.type_code}</b>{' '}
                      {selectedLot?.material?.name}{' '}
                    </TableCell>
                    <TableCell>{selectedLot?.place?.name} </TableCell>
                    <TableCell>
                      {selectedLot?.receipt_date
                        ? dayjs(selectedLot?.receipt_date)
                            .locale('th')
                            .format('D MMM BBBB HH:mm')
                        : '-'}
                    </TableCell>{' '}
                    {imsSetting?.display?.purchase_date && (
                      <TableCell>
                        {selectedLot?.purchase_date
                          ? dayjs(selectedLot?.purchase_date)
                              .locale('th')
                              .format('D MMM BBBB')
                          : '-'}
                      </TableCell>
                    )}{' '}
                    <TableCell>
                      {selectedLot?.duration} นาที
                      {selectedLot?.duration > 60 && (
                        <div>
                          ({Math.floor(selectedLot?.duration / 60)} ชั่วโมง{' '}
                          {selectedLot?.duration % 60} นาที)
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      {
                        INVENTORY_WORKFLOW[selectedLot?.inventory_workflow]
                          ?.description
                      }
                    </TableCell>
                    <TableCell>
                      <div className="my-2">
                        <Link
                          to={`/ims/material-stock-lot/${selectedLot?._id}`}
                        >
                          <Button
                            size="small"
                            variant="contained"
                            color="info"
                            fullWidth
                          >
                            รายละเอียด
                          </Button>
                        </Link>{' '}
                      </div>
                      <div>
                        {enableEdit && (
                          <Link
                            to={`/ims/material-stock/edit/${selectedLot?._id}`}
                          >
                            <Button
                              size="small"
                              variant="contained"
                              color="warning"
                              fullWidth
                            >
                              แก้ไข
                            </Button>
                          </Link>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow key={''}>
                  <TableCell colSpan={12}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>{' '}
          <TablePagination
            component="div"
            page={page - 1}
            count={total || 1}
            rowsPerPage={size}
            onRowsPerPageChange={(e) => {
              setSize(e.target.value);
              setPage(1);
            }}
            onPageChange={(e, newValue) => {
              setPage(newValue + 1);
            }}
            showFirstButton
            showLastButton
          />
        </TableContainer>{' '}
      </Card>
    </div>
  );
}

export default MaterialLotDurationTable;

MaterialLotDurationTable.propTypes = {
  imsSetting: PropTypes.object,
  materialStockLot: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  page: PropTypes.number,
  total: PropTypes.number,
  size: PropTypes.number,
  setPage: PropTypes.func,
  setSize: PropTypes.func,
  showStatus: PropTypes.bool,
  enableEdit: PropTypes.bool,
};
