import React from 'react';

function CalendarColorLegend() {
  return (
    <div className="flex gap-2 p-2 rounded-md m-2">
      <div className="flex gap-1 items-center">
        <div
          className="rounded-full w-4 h-4"
          style={{
            backgroundColor: '#10B981',
          }}
        />
        <div>สำเร็จ</div>
      </div>
      <div className="flex gap-1 items-center">
        <div
          className="rounded-full w-4 h-4"
          style={{
            backgroundColor: '#ff465d',
          }}
        />
        <div>ยกเลิก</div>
      </div>{' '}
      <div className="flex gap-1 items-center">
        <div
          className="rounded-full w-4 h-4"
          style={{
            backgroundColor: '#ffae2a',
          }}
        />
        <div>ล่าช้า</div>
      </div>
      <div className="flex gap-1 items-center">
        <div
          className="rounded-full w-4 h-4"
          style={{
            backgroundColor: '#2541B2',
          }}
        />
        <div>กำลังดำเนินการ/อยู่ในเวลา</div>
      </div>
    </div>
  );
}

export default CalendarColorLegend;
